//////////////////////////////

// https://stackoverflow.com/questions/7922744/how-can-i-search-all-columns-in-a-table

module.exports = (type) => {

    var schema = {
        /* GENERALE */
        nome: {
            type: type.STRING(64),
            allowNull: true,
        },
        cognome: {
            type: type.STRING(64),
            allowNull: true,
        },
        titolo: {
            type: type.STRING(64),
            allowNull: true,
        },
        denominazione: {
            type: type.STRING(64),
            allowNull: true,
        },
        partita_iva: {
            type: type.STRING(64),
            allowNull: true,
            options: {
                type : 'piva',
            },
        },
        codice_fiscale: {
            type: type.STRING(64),
            allowNull: true,
            options: {
                type : 'codfisc',
            },
        },
        iban: {
            type: type.STRING(64),
            allowNull: true,
            options: {
                type : 'iban',
            },
        },
        sesso: {
            type: type.ENUM( 'Maschio', 'Femmina', 'Altro' ),
            allowNull: true,
            options: {
                store_name  : 'sesso', // basta lo store
            },
        },
        data_nascita: {
            type: type.DATEONLY,
            allowNull: true,
        },
    };

    return schema;
}

