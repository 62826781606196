////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';
import cm          from '@/helpers/statemanager';

var options = {
    model_name    : 'custodian',
    send_alert    : false,
    default_order : 'label ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    filters     : { active: true, },
    customer_id : null,
    cm          : new cm(),
};

const getters = {
    ...base.getters,
    
}

const actions = {
    ...base.actions,
    get_all( { commit, state, getters, rootGetters }, customer_id = null ) { // ok
        if ( customer_id === null && state.customer_id ) { customer_id = state.customer_id; }
        commit('start_request');
        commit('success_list', rootGetters['statemanager/custodians']( customer_id ));
    },
    set_customer_id( { commit }, customer_id ) {
        commit('set_customer_id', customer_id);
    },
    update({ commit, state, getters }, data) {
        commit('start_request');
        state.service.update( 'custodian', data.id, data.payload )
            .then( obj => {
                commit('success', obj);
            })
            .catch( error => {
                commit('error', error)
            });
    },
    set_pratica( { commit }, praticaobj ) { commit('set_pratica', praticaobj); },
};

const mutations = {
    ...base.mutations,
    set_customer_id( state, customer_id ) { state.customer_id = customer_id; },
    set_pratica( state, praticaobj ) { state.cm.pratica = praticaobj; },
}

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
    getters,
};
