<script>

import Vue from 'vue';
Vue.use( require('vue-filter-pretty-bytes') );
const moment = require('moment-timezone');

const schemas = require('@/schemaconfig');
const config  = require('@/config.js')[ process.env.NODE_ENV || 'development' ];
const options = require('@/listconfig');
import filestable from './files.table';
import filesmoving from './files.moving';

export default {
    name: 'files',
    components: {
        filestable,
        filesmoving
    },
    props: [ 'codice_pratica', ],
    mounted () {
        this.$root.$on('bv::collapse::state', ( collapse_name, is_just_shown) => {
            if ( collapse_name === 'collapse-new-folder' && is_just_shown === false ) {
                this.new_folder_error = null;
                this.new_folder_state = null;
                this.new_folder_name  = '';
            } else if ( collapse_name === 'collapse-upload' && is_just_shown === false ) {
                this.upload_error = null;
                this.upload_state = null;
                this.files        = [];
            }
        })
        this.is_mounted = true;
    },
    watch: {
        created(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch('folder/executed');
                if ( this.$store.state.folder.error ) {
                    this.new_folder_state = false;
                    this.new_folder_error = this.$store.state.folder.obj.message;
                } else {
                    this.$root.$emit('bv::toggle::collapse', 'collapse-new-folder');
                }
            }
        },
        createdws(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch('folder/executed');
                if ( this.$store.state.folder.error ) {
                    this.new_folder_state = false;
                    this.new_folder_error = this.$store.state.folder.obj.message;
                } else {
                    if ( this.upload_counter.done === this.upload_counter.tot ) {
                        this.$root.$emit('bv::toggle::collapse', 'collapse-upload');
                        if ( this.multiple_update_visible ) { this.multiple_update_visible = false; }
                    }
                }
            }
        },
        updated(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch('folder/executed');
                if ( this.$store.state.folder.error ) {
                    this.new_folder_state = false;
                    this.new_folder_error = this.$store.state.folder.obj.message;
                } else {
                    this.toggle_modal('modal-context-form');
                    //this.modal_context_form = {};
                }
            }
        },
        zip_loading( new_val, old_val ) {
            if ( new_val === true ) { this.$bvToast.show('zip-toast'); }
            else { this.$bvToast.hide('zip-toast'); }
        },
        merged( new_val ) {
            console.log('ciao  da  merged');
            if ( new_val === true ) {
                this.items_selected = [];
            }
        },
    },
    computed: {
        created() { return this.$store.state.folder.created; },
        merged() { return this.$store.state.folder.merged; },
        updated() { return this.$store.state.folder.updated; },
        createdws() { return this.$store.state.folder.createdws; },
        store () { return this.$store.state.folder; },
        table_class () { return this.locked ? 'locked' : ''; },
        link_class () { return this.locked ? 'link-locked' : ''; },
        upload_counter () { return this.$store.state.folder.upload_counter; },
        previous_folder_ids: {
            get() {
                if ( this.is_mounted === false ) { return []; }
                return this.$refs.tablefiles.previous_folder_ids;
            }
        },
        parent_id: {
            get() {
                if ( this.is_mounted === false ) { return null; }
                return this.$refs.tablefiles.parent_id;
            },
            set( value ) {
                this.$refs.tablefiles.parent_id = value;
            }
        },
        zip_loading() { return this.$store.state.folder.zip_loading; },
        zip_message() { return this.$store.state.folder.zip_message; },
        backend_url() { return config.backend_url; },
        selected_are_images_pdf() {
            if ( !this.items_selected || this.items_selected.length === 0 ) { return false; }
            var total = this.items_selected.filter( x => [ 'application/pdf', 'image/jpeg', 'image/jpg', 'image/png' ].includes( x.task_image.value.mimetype ) ).length;
            return total !== this.items_selected.length;
        }
    },
    created () {
        this.items_selected = [];
    },
    methods: {
        backto_folder() {
            this.previous_folder_ids.pop();
            this.parent_id = this.previous_folder_ids[ this.previous_folder_ids.length - 1 ].value;
            var data = {
                options: {
                    codice_pratica : this.codice_pratica,
                    parentId       : this.parent_id,
                    include        : true,
                }
            };
            this.$store.dispatch('folder/get_all', data);
        },
        upload_files() {
            //console.log('ciaone');
            var pfi = this.previous_folder_ids.length;
            if ( this.files.length > 1 ) { this.multiple_update_visible = true;  }
            var data = {
                codice_pratica : this.codice_pratica,
            };
            var folder_id = this.previous_folder_ids[ pfi - 1 ];
            if ( this.files.length === 1 ) {
                data.payload = { file: this.files[ 0 ], };
                if ( folder_id['value'] ) {
                    data.folder_id = folder_id['value'];
                } else {
                    data.payload.codice_pratica = this.codice_pratica;
                }
                this.$store.dispatch( 'folder/createws', data );
            } else {
                data.folder_id = folder_id['value'];
                data.files     = this.files;
                this.$store.dispatch( 'folder/createws_multiple', data );
            }
        },
        toggle_modal(modal_id = 'modal-filter') {
            modal_id = modal_id.constructor.name === 'MouseEvent' ? 'modal-filter' : modal_id;
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        set_focus( element_ref ) {
            this.$refs[ element_ref ].focus();
        },
        create_folder() {
            var folder_data = {
                payload : {
                    name           : this.new_folder_name,
                    parentId       : this.parent_id,
                    codice_pratica : parseInt( this.codice_pratica ),
                },
                include: true,
            };
            this.$store.dispatch('folder/create', folder_data);
        },
        row_selected( rows ) {
            var ids = this.items_selected.map( x => x.id );
            rows.map( x => {
                if ( !ids.includes( x.id ) ) { this.items_selected.push( x ); }
            });
            ids = this.items_selected.map( x => x.id );
            var to_delete = [];
            var table_ids = rows.map( x => x.id );
            ids.map( x => {
                if ( !table_ids.includes( x ) ) {
                    to_delete.push( ids.indexOf( x ) );
                }
            });
            to_delete.map( x => {
                this.items_selected.splice( x, 1 );
            });
            //console.log( this.items_selected.map( x => x.name ) );
        },
        move_files() {
            if ( this.moving === false ) { this.$store.dispatch('folder/freeze'); }
            if ( this.moving === true  ) { this.items_selected = []; }
            this.moving = !this.moving;
        },
        download_files() {
            this.$store.dispatch('folder/download_multiple', {
                codice_pratica : this.codice_pratica,
                ids            : this.items_selected.map( x => x.id )
            }); 
        },
        get_files_label() {
            if ( this.files.length === 1 ) { return this.files[0].name };
            return `${ this.files.length } file selezionati per l'upload`;
        },
        set_zip_loading() {
            this.$store.dispatch('folder/set_zip_loading', false);
        },
        merge_files() { 
            console.log( this.items_selected );
            this.toggle_modal('modal-merge');
        },
        clear_merge_modal() {
        },
        submit_merge_modal() {
            var file_name = this.$refs.final_file_name.vModelValue;
            if ( !file_name || file_name.length < 3 ) { return; }
            file_name = file_name.replace( /\.pdf$/i, '' );
            var payload = {
                ids: this.items_selected.map( x => x.id ),
                file_name,
                // parent id è implicito perché si possono unire solo file nella stessa cartella
            };
            console.log( payload );
            this.$store.dispatch('folder/merge_files', payload);
            this.toggle_modal('modal-merge');
        },
        set_focus( ref ) {
            this.$refs[ ref ].focus();
        },
    },
    data () {
        return {
            schema_name         : 'folder',
            schema              : 'folder',
            locked              : false,
            new_folder_name     : '',
            new_folder_state    : null,
            new_folder_error    : null,
            files               : [],
            upload_state        : null,
            upload_error        : null,
            modal_context_form  : { show: false },
            modal_filter_key    : 0,
            items_selected      : [],
            moving              : false,
            is_mounted          : false,
            multiple_update_visible : false,
        }
    },
};

</script>

<template>

    <div>
    
        <b-container>
            <b-row style="margin-bottom: 10px;">
                <table style="width: 100%; margin: 0; padding: 0;">
                    <tr>
                        <!-- tasto torna indietro -->
                        <td style="margin: 0; padding: 0;">
                            <a v-if="previous_folder_ids !== null && previous_folder_ids.length > 1" href="#" @click="backto_folder()"><icon name="level-up-alt"/></a>
                            <span v-if="previous_folder_ids === null || previous_folder_ids.length === 1" style="color: grey;"><icon name="level-up-alt"/></span>
                        </td>
                        <td style="margin: 0; padding: 0;" class="text-right">
                            <b-button
                                v-b-tooltip.hover title="Unisci"
                                v-if="items_selected.length > 1"
                                size="sm"
                                variant="outline-dark"
                                style="margin-left: 10px;"
                                :disabled="selected_are_images_pdf"
                                @click="merge_files"
                                >
                                <icon name="paperclip"/>
                            </b-button>
                            <b-button
                                v-b-tooltip.hover title="Scarica"
                                v-if="items_selected.length > 0"
                                size="sm"
                                variant="outline-warning"
                                style="margin-left: 10px;"
                                :disabled="zip_loading"
                                @click="download_files"
                                >
                                <icon name="download" style="fill: #FFC107"/>
                            </b-button>
                            <b-button
                                v-b-tooltip.hover title="Sposta"
                                v-if="items_selected.length > 0"
                                size="sm"
                                :variant="moving === true ? 'danger' : 'outline-danger'"
                                style="margin-left: 10px;"
                                @click="move_files"
                                >
                                <icon name="folder-open" style="fill: #DC3545"/>
                            </b-button>
                            <b-button
                                v-b-tooltip.hover title="Nuova cartella"
                                v-b-toggle.collapse-new-folder
                                size      = "sm"
                                variant   = "outline-primary"
                                style     = "margin-left: 10px;"
                                :disabled = "moving"
                                >
                                <icon name="folder-plus" style="fill: #007BFF"/>
                            </b-button>
                            <b-button
                                v-b-tooltip.hover title="Upload"
                                v-b-toggle.collapse-upload
                                size      = "sm"
                                variant   = "outline-primary"
                                style     = "margin-left: 10px;"
                                :disabled = "moving"
                                >
                                <icon name="file-upload" style="fill: #007BFF"/>
                            </b-button>
                        </td>
                    </tr>
                </table>
            </b-row>

            <b-collapse id="collapse-new-folder" class="mt-2" @shown="set_focus('new_folder_input')">
                <b-row class="justify-content-md-center">
                    <b-col lg="6" md="8" sm="10">
                        <b-input-group style="">
                            <b-form-input size="sm"  v-on:keyup.enter="create_folder" :state="new_folder_state" placeholder="nome cartella" ref="new_folder_input" v-model="new_folder_name">
                            </b-form-input>
                            <b-input-group-append>
                                <b-button size="sm" variant="warning" @click="create_folder" :disabled="new_folder_state === ''"><icon name="save"/></b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <div class="form-text text-danger">
                            {{ new_folder_error }}
                        </div>
                    </b-col>
                </b-row>
            </b-collapse> 

            <b-collapse id="collapse-upload" class="mt-2">
                <b-row class="justify-content-md-center">
                    <b-col lg="6" md="8" sm="10">
                        <b-input-group style="">
                            <b-form-file
                                multiple
                                v-model              = "files"
                                ref                  = "upload_input"
                                placeholder          = "Clicca o trascina un file qui"
                                drop-placeholder     = "Drop file here..."
                                :file-name-formatter = "get_files_label"
                                size                 = "sm"
                            ></b-form-file>
                            <b-input-group-append>
                                <b-button size="sm" variant="warning" @click="upload_files" :disabled="files.length === 0"><icon name="cloud-upload-alt"/></b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <div class="form-text text-danger">
                            {{ upload_error }}
                        </div>
                        <b-collapse id="collapse-upload-progress" class="mt-2" :visible="multiple_update_visible">
                            <b-progress :max="upload_counter.tot" show-progress animated>
                                <b-progress-bar :value="upload_counter.done" :label="`${ upload_counter.done } / ${ upload_counter.tot }`"></b-progress-bar>
                            </b-progress>
                            <!-- <b-progress :max="upload_counter.tot" height="2rem">
                                <b-progress-bar :value="upload_counter.done">
                                    Upload: <strong>{{ upload_counter.done }} / {{ upload_counter.tot }}</strong>
                                </b-progress-bar>
                            </b-progress> -->
                        </b-collapse>
                    </b-col>
                </b-row>
            </b-collapse> 

            <div v-if="moving === false">

                <filestable
                    ref             = "tablefiles"
                    :codice_pratica = "codice_pratica"
                    @row-selected   = "row_selected"
                    >
                </filestable>

            </div>

            <div v-if="moving === true">

                <filesmoving
                    ref               = "tablefiles"
                    :items            = "items_selected"
                    :codice_pratica   = "codice_pratica"
                    @close-moving     = "move_files"
                    >
                </filesmoving>

            </div>

        </b-container>

        <!-- zip toast -->
        <b-toast
            no-auto-hide
            id       = "zip-toast"
            :title   = "zip_message.class"
            :variant = "zip_message.variant"
            :solid   = "true"
            toaster  = "b-toaster-bottom-right"
            @hidden  = "set_zip_loading"
            >
			<div v-if="zip_message.hasOwnProperty('url') === false"><span>{{ zip_message.action }}</span><b-spinner :variant="zip_message.variant || 'primary'" label="Spinning" style="float: right;" small></b-spinner></div>
            <a v-if="zip_message.hasOwnProperty('url')" :href="backend_url + zip_message.url" target="_blank">{{ zip_message.file_name }}</a>
        </b-toast>

        <!-- modal merge -->
        <b-modal
            id="modal-merge"
            title="Nome del file finale"
            @hide="clear_merge_modal"
            @shown="set_focus('final_file_name')"
            button-size="sm"
            >
            <b-input-group size="sm" append=".pdf" class="mb-2 mr-sm-2 mb-sm-0">
              <b-form-input id="final_file_name" ref="final_file_name" placeholder="file name"></b-form-input>
            </b-input-group>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_merge_modal">
                      OK
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="warning" @click="hide('clear')">
                      Reset
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

    </div>

</template>

<style>
    .breadcrumb {
        padding: 2px 15px !important;
    }
    .locked {
        opacity: 0.5;
    }
    .link-locked {
        cursor: default;
    }
    .table th {
        border-top: 0px !important;
    }
    .table {
        width: 100% !important;
    }
</style>
