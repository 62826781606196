////////////////////

import axios from 'axios';

import { authHeader } from '@/helpers';
import base_module from '@/vue-model-mirror/stores/base.module';
const config = require('@/config')[ process.env.NODE_ENV || 'development' ];

var options = {
    model_name    : 'registry',
    send_alert    : false,
    default_order : 'id DESC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    already_related : false,
}

const actions = {
    ...base.actions,
    set_params( { commit }, obj ) { commit('set_params', obj); },
    get_all( { commit, state, getters }, _data = { options: {} } ) {
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        commit('start_request');
        data.options.order = data.order;
        let options = { headers: authHeader() };
        let url = config.backend_url + 'model/registry/filter';
        let params = [];
        Object.keys( data.options ).map( x => {
            var data_encoded = encodeURIComponent( data.options[ x ] );
            params.push( `${x}=${ data_encoded }` );
        });
        if ( params.length > 0 ) {
            url += '?' + params.join('&');
        }
        //state.service.get_all( getters.model_name, data.options )
        axios.get( url, options )
            .then( response => {
                commit('success_list', response.data.data)
            })
            .catch( error => {
                if ( error.response.status === 400 ) {
                    commit('already_related');
                } else {
                    commit('error', error)
                }
            });
    },
};

const mutations = {
    ...base.mutations,
   set_params( state, obj ) {
       if ( typeof obj === 'object' ) {
           var keys = Object.keys( obj );
           for ( var i = 0; i < keys.length; i++  ) {
               if ( state.hasOwnProperty( keys[ i ] ) ) { state[ keys[ i ] ] = obj[ keys[ i ] ]; }
           }
       }
   },
    success_list( state, items ) {
        state.items    = items;
        state.loading  = false;
        state.loading_by_id = false;
        state.error    = false;
        state.executed = true;
        setTimeout( () => { state.executed = false }, 200 )
    },
    already_related( state ) {
        state.already_related = true;
        setTimeout( () => {
            state.already_related = false;
        }, 200);
    }
};

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
};

