<script>

export default {

    name: 'reminder-sending',
    props: [ 'item' ],
    data() {
        return {
        }
    },
    computed: {},
    methods: {
    },
    created () {},

}

</script>

<template>

    <div class="text-center">
        <div style="padding-bottom: 7px; font-size: 0.8rem;">EMAIL INVIATE: {{ item.config.sent }} / {{ item.config.max }}</div>
        <table style="margin: auto; width: 90%">
            <tr v-for="( row, counter ) in item.config.sending">
                <td style="padding: 0; text-align: center; padding-left: 4px; font-weight: 800;"><small style="font-weight: 800;">{{ counter + 1 }}</small></td>
                <td style="padding: 0; text-align: left; padding-left: 8px;"><small style="font-weight: 600">{{ row.datetime }}</small></td>
                <td style="padding: 0; padding-right: 4px;"><small style=""><b>{{ row.status }}</b></small></td>
            </tr>
        </table>
        <div v-if="item.config.next_send" style="margin-top: 14px; font-size: 0.8rem">Prossimo invio: {{ item.config.next_send }}</div>
    </div>

</template>

