//////////////////////////////////////////////////

const filters = require('@/filterconfig');
const cmps    = require('./components');

module.exports = {

    appuntamento: {
        title_list     : 'Appuntamenti',
        order          : 'codice_pratica ASC',
        add            : false,
        edit           : false,
        details        : true,
        details_action : 'click',
        include        : false,
        paginate       : true,
        initialfilter  : {},
        search_schema  : null,
        fields: [
            { key: 'codice', label: 'Codice', component: cmps.appuntamento_codice, sortable: true, screen: 'mobile', },
            { key: 'luogo_appuntamento', label: 'Luogo Appuntamento', component: cmps.appuntamento_luogo, sortable: false, screen: 'mobile', },
            { key: 'compagnia', label: 'Compagnia', component: cmps.appuntamento_compagnia, sortable: false, screen: 'mobile', },
            { key: 'pregs.incarico.tipo_danno', label: 'Tipo Danno', sortable: false, screen: 'mobile', },
            { key: 'assicurato', label: 'Assicurato', component: cmps.appuntamento_assicurato_line, sortable: false, screen: 'mobile', },
            { key: 'controparte', label: 'Controparte', component: cmps.appuntamento_controparte_line, sortable: false, screen: 'mobile', },
            { key: 'tasks', label: 'Tasks', component: cmps.appuntamento_tasks, screen: 'mobile', },

            { key: 'assignments', label: 'ASSEGNAZIONI', component: cmps.appuntamento_assignments, display: false, },
            { key: 'cliente', label: 'CLIENTE', component: cmps.appuntamento_cliente, display: false, },
            { key: 'pregs.incarico.numero_sinitro', label: 'NUM. SINISTRO', display: false, },
            { key: 'pregs.incarico.num_pratica_esterno', label: 'NUMERO ESTERNO', display: false, },
            { key: 'note', label: 'NOTE PRATICA', component: cmps.appuntamento_note, display: false, },
            { key: 'assicurato_referente', label: 'ASSICURATO', component: cmps.appuntamento_assicurato, sortable: false, display: false },
            { key: 'controparte_referente', label: 'CONTROPARTE', component: cmps.appuntamento_controparte, sortable: false, display: false },
        ],
    },

    appuntamento_list: {
        title_list     : 'Appuntamenti',
        order          : 'codice_pratica ASC',
        add            : false,
        edit           : false,
        details        : true,
        detailscmp     : cmps.appuntamento_details,
        details_action : 'click',
        include        : false,
        paginate       : true,
        initialfilter  : {},
        search_schema  : null,
        fields: [
            { key: 'codice', label: 'Codice', component: cmps.appuntamento_codice, sortable: true, screen: 'mobile', size: 'cols-1' },
            { key: 'luogo_appuntamento', label: 'Luogo Appuntamento', component: cmps.appuntamento_luogo, sortable: false, screen: 'mobile', size: '2' },
            { key: 'compagnia', label: 'Compagnia', component: cmps.appuntamento_compagnia, sortable: false, screen: 'mobile', size: 'cols-1' },
            { key: 'pregs.incarico.tipo_danno', label: 'Tipo Danno', sortable: false, screen: 'mobile', size: 'cols-2' },
            { key: 'assicurato', label: 'Assicurato', component: cmps.appuntamento_assicurato_line, sortable: false, screen: 'mobile', size: 'cols-2' },
            { key: 'controparte', label: 'Controparte', component: cmps.appuntamento_controparte_line, sortable: false, screen: 'mobile', size: 'cols-2' },
            { key: 'tasks', label: 'Tasks', component: cmps.appuntamento_tasks, screen: 'mobile', size: 'cols-2'},

            { key: 'assignments', label: 'ASSEGNAZIONI', component: cmps.appuntamento_assignments, _type : 'detail', },
            { key: 'cliente', label: 'CLIENTE', component: cmps.appuntamento_cliente, _type : 'detail', },
            { key: 'pregs.incarico.numero_sinitro', label: 'NUM. SINISTRO', _type : 'detail', },
            { key: 'pregs.incarico.num_pratica_esterno', label: 'NUMERO ESTERNO', _type : 'detail', },
            { key: 'note', label: 'NOTE PRATICA', component: cmps.appuntamento_note, _type : 'detail', },
            { key: 'assicurato_referente', label: 'ASSICURATO', component: cmps.appuntamento_assicurato, sortable: false, _type : 'detail' },
            { key: 'controparte_referente', label: 'CONTROPARTE', component: cmps.appuntamento_controparte, sortable: false, _type : 'detail' },
        ],
    },

    tipo_pratica: {
        title_list    : 'Tipologie di Pratiche',
        order         : 'label ASC',
        add           : true,
        edit          : false,
        details       : false,
        include       : false,
        edit_action   : 'double-click',
        paginate      : true,
        initialfilter : {},
        search_schema : null,
        fields: [
            { key: 'label', label: 'Nome', sortable: true },
            { key: 'descrizione', label: 'Descrizione', sortable: false },
            { key: 'created', htmlformatter: 'format_datetime_t2', label: 'Data', screen: 'mobile', sortable: true },
        ],
    },

    registry: {
        title_list    : 'Anagrafiche',
        order         : 'id ASC',
        add           : true,
        edit          : false,
        details       : false,
        include       : true,
        edit_action   : 'double-click',
        paginate      : true,
        initialfilter : {},
        search_schema : null,
        fields: [
            { key: 'label', label: 'Nome', sortable: false },
            { key: 'registry_template.label', label: 'Template', sortable: false },
            { key: 'contacts', label: 'Contatti', component: cmps.reg_contacts, sortable: false },
            { key: 'pacoib', label: 'Dati', component: cmps.reg_pacoib, sortable: false },
            { key: 'created', htmlformatter: 'format_datetime_t2', label: 'Data', screen: 'mobile' },
        ],
    },

    search_statistic: {
        title_list    : 'Parcelle',
        order         : 'id ASC',
        add           : false,
        edit          : false,
        details       : false,
        include       : true,
        paginate      : false,
        initialfilter : {},
        search_schema : 'search_statistic',
        fields: [
            { key: 'user', label: 'Utente', component: cmps.statistic_user, sortable: false, screen: 'mobile' },
            { key: 'sigla', label: 'Sigla', sortable: false, screen: 'mobile' },
            { key: 'amount', label: 'Importo', component: cmps.statistic_amount, sortable: false, screen: 'mobile' },
        ],
    },

//  pratica_parcel: {
//      title_list    : 'Parcelle',
//      order         : 'id ASC',
//      add           : false,
//      edit          : false,
//      details       : false,
//      include       : true,
//      paginate      : false,
//      initialfilter : {},
//      search_schema : 'search_statistic',
//      fields: [
//          { key: 'user', label: 'Utente', component: cmps.statistic_user, sortable: false, screen: 'mobile' },
//          { key: 'sigla', label: 'Sigla', sortable: false, screen: 'mobile' },
//          { key: 'amount', label: 'Importo', component: cmps.statistic_amount, sortable: false, screen: 'mobile' },
//      ],
//  },

    pratica_parcel_task: {
        title_list    : 'Parcelle',
        order         : 'id ASC',
        add           : false,
        edit          : false,
        details       : false,
        include       : true,
        paginate      : false,
        initialfilter : {},
        fields: [
            { key: 'icon', label: 'Task', component: cmps.parcel_icon, sortable: false, screen: 'mobile' },
            { key: 'user_name', label: 'Utente', component: cmps.parcel_user_name, sortable: false, screen: 'mobile' },
            { key: 'defaults', label: 'Defaults', component: cmps.parcel_defaults, sortable: false, screen: 'mobile' },
            { key: 'config', label: '% - Minimo', component: cmps.parcel_config, sortable: false, screen: 'mobile' },
            { key: 'amount', label: 'Importo', component: cmps.parcel_amount, sortable: false, screen: 'mobile' },
        ],
    },
    pratica_parcel_user: {
        title_list    : 'Parcelle',
        order         : 'id ASC',
        add           : false,
        edit          : false,
        details       : false,
        include       : true,
        paginate      : false,
        initialfilter : {},
        fields: [
            { key: 'icon', label: 'Task', component: cmps.parcel_icon_user, sortable: false, screen: 'mobile' },
            { key: 'user_name', label: 'Utente', component: cmps.parcel_user_name, sortable: false, screen: 'mobile' },
            { key: 'defaults', label: 'Defaults', component: cmps.parcel_defaults, sortable: false, screen: 'mobile' },
            { key: 'quota', label: 'Quote', component: cmps.parcel_quota, sortable: false, screen: 'mobile' },
            { key: 'config', label: '% - Minimo', component: cmps.parcel_config_user, sortable: false, screen: 'mobile' },
            { key: 'amount', label: 'Importo', component: cmps.parcel_amount, sortable: false, screen: 'mobile' },
        ],
    },
    importer_credential: {
        title_list    : 'Credenziali Importer',
        order         : 'importer ASC', // * TODO TODO TODO TODO TODO TODO TODO
        add           : false,
        edit          : true,
        details       : false,
        include       : true,
        paginate      : false,
        initialfilter : {},
        fields: [
            { key: 'importer', sortable: true, screen: 'mobile' },
            { key: 'customer.label', label: 'Customer', sortable: false, screen: 'mobile' },
            { key: 'credentials', sortable: false, component: cmps.settings_importer, screen: 'mobile' },
        ],
    },
    role: {
        title_list    : 'Ruoli',
//      order         : 'label DESC', // * TODO TODO TODO TODO TODO TODO TODO
        add           : false,
        edit          : false,
        details       : false,
//      include       : false,
        paginate      : true,
//      initialfilter : {},
        fields: [
            { key: 'label', label: 'Nome', screen: 'mobile', },
            { key: 'created', htmlformatter: 'format_datetime_t2', label: 'Data', screen: 'mobile' },
        ],
    },
    diary: {
        order         : 'id DESC', // * TODO TODO TODO TODO TODO TODO TODO
        add           : true,
        edit          : false,
        details       : false,
        include       : false,
        paginate      : true,
        initialfilter : {},
        fields: [
            { key: 'action', sortable: true, label: 'Titolo', screen: 'mobile' },
            //{ key: 'options.operator_label', sortable: false, label: 'Utente', screen: 'mobile' },
            { key: 'operator_label', component: cmps.diary_user, sortable: false, label: 'Utente', screen: 'mobile' },
            { key: 'task', sortable: false, component: cmps.diary_task, label: '', screen: 'mobile' },
            { key: 'description', sortable: false, component: cmps.diary_description, label: 'Descrizione', screen: 'mobile' },
            { key: 'created', htmlformatter: 'format_datetime_t2', label: 'Data', screen: 'mobile' },
        ],
    },
    fileupload: {
    },
    move_folder: {
        title_list    : 'Utenti',
        order         : 'name ASC', // * TODO TODO TODO TODO TODO TODO TODO
        add           : false,
        edit          : true,
        details       : false,
        include       : true,
        initialfilter : {},
        //details_class : 'd-lg-none', // sparisce su desktop
        //submit        : true,
        fields: [
            { key: 'name', sortable: false, component: cmps.files_label, label: 'Nome', screen: 'mobile' },
        ],
    },
    folder: {
        title_list    : 'Utenti',
        order         : 'name ASC', // * TODO TODO TODO TODO TODO TODO TODO
        add           : false,
        edit          : true,
        details       : false,
        include       : true,
        initialfilter : {},
        //details_class : 'd-lg-none', // sparisce su desktop
        //submit        : true,
        fields: [
            { key: 'name', sortable: false, component: cmps.files_label, label: 'Nome', screen: 'mobile' },
            { key: 'task_image.value.size', component: cmps.files_size, sortable: false, label: 'Size', screen: 'mobile', class: ['', 'text-right', ''] },
            { key: 'task_image.value.created', component: cmps.files_created, sortable: false, label: 'Date', screen: 'mobile', class: ['', 'text-right', ''] },
            { key: 'task_image.value.uploaded_by', component: cmps.files_uploader, sortable: false, label: 'User', screen: 'mobile', class: ['', 'text-right', ''] },
        ],
    },
    user: {
        title_list    : 'Utenti',
        order         : 'last_name ASC|first_name ASC', // * TODO TODO TODO TODO TODO TODO TODO
        add           : true,
        edit          : false,
        include       : true,
        edit_action    : 'double-click',
        details       : true,
        details_class : '', // sparisce su desktop
        submit        : true,
        fields: [
            // sempre visibili { screen: 'mobile' },
            { key: 'last_name', sortable: true, label: 'Cognome', screen: 'mobile' },
            { key: 'first_name', sortable: false, label: 'Nome', screen: 'mobile' },
            { key: 'enabled', sortable: true, label: 'Attivo', formatter: 'convert_boolean', screen: 'mobile' },
            // solo tablet { screen: 'tablet' }
            { key: 'email', sortable: false, label: 'Email', screen: 'tablet' },
            { key: 'role_label', sortable: false, label: 'Ruolo', component: cmps.user_role, screen: 'tablet' },
            { key: 'customers', sortable: false, label: 'Customers', component: cmps.user_customers, screen: 'tablet' },
            // solo desktop { screen: 'desktop' }
            { key: 'created', htmlformatter: 'format_datetime_t2', sortable: false, label: 'Creato il', screen: 'desktop', class: ['', 'text-center', ''] },
            //{ key: 'actions', sortable: false, label: 'Azioni', component: cmps.user_actions, display: false },
        ],
    },
    pratica: {
        title_list     : 'Pratiche',
        size           : 'sm',
        include        : true,
        paginate       : true,
        order          : 'codice DESC', // * TODO TODO TODO TODO TODO TODO TODO
        add            : true,
        edit           : true,
        edit_action    : 'double-click',
        details        : true,
        details_action : 'click',
        primary_key    : 'codice',
//      search_schema  : 'search_pratica',
//      skip           : [ 'user_creator', 'customer_owner', 'metas', 'dati_pratica', 'ufficio', 'referente', 'controparte', 'agenzia', 'assicurato', ],
        initialfilter  : {},
        //rowclass       : filters.rowclass,
        fields         : [
            // sempre visibili { screen: 'mobile' },
            { key: 'codice', htmlformatter: filters.codice, sortable: true, label: '#', screen: 'mobile', },
            { key: 'customer_owner.label', label: 'Proprietario', screen: 'mobile', sortable: false, },
            { key: 'compagnia', label: 'Compagnia', component: cmps.pra_compagnia, screen: 'mobile', },
            // tablet
            { key: 'pregs.incarico.numero_sinistro', label: 'Num. Sinistro', screen: 'tablet', },
            { key: 'assicurato', label: 'Assicurato', component: cmps.pra_assicurato, screen: 'tablet', },
            { key: 'controparte', label: 'Controparte', component: cmps.pra_controparte, screen: 'tablet', },
            // desktop
            { key: 'pregs.incarico.ramo', label: 'Ramo', screen: 'desktop', },
            { key: 'pregs.incarico.tipo_danno', label: 'Tipo danno', screen: 'desktop', },
            { key: 'pregs.incarico.data_affidamento', label: 'Data incarico', screen: 'desktop', formatter: 'format_date'  },
            { key: 'label', label: 'Task', component: cmps.legend, screen: 'desktop', },
            { key: 'linkexcel', label: '', component: cmps.linkxls, screen: 'desktop', },
            { key: 'agenzia', component: cmps.pra_agenzia, label: 'AGENZIA', display: false, },
            { key: 'recapiti-assicurato', component: cmps.pra_assicurato, label: 'ASSICURATO', display: false, },
            { key: 'recapiti-controparte', component: cmps.pra_controparte, label: 'CONTROPARTE', display: false, },
            { key: 'cliente', label: 'Cliente', display: false, component: cmps.pra_cliente },
            { key: 'pregs.incarico.num_pratica_esterno', label: 'NUMERO ESTERNO', display: false, },
            { key: 'created', label: 'CREATA', htmlformatter: 'format_datetime_t2', sortable: false, display: false, },
            { key: 'pregs.incarico.note', label: 'NOTE', sortable: false, display: false, },
        ],
    },

    ispezione: {
        title_list    : 'Ispezioni',
        size          : 'sm',
        include       : true,
        paginate      : true,
        order         : 'created DESC', // * TODO TODO TODO TODO TODO TODO TODO
        add           : false,
        edit          : true,
        details       : true,
        details_class : 'd-lg-none', // sparisce su desktop
        primary_key   : 'codice',
        //search_schema : 'search_pratica',
        skip          : [ 'user_creator', 'customer_owner', 'metas', 'dati_pratica', 'ufficio', 'referente', 'controparte', 'agenzia', 'assicurato', ],
        initialfilter : {},
        fields        : [
            // sempre visibili { screen: 'mobile' },
            { key: 'codice', sortable: true, label: '#', screen: 'mobile', },
            { key: 'created', label: 'Creata', htmlformatter: 'format_datetime_t2', sortable: true, screen: 'mobile', },
            { key: 'closed', label: 'Chiusa', formatter: 'convert_boolean', 'class': 'text-center', screen: 'mobile' },
            // solo tablet { screen: 'tablet' }
            { key: 'assicurato.label_table_row', htmlformatter: 'array_to_rows_t2', label: 'Assicurato', screen: 'tablet', },
            { key: 'controparte.label_table_row', htmlformatter: 'array_to_rows_t2', label: 'Controparte', screen: 'tablet', },
            // solo desktop { screen: 'desktop' }
            { key: 'customer_owner.label', label: 'Proprietario', screen: 'desktop', },
            { key: 'dati_pratica.studio', label: 'Studio', screen: 'desktop', },
            { key: 'dati_pratica.numero_pratica_esterno', label: 'Rif. aff.', screen: 'desktop', },
            { key: 'label', label: 'Label', component: cmps.legend, screen: 'desktop', }
        ],
    },

    customer: {
        title_list    : 'Customers',
        skip          : [ 'id', 'dns_record_id' ],
        order         : 'label ASC', // * TODO TODO TODO TODO TODO TODO TODO
        include       : false,
        details       : false,
        details_class : 'd-lg-none', // sparisce su desktop
        edit          : true,
        add           : true,
        paginate      : true,
        primarykey    : 'id',
        link          : null,
        search_schema : null,
        initialfilter : {},
        fields: [
            // sempre visibili { screen: 'mobile' },
            { key: 'label', sortable: true, label: 'Nome', screen: 'mobile', },
            { key: 'created', htmlformatter: 'format_datetime_t2', sortable: false, label: 'Creato', formatter: 'format_datetime', screen: 'mobile', },
            { key: 'active', sortable: false, label: 'Attivo', formatter: 'convert_boolean', screen: 'mobile', },
            // solo tablet { screen: 'tablet' }
            // solo desktop { screen: 'desktop' }

//          // sempre visibili { screen: 'always' }
//          // mai visibili { screen: 'never' }
            // mai visibili { display: false }
        ]
    },

    article: {
        title_list    : 'Articoli',
        skip          : ['id', 'created', 'mail_id', 'customer_id'],
        order         : 'created DESC|id DESC', // * TODO TODO TODO TODO TODO TODO TODO
        include       : true,
        details       : true,
        details_class : 'd-lg-none', // sparisce su desktop
        edit          : false,
        add           : false,
        paginate      : true,
        primarykey    : 'id',
        link          : 'link',
        search_schema : 'search_article',
        rowclass      : filters.rowclass,
        linkfunction  : filters.linkfunction,
        initialfilter : filters.initialfilter(),
        fields: [
            // sempre visibili
            { key: 'date', sortable: true, label: 'Data', formatter: 'format_date', display: true, stickyColumn: false, class: ['', '', 'd-none'] },
            { key: 'title', sortable: true, label: 'Titolo', class: ['', '', 'd-none'] },
            // solo tablet
            { key: 'section', sortable: false, label: 'Categoria', stickyColumn: false, class: ['', 'd-none d-md-table-cell', 'd-sm-none'] },
            // solo desktop
            { key: 'source', sortable: false, label: 'Testata', class: ['', 'd-none d-lg-table-cell', 'd-lg-none'] },
            { key: 'page', sortable: false, label: 'Pagina', class: ['', 'text-center d-none d-lg-table-cell', 'd-lg-none'] },
            { key: 'author', sortable: true, label: 'Autore', class: ['', 'd-none d-lg-table-cell', 'd-lg-none'] },
            // mai visibili
            { key: 'link', display: false }
        ]
    },

    email_template: {
        title_list    : 'Template delle Email',
        skip          : [ 'id', ],
        order         : 'label ASC', // * TODO TODO TODO TODO TODO TODO TODO
        include       : false,
        details       : true,
        details_class : 'd-lg-none', // sparisce su desktop
        edit          : true,
        edit_action    : 'double-click',
        add           : true,
        paginate      : true,
        destroy       : true,
        primarykey    : 'id',
        link          : null,
        search_schema : null,
        initialfilter : {},
        fields: [
            // sempre visibili { screen: 'mobile' },
            { key: 'label', sortable: true, label: 'Etichetta', screen: 'mobile', },
            { key: 'type', sortable: true, label: 'Tipo', screen: 'mobile', },
            { key: 'active', sortable: true, label: 'Attivo', formatter: 'convert_boolean', screen: 'mobile', },
        ]
    },

    reminder: {
        title_list     : 'Solleciti',
        skip           : [ 'id', ],
        order          : 'active DESC|created ASC', // * TODO TODO TODO TODO TODO TODO TODO
        include        : false,
        details        : true,
        details_action : 'click',
        details_class  : '', // sparisce su desktop
        edit           : false,
        edit_action    : 'double-click',
        add            : true,
        paginate       : false,
        primarykey     : 'id',
        link           : null,
        search_schema  : null,
        initialfilter  : {},
        fields: [
            { key: 'docs', sortable: false, component: cmps.reminder_list, label: 'Documenti Richiesti', screen: 'mobile', },
            //{ key: 'template1.label', sortable: false, label: 'Email', screen: 'mobile', },
            //{ key: 'template2.label', sortable: false, label: 'Solleciti', screen: 'mobile', },
            { key: 'active', sortable: true, label: 'Attivo', formatter: 'convert_boolean', screen: 'mobile', },
            { key: 'edit', sortable: false, component: cmps.reminder_edit, label: '', screen: 'mobile', },
  
            { key: 'to', sortable: false, component: cmps.reminder_list, label: 'TO', display: false, },
            { key: 'cc', sortable: false, component: cmps.reminder_list, label: 'CC', display: false, },
            { key: 'bcc', sortable: false, component: cmps.reminder_list, label: 'CCN', display: false, },
            { key: 'actions', sortable: false, component: cmps.reminder_actions, label: 'Azioni', display: false, },
            { key: 'sending', sortable: false, component: cmps.reminder_sending, label: 'Stato', display: false, },
        ]
    },

    sollecito_document: {
        title_list    : 'Documenti Sollecitatore',
        skip          : [ 'id', ],
        order         : 'label ASC', // * TODO TODO TODO TODO TODO TODO TODO
        include       : true,
        details       : false,
        details_class : 'd-lg-none', // sparisce su desktop
        edit          : true,
        edit_action    : 'double-click',
        add           : true,
        paginate      : true,
        destroy       : true,
        primarykey    : 'id',
        link          : null,
        search_schema : null,
        initialfilter : {},
        fields: [
            { key: 'label', sortable: true, label: 'Nome', screen: 'mobile', },
            { key: 'active', sortable: true, label: 'Attivo', formatter: 'convert_boolean', screen: 'mobile', },
            { key: 'customer.label', sortable: true, label: 'Customer', screen: 'mobile', },
            //{ key: 'type', sortable: true, label: 'Tipo', screen: 'mobile', },
            { key: 'created', sortable: false, label: 'Creato il', htmlformatter: 'format_datetime_t2', display: false },
            { key: 'actions', sortable: false, component: cmps.sollecito_document_actions, label: 'Azioni', display: false, },
        ]
    }

}

// *: mettere l'id nell'ordinamento risolve il problema della gestione "read"
//    però genera due problematiche:
//        a) perdo il linking tra ordinamento di default e th della tabella
//        b) posso riordinare la tabella cliccando sul th ma perdo l'ordinamento per id

//    SOLUZIONE: attributo 'order' e attributo 'default_order'

// REFERENCES

// title_list     : titolo della pagina list                               - string
// skip           : campi del model da non trattare                        - string[]
// order          : ordine iniziale della tabella                          - string (es: 'label ASC')
// include        : il backend restituisce i record related                - boolean
// details        : mostra il bottone per i dettagli                       - boolean
// details_class  : classe del bottone                                     - boolean
// edit           : mostra il bottone 'modifica'                           - boolean
// add            : mostra il bottone 'nuovo'                              - boolean
// paginate       : mostra il paginator e abilita la paginazione           - boolean
// primarykey     : attributo degli item da usare come chiave per le row   - string
// link           : attributo degli item da usare come link                - string
// search_schema  : schema per la form di ricerca                          - string
// rowclass       : funzione per l'assegnazione di una classe ad una riga  - function
// linkfunction   : filtro iniziale                                        - object
// fields:
//     key           : nome attributo item                         - string
//     sortable      : abilita l'ordinamento                       - boolean
//     label         : etichetta da mostrare nel th                - string
//     formatter     : funzione per la normalizzazione del dato    - string
//     htmlformatter : funzione per la normalizzazione del dato    - string
//                     che restituisce una stringa html
//     display       : se uguale a false non mostra la colonna     - boolean
//     stickyColumn  : fissa la colonna nello scorrimento orizz.   - boolean
//     class         : classe della cella/colonna                  - string[] ( [ 'classe usata sempre', 'classe della colonna', 'classe del detail' ] ) oppure
//                                                                   string   ( in questo caso la classe verrà usata sempre )
//     screen        : size dal quale far comparire il campo       - string ( 'mobile' | 'tablet' | 'desktop' )

