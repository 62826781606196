//////////////////////////////

module.exports = (type) => {
    var schema = {
        denominazione: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        descrizione: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        codice: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        titolo: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        rappresentante: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        referenti: {
            type: type.JSON,
            allowNull: true,
            defaultValue: null,
        },
        piva: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        telefono: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        cellulare: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        fax: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        email: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        indirizzo: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        cap: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        citta: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        note: {
            type: type.TEXT,
            allowNull: true,
            defaultValue: null,
        },
    };

    return schema;
}

