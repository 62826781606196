<script>

export default {

    name: 'cmp-files-label',
    props: [ 'item' ],
    data() {
        return {
            icons: {
                'message/rfc822': {
                    icon    : 'envelope-open-text',
                    variant : '#995695',
                },
                'application/x-msi': {
                    icon    : 'envelope-open-text',
                    variant : '#995695',
                },
                'image/png': {
                    icon    : 'file-image',
                    variant : '#5a9fa1',
                },
                'image/jpeg': {
                    icon    : 'file-image',
                    variant : '#5a9fa1',
                },
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
                    icon    : 'file-excel',
                    variant : '#60a65d',
                },
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
                    icon    : 'file-word',
                    variant : '',
                },
                'application/pdf': {
                    icon    : 'file-pdf',
                    variant : 'red',
                },
                'text/plain': {
                    icon    : 'file-alt',
                    variant : '#c5afc5',
                },
                'application/x-rar-compressed': {
                    icon    : 'file-archive',
                    variant : '#d17428',
                },
                'application/zip': {
                    icon    : 'file-archive',
                    variant : '#d17428',
                },
            },
            link_class: '',
        }
    },
    computed: {},
    methods: {
        goto_folder( id, name ) {
            this.$emit('genericevent', { event_name: 'goto_folder', id, name } );
        },
        get_link( image_id ) {
            this.$emit('genericevent', { event_name: 'get_link', image_id } );
        },
        get_icon( item ) {
            if ( this.icons.hasOwnProperty( item.conv_mimetype ) ) { return this.icons[ item.conv_mimetype ].icon; }
            if ( this.icons.hasOwnProperty( item.mimetype ) ) { return this.icons[ item.mimetype ].icon; }
            return 'question';
        },
        get_icon_color( item ) {
            if ( this.icons.hasOwnProperty( item.conv_mimetype ) && this.icons[ item.conv_mimetype ].variant ) { return { fill: this.icons[ item.conv_mimetype ].variant }; }
            if ( this.icons.hasOwnProperty( item.mimetype ) && this.icons[ item.mimetype ].variant ) { return { fill: this.icons[ item.mimetype ].variant }; }
            return undefined;
        },
        get_tag_label(tag) {
            if ( tag.startsWith('isp') ) { return tag.replace('isp', ''); }
            return tag;
        }
    },
    created () {
    },

}

</script>

<template>

    <div style="">
        <template v-if="item.task_image_id === null">
            <div style="font-size: 14pt">
                <a href="#" @click="goto_folder( item.id, item.name )" :class="link_class">
                    <icon name="folder" style="fill: blue" />
                    <span style="padding-left: 10px;"><small>{{ item.name }}</small></span>
                </a>
            </div>
        </template>
        <template v-if="item.task_image_id">
            <div style="font-size: 14pt">
                <a href="#" @click="get_link( item.task_image.id )" :class="link_class">
                    <icon :name="get_icon( item.task_image.value )" :style="get_icon_color( item.task_image.value )"/>
                    <span style="padding-left: 10px;"><small>{{ item.task_image.value.file_name }}</small></span>
                </a><template v-if="item.task_image.value.hasOwnProperty('tags') && item.task_image.value.tags.length > 0"><b-badge :variant="tag.startsWith('isp') ? 'warning' : 'info'" v-for="tag in item.task_image.value.tags" :key="tag" style="margin-left: 5px; font-size: 9pt;">{{ get_tag_label( tag ) }}</b-badge></template>
            </div>
        </template>
    </div>

</template>


