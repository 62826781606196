<script>

export default {

    name: 'cmp-recapiti',
    props: [ 'item', 'name', ],
    data() {
        return {
            attr_name : null,
            label     : null,
            telefono  : null,
            cellulare : null,
            email     : null,
            indirizzo : null,
        }
    },
    computed: {},
    methods: {
        set_data() {
            // label
            this.label = this.attr.denominazione;
            if ( this.attr.hasOwnProperty('api') && this.attr.api.hasOwnProperty('label_table_row') ) {
                this.label = this.attr.api.label_table_row[0];
            }
            // telefono
            if ( this.attr.hasOwnProperty('telefono') ) { this.telefono = this.attr.telefono; }
            // cellulare
            if ( this.attr.hasOwnProperty('cellulare') ) { this.cellulare = this.attr.cellulare; }
            // email
            if ( this.attr.hasOwnProperty('email') ) { this.email = this.attr.email; }
            // indirizzo
            if ( this.attr.hasOwnProperty('indirizzo') ) { this.indirizzo = this.attr.indirizzo; }
        },
        set_attr() {
            var attr_name = this.name.replace(/^recapiti-/,'');
            if ( attr_name && this.item.hasOwnProperty( attr_name ) ) { this.attr = this.item[ attr_name ]; }
        }
    },
    created () {
        this.set_attr();
        if ( this.attr ) {
            this.set_data();
        }
    },

}

</script>

<template>

    <div style="">
        <span v-if="!attr" style="color: lightgrey">empty</span>
        
        <p v-if="attr" style="line-height: 16px; margin-bottom: 2px;"><small style="font-weight: 600;">{{ label || 'empty' }}</small><br/>
            <table>
            <tr v-if="telefono"><td style=""><small style="font-weight: 600">telefono:</small></td><td><small style="padding-left: 4px;">{{ telefono }}</small></td></tr>
            <tr v-if="cellulare"><td style=""><small style="font-weight: 600">cellulare:</small></td><td><small style="padding-left: 4px;">{{ cellulare }}</small></td></tr>
            <tr v-if="email"><td style=""><small style="font-weight: 600">email:</small></td><td><small style="padding-left: 4px;">{{ email }}</small></td></tr>
            <tr v-if="indirizzo"><td style=""><small style="font-weight: 600">indirizzo:</small></td><td><small style="padding-left: 4px;">{{ indirizzo }}</small></td></tr>
            </table>
        </p>
    </div>

</template>

