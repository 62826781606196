////////////////////

import { authHeader } from '@/helpers';

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export const reportService = {
    make_report,
};

function make_report(codice_pratica, params = {}) {
    var options = {
        method: 'GET',
        headers: authHeader(),
    };
    var url = `${ config.backend_url }model/pratica/export`;
    if ( Object.keys( params ).length > 0 ) {
        url += '?';
        var url_params = [ 'include=true', ];
        Object.keys( params ).map( x => { url_params.push( `${ x }=${ params[ x ] }` ); }  );
        url += url_params.join('&');
    }
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

