<script>

export default {
    name: 'vmm-paginator',
    props: [ 'page', 'total_rows', 'rows_per_page', 'schema' ],
    computed: {
        page_number: {
            get() { return this.page },
            set( value ) {
                this.$emit( 'change', value );
            }
        },
    },
}

</script>

<template>
    <div class="" style="padding: 2px; padding-top: 20px">
        <b-pagination
            :pills="false"
            v-model="page_number"
            :total-rows="total_rows"
            :per-page="rows_per_page"
            :aria-controls="'bnvlist-' + schema"
            align="center"
            size="sm"
        ></b-pagination>
    </div>
</template>

<style>
</style>

