//////////////////////////////

module.exports = (type) => {
    var schema = {
        imponibile: {
            type: type.DECIMAL(10,2),
            defaultValue: 0.00,
        },
        supplemento_pl: {
            type: type.DECIMAL(10,2),
            defaultValue: 0.00,
        },
        totale_onorari: {
            type: type.DECIMAL(10,2),
            defaultValue: 0.00,
        },
        locomozione: {
            type: type.DECIMAL(10,2),
            defaultValue: 0.00,
        },
        visure: {
            type: type.DECIMAL(10,2),
            defaultValue: 0.00,
        },
        spese_non_imponibili: {
            type: type.DECIMAL(10,2),
            defaultValue: 0.00,
        },
        totale: {
            type: type.DECIMAL(10,2),
            defaultValue: 0.00,
        },
        codice: {
            type: type.VIRTUAL,
            hidden: true,
        },
    };

    return schema;
}

