<script>

import { mapActions, mapState } from 'vuex';

import add_registry from '@/registry/components/add_registry';
import ref_forms from '@/registry/forms';
import modal_association_options from './modal_association_options';

export default {
    name: 'modal-association',
    props: [ 'field_label', 'customer_id', 'codice_pratica', ],
    components : { 'add-registry': add_registry },
    computed : {
        ...mapState('registry_autocomplete', {
            executed        : state => state.executed,
            items           : state => state.items,
        }),
        ...mapState('registry', {
            executed_reg : state => state.executed,
            items_reg    : state => state.items,
        }),
        ...mapState('pratica', {
            registry_setted : state => state.registry_setted,
        }),
        modal_options_title() {
            if ( this.mao.search_filters && this.mao.search_filters.label ) { return this.mao.search_filters.label; }
            return '';
        },
    },
    mounted() {
        this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
            this.modals[ modalId ] = true;
        })
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            //console.log( modalId, false );
            this.modals[ modalId ] = false;
        })
    },
    watch: {
        registry_setted( new_value ) {
            if ( this.modals['modal-edit-registry'] ) {
                this.toggle_modal('modal-edit-registry');
            }
        },
        search_text_children( new_value ) {
            if ( new_value.length < this.min_matching_chars ) { this.clear(); return; }
            if ( this.timeout_search_text ) {
                clearTimeout( this.timeout_search_text );
            }
            this.timeout_search_text = setTimeout( () => {
                this.load_registries( new_value );
            }, 500);
        },
        search_text( new_value ) {
            if ( this.skip ) { this.skip = false; this.clear(); this.show_options = false; return; }
            if ( new_value.length < this.min_matching_chars ) { this.clear(); this.show_options = false; return; }
            if ( this.timeout ) {
                clearTimeout( this.timeout );
            }
            this.timeout = setTimeout( () => {
                this.load_items();
            }, 500);
        },
        executed( new_value ) {
            if ( !new_value ) { return; }
            if ( this.items && this.items.length > 0 ) {
                this.show_options = true;
            } else {
                this.show_options = false;
                this.clear();
            }
        },
        executed_reg( new_value ) {
            if ( !new_value ) { return; }
            if ( !this.modals['modal-options'] ) {
                if ( this.items_reg.length > 0 ) {
                    //if ( !this.modals['modal-options'] ) {
                        this.toggle_modal('modal-options');
                    //}
                    //this.toggle_modal('modal-relations');
                } else {
                    this.$refs.add_registry_component_invisible.toggle_modal()
                }
            }
        },
    },
    methods : {
        ...mapActions( 'registry_autocomplete', { search: 'get_all', clear: 'clear' } ),
        ...mapActions( 'registry', { get_children: 'get_children' } ),
        load_registries( label = null ) {
            let params = this.mao.search_filters;
            if ( label ) {
                params.qry.label = `|${ label }|`;
            }
            //console.log( params );
            this.get_children( params );
        },
        clear_modal() {
        },
        close_list() {
        },
        item_selected( item ) {
            //this.skip        = true;
            //this.search_text = item.label;
            //this.show        = false;
            this.selected = item.id
            this.ok();
        },
        clear() {
        },
        load_items() {
            var search_options = { order: 'label ASC', options: { label: `${ this.search_text }|`, customer_id: this.customer_id, paginate: true, rows_per_page: 12 } };
            if ( this.mao.search_filters ) {
                Object.keys( this.mao.search_filters ).map( key => { search_options.options[ key ] = this.mao.search_filters[ key ]; } );
            }
            console.log( { search_options } );
            this.search( search_options );
        },
        ok() {
            let payload = { registry_id: this.selected };
            if ( this.mao.father_id ) { payload.father_id = this.mao.father_id; }
            this.$emit( 'selected', payload );
        },
        new_registry( data ) {
            this.$store.dispatch( 'preg/set_customer_id', this.customer_id );
            this.newreg_schema_name = 'preg';
            this.newreg_component   = ref_forms[ data.template.replace( /^_/, '' ) ];
            this.toggle_modal('modal-new-registry');
        },
        set_editreg_component( label ) {
            this.editreg_component = ref_forms[ label.replace( /^_/, '' ) ];
        },
        clear_modal_new_registry() {
        },
        clear_modal_edit_registry() {
        },
        toggle_modal(modal_id = 'modal-relations') {
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        submit_newreg() {
            this.$refs.newreg_form.save( {} );
        },
        submit_newreg_all() {
            let options = { save_on_registry: true };
            if ( this.mao.registry_father_id ) {
                options.registry_father_id      = this.mao.registry_father_id;
                options.registry_father_role_id = this.mao.registry_father_role_id;
            }
            this.$refs.newreg_form.save( options );
        },
        submit_editreg() {
            this.$refs.editreg_form.save( {} );
        },
        submit_editreg_all() {
            this.$refs.editreg_form.save( { save_on_registry: true } );
        },
        create_new_registry( data ) {
        },
        edit_registry( data ) {
        },
    },
    created() {},
    data() {
        return {
            mao                  : new modal_association_options(),
            search_text          : '',
            search_text_children : '',
            modals               : {},
            show_options         : false,
            skip                 : false,
            timeout              : null,
            selected             : null,
            type                 : null,
            newreg_schema_name   : null,
            newreg_component     : null,
            newreg_key           : 0,
            editreg_component    : null,
            editreg_key          : 0,
        };
    },
}

</script>

<template>

    <div>

        <!-- COMPONENT INVISIBILE
             serve ad parire direttamente il modal di nuova anagrafica
             nel caso in cui non vi siamo anagrafiche associate -->
        <add-registry
            @save      = "new_registry"
            ref        = "add_registry_component_invisible"
            :payload   = "{ 'customer_id': customer_id, 'template': mao.template }"
            :invisible = "true"
            >
            <template #button="data">
                <b-button variant="outline-secondary" @click="data.toggle_modal()" :disabled="search_text.length > 0" size="sm"><icon name="plus"/></b-button>
            </template>
        </add-registry>
        <!-- - - - - - - - - - - -->

        <!-- modal options -->
        <!-- <b-modal
            id          = "modal-options"
            :title      = "modal_options_title"
            @hide       = "clear_modal"
            @ok         = "ok"
            button-size = "sm"
            >
            <div style="width: 80%; margin: auto">
                <b-form-group v-if="items_reg && items_reg.length > 0">
                    <b-form-radio v-for="item in items_reg"  v-model="selected" :name="'related_' + item.id" :value="item.id" :key="'related_' + item.id">{{ item.label }}</b-form-radio>
                </b-form-group>
                <span v-else-if="!items_reg || items_reg.length === 0"><i>Non sono presenti anagrafiche associate.</i></span>
            </div>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <add-registry
                        @save      = "new_registry"
                        :payload   = "{ 'customer_id': customer_id, 'template': mao.template }"
                        >
                        <template #button="data">
                            <b-button variant="info" @click="data.toggle_modal()" size="sm">Nuova</b-button>
                        </template>
                    </add-registry>
                </b-col><b-col>
                    <b-button size="sm" variant="success" @click="ok()">
                        Salva
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                        Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal> -->
        <b-modal
            id          = "modal-options"
            title       = "Relazioni"
            @hide       = "clear_modal"
            @ok         = "ok"
            button-size = "sm"
            >
            <div style="width: 80%; margin: auto">
                <div class="form-group autocomplete">
                    <label for="ac-relation" class="sm">{{ field_label }}</label>
                    <b-input-group>
                        <b-input
                            autocomplete      = "off"
                            v-on:keyup.enter  = "enter_key"
                            ref               = "input"
                            type              = "text"
                            id                = "ac-relation"
                            size              = "sm"
                            v-model           = "search_text_children"
                            @blur             = "close_list"
                        />
                        <b-input-group-append>
                            <add-registry
                                @save      = "new_registry"
                                :payload   = "{ 'customer_id': customer_id, 'template': mao.template }"
                                >
                                <template #button="data">
                                    <b-button variant="outline-secondary" @click="data.toggle_modal()" :disabled="search_text.length > 0" size="sm"><icon name="plus"/></b-button>
                                </template>
                            </add-registry>
                        </b-input-group-append>
                    </b-input-group>
                    <div class="" style="height: 390px">
                        <b-list-group>
                            <b-list-group-item
                                href   = "#"
                                v-for  = "item in items_reg"
                                :key   = "item.id"
                                style  = "padding: 4px 10px;"
                                @click = "item_selected( item )"
                                >
                                {{ item.label }}
                            </b-list-group-item>
                        </b-list-group>
                    </div>
                </div>
            </div>
        </b-modal>

        <!-- modal relations -->
        <b-modal
            id          = "modal-relations"
            title       = "Relazioni"
            @hide       = "clear_modal"
            @ok         = "ok"
            button-size = "sm"
            >
            <div style="width: 80%; margin: auto">
                <div class="form-group autocomplete">
                    <label for="ac-relation" class="sm">{{ field_label }}</label>
                    <b-input-group>
                        <b-input
                            autocomplete      = "off"
                            v-on:keyup.enter  = "enter_key"
                            ref               = "input"
                            type              = "text"
                            id                = "ac-relation"
                            size              = "sm"
                            v-model           = "search_text"
                            @blur             = "close_list"
                        />
                        <b-input-group-append>
                            <add-registry
                                @save      = "new_registry"
                                :payload   = "{ 'customer_id': customer_id, 'template': mao.template }"
                                >
                                <template #button="data">
                                    <b-button variant="outline-secondary" @click="data.toggle_modal()" :disabled="search_text.length > 0" size="sm"><icon name="plus"/></b-button>
                                </template>
                            </add-registry>
                        </b-input-group-append>
                    </b-input-group>
                    <div class="" style="height: 390px">
                        <b-list-group>
                            <b-list-group-item
                                href   = "#"
                                v-for  = "item in items"
                                :key   = "item.id"
                                style  = "padding: 4px 10px;"
                                @click = "item_selected( item )"
                                >
                                {{ item.label }}
                            </b-list-group-item>
                        </b-list-group>
                    </div>
                </div>
            </div>
        </b-modal>

        <!-- modal new registry -->
        <b-modal
            id          = "modal-new-registry"
            title       = "Nuova Anagrafica"
            size        = "xl"
            @hide       = "clear_modal_new_registry"
            button-size = "sm"
            >
            <component
                v-bind:key             = "newreg_key"
                ref                    = "newreg_form"
                :is                    = "newreg_component"
                schema_from_route      = 'preg'
                :modal                 = "true"
                :additional_attributes = "{ codice_pratica: codice_pratica, role_id: mao.role_id, father_id: mao.father_id }"
                @submitted             = "create_new_registry"
            ></component>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_newreg">
                        Salva
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="warning" @click="submit_newreg_all">
                        Salva in archivio
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                        Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <!-- modal edit registry -->
        <b-modal
            id          = "modal-edit-registry"
            title       = "Modifica Anagrafica"
            size        = "xl"
            @hide       = "clear_modal_edit_registry"
            button-size = "sm"
            >
            <component
                v-bind:key             = "editreg_key"
                ref                    = "editreg_form"
                :is                    = "editreg_component"
                schema_from_route      = 'preg'
                :modal                 = "true"
                :additional_attributes = "{ codice_pratica: codice_pratica, role_id: mao.role_id, id: mao.record_id }"
                @submitted             = "edit_registry"
            ></component>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_editreg">
                        Salva
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" :disabled="!mao.registry_father_id" variant="warning" @click="submit_editreg_all">
                        Salva in archivio
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                        Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

    </div>

</template>

<style>
</style>
