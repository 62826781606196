////////////////////

const axios = require('axios');

import base_module from '@/vue-model-mirror/stores/base.module';
const config = require('@/config')[ process.env.NODE_ENV || 'development' ];
import { authHeader } from '@/helpers';

var options = {
    model_name    : 'preg',
    send_alert    : false,
    default_order : 'id ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    customer_id              : null,
    delete_executed          : false,
    update_all_executed      : null,
    create_relation_executed : null,
    incarico_updated         : null,
    cga_updated              : null,
    already_related          : false,
};

const getters = {
    ...base.getters,
    get_incarico_updated: state => { return state.incarico_updated; }
};

const actions = {
    ...base.actions,
    set_cga( { commit }, obj ) {
        commit( 'set_cga', obj );
    },
    delete_registries( { commit }, ids ) {
        var url = `${config.backend_url}model/preg/delrelation`;
        var options = { headers: authHeader() };
        axios.post( url, ids, options )
            .then( res => {
                //dispatch( 'pratica/set_preg', res.data, { root: true } );
                commit('registry_deleted');
                commit( 'pratica/success_obj', res.data, { root: true } );
            })
            .catch( error => {
                console.log( error );
            });
    },
    create_relation( { getters, commit, dispatch }, payload ) {
        var url = `${config.backend_url}model/preg/relation`;
        var options = { headers: authHeader() };
        axios.post( url, payload, options )
            .then( res => {
                if ( res.data && res.data.preg && res.data.preg.id ) {
                    setTimeout( () => {
                        commit( 'create_relation', res.data.preg.id );
                    }, 200 );
                }
                dispatch( 'pratica/set_preg', res.data, { root: true } );
            })
            .catch( error => {
                if ( error.response.status === 400 ) {
                    commit('already_related');
                }
                console.log( error );
            });
    },
    set_customer_id( { commit }, customer_id ) { commit( 'set_customer_id', customer_id ); },
    create_modal( { commit, dispatch }, payload ) {
        let options = { headers: authHeader() };
        if ( payload.payload.save_on_registry === true ) {
            var url = `${config.backend_url}model/registry`;
            let registry_payload = payload.payload;
            if ( payload.payload.registry_father_id ) {
                registry_payload.related_objs = [{
                    customer_id    : payload.payload.customer_id,
                    father_id      : payload.payload.registry_father_id,
                    father_role_id : payload.payload.registry_father_role_id,
                    child_role_id  : payload.payload.role_id,
                }];
            }
            axios.post( url, registry_payload, options )
                .then( res => {
                    payload.payload.registry_id = res.data.id;
                    var url = `${config.backend_url}model/preg`;
                    axios.post( url, payload.payload, options )
                        .then( res => {
                            dispatch( 'pratica/set_preg', res.data, { root: true } );
                        })
                        .catch( error => {
                            console.log( error );
                        });
                })
                .catch( error => {
                    console.log( error );
                });
        } else {
            var url = `${config.backend_url}model/preg`;
            axios.post( url, payload.payload, options )
                .then( res => {
                    dispatch( 'pratica/set_preg', res.data, { root: true } );
                })
                .catch( error => {
                    console.log( error );
                });
        }
    },
    create_modal_with_attachments( { commit, dispatch }, payload ) {
        let registry_payload = payload.payload;
        const form = new FormData();
        Object.keys( registry_payload ).map( x => {
            if ( x === 'attachments' ) {         // allegati
                let files = registry_payload[ x ];
                let atts = [];
                files.map( file => {
                    form.append( 'files', file.file )
                    form.append( file.file.name, file.label )
                });
            } else if ( x === 'cgas' ) {         // cgas (esclusi dal payload)
                let cgas = registry_payload[ x ];
                cgas.map( cga => {
                    form.append( 'cgas[]', cga.id );
                });
            } else {                             // altri campi
                form.append(x, registry_payload[ x ]);
            }
        });

        if ( registry_payload.registry_father_id ) {
            form.append( 'related_objs[]', JSON.stringify({
                customer_id    : registry_payload.customer_id,
                father_id      : registry_payload.registry_father_id,
                father_role_id : registry_payload.registry_father_role_id,
                child_role_id  : registry_payload.role_id,
            }));
        }
        var options = {
            url     : null,
            headers : authHeader(),
            method  : 'post',
            data    : form,
        };
        options.headers['Content-Type'] = 'multipart/form-data';
        if ( registry_payload.save_on_registry === true ) {
            var url = `${config.backend_url}model/registry`;
            options.url = url;
            axios( options )
                .then( res => {
                    options.data.append( 'registry_id', res.data.id );
                    options.url = `${config.backend_url}model/preg`;
                    axios( options )
                        .then( res => {
                            dispatch( 'pratica/set_preg', res.data, { root: true } );
                        })
                        .catch( error => {
                            console.log( error );
                        });
                })
                .catch( error => {
                    console.log( error );
                });
        } else {
            var url = `${config.backend_url}model/preg`;
            options.url = url;
            //axios.post( url, registry_payload, options )
            axios( options )
                .then( res => {
                    dispatch( 'pratica/set_preg', res.data, { root: true } );
                })
                .catch( error => {
                    console.log( error );
                });
        }
    },
    async update_incarico( { commit, dispatch }, payload ) {
        let id = payload.id;
        let options = { headers: authHeader() };
        var url = `${config.backend_url}model/pratica_incarico/${ id }`;
        try {
            const res = await axios.patch( url, payload.data, options );
            
            if ( res.full === true ) {
                delete res.full;
                commit( 'pratica/success_obj', res, { root: true } );
             } else {
                 dispatch( 'pratica/set_incarico', res.data, { root: true } );
             }
             commit( 'incarico_updated' );
             
        } catch (error) {
            console.log( error );
            return false;
        }
        return true;
    },
    update_modal( { commit, dispatch, state }, payload ) {
        let options = { headers: authHeader() };
        if ( payload.payload.save_on_registry === true ) {
            var url = `${config.backend_url}model/preg/${ state.obj.id }`;
            axios.patch( url, payload.payload, options )
                .then( res => {
                    if ( !res.data.preg.registry_id ) {
                        dispatch( 'pratica/set_preg', res.data, { root: true } );
                        return;
                    }
                    var url = `${config.backend_url}model/registry/${ res.data.preg.registry_id }`;
                    axios.patch( url, payload.payload, options )
                        .then( res_reg => {
                            dispatch( 'pratica/set_preg', res.data, { root: true } );
                        })
                        .catch( error => {
                            console.log( error );
                        });
                })
                .catch( error => {
                    console.log( error );
                });
        } else {
            var url = `${config.backend_url}model/preg/${ state.obj.id }`;
            axios.patch( url, payload.payload, options )
                .then( res => {
                    dispatch( 'pratica/set_preg', res.data, { root: true } );
                })
                .catch( error => {
                    console.log( error );
                });
        }
    },
    update_all( { commit, dispatch, state }, data ) {
        let options = { headers: authHeader() };
        var url = `${config.backend_url}model/preg/updateall/${ data.codice_pratica }`;
        axios.patch( url, data.payload, options )
            .then( res => {
                dispatch( 'pratica/set_pregs', res.data, { root: true } );
                commit( 'update_partial_done', Object.keys( res.data ) );
            })
            .catch( error => {
                console.log( error );
            });
    },
};

const mutations = {
    ...base.mutations,
    set_customer_id( state, customer_id ) { state.customer_id = customer_id; },
    set_cga( state, obj ) {
        state.obj.cgas = obj.cgas;
        //state.prareg   = new pratica_registries( state.obj );
        state.cga_updated = true;
        setTimeout( () => {
            state.cga_updated = null;
        }, 200);
    },
    registry_deleted( state ) {
        state.delete_executed = true;
        setTimeout( () => {
            state.delete_executed = false;
        }, 200 );
    },
    update_partial_done( state, labels ) {
        state.update_all_executed = labels;
        setTimeout( () => {
            state.update_all_executed = null;
        }, 250 );
    },
    create_relation( state, new_id ) {
        state.create_relation_executed = new_id;
        setTimeout( () => { state.create_relation_executed = null; }, 200 );
    },
    incarico_updated( state ) {
        state.incarico_updated = true;
        setTimeout( () => { state.incarico_updated = null; }, 200 );
    },
    already_related( state ) {
        state.already_related = true;
        setTimeout( () => { state.already_related = null; }, 200 );
    },
};

export default {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
};

