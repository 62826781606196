<script>

const config  = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export default {
    name: 'pratica-cardleft',
    props: [ 'element', ],
    components: {
    },
    watch: {
    },
    created() {
    },
    computed: {
        backend_url() { return config.backend_url },
    },
    methods: {
        edit_description( obj ) {
            this.$emit( 'edit-description', obj );
        },
    },
    data() {
        return {
        }
    },
};

</script>

<template>

   <div>
       <b-img v-if="element.task_image.value.thumb_url" center thumbnail fluid :src="backend_url + element.task_image.value.thumb_url.replace(/^\//, '')"></b-img>
       <b-img v-else-if="element.task_image.value.thumb_url === null" center thumbnail fluid :src="backend_url + 'icons/no-image.png'"></b-img>
       <figcaption class="figure-caption text-center">
           <a href="#" @click="edit_description( element.task_image )">
               {{ element.task_image.value.description || 'descrizione assente' }}
           </a>
       </figcaption>
       <div v-if="element.task_image.value.thumb_url === null">
       </div>
   </div>

</template>



