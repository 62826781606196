/////////////////////////////////////////////////

const moment = require('moment');

class CodFisc {

    constructor( payload = {} ) {
        Object.keys( payload ).map( key => {
            this[ key ] = payload[ key ];
        });
        this.mese_code    = [ 'A', 'B', 'C', 'D', 'E', 'H', 'L', 'M', 'P', 'R', 'S', 'T' ];
        this.char_pari    = {
            A: 0, '0': 0, B: 1, '1': 1, C: 2, '2': 2, D: 3, '3': 3, E: 4, '4': 4, F: 5, '5': 5, G: 6, '6': 6, H: 7, '7': 7, I: 8, '8': 8, J: 9, '9': 9,
            K: 10, L: 11, M: 12, N: 13, O: 14, P: 15, Q: 16, R: 17, S: 18, T: 19, U: 20, V: 21, W: 22, X: 23, Y: 24, Z: 25
        };
        this.char_dispari = {
            A: 1, '0': 1, B: 0, '1': 0, C: 5, '2': 5, D: 7, '3': 7, E: 9, '4': 9, F: 13, '5': 13, G: 15, '6': 15, H: 17, '7': 17, I: 19, '8': 19, J: 21,
            '9': 21, K: 2, L: 4, M: 18, N: 20, O: 11, P: 3, Q: 6, R: 8, S: 12, T: 14, U: 16, V: 10, W: 22, X: 25, Y: 24, Z: 23
        };
        this.char_controlo = [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z' ];
    }

    // static public methods ////////////////////

    static get_codfisc( payload ) {
        var obj = new CodFisc( payload );
        return obj._get_codfisc();
    }

    static parse_codfisc( codfisc ) {
        var obj = new CodFisc( { codfisc } );
        return obj._parse_codfisc();
    }

    static validate( codfisc ) {
        var obj = new CodFisc( { codfisc } );
        return obj._validate();
    }

    // private methods // validate codfisc //////

    _validate() {
        var match = this.codfisc.match( /^[a-z]{6}\d\d[a-z]\d\d[a-z]\d\d\d[a-z]$/i );
        return match ? true : false;
    }

    // private methods // from codfisc to data //

    _parse_codfisc() {
        if ( this._validate() === false ) { throw `codice fiscale non valido: ${ this.codfisc }`; }
        var obj = { data_nascita: null, codice_comune: null, sesso: null };
        obj.data_nascita  = this._get_data_nascita();
        obj.codice_comune = this._get_codice_comune();
        obj.sesso         = this._get_sesso();
        return obj;
    }

    _get_sesso() {
        var match = this.codfisc.match( /^.........(..).....$/ );
        if ( !match ) { throw `codice fiscale non valido: ${ this.codfisc }`; }
        var giorno_raw = parseInt( match[1] );
        return giorno_raw > 40 ? 'Femmina' : 'Maschio';
    }

    _get_codice_comune() {
        var match = this.codfisc.match( /^...........(....).$/ );
        if ( !match ) { throw `codice fiscale non valido: ${ this.codfisc }`; }
        return match[1];
    }

    _get_data_nascita() {
        var match = this.codfisc.match( /^......(.....).....$/ );
        if ( !match ) { throw `codice fiscale non valido: ${ this.codfisc }`; }
        var string = match[1];
        var match = string.match( /^(\d\d)([a-z])(\d\d)$/i );
        if ( !match ) { throw `codice fiscale non valido: ${ this.codfisc }`; }
        var anno_raw   = match[1];
        var mese_raw   = match[2];
        var giorno_raw = parseInt( match[3] );
        var giorno = giorno_raw > 40 ? giorno_raw - 40 : giorno_raw;
        var anno = this._get_anno( anno_raw );
        var mese = this.mese_code.indexOf( mese_raw ) < 10 ? `0${ this.mese_code.indexOf( mese_raw ) + 1 }` : this.mese_code.indexOf( mese_raw ) + 1;
        return `${ anno }-${ mese }-${ giorno < 10 ? `0${ giorno }` : String( giorno ) }`;
    }

    _get_anno( anno_raw ) {
        var actual_year = moment().format('YY');
        if ( parseInt( anno_raw ) > actual_year - 1 ) { return `19${ anno_raw }` }
        if ( moment().format('YYYY') - parseInt( `19${ anno_raw }` ) > 89 ) {
            return `20${ anno_raw }`;
        } else {
            return `19${ anno_raw }`;
        }
    }

    // private methods // from data to codfisc //

    _get_codfisc() {
        this._validate_payload_get_cf();
        return this._calc_codfisc();
    }

    _calc_codfisc() {
        var codfisc = '';
        if ( this.cognome.length < 3 ) { this.cognome += 'x'; }
        if ( this.nome.length < 3 ) { this.nome += 'x'; }
        var objnome = this._get_stringobj( this.nome );
        var objcognome = this._get_stringobj( this.cognome );
        // nome
        codfisc += this._get_string_cognome( objcognome );
        // cognome
        codfisc += this._get_string_nome( objnome );
        // anno
        codfisc += this._get_string_anno();
        // mese
        codfisc += this._get_string_mese();
        // giorno
        codfisc += this._get_string_giorno();
        // codice comune
        codfisc += this.codice_comune;
        // carattere di controllo
        codfisc += this._get_control_char( codfisc );
        return codfisc.toUpperCase();
    }

    _get_control_char( string ) {
        var total = 0;
        for ( var i = 1; i < string.length + 1; i++ ) {
            if ( i % 2 == 0 ) { total += this.char_pari[ String( string.charAt( i - 1 ) ).toUpperCase() ]; } // pari
            else { total += this.char_dispari[ String( string.charAt( i - 1 ) ).toUpperCase() ]; } // dispari
        }
        var resto = total % 26;
        return this.char_controlo[ resto ];
    }

    _get_string_giorno() {
        var giorno = null;
        var match = this.data_nascita.match( /^(\d\d)[\/|\-]\d\d[\/|\-]\d\d\d\d$/ );
        if ( match ) { giorno = parseInt( match[1] ); }
        var match = this.data_nascita.match( /^\d\d\d\d[\/|\-]\d\d[\/|\-](\d\d)$/ );
        if ( match ) { giorno = parseInt( match[1] ); }
        if ( !giorno ) { throw `giorno - caso non gestito: ${ this.data_nascita }`; }
        if ( this.sesso === 'Femmina' ) { giorno += 40; }
        return giorno < 10 ? `0${ giorno }` : String( giorno );
    }

    _get_string_mese() {
        var mese = null;
        var match = this.data_nascita.match( /^\d\d[\/|\-](\d\d)[\/|\-]\d\d\d\d$/ );
        if ( match ) { mese = String( match[1] ); }
        var match = this.data_nascita.match( /^\d\d\d\d[\/|\-](\d\d)[\/\-]\d\d$/ );
        if ( match ) { mese = String( match[1] ); }
        if ( !mese ) { throw `mese - caso non gestito: ${ this.data_nascita }` }
        var mese_number = parseInt( mese );
        return this.mese_code[ mese - 1 ];
    }

    _get_string_anno() { 
        var match = this.data_nascita.match( /^\d\d[\/|\-]\d\d[\/|\-]\d\d(\d\d)$/ );
        if ( match ) { return String( match[1] ); }
        var match = this.data_nascita.match( /^\d\d(\d\d)[\/|\-]\d\d[\/|\-]\d\d$/ );
        if ( match ) { return String( match[1] ); }
        throw `anno - caso non gestito: ${ this.data_nascita }`;
    }

    _get_string_nome( obj ) {
        if ( obj.consonanti.length > 3 ) { return `${ obj.consonanti[0] }${ obj.consonanti[2] }${ obj.consonanti[3] }`;  }
        if ( obj.consonanti.length === 3 ) { return `${ obj.consonanti[0] }${ obj.consonanti[1] }${ obj.consonanti[2] }`;  }
        if ( obj.consonanti.length === 2 && obj.vocali.length > 0 ) { return `${ obj.consonanti[0] }${ obj.consonanti[1] }${ obj.vocali[0] }`;  }
        if ( obj.consonanti.length === 2 && obj.vocali.length > 1 ) { return `${ obj.consonanti[0] }${ obj.vocali[0] }${ obj.vocali[1] }`;  }
        throw `nome - caso non gestito: consonanti: ${ obj.consonanti.length }, vocali: ${ obj.vocali.length }`;
    }

    _get_string_cognome( obj ) {
        if ( obj.consonanti.length > 2 ) { return `${ obj.consonanti[0] }${ obj.consonanti[1] }${ obj.consonanti[2] }`;  }
        if ( obj.consonanti.length === 3 && obj.vocali.length > 0 ) { return `${ obj.consonanti[0] }${ obj.consonanti[1] }${ obj.vocali[0] }`;  }
        if ( obj.consonanti.length === 2 && obj.vocali.length > 1 ) { return `${ obj.consonanti[0] }${ obj.vocali[0] }${ obj.vocali[1] }`;  }
        throw `cognome - caso non gestito: consonanti: ${ obj.consonanti.length }, vocali: ${ obj.vocali.length }`;
    }

    _get_stringobj( _string ) {
        var string = _string.replace( /[ '\-]/g )
                            .replace( /à/g, 'a' )
                            .replace( /[èé]/g, 'u' )
                            .replace( /ì/g, 'i' )
                            .replace( /ò/g, 'o' )
                            .replace( /ù/g, 'u' );
        var vocali = [];
        var consonanti = [];
        for ( var i = 0; i < string.length; i++ ) { 
            if ( string.charAt( i ).match( /[aeiou]/i ) ) { vocali.push( string.charAt( i ) ); }
            else { consonanti.push( string.charAt( i ) ); }
        }
        return { vocali, consonanti };
    }

    _validate_payload_get_cf() {
        if ( typeof this.nome === 'string' && this.nome.length > 0 &&
             typeof this.cognome === 'string' && this.cognome.length > 0 &&
             typeof this.data_nascita === 'string' && this.data_nascita.length > 0 &&
             typeof this.sesso === 'string' && this.sesso.length > 0 &&
             typeof this.codice_comune === 'string' && this.codice_comune.length > 0
            ) {
            return true;
        }
        throw 'dati non validi';
    }

}

//module.exports = CodFisc;
export default CodFisc;

