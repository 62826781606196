<script>

import Vue     from 'vue';

import bnvcard from './card';
import bnvform from '@/vue-model-mirror/bnvform';
import list    from '@/vue-model-mirror/listpage';
import footer  from '../layout/components/footer';

const options = require('@/listconfig');

Vue.component( 'bnv-footer', footer );

export default {
    extends: list,
    components: {
        bnvcard,
        bnvform,
    },
    created () {
        this.schema_name = this.$route.params.schema || this.schema_from_route;
        this.options = options[ this.schema_name ];
        this.options.initialfilter = {
            'task.user_id'      : this.user.id,
            'task.task_label'   : 'ispezione',
            'newstate'          : 2000,
            'show_mine'         : true,
        };
        this.$store.dispatch(`${ this.schema_name }/clear`);
        if ( this.options.hasOwnProperty('order') && this.order === null ) { this.$store.dispatch(`${ this.schema_name }/set_order`, this.options.order); }
    },
    methods: {
        operation_success: function() {
            var data = this.$refs.search_form.get_form_data();
            Object.keys( data ).map( x => {
                if ( data[ x ] === null ) {
                    this.$refs.cardbnv.remove_qry_filter( x );
                    delete this.modal_filters[ x ];
                } else {
                    var filter = {};
                    filter[ x ] = data[ x ];
                    this.$refs.cardbnv.add_qry_filters( filter );
                    this.modal_filters[ x ] = data[ x ];
                }
            });
            this.$refs.cardbnv.page = 1;
        },
    },
    computed: {
        user () {
            return JSON.parse( localStorage.getItem('user') );
        },
    },
    data () {
        return {
            form_reload   : 0,
            inline        : false,
            modal_filters : {}
        }
    },
};

</script>

<template>

    <div style="width: 100%; margin: auto; margin-bottom: 45px;">
        <!-- <b-button v-b-modal.modal-filter>Filtra</b-button> -->
        <b-modal
            id="modal-filter"
            title="filtri"
            @ok="operation_success"
            hide-header-close
            size="sm"
            button-size="sm"
            >
            <bnvform
                v-if       = "search_schema"
                :name      = "search_schema"
                :schema    = "schema"
                :options   = "form_config"
                :record    = "modal_filters"
                :submit    = "false"
                :key       = "form_reload"
                :inline    = "inline"
                ref        = "search_form"
                @reset     = "reset_form"
                @submitted = "operation_success"
            ></bnvform>
        </b-modal>
        <div class="text-right" style="margin-bottom: 10px" v-if="add">
            <button type="button" @click="new_element();" class="btn btn-outline-info btn-sm">Nuovo</button>
        </div>
        <bnvcard
            :schema        = "schema_name"
            storename      = "bnvform"
            :skip          = "skip"
            :fields        = "fields"
            :primarykey    = "primary_key"
            :qryoptions    = "qry_options"
            :details       = "details"
            :detailsclass  = "details_class"
            :edit          = "edit"
            :paginate      = "paginate"
            link           = "/form/ispezione"
            :rowclass      = "rowclass"
            :linkfunction  = "linkfunction"
            :initialfilter = "initialfilter"
            ref            = "cardbnv"
        ></bnvcard>
        <bnv-footer></bnv-footer>
    </div>

</template>



