<script>

import { mapActions, mapState/*, mapGetters*/ } from 'vuex';

import googleaddress_adv from '@/vue-model-mirror/bnvform/components/bnvgoogleaddress_adv'
import Address from '@/registry/classes/address';

export default {
    name: 'appuntamento-luogo',
    props: [ 'item' ],
    components : { autocomplete: googleaddress_adv },
    computed : {
        incarico() {
            return this.item.pregs.incarico;
        },
        luogo_appuntamento() {
            return this.item.pregs.incarico.luogo_appuntamento || '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
        },
        ...mapState('pratica_incarico', {
            saved        : state => state.executed,
            new_incarico : state => state.obj
        }),
        ...mapState('comune_residenza', {
            loaded_appuntamento : state => state.loaded_appuntamento,
        }),
    },
    watch: {
        saved( new_value ) {
            if ( this.edit === false || new_value != true ) { return; }
            this.edit = false;
            this.reset_executed();
            this.override_incarico( this.new_incarico );
            //this.is_valid = true;
            //setTimeout( () => { this.is_valid = false }, 3000 );
            this.edit = false;
        },
        loaded_appuntamento( new_value ) {
            if ( new_value === false || this.edit === false ) { return; }
            this.appuntamento.comune_id = this.$store.state.comune_residenza.obj.id;
            this.save();
        },
    },
    methods : {
        ...mapActions( 'pratica_incarico', { update_address: 'update', reset_executed: 'executed' } ),
        ...mapActions( 'appuntamento', { override_incarico: 'override_incarico' } ),
        save() {
            let data = {};
            data.strada_luogo_appuntamento       = this.appuntamento.strada || null;
            data.comune_luogo_appuntamento_id    = this.appuntamento.comune_id || null;
            data.n_civ_luogo_appuntamento        = this.appuntamento.n_civ || null;
            data.cap_luogo_appuntamento          = this.appuntamento.cap || null;
            data.nazione_luogo_appuntamento      = this.appuntamento.nazione || null;
            data.latitudine_luogo_appuntamento   = this.appuntamento.latitudine || null;
            data.longitudine_luogo_appuntamento  = this.appuntamento.longitudine || null;
            data.citta_estero_luogo_appuntamento = this.appuntamento.citta_estero || null;
            data.altro_luogo_appuntamento        = this.appuntamento.altro || null;

            this.update_address( { id: this.item.pregs.incarico.id, payload: data } );
        },
        value_changed( payload ) {
            if ( payload.name === 'luogo_appuntamento' ) {
                this.appuntamento['$store'] = this.$store;
                this.appuntamento.set_google_payload( payload );
            }
        },
        generic_event( data ) {},
        submit_form() {},
        edit_field() {
            this.edit = true;
            setTimeout( () => {
                this.$refs.autocomplete._placeholder = this.luogo_appuntamento;
                this.$refs.autocomplete.unlock();
            }, 500);
        },
        blur() {
            this.edit = false;
        },
    },
    created() { this.note = this.incarico_note + ''; },
    data() {
        return {
            edit         : false,
            input_key    : 0,
            element      : googleaddress_adv,
            appuntamento : new Address('luogo_appuntamento'),
        }
    },
}

</script>

<template>

    <div>

        <div v-if="edit" style="font-size: 0.8em;" @click.stop @dblclick.stop>
    
            <component
                v-bind:key     = "input_key"
                name           = "luogo_appuntamento"
                ref            = "autocomplete"
                :disabled      = "false"
                :config        = "{}"
                :is            = "element"
                :value         = "incarico.luogo_appuntamento"
                label          = ""
                :edit          = "true"
                :readonly      = "false"
                :display_label = "false"
                :display_button_modal = "false"
                @change        = "value_changed"
                @enter_key     = "submit_form"
                @generic-event = "generic_event"
                @blur          = "blur"
            ></component>
        </div>

        <div v-if="!edit" @dblclick.stop="edit_field" @click.stop style="cursor: pointer;" v-html="luogo_appuntamento" />

    </div>

</template>

<style>
</style>

