<script>

import Vue from 'vue';
Vue.use( require('vue-filter-pretty-bytes') );
import VueContext from 'vue-context';
const moment = require('moment-timezone');

import bnvlist from '@/vue-model-mirror/bnvlist';
import bnvform from '@/vue-model-mirror/bnvform';
const schemas = require('@/schemaconfig');
const config  = require('@/config.js')[ process.env.NODE_ENV || 'development' ];
const options = require('@/listconfig');

export default {
    name: 'filesmoving',
    components: {
        bnvlist,
        bnvform,
        VueContext,
    },
    props: [ 'codice_pratica', 'items', ],
    mounted () {
    },
    watch: {
    },
    computed: {
        fields () {        // default: undefined
            if ( this.options.hasOwnProperty('fields') ) {
                return this.options.fields.map( column_field => {
                    if ( column_field.hasOwnProperty('class') === false ) {
                        column_field['class'] = [];
                    } else if ( typeof column_field['class'] === 'string' ) {
                        column_field['class'] = [ column_field['class'] ];
                    }
                    if ( column_field.hasOwnProperty( 'screen' ) ) {
                        var classes = [];
                        var screen_classes = this._get_column_class( column_field.screen );
                        for ( var i = 0; i < screen_classes.length; i++ ) {
                            var class_string = typeof column_field['class'][ i ] === 'string' ? column_field['class'][ i ] : '';
                            classes.push( class_string + ' ' + screen_classes[ i ]  );
                        }
                        column_field['class'] = classes;
                        delete column_field.screen;
                    }
                    return column_field;
                });
            }
            return undefined;
        },
        order: {
            get: function() {
                return this.$store.state[ this.schema ].order;
            },
            set: function(value) {
                this.$store.dispatch(`${this.schema}/set_order`, value);
            }
        },
        qry_options () {
            var options = {};
            if ( this.options.hasOwnProperty('include') ) { options.include = this.options.include; }
            if ( this.options.hasOwnProperty('initialfilter') ) {
                Object.keys( this.options.initialfilter ).map( x => { options[ x ] = this.options.initialfilter[ x ]; } );
            }
            return options;
        },
        initialfilter () {
            return this.options.hasOwnProperty('initialfilter') ? this.options.initialfilter : {};
        },
    },
    created () {
        this.items.map( x => { if ( x.task_image_id === null ) { this.forbidden_folders.push( x.id ); } } );
        this.source_folder_id = this.items[0].parentId;
        this.options = options[ this.schema_name ];
        this.options.initialfilter = {
            codice_pratica : this.codice_pratica,
            parentId       : this.parent_id,
        };
        if ( this.options.hasOwnProperty('order') && this.order === null ) { this.$store.dispatch(`${ this.schema_name }/set_order`, this.options.order); }
        var data = {
            options: {
                codice_pratica: this.codice_pratica,
                parentId: null,
                include : true,
            }
        }
        this.$store.dispatch('move_folder/clear', data);
    },
    methods: {
        goto_folder( folder_id, folder_name ) {
            if ( this.locked === false ) {
                if ( this.forbidden_folders.includes( folder_id ) ) {
                    this.$bvModal.msgBoxOk(`la cartella ${ folder_name } è stata selezionata per lo spostamento`, {
                        title       : 'Percorso illegale',
                        size        : 'sm',
                        buttonSize  : 'sm',
                        okVariant   : 'success',
                        headerClass : 'p-2 border-bottom-0',
                        footerClass : 'p-2 border-top-0',
                        centered    : true
                    });
                } else {
                    this.previous_folder_ids.push( { 'value': folder_id, text: String(folder_name) } );
                    this.parent_id = folder_id;
                    var data = {
                        options: {
                            codice_pratica : this.codice_pratica,
                            parentId       : folder_id,
                            include        : true,
                            order          : 'name ASC'
                        },
                    };
                    this.$store.dispatch('move_folder/get_all', data);
                }
            }
        },
        get_link( image_id ) {
            this.$store.dispatch( 'task/get_link', image_id )
        },
        move_to_folder( data ) {
            var new_folders = [];
            for ( var i = 0; i < this.previous_folder_ids.length; i++ ) {
               var bc = this.previous_folder_ids[ i ];
               if ( bc.text === data.srcElement.innerText ) {
                   this.goto_folder( bc.value, bc.text );
                   this.previous_folder_ids = new_folders;
               }
               new_folders.push( bc );
            }
        },
        _get_column_class( screen ) {
            if ( screen === 'mobile' )  { return ['', '', 'd-none']; }
            if ( screen === 'tablet' )  { return ['', 'd-none d-md-table-cell', 'd-md-none']; }
            if ( screen === 'desktop' ) { return ['', 'd-none d-lg-table-cell', 'd-lg-none']; }
            return '';
        },
        move_elements( id ) {
            console.log( id );
            var elements = this.items.map( x => x.id );
            var target_id = id;
            //console.log( `spostare ${ elements.length } elementi nella cartella ${ target_id }` );
            this.$store.dispatch('folder/move', { elements, target_id, source_id: this.source_folder_id, codice_pratica: parseInt( this.codice_pratica ) });
            this.$emit('close-moving');
        }
    },
    data () {
        return {
            schema_name         : 'move_folder',
            schema              : 'move_folder',
            locked              : false,
            new_folder_name     : '',
            new_folder_state    : null,
            new_folder_error    : null,
            file                : null,
            upload_state        : null,
            upload_error        : null,
            parent_id           : null,
            previous_folder_ids : [ { text: String( this.codice_pratica ), 'value': null } ],
            modal_context_form  : { show: false },
            modal_filter_key    : 0,
            items_selected      : [],
            moving              : false,
            source_folder_id    : null,
            forbidden_folders   : [],
        }
    },
};

</script>

<template>

    <div>
    
        <b-container fluid="xs">

            <b-row style="margin-top: 10px;">
                <b-col style="margin: 0; padding: 0;">
                    <template>
                        <b-breadcrumb :items="previous_folder_ids" @click="move_to_folder"></b-breadcrumb>
                    </template>
                </b-col>
            </b-row>

            <div class="text-center" style="margin-bottom: 10px;">Seleziona la cartella di destinazione</div>

            <bnvlist
                schema           = "move_folder"
                storename        = "bnvform"
                :tableattributes = "{ 'no-local-sorting': true, 'no-sort-reset': true, 'responsive': true, 'small': true, 'borderless': false, 'select-mode': 'single', 'selectable': false, 'dark': false, 'table-variant': 'warning', 'caption-top': true, }"
                size             = "sm"
                :link            = "null"
                :skip            = "[]"
                :fields          = "fields"
                primarykey       = "id"
                :qryoptions      = "qry_options"
                :details         = "false"
                :edit            = "false"
                :paginate        = "false"
                :initialfilter   = "initialfilter"
                ref              = "listbnv"
                @goto_folder     = "goto_folder"
                @get_link        = "get_link"
            ></bnvlist>

            <div class="text-center" @click="move_elements( previous_folder_ids[ previous_folder_ids.length - 1 ].value )">sposta {{ items.length }} {{ items.length > 1 ? 'elementi' : 'elemento'}} nella cartella<br/><b-button size="sm" :disabled="previous_folder_ids[ previous_folder_ids.length - 1 ].value === this.source_folder_id" variant="success">{{ previous_folder_ids[ previous_folder_ids.length - 1 ].text }}</b-button></div>

        </b-container>

    </div>

</template>

<style>
    .breadcrumb {
        padding: 2px 15px !important;
    }
    .locked {
        opacity: 0.5;
    }
    .link-locked {
        cursor: default;
    }
    .table th {
        border-top: 0px !important;
    }
    .table {
        width: 100% !important;
    }
</style>
