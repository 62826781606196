////////////////////

import { authHeader } from '@/helpers';

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export const task_service = {
    get_by_id,
    get_all,
    //create,
    createws,
    update,
    updatemeta,
    deleteimage,
    get_link,
//  upload_ispezione_file,
};

function get_link( image_id ) {
    var url = `${config.backend_url}task/image/${image_id}/`;
    var options = {
        method  : 'GET',
        headers : authHeader(),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function deleteimage( schema, meta ) {
    var url = `${config.backend_url}model/${schema}/${meta.task_id}/meta/${meta.id}/`;
    var options = {
        method  : 'DELETE',
        headers : authHeader(),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function get_all( schema, data = null ) {
    var url = `${config.backend_url}model/${schema}`;
    if ( data ) {
        var params = [];
        Object.keys( data ).map( x => {
            var data_encoded = encodeURIComponent( data[ x ] );
            params.push( `${x}=${ data_encoded }` );
        });
        if ( params.length > 0 ) {
            url += '?' + params.join('&');
        }
    }
    var options = {
        method  : 'GET',
        headers : authHeader(),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function get_by_id( schema, id, include = null ) {
    var url = `${config.backend_url}model/${schema}/${id}`;
    if ( include ) { url += '?include=true'; }
    var options = {
        method  : 'GET',
        headers : authHeader(),
    };
    return fetch(`${config.backend_url}model/${schema}/${id}?include=true`, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function update( schema, id, data, include ) {
    var url = `${config.backend_url}model/${schema}/${id}`;
    if ( include ) { url += `?include=true` }
    var payload = {};
    Object.keys( data ).map( x => {
        payload[ x ] = data[ x ];
    });
    var options = {
        method  : 'PUT',
        headers : authHeader(),
        body    : JSON.stringify( payload ),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function updatemeta( schema, id, data, include ) {
    var url = `${config.backend_url}model/${schema}/${id}/meta`;
    if ( include ) { url += `?include=true` }
    var payload = {};
    Object.keys( data ).map( x => {
        payload[ x ] = data[ x ];
    });
    var options = {
        method  : 'PUT',
        headers : authHeader(),
        body    : JSON.stringify( payload ),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

//function upload_ispezione_file(data) {
//    var { container, payload } = data;
//    var url = `${config.backend_url}model/folder/ispezione/${ container }`;
//    if ( data.include ) { url += `?include=true` }
//    var headers = authHeader();
//    delete headers['Content-Type'];
//    const form = new FormData();
//    Object.keys( payload ).map( x => {
//        form.append(x, payload[ x ]);
//    });
//    var options = {
//        method : 'POST',
//        body   : form,
//        headers,
//    };
//    return fetch(url, options)
//        .then(handleResponse)
//        .then(msg => {
//            return msg;
//        });
//}

function createws( schema, data, include = false) {
    var url = `${config.backend_url}model/${schema}/${data.task_id}/meta/ws`;
    if ( include ) { url += `?include=true` }
    var payload = {};
    const form = new FormData();
    Object.keys( data ).map( x => {
        form.append(x, data[ x ]);
    });
    var options = {
        method  : 'POST',
        //headers : { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json', },
        body    : form,
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = data || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}


