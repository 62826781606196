<script>

const nprogress = require('nprogress');

import bnvform from '@/vue-model-mirror/bnvform';
const schemas = require('@/schemaconfig');
const config  = require('@/config.js')[ process.env.NODE_ENV || 'development' ];

export default {
    name: 'pratica-form',
    components: {
        bnvform,
    },
    props: [ 'task', ],
    mounted () {
    },
    watch: {
    },
    computed: {
        pratica_loading () {
            return this.$store.state.pratica.loading_by_id;
        },
        pra () {
            return this.$store.state.pratica.obj
        },
    },
    created () {
        this.dp_schema = schemas.ispezione_dp;
        this.as_schema = schemas.ispezione_as;
        this.co_schema = schemas.ispezione_co;
        this.$store.dispatch( 'pratica/get_by_id', { id: this.task.codice_pratica, include: true, } );
    },
    methods: {
        main_submit() {
            var data = {};
            var forms = [ /*'dp_form',*/ 'as_form', /*'co_form',*/ ];
            for ( var i = 0; i < forms.length; i++ ) {
                var form_ref = forms[ i ];
                //data[ form_ref ] = this.$refs[ form_ref ].get_form_data();
                //console.log( data[ form_ref ] );
                data[ form_ref ] = this.$refs[ form_ref ].submit_form();
                //if ( typeof data[ form_ref ] === 'undefined' ) { error = true; break; }
            }
        }
    },
    data () {
        return {
            dp_key    : 0,
            dp_schema : null,
            as_key    : 1,
            as_schema : null,
            co_key    : 2,
            co_schema : null,
        }
    },
};

</script>

<template>

    <b-container v-if="!pratica_loading" fluid="sm" style="margin-bottom: 120px; margin-top: 20px;">
        <!-- incarico -->
        <!-- <div style="background-color: #E8E8E8; padding: 5px; margin-bottom: 20px; border-radius: 5px;">
            <h6 style="margin-bottom: 0px; padding-bottom: 0px; font-weight: 700;" class="text-secondary">DATI</h6>
            <bnvform
                :key       = "dp_key"
                name       = "pratica_incarico"
                :schema    = "dp_schema"
                :options   = "{}"
                :record    = "pra.pregs.incarico"
                :submit    = "true"
                :inline    = "true"
                ref        = "dp_form"
                layout     = "single"
            ></bnvform>
        </div> -->
        <!-- assicurato -->
        <div style="background-color: #E8E8E8; padding: 5px; margin-bottom: 20px; border-radius: 5px;">
            <h6 style="margin-bottom: 0px; padding-bottom: 0px; font-weight: 700;" class="text-secondary">ASSICURATO</h6>
            <bnvform
                :key       = "as_key"
                name       = "preg"
                :schema    = "as_schema"
                :options   = "{}"
                :record    = "pra.pregs.assicurato"
                :submit    = "true"
                :inline    = "true"
                ref        = "as_form"
                layout     = "single"
            ></bnvform>
        </div>
        <!-- controparte -->
        <!-- <div style="background-color: #E8E8E8; padding: 5px; margin-bottom: 20px; border-radius: 5px;">
            <h6 style="margin-bottom: 0px; padding-bottom: 0px; font-weight: 700;" class="text-secondary">CONTROPARTE</h6>
            <bnvform
                :key       = "co_key"
                :name      = "'controparte'"
                :schema    = "co_schema"
                :options   = "{}"
                :record    = "pra.controparte"
                :submit    = "true"
                :inline    = "true"
                ref        = "co_form"
                layout     = "single"
            ></bnvform>
        </div> -->
        <b-row><b-col>
            <b-button size="sm" variant="success" @click="main_submit">
              Salva
            </b-button>
        </b-col></b-row>
    </b-container>

    <b-container v-else-if="pratica_loading" fluid="sm" style="margin-bottom: 70px;">
        <div class="text-center">
            <div class="spinner-grow text-dark" role="status" style="width: 6rem; height: 6rem;">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </b-container>

</template>

