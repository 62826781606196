//////////////////////////////

module.exports = (type) => {
    var schema = {
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        user_id: {
            type: type.INTEGER,
            allowNull: true,
            defaultValue: null,
            references: {
                model       : 'users',
                key         : 'id',
                constraints : true,
                store_name  : 'user',
            }
        },
        custodian_id: {
            type: type.INTEGER,
            allowNull: true,
            defaultValue: null,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        parcel_mode: {
            type: type.STRING(64),
            allowNull: false,
        },
        task_label: {
            type: type.STRING(128),
            defaultValue: null,
        },
        percentage: {
            type: type.DECIMAL(10,2),
            defaultValue: 0.00,
        },
        minimum: {
            type: type.DECIMAL(10,2),
            defaultValue: 0.00,
        },
    };

    return schema;
}

