////////////////////

import axios from 'axios';
import base_module from '@/vue-model-mirror/stores/base.module';
import { authHeader } from '@/helpers';
const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

var options = {
    model_name    : 'comune',
    send_alert    : false,
    default_order : 'nome ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    filters       : {},
    title_form    : 'Tipologie di Pratica',
    items         : null,
    page          : 1,
    reload_select : false,
};

const actions = {
    ...base.actions,
    get_all( { commit, state, getters }, _data = { options: {} } ) { // ok
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        commit('start_request');
        data.options.order = getters.order;
        data.options.include = true;
        Object.keys( state.filters ).map( key => { data.options[ key ] = state.filters[ key ]; } );
        if ( !data.options.regione && !data.options.provincia ) { commit('success_list', []); return; }
        state.service.get_all(getters.model_name, data.options)
            .then( response => {
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response)
                    commit('set_reload_select');
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
    add_filter( {commit, dispatch}, filter ) { commit('addfilter', filter); dispatch('get_all', { options: {} }); },
    remove_filter( {commit, dispatch}, index ) { commit('removefilter', index); dispatch('get_all', { options: {} }); },
    force_reload({commit}, value = true) { commit('force_reload', value); },
};

const mutations = {
    ...base.mutations,
    set_reload_select( state ) {
        state.reload_select = true;
        setTimeout( () => { state.reload_select = false; }, 250 );
    },
    force_reload(state, value = true) { state.force_reload = value; },
    get_label: state => comune_id => {
        if ( !comune_id || !state.items ) { return null; }
        for ( var i = 0; i < state.items.length; i++ ) {
           if ( state.items[ i ].id === comune_id ) { return state.items[ i ].nome; }
        }
        console.log( 'ERROR: comune_select.getters.get_label - label non trovata per id ' + String( comune_id ) );
        return null;
    },
};

const getters = {
    ...base.getters,
    is_present: state => comune_id => {
        if ( !comune_id || !state.items ) { return false; }
        return state.items.map( x => x.id ).includes( comune_id );
    },
    get_label: state => comune_id => {
        if ( !comune_id || !state.items ) { return null; }
        for ( var i = 0; i < state.items.length; i++ ) {
           if ( state.items[ i ].id === comune_id ) { return state.items[ i ].nome; }
        }
        console.log( 'ERROR: comune_select.getters.get_label - label non trovata per id ' + String( comune_id ) );
        return null;
    },
    check_regione: state => obj => {
        if ( !obj || !obj.regione || !obj.id || !state.items ) { return false; }
        for ( var i = 0; i < state.items.length; i++ ) {
           if ( state.items[ i ].id === obj.id && state.items[ i ].regione === obj.regione ) { return true; }
        }
        return false;
    },
    check_provincia: state => obj => {
        if ( !obj || !obj.provincia || !obj.id || !state.items ) { return false; }
        for ( var i = 0; i < state.items.length; i++ ) {
           if ( state.items[ i ].id === obj.id && state.items[ i ].provincia === obj.provincia ) { return true; }
        }
        return false;
    },
};


export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
    getters,
};

