////////////////////

import { authHeader } from '@/helpers';
import base_service   from '@/vue-model-mirror/services/base.service';

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export const photoeditor_service = {
    get_by_id : base_service.get_by_id,
    get_all   : base_service.get_all,
    create    : base_service.create,
    update    : base_service.update,
    edit_description,
    print_report,
};

const handleResponse = base_service.handleResponse;

async function print_report( input_options ) {
    var { params, payload, model, codice_pratica } = input_options;
    var url = `${ config.backend_url }model/${ model }/${ codice_pratica }/printgallery`;
    var filters = [];
    Object.keys( params ).map( x => {
        var data_encoded = encodeURIComponent( params[ x ] );
        filters.push( `${x}=${ data_encoded }` );
    });
    if ( filters.length > 0 ) {
        url += '?' + filters.join('&');
    }
    var options = {
        method  : 'POST',
        headers : authHeader(),
        body    : JSON.stringify( payload ),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

async function edit_description( input_options ) {
    var { params, payload, model, image_id } = input_options;
    var url = `${ config.backend_url }model/${ model }/image/${ image_id }`;
    var filters = [];
    Object.keys( params ).map( x => {
        var data_encoded = encodeURIComponent( params[ x ] );
        filters.push( `${x}=${ data_encoded }` );
    });
    if ( filters.length > 0 ) {
        url += '?' + filters.join('&');
    }
    var options = {
        method  : 'PATCH',
        headers : authHeader(),
        body    : JSON.stringify( payload ),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

