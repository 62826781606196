<script>

const nprogress = require('nprogress');

const meta_converter = require('@/vue-model-mirror/helpers/meta_converter').default;
import bnvform      from '@/vue-model-mirror/bnvform';
import gallery      from './gallery';
import documents    from './documents';
import pratica_form from './pratica_form';

export default {
    components: {
        bnvform,
        gallery,
        documents,
        'pratica-form': pratica_form,
    },
    props: [ 'schema_from_route', ],
    mounted () {
        this.$nextTick(function () { // stop loader
            nprogress.done(true);
        });
        //this.$store.dispatch( 'task/open_connection', this.store.items[0].id );
    },
    watch: {
        qry_save(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.make_toast( 'ISPEZIONE', 'dati aggiornati con succeso' );
            }
        },
    },
    computed: {
        store () { return this.$store.state[ this.schema_name ]; },
        qry_save () {
            return this.$store.state.task.executed;
        },
    },
    created () {
        this.started = true;
        this.schema_name = this.$route.params.schema || this.schema_from_route;
        this.options = {};
        var record_id = this.$route.params.id;
        this.action = 'edit';
        this.$store.subscribe((mutation, state) => {
            if ( mutation.type === `${this.schema_name}/success_list` ) {
                this.started = false;
                this.$store.dispatch(`${this.schema_name}/executed`);
            } else if ( mutation.type === `meta_file/success` ) {
                this.make_toast( 'ISPEZIONE', 'immagine salvata con successo' );
            } else if ( mutation.type === `task/deleteimage` ) {
                this.make_toast( 'ISPEZIONE', 'immagine eliminata con successo' );
            }
        });
        this.$store.dispatch(`${this.schema_name}/get_all`, { options: { codice_pratica: record_id, include: true, task_label: 'ispezione', order: 'id ASC' } });
    },
    methods: {
        back_to_list() {
            this.$router.push( '/list/ispezione' );
        },
        get_tab_title( index, task ) {
            return task.label ? task.label : 'Ispezione ' + String(index + 1);
        },
        get_schema( task ) {
            return meta_converter.get_schema_with_id( task.metas );
        },
        save_form_data: function(form_data) {
            var { id, ...payload } = form_data;
            var data = { id, payload };
            this.$store.dispatch('task/updatemeta', data);
        },
        make_toast(title, body) {
            this.$bvToast.toast( body, {
                title         : title,
                autoHideDelay : 5000,
                appendToast   : true,
            });
        },
        get_nav_item_class(index) {
            return this.selected_sub_tab_index === index ? 'bg-white' : 'bg-transparent';
        },
        get_span_class(index) {
            return this.selected_sub_tab_index === index ? 'text-dark' : 'text-white';
        },
        get_icon_style(index) {
            return { fill: this.selected_sub_tab_index === index ? 'black' : 'white' };
        }
    },
    data () {
        return {
            form_reload            : 0,
            started                : false,
            action                 : null,
            selected_tab_index     : 0,
            selected_sub_tab_index : 0,
            tabnum                 : 0,
            form_key               : 0,
            gallery_key            : 0,
            documents_key          : 0,
            inline                 : false,
        }
    },
};

</script>

<template>

    <div>
        <div v-if="started" style="text-align: center; padding-top: 20px">
            <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div v-if="!started">
            <b-tabs v-model="selected_tab_index">
                <b-tab
                    lazy
                    v-for      = "(isp, index) in store.items"
                    :title     = "get_tab_title(index, isp)"
                    v-bind:key = "isp.id"
                    :active    = "index === store.items.length - 1"
                    style      = "padding: 0;"
                    >
                    <!-- FORM DATI TASK -->
                    <b-container style="margin-top: 20px; margin-bottom: 100px;" v-if="selected_sub_tab_index === 0" fluid="sm">
                        <b-row>
                            <b-col xs="12" md="10" lg="8" style="margin: auto;">
                                <bnvform
                                    :key       = "form_key"
                                    name       = "task"
                                    :schema    = "get_schema( isp ).schema"
                                    :options   = "{}"
                                    :record    = "get_schema( isp ).record"
                                    :submit    = "false"
                                    :inline    = "inline"
                                    :ref       = "'tab_' + index"
                                    :showexit  = "false"
                                    @submitted = "save_form_data"
                                ></bnvform>
                            </b-col>
                        </b-row>
                    </b-container>
                    <!-- GALLERIA -->
                    <gallery
                        v-if        = "selected_sub_tab_index === 1"
                        :key        = "gallery_key"
                        :task       = "isp"
                        :isp_number = "String(index + 1)"
                        :add        = "true"
                    ></gallery>
                    <!-- DOCUMENTI -->
                    <documents
                        v-if        = "selected_sub_tab_index === 2"
                        :key        = "documents_key"
                        :task       = "isp"
                        :isp_number = "String(index + 1)"
                        :add        = "true"
                    ></documents>
                    <!-- FORM DATI PRATICA -->
                    <pratica-form
                        v-if  = "selected_sub_tab_index === 3"
                        :task = "isp"
                        >
                    </pratica-form>
                </b-tab>
                <!-- New Tab Button (Using tabs-end slot) -->
                <template v-slot:tabs-start>
                    <b-nav-item role="presentation" @click.prevent="back_to_list()" href="#">
                        <b-icon-x-circle-fill variant="dark" scale="1.4" font-scale = "1" ></b-icon-x-circle-fill>
                    </b-nav-item>
                </template>
                <!-- <template v-slot:tabs-end>
                    <b-nav-item @click.prevent="newTab" href="#">
                        <icon name="plus" />
                    </b-nav-item>
                </template> -->
            </b-tabs>
        </div>
        <b-navbar type="dark" fixed="bottom" variant="dark">
            <b-nav style="width: 100%;" justified>
                <b-nav-item @click="selected_sub_tab_index = 0" :class="get_nav_item_class(0)"><span :class="get_span_class(0)"><icon :style="get_icon_style(0)" scale="1.5" name="tasks"/><br/><span style="font-size: 10pt;">Task</span></span></b-nav-item>
                <b-nav-item @click="selected_sub_tab_index = 1" :class="get_nav_item_class(1)"><span :class="get_span_class(1)"><icon :style="get_icon_style(1)" scale="1.5" name="images"/><br/><span style="font-size: 10pt;">Gallery</span></span></b-nav-item>
                <b-nav-item @click="selected_sub_tab_index = 2" :class="get_nav_item_class(2)"><span :class="get_span_class(2)"><icon :style="get_icon_style(2)" scale="1.5" name="copy"/><br/><span style="font-size: 10pt;">Docs</span></span></b-nav-item>
                <b-nav-item @click="selected_sub_tab_index = 3" :class="get_nav_item_class(3)"><span :class="get_span_class(3)"><icon :style="get_icon_style(3)" scale="1.5" name="shield-alt"/><br/><span style="font-size: 10pt;">Dati</span></span></b-nav-item>
            </b-nav>
        </b-navbar>
    </div>

</template>

<style>
    .card-img-top {
        width: 100%;
        height: 15vw;
        object-fit: cover;
    }
</style>

