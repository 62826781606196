<script>

export default {

    name: 'pra-compagnia',
    props: [ 'item', 'name' ],
    computed: {
        compagnia() {
            return this.item.pregs && this.item.pregs.compagnia ? this.item.pregs.compagnia : null
        },
        compagnia_label() {
            return this.compagnia ? this.compagnia.label : '';
        },
        telefono() {
            let res = null;
            if ( this.compagnia && this.compagnia.telefono.length > 0 ) {
                res = this.compagnia.telefono.map( x => {
                    //return x.label ? `${ x.label } ${ x.value }` : x.value;
                    return x.value;
                });
            }
            return res ? res.join(' / ') : null;
        },
        email() {
            let res = null;
            if ( this.compagnia && this.compagnia.email.length > 0 ) {
                res = this.compagnia.email.map( x => {
                    //return x.label ? `${ x.label } ${ x.value }` : x.value;
                    return x.value;
                });
            }
            return res ? res.join(' / ') : null;
        },
    },

}

</script>

<template>

    <div>
        <p style="line-height: 16px; margin-bottom: 2px;"><small style="font-weight: 600;">{{ compagnia_label }}</small><!-- <br>
            <table :style="{ 'width': name ? '100%' : 'auto' }">
                <tr v-if="telefono">
                    <td v-if="name"><small style="font-weight: 600;">cellulare:</small></td>
                    <td><small style="padding-left: 4px;">{{ telefono }}</small></td>
                </tr>
                <tr v-if="email">
                    <td v-if="name"><small style="font-weight: 600;">email:</small></td>
                    <td><small style="padding-left: 4px;">{{ email }}</small></td>
                </tr>
            </table> -->
        </p>
    </div>

</template>
