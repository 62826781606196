

module.exports = {
    cols: [
          { 
              field1: 'telefono',
              field2: 'email',
          },
    ]
};

