<script>

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];
import { authHeader } from '@/helpers';

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if ( !response.ok ) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = data || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

export default {

    name: 'card-gallery',
    props: [ 'image' ],
    data() {
        return {
            got_link : false,
            url      : null,
        }
    },
    computed: {},
    methods: {
        card_clicked() { this.$emit('canc'); },
    },
    created () {
        if ( this.image.value.thumb_url ) {
            this.url = `${ config.backend_url }${ this.image.value.thumb_url.replace(/^\//, '') }`;
            this.got_link = true;
            return;
        }
        var url = `${ config.backend_url }model/task/image/${ this.image.id }`;
        var options = {
            method  : 'GET',
            headers : authHeader(),
        };
        try {
            fetch(url, options)
                .then(handleResponse)
                .then(res => {
                    this.url = res.url;
                    this.got_link = true;
                });
        } catch ( error ) {
            console.log( error );
        }
    },

}

</script>

<template>

    <b-card
        img-top
        v-if           = "got_link"
        style          = "margin: 10px; padding: 5px;"
        :img-src       = "url"
        img-alt        = "Image"
        border-variant = "secondary"
        @click         = "card_clicked()"
        >
        <b-card-text>{{ image.value.description }}</b-card-text>
    </b-card>
    <b-card
        v-else-if      = "!got_link"
        style          = "margin: 10px; padding: 5px;"
        border-variant = "secondary"
        >
        <b-card-text>
            <div class="text-center" style="margin-bottom: 30px;">
                <b-spinner variant="primary" label="Spinning"></b-spinner><br/>
                <span style="font-size: 8pt; color: grey">loading...</span>
            </div>
            {{ image.value.description }}
        </b-card-text>
    </b-card>

</template>

