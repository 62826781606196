////////////////////

import { photoeditor_service } from '@/services';
import base_module  from '@/vue-model-mirror/stores/base.module';
const config = require('@/config.js')[ process.env.NODE_ENV || 'test' ];

var options = {
    model_name    : 'folder',
    send_alert    : false,
    default_order : 'name ASC',
};

var base = new base_module( options, photoeditor_service );

const state = {
    ...base.state,
    codice_pratica   : null,
    photo_folder_obj : null,
    goto_folder_id   : null,
    folder_found     : null,
    right_column     : [],
    message          : '',
    variant          : '',
    loader           : false,
    ws               : null,
};

const actions = {
    ...base.actions,
    get_photo_folder_id( { state, commit } ) {
        commit('clear');
        commit('start_request');
        var options = {
            codice_pratica : state.codice_pratica,
            name           : 'Foto',
            hierarchyLevel : '1', // impone che la cartella 'Foto' sia sempre nella root
        };
        state.service.get_all( 'folder', options )
            .then( rows => {
                if ( rows.length > 0 ) {
                    commit('set_photo_folder_id', rows[0]);
                    return;
                }
                commit('error_folder_found');
                commit('error', { message: 'cartella Foto non trovata' });
            })
            .catch( error => {
                commit('error_folder_found');
                commit('error', error)
            });
    },
    edit_description( { state, commit, getters, }, options ) {
        options.model = getters.model_name;
        state.service.edit_description( options )
            .then( obj => {
                commit('update_task_image', obj );
            })
            .catch( error => {
                console.log( error )
                commit('error', error)
            });
    },
    print_report( { state, commit, getters }, options ) {
        options.model = getters.model_name;
        state.service.print_report( options )
            .then( obj => {
                if ( config.backend_url.includes( 'https' ) ) {
                    var ws = new WebSocket( config.backend_url.replace('https://', 'wss://') + `ws/gallery/?code=${state.codice_pratica}` );
                } else if ( config.backend_url.includes( 'http' ) ) {
                    var ws = new WebSocket( config.backend_url.replace('http://', 'ws://') + `ws/gallery/?code=${state.codice_pratica}` );
                }
                commit('set_ws', ws );
                ws.onmessage = msg => commit('in_message', msg);
            })
            .catch( error => {
                console.log( error )
                commit('error', error)
            });
    },
    update_items( { commit }, items ) { commit('update_items', items) },
    update_right_column( { commit }, right_column ) { commit('update_right_column', right_column) },
    set_codice_pratica( { commit }, codice_pratica ) { commit('set_codice_pratica', codice_pratica) },
    reset( { commit } ) { commit('reset') },
};

const mutations = {
    ...base.mutations,
    update_items( state, items ) { state.items = items; },
    update_right_column( state, right_column ) { state.right_column = right_column; },
    update_task_image( state, obj ) {
        for ( var i = 0; state.items.length; i++ ) {
            if ( state.items[ i ].task_image.id === obj.id ) {
                state.items[ i ].task_image = obj;
                break;
            }
        }
    },
    set_photo_folder_id( state, folderobj ) {
        state.photo_folder_obj = folderobj;
        state.photo_folder_id  = folderobj.id;
        state.folder_found     = true;
    },
    error_folder_found(state) {
        state.folder_found = false;
    },
    clear( state ) {
        state.obj           = {};
        state.items         = null;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.executed      = false;
        state.force_reload  = false;
        state.selected      = false;
        state.folder_found  = null;
        state.right_column  = [];
    },
    set_ws( state, ws ) { state.ws = ws; },
    in_message( state, msg ) {
        var message = JSON.parse(msg.data);
        if ( message.hasOwnProperty('start') === true ) {
            state.loader = true;
        } else if ( message.hasOwnProperty('stop') === true ) {
            state.loader = false;
            state.ws.close();
            state.ws = null;
        } else {
            state.loader = true;
        }
        if ( message.level === 'info' ) {
            state.variant = '';
            if ( message.hasOwnProperty('success') === true ) {
                state.variant = 'success';
            }
        } else if ( message.level === 'notice' ) {
            state.variant = 'warning';
        } else if ( message.level === 'crit' ) {
            state.variant = 'danger';
            state.loader = false;
        } else if ( message.level === 'error' ) {
            state.variant = 'danger';
            state.loader = false;
        }
        state.message = message;
    },
    set_codice_pratica( state, codice_pratica ) { state.codice_pratica = codice_pratica; },
    reset( state ) {
        var message_class = 'Galleria fotografica';
        state.loader         = true;
        state.message        = { 'class': message_class, action: 'Inizializzazione in corso' };
        state.variant        = '';
    },
};

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
};

