////////////////////

import { authHeader } from '@/helpers';

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export const import_csv_service = {
    upload_csv,
};

function upload_csv( schema, data, include = false) {
    var url = `${config.backend_url}model/${schema}/ws`;
    if ( include ) { url += `?include=true` }
    var payload = {};
    const form = new FormData();
    Object.keys( data.payload ).map( x => {
        form.append(x, data.payload[ x ]);
    });
    var options = {
        method  : 'POST',
        //headers : { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json', },
        headers : authHeader( true ),
        body    : form,
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = data || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

