
const legend    = require('./legend').default;
const linkxls   = require('./linkxls').default;
const studio    = require('./studio').default;
const recapiti  = require('./recapiti').default;

const pra_compagnia   = require('./pratica/compagnia').default;
const pra_assicurato  = require('./pratica/assicurato').default;
const pra_agenzia     = require('./pratica/agenzia').default;
const pra_cliente     = require('./pratica/cliente').default;
const pra_controparte = require('./pratica/controparte').default;

const files_created  = require('./files/created').default;
const files_label    = require('./files/label').default;
const files_size     = require('./files/pretty_print_size').default;
const files_uploader = require('./files/uploader').default;

const diary_type        = require('./diary/type').default;
const diary_task        = require('./diary/task').default;
const diary_description = require('./diary/description').default;
const diary_user        = require('./diary/user').default;

const settings_importer = require('./settings/importer').default;

const parcel_user_name   = require('./parcel/user').default;
const parcel_defaults    = require('./parcel/defaults').default;
const parcel_config      = require('./parcel/config').default;
const parcel_amount      = require('./parcel/amount').default;
const parcel_icon        = require('./parcel/icon').default;
const parcel_config_user = require('./parcel/config_user').default;
const parcel_icon_user   = require('./parcel/icon_user').default;
const parcel_quota       = require('./parcel/quota').default;

const statistic_user     = require('./statistic/user').default;
const statistic_amount   = require('./statistic/amount').default;

const reminder_list    = require('./reminder/list').default;
const reminder_edit    = require('./reminder/edit').default;
const reminder_actions = require('./reminder/actions').default;
const reminder_status  = require('./reminder/status').default;
const reminder_sending = require('./reminder/sending').default;

const sollecito_document_actions = require('./sollecito_document/actions').default;

const user_customers = require('./user/customer').default;
const user_role      = require('./user/role').default;
const user_actions   = require('./user/actions').default;

const reg_contacts = require('./registry/contacts').default;
const reg_pacoib   = require('./registry/pacoib').default;

const appuntamento_compagnia        = require('./appuntamento/compagnia').default;
const appuntamento_assicurato       = require('./appuntamento/assicurato').default;
const appuntamento_assicurato_line  = require('./appuntamento/assicurato_line').default;
const appuntamento_controparte      = require('./appuntamento/controparte').default;
const appuntamento_controparte_line = require('./appuntamento/controparte_line').default;
const appuntamento_tasks            = require('./appuntamento/tasks').default;
const appuntamento_assignments      = require('./appuntamento/assignments').default;
const appuntamento_cliente          = require('./appuntamento/cliente').default;
const appuntamento_note             = require('./appuntamento/note').default;
const appuntamento_luogo            = require('./appuntamento/luogo_appuntamento').default;
const appuntamento_codice           = require('./appuntamento/codice_pratica').default;
const appuntamento_details          = require('./appuntamento/card_details').default;

module.exports = {
    legend,
    linkxls,
    studio,
    recapiti,
    files_created,
    files_label,
    files_size,
    files_uploader,
    diary_type,
    diary_task,
    diary_description,
    diary_user,
    settings_importer,
    parcel_user_name,
    parcel_config,
    parcel_defaults,
    parcel_amount,
    parcel_icon,
    parcel_config_user,
    parcel_icon_user,
    parcel_quota,
    statistic_user,
    statistic_amount,
    reminder_list,
    reminder_edit,
    reminder_actions,
    reminder_status,
    reminder_sending,
    sollecito_document_actions,
    user_customers,
    user_role,
    user_actions,

    pra_compagnia,
    pra_assicurato,
    pra_agenzia,
    pra_cliente,
    pra_controparte,

    reg_contacts,
    reg_pacoib,

    appuntamento_compagnia,
    appuntamento_assicurato,
    appuntamento_assicurato_line,
    appuntamento_controparte,
    appuntamento_controparte_line,
    appuntamento_tasks,
    appuntamento_assignments,
    appuntamento_cliente,
    appuntamento_note,
    appuntamento_luogo,
    appuntamento_codice,
    appuntamento_details,
}

