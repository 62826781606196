<script>

export default {

    name: 'registry-pacoib',
    props: [ 'item' ],
    created() {},
    computed: {
        piva() { return this.item.partita_iva || null; },
        codfisc() { return this.item.codice_fiscale || null; },
        iban() { return this.item.iban || null; },
    },

}

</script>

<template>

        <table>
            <tr v-if="piva">
                <td class="small-td" style="font-weight: 700"><small style="">P.iva</small></td>
                <td class="small-td"><small style="padding-left: 4px;">{{ piva }}</small></td>
            </tr>
            <tr v-if="codfisc">
                <td class="small-td" style="font-weight: 700"><small style="">C.F.</small></td>
                <td class="small-td"><small style="padding-left: 4px;">{{ codfisc }}</small></td>
            </tr>
            <tr v-if="iban">
                <td class="small-td" style="font-weight: 700"><small style="">IBAN</small></td>
                <td class="small-td"><small style="padding-left: 4px;">{{ iban }}</small></td>
            </tr>
        </table>

</template>

<style>
    .small-td {
        padding: 2px 5px !important;
    }
</style>

