////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'pratica',
    send_alert    : false,
    default_order : 'codice ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
};

const getters = {
    ...base.getters,
}

const actions = {
    ...base.actions,
    get_by_id({commit, state, getters, rootGetters}, data) {
        commit('start_request_id');
        var obj = rootGetters['pratica/get_obj'];
        commit('success_obj', rows);
    },
    update({ commit, state, getters }, data) {
        commit('start_request');
        if ( data.payload.mode === 'custodian' ) { delete data.payload.imponibile; }
        state.service.update( getters.model_name, data.codice, data.payload, data.include )
            .then( obj => {
                commit('success', obj);
            })
            .catch( error => {
                commit('error', error)
            });
    },
};

const mutations = {
    ...base.mutations,
}

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
    getters,
};
