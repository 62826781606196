<script>

const nprogress = require('nprogress');

export default {
    name: 'cameratest',
    mounted () {
        this.$nextTick(function () {
            nprogress.done(true);
        })
    },
    components: {
    },
    computed: {
    },
    created () {
        navigator.mediaDevices.getUserMedia({video: true})
            .then(gotMedia)
            .catch(error => console.error('getUserMedia() error:', error));
        
        function gotMedia(mediaStream) {
            const mediaStreamTrack = mediaStream.getVideoTracks()[0];
            const imageCapture = new ImageCapture(mediaStreamTrack);
            console.log(imageCapture);
        }
    },
    methods: {
        snap() {
            var picture = this.webcam.snap();
            this.file = this.dataURLtoFile( picture, 'test.png' );
            this.file.url = picture;
            this.upload();
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        },
        upload() {
            var data = {
                payload: {
                    codice_pratica : 6586,
                    task_id        : 1876,
                    tag            : 'isp1',
                    ispezione_type : 'image',
                    latitude       : this['location'].hasOwnProperty('latitude') ? this['location'].latitude : null,
                    longitude      : this['location'].hasOwnProperty('longitude') ? this['location'].longitude : null,
                    accuracy       : this['location'].hasOwnProperty('accuracy') ? this['location'].accuracy : null,
                },
                container: 'gallery',
            };
            data.payload.file = this.file;
            data.payload.description = 'File caricato direttamente da webcam';
            this.$store.dispatch( 'task/createws', data );
        },
    },
    data () {
        return {
            webcam   : null,
            file     : null,
            location : {},
        }
    },
};

</script>

<template>

    <b-container fluid>
        <b-row class="justify-content-md-center">
            <video id="webcam" ref="webcam_element" autoplay playsinline width="100%" style="width: 70%; height: auto;" capture="camera"></video>
            <canvas id="canvas" ref="canvas_element" class="d-none"></canvas>
            <audio id="snapSound" ref="snap_sound_element" src="audio/snap.wav" preload = "auto"></audio>
        </b-row>
        <b-button @click="snap()">Snap!</b-button>

        <b-card
            v-if           = "file"
            img-top
            style          = "margin: 10px; padding: 5px; opacity: 0.4"
            :img-src       = "file.url"
            img-alt        = "Image"
            border-variant = "danger"
            >
        </b-card>
    </b-container>

</template>

