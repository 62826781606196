////////////////////

const axios = require('axios');

import base_module from '@/vue-model-mirror/stores/base.module';
const config = require('@/config')[ process.env.NODE_ENV || 'development' ];
import { authHeader } from '@/helpers';

var options = {
    model_name    : 'reminder',
    send_alert    : false,
    default_order : 'created ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    customer_id        : null,
    codice_pratica     : null,
    task_id            : null,
    error_auth         : null,
    max_upload_retries : 3,
    sent               : false,
    updated            : false,
    errorobj           : {},
};

const actions = {
    ...base.actions,
    async send_data( { commit, dispatch, state }, data ) {
        dispatch( 'set_doc_loading', { index: data.index, loading: true } );
        var url = `${config.backend_url}model/reminder/send_file/${ state.obj.reminder_id }`;
        var options = { headers: authHeader() };
        options.headers['Authorization'] = `Bearer ${ state.obj.jwt }`;
        options.headers['Content-Type'] = 'multipart/form-data';
        if ( data.files.length > 1 ) {
            for ( var file_i = 0; file_i < data.files.length - 1; file_i++ ) {
                var retries = 0;
                var file = data.files[ file_i ];
                while ( retries < state.max_upload_retries ) {
                    var form_data = new FormData();
                    form_data.append('file', file);
                    form_data.append('index', data.index);
                    form_data.append('label', data.label);
                    var response = await axios.post( url, form_data, options )
                        .then( res => {
                            //commit('set_doc', { doc: res.data, index: data.index });
                            return true;
                        })
                        .catch( error => {
                            //console.log( error );
                            //commit('set_message', { index: data.index, message: { error: true, text: 'ERRORE: ' + error } });
                            return false;
                        });
                    if ( response === true ) { break; }
                    retries += 1;
                }
                if ( retries === state.max_upload_retries ) { // upload fallito
                }
            }
        }
        dispatch( 'close_reminder', {
            file  : data.files.length === 0 ? null : data.files[ data.files.length - 1 ],
            index : data.index,
            text  : data.text,
            label : data.label
        });
    },
    async close_reminder( { commit, dispatch, state }, data ) {
        //console.log( data );
        dispatch( 'set_doc_loading', { index: data.index, loading: true } );
        var url = `${config.backend_url}model/reminder/send_data/${ state.obj.reminder_id }`;
        var options = { headers: authHeader() };
        options.headers['Authorization'] = `Bearer ${ state.obj.jwt }`;
        //console.log( options.headers['Authorization'] );
        options.headers['Content-Type'] = 'multipart/form-data';
        var form_data = new FormData();
        form_data.append('file', data.file);
        form_data.append('text', data.text);
        form_data.append('index', data.index);
        form_data.append('label', data.label);
        await axios.post( url, form_data, options )
            .then( res => {
                commit('set_doc', { doc: res.data, index: data.index });
            })
            .catch( error => {
                console.log( error );
                commit('set_message', { index: data.index, message: { error: true, text: 'ERRORE: ' + error } });
            })
        dispatch( 'set_doc_loading', { index: data.index, loading: false } );
    },
    set_doc_loading( { commit }, data ) { commit('set_doc_loading', data); },
    set_message( { commit }, data ) { commit('set_message', data); },
    validate_jwt( { commit, dispatch }, data ) {
        var url = `${config.backend_url}model/user/validate_jwt/`
        var payload = { jwt: data.jwt };
        var options = { headers: authHeader() };
        axios.post( url, payload, options )
            .then( res => {
                dispatch('load_reminder', data);
            })
            .catch( error => {
                console.log( error );
                commit('set_error_auth', 'token non valido');
            })
    },
    load_reminder( { commit, dispatch }, data ) {
        var url = `${config.backend_url}model/reminder/customer/${ data.id }?include=true`;
        var options = { headers: authHeader() };
        options.headers['Authorization'] = `Bearer ${ data.jwt }`;
        axios.get( url, options )
            .then( res => {
                var obj = res.data;
                obj.jwt = data.jwt;
                commit('success_obj', obj);
            })
            .catch( error => {
                console.log( error );
                commit('set_error_auth', 'errore nel caricare i dati richiesti');
            })
    },
    send_first_email( { commit }, item_id ) {
        var url = `${config.backend_url}model/reminder/first_email/${item_id }`;
        var options = { headers: authHeader() };
        commit('start_request');
        axios.post( url, {}, options )
            .then( res => {
                commit( 'email_sent' );
                commit('update_obj', res.data);
            })
            .catch( error => {
                // TODO alert per l'errore
            })
    },
    test_first_email( { commit }, item_id ) {
        var url = `${config.backend_url}model/reminder/test_first_email/${item_id }`;
        var options = { headers: authHeader() };
        commit('start_request');
        axios.post( url, {}, options )
            .then( res => {
                commit('update_obj', res.data);
            })
            .catch( error => {
                // TODO alert per l'errore
            })
    },
    test_reminder_email( { commit }, item_id ) {
        var url = `${config.backend_url}model/reminder/test_reminder_email/${item_id }`;
        var options = { headers: authHeader() };
        commit('start_request');
        axios.post( url, {}, options )
            .then( res => {
                commit('update_obj', res.data);
            })
            .catch( error => {
                // TODO alert per l'errore
            })
    },
    async reminder_deactivation( { commit }, pratica_id ) {
        var url = `${config.backend_url}model/reminder/reminder_deactivation/${pratica_id}`;
        let options = { headers: authHeader() };
        try {
            const res = await axios.post( url, {}, options );
            return res.data.done;
        } catch (error) {
            console.log( error );
        }
        return false;
    },
    get_all( { commit, state, getters }, _data = { options: {} } ) {
        var data = JSON.parse( JSON.stringify( _data ) );
        commit('start_request');
        data.options.order = getters.order;
        data.options.codice_pratica = state.codice_pratica;
        state.service.get_all(getters.model_name, data.options)
            .then( response => {
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
    create({ commit, state, getters }, data) {
        //console.log( 'codice_pratica', state.codice_pratica );
        commit('start_request');
        var payload = data.payload;
        payload.customer_id    = state.customer_id;
        payload.codice_pratica = state.codice_pratica;
        payload.task_id        = state.task_id;
        state.service.create( getters.model_name, payload, data.include )
            .then( obj => {
                commit('success', obj);
            })
            .catch( error => {
                commit('error', error)
            });
    },
    update({ commit, state, getters }, data) {
        commit('start_request');
        var payload = data.payload;
        //payload.docs.map( x => {
        //    
        //});
        state.service.update( getters.model_name, data.id, data.payload, data.include )
            .then( obj => {
                commit('success', obj);
                commit( 'success_update' );
            })
            .catch( error => {
                commit('error', error)
            });
    },
    destroy( { getters, dispatch }, item_id ) {
        var url = `${ config.backend_url }model/${ getters.model_name }/${ item_id }`;
        var options = { headers: authHeader() };
        axios.delete( url, options )
           .then( response => {
               dispatch('force_reload');
               dispatch('get_all');
           })
           .catch( error => {
               console.log( error );
           });
    },
    validate_placeholders( { commit, rootGetters }, text ) {
        return 'provengo dallo store';
    },
    set_customer_id( { commit }, customer_id ) {
        commit('set_customer_id', customer_id);
    },
    set_codice_pratica( { commit, state }, codice_pratica ) {
        commit('set_codice_pratica', codice_pratica);
    },
    set_task_id( { commit }, task_id ) {
        commit('set_task_id', task_id);
    },
    clearobj( { commit } ) {
        commit('clearobj');
    }
};

function gdifi( docs, index ) { // get_doc_i_from_index
    for ( var i = 0; i < docs.length; i++ ) {
        if ( docs[i].index === index ) { return i; }
    }
}

const mutations = {
    ...base.mutations,
    error( state, error ) {
        state.errorobj     = error;
        state.loading      = false;
        state.error        = true;
        state.executed     = false;
        state.force_reload = true;
        setTimeout( () => {
            state.error = false;
        }, 250);
    },
    success_update( state ) {
        state.updated = true;
        setTimeout( () => {
            state.updated = false;
        }, 200);
    },
    email_sent( state ) {
        state.sent = true;
        setTimeout( () => {
             state.sent = false;
        }, 250);
    },
    clear( state ) {
        //console.log('clear reminder');
        state.obj           = {};
        state.items         = null;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.executed      = false;
        state.force_reload  = false;
        state.selected      = false;
        state.codice_pratica = false;
        state.customer_id    = false;
    },
    clearobj( state ) {
        //console.log('clear obj');
        state.obj           = {};
        state.items         = null;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.executed      = false;
        state.force_reload  = false;
        state.selected      = false;
    },
    set_doc( state, data ) {
        var doc_index = gdifi( state.obj.docs, data.index );
        var docs = JSON.parse( JSON.stringify( state.obj.docs ) );
        docs[ doc_index ] = data.doc;
        state.obj.docs = docs
    },
    set_doc_loading( state, data ) {
        var doc_index = gdifi( state.obj.docs, data.index );
        if ( !state.obj.docs[ doc_index ] ) { return; }
        var docs = JSON.parse( JSON.stringify( state.obj.docs ) );
        docs[ doc_index ].loading = data.loading;
        delete docs[ doc_index ].message;
        state.obj.docs = docs
    },
    set_message( state, data ) {
        var doc_index = gdifi( state.docs, data.index );
        if ( !state.obj.docs[ doc_index ] ) { return; }
        var docs = JSON.parse( JSON.stringify( state.obj.docs ) );
        docs[ doc_index ].message = data.message;
        state.obj.docs = docs
    },
    set_error_auth( state, value ) { state.error_auth = value; },
    set_customer_id( state, value ) { state.customer_id = value; },
    set_codice_pratica( state, value ) { state.codice_pratica = value; },
    set_task_id( state, value ) { state.task_id = value; },
    success( state, obj ) {
        state.obj           = obj;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.executed      = true;
        state.items.push( obj );
    },
    success_list( state, items ) {
        state.items    = items.map( x => { x._showDetails = true; return x; } );
        state.loading  = false;
        state.loading_by_id = false;
        state.error    = false;
        state.executed = true;
        state.force_reload = true;
    },
    update_obj( state, obj ) {
        var items = JSON.parse( JSON.stringify( state.items ) );
        state.items = [];
        for ( var i = 0; i < items.length; i++ ) {
            if ( items[ i ].id === obj.id ) {
                items[ i ] = obj;
                console.log( 'item trovato' );
            }
        }
        state.items = items;
        state.loading = false;
    },
};

export default {
    namespaced : true,
    state,
    getters : base.getters,
    actions,
    mutations,
};

