////////////////////

import { task_service } from '@/services';
import base_module      from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'task',
    send_alert    : false,
    //default_order : 'label ASC',
};

var base = new base_module( options, task_service );

const actions = {
    ...base.actions,
    get_by_id({commit, state, getters}, data) {
        commit('start_request');
        state.service.get_by_id(getters.model_name, data.id, data.include)
            .then( rows => {
                commit('openmodal')
                commit('success_obj', rows)
            })
            .catch( error => {
                commit('error', error)
            });
    },
    close_modal({commit}) { commit('closemodal'); },
    ovveride_meta({commit}, data) {
        commit('overridemeta', data);
    }
};

const mutations = {
    ...base.mutations,
    openmodal( state ) {
        state.open_modal = true;
    },
    closemodal( state ) {
        state.open_modal = false;
        state.executed   = false;
    },
    overridemeta( state, data ) {
        if ( state.items ) {
            for ( var i = 0; i < state.items.length; i++ ) {
                if ( state.items[ i ].id === data.id ) { state.items[ i ].task_meta = JSON.parse(JSON.stringify(data.metas)); break; }
            }
        }
    },
};

export default {
    namespaced : true,
    state      : base.state,
    getters    : base.getters,
    actions,
    mutations,
};

////////////////////

//import { task_service } from '@/services';
//
//export const meta_file = {
//    namespaced: true,
//    state: {
//        title_form    : 'Task',
//        obj           : {},
//        items         : null,
//        loading       : false,
//        error         : false,
//        executed      : false,
//        force_reload  : false,
//        page          : 1,  // default
//        rows_per_page : 25, // default
//        tot           : 0,
//        open_modal    : false,
//    },
//    actions: {
//        get_all({commit}, data = { options: {} }) {
//            commit('start_request');
//            task_service.get_all('task', data.options)
//                .then( response => {
//                    if ( data.options.paginate === true ) { // gestione paginator
//                        commit('success_list', response.data);
//                        commit('set_total', response.tot);
//                        commit('set_page', response.page);
//                        commit('set_rows_per_page', response.rows_per_page);
//                    } else {
//                        commit('success_list', response)
//                    }
//                })
//                .catch( error => {
//                    commit('error', error)
//                });
//        },
//        get_by_id({commit}, data) {
//            commit('start_request');
//            task_service.get_by_id('task', data.id, data.include)
//                .then( rows => {
//                    commit('openmodal')
//                    commit('success_obj', rows)
//                })
//                .catch( error => {
//                    commit('error', error)
//                });
//        },
//        update({ commit }, data) {
//            commit('start_request');
//            data.include = false;
//            task_service.update( 'task', data.id, data.payload, data.include )
//                .then( obj => {
//                    commit('overridemeta', { id: data.id, metas: obj }) ;
//                    commit('success', obj);
//                })
//                .catch( error => {
//                    commit('error', error)
//                });
//        },
//        createws({ commit }, data) {
//            commit('start_request');
//            task_service.createws( 'task', data.payload, data.include )
//                .then( obj => {
//                    commit('success', obj);
//                })
//                .catch( error => {
//                    commit('error', error)
//                });
//        },
//        force_reload({commit}) { commit('force_reload'); },
//        executed({commit}) { commit('executed'); },
//        clear({commit}) { commit('clear'); },
//        close_modal({commit}) { commit('closemodal'); },
//        ovveride_meta({commit}, data) {
//            commit('overridemeta', data);
//        }
//    },
//    mutations: {
//        openmodal( state ) {
//            state.open_modal = true;
//        },
//        closemodal( state ) {
//            state.open_modal = false;
//            state.executed     = false;
//        },
//        start_request( state ) {
//            state.loading      = true;
//            state.error        = false;
//            state.executed     = false;
//            state.force_reload = false;
//        },
//        success( state, obj ) {
//            state.obj      = obj;
//            state.loading  = false;
//            state.error    = false;
//            state.executed = true;
//        },
//        success_obj( state, obj ) {
//            state.obj      = obj;
//            state.loading  = false;
//            state.error    = false;
//            state.executed = true;
//        },
//        success_list( state, items ) {
//            state.items    = items;
//            state.loading  = false;
//            state.error    = false;
//            state.executed = true;
//        },
//        error( state, error ) {
//            console.log( error );
//            state.obj          = error;
//            state.loading      = false;
//            state.error        = true;
//            state.executed     = false;
//            state.force_reload = true;
//        },
//        set_total( state, data ) {
//            state.tot = data;
//        },
//        set_page( state, data ) {
//            state.page = data;
//        },
//        set_rows_per_page( state, data ) {
//            state.rows_per_page = data;
//        },
//        force_reload(state) {
//            state.force_reload = true;
//        },
//        executed(state) {
//            state.executed = false;
//        },
//        clear( state ) {
//            state.obj          = {};
//            state.items        = null;
//            state.loading      = false;
//            state.error        = false;
//            state.executed     = false;
//            state.force_reload = false;
//        },
//        overridemeta( state, data ) {
//            if ( state.items ) {
//                for ( var i = 0; i < state.items.length; i++ ) {
//                    if ( state.items[ i ].id === data.id ) { state.items[ i ].task_meta = JSON.parse(JSON.stringify(data.metas)); break; }
//                }
//            }
//        }
//    }
//}


