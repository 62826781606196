<script>

import { mapState } from 'vuex';

import listpage from '@/vue-model-mirror/listpage';
import bnvform from '@/vue-model-mirror/bnvform';
import addRegistry from './components/add_registry';
import advanced_search_registry  from './components/advanced_search_registry';

export default {
    name: 'registry-list',
    extends: listpage,
    components: { addRegistry, 'advanced-search-registry': advanced_search_registry },
    props: [],
    mounted() {},
    computed: {
        customers() {
            return this.$store.getters['statemanager/customers'];
        },
        ...mapState('registry_template', {
            templates : state => state.items,
        }),
        ...mapState('registry', {
            registry_deleted : state => state.registry_deleted,
        }),
        customer_id: {
            get() {
                return this.$store.state.registry.filters.customer_id || '';
            },
            set( value ) {
                if ( value ) {
                    this.$store.dispatch( 'registry/add_filter', { customer_id: value } );
                    this.$store.dispatch( 'registry/remove_filter', 'customer_id__in' );
                } else {
                    this.$store.dispatch( 'registry/add_filter', { customer_id__in: this.customers.map( x => x.id ).join(',') } );
                    this.$store.dispatch( 'registry/remove_filter', 'customer_id' );
                }
                this.$store.dispatch( 'registry/set_params', { force_reload: true } );
                this.list_key += 1;
            },
        },
        template_id: {
            get() {
                return this.$store.state.registry.filters.template_id || '';
            },
            set( value ) {
                if ( value ) {
                    this.$store.dispatch( 'registry/add_filter', { template_id: value } );
                } else {
                    this.$store.dispatch( 'registry/remove_filter', 'template_id' );
                }
                this.$store.dispatch( 'registry/set_params', { force_reload: true } );
                this.list_key += 1;
            },
        },
        routes() {
            return new Map([ // TODO da rimuovere
                [ 1, 'persona_fisica' ],
                [ 2, 'persona_giuridica' ],
                [ 3, 'compagnia' ],
                [ 4, 'agenzia' ],
                [ 5, 'ispettorato' ],
                [ 6, 'liquidatore' ],
                [ 7, 'ente_pubblico' ],
                [ 8, 'polizza' ],
            ]);
        },
        qry_options () {
            var options = { paginate: true };
            if ( this.options.hasOwnProperty('include') ) { options.include = this.options.include; }
            if ( this.options.hasOwnProperty('initialfilter') ) {
                Object.keys( this.options.initialfilter ).map( x => { options[ x ] = this.options.initialfilter[ x ]; } );
            }
            if ( this.customer_id ) {
                options.customer_id = this.customer_id;
            } else {
                options.customer_id__in = this.customers.map( x => x.id )
            }
            if ( this.template_id ) {
                options.template_id = this.template_id; 
            }
            return options;
        },
    },
    watch: {
//      customer_id( new_value ) {
//          //if ( !new_value ) { return; }
//          this.$store.dispatch( 'registry/set_params', { force_reload: true, customer_id: new_value } );
//          this.list_key += 1;
//      },
//      template_id( new_value ) {
//          //if ( !new_value ) { return; }
//          this.$store.dispatch( 'registry/set_params', { force_reload: true, template_id: new_value } );
//          this.list_key += 1;
//      },
        registry_deleted( new_value ) {
             if ( new_value ) { return; }
             this.make_toast( 'anagrafica eliminata' );
        },
    },
    created () {
        this.$store.dispatch( 'registry_template/load' );
        this.$store.dispatch( 'registry_role/load' );
        //this.set_default_customer();
    },
    methods: {
        set_default_customer() {
            this.customer_id = this.customers[0].id;
        },
        new_registry( data ) {
            this.$store.dispatch( 'registry/set_params', { customer_id: data.customer_id } );
            this.$router.push(`/form/registry_${ data.template.replace( /^_/, '' ) }`);
        },
        edit_item( item ) {
            this.$store.dispatch( 'registry/set_params', { customer_id: item.customer_id } );
            this.$router.push(`/form/registry_${ this.routes.get( item.template_id ) }/${ item.id }`);
        },
        make_toast( body ) {
            this.$bvToast.toast( body, {
                title         : 'ANAGRAFICHE',
                autoHideDelay : 5000,
                appendToast   : true,
            });
        },
        set_filter( payload ) {
            if ( payload.text ) {
                this.$store.dispatch('registry/add_filter', { contains: payload.text });
            } else {
                this.$store.dispatch('registry/remove_filter', 'contains');
            }
            if ( payload.date_filters && payload.date_filters.length > 0 ) {
                this.$store.dispatch('registry/add_filter', { date_filters: payload.date_filters });
            } else {
                this.$store.dispatch('registry/remove_filter', 'date_filters');
            }
            this.$store.dispatch('registry/force_reload');
            this.$refs.listbnv.page = 1;
        },
    },
    data() {
        return {
            list_key    : 0,
        };
    },
};

</script>

<template>

    <div style="width: 100%; margin: auto">
        <h3 style="text-align: center; padding-bottom: 14px;">{{ title }}</h3>
        <b-row style="margin: 0 10px 20px 10px" align-h="end" align-v="end">
            <b-col class="" v-if="customers.length > 1" align-v="end" lg="4" md="6" sm="12">
                <label for="ac-role" style="margin-bottom: 2px; font-size: 0.8em;">Proprietario</label>
                <b-select
                    class             = "form-control"
                    size              = "sm"
                    id                = "ac-role"
                    v-model           = "customer_id"
                    >
                    <option value="">- - - - - -</option>
                    <option v-for="option in customers" v-bind:key="'cus_' + option.id" :value="option.id">{{ option.label }}</option>
                </b-select>
            </b-col>
            <b-col class="" v-if="templates && templates.length > 1" align-v="end" lg="4" md="6" sm="12">
                <label for="ac-role" style="margin-bottom: 2px; font-size: 0.8em;">Template</label>
                <b-select
                    class             = "form-control"
                    size              = "sm"
                    id                = "ac-template"
                    v-model           = "template_id"
                    >
                    <option value="">- - - - - -</option>
                    <option v-for="option in templates" v-bind:key="'temp_' + option.id" :value="option.id">{{ option.label }}</option>
                </b-select>
            </b-col>
            <b-col v-if="add" class="text-right" lg="4" md="12" sm="12">
                <b-row align-v="end" align-h="end" style="padding-left: 15px; padding-right: 15px">
                    <advanced-search-registry @search="set_filter" style="margin-right: 10px"/>
                    <add-registry @save="new_registry" :customers="customers" :customer_id="customer_id" />
                </b-row>
            </b-col>
        </b-row>
        <bnvlist
            :key             = "list_key"
            :schema          = "schema_name"
            storename        = "bnvform"
            :skip            = "skip"
            :fields          = "fields"
            :primarykey      = "primary_key"
            :qryoptions      = "qry_options"
            :details         = "details"
            :detailsclass    = "details_class"
            :detailsaction   = "details_action"
            :edit            = "edit"
            :editaction      = "edit_action"
            :paginate        = "paginate"
            :link            = "link"
            :rowclass        = "rowclass"
            :linkfunction    = "linkfunction"
            :initialfilter   = "initialfilter"
            ref              = "listbnv"
            :size            = "size"
            :formpath        = "item_form"
            :event_on_edit   = "true"
            @row_selected    = "row_selected"
            @right-click     = "right_click"
            @edit            = "edit_item"
        />
    </div>

</template>

