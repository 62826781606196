////////////////////

const axios = require('axios');
import md5 from "crypto-js/md5";

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];
import { import_csv_service } from '@/services';
import base_module from '@/vue-model-mirror/stores/base.module';
import { authHeader } from '@/helpers';

var options = {
    model_name    : 'csv',
    send_alert    : false,
    default_order : null,
};

var base = new base_module( options, import_csv_service );

const state = {
    ...base.state,
    created             : false,
    createdws           : false,
    freeze              : false,
    previous_folder_ids : [],
    upload_counter      : { tot: 0, done: 0 },
    zip_loading         : false,
    zip_message         : {},
    timeout             : null,
    merged              : false,
    //
    loading             : false,
    wscode              : null,
    enable_import_buttons : true,
    // upload queue
    upload_active     : false,
    queue             : [],
    queue_size        : 0,
    active_file_size  : 0,
    chunk_size        : 30000000, // 15MB
    queue_quota       : null,
    active_file_quota : null,
    chunk_quota       : 0,
    chunks_queue      : null,
    chunks_quantity   : null,
    progress_cache    : {},
    queue_percentage  : 0,
    file_percentage   : 0,
    active_file_name  : null,
    uploaded_files    : [],
    wscode_docs       : null,
    loading_docs      : false,
};

const actions = {
    ...base.actions,

    async create({ state, commit, dispatch, getters }, data) {
        await state.service.upload_csv( getters.model_name, data )
            .then( response => {
                commit('success_upload', response);
            })
            .catch( error => {
                if ( state.upload_counter.tot > 0 ) {
                    commit('add_upload_done' );
                    if ( state.upload_counter.tot === state.upload_counter.done ) {
                        commit('reset_upload_counter' );
                    }
                }
                commit('error', error)
            });
    },

    reset( { commit } ) {
        commit('reset');
    },

    set_enabled_import_buttons( { commit }, value ) {
        commit('set_enabled_import_buttons', value);
    },

    // upload zip

    push_to_queue( { commit }, _files ) {
        var files = _files;
        if ( _files.constructor.name === 'File' ) {
            files = [ _files ];
        }
        commit('push_to_queue', files);
    },

    upload_queue( { commit, state, dispatch } ) {
        if ( state.upload_active === true || state.queue.length === 0 ) { return; }
        commit('set_params', { upload_active: true });
        dispatch( 'upload_file' );
    },

    upload_file( { commit, dispatch, state, getters } ) {
        var file = state.queue[0];
        dispatch('set_params', { active_file_size: file.size, active_file_quota: 0, active_file_name: file.name });
        if ( file.size < state.chunk_size ) {
            dispatch('send_file', file);
            return;
        }
        var chunk_size = state.chunk_size;
        var chunks_quantity = Math.ceil(file.size / chunk_size);
        const chunks_queue = new Array( chunks_quantity ).fill().map( ( _, index ) => index ).reverse();
        commit('set_params', { chunks_queue, chunks_quantity });
        dispatch('send_chunk', { file/*, fileId*/ });
    },

    send_chunk( { commit, state, dispatch }, { file } ) {
        var chunks_queue    = state.chunks_queue;
        var chunks_quantity = state.chunks_quantity;
        var chunk_size      = state.chunk_size;
        if ( !chunks_queue.length ) {
            dispatch( 'remove_from_queue', file.hash );
            commit('save_file_name', { hash: file.hash, name: file.name });
            if ( state.queue.length > 0 ) {
                // reset params
                commit('set_params', {
                    active_file_size  : 0,
                    active_file_quota : 0,
                    chunk_quota       : 0,
                    chunks_queue      : [],
                    chunks_quantity   : null,
                    progress_cache    : {},
                    file_percentage   : 0,
                    active_file_name  : null,
                });
                dispatch( 'upload_file' );
            } else {
                commit('set_params', { upload_active: false/*, queue_percentage: 100*/ });
                setTimeout( () => {
                    commit('set_params', {
                        queue_size        : 0,
                        active_file_size  : 0,
                        queue_quota       : 0,
                        active_file_quota : 0,
                        chunk_quota       : 0,
                        chunks_queue      : [],
                        chunks_quantity   : null,
                        progress_cache    : {},
                        queue_percentage  : 0,
                        file_percentage   : 0,
                        active_file_name  : null,
                    });
                }, 2200);
            }
            return;
        }
        const chunk_id = chunks_queue.pop();
        const begin    = chunk_id * chunk_size;
        const chunk    = file.slice(begin, begin + chunk_size);

        var headers = authHeader();

        var on_progress = function(chunkId, event) {
            if (event.type === "progress" || event.type === "error" || event.type === "abort") {
                commit('set_progress_chunk', { chunk_id, bytes: event.loaded });
            }
        
            if (event.type === "loadend") {
                commit('sum_active_file_quota', state.progress_cache[chunk_id] || 0);
                commit('sum_queue_quota', state.progress_cache[chunk_id] || 0);
                commit('remove_progress_chunk', chunk_id)
            }
            const in_progress = Object.keys(state.progress_cache).reduce((memo, id) => memo + state.progress_cache[id], 0); 
            const sent_length_file  = Math.min(state.active_file_quota + in_progress, file.size);
            const sent_length_queue = Math.min(state.size_quota + in_progress, state.queue.size);
            const file_percent  = Math.round(sent_length_file / file.size * 100 * 100) / 100;
            const queue_percent = Math.round( state.queue_quota * 100 / state.queue_size );
//          console.log( 'active_file_quota :', state.active_file_quota );
//          console.log( 'active_file_size  :', state.active_file_size );
//          console.log( 'file_percentage   :', file_percent );
//          console.log( 'queue_size        :', state.queue_size );
//          console.log( 'queue_quota       :', state.queue_quota );
//          console.log( 'queue_percentage  :', queue_percent );
            commit('set_params', {
                queue_percentage : queue_percent,
                file_percentage  : file_percent,
            });
        }
        var upload_chunk = function( chunk, chunk_id, chunks_quantity, file, auth) {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();

                const progress_listener = on_progress.bind(null, chunk_id);
                xhr.upload.addEventListener("progress", progress_listener);
                xhr.addEventListener("error", progress_listener);
                xhr.addEventListener("abort", progress_listener);
                xhr.addEventListener("loadend", progress_listener);

                var url = `${config.backend_url}model/csv/upload`;
                xhr.open("post", url);
        
                xhr.setRequestHeader("Content-Type", "application/octet-stream");
                xhr.setRequestHeader("X-Chunk-Id", chunk_id);
                xhr.setRequestHeader("X-Chunks-Quantity", chunks_quantity);
                xhr.setRequestHeader("X-Content-Id", file.hash);
                xhr.setRequestHeader("Authorization", auth);
                xhr.setRequestHeader("X-Content-Length", file.size);
                xhr.setRequestHeader("X-Content-Name", file.name);
        
                xhr.onreadystatechange = () => {
                    if ( xhr.readyState === 4 && [ 200, 201 ].includes( xhr.status ) ) {
                        resolve();
                    }
                };
                xhr.onerror = reject;
        
                xhr.send(chunk);
            });
        }

        upload_chunk( chunk, chunk_id, chunks_quantity, file, headers['Authorization'])
            .then(() => {
                dispatch('send_chunk', { file });
            })
            .catch(() => {
                chunks_queue.push(chunk_id);
            });
    },

    // TODO questa è la funzione di default per l'upload nel caso in cui il size del file
    //      permetta un sigolo upload
    send_file( { commit, state, getters, dispatch }, file ) {
        var schema = getters.model_name;
        //var url = `${config.backend_url}model/${schema}/ws`;
        var url = `${config.backend_url}model/csv/upload_nochunks`;
        var payload = {};
        const form = new FormData();
        form.append( 'file', file )
        var headers = authHeader();
        headers['Content-Type'] = 'multipart/form-data';
        var options = [
            url,
            form,
            {
                headers,
                onUploadProgress: function( progress_event ) {
                    const file_percent  = Math.round(progress_event.loaded / file.size * 100 * 100) / 100;
                    const queue_percent = Math.round( (state.queue_quota + progress_event.loaded) * 100 / state.queue_size );
                    commit('set_params', {
                        queue_percentage : queue_percent,
                        file_percentage  : file_percent,
                    });
                }
            }
        ];
        axios.post( ...options )
            .then( ( response ) => {
                commit('save_file_name', { hash: response.data.filename.split('.')[0], name: response.data.originalname });
                commit('sum_active_file_quota', file.size);
                commit('sum_queue_quota', file.size);
                const queue_percent = Math.round( state.queue_quota * 100 / state.queue_size );
                    commit('set_params', {
                        queue_percentage : queue_percent,
                        file_percentage  : 100,
                    });
                commit( 'remove_from_queue', file.hash );
                if ( state.queue.length > 0 ) {
                    // reset params
                    commit('set_params', {
                        active_file_size  : 0,
                        active_file_quota : 0,
                        chunk_quota       : 0,
                        chunks_queue      : [],
                        chunks_quantity   : null,
                        progress_cache    : {},
                        file_percentage   : 0,
                        active_file_name  : null,
                    });
                    dispatch( 'upload_file' );
                } else {
                    commit('set_params', { upload_active: false });
                    setTimeout( () => {
                        commit('set_params', {
                            queue_size        : 0,
                            active_file_size  : 0,
                            queue_quota       : 0,
                            active_file_quota : 0,
                            chunk_quota       : 0,
                            chunks_queue      : [],
                            chunks_quantity   : null,
                            progress_cache    : {},
                            queue_percentage  : 0,
                            file_percentage   : 0,
                            active_file_name  : null,
                        });
                    }, 2200);
                }
                return;
            })
            .catch( error => {
                // TODO
                commit('error', error)
            });
    },

    set_params( { commit }, obj ) { commit('set_params', obj); },

    remove_from_queue( { commit }, hash ) { commit('remove_from_queue', hash); },

    async import_docs( { commit, state } ) {
        let url = `${ config.backend_url }model/csv/import`;
        var headers = authHeader();
        await axios.post( url, { archives: state.uploaded_files }, { headers } )
            .then( response => {
                console.log( response.data );
                commit('success_start_import_docs', response.data);
            })
            .catch( error => {
                console.log( error );
            });
    },

    //

};

const mutations = {
    ...base.mutations,

    // upload zip

    success_start_import_docs( state, data ) {
        state.wscode_docs  = data.wscode;
        state.loading_docs = true;
    },

    save_file_name( state, data ) {
        state.uploaded_files.push( `${ data.hash }.zip` );
    },

    push_to_queue( state, files ) {
        for ( var i = 0; i < files.length; i++ ) {
            var file = files[ i ];
            var hash_obj = {
                name         : file.hash,
                lastModified : file.lastModified,
                size         : file.size,
                type         : file.type
            };
            var hash = md5( JSON.stringify( hash_obj ) ).toString();
            if ( file.constructor.name !== 'File' ) {
                console.warning( '====================' );
                console.warning('');
                console.warning( 'la coda di upload contiene acccedetta solo objects di tipo File. Trovato:' ); 
                console.warning( file )
                console.warning( 'skip.' );
                console.warning('');
                console.warning( '====================' );
                continue;
            }
            if ( state.queue.map( x => x.hash ).includes( hash ) ) {
                console.warning( '====================' );
                console.warning('');
                console.warning( 'Il file è già in coda' ); 
                console.warning( 'skip.' );
                console.warning('');
                console.warning( '====================' );
                continue;
            }
            file.hash = hash;
            state.queue.push( file );
            state.queue_size += file.size;
        }
    },

    set_params( state, obj ) {
        if ( typeof obj === 'object' ) {
            var keys = Object.keys( obj );
            for ( var i = 0; i < keys.length; i++  ) {
                if ( state.hasOwnProperty( keys[ i ] ) ) { state[ keys[ i ] ] = obj[ keys[ i ] ]; }
            }
        }
    },

    set_progress_chunk( state, obj ) { // mutations
        if ( typeof obj === 'object' && obj.hasOwnProperty('chunk_id') && obj.hasOwnProperty('bytes') ) {
            state.progress_cache[ obj.chunk_id ] = obj.bytes;
        }
    },

    sum_active_file_quota( state, quota ) {
        state.active_file_quota += quota;
    },

    remove_progress_chunk( state, chunk_id ) {
        delete state.progress_cache[ chunk_id ];
    },

    sum_queue_quota( state, quota ) {
        state.queue_quota += quota;
    },

    remove_from_queue( state, hash ) {
        var index = null;
        var files = state.queue;
        for ( var i = 0; i < files.length; i++ ) {
            if ( files[ i ].hash === hash ) { var index = i; break; }
        }
        if ( index !== null ) {
            state.queue.splice(index, 1);
        }
    },

    //

    reset( state ) {
        state.loading = false;
        state.wscode  = null;
    },

    set_enabled_import_buttons( state, value ) {
        state.enable_import_buttons = value;
    },

    start_request( state ) {
        state.loading      = true;
        state.error        = false;
        state.executed     = false;
        state.force_reload = false;
    },
    success_upload( state, payload ) {
        state.wscode = payload.wscode;
        state.loading = true;
        state.enable_import_buttons = false;
    },

    merged( state ) {
        state.merged = true;
        setTimeout( () => { state.merged = false; }, 200 );
    },
    set_timeout( state, timeout ) {
        if ( state.timeout ) { clearTimeout( state.timeout ); }
        state.timeout = timeout;
    },
    set_ws( state, ws ) {
        state.ws = ws;
    },
    close_ws( state ) {
        if ( state.ws ) {
            state.ws.close();
            state.ws = null;
            console.log( 'connessione ws zip chiusa' );
        }
    },
    set_zip_loading( state, value = true ) {
        console.log( 'set_zip_loading:', value );
        state.zip_loading = value;
    },
    in_message( state, message ) {
        state.zip_message = message;
    },
    set_upload_tot( state, tot ) {
        state.upload_counter.tot  = tot;
        state.upload_counter.done = 0;
    },
    reset_upload_counter( state ) {
        state.upload_couter.tot  = 0;
        state.upload_couter.done = 0;
    },
    add_upload_done( state ) {
        state.upload_counter.done += 1;
    },
    add_folder_id( state, folder_id ) {
        state.previous_folder_ids.push( folder_id );
    },
    set_folder_ids(state, folder_ids) {
        state.previous_folder_ids = folder_ids;
    },
    freeze(state) {
        state.freeze = !state.freeze;
    },
    success_list( state, items ) {
        var folders = [];
        var files   = [];
        for ( var i = 0; i < items.length; i++ ) {
            if ( items[ i ].task_image_id === null ) {
                folders.push( items[ i ] );
            } else {
                files.push( items[ i ] );
            }
        }
        state.items    = folders.concat( files );
        state.loading  = false;
        state.error    = false;
        state.executed = true;
    },
    success_create( state, items ) {
        state.created = true;
    },
    success_update( state, items ) {
        state.updated = true;
    },
    executed(state) {
        state.executed  = false;
        state.created   = false;
        state.updated   = false;
        state.createdws = false;
    },
};

export default {
    namespaced : true,
    state,
    actions,
    mutations,
    getters: base.getters,
};

