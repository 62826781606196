////////////////////

export const color_service = {
    get_all,
};

function get_all() {
    var options = [
        { id: 'danger', label: 'Rosso' },
        { id: 'warning', label: 'Giallo' },
        { id: 'success', label: 'Verde' },
    ];
    return new Promise(function(resolve, reject) {
        resolve( options );
    });
}


