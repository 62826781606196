<script>

const moment = require('moment-timezone');

export default {

    name: 'cmp-diary-user',
    props: [ 'item' ],
    data() {
        return {
        }
    },
    computed: {
        is_mine()   {
            if ( [ 1, 4, 19 ].includes( this.item.user_id ) ) { return true; }
            return this.$store.getters['userletter/is_mine']( this.item.user_id );
        },
        customers() { return this.$store.getters['statemanager/customers'].map( x => x.id ); },
        pra()       { return this.$store.getters['pratica/get_item']( this.item.codice_pratica ); },
        customer() {
            if ( this.pra === null ) {
                return '';
            }
            if ( this.is_mine === false ) {
                if ( this.customers.includes( this.pra.owner ) ) {
                    return this.pra.customer_custodian.label;
                } else if ( this.customers.includes( this.pra.custodian ) ) {
                    return this.pra.customer_owner.label;
                }
            }
        }
    },
    methods: {
    },
    created () {
    },

}

</script>

<template>

    <div style="">
        <span v-if="is_mine">{{ item.options.operator_label }}</span>
        <span v-else-if="is_mine === false"><b-badge variant="warning">{{ customer }}</b-badge></span>
    </div>

</template>



