//////////////////////////////

// https://stackoverflow.com/questions/7922744/how-can-i-search-all-columns-in-a-table

module.exports = (type) => {

    var schema = {
        codice_pratica: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'pratiche',
                key         : 'codice',
                constraints : true,
                store_name  : 'pratica',
            }
        },
        tipo_pratica_id: {
            type: type.INTEGER,
            allowNull: true,
            defaultValue: null,
            references: {
                model       : 'tipo_pratica',
                key         : 'id',
                constraints : true,
                store_name  : 'tipo_pratica',
            }
        },
        owner: {
            type: type.INTEGER,
            allowNull: true,
            defaultValue: null,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            },
        },
        created_by: {
            type: type.INTEGER,
            allowNull: true,
            defaultValue: null,
            references: {
                model       : 'users',
                key         : 'id',
                constraints : true,
                store_name  : 'useradv',
            },
        },
        state: {
            type: type.INTEGER,
            allowNull: true,
            defaultValue: null,
            references: {
                model       : 'newstates',
                key         : 'key',
                constraints : true,
                store_name  : 'newstate',
            },
        },
        numero_sinistro: {
            type: type.STRING(128),
            allowNull: true,
            defaultValue: null,
        },
        ramo: {
            type: type.STRING(128),
            allowNull: true,
            defaultValue: null,
        },
        tipo_danno: {
            type: type.STRING(2048),
            allowNull: true,
            defaultValue: null,
        },
        delega_a_pagare: {
            type: type.BOOLEAN,
            allowNull: true,
            defaultValue: null,
        },
        risarcimento_in_forma_spese: {
            type: type.BOOLEAN,
            allowNull: true,
            defaultValue: null,
        },
        tipo_trattazione: {
            type: type.STRING(2048),
            allowNull: true,
            defaultValue: null,
        },
        num_pratica_esterno: {
            type: type.STRING(64),
            allowNull: true,
            defaultValue: null,
        },
        // luogo sinistro
        luogo_sinistro: {
            type: type.VIRTUAL,
            allowNull: true,
            field_type: 'google_address',
        },
        strada_luogo_sinistro: {
            type: type.STRING(64),
            allowNull: true,
            defaultValue: null,
        },
        n_civ_luogo_sinistro: {
            type: type.STRING(64),
            allowNull: true,
            defaultValue: null,
        },
        cap_luogo_sinistro: {
            type: type.STRING(32),
            allowNull: true,
            defaultValue: null,
        },
        comune_luogo_sinistro_id: {
            type: type.INTEGER,
            allowNull: true,
            defaultValue: null,
            references: {
                model       : 'comuni',
                key         : 'id',
                constraints : true,
                store_name  : 'comune_residenza',
                type        : 'autocomplete'
            },
        },
        citta_estero_luogo_sinistro: {
            type: type.STRING(128),
            allowNull: true,
            defaultValue: null,
        },
        nazione_luogo_sinistro: {
            type: type.STRING(128),
            allowNull: true,
            defaultValue: null,
        },
        altro_luogo_sinistro: {
            type: type.STRING(32),
            allowNull: true,
            defaultValue: null,
        },
        latitudine_luogo_sinistro: {
            type: type.DECIMAL(10,6),
            allowNull: true,
            defaultValue: null,
        },
        longitudine_luogo_sinistro: {
            type: type.DECIMAL(10,6),
            allowNull: true,
            defaultValue: null,
        },
        // luogo appuntamento
        luogo_appuntamento: {
            type: type.VIRTUAL,
            allowNull: true,
            field_type: 'google_address',
        },
        strada_luogo_appuntamento: {
            type: type.STRING(64),
            allowNull: true,
            defaultValue: null,
        },
        n_civ_luogo_appuntamento: {
            type: type.STRING(64),
            allowNull: true,
            defaultValue: null,
        },
        cap_luogo_appuntamento: {
            type: type.STRING(32),
            allowNull: true,
            defaultValue: null,
        },
        comune_luogo_appuntamento_id: {
            type: type.INTEGER,
            allowNull: true,
            defaultValue: null,
            references: {
                model       : 'comuni',
                key         : 'id',
                constraints : true,
                store_name  : 'comune_residenza',
                type        : 'autocomplete'
            },
        },
        citta_estero_luogo_appuntamento: {
            type: type.STRING(128),
            allowNull: true,
            defaultValue: null,
        },
        nazione_luogo_appuntamento: {
            type: type.STRING(128),
            allowNull: true,
            defaultValue: null,
        },
        altro_luogo_appuntamento: {
            type: type.STRING(32),
            allowNull: true,
            defaultValue: null,
        },
        latitudine_luogo_appuntamento: {
            type: type.DECIMAL(10,6),
            allowNull: true,
            defaultValue: null,
        },
        longitudine_luogo_appuntamento: {
            type: type.DECIMAL(10,6),
            allowNull: true,
            defaultValue: null,
        },
        data_sinistro: {
            type: type.DATEONLY,
            allowNull: true,
            defaultValue: null,
        },
        data_denuncia: {
            type: type.DATEONLY,
            allowNull: true,
            defaultValue: null,
        },
        data_affidamento: {
            type: type.DATEONLY,
            allowNull: true,
            defaultValue: null,
        },
        data_primo_contatto: {
            type: type.DATEONLY,
            allowNull: true,
            defaultValue: null,
        },
        data_sopralluogo: {
            type: type.DATEONLY,
            allowNull: true,
            defaultValue: null,
        },
        data_videoperizia: {
            type: type.DATEONLY,
            allowNull: true,
            defaultValue: null,
        },
        data_invio_atto: {
            type: type.DATEONLY,
            allowNull: true,
            defaultValue: null,
        },
        data_evasione: {
            type: type.DATEONLY,
            allowNull: true,
            defaultValue: null,
        },
        importo_riserva: {
            type: type.DECIMAL(10,2),
            allowNull: true,
            defaultValue: null,
        },
        importo_richiesto: {
            type: type.DECIMAL(10,2),
            allowNull: true,
            defaultValue: null,
        },
        saving: {
            type: type.VIRTUAL,
            allowNull: true,
            field_type: 'bnvinteger',
        },
        tipo_chiusura_id: {
            type: type.INTEGER,
            allowNull: true,
            defaultValue: null,
            references: {
                model       : 'tipo_chiusura',
                key         : 'id',
                constraints : true,
                store_name  : 'tipo_chiusura',
            }
        },
        importo_liquidato: {
            type: type.DECIMAL(10,2),
            allowNull: true,
            defaultValue: null,
        },
        importo_accertato: {
            type: type.DECIMAL(10,2),
            allowNull: true,
            defaultValue: null,
        },
        note: {
            type: type.TEXT,
            allowNull: true,
            defaultValue: null,
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue: type.NOW,
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        operator: {
            type: type.VIRTUAL,
        },
        archived: {
            type: type.BOOLEAN,
            defaultValue: false,
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

