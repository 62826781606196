<script>

import type_manager from './types';
const extras         = require('../../formconfig')
const element_config = require('./element_config').default;

export default {
    name: 'bnvform',
    props: [
        'name',
        'schema',
        'or_data',
        'options',
        'record',
        'submit',
        'inline',
        'pk',
        'listpath',
        'layout',
        'focus',
        'showexit',
        'exit',
    ],
    components: {},
    watch: {
        qry_executed(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch(`${ this.name }/force_reload`, true);
                this.$store.dispatch(`${ this.name }/executed`, false);
                this.$emit('saved', this.store.obj );
            }
        },
    },
    computed: {
        store () {
            return this.$store.state[ this.name ];
        },
        qry_executed () {
            return this.$store.state[ this.name ].executed;
        },
        _submit () {
            if ( this.submit === false ) {
                return false;
            }
            return true;
        },
        model_label () {
            //return ( extras[ this.name ].hasOwnProperty('_conf') && extras[ this.name ]._conf.hasOwnProperty('label') ) ? extras[ this.name ]._conf.label : this.name;
        },
        form_size () {
            return this.form_settings.hasOwnProperty('size') ? this.form_settings.size : undefined;
        },
        validators() {
            return this.form_settings.hasOwnProperty('validators') ? this.form_settings.validators : undefined;
        },
        element_class () {
            if ( this.form_layout ) { return this.form_layout.cols.length; }
            if ( this._layout === 'condensed' ) { return 2; }
            if ( typeof this._layout === 'undefinded' || this._layout === 'auto' ) { return 2; }
            if ( this._layout === 'single' ) { return 1; }
            return 2;
        },
        setfocus() { return this.focus ? true : false; },
        _layout() { // prop ha la precendeza su formconfig
            if ( this.layout ) { return this.layout; }
            else if ( this.form_settings && this.form_settings.layout ) { return this.form_settings.layout; }
            return this.layout;
        },
        form_layout() {
            if ( this.form_settings.hasOwnProperty('form_layout') ) { return this.form_settings.form_layout; }
            return null;
        },
        recordid() {
            if ( this.record ) {
                return this.record[ this.pk ? this.pk : 'id' ];
            }
            return null;
        }
    },
    created () {
        var pre_elements = typeof this.schema === 'function' ? this.schema(type_manager) : this.schema;
        this.elements = {};
        this.elements_column = { 'left': {}, 'right': {}, };
        // layout a una colonna
        Object.keys( pre_elements ).map( x => {
            var column = pre_elements[ x ];
            if (
                   column.type &&
                   [ 'autocomplete', 'enum', 'text', 'boolean', 'date', 'integer', 'virt', 'textarea', 'password', 'hidden', ].indexOf( column.type.element_type ) > -1
               ) {
                var frontend_configuration = ( extras.hasOwnProperty( this.name ) && extras[ this.name ].hasOwnProperty( x ) ) ? extras[ this.name ][ x ] : null;
                if ( frontend_configuration === null || frontend_configuration.hasOwnProperty('skip') === false || frontend_configuration.skip === false ) {
                    this.elements[ x ] = column.type;
                    column.edit = false;
                    if ( this.record && this.record[ this.pk ? this.pk : 'id' ] ) { column.edit = true; }
                    if ( this.record && this.record.hasOwnProperty( x ) ) { column.defaultValue = this.record[ x ]; } // in caso di edit
                    this.elements[ x ].set_schema(column, frontend_configuration); // frontend_configuration overrides backend configuration
                    var conf = new element_config( this.elements[ x ] );
                    this.elements[ x ].obj = conf.obj;
                }
            }
        });
        // layout a due colonne
        var left_tot = Object.keys( this.elements ).length / 2;
        if ( Number.isInteger( left_tot ) === false ) { left_tot += 0.5; }
        for ( var i = 0; i < Object.keys( this.elements ).length; i++ ) {
            var x = Object.keys( this.elements )[ i ];
            this.elements_column[ i < left_tot  ? 'left' : 'right' ][ x ] = this.elements[ x ];
        }
        // settings generici della form
        this.form_settings = extras.hasOwnProperty( this.name ) && extras[ this.name ].hasOwnProperty('settings') ? extras[ this.name ].settings : {};
    },
    mounted() {
        if ( Object.keys( this.elements ).length > 0 && this.focus ) {
            setTimeout( () => {
                this.$refs['bnvelement_' + Object.keys( this.elements )[0] ][0].$refs.input.focus();
            }, 500 );
        }
    },
    methods: {
        submit_form: function() {
            this.validate();
            if ( this.is_valid === false ) {
                return false;
            } else {
                if ( this._submit === true ) {
                    if ( this.has_file === true ) {
                        // form multi-type
                        var data = { schema: this.name, payload: this.form_data, include: true };
                        var pk = this.pk ? this.pk : 'id';
                        if ( this.record && this.record[ pk ] ) {
                            data[ pk ] = this.record[ pk ];
                            this.$store.dispatch(`${this.name}/updatews`, data);
                        } else {
                            this.$store.dispatch(`${this.name}/createws`, data);
                        }
                    } else {
                        // IL PROBLEMA È QUI GHESBIRO
                        var data = { schema: this.name, payload: this.form_data, include: true };
                        var pk = this.pk ? this.pk : 'id';
                        if ( this.record && this.record[ pk ] ) {
                            data[ pk ] = this.record[ pk ];
                            this.$store.dispatch(`${this.name}/update`, data);
                        } else {
                            this.$store.dispatch(`${this.name}/create`, data);
                        }
                    }
                } else {
                    // metto da parte eventuali obj file prima della conversione in json
                    var object_elements = {}
                    Object.keys( this.form_data ).map( x => {
                        if ( this.form_data !== null && typeof this.form_data[ x ] === 'object' ) {
                            object_elements[ x ] = this.form_data[ x ];
                        }
                    });
                    try {
                        var res = JSON.parse( JSON.stringify( this.form_data ) );
                        Object.keys( object_elements ).map( x => { res[ x ] = object_elements[ x ] } );
                        this.$emit('submitted', res);
                        
                        return res;
                    } catch (error) {
                        throw error;
                    }
                }
            }
            //console.log( 'has file :', this.has_file );
            //console.log( 'VALID    :', this.is_valid );
        },
        get_form_data() { // public
            this.validate();
            let res = JSON.stringify( this.form_data );
            return JSON.parse( res );
        },
        reset_form: function() {
            //this.$store.dispatch('bnvform/clear');
            this.$emit('reset', 1);
        },
        reseta: function() { // public - experimental 14/03/20
            Object.keys( this.elements ).map( element_name => {
                this.$refs[ `bnvelement_${ element_name }` ][0].reseta();
            });
        },
        reset_error( element_name ) {
                this.$refs[ `bnvelement_${ element_name }` ][0].reset_error();
        },
        set_payload(payload, force ) { // public, attributo force aggiunto il 20210907 - usato da sollecito / form
            for ( var i = 0; i < Object.keys( payload ).length; i++ ) {
                var element_name = Object.keys( payload )[ i ];
                this.$refs[ `bnvelement_${ element_name }` ][0].set_value( payload[ element_name ], force );
            }
        },
        set_error( payload ) { // public, creato il 2021-09-15
            for ( var i = 0; i < Object.keys( payload ).length; i++ ) {
                var element_name = Object.keys( payload )[ i ];
                this.$refs[ `bnvelement_${ element_name }` ][0].set_error( payload[ element_name ] );
            }
        },
        get_element_value( element_name ) { // public
            var value = this.$refs[ `bnvelement_${ element_name }` ][0].get_value();
            return value ? value : null;
        },
        get_value_by_element_name( element_name ) { // aggiunto 20210907 - non sono sicuro funzioni con tutti component (usato da sollecito/form)
            return this.$refs[ `bnvelement_${ element_name }` ][0].model;
        },
        validate() {
            this.is_valid = true;
            Object.keys( this.elements ).map( element_name => {
                var value        =  this.$refs[ `bnvelement_${ element_name }` ][0].get_value();
                var element_type =  this.$refs[ `bnvelement_${ element_name }` ][0].elementtype;
                var is_valid     = !this.$refs[ `bnvelement_${ element_name }` ][0].error_component;
                if ( element_type === 'file' ) { this.has_file = true; }
                if ( is_valid === false ) { this.is_valid = false; }
                this.form_data[ element_name ] = typeof value === 'string' ? value.trim() : value;
            });
        },
        error_message( name ) {
            if ( this.store.error === true ) {
                if ( this.store.obj && this.store.obj.hasOwnProperty('errors') && this.store.obj.errors.hasOwnProperty( name ) ) {
                    return this.store.obj.errors[ name ].message;
                }
            }
        },
        back_to_list() {
            if ( this.exit !== false ) {
                var list_path = this.listpath || 'list';
                this.$router.push( `/${ list_path }/${this.name}` );
            } else {
                this.$emit('closed', this.store.obj );
            }
        },
        value_changed( payload ) {
            this.$emit('change', payload );
        },
//      get_element_config( element ) {
//          var conf = new element_config( element );
//          return conf.obj;
//      },
        noop() {},
        generic_event( data ) {
//          if ( data.event_name === 'blur' ) {
//              return this.blur_event( data.payload );
//          }
            this.$emit( data.event_name, data.payload );
        },
//      blur_event( data ) {
//          let is_changed = false;
//          console.log( 'original', String( this.or_data[ data.name ] ) );
//          console.log( 'new     ', String( data.value ) );
//          if ( [ 'string', 'integer' ].includes( typeof data.value ) ) {
//              is_changed = String( this.or_data[ data.name ] || '' ) !== String( data.value );
//          }
//          if ( [ 'object' ].includes( typeof data.value ) ) {
//              is_changed = JSON.parse( JSON.stringify( this.or_data[ data.name ] ) ) !== JSON.parse( JSON.stringify( data.value ) );
//          }
//          if ( is_changed === true ) { this.or_data[ data.name ] = data.value; }
//          if ( is_changed === false ) { return; }
//          this.$emit( 'changed-data', data );
//      }
    },
    data () {
        return {
            is_valid  : true,
            form_data : {},
            has_file  : false,
        }
    },
};

</script>

<template>

    <div style="padding: 10px">
        <b-form :inline="false" v-on:submit.prevent="noop">

            <template v-if="form_layout">
                <b-container fluid="xs">

                    <b-row>
                        <b-col v-for="( col, index ) in form_layout.cols" :key="'form-' + name + '-' + index">
                            <template v-for="item in Object.keys( col )">
                                <component
                                    v-if           = "item.startsWith('field')"
                                    v-bind:key     = "col[ item ]"
                                    :name          = "col[ item ]"
                                    :ref           = "'bnvelement_' + col[ item ]"
                                    :disabled      = "store.loading"
                                    :errormessage  = "error_message(col[ item ])"
                                    :config        = "elements[ col[ item ] ].obj"
                                    :is            = "elements[ col[ item ] ].component"
                                    :elementtype   = "elements[ col[ item ] ].element_type"
                                    :value         = "elements[ col[ item ] ].default_value"
                                    :label         = "elements[ col[ item ] ].label"
                                    :helper        = "elements[ col[ item ] ].helper"
                                    :placeholder   = "elements[ col[ item ] ].placeholder"
                                    :store         = "elements[ col[ item ] ].store_name"
                                    :allownull     = "elements[ col[ item ] ].allow_null"
                                    :minlength     = "elements[ col[ item ] ].min_length"
                                    :maxlength     = "elements[ col[ item ] ].max_length"
                                    :precision     = "elements[ col[ item ] ].precision"
                                    :decimal       = "elements[ col[ item ] ].decimal"
                                    :maxsize       = "elements[ col[ item ] ].max_size"
                                    :maxvalue      = "elements[ col[ item ] ].max_value"
                                    :mimetypes     = "elements[ col[ item ] ].mime_types"
                                    :edit          = "elements[ col[ item ] ].edit"
                                    :readonly      = "elements[ col[ item ] ].readonly"
                                    :search        = "elements[ col[ item ] ].search"
                                    :filter        = "elements[ col[ item ] ].filter"
                                    :creator       = "elements[ col[ item ] ]"
                                    :recordid      = "recordid"
                                    :size          = "form_size"
                                    :layout        = "_layout"
                                    :display_error = "elements[ col[ item ] ].display_error"
                                    @change        = "value_changed"
                                    @enter_key     = "submit_form"
                                    @generic-event = "generic_event"
                                ></component>
                                <b-row class="form-group row" v-if="item.startsWith('row')" style="margin-bottom: 0" align-h="between">
                                    <template v-for="field in Object.keys( col[ item ] )">
                                        <b-col :cols="12 / Object.keys( col[ item ] ).length">
                                            <component
                                                v-if           = "col[ item ][ field ] !== null"
                                                v-bind:key     = "col[ item ][ field ]"
                                                :name          = "col[ item ][ field ]"
                                                :ref           = "'bnvelement_' + col[ item ][ field ]"
                                                :disabled      = "store.loading"
                                                :errormessage  = "error_message(col[ item ][ field ])"
                                                :config        = "elements[ col[ item ][ field ] ].obj"
                                                :is            = "elements[ col[ item ][ field ] ].component"
                                                :elementtype   = "elements[ col[ item ][ field ] ].element_type"
                                                :value         = "elements[ col[ item ][ field ] ].default_value"
                                                :label         = "elements[ col[ item ][ field ] ].label"
                                                :helper        = "elements[ col[ item ][ field ] ].helper"
                                                :placeholder   = "elements[ col[ item ][ field ] ].placeholder"
                                                :store         = "elements[ col[ item ][ field ] ].store_name"
                                                :allownull     = "elements[ col[ item ][ field ] ].allow_null"
                                                :minlength     = "elements[ col[ item ][ field ] ].min_length"
                                                :maxlength     = "elements[ col[ item ][ field ] ].max_length"
                                                :precision     = "elements[ col[ item ][ field ] ].precision"
                                                :decimal       = "elements[ col[ item ][ field ] ].decimal"
                                                :maxsize       = "elements[ col[ item ][ field ] ].max_size"
                                                :maxvalue      = "elements[ col[ item ][ field ] ].max_value"
                                                :mimetypes     = "elements[ col[ item ][ field ] ].mime_types"
                                                :edit          = "elements[ col[ item ][ field ] ].edit"
                                                :readonly      = "elements[ col[ item ][ field ] ].readonly"
                                                :search        = "elements[ col[ item ][ field ] ].search"
                                                :filter        = "elements[ col[ item ][ field ] ].filter"
                                                :creator       = "elements[ col[ item ][ field ] ]"
                                                :size          = "form_size"
                                                :layout        = "_layout"
                                                :display_error = "elements[ col[ item ][ field ] ].display_error"
                                                fields_per_row = "2"
                                                @change        = "value_changed"
                                                @enter_key     = "submit_form"
                                                @generic-event = "generic_event"
                                            ></component>
                                        </b-col>
                                    </template>
                                </b-row>
                            </template>
                        </b-col>
                    </b-row>
                    <div v-if="!inline" style="margin-bottom: 15px; margin-top: 10px;" class="text-center">
                        <b-button v-if="!inline" :size="form_size" style="margin-right: 10px;" @click="submit_form();" variant="success">Salva</b-button>
                        <b-button v-if="!inline" :size="form_size"  style="margin-right: 10px;" @click="reset_form();" variant="warning">Reset</b-button>
                        <b-button v-if="showexit !== false && !inline" :size="form_size"  style="margin-right: 10px;" @click="back_to_list();" variant="outline-secondary">
                            Esci
                        </b-button>
                        <!-- <button v-if="inline" style="margin-right: 10px;" type="button" @click="submit_form();" class="btn btn-outline-success">Filtra</button> -->
                    </div>

                </b-container>
            </template>

            <template v-else-if="!form_layout">
                <b-container fluid="xs">
                    <b-row cols="1" :cols-md="element_class">
                        <template v-for="(element, name) in elements">
                            <b-col style="margin: 0; padding: 5px;">
                                <component
                                    v-bind:key    = "name"
                                    :name         = "name"
                                    :ref          = "'bnvelement_' + name"
                                    :disabled     = "store.loading"
                                    :errormessage = "error_message(name)"
                                    :config       = "element.obj"
                                    :is           = "element.component"
                                    :elementtype  = "element.element_type"
                                    :value        = "element.default_value"
                                    :label        = "element.label"
                                    :helper       = "element.helper"
                                    :placeholder  = "element.placeholder"
                                    :store        = "element.store_name"
                                    :allownull    = "element.allow_null"
                                    :minlength    = "element.min_length"
                                    :maxlength    = "element.max_length"
                                    :precision    = "element.precision"
                                    :decimal      = "element.decimal"
                                    :maxsize      = "element.max_size"
                                    :maxvalue     = "element.max_value"
                                    :mimetypes    = "element.mime_types"
                                    :edit         = "element.edit"
                                    :readonly     = "element.readonly"
                                    :search       = "element.search"
                                    :filter       = "element.filter"
                                    :creator      = "element"
                                    :size         = "form_size"
                                    :layout       = "_layout"
                                    :display_error = "elements.display_error"
                                    @change       = "value_changed"
                                    @enter_key    = "submit_form"
                                ></component>
                            </b-col>
                        </template>
                    </b-row>
                    <div style="margin-bottom: 15px; margin-top: 10px;" class="text-center">
                        <b-button v-if="!inline" :size="form_size" style="margin-right: 10px;" @click="submit_form();" variant="success">Salva</b-button>
                        <b-button v-if="!inline" :size="form_size"  style="margin-right: 10px;" @click="reset_form();" variant="warning">Reset</b-button>
                        <b-button v-if="showexit !== false && !inline" :size="form_size"  style="margin-right: 10px;" @click="back_to_list();" variant="outline-secondary">
                            Esci
                        </b-button>
                        <!-- <button v-if="inline" style="margin-right: 10px;" type="button" @click="submit_form();" class="btn btn-outline-success">Filtra</button> -->
                    </div>
                </b-container>
            </template>

        </b-form>
    </div>

</template>

