//////////////////////////////

module.exports = (type) => {
    var schema = {
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue: type.NOW,
        },
        row: {
            type: type.INTEGER,
            allowNull: false,
        },
        data: {
            type: type.STRING(1024),
            allowNull: false,
        },
        label: {
            type: type.STRING(1024),
            allowNull: false,
        },
        codice: {
            type: type.FLOAT,
            primaryKey: true,
            get() {
                if ( this.getDataValue('codice') === null && typeof this.getDataValue('codice_pratica') === 'number' && typeof this.getDataValue('row') === 'number' ) {
                    var codice = null;
                    try {
                        codice = parseFloat([
                            String( this.getDataValue('codice_pratica') ),
                            this.getDataValue('row') < 10 ? `0${this.getDataValue('row')}` : this.getDataValue('row')
                        ].join('.') );
                    } catch(error) {}
                    if ( codice ) {
                        this.setDataValue( 'codice', codice );
                    }
                }
                return this.getDataValue('codice');
            },
        },
        closed: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: false,
        },
        sollecito_id: {
            type: type.INTEGER,
            allowNull: false,
        },
//      recipient: {
//          type: type.STRING(1024),
//          allowNull: false,
//          isEmail: {
//              msg: 'il valore non è un indirizzo email valido',
//          }
//      },
        to: {
            type: type.STRING(4096),
            allowNull: false,
            validate: {
                is_list_of_email_addresses: function(val) {
                    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    var tokens = val.split(';').map( x => x.trim() );
                    var res = [];
                    for ( var i = 0; i < tokens.length; i++ ) {
                        if ( tokens[ i ].length > 0 ) {
                            if ( re.test( tokens[ i ].toLowerCase() ) === null ) {
                                throw new Error(`l'indirizzo n.${i+1} non è un indirizzo email valido`);
                            }
                            res.push( tokens[ i ] );
                        }
                    }
                    this.setDataValue('to', res.join(';'));
                }
            }
        },
        cc: {
            type: type.STRING(4096),
            allowNull: false,
            validate: {
                is_list_of_email_addresses: function(val) {
                    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    var tokens = val.split(';').map( x => x.trim() );
                    var res = [];
                    for ( var i = 0; i < tokens.length; i++ ) {
                        if ( tokens[ i ].length > 0 ) {
                            if ( re.test( tokens[ i ].toLowerCase() ) === null ) {
                                throw new Error(`l'indirizzo n.${i+1} non è un indirizzo email valido`);
                            }
                            res.push( tokens[ i ] );
                        }
                    }
                    this.setDataValue('cc', res.join(';'));
                }
            }
        },
    };

    return schema;
}

