

module.exports = {
    cols: [
          { 
              row1: {
                  field1: 'denominazione',
                  field2: 'codice',
              },
          },
    ]
};

