<script>

import bnvtext from './bnvtext.vue';

export default {
    extends: bnvtext,
    props: [ 'placeholder', ],
    created() {
        this._validate();
    },
    watch: {
        _model( new_value ) {
            if ( this.timeout ) { clearTimeout( this.timeout ); }
            this.timeout = setTimeout( () => {
                this._validate();
            }, 500 );
        },
    },
    methods: {
        _validate: function() {
            var check = this._validate_allow_null();
            if ( check === true && !this._model ) { this._reset_state(); return check; }
            if ( check === true && this.model ) {
                check = this._validate_piva();
            }
            //return check;
            return true;
        },
        _validate_allow_null: function() {
            if ( this.allownull === false ) {
                if ( this.model === '' || typeof this.model === 'undefined' || this.model === null ) {
                    this.error_message = 'campo obbligatorio';
                    this.merror_component = true;
                    return false;
                }
            }
            return true;
        },
        _reset_state() {
            this.error_message    = null; 
            this.merror_component = false;
            this.is_valid        = null;
        },
        _set_error( msg ) {
            this.error_message    = msg; 
            this.merror_component = true;
            this.is_valid        = null;
        },
        _set_is_valid() {
            this.error_message    = null; 
            this.merror_component = false;
            this.is_valid        = true;
        },
        _validate_piva() {
    		if ( this.model.length !== 11 || !/^[0-9]{11}$/.test(this.model) ) {
                //this.error_message = 'La partita IVA deve essere composta da 11 caratteri numerici';
                //this.merror_component = true;
                this._set_error( 'La partita IVA deve essere composta da 11 caratteri numerici' );
                return false;
            } else {
                this._set_is_valid();
            }
    		var s = 0;
    		for ( var i = 0; i < 11; i++ ) {
    			var n = this.model.charCodeAt(i) - "0".charCodeAt(0);
    			if( (i & 1) === 1 ){
    				n *= 2;
    				if( n > 9 )
    					n -= 9;
    			}
    			s += n;
    		}
    		if ( s % 10 !== 0 ) {
                //this.error_message = 'Partita IVA non valida';
                //this.merror_component = true;
                this._set_error('Partita IVA non valida');
                return false;
            } else {
                this._set_is_valid();
            }
    		return true;
        },
    },
    data() {
        return { is_valid: null, timeout: null, merror_component: null };
    },
};

</script>

<template>

    <div class="form-group" :class="field_class" :style="field_style">
        <label :for="'bnv-form-' + name" :class="label_class">{{ get_label() }}</label>
        <div :class="element_container_class">
            <b-input
                v-on:keyup.enter  = "enter_key"
                ref               = "input"
                type              = "text"
                :size             = "input_class"
                v-bind:class      = "{ 'is-invalid': error_component, 'is-valid': is_valid }"
                :placeholder      = "get_placeholder()"
                :aria-label       = "name"
                :aria-describedby = "name"
                :id               = "'bnv-form-' + name"
                v-model           = "_model"
                :disabled         = "disabled"
                :readonly         = "_readonly"
            />
            <small v-if="helper" :id="'bnv-form-' + name + '-help'" class="form-text text-muted" v-html="_helper"></small>
            <div v-if="display_error"  class="invalid-feedback">
                {{ get_error_message() }}
            </div>
        </div>
    </div>

</template>

