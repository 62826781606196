/////////////////////////////////////////////////

import task from './task';

class task_panel {

    constructor( tasks ) {
        this._tasks = tasks.map( x => new task( x ) );
    }

    // static public methods ////////////////////

    // static private methods ///////////////////

    // public methods ///////////////////////////

    // private methods //////////////////////////

    // getters & setters ////////////////////////

    get tasks() {
        return this._tasks.filter( x => x.has_appuntamento );
    }

}

// static getters ///////////////////////////

export default task_panel;

