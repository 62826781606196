//////////////////////////////

module.exports = (type) => {
    var schema = {
        codice: {
            type: type.INTEGER,
            primaryKey: true,
            allowNull: false,
            autoIncrement: true,
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue: type.NOW,
        },
        closed: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: false,
        },
        owner: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            },
        },
        custodian: {
            type: type.INTEGER,
            allowNull: true,
            defaultValue: null,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            },
        },
        created_by: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'users',
                key         : 'id',
                constraints : true,
                store_name  : 'useradv',
            },
        },
        template_id: {
            type: type.VIRTUAL,
        },
        operator: {
            type: type.VIRTUAL,
        },
        operator_notes: {
            type: type.VIRTUAL,
        },
        newstate: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'newstates',
                key         : 'id',
                constraints : true,
                store_name  : 'newstate',
            },
        },
        parcel_mode: {
            type: type.STRING(64),
            allowNull: false,
        },
        parcel_mode_custodian: {
            type: type.STRING(64),
            allowNull: true,
        },
        data_evasione: {
            type: type.DATEONLY,
            allowNull: true,
        },
        data_evasione_custodian: {
            type: type.DATEONLY,
            allowNull: true,
        },
        imponibile: {
            type: type.DECIMAL(10,2),
            defaultValue: 0,
            get() {
                return parseFloat( this.getDataValue('imponibile') );
            }
        },
        imponibile_custodian: {
            type: type.DECIMAL(10,2),
            defaultValue: 0,
        },
        spese_non_imponibili: {
            type: type.DECIMAL(10,2),
            defaultValue: 0,
        },
        supplemento_pl: {
            type: type.DECIMAL(10,2),
            defaultValue: 0.00,
        },
        locomozione: {
            type: type.DECIMAL(10,2),
            defaultValue: 0.00,
        },
        visure: {
            type: type.DECIMAL(10,2),
            defaultValue: 0.00,
        },
        spese_non_imponibili_custodian: {
            type: type.DECIMAL(10,2),
            defaultValue: 0,
        },
        supplemento_pl_custodian: {
            type: type.DECIMAL(10,2),
            defaultValue: 0.00,
        },
        locomozione_custodian: {
            type: type.DECIMAL(10,2),
            defaultValue: 0.00,
        },
        visure_custodian: {
            type: type.DECIMAL(10,2),
            defaultValue: 0.00,
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        archived: {
            type: type.BOOLEAN,
            defaultValue: false,
        },
    };

    return schema;
}

