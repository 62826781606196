////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'parcel',
    send_alert    : false,
    default_order : 'id ASC',
};

var base = new base_module( options );

var getters = {
    ...base.getters,
    user_config: ( state ) => ( user_id, task_label ) => { // nome corretto: user_config_task
        return ( !user_id || state.items === null || state.items.length === 0 ) ? {} : state.items.filter( x => x.user_id === user_id && x.task_label === task_label )[0];
    },
    user_config_user: ( state ) => ( user_id ) => {
        return ( !user_id || state.items === null || state.items.length === 0 ) ? {} : state.items.filter( x => x.user_id === user_id && x.parcel_mode === 'user' )[0];
    },
    custodian_config: ( state ) => ( custodian_id, task_label ) => { // nome corretto: custodian_config_task
        return ( !custodian_id || state.items === null || state.items.length === 0 ) ? {} : state.items.filter( x => x.custodian_id === custodian_id && x.task_label === task_label )[0];
    },
    custodian_config_custodian: ( state ) => ( custodian_id ) => {
        return ( !custodian_id || state.items === null || state.items.length === 0 ) ? {} : state.items.filter( x => x.custodian_id === custodian_id && x.parcel_mode === 'user' )[0];
    },
}

export default {
    namespaced : true,
    ...base,
    getters,
};
