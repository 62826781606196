////////////////////////////////////////

export default class {

    constructor( schema_name, store, table ) {
        this.schema_name = schema_name;
        this.store       = store;
        this.table       = table;
        this._set_rows();
        this._set_totals();
        //console.log( 'total', this.total_amount );
    }

    // public methods /////////////////////

    quota_modified() {
        this._set_error_quota( this.quota_is_valid ? null : false );
        this._set_totals();
        if ( this.quota_is_valid ) { this._save_quota(); }
    }

    lock_modified() {
        if ( this.quota_is_valid ) { this._save_lock(); }
    }

    percentage_modified( id ) {
        //var rows = this.store.state[ this.schema_name ].items;
        for ( var i = 0; i < this.rows.length; i++  ) {
            if ( this.rows[ i ].id === id ) {
                var payload = {
                    percentage : this.rows[ i ].config.percentage || 0,
                    id         : id,
                };
                this.store.dispatch( `${ this.schema_name }/set_total_update`, 1 );
                this.store.dispatch( `${ this.schema_name }/save_percentage`, payload );
                break;
            }
        }
    }

    minimum_modified( id ) {
        //var rows = this.store.state[ this.schema_name ].items;
        for ( var i = 0; i < this.rows.length; i++  ) {
            if ( this.rows[ i ].id === id ) {
                var payload = {
                    minimum : this.rows[ i ].config.minimum || 0,
                    id         : id,
                };
                this.store.dispatch( `${ this.schema_name }/set_total_update`, 1 );
                this.store.dispatch( `${ this.schema_name }/save_minimum`, payload );
                break
            }
        }
    }

    // private methods ////////////////////

    _set_totals() {
        if ( this.rows.length === 0 ) { return; }
        if ( this.parcel_mode === 'user' ) {
            this.rows[ this.rows.length - 1 ].quota.set_value( this.total_quota );
        }
        this.rows[ this.rows.length - 1 ].amount.set_value( this.total_amount );
    }

    _save_lock() {
        var rows = this.store.state[ this.schema_name ].items;
        var updates = [];
        for ( var i = 0; i < rows.length - 1; i++  ) {
            var payload = {
                locked : this.rows[ i ].config.locked,
                id     : rows[ i ].id,
            };
            updates.push( payload );
        }
        this.store.dispatch( `${ this.schema_name }/set_total_update`, updates.length );
        updates.map( payload => { this.store.dispatch( `${ this.schema_name }/save_locked`, payload ); } )
    }

    _save_quota() {
        var rows = this.store.state[ this.schema_name ].items;
        var updates = [];
        for ( var i = 0; i < rows.length - 1; i++  ) {
            var payload = {
                quota    : this.rows[ i ].quota._value,
                id       : rows[ i ].id,
                defaults : { percentage: this.rows[ i ].defaults._percentage, minimum: this.rows[ i ].defaults._minimum, },
            };
            if ( this.rows[ i ].config.locked === true ) {
                payload.update_config = true;
            }
            updates.push( payload );
        }
        this.store.dispatch( `${ this.schema_name }/set_total_update`, updates.length );
        updates.map( payload => { this.store.dispatch( `${ this.schema_name }/save_quota`, payload ); } )
    }

    _set_error_quota( value ) {
        this.rows.map( x => { x.quota.set_error( value ); } );
    }

    _set_rows() {
        this.rows = [];
        var rows = this.store.state[ this.schema_name ].items;
        var total = 0;
        for ( var i = 0; i < rows.length; i++  ) {
            var row = rows[ i ];
            if ( typeof row.user_id === 'number' || typeof row.custodian_id === 'number' ) {
                var td = {
                    quota  : null,
                    config : {},
                    amount : null,
                };
                if ( this.parcel_mode === 'user' ) {
                    // modifica del 2023-08-18 per presunto cambiamento di bootstrap
//                  td.defaults          = this.table.$children[0].$refs.itemRows[ i ].$children[ 2 ];
//                  td.quota             = this.table.$children[0].$refs.itemRows[ i ].$children[ 3 ];
//                  td.config            = this.table.$children[0].$refs.itemRows[ i ].$children[ 4 ];
//                  td.amount            = this.table.$children[0].$refs.itemRows[ i ].$children[ 5 ];
                    td.defaults          = this.table.$children[0].$refs['item-rows'][ i ].$children[ 2 ];
                    td.quota             = this.table.$children[0].$refs['item-rows'][ i ].$children[ 3 ];
                    td.config            = this.table.$children[0].$refs['item-rows'][ i ].$children[ 4 ];
                    td.amount            = this.table.$children[0].$refs['item-rows'][ i ].$children[ 5 ];
                    td.id                = row.id;
                } else if ( this.parcel_mode === 'task' ) {
                    // modifica del 2023-08-18 per presunto cambiamento di bootstrap
//                  td.defaults          = this.table.$children[0].$refs.itemRows[ i ].$children[ 2 ];
//                  td.config            = this.table.$children[0].$refs.itemRows[ i ].$children[ 3 ];
//                  td.amount            = this.table.$children[0].$refs.itemRows[ i ].$children[ 4 ];
                    td.defaults          = this.table.$children[0].$refs['item-rows'][ i ].$children[ 2 ];
                    td.config            = this.table.$children[0].$refs['item-rows'][ i ].$children[ 3 ];
                    td.amount            = this.table.$children[0].$refs['item-rows'][ i ].$children[ 4 ];
                    td.id                = row.id;
                }
                this.rows.push( td );
            }
        }
    }

    _get_amount_ref( row ) {
        return this.parcel_mode === 'task' ? 'amount-' + row.user_id + '-' + row.task_label : `amount-${ row.user_id }`;
    }

    // getters & setters //////////////////

    get quota_is_valid() {
        return this.total_quota !== 100 ? false : true;
    }

    get parcel_mode() {
        return this.schema_name === 'pratica_parcel_user' ? 'user' : 'task';
    }

    get total_amount() {
        var total = 0;
        this.rows.map( x => { if ( x.id ) { total += parseFloat( x.amount.value ); } } );
        return total;
    }

    get total_quota() {
        var total = 0;
        this.rows.map( x => { if ( x.id ) { total += parseFloat( x.quota._value ); } } );
        return total;
    }

}

