<script>

export default {

    data() {
        return {
        }
    },
    computed: {
        user () {
        },
    },
    methods: {
        navigate_to(route) {
        },
    }
}

</script>

<template>

    <div>
        <b-navbar type="dark" fixed="bottom" style="background-color: white;">
            <b-nav right>
                <b-nav-item active v-b-modal.modal-filter><icon name="filter"/> Filtra</b-nav-item>
            </b-nav>
        </b-navbar>
    </div>

</template>

