////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'customer',
    send_alert    : false,
    default_order : 'label ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    filters: { active: true, }
};

const actions = {
    ...base.actions,
    get_all( { commit, state, getters, rootGetters }, _data = { options: {} } ) { // ok
        commit('start_request');
        commit('success_list', rootGetters['statemanager/customers'])
//      var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
//      commit('start_request');
//      data.options.order = getters.order;
//      // TODO non capisco perché questa riga è commentata in base.module
//      //      mi ha obbligato a creare questo store
//      Object.keys( state.filters ).map( key => { data.options[ key ] = state.filters[ key ]; } );
//      state.service.get_all(getters.model_name, data.options)
//          .then( response => {
//              if ( data.options.paginate === true ) { // gestione paginator
//                  commit('success_list', response.data);
//                  commit('set_total', response.tot);
//                  commit('set_page', response.page);
//                  commit('set_rows_per_page', response.rows_per_page);
//              } else {
//                  commit('success_list', response)
//              }
//          })
//          .catch( error => {
//              commit('error', error)
//          });
    },
};


export default {
    namespaced : true,
    ...base,
    state,
    actions,
};
