//////////////////////

import { periziaService } from '@/services';
const config = require('@/config.js')[ process.env.NODE_ENV || 'test' ];

export const perizia = {

    namespaced: true,
    state: {
        message        : '',
        variant        : '',
        loader         : false,
        ws             : null,
        codice_pratica : null,
        clear          : true, 
        shrink         : false, 
    },
    actions: {
        make({ commit, state }) {
            commit('set_clear');
            if ( config.backend_url.includes( 'https' ) ) {
                var ws = new WebSocket( config.backend_url.replace('https://', 'wss://') + `ws/pratica/?code=${state.codice_pratica}` );
            } else if ( config.backend_url.includes( 'http' ) ) {
                var ws = new WebSocket( config.backend_url.replace('http://', 'ws://') + `ws/pratica/?code=${state.codice_pratica}` );
            }
            commit('set_ws', ws );
            ws.onmessage = msg => commit('in_message', msg);
            periziaService.make_perizia(state.codice_pratica, state.shrink);
        },
        reset( { commit }, codice_pratica ) { commit('reset', codice_pratica) },
        set_codice_pratica( { commit }, codice_pratica ) { commit('set_codice_pratica', codice_pratica) },
        set_shrink( { commit }, shrink ) { commit('set_shrink', shrink) },
        set_clear( { commit } ) { commit('set_clear') },
    },
    mutations: {
        set_clear( state ) {
            state.clear = !state.clear;
        },
        reset( state, codice_pratica = null ) {
            var message_class = 'Generazione perizia';
            if ( codice_pratica ) { message_class += ` [${ codice_pratica }]` }
            state.loader         = false;
            state.message        = { 'class': message_class, action: 'Inizializzazione in corso' };
            state.codice_pratica = null;
            state.variant        = '';
            state.ws             = null;
        },
        set_ws( state, ws ) {
            state.ws = ws;
        },
        in_message( state, msg ) {
            var message = JSON.parse(msg.data);
            if ( message.hasOwnProperty('start') === true ) {
                state.loader = true;
            } else if ( message.hasOwnProperty('stop') === true ) {
                state.loader = false;
                state.ws.close();
                state.ws = null;
            } else {
                state.loader = true;
            }
            if ( message.level === 'info' ) {
                state.variant = '';
                if ( message.hasOwnProperty('success') === true ) {
                    state.variant = 'success';
                }
            } else if ( message.level === 'notice' ) {
                state.variant = 'warning';
                state.loader = false;
            } else if ( message.level === 'crit' ) {
                state.variant = 'danger';
                state.loader = false;
            } else if ( message.level === 'error' ) {
                state.variant = 'danger';
                state.loader = false;
            }
            state.message = message;
        },
        set_codice_pratica( state, codice_pratica ) {
            state.codice_pratica = codice_pratica;
        },
        set_shrink( state, shrink ) {
            state.shrink = shrink;
        },
    }

}


