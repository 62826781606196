///////////////////////////////////////////////////

import agenzia           from './form_agenzia.vue';
import compagnia         from './form_compagnia.vue';
import ente_pubblico     from './form_ente_pubblico.vue';
import ispettorato       from './form_ispettorato.vue';
import liquidatore       from './form_liquidatore.vue';
import persona_fisica    from './form_persona_fisica.vue';
import persona_giuridica from './form_persona_giuridica.vue';
import polizza           from './form_polizza';

export default {
    agenzia,
    compagnia,
    ente_pubblico,
    ispettorato,
    liquidatore,
    persona_fisica,
    persona_giuridica,
    polizza,
};

