//////////////////////////////

module.exports = (type) => {

    var schema = {
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        father_id: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'registries',
                key         : 'id',
                constraints : true,
                store_name  : 'registry',
            }
        },
        child_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'registries',
                key         : 'id',
                constraints : true,
                store_name  : 'registry',
            }
        },
        father_role_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'registry_roles',
                key         : 'id',
                constraints : true,
                store_name  : 'registry',
            }
        },
        child_role_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'registry_roles',
                key         : 'id',
                constraints : true,
                store_name  : 'registry',
            }
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

