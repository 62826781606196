//////////////////////////////

module.exports = (type) => {
    var schema = {
        codice: {
            type: type.INTEGER,
            allowNull: true,
        },
        'dati_pratica.studio': {
            label: 'studio',
            type: type.STRING(128),
            allowNull: true,
        },
        'dati_pratica.numero_pratica_esterno': {
            label: 'codice esterno',
            type: type.STRING(128),
            allowNull: true,
        },
        'assicurato.cognome,assicurato.denominazione': {
            type: type.STRING(128),
            allowNull: true,
        },
        'dati_pratica.data_incarico__gte': {
            type: type.DATEONLY,
            allowNull: true,
        },
        'dati_pratica.data_incarico__lte': {
            type: type.DATEONLY,
            allowNull: true,
        },
        data_evasione__gte: {
            type: type.DATEONLY,
            allowNull: true,
        },
        data_evasione__lte: {
            type: type.DATEONLY,
            allowNull: true,
        },
    };

    return schema;
}


