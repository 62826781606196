////////////////////

import base_module      from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'sollecito_document',
    send_alert    : false,
    default_order : 'label ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    customer_id : null,
};

const actions = {
    ...base.actions,
    get_all( { commit, state, getters, dispatch }, _data = { options: {} } ) {
        var data = JSON.parse( JSON.stringify( _data ) );
        commit('start_request');
        data.options.order = getters.order;
        //data.options.customer_id = state.customer_id;
        data.options.active = true;
        state.service.get_all(getters.model_name, data.options)
            .then( response => {
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response)
                }
                dispatch('force_reload');
            })
            .catch( error => {
                commit('error', error)
            });
    },
    create({ commit, state, getters }, data) {
        commit('start_request');
        var payload = data.payload;
        payload.customer_id = state.customer_id;
        state.service.create( getters.model_name, payload, data.include )
            .then( obj => {
                commit('success', obj);
            })
            .catch( error => {
                commit('error', error)
            });
    },
    set_customer_id( { commit }, customer_id ) {
        commit('set_customer_id', customer_id);
    },
};

const mutations = {
    ...base.mutations,
    set_customer_id( state, value ) { state.customer_id = value; },
    success( state, obj ) {
        state.obj           = obj;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.executed      = true;
        state.items.push( obj );
    },
};

export default {
    namespaced : true,
    state,
    getters : base.getters,
    actions,
    mutations,
};

