//////////////////////////////

// https://stackoverflow.com/questions/7922744/how-can-i-search-all-columns-in-a-table

module.exports = (type) => {

    var schema = {
        codice: {
            type: type.STRING(64),
            allowNull: false,
        },
        cgas: {
            type: type.VIRTUAL,
            allowNull: true,
            field_type: 'mtm',
            references: { store_name: 'cga_preg' },
        },
        attachments: {
            type: type.VIRTUAL,
            allowNull: true,
            field_type: 'multifile',
            references: {
                label      : true,
                note       : false,
                store_name : 'attachments',
            }
        },
        data_effetto: {
            type: type.DATEONLY,
            allowNull: true,
            defaultValue: null,
        },
        data_termine: {
            type: type.DATEONLY,
            allowNull: true,
            defaultValue: null,
        },
        data_pagamento_premio: {
            type: type.DATEONLY,
            allowNull: true,
            defaultValue: null,
        },
        note: {
            type: type.TEXT,
            allowNull: true,
        },
    };

    return schema;
}

