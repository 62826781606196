////////////////////

import axios from 'axios';
import base_module from '@/vue-model-mirror/stores/base.module';
import { authHeader } from '@/helpers';
const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

var options = {
    model_name    : 'ricerca',
    send_alert    : false,
    default_order : 'label ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    filters       : {},
    title_form    : 'Tipologie di Pratica',
    items         : null,
    page          : 1,
    active_list   : [],
    created       : false,
    newlist_error : null,
    ricerca_id    : null,
};

const actions = {
    ...base.actions,
    create({ commit, state, getters }, data) {
        commit('start_request');
        state.service.create( getters.model_name, data.payload, data.include )
            .then( obj => {
                commit('successcreate', obj);
            })
            .catch( error => {
                if ( error.message = 'un valore chiave duplicato viola il vincolo univoco "ricerca_user_id_label"' ) {
                    commit('errorcreate');
                } else {
                    commit('error', error)
                }
            });
    },
    get_all( { commit, state, getters }, _data = { options: {} } ) { // ok
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        commit('start_request');
        data.options.order = getters.order;
        data.options.include = true;
        //Object.keys( state.filters ).map( key => { data.options[ key ] = state.filters[ key ]; } );
        state.service.get_all(getters.model_name, data.options)
            .then( response => {
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
    set_active_obj( { commit }, ricerca_id ) {
        commit( 'set_active_list', ricerca_id )
    },
    create_relation( { commit, rootGetters, dispatch }, codice_pratica ) {
        let ricerca_id = rootGetters['appuntamento/ricerca_id'];
        let options = { headers: authHeader() };
        let url = config.backend_url + 'model/ricerca_pratica/';
        let body = { ricerca_id, codice_pratica };
        axios.post( url, body, options )
            .then( response => {
                let res = response.data;
                commit('update_ricerca_pratiche', { id: ricerca_id, pratiche: res });
                dispatch( 'appuntamento_list/force_reload', null, { root: true } );
            })
            .catch( error => {
                commit('error', error)
            });
    },
    destroy_relation( { commit, rootGetters, dispatch }, codice_pratica ) {
        let ricerca_id = rootGetters['appuntamento/ricerca_id'];
        let options = { headers: authHeader() };
        let url = config.backend_url + 'model/ricerca_pratica/remove';
        let body = { ricerca_id, codice_pratica };
        axios.post( url, body, options )
            .then( response => {
                let res = response.data;
                commit('update_ricerca_pratiche', { id: ricerca_id, pratiche: res });
                dispatch( 'appuntamento_list/force_reload', null, { root: true } );
                dispatch( 'appuntamento/force_reload', null, { root: true } );
            })
            .catch( error => {
                commit('error', error)
            });
    },
    destroy_ricerca( { commit, dispatch }, ricerca_id ) {
        let options = { headers: authHeader() };
        let url = config.backend_url + 'model/ricerca/' + String( ricerca_id );
        axios.delete( url, options )
            .then( response => {
                dispatch('force_reload');
                dispatch('get_all');
                dispatch( 'appuntamento/force_reload', null, { root: true } );
            })
            .catch( error => {
                commit('error', error)
            });
        
    },
};

const mutations = {
    ...base.mutations,
    successcreate( state, obj ) {
        state.obj           = obj;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.executed      = false;
        state.created       = true;
        setTimeout( () => { state.created = false; }, 250 );
    },
    errorcreate( state ) {
        state.obj           = null;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.executed      = false;
        state.created       = false;
        state.newlist_error = 'La lista esiste già.';
        setTimeout( () => { state.newlist_error = null; }, 250 );
    },
    set_active_list( state, ricerca_id ) {
        if ( !ricerca_id ) { state.active_list = []; state.ricerca_id = null; return; }
        if ( !state.items ) { console.log('ERROR: ricerca.mutations.set_active_list - state.items is null'); return; }
        let active_obj = null;
        for ( var i = 0; i < state.items.length; i++ ) {
            if ( state.items[ i ].id === ricerca_id ) { active_obj = state.items[ i ]; break; }
        }
        if ( !active_obj ) { console.log('ERROR: ricerca.mutations.set_active_list - active_obj is null'); return; }
        state.ricerca_id = ricerca_id;
        let items = [];
        active_obj.pratiche.map( x => { items.push( x.codice_pratica ); } );
        state.active_list = items;
    },
    update_ricerca_pratiche( state, obj ) {
        let active_obj = null;
        for ( var i = 0; i < state.items.length; i++ ) {
            if ( state.items[ i ].id === obj.id ) { active_obj = state.items[ i ]; break; }
        }
        if ( !active_obj ) { console.log('ERROR: ricerca.mutations.update_ricerca_pratiche - active_obj is null'); return; }
        active_obj.pratiche = obj.pratiche;
        let items = [];
        active_obj.pratiche.map( x => { items.push( x.codice_pratica ); } );
        state.active_list = items;
        state.items[ i ] = active_obj;
    }
};


export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
};

