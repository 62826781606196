//////////////////////////////

module.exports = (type) => {

    var schema = {
        file: {
            type: type.VIRTUAL,
            file: true,
            allowNull: false,
            //validate: {
            //},
        },
        name: {
            type: type.STRING(64),
            allowNull: false,
        },
        description: {
            type: type.TEXT,
            allowNull: true,
            defaultValue: null,
        },
    };

    return schema;
}

