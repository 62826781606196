////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'pratica_parcel',
    send_alert    : false,
    default_order : 'id ASC',
};

var base = new base_module( options );

var state = {
    ...base.state,
    done            : false,
    total_update    : 0,
    executed_update : 0,
    customer_id     : null,
};

var actions = {
    ...base.actions,

    get_all( { commit, state, getters, rootGetters }, _data = { options: {} } ) { // ok
        commit('start_request');
        var pratica = rootGetters['pratica/get_obj'];
        state.service.get_all('pratica_parcel', { codice_pratica: pratica.codice, order: 'custodian_id ASC|id ASC' })
            .then( rows => {
                var items = rows.filter( x => x.customer_id === state.customer_id );
                if ( items.length > 0 ) {
                    items.push({
                        id             : null,
                        codice_pratica : null,
                        user_id        : 0,
                        custodian_id   : null,
                        task_label     : null,
                        quota          : null,
                        percentage     : null,
                        minimum        : null,
                    });
                }
                commit('success_list', items)
            })
            .catch( error => {
                commit('error', error)
            });
    },

    save_minimum( { commit, state, getters, dispatch }, data ) {
        var payload = {
            minimum : data.minimum,
        };
        state.service.update( getters.model_name, data.id, payload, false )
            .then( obj => {
                commit('add_executed', obj.id);
                dispatch('pratica/update_pratica_parcel', obj, { root: true });
            })
            .catch( error => {
                //commit('error', error)
            });
    },

    save_percentage( { commit, state, getters, dispatch }, data ) {
        var payload = {
            percentage : data.percentage,
        };
        state.service.update( getters.model_name, data.id, payload, false )
            .then( obj => {
                commit('add_executed', obj.id);
                dispatch('pratica/update_pratica_parcel', obj, { root: true });
            })
            .catch( error => {
                //commit('error', error)
            });
    },

    set_total_update( { commit }, tot ) { commit('set_total_update', tot); },
    set_customer_id( { commit }, customer_id ) { commit('set_customer_id', customer_id); },
    clear_procedure( { commit } ) { commit('clear_procedure'); },

};

var mutations = {
    ...base.mutations,
    set_total_update( state, tot ) { state.total_update = tot; },
    set_customer_id( state, customer_id ) { state.customer_id = customer_id; },
    add_executed( state, id ) {
        state.executed_update += 1;
        if ( state.executed_update === state.total_update ) {
            state.done = true;
        }
    },
    clear_procedure( state ) {
        state.done            = false;
        state.total_update    = 0;
        state.executed_update = 0;
        state.updated_id      = null;
    },
};

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
};
