<script>

//import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    name: 'appuntamento-card-details',
    props: [ 'item', 'fields' ],
    components : {},
    computed : {
//      ...mapState('store', {
//          localattribute : state => state.attribute,
//      }),
//      ...mapGetters( 'store', [ 'attribute' ] ),
    },
    watch: {},
    methods : {
//      ...mapActions( 'store', { local_action: 'action' } ),
        get_name( obj ) {
            if ( typeof obj === 'string' ) { return obj; }
            if ( obj.hasOwnProperty('label') ) {
                return obj.label;
            }
            return obj.key;
        },
        _get_value_from_string( str, obj ) {
            if ( obj === null ) { return null; }
            if ( str.includes('.') ) {
                var tokens = str.split('.');
                var value = obj;
                for ( var i = 0; i < tokens.length; i++ ) {
                    if ( !value || value.hasOwnProperty( tokens[ i ] ) === false ) { return null; }
                    value = value[ tokens[ i ] ];
                }
                return value;
            }
            return obj[ str ];
        },
        filter( obj ) {
            if ( typeof obj === 'string' ) { return this.set_mancante( this.row[ obj ] ); }
            var res = null;
            if ( obj.hasOwnProperty('formatter') ) {
                return this.set_mancante( filters[ obj.formatter ]( this._get_value_from_string( obj.key, this.row ) ) );
            }
            if ( obj.hasOwnProperty('htmlformatter') ) {
                if ( typeof obj.htmlformatter === 'string' ) {
                    return this.set_mancante( filters[ obj.htmlformatter ]( this._get_value_from_string( obj.key, this.row ) ) );
                } else if ( typeof obj.htmlformatter === 'function' ) {
                    return this.set_mancante( obj.htmlformatter( this.row ) );
                }
            }
            return this.set_mancante( this._get_value_from_string( obj.key, this.row ) );
        },
        set_mancante(res) {
            return res ? res : '<span style="color: lightgrey">dato mancante</span>';
        },
        get_class( field ) {
            if ( field.hasOwnProperty( 'class' )  ) {
                if ( Array.isArray(field.class) ) {
                    return [ field.class[0], field.class[2] ].join(' ');
                }
            } else {
                return field.class;
            }
            return '';
        },
        check_display( obj ) {
            return true;
        },
        check_component( obj ) {
            return obj.hasOwnProperty('component') ? true : false;
        },
        emit_event( data ) {
            this.$emit('genericevent', data );
        },
    },
    created() {},
    data() {
        return {
            col_style : { 'border': '0px solid lightgrey', 'margin-bottom': '8px', 'margin-left': '0', 'margin-right': '0', 'padding': '0', 'padding-left': '3px', 'madding-right': '4px,'}
        }
    },
}

</script>

<template>
    <div>
        <b-card>
            <b-row class="margin: 0; padding: 0;">
                <!-- component: assegnazioni -->
                <b-col cols="12" md="6" lg="3" v-bind:key="'detail-card-' + get_name( fields[ 0 ] ) + '-cmp'" :class="get_class(fields[ 0 ])" :style="col_style">
                    <h6 class="text-center" style="padding-top: 3px;; padding-bottom: 3px; margin-top: 8px; background-color: lightgrey;">
                        <b>{{ get_name(fields[ 0 ]) ? get_name(fields[ 0 ]) : '' }}</b>
                    </h6>
                    <component
                        v-bind:key    = "fields[ 0 ].key"
                        :name         = "fields[ 0 ].key"
                        :is           = "fields[ 0 ].component"
                        :field_name   = "fields[ 0 ].key"
                        :item         = "item"
                        @genericevent = "emit_event"
                    ></component>
                </b-col>
                <!-- component: cliente -->
                <b-col cols="12" md="6" lg="3" v-bind:key="'detail-card-' + get_name( fields[ 1 ] ) + '-cmp'" :class="get_class(fields[ 1 ])" :style="col_style">
                    <h6 class="text-center" style="padding-top: 3px;; padding-bottom: 3px; margin-top: 8px; background-color: lightgrey;">
                        <b>{{ get_name(fields[ 1 ]) ? get_name(fields[ 1 ]) : '' }}</b>
                    </h6>
                    <component
                        v-bind:key    = "fields[ 1 ].key"
                        :name         = "fields[ 1 ].key"
                        :is           = "fields[ 1 ].component"
                        :field_name   = "fields[ 1 ].key"
                        :item         = "item"
                        @genericevent = "emit_event"
                    ></component>
                </b-col>
                <!-- string: numero_sinistro -->
                <b-col cols="12" md="6" lg="3" v-bind:key="'detail-card-' + get_name( fields[ 2 ] ) + '-filter'" :class="get_class(fields[ 2 ])" :style="col_style">
                    <h6 class="text-center" style="padding-top: 3px; padding-bottom: 3px; margin-top: 8px; background-color: lightgrey;">
                        <b>{{ get_name(fields[ 2 ]) ? get_name(fields[ 2 ]) : '&nbsp;' }}</b>
                    </h6>
                    <p v-html="filter(fields[ 2 ])"></p>
                </b-col>
                <!-- string: esterno -->
                <b-col cols="12" md="6" lg="3" v-bind:key="'detail-card-' + get_name( fields[ 3 ] ) + '-filter'" :class="get_class(fields[ 3 ])" :style="col_style">
                    <h6 class="text-center" style="padding-top: 3px; padding-bottom: 3px; margin-top: 8px; background-color: lightgrey;">
                        <b>{{ get_name(fields[ 3 ]) ? get_name(fields[ 3 ]) : '&nbsp;' }}</b>
                    </h6>
                    <p v-html="filter(fields[ 3 ])"></p>
                </b-col>
                <!-- component: note_pratica -->
                <b-col cols="12" md="12" lg="12" v-bind:key="'detail-card-' + get_name( fields[ 4 ] ) + '-cmp'" :class="get_class(fields[ 4 ])" :style="col_style">
                    <h6 class="text-center" style="padding-top: 3px;; padding-bottom: 3px; margin-top: 8px; background-color: lightgrey;">
                        <b>{{ get_name(fields[ 4 ]) ? get_name(fields[ 4 ]) : '' }}</b>
                    </h6>
                    <component
                        v-bind:key    = "fields[ 4 ].key"
                        :name         = "fields[ 4 ].key"
                        :is           = "fields[ 4 ].component"
                        :field_name   = "fields[ 4 ].key"
                        :item         = "item"
                        @genericevent = "emit_event"
                    ></component>
                </b-col>
                <!-- component: assicurato -->
                <b-col cols="12" md="6" lg="6" v-bind:key="'detail-card-' + get_name( fields[ 5 ] ) + '-cmp'" :class="get_class(fields[ 5 ])" :style="col_style">
                    <h6 class="text-center" style="padding-top: 3px;; padding-bottom: 3px; margin-top: 8px; background-color: lightgrey;">
                        <b>{{ get_name(fields[ 5 ]) ? get_name(fields[ 5 ]) : '' }}</b>
                    </h6>
                    <component
                        v-bind:key    = "fields[ 5 ].key"
                        :name         = "fields[ 5 ].key"
                        :is           = "fields[ 5 ].component"
                        :field_name   = "fields[ 5 ].key"
                        :item         = "item"
                        @genericevent = "emit_event"
                    ></component>
                </b-col>
                <!-- component: controparte -->
                <b-col cols="12" md="6" lg="6" v-bind:key="'detail-card-' + get_name( fields[ 6 ] ) + '-cmp'" :class="get_class(fields[ 6 ])" :style="col_style">
                    <h6 class="text-center" style="padding-top: 3px;; padding-bottom: 3px; margin-top: 8px; background-color: lightgrey;">
                        <b>{{ get_name(fields[ 6 ]) ? get_name(fields[ 6 ]) : '' }}</b>
                    </h6>
                    <component
                        v-bind:key    = "fields[ 6 ].key"
                        :name         = "fields[ 6 ].key"
                        :is           = "fields[ 6 ].component"
                        :field_name   = "fields[ 6 ].key"
                        :item         = "item"
                        @genericevent = "emit_event"
                    ></component>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<style>
</style>
