////////////////////

const axios = require('axios');

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];
import { folder_service } from '@/services';
import base_module from '@/vue-model-mirror/stores/base.module';
import { authHeader } from '@/helpers';

var options = {
    model_name    : 'folder',
    send_alert    : false,
    default_order : 'last_name ASC',
};

var base = new base_module( options, folder_service );

const state = {
    ...base.state,
    created             : false,
    createdws           : false,
    freeze              : false,
    previous_folder_ids : [],
    upload_counter      : { tot: 0, done: 0 },
    zip_loading         : false,
    zip_message         : {},
    timeout             : null,
    merged              : false,
};

const actions = {
    ...base.actions,
    get_all({ commit, state }, data = { options: {} }) {
        commit('start_request');
        data.options.order  = state.order;
        folder_service.get_all('folder', data.options)
            .then( response => {
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
    merge_files( { commit, state }, payload ) {
        var url = `${config.backend_url}model/folder/merge`;
        var options = { headers: authHeader() };
        commit('start_request');
        axios.post( url, payload, options )
            .then( response => {
                //if ( response.status !== 200 ) {
                //    commit( 'in_message', { action: 'Errore inizializzazione', variant: 'danger', stop: 'true', class: 'Creazione archivio' } );
                //}
                console.log( response.data );
                commit('success_list', response.data);
                commit( 'merged' );
            })
            .catch( error => { console.log( error ); } )
    },
    create({ commit, state, getters }, data) {
        commit('start_request');
        state.service.create( getters.model_name, data.payload, data.include )
            .then(response => {
                commit('success_create');
                commit('success_list', response);
            })
            .catch( error => {
                commit('error', error)
                commit('success_create');
            });
    },
    rename({ commit, state, getters }, data) {
        commit('start_request');
        state.service.update( getters.model_name, data.payload.id, { name: data.payload.name }, data.include )
            .then(response => {
                commit('success_update');
                commit('success_list', response);
            })
            .catch( error => {
                commit('error', error)
                commit('success_update');
            });
    },
    move({ commit, state, getters }, data) {
        commit('start_request');
        state.service.move( data )
            .then(response => {
                //commit('success_update');
                commit('success_list', response);
            })
            .catch( error => {
                commit('error', error)
                //commit('success_update');
            });
    },
    delete({ commit, state, getters }, item_id) {
        commit('start_request');
        state.service.delete_item( getters.model_name, item_id )
            .then(response => {
                commit('success_list', response);
            })
            .catch( error => {
                commit('error', error)
            });
    },
    set_upload_tot( { commit }, files_number ) { commit( 'set_upload_tot', files_number ); },
    async createws_multiple( { state, commit, dispatch }, conf ) {
        commit( 'set_upload_tot', conf.files.length );
        var data = {
            codice_pratica : conf.codice_pratica,
        };
        for ( var i = 0; i < conf.files.length; i++ ) {
            data.payload = { file: conf.files[ i ], };
            if ( conf.folder_id ) {
                data.folder_id = conf.folder_id;
            } else {
                data.payload.codice_pratica = data.codice_pratica;
            }
            await dispatch( 'createws', data );
        }
    },
    async createws({ state, commit, dispatch, getters }, data) {
        commit('start_request');
        var method = null;
        var options_select = { options: { codice_pratica: data.codice_pratica, include: true } };
        if ( data.payload.hasOwnProperty('codice_pratica') ) {
            method = 'createws_on_root';
            options_select.options.parentId = 'null';
        } else {
            method = 'createws';
            options_select.options.parentId = data.folder_id;
        }
        await state.service[ method ]( getters.model_name, data )
            .then( response => {
                commit('success_upload');
                commit('success_list', response);
                if ( state.upload_counter.tot > 0 ) {
                    commit('add_upload_done' );
                    if ( state.upload_counter.tot === state.upload_counter.done ) {
                        commit('reset_upload_counter' );
                    }
                }
            })
            .catch( error => {
                if ( state.upload_counter.tot > 0 ) {
                    commit('add_upload_done' );
                    if ( state.upload_counter.tot === state.upload_counter.done ) {
                        commit('reset_upload_counter' );
                    }
                }
                commit('error', error)
            });
    },
    close_modal({commit}) { commit('closemodal'); },
    ovveride_meta({commit}, data) {
        commit('overridemeta', data);
    },
    update({ commit, state, getters }, data) {
        commit('start_request');
        state.service.update( getters.model_name, data.id, data.payload, data.include )
            .then( obj => {
                commit('success', obj);
            })
            .catch( error => {
                commit('error', error)
            });
    },
    freeze({commit}) { commit('freeze'); },
    set_folder_ids({commit}, folder_ids) {
        commit('set_folder_ids', folder_ids);
    },
    add_folder_id({commit}, folder_id) {
        commit('add_folder_id', folder_id);
    },
    download_multiple( { commit, dispatch }, payload ) {
        dispatch('start_zip', payload.codice_pratica);
        var url = `${config.backend_url}model/folder/zip`;
        var options = { headers: authHeader() };
        axios.post( url, payload, options )
            .then( response => {
                if ( response.status !== 200 ) {
                    commit( 'in_message', { action: 'Errore inizializzazione', variant: 'danger', stop: 'true', class: 'Creazione archivio' } );
                }
            })
            .catch( error => { console.log( error ); } )
    },
    start_zip( { commit }, codice_pratica ) {
        var wspath = `zip${ codice_pratica }`;
        if ( config.backend_url.includes( 'https' ) ) {
            var ws = new WebSocket( config.backend_url.replace('https://', 'wss://') + `ws/zip?code=${ codice_pratica }` );
        } else if ( config.backend_url.includes( 'http' ) ) {
            var ws = new WebSocket( config.backend_url.replace('http://', 'ws://') + `ws/zip?code=${ codice_pratica }` );
        }
        commit('set_ws', ws );
        console.log( 'connessione ws zip aperta' );
        commit( 'set_zip_loading' );
        commit( 'in_message', { action: 'Inizializzazione', class: 'Creazione archivio' } );
        ws.onmessage = msg => {
            var message = JSON.parse(msg.data);
            console.log( message );
            commit('in_message', message);
            if ( message.hasOwnProperty('stop') === true ) {
                commit('close_ws');
                var timeout = setTimeout( () => {
                    commit( 'set_zip_loading', false );
                }, 20000);
                commit('set_timeout', timeout);
            }
        }
    },
    set_zip_loading( { commit }, value ) {
        commit('set_zip_loading', false);
        commit('set_timeout', null);
    },
};

const mutations = {
    ...base.mutations,
    merged( state ) {
        state.merged = true;
        setTimeout( () => { state.merged = false; }, 200 );
    },
    set_timeout( state, timeout ) {
        if ( state.timeout ) { clearTimeout( state.timeout ); }
        state.timeout = timeout;
    },
    set_ws( state, ws ) {
        state.ws = ws;
    },
    close_ws( state ) {
        if ( state.ws ) {
            state.ws.close();
            state.ws = null;
            console.log( 'connessione ws zip chiusa' );
        }
    },
    set_zip_loading( state, value = true ) {
        console.log( 'set_zip_loading:', value );
        state.zip_loading = value;
    },
    in_message( state, message ) {
        state.zip_message = message;
    },
    set_upload_tot( state, tot ) {
        state.upload_counter.tot  = tot;
        state.upload_counter.done = 0;
    },
    reset_upload_counter( state ) {
        state.upload_couter.tot  = 0;
        state.upload_couter.done = 0;
    },
    add_upload_done( state ) {
        state.upload_counter.done += 1;
    },
    add_folder_id( state, folder_id ) {
        state.previous_folder_ids.push( folder_id );
    },
    set_folder_ids(state, folder_ids) {
        state.previous_folder_ids = folder_ids;
    },
    freeze(state) {
        state.freeze = !state.freeze;
    },
    success_list( state, items ) {
        var folders = [];
        var files   = [];
        for ( var i = 0; i < items.length; i++ ) {
            if ( items[ i ].task_image_id === null ) {
                folders.push( items[ i ] );
            } else {
                files.push( items[ i ] );
            }
        }
        state.items    = folders.concat( files );
        state.loading  = false;
        state.error    = false;
        state.executed = true;
    },
    success_create( state, items ) {
        state.created = true;
    },
    success_update( state, items ) {
        state.updated = true;
    },
    success_upload( state, items ) {
        state.createdws = true;
    },
    executed(state) {
        state.executed  = false;
        state.created   = false;
        state.updated   = false;
        state.createdws = false;
    },
};

export default {
    namespaced : true,
    state,
    actions,
    mutations,
    getters: base.getters,
};

////////////////////

//import { folder_service } from '@/services';
//
//export const folder = {
//    namespaced: true,
//    state: {
//        title_form          : 'folder',
//        obj                 : {},
//        items               : null,
//        loading             : false,
//        error               : false,
//        created             : false,
//        updated             : false,
//        createdws           : false,
//        executed            : false,
//        force_reload        : false,
//        page                : 1,  // default
//        rows_per_page       : 25, // default
//        tot                 : 0,
//        order               : null,
//        page                : 1,  // default
//        rows_per_page       : 25, // default
//        tot                 : 0,
//        selected            : false,
//        freeze              : false,
//        previous_folder_ids : [],
//        upload_counter      : { tot: 0, done: 0 },
//    },
//    actions: {
//        get_by_id({commit}, data) {
//            commit('start_request');
//            folder_service.get_by_id('folder', data.id, data.include)
//                .then( rows => {
//                    commit('success_obj', rows)
//                })
//                .catch( error => {
//                    commit('error', error)
//                });
//        },
//        get_all({ commit, state }, data = { options: {} }) {
//            commit('start_request');
//            data.options.order  = state.order;
//            folder_service.get_all('folder', data.options)
//                .then( response => {
//                    if ( data.options.paginate === true ) { // gestione paginator
//                        commit('success_list', response.data);
//                        commit('set_total', response.tot);
//                        commit('set_page', response.page);
//                        commit('set_rows_per_page', response.rows_per_page);
//                    } else {
//                        commit('success_list', response)
//                    }
//                })
//                .catch( error => {
//                    commit('error', error)
//                });
//        },
//        create({ commit }, data) {
//            commit('start_request');
//            folder_service.create( 'folder', data.payload, data.include )
//                .then(response => {
//                    commit('success_create');
//                    commit('success_list', response);
//                })
//                .catch( error => {
//                    commit('error', error)
//                    commit('success_create');
//                });
//        },
//        rename({ commit }, data) {
//            commit('start_request');
//            folder_service.update( 'folder', data.payload.id, { name: data.payload.name }, data.include )
//                .then(response => {
//                    commit('success_update');
//                    commit('success_list', response);
//                })
//                .catch( error => {
//                    commit('error', error)
//                    commit('success_update');
//                });
//        },
//        move({ commit }, data) {
//            commit('start_request');
//            folder_service.move( data )
//                .then(response => {
//                    //commit('success_update');
//                    commit('success_list', response);
//                })
//                .catch( error => {
//                    commit('error', error)
//                    //commit('success_update');
//                });
//        },
//        delete({ commit }, item_id) {
//            commit('start_request');
//            folder_service.delete_item( 'folder', item_id )
//                .then(response => {
//                    commit('success_list', response);
//                })
//                .catch( error => {
//                    commit('error', error)
//                });
//        },
//        set_upload_tot( { commit }, files_number ) { commit( 'set_upload_tot', files_number ); },
//        async createws_multiple( { state, commit, dispatch }, conf ) {
//            commit( 'set_upload_tot', conf.files.length );
//            var data = {
//                codice_pratica : conf.codice_pratica,
//            };
//            for ( var i = 0; i < conf.files.length; i++ ) {
//                data.payload = { file: conf.files[ i ], };
//                if ( conf.folder_id ) {
//                    data.folder_id = conf.folder_id;
//                } else {
//                    data.payload.codice_pratica = data.codice_pratica;
//                }
//                await dispatch( 'createws', data );
//            }
//        },
//        async createws({ state, commit, dispatch }, data) {
//            commit('start_request');
//            var method = null;
//            var options_select = { options: { codice_pratica: data.codice_pratica, include: true } };
//            if ( data.payload.hasOwnProperty('codice_pratica') ) {
//                method = 'createws_on_root';
//                options_select.options.parentId = 'null';
//            } else {
//                method = 'createws';
//                options_select.options.parentId = data.folder_id;
//            }
//            await folder_service[ method ]( 'folder', data )
//                .then( response => {
//                    commit('success_upload');
//                    commit('success_list', response);
//                    if ( state.upload_counter.tot > 0 ) {
//                        commit('add_upload_done' );
//                        if ( state.upload_counter.tot === state.upload_counter.done ) {
//                            commit('reset_upload_counter' );
//                        }
//                    }
//                })
//                .catch( error => {
//                    if ( state.upload_counter.tot > 0 ) {
//                        commit('add_upload_done' );
//                        if ( state.upload_counter.tot === state.upload_counter.done ) {
//                            commit('reset_upload_counter' );
//                        }
//                    }
//                    commit('error', error)
//                });
//        },
//        force_reload({commit}) { commit('force_reload'); },
//        executed({commit}) { commit('executed'); },
//        clear({commit}) { commit('clear'); },
//        close_modal({commit}) { commit('closemodal'); },
//        ovveride_meta({commit}, data) {
//            commit('overridemeta', data);
//        },
//        update({ commit }, data) {
//            commit('start_request');
//            folder_service.update( 'folder', data.id, data.payload, data.include )
//                .then( obj => {
//                    commit('success', obj);
//                })
//                .catch( error => {
//                    commit('error', error)
//                });
//        },
//        force_reload({commit}) { commit('force_reload'); },
//        executed({commit}) { commit('executed'); },
//        set_order( {commit}, value ) {
//            commit('setorder', value);
//        },
//        freeze({commit}) { commit('freeze'); },
//        set_folder_ids({commit}, folder_ids) {
//            commit('set_folder_ids', folder_ids);
//        },
//        add_folder_id({commit}, folder_id) {
//            commit('add_folder_id', folder_id);
//        }
//    },
//    mutations: {
//        set_upload_tot( state, tot ) {
//            state.upload_counter.tot  = tot;
//            state.upload_counter.done = 0;
//        },
//        reset_upload_counter( state ) {
//            state.upload_couter.tot  = 0;
//            state.upload_couter.done = 0;
//        },
//        add_upload_done( state ) {
//            state.upload_counter.done += 1;
//        },
//        add_folder_id( state, folder_id ) {
//            state.previous_folder_ids.push( folder_id );
//        },
//        set_folder_ids(state, folder_ids) {
//            state.previous_folder_ids = folder_ids;
//        },
//        freeze(state) {
//            state.freeze = !state.freeze;
//        },
//        setorder ( state, value ) {
//            state.order = value;
//        },
//        start_request( state ) {
//            state.loading      = true;
//            state.error        = false;
//            state.executed     = false;
//            state.force_reload = false;
//        },
//        success( state, obj ) {
//            state.obj      = obj;
//            state.loading  = false;
//            state.error    = false;
//            state.executed = true;
//        },
//        success_obj( state, obj ) {
//            state.obj      = obj;
//            state.loading  = false;
//            state.error    = false;
//            state.executed = false;
//        },
//        success_create( state, items ) {
//            state.created = true;
//        },
//        success_update( state, items ) {
//            state.updated = true;
//        },
//        success_upload( state, items ) {
//            state.createdws = true;
//        },
//        success_list( state, items ) {
//            var folders = [];
//            var files   = [];
//            for ( var i = 0; i < items.length; i++ ) {
//                if ( items[ i ].task_image_id === null ) {
//                    folders.push( items[ i ] );
//                } else {
//                    files.push( items[ i ] );
//                }
//            }
//            state.items    = folders.concat( files );
//            state.loading  = false;
//            state.error    = false;
//            state.executed = true;
//        },
//        error( state, error ) {
//            state.obj          = error;
//            state.loading      = false;
//            state.error        = true;
//            state.executed     = false;
//            state.force_reload = true;
//        },
//        set_total( state, data ) {
//            state.tot = data;
//        },
//        set_page( state, data ) {
//            state.page = data;
//        },
//        set_rows_per_page( state, data ) {
//            state.rows_per_page = data;
//        },
//        force_reload(state) {
//            state.force_reload = true;
//        },
//        executed(state) {
//            state.executed  = false;
//            state.created   = false;
//            state.updated   = false;
//            state.createdws = false;
//        },
//        clear( state ) {
//            state.obj          = {};
//            state.items        = null;
//            state.loading      = false;
//            state.error        = false;
//            state.executed     = false;
//            state.force_reload = false;
//        },
//    }
//}


