<script>

import bnvform from '@/vue-model-mirror/bnvform';
const nprogress = require('nprogress');

const schemas = require('@/schemaconfig');
const manager = require('@/helpers/placeholder_manager').default;

export default {
    name: 'sollecito-form',
    components: {
        bnvform,
    },
    props: [ 'schema_from_route', 'id' ],
    mounted () {
        this.$nextTick(function () { // stop loader
            nprogress.done(true);
        })
    },
    watch: {
        qry_executed( new_value ) {
            console.log( 'qry_executed', new_value );
        },
        loading_by_id( new_value, old_value ) {
            if ( new_value === false && old_value === true ) {
                this.started = true;
                this.reset_form();
            }
        },
        error( new_value ) {
            if ( new_value === true ) {
                console.log( 'errore nella creazione del reminder' );
                if ( this.errorobj.message === 'Errori nella validazione dei placeholders' )  {
                    this.toggle_modal( 'modal-errors-create-update' );
                }
            }
        },
    },
    computed: {
        store () { return this.$store.state[ this.schema_from_route ]; },
        qry_executed () {
            return this.store.executed;
        },
        record () {
            return typeof this.$route.params.id !== 'undefined' ? this.store.obj : {};
        },
        title () { // default schema_name.toUpperCase()
            return this.id ? 'Modifica Sollecito' : 'Nuovo Sollecito';
        },
        inline () {
        },
        loading_by_id() {
            return this.store.loading_by_id;
        },
        error() {
            return this.store.error;
        },
        errorobj() {
            return this.store.errorobj;
        },
    },
    created () {
        this.schema_name = this.schema_from_route;
        this.schema = schemas[ this.schema_name ];
        this.options = {};
        // gestione add/edit
        var record_id = this.id;
        if ( record_id ) {
            this.action = 'edit';
//          this.$store.subscribe((mutation, state) => {
//              if ( this.started === false && mutation.type === `${this.schema_name}/success_obj` ) {
//                  this.started = true;
//              }
//          });
            this.$store.dispatch(`${this.schema_name}/get_by_id`, { id: record_id, include: true });
        } else {
            this.$store.dispatch( `${this.schema_name}/clearobj` );
            this.action = 'add';
            this.started = true;
        }
    },
    methods: {
        reset_form: function() {
            this.form_reload += 1;
        },
        operation_success: function( data ) {
            if ( this.schema.submit !== false ) {
                var title = this.action === 'add' ? `nuovo ${ data.label }` : `modifica sollecito`;
                var body = this.action === 'add' ? 'record creato con successo' : `record salvato con successo`;
                //if ( this.action === 'add' ) {
                    this.make_toast( title.toUpperCase(), body );
                //}
                this.reset_form();
                this.$store.dispatch('pratica/force_reload');
                if ( this.action === 'add' ) {
                    //console.log( 'emit', data );
                    this.$emit('saved', data.id)
                }
            }
        },
        make_toast(title, body) {
            this.$bvToast.toast( body, {
                title         : title,
                autoHideDelay : 5000,
                appendToast   : true,
            });
        },
        payload_changed( payload ) {
            var data = {};
            if ( payload.hasOwnProperty('template1_id') ) {
                var template_id  = payload['template1_id'];
                var target_field = 'body_email1';
                var target_label = 'Corpo prima email';
            } else if ( payload.hasOwnProperty('template2_id') ) {
                var template_id  = payload['template2_id'];
                var target_field = 'body_email2';
                var target_label = 'Corpo solleciti';
            } else {
                return;
            }
            var body_template = this.$store.getters['email_template/email_body']( template_id );
            data[ target_field ] = body_template;
            var body_written = this.$refs.sollecito_form.get_value_by_element_name( target_field );
            if ( body_written ) {
                var confirm_options = {
                    buttonSize: 'sm', autoFocusButton: 'ok', okVariant: 'info', okTitle: 'SÌ', cancelVariant: 'secondary',
                    cancelTitle: 'NO', noCloseOnEsc: true, noCloseOnBackdrop: true,
                };
                this.$bvModal.msgBoxConfirm(`Il campo "${ target_label }" non è vuoto, sovrascrivere il contenuto?`, confirm_options)
                    .then(value => {
                        if ( value === true ) {
                            this.$refs.sollecito_form.set_payload( data, true ); // true è il force
                        }
                    })
                    .catch(err => {
                        console.log( err );
                    });
            } else {
                this.$refs.sollecito_form.set_payload( data );
            }
        },
        back_to_list( obj ) {
            this.$store.dispatch(`${this.schema_name}/clearobj`);
            this.$emit('close', obj );
        },
        submit_form() {
            this.$refs.sollecito_form.submit_form();
        },
        resetform() {
            this.$refs.sollecito_form.reset_form();
        },
        send_first_email() {
            var check = this.validate();
            if ( !check ) { /*console.log( 'validation', check );*/ return; }
            var message = 'Inviare la prima email';
            if ( !this.is_active ) { message += ' e attivare il sollecito'; }
            message += '?';
            this.$emit('send_first_email', ...[ this.record.id, message ]);
        },
        validate() {
             var pra = this.$store.getters['pratica/get_obj'];
             var error_email_1 = false;
             var error_email_2 = false;
             var man = manager.validate( pra, this.record.body_email1 );
             if ( man.is_valid === false ) {
                 error_email_1 = true;
                 //console.log('ciao');
                 this.errors.push( { label: 'Prima Email', placeholders: man.error_placeholders } );
             }
            //console.log('ciao');
             var man = manager.validate( pra, this.record.body_email2 );
             if ( man.is_valid === false ) {
                 error_email_2 = true;
                 this.errors.push( { label: 'Email di sollecito', placeholders: man.error_placeholders } );
             }
             //return ( !error_email_1 && !error_email_2 ) ? true : false;
             var is_valid = ( !error_email_1 && !error_email_2 ) ? true : false;
             if ( is_valid ) { return is_valid; }
             this.toggle_modal();
             return is_valid;
        },
        clear_errors() {
            this.errors = [];
        },
        toggle_modal( modal_id = 'modal-errors' ) {
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
    },
    data () {
        return {
            form_reload : 0,
            started     : false,
            action      : null,
            errors      : [],
        }
    },
}

</script>

<template>

    <div>
        <h3 style="text-align: center; padding-bottom: 14px;">{{ action === 'add' ? 'Nuovo Sollecito' : 'Sollecito' }}</h3>
        <bnvform
            v-if       = "started === true"
            ref        = "sollecito_form"
            :name      = "schema_name"
            :schema    = "schema"
            :options   = "options"
            :record    = "record"
            :inline    = "true"
            :exit      = "false"
            :key       = "form_reload"
            @reset     = "reset_form"
            @submitted = "operation_success"
            @saved     = "operation_success"
            @change    = "payload_changed"
            @closed    = "back_to_list"
        ></bnvform>
        <b-row style="margin-bottom: 15px; margin-top: 10px;" class="" v-if="started === true">
            <b-col class="text-left">
                <b-button size="sm"  style="margin-right: 10px;" @click="submit_form();" variant="success">Salva</b-button>
                <b-button size="sm"  style="margin-right: 10px;" @click="resetform();" variant="warning">Reset</b-button>
                <b-button size="sm"  style="margin-right: 10px;" @click="back_to_list();" variant="outline-secondary">
                    Esci
                </b-button>
            </b-col>
            <b-col class="text-right" v-if="action === 'edit'">
                <b-button style="margin-bottom: 5px;" :disabled="record.config.sent > 0" size="sm" variant="success" @click="send_first_email()">
                    <icon name="envelope" style="margin-right: 8px;"/>Invia prima email
                </b-button>
            </b-col>
        </b-row>

        <!-- modal errors -->
        <b-modal
            id="modal-errors"
            title="Placeholders non valorizzati"
            @hide="clear_errors"
            >
            Alcuni placeholders non sono valorizzabili:
            <div v-for="error in errors" style="margin-top: 10px;">
                <b>{{ error.label }}:</b>
                <ul>
                    <li v-for="plhd in error.placeholders">{{plhd}}</li>
                </ul>
            </div>
            Invio della email non consentito.
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="outline-danger" @click="hide('close')">
                        Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <!-- modal errors su creazione e update-->
        <b-modal
            id    = "modal-errors-create-update"
            title = "Placeholders non valorizzati"
            @hide = "clear_errors"
            >
            Alcuni placeholders non sono valorizzabili:
            <div style="margin-top: 10px;">
                <ul>
                    <li v-for="plhd in errorobj.errors">{{plhd}}</li>
                </ul>
            </div>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="outline-danger" @click="hide('close')">
                        Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>
    </div>

</template>

<style>
</style>


