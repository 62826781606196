<script>

import { /*mapActions,*/ mapState/*, mapGetters*/ } from 'vuex';

export default {

    name: 'appuntamento-tasks',
    props: [ 'item' ],
    data() {
        return {
            newicons: {
                'primo_contatto' : 'flag',
                'disamina'       : 'check',
                'appuntamento'   : 'geo',
                'ispezione'      : 'eye',
                'supervisore'    : 'check',
                'documenti'      : 'file-earmark',
                'scrittura'      : 'pen',
                'sottoscrizione' : 'tag',
                'correzione'     : 'cone-striped',
                'evasione'       : 'box-arrow-right',
                'incasso'        : 'wallet',
                'pagamenti'      : 'people',
            },
            icons: {
                nuovo: {
                    'primo_contatto' : 'flag',
                    'disamina'       : 'check',
                    'appuntamento'   : 'geo',
                },
                lavorazione: {
                    'ispezione'   : 'eye',
                    'supervisore' : 'check',
                    'documenti'   : 'file-earmark',
                },
                evasione: {
                    'scrittura'      : 'pen',
                    'sottoscrizione' : 'tag',
                    'correzione'     : 'cone-striped',
                    'evasione'       : 'box-arrow-right',
                },
                chiuso: {
                    'incasso'   : 'wallet',
                    'pagamenti' : 'people',
                },
            },
            bg_colors: {
                'done'         : 'bg-success',
                'unnecessary'  : 'bg-secondary',
                'intermediate' : 'bg-warning'
            },
            icons_key : 0,
        }
    },
    created() {
    },
    watch: {
        task_overrided( new_value ) {
            if ( !new_value ) { return; }
            this.icons_key += 1;
        }
    },
    computed: {
        ...mapState('appuntamento', {
            task_overrided : state => state.task_overrided,
        }),
        ...mapState('appuntamento_list', {
            task_overrided : state => state.task_overrided,
        }),
        sigle () {
            return this.$store.state.userletter.users;
        },
        user_id () {
            return JSON.parse( localStorage.getItem('user') ).id;
        },
        show_completed () {
            return this.$store.state.pratica.show_completed;
        },
        states () { return this.$store.getters['statemanager/states']; },
        customers () { return this.$store.getters['statemanager/customers']; },
        i_can_see_or() { return ( this.item.custodian !== null && this.customers.map( x => x.id ).includes( this.item.custodian ) === false ) ? false : true;  },
        custodians () { return this.$store.getters['statemanager/custodians']( this.item.owner ); },
        tasks () {
            return this.item.tasks.sort( ( a, b ) => ( a.id < b.id ) ? -1 : 0 );
        },
        class_document() {
            if ( this.item.config.hasOwnProperty('reminders') === false || this.item.config.reminders.length === 0 ) { return 'rounded-circle'; }
            var completed = 0;
            var actives   = 0;
            this.item.config.reminders.map( x => {
                if ( x.docs === x.delivered ) { completed += 1; }
                if ( x.active === true ) { actives += 1; }
            });
            //console.log( 'completed', completed );
            //console.log( 'actives', actives );
            if ( completed > 0 && actives === 0 ) { return 'border-delivered'; }
            if ( actives === 0 ) { return ''; }
            if ( actives > 0 ) { return 'border-active'; }
        },
    },
    methods: {
        get_class() {
            return '';
        },
        i_can_see( task ) { return this.customers.map( x => x.id ).includes( task.customer_id ) ? true : false; },
        open_modal_form ( task, event ) {
            event.stopPropagation();
            if ( this.i_can_see( task ) === false ) { return; }
            var state_task  = null;
            //this.item.tasks.map( x => { if ( x.task_label === task.task_label ) { state_task = x; } } );
            this.item.tasks.map( x => { if ( x.id === task.id ) { state_task = x; } } );
            //if ( state_task.multi === false ) {
                var data = { task_id: null, description: task.description };
                var multi = this.item.tasks;
                data.task_id = state_task.id;
                console.log( data );
                // non viene emesso un evento perché questo component è dentro bnvlist (che ha i suoi eventi)
                if ( data.task_id !== null ) { this.$store.dispatch(`form_modal/set_schema`, data); return; }
                console.log( `"${ task_name }" non corrisponde ad un task valido` );
            //}
        },
        get_sigla( task ) {
            var task_label = task.task_label;
            if ( this.i_can_see( task ) === false ) {
                var custodian_label = this.custodians.filter( x => x.id === this.item.custodian )[0].label;
                return custodian_label.charAt(0).toUpperCase() + custodian_label.charAt(1).toLowerCase();
            }
            for ( var i = 0; i < this.item.tasks.length; i++ ) {
                //if ( this.item.tasks[ i ].task_label === task_label ) {
                if ( this.item.tasks[ i ].id === task.id ) {
                    if ( this.item.tasks[ i ].label === 'unnecessary' ) { return '&nbsp;'; }
                    if ( this.item.tasks[ i ].user_id !== null ) {
                        var sigla = this.sigle[ this.item.tasks[ i ].user_id ];
                        return this.item.tasks[ i ].user_id === this.user_id ? `<span style="color: blue;">${ sigla }</span>` : sigla;
                    }
                }
            }
            return '--';
        },
        get_icon_color( task_id, task_label ) {
            for ( var i = 0; i < this.item.tasks.length; i++ ) {
                //if ( this.item.tasks[ i ].task_label === task_label ) {
                if ( this.item.tasks[ i ].id === task_id ) {
                    var res =  this.item.tasks[ i ].label === null ? 'bg-danger' : this.bg_colors[ this.item.tasks[ i ].label ];
                    if ( task_label === 'documenti' ) { /*console.log( this.class_document );*/ return res + ' ' + this.class_document; }
                    return res + ' rounded-circle';
                }
            }
        },
        get_title( str ) {
            str = str.replace(/_/g, ' ');
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        get_state_label( key ) {
            var label = null;
            for ( var i = 0; i < this.states.length; i++ ) {
                if ( this.states[ i ].key === key ) { label = this.states[ i ].label; break; }
            }
            return label;
        },
        get_icon( state, task ) {
            if ( this.icons.hasOwnProperty( state ) === false || this.icons[ state ].hasOwnProperty( task ) === false ) { return 'b-icon-question'; }
            return 'b-icon-' + this.icons[ state ][ task ];
        },
        get_newicon( task_label ) {
            if ( this.newicons.hasOwnProperty( task_label ) === false ) { return 'b-icon-question'; }
            return 'b-icon-' + this.newicons[ task_label ];
        },
        is_visible( task ) {
            if ( this.item.newstate === 4000 ) {
                return this.customers.map( x => x.id ).includes( task.customer_id ) ? true : false;
            } else {
                return ( this.customers.map( x => x.id ).includes( task.customer_id ) || this.custodians.map( x => x.id ).includes( task.customer_id ) ) ? true : false;
            }
        }
    },

}

</script>

<template>

    <div class="form-inline" :key="'app_tasks_' + String( icons_key )" style="white-space: nowrap; display: inline-block;">
        <div v-for="task in item.tasks.filter( x => customers.map( x => x.id ).includes( x.customer_id ) === false )" class="text-center" style="padding: 0; margin: 0;">
            <div v-if="is_visible( task )" @click="open_modal_form( task, $event )" style="" :title="get_title( task.task_label )">
                <span style="font-weight: 600; font-family: monospace; color: grey; margin-right: 4px" :style="{ 'font-weight': i_can_see( task ) ? '600' : '900', color: i_can_see( task ) ? 'grey' : 'blue' }" v-html="get_sigla( task )"></span>
                <component
                    v-bind:key = "task.task_label"
                    :name      = "task.task_label"
                    style      = "margin-right: 3px;"
                    :style     = "{ cursor: customers.map( x => x.id ).includes( task.customer_id ) ? 'pointer' : 'default', padding: [ 'disamina', 'supervisore' ].includes( task.task_label ) ? '0' : '3px' }"
                    variant    = "light"
                    :class     = "get_icon_color( task.id, task.task_label )"
                    :is        = "get_newicon( task.task_label )"
                    scale      = "0.9"
                    font-scale = "1.8"
                ></component><br/>
            </div>
        </div>
        <div v-for="task in item.tasks.filter( x => customers.map( x => x.id ).includes( x.customer_id ) )" class="text-center" style="padding: 0; margin: 0;display: inline-block">
            <div v-if="is_visible( task )" @click="open_modal_form( task, $event )" style="" :title="get_title( task.task_label )">
                <span style="font-weight: 600; font-family: monospace; color: grey; margin-right: 4px" :style="{ 'font-weight': i_can_see( task ) ? '600' : '900', color: i_can_see( task ) ? 'grey' : 'blue' }" v-html="get_sigla( task )"></span>
                <component
                    v-bind:key = "task.task_label"
                    :name      = "task.task_label"
                    style      = "margin-right: 3px;"
                    :style     = "{ cursor: customers.map( x => x.id ).includes( task.customer_id ) ? 'pointer' : 'default', padding: [ 'disamina', 'supervisore' ].includes( task.task_label ) ? '0' : '3px' }"
                    variant    = "light"
                    :class     = "get_icon_color( task.id, task.task_label )"
                    :is        = "get_newicon( task.task_label )"
                    scale      = "0.9"
                    font-scale = "1.8"
                ></component><br/>
            </div>
        </div>
    </div>

</template>

