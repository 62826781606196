export function authHeader( jwt_only = false ) {
    var headers = {};
    var userjson = localStorage.getItem('user');
    if ( jwt_only === false ) {
        headers['Content-Type'] = 'application/json';
        headers['Accept']       = 'application/json';
    }
    if ( userjson ) {
        var userobj = JSON.parse( userjson );
        if ( userobj.hasOwnProperty('jwt') && userobj.jwt ) {
            headers['Authorization'] = `Bearer ${ userobj.jwt }`;
        }
    }
    return headers;
}
