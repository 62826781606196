//////////////////////////////

module.exports = (type) => {
    var schema = {
        label: {
            type: type.STRING(1024),
            allowNull: false,
        },
        settings: {
            type: type.JSONB,
            allowNull: true,
            defaultValue: {},
        },
    };

    return schema;
}

