<script>

const moment = require('moment-timezone');

export default {

    name: 'cmp-files-created',
    props: [ 'item' ],
    data() {
        return {
            dateobj : null,
        }
    },
    computed: {
        label() {
            if ( this.dateobj !== null ) {
                if ( moment().format('DD-MM-YYYY') === this.dateobj.format('DD-MM-YYYY') ) {
                    return this.dateobj.format('HH:mm');
                } else {
                    return this.dateobj.format('DD-MM-YYYY');
                }
            }
            return '';
        }
    },
    methods: {
    },
    created () {
        var date_string = null;
        try {
            date_string = this.item.task_image.value.created;
        } catch( error ) {}
        if ( date_string !== null ) { this.dateobj = moment( date_string ); }
    },

}

</script>

<template>

    <div style="padding-left: 10px; color: grey; white-space: nowrap;">
        <small>{{ label }}</small>
    </div>

</template>


