<script>

import pratica_subtab_base_multi_item from './base_data_multi_item';
const schemas = require('@/schemaconfig');
import CodFisc from '@/registry/helpers/CodFisc';

export default {
    name: 'pratica-subtab-testimone',
    extends: pratica_subtab_base_multi_item,
    props: [],
    computed : {
        testimone_referente_template() {
            let template = this.registry_template_ids.get( this.prareg.get_testimone_referente( String( this.item_index ) ).template_id );
            return template.template_name + '_subtab';
        },
        testimone_referente_schema() {
            return schemas[ this.testimone_referente_template ];
        },
        item_ids() {
            return this.prareg.testimone_ids;
        },
        has_testimone() {
            if ( this.new_item === true ) { return false; }
            return this.prareg.has_testimone( String( this.selected_index ) );
        },
        has_testimone_referente() {
            if ( this.new_item === true ) { return false; }
            return this.prareg.has_testimone_referente( String( this.selected_index ) );
        },
    },
    watch: {},
    methods : {
        init_registry( template_name, role_name ) {
            if ( role_name === 'testimone' ) { // da sistemare
                this.template_id    = template_name.map( x => this.registry_template.get( x ).id );
                this.role_id        = this.registry_role.get( role_name ).id;
                this.field_label    = 'Associa Testimone';
                this.$refs.modal_association.mao.set_params({
                    search_filters : { template_id__in : this.template_id },
                    template       : [ '_persona_fisica', '_persona_giuridica', '_compagnia' ],
                    role_id        : this.role_id,
                });
            } else if ( role_name === 'testimone_referente' ) {
                this.template_id    = template_name.map( x => this.registry_template.get( x ).id );
                this.role_id        = this.registry_role.get( 'referente' ).id;
                this.field_label    = 'Associa Referente Testimone';
                let modal_association_options = {
                    template  : [ '_persona_fisica', '_persona_giuridica' ],
                    role_id   : this.role_id,
                    father_id : this.prareg.get_testimone( String( this.item_index ) ).id,
                };
                if ( this.prareg.get_testimone( String( this.item_index ) ).registry_id ) {
                    modal_association_options.registry_father_id      = this.prareg.get_testimone( String( this.item_index ) ).registry_id;
                    modal_association_options.registry_father_role_id = this.registry_role.get( 'referente' ).id;
                    modal_association_options.search_filters          = { id: this.prareg.get_testimone( String( this.item_index ) ).registry_id, qry: { child_role_id: this.role_id }, label: 'Referenti associati' };
                    this.$refs.modal_association.mao.set_params( modal_association_options );
                    this.$refs.modal_association.load_registries();
                    return;
                } else {
                    modal_association_options.search_filters = { template_id__in : this.template_id };
                    this.$refs.modal_association.mao.set_params( modal_association_options );
                }
            }
            this.$refs.modal_association.load_items();
            //this.toggle_modal();
        },
        cf_button( payload ) {
            var codice_fiscale = this.$refs.form_testimone.get_element_value('codice_fiscale');
            console.log( codice_fiscale );
            if ( codice_fiscale && codice_fiscale.length > 0 ) {
                if ( !CodFisc.validate( codice_fiscale ) ) {
                    this.cf_payload.sesso             = null;
                    this.cf_payload.data_nascita      = null;
                    this.cf_payload.comune_nascita_id = null;
                    if ( payload.open ) { this.toggle_modal('modal-cf'); }
                    return;
                }
                var data = CodFisc.parse_codfisc( codice_fiscale );
                this.cf_payload.sesso          = data.sesso;
                this.cf_payload.data_nascita   = data.data_nascita;
                this.$store.dispatch( 'comune_nascita/get_by_code', data.codice_comune );
                if ( payload.open ) { this.toggle_modal('modal-cf'); }
                return;
            } else {
                this.cf_payload.sesso             = null;
                this.cf_payload.data_nascita      = null;
                this.cf_payload.comune_nascita_id = null;
            }
            if ( payload.open ) { this.toggle_modal('modal-cf'); }
        },
        save_cf_data( payload ) {
            this.$refs.modal_cf.validate();
            if ( !this.$refs.modal_cf.is_valid ) { return; }
            if ( payload.sesso && payload.data_nascita && payload.comune_nascita_id ) {
                var nome    = this.$refs.form_assicurato.get_element_value('nome');
                var cognome = this.$refs.form_assicurato.get_element_value('cognome');
                if ( nome && nome.length > 0 && cognome && cognome.length > 0 ) {
                    var data = {
                        nome,
                        cognome,
                        codice_comune : this.$store.state.comune_nascita.obj.codice,
                        data_nascita  : payload.data_nascita,
                        sesso         : payload.sesso,
                    };
                    var codice_fiscale = CodFisc.get_codfisc( data );
                    this.$refs.form_assicurato.set_payload( { codice_fiscale }, true );
                    this.$refs.form_assicurato.reset_error('codice_fiscale');
                    this.cf_payload.sesso             = payload.sesso;
                    this.cf_payload.comune_nascita_id = payload.comune_nascita_id;
                    this.cf_payload.data_nascita      = payload.data_nascita;
                }
            }
            this.toggle_modal('modal-cf');
            console.log( 'payload', this.$refs.form_assicurato.get_form_data() );
        },
        submit_cf_form() {
            this.$refs.modal_cf.submit_form();
        },
        add_testimone() {
            this.new_item = true;
            this.selected_index = null;
            setTimeout( () => {
                this.init_registry([ 'Persona fisica', 'Persona giuridica', 'Compagnia' ], 'testimone');
            }, 100 );
        },
        async save_all() { // fab button
            let payload = {};
            if ( this.prareg.has_testimone( this.selected_index ) ) {
                let payload_testimone = this.$refs.form_testimone.get_form_data();
                if ( this.$refs.form_testimone.is_valid ) {
                    payload.testimone = {
                        ...payload_testimone,
                        ...this.addressobj.values,
                        id: this.prareg.get_testimone( this.selected_index ).id
                    };
                    if ( payload.testimone.codice_fiscale ) {
                        try {
                            let codfisc_data = CodFisc.parse_codfisc( payload.testimone.codice_fiscale );
                            let comune_nascita_id = await this.$store.dispatch( 'comune_nascita/get_by_code', codfisc_data.codice_comune );
                            payload.testimone.comune_nascita_id = comune_nascita_id;
                            payload.testimone.sesso             = codfisc_data.sesso;
                            payload.testimone.data_nascita      = codfisc_data.data_nascita;
                        } catch ( error ) {
                            payload.testimone.comune_nascita_id = null;
                            payload.testimone.sesso             = null;
                            payload.testimone.data_nascita      = null;
                        }
                    }
                }
                if ( this.prareg.has_testimone_referente( this.selected_index ) ) {
                    let payload_testimone_referente = this.$refs.form_testimone_referente.get_form_data();
                    if ( this.$refs.form_testimone_referente.is_valid ) {
                        payload.testimone_referente = {
                            ...payload_testimone_referente,
                            id: this.prareg.get_testimone_referente( this.selected_index ).id
                        };
                    }
                }
            }
            this.update_all( { payload, codice_pratica: this.codice_pratica } )
        },
        set_addressobj_value() {
            if ( this.has_testimone ) {
                this.addressobj.set_values( this.copy( this.prareg.get_testimone( this.item_index ) ) );
            }
        },
    },
    created() {},
    data() {
        return {
            schema_testimone_subtab   : schemas._testimone_subtab,
            testimone_key             : 'testimone_0',
            testimone_referente_key   : 'testimone_0',
            testimone_si              : false,
            testimone_referente_si    : false,
            // gestione codice fiscale
            cf_payload                : { sesso: null, data_nascita: null, comune_nascita_id: null, first_name: null, last_name: null },
            cf_schema                 : schemas._codfisc,
            classes                   : {},
        };
    },
}

</script>

<template>

    <div :key="main_key">

        <entry-selector
            :options         = "prareg.testimone_items"
            :selected_index  = "item_index || null"
            @change-selected = "set_selected_index"
            @add-item        = "add_testimone"
        />

        <b-row>

            <b-col cols="6" style="">

                <!-- TESTIMONE -->
                <div v-if="!has_testimone" style="margin-bottom: 15px">
                    <b-link @click="init_registry([ 'Persona fisica', 'Persona giuridica', 'Compagnia' ], 'testimone')">
                        Testimone <icon name="plus-circle" style="margin-bottom: 3px"/>
                    </b-link>
                </div>
                <div v-else-if="has_testimone">
                    <div style="min-height: 420px;" class="dati_box" :class="classes.testimone || ''" @mouseover="testimone_si = true" @mouseleave="testimone_si = false">
                        <b-row
                            @click="edit_registry('testimone')"
                            >
                            <b-col cols="9">
                                <h6 class="block_label">Testimone</h6>
                            </b-col>
                            <b-col cols="3" class="text-right">
                                <icon v-if="testimone_si" name="times-circle" variant="danger" style="color: red; margin-bottom: 3px; margin-right: 10px" @click.stop="unlink('testimone')" />
                            </b-col>
                        </b-row>
                        <bnvform
                            ref        = "form_testimone"
                            name       = "_testimone_subtab"
                            :or_data   = "copy( prareg.get_testimone( item_index ) )"
                            :schema    = "schema_testimone_subtab"
                            :options   = "{}"
                            :record    = "copy( prareg.get_testimone( item_index ) )"
                            :inline    = "true"
                            :submit    = "false"
                            :key       = "testimone_key"
                            @change    = "value_changed"
                            @cf-button = "cf_button"
                            @ad-button = "ad_button"
                            @submitted = "save_all"
                        />
                    </div>
                </div>

            </b-col>

            <b-col cols="6" style="">

                <!-- REFERENTE TESTIMONE -->
                <div v-if="!has_testimone_referente" style="margin-bottom: 15px;">
                    <b-link @click="init_registry([ 'Persona fisica', 'Persona giuridica' ], 'testimone_referente')" :class="has_testimone ? '' : 'link_disabled'">
                        Referente <icon name="plus-circle" style="margin-bottom: 3px"/>
                    </b-link>
                </div>
                <div v-else-if="has_testimone_referente">
                    <div style="min-height: 200px;" class="dati_box" :class="classes.testimone_referente || ''" @mouseover="testimone_referente_si = true" @mouseleave="testimone_referente_si = false">
                        <b-row @click="edit_registry('testimone_referente')">
                            <b-col cols="9">
                                <h6 class="block_label">Referente</h6>
                            </b-col>
                            <b-col cols="3" class="text-right">
                                <icon v-if="testimone_referente_si" name="times-circle" variant="danger" style="color: red; margin-bottom: 3px; margin-right: 10px" @click.stop="unlink('testimone_referente')" />
                            </b-col>
                        </b-row>
                        <bnvform
                            ref        = "form_testimone_referente"
                            :name      = "testimone_referente_template"
                            :schema    = "testimone_referente_schema"
                            :options   = "{}"
                            :record    = "prareg.get_testimone_referente( String( item_index ) )"
                            :inline    = "true"
                            :submit    = "false"
                            :key       = "testimone_referente_key"
                            @submitted = "save_all"
                        />
                    </div>
                </div>

            </b-col>

        </b-row>

        <modal-association
            ref             = "modal_association"
            :field_label    = "field_label"
            :customer_id    = "customer_id"
            :codice_pratica = "codice_pratica"
            @selected       = "registry_selected"
        />

        <!-- modal new confirm-unlink -->
        <b-modal
            id          = "confirm-unlink"
            title       = "Conferma operazione"
            @ok         = "unlink_dependencies"
            button-size = "sm"
            >
            <div style="width: 80%; margin: auto">
                Rimuovere l'anagrafica?
                <div v-if="unlink_payload.dependencies && unlink_payload.dependencies.length > 0" style="margin-top: 5px;">
                    Verranno rimosse anche le anagrafiche collegate:
                    <ul>
                        <li v-for="dep in unlink_payload.dependencies" :key="'dep_' + dep">{{ dep }}</li>
                    </ul>
                </div>
            </div>
        </b-modal>

        <!-- modal Address Data -->
        <b-modal
            id          = "modal-address-data"
            title       = "Indirizzo"
            button-size = "sm"
            >
            <bnvform
                name       = "_address_data"
                :schema    = "ad_schema"
                :options   = "{}"
                :record    = "addressobj"
                :submit    = "false"
                :inline    = "true"
                ref        = "modal_ad"
                layout     = "single"
                @submitted = "save_ad"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_ad_form">
                      Salva
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <!-- modal CF -->
        <b-modal
            id          = "modal-cf"
            title       = "Dati relativi al Codice Fiscale"
            button-size = "sm"
            >
            <bnvform
                name       = "_codfisc"
                :schema    = "cf_schema"
                :options   = "{}"
                :record    = "cf_payload"
                :submit    = "false"
                :inline    = "true"
                ref        = "modal_cf"
                layout     = "single"
                @submitted = "save_cf_data"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_cf_form">
                      Salva
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <fab-button
            v-if      = "prareg.testimone_items.length > 0"
            main_icon = "save"
            bg_color  = "#218838"
            @save     = "save_all"
        />

    </div>

</template>

<style>
</style>

