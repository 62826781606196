////////////////////////////////

import data          from './data';
import ispezione     from './ispezione';
import assegnazioni  from './assegnazioni';
import files         from './files';
import diary         from './diary';
import photoeditor   from './photoeditor/photoeditor';
import parcel        from './parcel_module';
import simple_search from '../../pratica/simple-search';
import sollecito     from './sollecito/list';

export default {
    data,
    ispezione,
    assegnazioni,
    files,
    photoeditor,
    parcel,
    sollecito,
};

