//////////////////////////////

module.exports = (type) => {
    var schema = {
        'dati_pratica.data_incarico__gte': {
            type: type.DATEONLY,
            allowNull: true,
        },
        'dati_pratica.data_incarico__lte': {
            type: type.DATEONLY,
            allowNull: true,
        },
        data_evasione__gte: {
            type: type.DATEONLY,
            allowNull: true,
        },
        data_evasione__lte: {
            type: type.DATEONLY,
            allowNull: true,
        },
        'dati_pratica.mandante': {
            type: type.STRING(128),
            allowNull: true,
        },
        'dati_pratica.ramo': {
            type: type.STRING(128),
            allowNull: true,
        },
        'agenzia.denominazione': {
            type: type.STRING(128),
            allowNull: true,
        },
        'customer_id': {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        user_id: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'users',
                key         : 'id',
                constraints : true,
                store_name  : 'user',
            }
        },
    };

    return schema;
}


