<script>

export default {
    name: 'websocket-logger',
    props: [ 'wspath', 'title' ],
    created() {
        this.$store.dispatch('websocket_logger/reset', this.title);
        this.$store.dispatch('websocket_logger/set_path', this.wspath);
    },
    computed: {
        message() { return this.$store.state.websocket_logger.message; },
        loader()  { return this.$store.state.websocket_logger.loader; },
        variant() { return this.$store.state.websocket_logger.variant; },
    },
    watch: {
        loader(new_val, old_val) { // riportare sul parent
            if ( new_val === false && old_val === true ) {
                this.connection_closed();
                setTimeout( () => {
                    this.close();
                }, 5000);
            }
        },
    },
    methods: {
        close() {
            this.$emit( 'closed', {} );
        },
        connection_closed() {
            this.$emit( 'connection_closed', {} );
        },
    },
    data() {
        return {
        }
    },
}

</script>

<template>

    <b-alert :variant="variant" show>
        <span v-if="message.hasOwnProperty('url') === false">{{ message.action }}</span><a v-if="message.hasOwnProperty('url')" :href="message.url" target="_blank">{{ message.file_name }}</a>
        <b-spinner v-if="loader" variant="primary" label="Spinning" style="float: right;" small></b-spinner>
    </b-alert>

</template>

