<script>

import websocket_logger from '@/layout/components/websocket_logger';
const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export default {
    name: 'websocket-logger-csv',
    extends: websocket_logger,
    computed: {
        backend_url() { return config.backend_url; },
    },
    watch: {
        loader(new_val, old_val) {
            if ( new_val === false && old_val === true ) {
                this.connection_closed( this.message.hasOwnProperty('log_url') );
                setTimeout( () => {
                    this.close();
                }, 5000);
            }
        },
    },
    methods: {
        connection_closed( keep_component ) {
            this.$emit( 'connection_closed', { keep_component } );
        },
    },
}

</script>

<template>

    <b-alert :variant="variant" show>
        <span v-if="message.hasOwnProperty('url') === false">{{ message.action }}</span><span v-if="message.hasOwnProperty('log_url')">&nbsp;&nbsp;<a :href="backend_url + message.log_url" target="_blank">Log di importazione</a></span>
        <b-spinner v-if="loader" variant="primary" label="Spinning" style="float: right;" small></b-spinner>
    </b-alert>

</template>

