//////////////////////////////

module.exports = (type) => {
    var schema = {
        codice_fiscale: {
            type: type.STRING(64),
            allowNull: true,
            options: {
                type : 'codfisc',
            },
        },
        telefono: {
            type: type.JSONB,
            defaultValue: [],
            allowNull : false,
            options: {
                type          : 'object_manager',
                new_item_type : 'string',
                not_empty     : true,
                label         : true,
                note          : true,
            },
        },
        email: {
            type: type.JSONB,
            defaultValue: [],
            allowNull : false,
            options: {
                type          : 'object_manager',
                new_item_type : 'email',
                not_empty     : true,
                label         : true,
            },
        },
        iban: {
            type: type.STRING(64),
            allowNull: true,
            options: {
                type: 'iban',
                display_error : true,
            }
        },
//      id: {
//          type: type.VIRTUAL,
//          hidden: true,
//      },
    };

    return schema;
}


