//////////////////////////////

import Vue from 'vue';
import Vuex from 'vuex';

import base_module from '@/vue-model-mirror/stores/base.module';

import useradv                    from '@/store/useradv.module';
import user                       from '@/store/user.module';
import authentication             from '@/store/authentication.module';
import pratica                    from '@/store/pratica.module';
import pratica_custodian          from '@/store/pratica.module'; // alias
import pratica_owner              from '@/store/pratica.module'; // alias
import task                       from '@/store/task.module';
import nullboolean                from '@/store/nullboolean.module';
import meta_file                  from '@/store/meta_file.module';
import meta_file_doc              from '@/store/meta_file_doc.module';
import ispezione_file_description from '@/store/ispezione_file_description.module';
import ispezione_doc_description  from '@/store/ispezione_doc_description.module';
import folder                     from '@/store/folder.module';
import move_folder                from '@/store/move_folder.module';
import diary                      from '@/store/diary.module';
import presence                   from '@/store/presence.module';
import tasklist                   from '@/store/tasklist.module';
import format                     from '@/store/format.module';
import order_direction            from '@/store/order_direction.module';
import pratica_order_field        from '@/store/pratica_order_field.module';
import color                      from '@/store/color.module';
import ispezione                  from '@/store/ispezione.module';
import customer                   from '@/store/customer.module';
import newstate                   from '@/store/newstate.module';
import custodian                  from '@/store/custodian.module';
import photoeditor                from '@/store/photoeditor.module';
import template                   from '@/store/template.module';
import pratica_parcel_task        from '@/store/pratica_parcel_task.module';
import pratica_parcel_user        from '@/store/pratica_parcel_user.module';
import parcel                     from '@/store/parcel.module';
import search_statistic           from '@/store/search_statistic.module';
import pratica_parcel             from '@/store/pratica_parcel.module';
import amounts_owner              from '@/store/amounts.module'; // alias
import amounts_custodian          from '@/store/amounts.module'; // alias
import sollecito                  from '@/store/sollecito.module';
import sollecito_document         from '@/store/sollecito_document.module';
import sollecito_document_bnvtag  from '@/store/sollecito_document_bnvtag.module';
import email_template             from '@/store/email_template.module';
import reminder                   from '@/store/reminder.module';
import sesso                      from '@/store/sesso.module';
import comune_nascita             from '@/store/comune_nascita.module';     // alias di comune
import comune_residenza           from '@/store/comune_residenza.module';   // alias di comune
import manual_email               from '@/store/reminder.module'; // alias
import tipo_strada                from '@/store/tipo_strada.module';
import registry                   from '@/store/registry.module';
import registry_template          from '@/store/registry_template.module';
import registry_role              from '@/store/registry_role.module';
import registry_autocomplete      from '@/store/registry_autocomplete.module';
import preg                       from '@/store/preg.module';
import { alert }                  from '@/store/alert.module'; // ................. da sistemare e implementare per tutti
import { fileupload }             from '@/store/fileupload.module'; // ............ cameratest
import statemanager               from '@/store/statemanager.module'; // .......... design alternativo
import { form_modal }             from '@/store/form_modal.module'; // ............ design alternativo
import { userletter }             from '@/store/userletter.module'; // ............ design alternativo
import { report }                 from '@/store/report.module'; // ................ design alternativo
import { perizia }                from '@/store/perizia.module'; // ............... design alternativo
import { websocket_logger }       from '@/store/websocket_logger.module'; // ...... design alternativo
import { localdb_images }         from '@/store/localdb_images.module'; // ........ design alternativo
import { localdb_documents }      from '@/store/localdb_documents.module'; // ..... design alternativo
import { pratica_addresses }      from '@/store/pratica_addresses.module'; // ..... design alternativo
import cga                        from '@/store/cga.module'; // ................... design alternativo
import cga_preg                   from '@/store/cga_preg.module'; // .............. design alternativo
import attachments                from '@/store/attachments.module'; // ........... design alternativo
import attachments_preg           from '@/store/attachments_preg.module'; // ...... design alternativo
import tipo_pratica               from '@/store/tipo_pratica.module'; // .......... design alternativo
import appuntamento               from '@/store/appuntamento.module'; // .......... design alternativo
import appuntamento_list          from '@/store/appuntamento_list.module'; // ..... design alternativo
import ricerca                    from '@/store/ricerca.module'; // ............... design_alternativo
import comune_regione             from '@/store/comune_regione.module'; // ........ design_alternativo
import comune_provincia           from '@/store/comune_provincia.module'; // ...... design_alternativo
import comune_select              from '@/store/comune_select.module'; // ......... design_alternativo
import import_csv                 from '@/store/import_csv.module'; // ............ design_alternativo

// not default included stores
var vuex_conf = {
    modules: {
        alert, authentication, useradv, pratica, fileupload, form_modal, task, nullboolean,
        meta_file, meta_file_doc, ispezione_file_description, ispezione_doc_description, folder,
        userletter, move_folder, diary, perizia, presence, color, tasklist, report, format,
        order_direction, pratica_order_field, ispezione, customer, photoeditor, websocket_logger,
        statemanager, template, newstate, custodian, user, pratica_parcel_task, pratica_parcel_user, parcel,
        search_statistic, pratica_parcel, amounts_owner, amounts_custodian,
        pratica_owner, pratica_custodian, localdb_images, localdb_documents, pratica_addresses, sollecito_document,
        email_template, reminder, sollecito_document_bnvtag, manual_email, sesso, comune_nascita, comune_residenza,
        tipo_strada, registry, registry_template, registry_autocomplete, registry_role, sollecito,
        email_template, reminder, sollecito_document_bnvtag, manual_email, sollecito, preg, cga, cga_preg, attachments,
        attachments_preg, tipo_pratica, appuntamento, appuntamento_list, ricerca, comune_regione, comune_provincia, comune_select,
        import_csv,
    }
};
// optionals settings for default included stores
var conf = {
    add_pratica          : { default_order : 'last_name ASC', },
    ispezione            : { default_order : 'created DESC', },
    role                 : { default_order : 'label ASC', },
    search_pratica       : { default_order : 'label ASC', },
    comune               : { default_order : 'nome ASC', },
  //registry             : { default_order : 'id ASC' },
    tipo_chiusura        : { default_order : 'id ASC' },
    pratica_incarico     : { default_order : 'id ASC' },
    ricerca_pratica      : { default_order : 'id ASC' },
  //comune_regione       : { default_order : 'label ASC' },
  //comune_provincia     : { default_order : 'regione_label ASC|label ASC' },
};
// default included stores (based on schema name)
const schemasobj = require.context( '@/schemas', true, /^.*\.schema.js$/ );
var schemas = schemasobj.keys();
for ( var i = 0; i < schemas.length; i++ ) {
    var schema_name = schemas[ i ].replace(/^\.\//, '').replace(/\.schema\.js$/, '');
    if ( vuex_conf.modules.hasOwnProperty( schema_name ) === false ) {
        var options = {
            model_name    : schema_name,
            send_alert    : false,
        };
        if ( conf.hasOwnProperty( schema_name ) === true && conf[ schema_name ].hasOwnProperty( 'default_order' ) ) {
            options.default_order = conf[ schema_name ].default_order;
        }
        //if ( schema_name === 'ispezione' ) { console.log( options ); }
        var base = new base_module( options );
        vuex_conf.modules[ schema_name ] = {
            namespaced : true,
            ...base,
        };
    }
}

Vue.use(Vuex);
export const store = new Vuex.Store( vuex_conf );

