//////////////////////////////

module.exports = (type) => {
    var schema = {
        template_id: {
            type: type.INTEGER,
            allowNull: false,
            label: 'Template',
            references: {
                model       : 'registry_templates',
                key         : 'id',
                constraints : true,
                store_name  : 'registry_template',
            }
        },
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            label: 'Customer',
            //readonly: true,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
    };

    return schema;
}


