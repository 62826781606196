<script>

const schemas = require('@/schemaconfig');
import baseform from './base_form';
import RegistryResidenza from '../components/residenza';
import RegistryContact from '../components/contact';

export default {
    name: 'registry-agenzia',
    extends: baseform,
    props: [ '' ],
    components: { RegistryResidenza, RegistryContact, },
    computed: {},
    watch: {},
    methods: {},
    created() {},
    data() {
        return {
            template_name   : 'Agenzia',
            registry_schema : schemas._agenzia,
            blocks          : [ 'form_registry', 'form_contacts', 'form_residenza' ],
            relation_config : {
                compagnia : { template: [ '_compagnia' ], direction: 'child', child_role: 'agenzia', father_role: 'compagnia' },
                referente : { template: [ '_persona_fisica', '_persona_giuridica', ], direction: 'parent', child_role: 'referente', father_role: 'referente', },
            },
        }
    },
}

</script>

<template>

    <div :key="main_key">
        <h3 style="text-align: center; padding-bottom: 14px;">AGENZIA</h3>

        <b-row style="margin: 2px; max-width: 800px; margin: auto" v-if="started === true">
            <b-col style="border: 1px solid lightgrey; padding: 5px;" lg="12" md="12">
                <p style="font-weight: 800; margin-left: 10px; margin-bottom: 0">Anagrafica</p>
                <bnvform
                    v-if       = "started === true"
                    ref        = "form_registry"
                    name       = "_agenzia"
                    :schema    = "registry_schema"
                    :options   = "{}"
                    :record    = "record"
                    :inline    = "true"
                    :submit    = "false"
                    :key       = "registry_key"
                />
                <p style="font-weight: 800; margin-left: 10px; margin-bottom: 0">Contatti</p>
                <registry-contact
                    ref      = "form_contacts"
                    :record  = "record"
                    :started = "started"
                />
            </b-col>
            <b-col style="border: 1px solid lightgrey; padding: 5px;" lg="12" md="12">
                <p style="font-weight: 800; margin-left: 10px; margin-bottom: 0">Sede</p>
                <registry-residenza
                    ref      = "form_residenza"
                    :record  = "record"
                    :started = "started"
                />
            </b-col>
        </b-row>

        <div v-if="in_modal === false">

            <relation-manager
                v-if          = "started === true"
                ref           = "relations"
                :action       = "action"
                :customer_id  = "customer_id"
                :related_objs = "related_objs"
                :record       = "record"
                @selected     = "set_parent"
                >
                <template #relation_buttons="parent">
                    <b-button size="sm" variant="secondary" style="margin-right: 10px;" @click="parent.new_relation( relation_config.compagnia )"><icon name="plus"/> Compagnia</b-button>
                    <b-button size="sm" variant="warning" style="margin-right: 10px;" @click="parent.new_relation( relation_config.referente )"><icon name="plus"/> Referente</b-button>
                </template>
            </relation-manager>
    
            <b-row class="text-center" style="margin: 2px; margin-top: 15px; max-width: 800px; margin: auto">
                <b-col class="text-left">
                    <b-button v-if="action === 'edit'" size="sm" variant="danger" @click="destroy">Elimina</b-button>
                </b-col>
                <b-col class="text-right">
                    <b-button size="sm" variant="outline-secondary" style="margin-right: 10px" @click="back_to_list">Chiudi</b-button>
                    <b-button size="sm" variant="success" @click="save">Salva</b-button>
                </b-col>
            </b-row>

        </div>

    </div>

</template>

<style>
</style>

