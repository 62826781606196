//////////////////////////////

module.exports = (type) => {
    var schema = {
        cf: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        telefono: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        cellulare: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        email: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        iban: {
            type: type.STRING(40),
            allowNull: true,
            defaultValue: null,
        },
    };

    return schema;
}


