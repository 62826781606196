<script>

import { mapActions, mapState/*, mapGetters*/ } from 'vuex';

export default {
    name: 'appuntamento-codice',
    props: [ 'item' ],
    components : {},
    computed : {
        ...mapState('appuntamento', {
            edit_mode : state => state.edit_mode,
        }),
        ...mapState('ricerca', {
            active_list : state => state.active_list,
            ricerca_id  : state => state.ricerca_id,
        }),
        codice_pratica() { return this.item.codice; },
        is_included() {
            return this.active_list.includes( this.codice_pratica );
        },
        is_disabled() {
            return this.item._list && this.item._list.id !== this.ricerca_id;
        },
        variant() {
            if ( this.is_disabled ) { return 'secondary'; }
            return this.is_included ? 'primary' : 'outline-secondary';
        },
        list_name() {
            if ( this.is_disabled ) { return this.item._list.label; }
            return null;
        },
    },
    watch: {
    },
    methods : {
        ...mapActions( 'ricerca', {
            create_relation  : 'create_relation',
            destroy_relation : 'destroy_relation',
        }),
        select_pratica() {
            if ( this.is_included ) { this.destroy_relation( this.codice_pratica ); }
            else { this.create_relation( this.codice_pratica ); }
        },
    },
    created() {},
    data() {
        return {
        }
    },
}

</script>

<template>
    <div>
        <div v-if="!edit_mode">
            <span>{{ codice_pratica }}</span>
        </div>
        <div v-else-if="edit_mode && is_disabled">
            <span v-b-tooltip.hover.right :title="list_name">
                <b-button :variant="variant" :disabled="true" size="sm" @click="select_pratica">{{ codice_pratica }}</b-button>
            </span>
        </div>
        <div v-else>
            <b-button :variant="variant" size="sm" @click="select_pratica">{{ codice_pratica }}</b-button>
        </div>
    </div>
</template>

<style>
</style>

