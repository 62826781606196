//////////////////////////////

// https://stackoverflow.com/questions/7922744/how-can-i-search-all-columns-in-a-table

module.exports = (type) => {
    var schema = {
        
        /* INDIRIZZO */

        indirizzo: {
            type: type.VIRTUAL,
            allowNull: true,
            field_type: 'google_address',
        },

    };

    return schema;
}



