//////////////////////////////

module.exports = (type) => {
    var schema = {
        label: {
            type: type.STRING(1024),
            allowNull: false,
        },
        name: {
            type: type.STRING(1024),
            allowNull: false,
        },
        fileid: {
            type: type.STRING(1024),
            allowNull: false,
        },
        path: {
            type: type.STRING(1024),
            allowNull: false,
        },
        web_url: {
            type: type.TEXT,
            allowNull: false,
        },
    };

    return schema;
}


