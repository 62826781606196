<script>

export default {

    name: 'parcel-defaults',
    props: [ 'item' ],
    data() {
        return {
            config: {},
        }
    },
    created() {
        var id = this.item.user_id ? this.item.user_id : this.item.custodian_id;
        this.config = id ? this.get_user_config( id, this.item.task_label ) : {};
    },
    computed: {
        mode() { return this.item.custodian_id ? 'custodian' : 'owner'; },
        _percentage() {
            if ( this.config.hasOwnProperty('percentage') === false || this.config.percentage === null ) { return 0; }
            return this.config.percentage;
        },
        percentage() {
            var percentage = this._percentage % 1 === 0 ? this._percentage.split('.')[0] : this._percentage.replace('.', ',');
            return `${ percentage }%`;
        },
        _minimum() {
            if ( this.config.hasOwnProperty('minimum') === false || this.config.minimum === null ) { return 0; }
            return this.config.minimum;
        }, 
        minimum() {
            return this.convert_to_currency( this._minimum );
        }
    },
    methods: {
        get_user_config( id, task_label ) {
            if ( this.mode === 'owner' ) {
                var config = this.$store.getters[ 'parcel/user_config' ]( id, task_label ); 
            } else {
                var config = this.$store.getters[ 'parcel/custodian_config' ]( id, task_label );
            }
            return config;
        },
        convert_to_currency( value ) {
            try {
                var importo = new Intl.NumberFormat( 'it-IT', { style: 'currency', currency: 'EUR' } ).format( value );
                return importo;
            } catch ( error ) {
                console.log( error );
                return value;
            }
           
        },
    },

}

</script>

<template>

    <span v-if="config.percentage || config.minimum"><span v-if="!percentage" style="color: lightgrey; font-weight: 500">null</span><span v-else-if="percentage" style="font-weight: 500">{{ percentage }}</span> / <span v-if="!minimum" style="color: lightgrey; font-weight: 500">null</span><span v-else-if="minimum" style="font-weight: 500">{{ minimum }}</span></span>

</template>

