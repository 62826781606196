<script>

export default {

    name: 'parcel-quota',
    props: [ 'item' ],
    data() {
        return {
            _value : null,
            state  : null,
            key    : 0,
        }
    },
    created() {
        //this._value = this.item.user_id > 0 ? JSON.parse( JSON.stringify( this.item.quota ) ) : 0;
        if ( this.user_id === 0 ) { this._value = parseFloat( 0 ).toFixed(2); }
        else { this._value = JSON.parse( JSON.stringify( this.item.quota ) ); }
    },
    computed: {
        ref() { return 'quota-' + this.item.user_id; },
        disabled() {
            //if ( this.$store.getters['statemanager/role_id'] !== 1 ) { return true; }
            if ( ![ 1, 6 ].includes( this.$store.getters['statemanager/role_id'] ) ) { return true; }
            return this.item.user_id !== 0 ? false : true;
        },
    },
    methods: {
        focus_lost() {
            this.$emit( 'genericevent', { event_name: 'table-modified', action: 'quota-modified', ref: this.ref, value: this._value  } );
        },
        value_changed( value ) { this._value = value; },
        set_error: function( value ) { this.state = value; },
        set_value( value ) { this._value = parseFloat(value).toFixed(2); this.key += 1; }
    },

}

</script>

<template>

    <div style="width: 110px;">
        <b-input-group size="sm" append="%" :key="key">
            <b-form-input :state="state" :disabled="disabled" :ref="ref" @change="value_changed" @blur="focus_lost" type="number" step="0.01" :value="_value"></b-form-input>
        </b-input-group>
    </div>

</template>

