<script>

const nprogress = require('nprogress');

export default {
    name: 'ispezione',
    props: [ 'label', 'codice_pratica' ],
    mounted () {
        this.$nextTick(function () { // stop loader
            nprogress.done(true);
        })
    },
    computed: {
    },
    created () {
    },
    methods: {
    },
    data () {
        return {
            loading: true,
        }
    },
};

</script>

<template>

    <div>
        <div v-if="!loading">
            <!-- <b-card no-body>
                <b-tabs v-model="selected_tab_index" card>
                    <b-tab v-for="tab in tabs" :title="tab.label" v-bind:key="tab.key" lazy>
                        <b-card-text>
                            <component
                                v-bind:key        = "name"
                                :ref              = "'tab_' + tab.key"
                                :is               = "tab.component"
                                schema_from_route = 'pratica'
                            ></component>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </b-card> -->
        </div>
        <div v-if="loading">
            <div class="text-center">
                <div class="spinner-grow text-dark" role="status" style="width: 6rem; height: 6rem;">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>

</template>

