<script>

const moment = require('moment-timezone');

export default {

    name: 'cmp-diary-type',
    props: [ 'item' ],
    data() {
        return {
            icons: {
                '0': 'gear',
                '1': 'person',
            },
        }
    },
    computed: {
        show_un() { return this.$store.state.diary.show_un; }
    },
    methods: {
    },
    created () {
    },

}

</script>

<template>

    <div style="">
        <component
            :name      = "String( item.type )"
            style      = "margin-right: 3px; cursor: pointer;"
            class      = "rounded-circle"
            :class     = "show_un === false && item.type === 1 ? 'bg-dark text-light' : 'bg-light text-dark'"
            :is        = "'b-icon-' + icons[ String( item.type ) ]"
            scale      = "0.9"
            font-scale = "1.8"
        ></component>
    </div>

</template>



