////////////////////

import { authHeader } from '@/helpers';

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export const ispezione_file_description_service = {
    get_all,
};

function get_all() {
    var options = [
        { id: 'Rischio assicurato', label: 'Rischio assicurato' },
        { id: 'Causa del danno', label: 'Causa del danno' },
        { id: 'Luogo di accadimento', label: 'Luogo di accadimento' },
        { id: 'Ente danneggiato', label: 'Ente danneggiato' },
        { id: 'Particolare del danno', label: 'Particolare del danno' },
        { id: 'Danno già ripristinato', label: 'Danno già ripristinato' },
    ];
    return new Promise(function(resolve, reject) {
        options.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        resolve( options );
    });
}


