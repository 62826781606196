<script>

export default {
    components: {
    },
    computed: {},
    created () {
    },
    methods: {
    },
    data () {
        return {
        }
    },
};

</script>

<template>

    <div>
        <h3 style="text-align: center; padding-bottom: 14px;">PIC Dashboard</h3>
        <img src="https://perizieincloud.s3.amazonaws.com/f110.jpg?AWSAccessKeyId=AKIAQZSIIM3Z6OSBJLPP&Expires=1585743778&Signature=zwomLPDXKbAUzCPzkgsz5x305nw%3D"/>
    </div>

</template>


