//////////////////////////////

moment  = require('moment');
momentz = require('moment-timezone');

module.exports = (type) => {
    var schema = {
        name: {
            type: type.STRING(1024),
            allowNull: false,
        },
        value: {
            type: type.TEXT,
            allowNull: true,
            defaultValue: null,
            get() {
                var value = this.getDataValue('value');
                var res = null;
                if ( value !== null ) {
                    switch ( this.getDataValue('type') ) {
                        case 'integer':
                            res = parseInt( value ); break;
                        case 'json':
                            res = JSON.parse( value ); break;
                        case 'file':
                            res = JSON.parse( value ); break;
                        case 'boolean':
                            res = value === 'true' ? true : false; break;
                        case 'string':
                            res = value; break;
                        case 'dateonly':
                            res = moment( value ); break;
                        case 'datetime':
                            res = moment( value ); break;
                        case 'datetime_tz':
                            res = momentz( value ); break;
                    }
                }
                return res;
            }
        },
        type: {
            type: type.STRING(64),
            allowNull: false,
        },
        codice_pratica: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'pratiche',
                key         : 'codice',
                constraints : true,
                store_name  : 'pratica',
            }
        },
        task_id: {
            type: type.INTEGER,
            allowNull: true,
            defaultValue: null,
            references: {
                model       : 'tasks',
                key         : 'id',
                constraints : true,
                store_name  : 'task',
            }
        }
    };

    return schema;
}


