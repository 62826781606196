//////////////////////////////

const types = require('../bnvform/types').default;


class meta_converter {

    static get_schema(metas, options = { exclude: [] }) {
        var data = { schema: {}, record: {} };
        for ( var i = 0; i < metas.length; i++ ) {
            var meta = metas[ i ];
            if ( meta.type === 'json' ) {
            if ( options.exclude.includes( meta.type )  ) { continue; }
            var post_data = {};
            if ( meta.value.type === 'boolean' ) {
                post_data.type = types.BOOLEAN;
            } else if ( meta.value.type === 'textarea' ) {
                post_data.type = types.TEXT;
            } else if ( meta.value.type === 'date_only' ) {
                post_data.type = types.DATEONLY;
            }
            post_data.allowNull = meta.value.hasOwnProperty('allowNull') ? meta.value.allowNull : true;
            if ( meta.value.hasOwnProperty('label') ) { post_data.label = meta.value.label; }
            data.schema[ meta.name ] = post_data;
            data.record[ meta.name ] = meta.value.value;
            }
        }
        return data;
    }

    static get_schema_with_id(metas, options = { exclude: [] }) {
        var data = meta_converter.get_schema(metas, options);
        var post_data = {
            type      : types.HIDDEN,
            allowNull : false,
        }
        data.schema['id'] = post_data;
        data.record['id'] = metas[0].task_id;
        return data;
    }

}

export default meta_converter;


