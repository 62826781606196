<script>

const schemas = require('@/schemaconfig');
import bnvform from '@/vue-model-mirror/bnvform';
import Address from '@/registry/classes/address';

export default {
    name: 'registry-residenza',
    props: [ 'record', 'started' ],
    components : { bnvform },
    computed : {
        is_valid() { return this.$refs.form.is_valid; },
        form_data() { return this.addressobj.values; },
        label() { return this.addressobj.label; },
        loaded() { return this.$store.state.comune_residenza.loaded; },
    },
    watch: {
        loaded( new_value ) {
            if ( new_value === false ) { return; }
            this.addressobj.comune_id = this.$store.state.comune_residenza.obj.id;
        }
    },
    methods : {
        value_changed( obj ) {
            this.addressobj.set_google_payload( obj );
        },
        operation_success() {},
        reset_form() {},
        submit_form() { // public
            this.$refs.form.submit_form();
            return this.$refs.form.is_valid;
        },
        submit_ad_form() {
            this.$refs.modal_ad.submit_form();
        },
        ad_button( data ) {
            //console.log( data );
            this.toggle_modal(); 
        },
        save_ad( payload ) {
            this.addressobj.set_values( payload );
            this.toggle_modal(); 
            this._record.indirizzo = this.addressobj.label;
            this.key += 1;
        },
        toggle_modal(modal_id = 'modal-address-data') {
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
    },
    created() {
        this._record = JSON.parse( JSON.stringify( this.record ) );
        this.addressobj.store = this.$store;
        if ( this._record && this._record.id ) {
            this.addressobj.set_values( this._record );
        }
    },
    data() {
        return {
            schema_name  : '_address',
            schema       : schemas._address,
            ad_schema    : schemas._address_data,
            addressobj   : new Address(),
            key          : 0,
            options      : {},
            _record      : null,
        }
    },
}

</script>

<template>

    <div>

        <bnvform
            v-if       = "started === true"
            ref        = "form"
            :name      = "schema_name"
            :schema    = "schema"
            :options   = "options"
            :record    = "_record"
            :inline    = "true"
            :submit    = "false"
            :key       = "key"
            @reset     = "reset_form"
            @submitted = "operation_success"
            @saved     = "operation_success"
            @change    = "value_changed"
            @ad-button = "ad_button"
        ></bnvform>

        <!-- modal Address Data -->
        <b-modal
            id          = "modal-address-data"
            title       = "Indirizzo"
            button-size = "sm"
            >
            <bnvform
                name       = "_address_data"
                :schema    = "ad_schema"
                :options   = "{}"
                :record    = "addressobj"
                :submit    = "false"
                :inline    = "true"
                ref        = "modal_ad"
                layout     = "single"
                @submitted = "save_ad"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_ad_form">
                      Salva
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

    </div>

</template>

<style>
</style>

