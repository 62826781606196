<script>

export default {

    name: 'parcel-user',
    props: [ 'item' ],
    data() {
        return {
            label : null,
        }
    },
    created() {
        if ( this.item.user_id > 0 ) {
            this.label = this.$store.getters[ 'user/user_name' ]( this.item.user_id );
        } else if ( this.item.custodian_id ) {
            var custodians = this.$store.getters['statemanager/custodians']( this.item.customer_id );
            this.label = custodians.filter( x => x.id === this.item.custodian_id )[0].label;
        }
    },
    computed: {
        show_name() {
            if ( this.item.user_id > 0 || this.item.custodian_id ) { return true; }
            return false;
        },
        mode() { return this.item.custodian_id ? 'custodian' : 'owner'; },
    },
    methods: {
    },

}

</script>

<template>

    <span v-if="show_name && mode === 'owner'">{{ label }}</span>
    <span v-else-if="show_name && mode === 'custodian'"><b-badge variant="info">{{ label }}</b-badge></span>
    <span v-else-if="item.task_label === 'task' && item.user_id === null" style="color: lightgrey">non assegnato</span>

</template>

