////////////////////////////////

import incarico    from './incarico';
import cliente     from './cliente';
import assicurato  from './assicurato';
import controparte from './controparte';

import riparatore  from './riparatore';
import autorita    from './autorita';
import testimone   from './testimone';

export default {
    incarico,
    cliente,
    assicurato,
    controparte,

    riparatore,
    autorita,
    testimone,
};

