<script>

import bnvlist from '../vue-model-mirror/bnvlist';

export default {
    extends: bnvlist,
    methods: {
        go_to_link: function( row ) {
            this.$router.push( `/form/ispezione/${ row.codice }` );
            //if ( this.linkfunction ) {
            //    this.linkfunction( 'link', row )
            //        .then( res => { this.load_rows( { options: this.get_qry_filters() } ); } );
            //}
        },
        get_title: function( assicurato ) {
            var res = '';
            if ( !assicurato ) { return res; }
            if ( assicurato.cognome ) {
                res += assicurato.cognome;
                if ( assicurato.nome ) { res += ' ' + assicurato.nome; }
            } else if ( assicurato.denominazione ) {
                res = assicurato.denominazione;
            }
            return res;
        },
    }
};

</script>

<template>

    <div>
        <!-- loader -->
        <div v-if="store.loading || started === false" style="text-align: center; padding-top: 20px">
            <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <!-- card list -->
        <div v-if="store.loading === false && started === true">
            <div  style="margin-bottom: 15px">
                <b-card
                    v-for="pratica in store.items"
                    v-bind:key="pratica.codice"
                    :title="get_title( pratica.pregs.assicurato )"
                    :sub-title="pratica.pregs.incarico.tipo_danno"
                    style="margin-bottom: 7px;"
                    :border-variant="'secondary'"
                    @click="go_to_link( pratica )"
                    >
                    <b-card-text>
                        <b-badge variant="warning">#{{ pratica.codice }}</b-badge>
                        <small style="color: grey; font-weight: 700; float: right;">{{ format_date_extended( pratica.pregs.incarico.data_affidamento ) }}</small>
                        <!-- <div class="text-right text-dark" style="margin: 0; padding: 0;">
                            <small><em>{{ pratica.author }}</em> <em v-if="pratica.page"> (pg. {{ pratica.page }})</em></small>
                        </div> -->
                    </b-card-text>
                </b-card>
            </div>
    
            <!-- paginator -->
            <div class="overflow-auto" v-if="paginate === true && started === true">
                <b-pagination
                    v-model="page"
                    :total-rows="total_rows"
                    :per-page="rows_per_page"
                    :aria-controls="'bnvlist-' + schema"
                    align="center"
                ></b-pagination>
            </div>
        </div>
    
    </div>

</template>

<style scoped>
    .secondary {
        opacity: 0.7;
    }
</style>

