<script>

import { mapState } from 'vuex';
const { listpage }   = require('@/vue-model-mirror');

export default {
    name: 'emailtemplate-list',
    props: [ '' ],
    extends: listpage,
    components : {},
    computed : {
        ...mapState('email_template', {
            error_delete  : state => state.error_delete,
            error_message : state => state.error_message,
        }),
    },
    watch: {
        error_delete( new_value ) {
            if ( !new_value ) { return; }
            let message;
            if (
                this.error_message === 'l\'istruzione UPDATE o DELETE sulla tabella "email_templates" viola il vincolo di chiave esterna "reminders_template1_id_fkey" sulla tabella "reminders"' ||
                this.error_message === 'update or delete on table \"email_templates\" violates foreign key constraint \"reminders_template1_id_fkey\" on table \"reminders\"'
               ) {
                message = 'Impossibile eliminare un documento collegato ad un sollecito';
            } else {
                message = 'Errore sconosciuto';
            }
            this.make_toast( 'ELIMINAZIONE TEMPLATE', message )
        }
    },
    methods: {
        make_toast(title, body) {
            this.$bvToast.toast( body, {
                title         : title,
                autoHideDelay : 5000,
                appendToast   : true,
            });
        },
    },
    created() {
        //this.qry_options.customer_id__in = this.$store.getters['authentication/customer_ids'].join(',');
        //console.log( this.qry_options );
        this.$store.dispatch('email_template/force_reload');
        this.loaded = true;
    },
    data() {
        return {
            loaded : false
        }
    },
}

</script>

<template>

    <div style="width: 100%; margin: auto">
        <h3 style="text-align: center; padding-bottom: 14px;">{{ title }}</h3>
        <bnvform
            v-if       = "search_schema"
            :name      = "search_schema"
            :schema    = "schema"
            :options   = "form_config"
            :record    = "record"
            :submit    = "false"
            ref        = "search_form"
            :key       = "form_reload"
            :inline    = "inline"
            @reset     = "reset_form"
            @submitted = "operation_success"
        ></bnvform>
        <b-row style="margin-bottom: 10px">
            <b-col v-if="add" align-self="end" class="text-right">
                <b-button @click="new_element();" size="sm" variant="outline-info"><icon name="plus" style="fill: #17A2B8"/></b-button>
            </b-col>
        </b-row>
        <bnvlist
            v-if             = "loaded"
            :schema          = "schema_name"
            storename        = "bnvform"
            :skip            = "skip"
            :fields          = "fields"
            :primarykey      = "primary_key"
            :qryoptions      = "qry_options"
            :details         = "details"
            :detailsclass    = "details_class"
            :detailsaction   = "details_action"
            :edit            = "edit"
            :editaction      = "edit_action"
            :paginate        = "paginate"
            :link            = "link"
            :rowclass        = "rowclass"
            :linkfunction    = "linkfunction"
            :initialfilter   = "initialfilter"
            ref              = "listbnv"
            :size            = "size"
            :formpath        = "item_form"
            :destroy         = "destroy"
            @row_selected    = "row_selected"
            @right-click     = "right_click"
        ></bnvlist>
    </div>

</template>

<style>
</style>

