<script>

export default {

    name: 'parcel-config',
    props: [ 'item' ],
    data() {
        return {
            percentage : 0,
            minimum    : 0,
        }
    },
    created() {
        this.percentage = JSON.parse( JSON.stringify( this.item.percentage ) );
        this.minimum   = JSON.parse( JSON.stringify( this.item.minimum ) );
    },
    computed: {
        disabled() {
            //return this.$store.getters['statemanager/role_id'] !== 1 ? true : false;
            return ![ 1, 6 ].includes( this.$store.getters['statemanager/role_id'] ) ? true : false;
        },
    },
    methods: {
        percentage_changed( value ) { this.percentage = value; },
        minimum_changed( value ) { this.minimum = value; },
        focus_lost_percentage() {
            this.$emit( 'genericevent', { event_name: 'table-modified', action: 'percentage-modified', id: this.item.id, value: this.percentage } );
        },
        focus_lost_minimum() {
            this.$emit( 'genericevent', { event_name: 'table-modified', action: 'minimum-modified', id: this.item.id, value: this.minimum } );
        },
    },

}

</script>

<template>

    <b-input-group size="sm" v-if="item.user_id != 0">
        <b-form-input style="width: 60px" @blur="focus_lost_percentage" @change="percentage_changed" type="number" :disabled="disabled" :ref="'config-percentage-' + item.task_label + item.user_id" step="0.01" aria-label="Percentage" :value="percentage"></b-form-input>
        <b-form-input style="width: 60px" @blur="focus_lost_minimum" @change="minimum_changed" type="number" :disabled="disabled" :ref="'config-minimum-'+ item.task_label + item.user_id" step="0.01" aria-label="Minimum" :value="minimum"></b-form-input>
    </b-input-group>

</template>

