<script>

const schemas = require('@/schemaconfig');
import baseform from './base_form';
import RegistryResidenza from '../components/residenza';
import RegistryContact from '../components/contact';
import CodFisc from '../helpers/CodFisc';

export default {
    name: 'registry-ente_pubblico',
    extends: baseform,
    props: [ '' ],
    components: { RegistryResidenza, RegistryContact },
    computed: {},
    watch: {},
    methods: {
        async save( options = {} ) { // override
            var payload = this.base_payload;
            for ( var i = 0; i < this.blocks.length; i++ ) {
                this.$refs[ this.blocks[ i ] ].submit_form();
            }
            for ( var i = 0; i < this.blocks.length; i++ ) {
                var form_data = this.$refs[ this.blocks[ i ] ].form_data;
                payload = { ...payload, ...form_data };
                if ( this.$refs[ this.blocks[ i ] ].is_valid === false ) { return false; }
            }
            if ( payload.codice_fiscale ) {
                try {
                    let codfisc_data = CodFisc.parse_codfisc( payload.codice_fiscale );
                    let comune_nascita_id = await this.$store.dispatch( 'comune_nascita/get_by_code', codfisc_data.codice_comune );
                    console.log( { comune_nascita_id } );
                    payload.comune_nascita_id = comune_nascita_id;
                    payload.sesso             = codfisc_data.sesso;
                    payload.data_nascita      = codfisc_data.data_nascita;
                } catch ( error ) {
                    payload.comune_nascita_id = null;
                    payload.sesso             = null;
                    payload.data_nascita      = null;
                }
            }
            if ( !this.in_modal && this.action === 'add' ) {
                payload.related_objs = this.$refs.relations.rels.map( x =>  x.obj );
            }
            //console.log( payload.related_objs );
            if ( this.in_modal === true ) {
                this.add_additional_attributes( payload ); 
                if ( options.save_on_registry === true ) {
                    payload.save_on_registry = true;
                    if ( options.registry_father_id ) {
                        payload.registry_father_id = options.registry_father_id;
                        payload.registry_father_role_id = options.registry_father_role_id;
                    }
                }
                if ( this.action === 'add' ) {
                    this.$store.dispatch( `${ this.schema_name }/create_modal`, { schema: this.schema_name, payload } );
                } else {
                    this.$store.dispatch( `${ this.schema_name }/update_modal`, { schema: this.schema_name, payload } );
                }
            } else if ( this.action === 'add' ) {
                this.$store.dispatch( `${ this.schema_name }/create`, { schema: this.schema_name, payload } );
            } else {
                this.$store.dispatch( `${ this.schema_name }/update`, { schema: this.schema_name, payload, id: this.record.id } );
            }
            return true;
        },
        async cf_button( payload ) {
            var codice_fiscale = this.$refs.form_registry.get_element_value('codice_fiscale');
            if ( codice_fiscale && codice_fiscale.length > 0 ) {
                if ( !CodFisc.validate( codice_fiscale ) ) {
                    this.cf_payload.sesso             = null;
                    this.cf_payload.data_nascita      = null;
                    this.cf_payload.comune_nascita_id = null;
                    if ( payload.open ) { this.toggle_modal(); }
                    return;
                }
                var data = CodFisc.parse_codfisc( codice_fiscale );
                this.cf_payload.sesso          = data.sesso;
                this.cf_payload.data_nascita   = data.data_nascita;
                this.cf_payload.comune_nascita_id = await this.$store.dispatch( 'comune_nascita/get_by_code', data.codice_comune );
                if ( payload.open ) { this.toggle_modal(); }
                return;
            } else {
                this.cf_payload.sesso             = null;
                this.cf_payload.data_nascita      = null;
                this.cf_payload.comune_nascita_id = null;
            }
            if ( payload.open ) { this.toggle_modal(); }
        },
        save_cf_data( payload ) {
            this.$refs.modal_cf.validate();
            if ( !this.$refs.modal_cf.is_valid ) { return; }
            if ( payload.sesso && payload.data_nascita && payload.comune_nascita_id ) {
                var nome    = this.$refs.form_registry.get_element_value('nome');
                var cognome = this.$refs.form_registry.get_element_value('cognome');
                if ( nome && nome.length > 0 && cognome && cognome.length > 0 ) {
                    var data = {
                        nome,
                        cognome,
                        codice_comune : this.$store.state.comune_nascita.obj.codice,
                        data_nascita  : payload.data_nascita,
                        sesso         : payload.sesso,
                    };
                    var codice_fiscale = CodFisc.get_codfisc( data );
                    this.$refs.form_registry.set_payload( { codice_fiscale }, true );
                    this.$refs.form_registry.reset_error('codice_fiscale');
                    this.cf_payload.sesso             = payload.sesso;
                    this.cf_payload.comune_nascita_id = payload.comune_nascita_id;
                    this.cf_payload.data_nascita      = payload.data_nascita;
                }
            }
            this.toggle_modal();
        },
        toggle_modal(modal_id = 'modal-cf') {
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        submit_cf_form() {
            this.$refs.modal_cf.submit_form();
        },
    },
    created() {},
    data() {
        return {
            template_name   : 'Ente pubblico',
            registry_schema : schemas._ente_pubblico,
            blocks          : [ 'form_registry', 'form_contacts', 'form_residenza', ],
            cf_schema       : schemas._codfisc,
            cf_payload      : { sesso: null, data_nascita: null, comune_nascita_id: null, first_name: null, last_name: null },
            relation_config : {
                referente_child  : { template: [ '_persona_fisica', '_persona_giuridica', ], direction: 'child', child_role: 'referente', father_role: 'referente', },
                referente_parent : { template: [ '_persona_fisica', '_persona_giuridica', ], direction: 'parent', child_role: 'referente', father_role: 'referente', },
                polizza          : { template: [ '_polizza' ], direction: 'parent', child_role: 'polizza', father_role: 'polizza' },
            },
        }
    },
}

</script>

<template>

    <div :key="main_key">
        <h3 style="text-align: center; padding-bottom: 14px;">ENTE PUBBLICO</h3>

        <b-row style="margin: 2px; max-width: 800px; margin: auto" v-if="started === true">
        <!-- <b-row style="margin: 2px; margin: auto"> -->
            <b-col style="border: 1px solid lightgrey; padding: 5px;" lg="12" md="12">
                <p style="font-weight: 800; margin-left: 10px; margin-bottom: 0">Anagrafica</p>
                <bnvform
                    v-if       = "started === true"
                    ref        = "form_registry"
                    name       = "_ente_pubblico"
                    :schema    = "registry_schema"
                    :options   = "{}"
                    :record    = "record"
                    :inline    = "true"
                    :submit    = "false"
                    :key       = "registry_key"
                    @cf-button = "cf_button"
                />
                <p style="font-weight: 800; margin-left: 10px; margin-bottom: 0">Contatti</p>
                <registry-contact
                    ref      = "form_contacts"
                    :record  = "record"
                    :started = "started"
                />
            </b-col>
            <b-col style="border: 1px solid lightgrey; padding: 5px;" lg="12" md="12">
                <p style="font-weight: 800; margin-left: 10px; margin-bottom: 0">Sede</p>
                <registry-residenza
                    ref      = "form_residenza"
                    :record  = "record"
                    :started = "started"
                />
            </b-col>
        </b-row>

        <div v-if="in_modal === false">
            <relation-manager
                v-if          = "started === true"
                ref           = "relations"
                :action       = "action"
                :customer_id  = "customer_id"
                :related_objs = "related_objs"
                :record       = "record"
                @selected     = "set_parent"
                >
                <template #relation_buttons="parent">
                    <b-button size="sm" variant="secondary" style="margin-right: 10px;" @click="parent.new_relation( relation_config.referente_child )"><icon name="plus"/> Referente di</b-button>
                    <b-button size="sm" variant="warning" style="margin-right: 10px;" @click="parent.new_relation( relation_config.referente_parent )"><icon name="plus"/> Referente</b-button>
                    <b-button size="sm" variant="warning" style="margin-right: 10px;" @click="parent.new_relation( relation_config.polizza )"><icon name="plus"/> Polizza</b-button>
                </template>
            </relation-manager>

            <b-row class="text-center" style="margin: 2px; margin-top: 15px; max-width: 800px; margin: auto">
                <b-col class="text-left">
                    <b-button v-if="action === 'edit'" size="sm" variant="danger" @click="destroy">Elimina</b-button>
                </b-col>
                <b-col class="text-right">
                    <b-button size="sm" variant="outline-secondary" style="margin-right: 10px" @click="back_to_list">Chiudi</b-button>
                    <b-button size="sm" variant="success" @click="save">Salva</b-button>
                </b-col>
            </b-row>
        </div>

        <!-- modal CF -->
        <b-modal
            id          = "modal-cf"
            title       = "Dati relativi al Codice Fiscale"
            button-size = "sm"
            >
            <bnvform
                name       = "_codfisc"
                :schema    = "cf_schema"
                :options   = "{}"
                :record    = "cf_payload"
                :submit    = "false"
                :inline    = "true"
                ref        = "modal_cf"
                layout     = "single"
                @submitted = "save_cf_data"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col> <!-- commento perché ente_pubblica non ha nome e cognome -->
                    <!-- <b-button size="sm" variant="success" @click="submit_cf_form">
                      Salva
                    </b-button>
                </b-col><b-col> -->
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>
    </div>

</template>

<style>
</style>
