<script>

import { mapState } from 'vuex';

const schemas = require('@/schemaconfig');
import bnvform from '@/vue-model-mirror/bnvform';

export default {
    name: 'add-registry',
    props: [ 'customers', 'payload', 'customer_id', 'invisible' ],
    components : { bnvform },
    computed : {
        ...mapState('registry_template', {
            templates : state => state.template_map_ids,
        }),
        _invisible() {
            return this.invisible === true ? true : false;
        }
    },
    watch: {},
    methods : {
        new_registry( data ) {
            var template = data.hasOwnProperty('template') ? data.template : this.templates.get( data.template_id ).template_name;
            this.$emit( 'save', { customer_id: data.customer_id, template } );
        },
        toggle_modal(modal_id = 'modal-add') {
            if ( modal_id === 'modal-add' ) {
                this.reset_registry_record();
                if ( this.payload ) {
                    if ( this.payload.customer_id && this.payload.template ) {
                        if ( typeof this.payload.template === 'string' || ( Array.isArray( this.payload.template ) && this.payload.template.length === 1 ) ) {
                            var template_label = typeof this.payload.template === 'string' ? this.payload.template : this.payload.template[0];
                            this.new_registry( { customer_id: this.payload.customer_id, template: template_label } );
                            return;
                        } else if ( Array.isArray( this.payload.template ) && this.payload.template.length > 1 ) {
                            this.new_registry_record.customer_id = this.payload.customer_id;
                            //this.new_registry_record.customer_id = this.payload.customers.id;
                        }
                    }
                } else if ( this.customer_id ) {
                    this.new_registry_record.customer_id = this.customer_id;
                } else if ( this.customers.length === 1 ) {
                    this.new_registry_record.customer_id = this.customers[0].id;
                }
            }
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        reset_registry_record() {
            this.new_registry_record = { template_id: null, customer_id: null };
        },
        submit_add_form() {
            this.$refs.modal_add.submit_form();
        },
    },
    created() {},
    data() {
        return {
            add_schema          : schemas._add_registry,
            new_registry_record : { template_id: null, customer_id: null, },
        }
    },
}

</script>

<template>
    <div  v-show="!_invisible" >
        <slot name="button" :toggle_modal="toggle_modal" />
            <b-button v-if="!$scopedSlots.button" @click="toggle_modal()" size="sm" variant="outline-info"><icon name="plus" style="fill: #17A2B8"/></b-button>
        </slot>

        <!-- modal nuova pratica -->
        <b-modal
            id          = "modal-add"
            title       = "Nuova Anagrafica"
            button-size = "sm"
            >
            <bnvform
                name       = "_add_registry"
                :schema    = "add_schema"
                ref        = "modal_add"
                layout     = "single"
                :options   = "{}"
                :record    = "new_registry_record"
                :submit    = "false"
                :inline    = "true"
                @submitted = "new_registry"
            />
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_add_form">
                        OK
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                        Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>
    </div>
</template>

<style>
</style>
