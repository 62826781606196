////////////////////////////////////////

let date_fields = [
    { value: '',                      text: '- - - - - - -' },
    { value: 'data_effetto',          text: 'Data Effetto' },
    { value: 'data_termine',          text: 'Data Termine' },
    { value: 'data_pagamento_premio', text: 'Data Pagamento Premio' },
    { value: 'data_nascita',          text: 'Data di Nascita' },
];

export default date_fields;

