<script>

const schemas = require('@/schemaconfig');
import formpage from '@/vue-model-mirror/formpage';
import RelationManager from '../components/RelationManager';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'registry-baseform',
    extends: formpage,
    props: [ 'modal', 'additional_attributes' ],
    components : { RelationManager },
    computed : {
        customer_id() { return this.store.customer_id; },
        base_payload() {
            return this.action === 'add' ?
                       { customer_id: this.customer_id, template_id: this.$store.state.registry_template.template_map.get( this.template_name ).id } :
                       { customer_id: this.record.customer_id, template_id: this.record.template_id };
        },
        related_objs() {
            if ( !this.record || this.action === 'add' ) { return { parents: [], children: [] }; }
            return { parents: this.record.parents, children: this.record.children };
        },
        in_modal() { return this.modal === true ? true : false; },
        record () { // override
            if ( this.in_modal && !this.additional_attributes ) {
                return {};
            } else if ( this.in_modal && this.additional_attributes ) {
                return this.record_id ? this.store.obj : {};
            }
            return typeof this.$route.params.id !== 'undefined' ? this.store.obj : {};
        },
        record_id() {
            if ( this.in_modal && !this.additional_attributes ) {
                return null;
            } else if ( this.in_modal ) {
                return this.additional_attributes.id;
            }
            return this.$route.params.id;
        },
        ...mapState('registry', {
            new_record           : state => state.new_record,
            new_record_modal     : state => state.new_record_modal,
            record_updated       : state => state.record_updated,
            relation_deleted     : state => state.relation_deleted,
            relation_created     : state => state.relation_created,
            registry_deleted     : state => state.registry_deleted,
            error_delete         : state => state.error_delete,
            error_delete_message : state => state.error_delete_message,
            error                : state => state.error,
            error_obj            : state => state.error_obj,
        }),
    },
    watch: {
        error( new_value ) {
            if ( !new_value ) { return; }
            let errors = {};
            Object.keys( this.error_obj.errors ).map( key => { errors[ key ] = this.error_obj.errors[ key ].message; } )
            this.$refs.form_registry.set_error( errors );
        },
        new_record( new_value ) {
             if ( !new_value ) { return; }
             this.make_toast( 'nuova anagrafica creata' );
             this.main_key += 1;
        },
        record_updated( new_value ) {
             if ( !new_value ) { return; }
             this.make_toast( 'anagrafica aggiornata' );
        },
        new_record_modal( new_value ) {
             if ( !new_value || this.in_modal ) { return; }
             this.make_toast( 'nuova anagrafica creata' );
        },
        relation_deleted( new_value ) {
             if ( !new_value ) { return; }
             this.make_toast( 'relazione eliminata' );
        },
        relation_created( new_value ) {
             if ( !new_value ) { return; }
             this.make_toast( 'relazione creata' );
        },
        registry_deleted( new_value ) {
             if ( !new_value ) { return; }
             this.back_to_list();
        },
        error_delete( new_value ) {
             if ( !new_value ) { return; }
             this.make_toast_error( this.error_delete_message );
        },
    },
    methods : {
        ...mapActions( 'registry', { destroy_registry: 'destroy', } ),
        make_toast( body ) {
            this.$bvToast.toast( body, {
                title         : 'ANAGRAFICHE',
                autoHideDelay : 5000,
                appendToast   : true,
            });
        },
        make_toast_error( body ) {
            this.$bvToast.toast( body, {
                title         : 'ANAGRAFICHE',
                autoHideDelay : 5000,
                appendToast   : true,
                variant       : 'danger',
            });
        },
        _save( options = {} ) {
            //console.log( 'schema name:', this.schema_name );
            if ( this.schema_name !== 'preg' ) { return this.save(); }
            var confirm_options = {
                buttonSize: 'sm', autoFocusButton: 'ok', okVariant: 'info', okTitle: 'Confermo', cancelVariant: 'secondary',
                cancelTitle: 'Annulla', noCloseOnEsc: true, noCloseOnBackdrop: true,
            };
            this.$bvModal.msgBoxConfirm('Salvare la nuova anagrafica anche nel pool delle anagrafiche svincolate dalle pratiche?', confirm_options)
                .then(value => {
                    if ( value ) {
                        options.save_on_registry = true;
                        this.save( options );
                    }
                    else { this.save( options ); }
                })
                .catch(err => {
                    console.log( err );
                });
        },
        save( options = {} ) {
            var payload = this.base_payload;
            for ( var i = 0; i < this.blocks.length; i++ ) {
                this.$refs[ this.blocks[ i ] ].submit_form();
            }
            for ( var i = 0; i < this.blocks.length; i++ ) {
                var form_data = this.$refs[ this.blocks[ i ] ].form_data;
                payload = { ...payload, ...form_data };
                if ( this.$refs[ this.blocks[ i ] ].is_valid === false ) { return false; }
            }
            if ( !this.in_modal && this.action === 'add' ) {
                payload.related_objs = this.$refs.relations.rels.map( x =>  x.obj );
            }
            //console.log( payload.related_objs );
            if ( this.in_modal === true ) {
                this.add_additional_attributes( payload ); 
                if ( options.save_on_registry === true ) {
                    payload.save_on_registry = true;
                    if ( options.registry_father_id ) {
                        payload.registry_father_id = options.registry_father_id;
                        payload.registry_father_role_id = options.registry_father_role_id;
                    }
                }
                if ( this.action === 'add' ) {
                    this.$store.dispatch( `${ this.schema_name }/create_modal`, { schema: this.schema_name, payload } );
                } else {
                    this.$store.dispatch( `${ this.schema_name }/update_modal`, { schema: this.schema_name, payload } );
                }
            } else if ( this.action === 'add' ) {
                this.$store.dispatch( `${ this.schema_name }/create`, { schema: this.schema_name, payload } );
            } else {
                this.$store.dispatch( `${ this.schema_name }/update`, { schema: this.schema_name, payload, id: this.record.id } );
            }
            return true;
        },
        back_to_list() {
            this.$router.push('/list/registry');
        },
        set_parent( payload ) {
        },
        new_registry( data ) {
        },
        destroy() {
            var confirm_options = {
                buttonSize: 'sm', autoFocusButton: 'ok', okVariant: 'info', okTitle: 'Confermo', cancelVariant: 'secondary',
                cancelTitle: 'Annulla', noCloseOnEsc: true, noCloseOnBackdrop: true,
            };
            this.$bvModal.msgBoxConfirm('Eliminare la anagrafica?', confirm_options)
                .then(value => {
                    if ( value ) {
                        this.destroy_registry( this.record.id );
                    }
                })
                .catch(err => {
                    console.log( err );
                });
        },
        add_additional_attributes( payload ) {
            if ( this.additional_attributes ) {
                Object.keys( this.additional_attributes ).map( x => {
                    payload[ x ] = this.additional_attributes[ x ];
                });
            }
        },
    },
    created() {},
    data() {
        return {
            template_name   : null,
            registry_schema : null,
            registry_key    : 0,
            blocks          : [],
            show_all_fields : false,
            main_key        : 1000,
        }
    },
}

</script>

<template>

    <div></div>

</template>

<style>
</style>

