<script>

import bnvform  from '@/vue-model-mirror/bnvform';
const schemas = require('@/schemaconfig');

export default {

    name: 'pratica-amounts',
    props: [ 'codice_pratica' ],
    components: { bnvform, },
    data() {
        return {
            //schema   : null,
            form_key : 0,
            record   : {},
        }
    },
    watch: {
        is_valid(new_val, old_val) {
            if ( new_val !== old_val ) { this.$emit( 'is-valid', new_val ) }
        },
    },
    computed: {
        pratica()      { return this.$store.state.pratica.obj; },
        customers()    { return this.$store.getters['statemanager/customers']; },
        is_custodian() { return ( this.customers.map( x => x.id ).includes( this.pratica.owner ) ) ? false : true; },
        parcel_mode()  { return this.is_custodian ? this.pratica.parcel_mode_custodian : this.pratica.parcel_mode; },
        imponibile()   { return this.is_custodian ? this.pratica.imponibile_custodian : this.pratica.imponibile; },
        schema()       { return this.is_custodian ? schemas.amounts_custodian : schemas.amounts_owner; },
        form_name()    { return this.is_custodian ? 'amounts_custodian' : 'amounts_owner'; },
    },
    methods: {
        change_custodian(payload) { this.value = payload.custodian ? payload.custodian : null; },
        value_changed( payload ) {
        },
        saved( pra ) {
            this.$store.dispatch( 'pratica/override_pratica', pra );
            this.$emit('pra-modified', null);
            //this.form_key += 1;
        },
    },
    created () {
        this.record = JSON.parse( JSON.stringify( this.pratica ) );
        this.record.mode = this.is_custodian ? 'custodian' : 'owner';
        // correggo l'imponibile per il custodian
        if ( this.is_custodian ) {
            var parcel = null;
            this.pratica.my_parcels.map( x => { if ( x.custodian_id === this.pratica.custodian ) { parcel = x; } } );
            this.record.imponibile = parseFloat( this.pratica.imponibile ) * parseFloat( parcel.percentage ) / 100 < parseFloat( parcel.minimum ) ?
                                     parseFloat( parcel.minimum ) :
                                     parseFloat( this.pratica.imponibile ) * parseFloat( parcel.percentage ) / 100;
            this.record.imponibile = this.record.imponibile.toFixed(2);
        }
        this.record.totale_onorari = parseFloat( parseFloat( this.record.imponibile ) + ( this.is_custodian ? parseFloat( this.record.supplemento_pl_custodian ) : parseFloat( this.record.supplemento_pl ) ) ).toFixed(2);
        if ( this.is_custodian ) {
            this.record.totale = (
                parseFloat( this.record.imponibile ) +
                parseFloat( this.pratica.spese_non_imponibili_custodian ) +
                parseFloat( this.pratica.visure_custodian ) +
                parseFloat( this.pratica.supplemento_pl_custodian ) +
                parseFloat( this.pratica.locomozione_custodian ) 
            ).toFixed(2);
        } else {
            this.record.totale = (
                parseFloat( this.record.imponibile ) +
                parseFloat( this.pratica.spese_non_imponibili ) +
                parseFloat( this.pratica.visure ) +
                parseFloat( this.pratica.supplemento_pl ) +
                parseFloat( this.pratica.locomozione ) 
            ).toFixed(2);
        }
        //console.log( this.record );
    },
}

</script>

<template>

    <b-row class="justify-content-md-center">
        <b-col col lg="6" md="6" sm="12">
            <bnvform
                :key       = "form_key"
                :name      = "form_name"
                :schema    = "schema"
                :options   = "{}"
                :record    = "record"
                :submit    = "true"
                :inline    = "false"
                ref        = "amount_form"
                layout     = "single"
                :showexit  = "false"
                pk         = "codice"
                @change    = "value_changed"
                @saved     = "saved"
            ></bnvform>
        </b-col>
    </b-row>

</template>


