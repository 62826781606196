

module.exports = {
    cols: [
          { 
              row1: {
                  field1: 'denominazione',
              },
              row2: {
                  field2: 'indirizzo',
              },
              row3: {
                  field1: 'partita_iva',
              },
              row4: {
                  field1: 'telefono',
              },
              row5: {
                  field1: 'email',
              },
              row6: {
                  field2: 'codice_destinatario'
              },
              field1: 'note',
          },
    ]
};

