//////////////////////////////

// https://stackoverflow.com/questions/7922744/how-can-i-search-all-columns-in-a-table

module.exports = (type) => {

    var schema = {
        /* GENERALE */
        nome: {
            type: type.STRING(64),
            allowNull: true,
        },
        cognome: {
            type: type.STRING(64),
            allowNull: true,
        },
        titolo: {
            type: type.STRING(64),
            allowNull: true,
        },
        note: {
            type: type.TEXT,
            allowNull: true,
        },
    };

    return schema;
}

