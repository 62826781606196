//////////////////////////////

module.exports = (type) => {
    var schema = {
        label: {
            type: type.STRING(64),
            allowNull: false,
            unique: true,
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue: type.NOW,
        },
    };

    return schema;
}


