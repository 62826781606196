////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';

var options = {
    //model_name    : 'user',
    send_alert    : false,
    //default_order : 'last_name ASC',
};

var base = new base_module( options );

const actions = {
    ...base.actions,
    get_all({commit}, data = { options: {} }) {
        commit('start_request');
        get_all('role', data.options)
            .then( response => {
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
};

function get_all() {
    var options = [
        { id: 'codice', label: 'codice' },
    ];
    return new Promise(function(resolve, reject) {
        resolve( options );
    });
}

export default {
    namespaced : true,
    ...base,
    actions,
};

////////////////////

//export const pratica_order_field = {
//    namespaced: true,
//    state: {
//        title_form    : 'Ruolo',
//        obj           : {},
//        items         : null,
//        loading       : false,
//        error         : false,
//        executed      : false,
//        force_reload  : false,
//        page          : 1,  // default
//        rows_per_page : 25, // default
//        tot           : 0,
//    },
//    actions: {
//        get_all({commit}, data = { options: {} }) {
//            commit('start_request');
//            get_all('role', data.options)
//                .then( response => {
//                    if ( data.options.paginate === true ) { // gestione paginator
//                        commit('success_list', response.data);
//                        commit('set_total', response.tot);
//                        commit('set_page', response.page);
//                        commit('set_rows_per_page', response.rows_per_page);
//                    } else {
//                        commit('success_list', response)
//                    }
//                })
//                .catch( error => {
//                    commit('error', error)
//                });
//        },
//    },
//    mutations: {
//        start_request( state ) {
//            state.loading      = true;
//            state.error        = false;
//            state.executed     = false;
//            state.force_reload = false;
//        },
//        success( state, obj ) {
//            state.obj      = obj;
//            state.loading  = false;
//            state.error    = false;
//            state.executed = true;
//        },
//        success_obj( state, obj ) {
//            state.obj      = obj;
//            state.loading  = false;
//            state.error    = false;
//            state.executed = false;
//        },
//        success_list( state, items ) {
//            state.items    = items;
//            state.loading  = false;
//            state.error    = false;
//            state.executed = true;
//        },
//        error( state, error ) {
//            state.obj          = error;
//            state.loading      = false;
//            state.error        = true;
//            state.executed     = false;
//            state.force_reload = true;
//        },
//        set_total( state, data ) {
//            state.tot = data;
//        },
//        set_page( state, data ) {
//            state.page = data;
//        },
//        set_rows_per_page( state, data ) {
//            state.rows_per_page = data;
//        },
//        force_reload(state) {
//            state.force_reload = true;
//        },
//        executed(state) {
//            state.executed = false;
//        },
//        clear( state ) {
//            state.obj          = {};
//            state.items        = null;
//            state.loading      = false;
//            state.error        = false;
//            state.executed     = false;
//            state.force_reload = false;
//        },
//    }
//}
//
//function get_all() {
//    var options = [
//        { id: 'codice', label: 'codice' },
//    ];
//    return new Promise(function(resolve, reject) {
//        resolve( options );
//    });
//}


