<script>

import bnvbase from './bnvbase.vue';

export default {
    extends: bnvbase,
    props: [ 'placeholder', ],

    created() {
    },

};

</script>

<template>

    <div class="form-group" :class="field_class" :style="field_style">
        <label :for="'bnv-form-' + name" :class="label_class">{{ get_label() }}</label>
        <div :class="element_container_class">
            <textarea
                ref="input"
                :id="'bnv-form-' + name"
                class="form-control"
                v-bind:class="{ 'is-invalid': error_component }"
                v-model="model"
                :size = "input_class"
                :placeholder ="get_placeholder()"
                rows="4"
                :aria-label="name"
                :aria-describedby="name"
                :disabled="disabled"
                :readonly="_readonly"
            ></textarea>
            <div class="invalid-feedback">
                {{ get_error_message() }}
            </div>
        </div>
    </div>

</template>



