////////////////////

const axios = require('axios');

import base_module from '@/vue-model-mirror/stores/base.module';
const config = require('@/config')[ process.env.NODE_ENV || 'development' ];
import { authHeader } from '@/helpers';

var options = {
    model_name    : 'comune',
    send_alert    : false,
    default_order : 'nome ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    loaded              : false,
    loaded_sinistro     : false,
    loaded_appuntamento : false,
};

const actions = {
    ...base.actions,
    async get_by_name( { commit, dispatch, getters, state }, data ) {
        var success_load = false;          // serve alla form residenza
        if ( typeof data === 'object' ) {
            var success_load = true;
            name = data.name;
        }
        let url = `${config.backend_url}model/comune/?nome=${ name }`;
        let options = { headers: authHeader() };
        //options.headers['Authorization'] = `Bearer ${ JSON.parse( localStorage.getItem('user') ).jwt }`;
        return await axios.get( url, options )
            .then( async rows => {
                if ( rows.data.length === 0 ) {
                    console.log('IL COMUNE NON È STATO TROVATO');
                    //let row = await dispatch('create', data)
                    data.codice = 'X000';
                    data.zona   = 'XXXXX';
                    data.nome   = data.name;
                    data.custom = true;
                    url = `${config.backend_url}model/comune/`;
                    let row = await axios.post( url, data, options )
                        .then( response => response.data );
                    console.log( { row } );
                    commit('success_obj', row);
                } else {
                    commit('success_obj', rows.data[0]);
                }
                if ( success_load === true ) {
                    if ( data.label ) {
                        let commit_function = `success_load_${ data.label }`;
                        commit( commit_function );
                    } else {
                        commit('success_load');
                    }
                }
                return rows.data.length > 0 ? rows.data[0].id : null
            })
    },

//  async get_by_name( { commit, dispatch, getters, state }, data ) {
//      data.codice = 'X000';
//      data.zona   = 'XXXXX';
//      data.nome   = data.name;
//      data.custom = true;
//      var url = `${config.backend_url}model/comune/`;
//      var options = { headers: authHeader() };
//      return await axios.post( url, data, options )
//          .then( response => {
//              let row = response.data;
//              return row;
//          });
//  },
};

const mutations = {
    ...base.mutations,
    success_load( state ) {
        state.loaded = true;
        setTimeout( () => { state.loaded = false }, 200 );
    },
    success_load_luogo_sinistro( state ) {
        state.loaded_sinistro = true;
        setTimeout( () => { state.loaded_sinistro = false }, 200 );
    },
    success_load_luogo_appuntamento( state ) {
        state.loaded_appuntamento = true;
        setTimeout( () => { state.loaded_appuntamento = false }, 200 );
    },
};

export default {
    namespaced : true,
    getters    : base.getters,
    state,
    mutations,
    actions,
};
