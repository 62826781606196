

module.exports = {
    cols: [
          { 
              row1: {
                  field1: 'codice',
                  field2: 'data_effetto',
              },
              row2: {
                  field1: 'data_termine',
                  field2: 'data_pagamento_premio',
              },
              field1: 'cgas',
              field2: 'attachments',
          },
    ]
};

