////////////////////

import { authHeader } from '@/helpers';

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export const ispezione_doc_description_service = {
    get_all,
};

function get_all() {
    var options = [
        { id: 'Verbale VV.FF/FF.OO.', label: 'Verbale VV.FF/FF.OO.' },
        { id: 'Fattura di acquisto bene danneggiato', label: 'Fattura di acquisto bene danneggiato' },
        { id: 'Dichiarazione di irreparabilità del tecnico', label: 'Dichiarazione di irreparabilità del tecnico' },
        { id: 'Preventivo', label: 'Preventivo' },
        { id: 'Fattura', label: 'Fattura' },
        { id: 'Denuncia di sinistro', label: 'Denuncia di sinistro' },
        { id: 'Rivalsa di Controparte', label: 'Rivalsa di Controparte' },
        { id: 'Documenti d\'identità di', label: 'Documenti d\'identità di' },
        { id: 'Documentazione medica', label: 'Documentazione medica' },
        { id: 'Relazione tecnica', label: 'Relazione tecnica' },
        { id: 'Documentazione lavori svolti dall\'Assicurato al momento del sinistro', label: 'Documentazione lavori svolti dall\'Assicurato al momento del sinistro' },
        { id: 'Libretto animale', label: 'Libretto animale' },
        { id: 'Carta di circolazione veicolo', label: 'Carta di circolazione veicolo' },
        { id: 'Attestato di formazione', label: 'Attestato di formazione' },
        { id: 'Dichiarazione testimoniale', label: 'Dichiarazione testimoniale' },
    ];
    return new Promise(function(resolve, reject) {
        options.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        resolve( options );
    });
}


