
const manager = require('@/helpers/placeholder_manager').default;

var placeholders = function( obj ) {
     var pra = obj.$store.getters['pratica/get_obj'];
     var man = manager.validate( pra, obj.model );
     if ( man.is_valid === false ) {
         throw man.error;
     }
     console.log( man.translation );
     return true;
}

module.exports = [ placeholders ];

