<script>

const manager = require('@/helpers/placeholder_manager').default;

export default {

    name: 'reminder-actions',
    props: [ 'item' ],
    data() {
        return {
            errors: [],
        }
    },
    computed: {
        is_active() { return this.item.active }
    },
    methods: {
    },
    created () {},

}

</script>

<template>

    <div>
        <div v-for="cus in item.my_customers" :key="cus.id">
            <b-badge>{{ cus.label }}</b-badge><br/>
        </div>
    </div>

</template>

