<script>

export default {

    name: 'reminder-edit',
    props: [ 'item' ],
    data() {
        return {
        }
    },
    computed: {},
    methods: {
        edit() {
            this.$emit('genericevent', { event_name: 'edit_item', item_id: this.item.id });
        },
        destroy() {
            var confirm_options = {
                buttonSize: 'sm', autoFocusButton: 'ok', okVariant: 'info', okTitle: 'SÌ', cancelVariant: 'secondary',
                cancelTitle: 'NO', noCloseOnEsc: true, noCloseOnBackdrop: true,
            };
            this.$bvModal.msgBoxConfirm('Eliminare il sollecito?', confirm_options)
                .then(value => {
                    if ( value === true ) {
                        this.$emit('genericevent', { event_name: 'destroy_item', item_id: this.item.id });
                    }
                })
                .catch(err => {
                    console.log( err );
                });
        },
    },
    created () {},

}

</script>

<template>

    <div>

        <!-- <b-button size="sm" variant="light" @click="edit()">
            <icon name="edit"/>
        </b-button> -->

        <b-button size="sm" variant="light" @click="destroy()">
            <icon name="trash" style="fill: #C82333"/>
        </b-button>

    </div>

</template>

