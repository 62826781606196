<script>

import VueContext from 'vue-context';

import files            from './tabs/files';
import assegnazioni     from './tabs/assegnazioni';
import diary            from './tabs/diary';
//import custodian        from './custodian';
//import simple_search    from './simple-search';
import advanced_search  from './components/advanced_search';
import websocket_logger from '../layout/components/websocket_logger';
import websocket_csv    from './tabs/data/components/websocket_logger';

const options        = require('@/listconfig');
const { listpage }   = require('@/vue-model-mirror');
const meta_converter = require('@/vue-model-mirror/helpers/meta_converter').default;
const schemas        = require('@/schemaconfig');
const manager        = require('@/helpers/placeholder_manager').default;

export default {
    name: 'pratica-list',
    extends: listpage,
    components: {
        VueContext,
        'websocket-logger' : websocket_logger,
        'advanced-search'  : advanced_search,
        'websocket-csv'    : websocket_csv,
    },
    watch: {
        import_tab( new_val ) {
            this.import_csv_file = null;
            this.import_customer = '';
            if ( new_val === 1 ) { this.import_customer = 'test'; }
        },
        open_modal(new_val, old_val) { // riportare sul parent
            if ( new_val === true && old_val === false ) {
                this.form = JSON.parse( JSON.stringify( this.$store.state.form_modal.data ) );
                this.$store.dispatch('form_modal/clear');
                this.$store.dispatch('task/get_by_id', { id: this.form.task_id });
            }
        },
        task_qry_select(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch(`task/close_modal`);
                var task = JSON.parse( JSON.stringify( this.$store.state.task.obj ) );
                var schema = meta_converter.get_schema( task.metas );
                this.form.task_id       = task.id;
                this.form.schema        = schema.schema;
                this.form.schema_config = {};
                this.form.record        = schema.record;
                if ( !this.form.description ) { this.form.description = task.task_label; }
                this.form.schema_name   = 'task';
                this.modal_filter_key += 1;
                this.toggle_modal('modal-form');
            }
        },
        task_qry_save(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch(`task/executed`);
                this.toggle_modal('modal-form');
                if ( this.$store.state.task.obj.hasOwnProperty('pratica_metas') && this.$store.state.task.obj.pratica_metas.hasOwnProperty('reload') ) {
                    if ( this.$store.state.task.obj.pratica_metas.reload === true ) {
                        this.$store.dispatch( 'pratica/get_all' , { options: this.$refs.listbnv.get_qry_filters() });
                    }
                }
                setTimeout( () => {
                    this.make_toast( 'SALVATAGGIO TASK', 'task salvato con successo' );
                }, 300);
            }
        },
        pratica_qry_save(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch(`pratica/created`);
            }
        },
    },
    created () {
        if ( this.state_tab === null ) { this.state_tab = this.states[0].key; }
        this.$store.dispatch('userletter/get_all');
        this.export_schema = schemas[ this.export_schema_name ];
        this.$store.dispatch( 'pratica/test_comp' );
    },
    computed: {
        // import csv
        import_in_progress() {
            return this.$store.state.import_csv.loading;
        },
        import_code() {
            return this.$store.state.import_csv.wscode;
        },
        enable_import_buttons() {
            return this.$store.state.import_csv.enable_import_buttons;
        },
        //
//      test_comp () { return this.$store.state[ this.schema_name ].model_name; },
        loading() {
            return this.$store.state[ this.schema_name ].loading;
        },
        user () {
            return JSON.parse( localStorage.getItem('user') );
        },
        state_tab: {
            get : function() {
                return this.$store.state.pratica.state_tab;
            },
            set : function (value) {
                this.$store.dispatch('pratica/set_tab', value);
            }
        },
        show_archived: {
            get : function() {
                return this.$store.state.pratica.show_archived;
            },
            set : function (value) {
                this.$store.dispatch('pratica/set_show_archived', value);
            }
        },
        show_mine: {
            get : function() {
                return this.$store.state.pratica.show_mine;
            },
            set : function (value) {
                this.$store.dispatch('pratica/set_show_mine', value);
            }
        },
        show_completed: {
            get : function() {
                return this.$store.state.pratica.show_completed;
            },
            set : function (value) {
                this.$store.dispatch('pratica/set_show_completed', value);
            }
        },
        filters: {
            get : function() {
                return this.$store.state.pratica.filters;
            },
            set : function (filters) {
                this.$store.dispatch('pratica/set_filters', filters);
            }
        },
        open_modal () { // riportare sul parent
            return this.$store.state.form_modal.open_modal;
        },
        task_qry_select () { // riportare sul parent
            return this.$store.state.task.open_modal;
        },
        task_qry_save () { // riportare sul parent
            return this.$store.state.task.executed;
        },
        pratica_qry_save () { // riportare sul parent
            return this.$store.state.pratica.created;
        },
        tab_counter () {
            return this.$store.state.pratica.tab_counter;
        },
        perizia_loader () {
            return this.$store.state.perizia.loader;
        },
        perizia_message () {
            return this.$store.state.perizia.message;
        },
        perizia_variant () {
            return this.$store.state.perizia.variant;
        },
        perizia_clear () {
            return this.$store.state.perizia.clear;
        },
        report_loader () {
            return this.$store.state.report.loader;
        },
        report_message () {
            return this.$store.state.report.message;
        },
        report_variant () {
            return this.$store.state.report.variant;
        },
        report_clear () {
            return this.$store.state.report.clear;
        },
        states () { return this.$store.getters['statemanager/states']; },
        customers () { return this.$store.getters['statemanager/customers']; },
        email_schema() { return schemas.manual_email_schema; },
        import_customers() {
            let options = [ { value: '', text: '- - - - - - -' } ];
            this.customers.map( x => { options.push( { value: x.id, text: x.label } ); } );
            return options;
        },
        import_templates() {
            let options = [ { value: '', text: '- - - - - - -' } ];
            if ( this.import_customer && this.import_customer !== 'test' ) {
                this.$store.getters['statemanager/templates']( this.import_customer )
                    .map( x => { options.push( { value: x.id, text: x.label } ); } );
            }
            return options;
        },
        wscode_settemplate() {
            return this.$store.state.pratica.wscode_settemplate;
        },
        set_template_in_progress() {
            return this.$store.state.pratica.settemplate_in_progress;
        },
    },
    methods: {
        operation_success: function(data) {
            Object.keys( data ).map( x => {
                if ( data[ x ] === null ) {
                    this.$store.dispatch('pratica/remove_filter', x);
                } else {
                    var filter = {};
                    filter[ x ] = typeof data[ x ] === 'object' ? data[ x ].search : data[ x ];
                    this.$store.dispatch('pratica/add_filter', filter);
                }
            }); 
            //if ( this.user.role_id !== 1 ) { // se non admin passo alla visione "le mie pratiche"
            if ( ![ 1, 6, 7 ].includes( this.user.role_id ) ) {
                if ( Object.keys( this.record ).length === 0 ) {
                    this.show_mine = true;
                } else {
                    this.show_mine = false;
                }
            }
            this.toggle_modal();
//            this.$refs.listbnv.page = 1;
            this.$store.dispatch( 'pratica/get_all' , { options: this.$refs.listbnv.get_qry_filters() });
        },
        operation_success_export: function(data) {
            var params = {};
            Object.keys( data ).map( x => { if ( data[ x ] !== null ) { params[ x ] = data[ x ]; } } );
            params.order = params.order + ' ' + params.order_direction;
            delete params.order_direction;
            this.$store.dispatch('report/reset');
            this.$store.dispatch('report/set_params', params);
            this.$bvToast.show('report-toast');
        },
        make_toast(title, body) {
            this.$bvToast.toast( body, {
                title         : title,
                autoHideDelay : 5000,
                appendToast   : true,
            });
        },
        change_tab(state_label) {
            if ( state_label ) {
                var state_id = null;
                for ( var i = 0; i < this.states.length; i++ ) {
                    //if ( this.states[ i ].name === state_label ) { state_id = this.states[ i ].key; break; }
                    if ( this.states[ i ].description === state_label ) { state_id = this.states[ i ].key; break; }
                }
                if ( this.state_tab !== state_id ) {
                    this.state_tab = state_id;
                    // Sul cambio di tab vado in pagina 1
                    this.$refs.listbnv.page = 1;
                }
            }
           
        },
        clear_search_form( modal_event ) {
            if ( modal_event.trigger === 'clear' ) {
                //if ( this.user.role_id !== 1 ) {
                if ( ![ 1, 6, 7 ].includes( this.user.role_id ) ) {
                    this.show_mine = true;
                }
                this.reset_search_form();
                this.$refs.search_form.submit_form();
            }
        },
        clear_export_form( modal_event ) {
            if ( modal_event.trigger === 'clear' || modal_event.trigger === 'close' ) {
                return;
            }
        },
        clear_import_form( modal_event ) {
            this.import_tab      = 0;
            this.import_csv_file = null;
            this.import_customer = '';
            this.import_template = '';
            this.$store.dispatch( 'import_csv/reset', null );
            this.show_websocket_csv = false;
        },
        submit_search_form() {
            this.$refs.search_form.submit_form();
        },
        submit_export_form() {
            this.$refs.export_form.submit_form();
        },
        submit_import_form() {
            this.show_websocket_csv = false;
            if ( this.import_tab === 0 ) {
                this.$store.dispatch( 'import_csv/create', { payload: { file: this.import_csv_file, customer_id: this.import_customer, template_id: this.import_template } } );
            } else if ( this.import_tab === 1 ) {
                console.log( 'FACCIO PARTIRE IMPORTAZIONE DEI DOCS' );
                this.$store.dispatch('import_csv/push_to_queue', this.import_csv_file);
                var file_number = this.import_csv_file.length;
                if ( file_number > 0 ) {
                    var title = 'Coda di upload';
                    var body = `${ file_number } file aggiunti`;
                    this.toggle_modal('modal-import');
                    this.make_toast( title.toUpperCase(), body );
                    this.$store.dispatch('import_csv/upload_queue');
                }

            }
        },
        make_toast(title, body) {
            this.$bvToast.toast( body, {
                title         : title,
                autoHideDelay : 2500,
                appendToast   : true,
            });
        },
        submit_modal_form() {
            this.$refs.modal_form.submit_form();
        },
        submit_add_form() {
            this.$refs.modal_add.submit_form();
        },
        submit_set_template_form() {
            this.$refs.modal_set_template.submit_form();
        },
        save_modal_form_data: function(payload) {
            var data = { payload, id: this.form.task_id };
            this.$store.dispatch('task/updatemeta', data);
        },
        save_modal_add_data: function(payload) {
            this.customer_id = payload.owner;
            var data = { payload, include: true };
            this.$store.dispatch('pratica/create', data);
            this.creation_in_progress = true;
        },
        save_modal_set_template_data( data ) {
            data.codice_pratica = this.add_template.codice_pratica;
            this.$store.dispatch('pratica/set_template', data);
        },
        reset_modal_form() {
            this.$refs.modal_form.reseta();
        },
        reset_modal_add() { 
        },
        reset_modal_set_template() {
            this.add_template.owner = null;
            this.add_template.template_id = null;
        },
        toggle_modal(modal_id = 'modal-filter') {
            modal_id = modal_id.constructor.name === 'MouseEvent' ? 'modal-filter' : modal_id;
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        show_archived_changed() {
            this.$refs.listbnv.page = 1;
        },
        show_mine_changed() {
            this.$refs.listbnv.page = 1;
        },
        show_completed_changed() {
            this.$refs.listbnv.page = 1;
        },
        get_tab_counter(state_label, type = null) {
            if ( this.loading ) { return '..'; }
            if ( type === null ) {
                if ( this.tab_counter.hasOwnProperty( state_label ) ) { return this.tab_counter[ state_label ].tot + ' / ' + this.tab_counter[ state_label ].new; }
            } else {
                if ( this.tab_counter.hasOwnProperty( state_label ) ) { return this.tab_counter[ state_label ][ type ]; }
            }
            return '0';
        },
        right_click( obj ) {
            this.$refs.menu.open( obj.event, { data: obj.row } );
        },
        context_action( action, codice_pratica, read, pratica ) {
            if ( action === 'read' ) {
                this.$store.dispatch('pratica/set_read', { codice_pratica, read, state_label: this.state_tab }); // state_label non serve più
            } else if ( action === 'perizia' ) {
                this.$store.dispatch('perizia/reset', codice_pratica);
                this.$store.dispatch('perizia/set_codice_pratica', codice_pratica);
                var confirm_options = {
                    buttonSize: 'sm', autoFocusButton: 'ok', okVariant: 'info', okTitle: 'SÌ', cancelVariant: 'secondary',
                    cancelTitle: 'NO', noCloseOnEsc: true, noCloseOnBackdrop: true,
                };
                this.$bvModal.msgBoxConfirm('Abilitare la compressione?', confirm_options)
                    .then(value => {
                        if ( value === true ) { this.$store.dispatch('perizia/set_shrink', true); }
                        else { this.$store.dispatch('perizia/set_shrink', false); }
                        this.$bvToast.show('perizia-toast');
                    })
                    .catch(err => {
                        console.log( err );
                    });
            } else if ( action === 'diary' ) {
                this.menu.component_name = 'diary';
                this.menu.component      = diary;
                this.menu.description    = `Diario della pratica ${ codice_pratica }`;
                this.menu.codice_pratica = codice_pratica;
                this.menu.show           = true;
                this.toggle_modal('modal-menu');
            } else if ( action === 'custodian' ) {
                if ( this.customers.map( x => x.id ).includes( pratica.owner ) === false ) { return; }
                this.menu.component_name = 'custodian';
                this.menu.component      = custodian;
                this.menu.save_disabled  = false;
                this.menu.description    = `Assegnazione custodian pratica ${ codice_pratica }`;
                this.menu.codice_pratica = codice_pratica;
                this.menu.show           = true;
                this.toggle_modal('modal-menu');
            } else if ( action === 'email' ) {
                var customer_id = read.customer_owner.id;
                this.$store.dispatch('sollecito_document_bnvtag/set_customer_id', customer_id);
                this.$store.dispatch('sollecito_document_bnvtag/force_reload');
                this.$store.dispatch('pratica_addresses/load_pratica', read);
                this.email_record.codice_pratica = codice_pratica;
                this.email_record.customer_id = customer_id;
                this.toggle_modal('modal-email');
                this.pra_email = read;
            } else {
                if ( action === 'files' ) {
                    this.menu.component_name = 'files';
                    this.menu.component      = files;
                    this.menu.description    = `Pratica ${ codice_pratica } - Files`;
                } else if ( action = 'assegnazioni' ) {
                    this.menu.component_name = 'assegnazioni';
                    this.menu.component      = assegnazioni;
                    this.menu.description    = `Pratica ${ codice_pratica } - Assegnazioni`;
                }
                this.menu.codice_pratica = codice_pratica;
                this.menu.show           = true;
                this.toggle_modal('modal-menu');
            }
        },
        start_make_perizia() {
            setTimeout( () => {
                this.$store.dispatch('perizia/make');
            }, 1000);
        },
        start_make_report() {
            this.toggle_modal('modal-export');
            setTimeout( () => {
                this.$store.dispatch('report/make', 'csv');
            }, 1000);
        },
        task_modified( task ) {    // chiamato dell'evento 'editedtask' del component 'assengnazioni' e del component 'custodian'
            if ( typeof task === 'string' && task === 'custodian - reload' ) {
                //this.$refs.listbnv.page = 1;
                this.$store.dispatch( 'pratica/get_all' , { options: {} });
                // Ricarico utilizzando i filtri attivi nella lista per rimanere nella stessa pagina
                this.$store.dispatch( 'pratica/get_all' , { options: this.$refs.listbnv.get_qry_filters() });
                this.toggle_modal('modal-menu');
            } else if (                              // ricarica la lista se lo stato del task appena modificato
                    task.newstate === this.state_tab  // è uguale allo stato in visione e show_mine = true
                    //  this.show_mine === true
                    ) {
//                    this.$refs.listbnv.page = 1;
                    this.$store.dispatch('pratica/force_reload');
                    // Ricarico utilizzando i filtri attivi nella lista per rimanere nella stessa pagina
                    this.$store.dispatch( 'pratica/get_all' , { options: this.$refs.listbnv.get_qry_filters() });
            }
        },
        reset_perizia() {
            this.$store.dispatch('perizia/set_clear');
            this.$store.dispatch('perizia/reset');
        },
        reset_report() {
            this.$store.dispatch('report/set_clear');
            this.$store.dispatch('report/reset');
        },
        close_modal_add() {
            this.creation_in_progress = false;
            this.toggle_modal('modal-add');
            setTimeout( () => {
                this.make_toast( 'SALVATAGGIO PRATICA', 'pratica salvata con successo' );
//                this.$refs.listbnv.page = 1;
                this.$store.dispatch( 'pratica/get_all' , { options: this.$refs.listbnv.get_qry_filters() });
            }, 300);
        },
        close_modal_set_template() {
            this.$store.dispatch('pratica/settemplate_in_progress', false);
            this.toggle_modal('modal-set-template');
        },
        connection_close_modal_set_template() {
            this.$store.dispatch('pratica/force_reload');
//            this.$refs.listbnv.page = 1;
            this.$store.dispatch( 'pratica/get_all' , { options: this.$refs.listbnv.get_qry_filters() });
        },
        close_modal_import() {
            this.$store.dispatch( 'import_csv/reset', null );
        },
        set_enabled_import_buttons( data ) {
            this.$store.dispatch( 'import_csv/set_enabled_import_buttons', true );
            this.import_csv_file = null;
            this.import_customer = '';
            this.import_template = '';
            if ( data.keep_component === true ) {
                this.show_websocket_csv = true;
            }
        },
        reload() {
            this.$refs.listbnv.page = 1;
        },
        modal_add_change( payload ) {
            var field = Object.keys( payload )[ 0 ];
            if ( field === 'owner' ) {
                var value = payload[ field ];
                this.$store.dispatch( 'template/get_all', { options: { owner: value } } );
            }
        },
        change_menu_save_disabled( value ) {
            //this.menu.save_disabled = !value;
        },
        reset_modal_email() {},
        save_modal_email( data ) {
             var pra = this.pra_email;
             var error_email = false;
             var man = manager.validate( pra, data.body_email1 );
             if ( man.is_valid === false ) {
                 this.$refs.form_email.set_error({ body_email1: man.error })
             } else {
                 var mailto = data.to.map( x => x.label ).join(';') + '?';
                 if ( data.cc.length > 0 ) {
                     mailto += 'cc=' + data.cc.map( x => x.label ).join(';')
                 }
                 if ( data.bcc.length > 0 ) {
                     if ( mailto.endsWith('?') === false ) { mailto += '&'; }
                     mailto += 'bcc=' + data.bcc.map( x => x.label ).join(';')
                 }
                 if ( mailto.endsWith('?') === false ) { mailto += '&'; }
                 mailto += 'subject=' + encodeURI( man.subject );
                 var translation = man.translation;
                 if ( data.docs.length > 0 ) {
                     var docs_string = data.docs.map( x => { return `  - ${ x.label }` } ).join('\n');
                     translation = translation.replace('{{documenti}}', docs_string);
                 }
                 mailto += '&body=' + encodeURI( translation );
                 var link = document.createElement("a");
                 link.id   = 'email_link';
                 link.href = "mailto:" + mailto;
                 link.setAttribute('target', '_blank');
                 document.body.appendChild( link )
                 document.getElementById('email_link').click();
                 link.remove();
                 this.toggle_modal('modal-email');
                 this.pra_email = null;
                 this.email_record = {};
             }
        },
        submit_email_form() {
            this.$refs.form_email.submit_form();
        },
        email_payload_changed( payload ) {
            var data = {};
            if ( payload.hasOwnProperty('template1_id') ) {
                var template_id  = payload['template1_id'];
                var target_field = 'body_email1';
                var target_label = 'Corpo prima email';
            } else if ( payload.hasOwnProperty('template2_id') ) {
                var template_id  = payload['template2_id'];
                var target_field = 'body_email2';
                var target_label = 'Corpo solleciti';
            } else {
                return;
            }
            var body_template = this.$store.getters['email_template/email_body']( template_id );
            data[ target_field ] = body_template;
            var body_written = this.$refs.form_email.get_value_by_element_name( target_field );
            if ( body_written ) {
                var confirm_options = {
                    buttonSize: 'sm', autoFocusButton: 'ok', okVariant: 'info', okTitle: 'SÌ', cancelVariant: 'secondary',
                    cancelTitle: 'NO', noCloseOnEsc: true, noCloseOnBackdrop: true,
                };
                this.$bvModal.msgBoxConfirm(`Il campo "${ target_label }" non è vuoto, sovrascrivere il contenuto?`, confirm_options)
                    .then(value => {
                        if ( value === true ) {
                            this.$refs.form_email.set_payload( data, true ); // true è il force
                        }
                    })
                    .catch(err => {
                        console.log( err );
                    });
            } else {
                this.$refs.form_email.set_payload( data );
            }
        },
        set_filter( payload ) {
            if ( payload.text ) {
                this.$store.dispatch('pratica/add_filter', { contains: payload.text });
            } else {
                this.$store.dispatch('pratica/remove_filter', 'contains');
            }
            if ( payload.date_filters && payload.date_filters.length > 0 ) {
                this.$store.dispatch('pratica/add_filter', { date_filters: payload.date_filters });
            } else {
                this.$store.dispatch('pratica/remove_filter', 'date_filters');
            }
            this.$store.dispatch('pratica/force_reload');
            this.$refs.listbnv.page = 1;
        },
        set_template( cp, owner ) {
            this.add_template.codice_pratica = cp;
            this.add_template.owner          = owner;
            this.$store.dispatch( 'template/get_all', { options: { owner } } );
            this.toggle_modal('modal-set-template');
        },
    },
    data () {
        return {
            form_reload      : 0,
            inline           : false,
            modal_filter_key : 0,
            modal_add_key    : 0,
            modal_set_template_key : 100000,
            add_schema       : schemas.add_pratica,
            menu             : {
                title          : null,
                show           : false,
                key            : 0,
                component      : null,
                codice_pratica : null,
                component_name : null,
                description    : null,
                save_disabled  : false,
            },
            form              : {
                schema_name   : null,
                schema        : null,
                schema_config : {},
                record        : {},
                task_id       : null,
                description   : null,
            },
            export_schema_name   : 'export_pratica',
            export_schema        : null,
            modal_export_key     : 0,
            customer_id          : null,
            creation_in_progress : false,
            modal_form_email     : 0,
            email_record         : {},
            pra_email            : null,
            form_config          : {},
            import_tab           : 0,
            import_csv_file      : null,
            import_customer      : '',
            import_template      : '',
            show_websocket_csv   : false,
            add_template         : {
                owner          : null,
                template_id    : null,
                codice_pratica : null,
            },
        };
    },
};

</script>

<template>

    <div style="width: 100%; margin: auto;">

        <h3 style="text-align: center; padding-bottom: 14px;" class="d-sm-none">{{ title }}</h3>
        <b-row style="margin-bottom: 10px;">
            <b-col align-self="center" lg="auto" md="6">
                <b-button v-if="add && [ 1, 3, 6 ].includes( user.role_id )" @click="toggle_modal('modal-add')" size="sm" variant="outline-info" v-b-tooltip.hover title="Nuova Pratica">
                    <icon name="plus" style="fill: #17A2B8"/>
                </b-button>
           <!-- <b-button @click="toggle_modal('modal-filter')" size="sm" variant="outline-success btn-sm mr-auto" style="margin-left: 10px;" :disabled="true"><icon name="filter" style="fill: #28A745"/></b-button> -->
                <b-button :disabled="user.role_id !== 1" @click="toggle_modal('modal-import')" size="sm" variant="outline-success btn-sm mr-auto" style="margin-left: 10px;" v-b-tooltip.hover title="Upload CSV">
                    <icon name="upload" style="fill: #28A745"/>
                </b-button>
                <b-button v-if="user.role_id === 1" @click="toggle_modal('modal-export')" size="sm" variant="outline-secondary btn-sm mr-auto" style="margin-left: 10px;" v-b-tooltip.hover.right title="Download CSV">
                    <icon name="download" style="fill: #6D767E"/>
                </b-button>
            </b-col>
            <b-col align-self="center" md="6">
                <b-form-checkbox
                    inline 
                    switch
                    :disabled = "![ 1, 6, 7 ].includes( user.role_id )"
                    @change   = "show_mine_changed()"
                    id        = "mine"
                    v-model   = "show_mine"
                    >
                    Le mie pratiche
                </b-form-checkbox><br/>
                <b-form-checkbox
                    inline 
                    switch
                    :disabled = "show_mine === false"
                    button-variant   = "success"
                    @change   = "show_completed_changed()"
                    id        = "completed"
                    v-model   = "show_completed"
                    >
                    Mostra completati
                </b-form-checkbox>
                <b-form-checkbox
                    inline 
                    switch
                    button-variant   = "warning"
                    @change   = "show_archived_changed()"
                    id        = "archived"
                    v-model   = "show_archived"
                    >
                    Mostra archiviati
                </b-form-checkbox>
            </b-col>
            <b-col class="text-right" align-self="center">
                <!-- <simple-search @reload-rows="reload"/> -->
                <advanced-search @search="set_filter"/>
            </b-col>
        </b-row>
        <div style="margin-bottom: 15px;">
            <b-tabs>
                <template v-for="state in states">
                    <b-tab :active="state_tab === state.key" @click="change_tab( state.description )">
                    <template #title>
                    {{ state.name }}  <b-badge :variant="state_tab === state.key ? 'dark': 'secondary'">{{ get_tab_counter(state.label, 'tot') }}</b-badge><b-badge :style="{ visibility: ( get_tab_counter(state.label, 'tot') > 0 && get_tab_counter(state.label, 'new') > 0 ) ? 'visible' : 'hidden' }" style="margin-left: 2px;" :variant="state_tab === state.label ? 'warning': 'warning'">{{ get_tab_counter(state.label, 'new') }}</b-badge>
                    </template>
                    </b-tab>
                </template>
            </b-tabs>
        </div>
        <bnvlist
            :schema          = "schema_name"
            storename        = "bnvform"
            :skip            = "skip"
            :fields          = "fields"
            :primarykey      = "primary_key"
            :qryoptions      = "qry_options"
            :details         = "details"
            :detailsclass    = "details_class"
            :detailsaction   = "details_action"
            :edit            = "edit"
            :editaction      = "edit_action"
            :paginate        = "paginate"
            :link            = "link"
            :rowclass        = "rowclass"
            :linkfunction    = "linkfunction"
            :initialfilter   = "initialfilter"
            ref              = "listbnv"
            :formpath        = "item_form"
            :size            = "size"
            @row-selected    = "row_selected"
            @right-click     = "right_click"
            @set_template    = "set_template"
        ></bnvlist>

        <!-- menu contestuale -->
        <vue-context ref="menu">
            <template slot-scope="child">
                <li v-if="child.data" style="cursor: pointer">
                    <a @click.prevent="context_action( 'read', child.data.data.codice, !child.data.data.read )">Segna come "{{ child.data.data.read === true ? 'da leggere' : 'letta' }}"</a>
                </li>
                <li style="cursor: pointer">
                    <a @click.prevent="context_action( 'files', child.data.data.codice )">Files</a>
                </li>
                <li style="cursor: pointer">
                    <a @click.prevent="context_action( 'assegnazioni', child.data.data.codice, null, child.data.data )">Assegnazioni</a>
                </li>
                <li style="cursor: pointer">
                    <a @click.prevent="context_action( 'diary', child.data.data.codice )">Diario</a>
                </li>
                <!-- <li style="cursor: pointer">
                    <a @click.prevent="context_action( 'custodian', child.data.data.codice, null, child.data.data )">Custodian</a>
                </li> -->
                <li style="cursor: pointer" v-if="perizia_clear === true">
                    <a @click.prevent="context_action( 'perizia', child.data.data.codice )">Genera relazione</a>
                </li>
                <li style="cursor: pointer">
                    <a @click.prevent="context_action( 'email', child.data.data.codice, child.data.data )">Nuova Email</a>
                </li>
            </template> 
        </vue-context>

        <!-- modal filtri -->
        <!-- 
        <b-modal
            id="modal-filter"
            title="Filtri"
            @hide="clear_search_form"
            button-size="sm"
            >
            <bnvform
                v-if       = "search_schema"
                :key       = "modal_filter_key"
                :name      = "search_schema"
                :schema    = "schema"
                :options   = "form_config"
                :record    = "filters"
                :submit    = "false"
                :inline    = "inline"
                ref        = "search_form"
                @submitted = "operation_success"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_search_form">
                      OK
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="warning" @click="hide('clear')">
                      Reset
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>
        -->

        <!-- modal import -->
        <b-modal
            id          = "modal-import"
            title       = "Importazione"
            @hide       = "clear_import_form"
            button-size = "sm"
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            >
            <b-tabs content-class="mt-3" pills v-model="import_tab">
                <b-tab title="Pratiche">
                    <h5 style="margin-bottom: 10px; text-align: center">Importazione Pratiche</h5>
                    <b-form-group label="File csv">
                        <b-form-file
                            v-model          = "import_csv_file"
                            placeholder      = "Scegli un file o trascinalo qui..."
                            drop-placeholder = "Trascina il file qui..."
                        ></b-form-file>
                    </b-form-group>
                    <b-form-group label="Customer">
                        <b-form-select v-model="import_customer" :options="import_customers"></b-form-select>
                    </b-form-group>
                    <b-form-group label="Template">
                        <b-form-select v-model="import_template" :options="import_templates"></b-form-select>
                    </b-form-group>
                    <div v-if="( import_in_progress && import_code ) || show_websocket_csv" style="margin-top: 20px;">
                        <websocket-csv
                            :wspath = "'ws/importcsv/?code=' + import_code"
                            title   = "Importazione pratiche"
                            @closed = "close_modal_import"
                            @connection_closed = "set_enabled_import_buttons"
                            >
                        </websocket-csv>
                    </div>
                </b-tab>
                <b-tab title="Documenti" disabled>
                    <h5 style="margin-bottom: 10px; text-align: center">Importazione Documenti</h5>
                    <b-form-group label="File zip">
                        <b-form-file
                            v-model          = "import_csv_file"
                            placeholder      = "Scegli un file o trascinalo qui..."
                            drop-placeholder = "Trascina il file qui..."
                            multiple
                        ></b-form-file>
                    </b-form-group>
                </b-tab>
            </b-tabs>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_import_form" :disabled="!enable_import_buttons || !import_csv_file || !import_customer">
                      OK
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="hide('close')" :disabled="!enable_import_buttons">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <!-- modal export -->
        <b-modal
            id="modal-export"
            title="Esportazione"
            @hide="clear_export_form"
            button-size="sm"
            >
            <bnvform
                v-if       = "export_schema_name"
                :key       = "modal_export_key"
                :name      = "export_schema_name"
                :schema    = "export_schema"
                :options   = "form_config"
                :record    = "{ order_direction: 'DESC', order: 'codice', format: 'csv', customer: null  }"
                :submit    = "false"
                :inline    = "true"
                ref        = "export_form"
                @submitted = "operation_success_export"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_export_form">
                      OK
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="hide('close')">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <!-- modal per le voci del menu contestuale -->
        <b-modal
            id          = "modal-menu"
            :title      = "menu.description"
            button-size = "sm"
            size        = "lg"
            >
            <component
                v-if              = "menu.show"
                :ref              = "menu.key"
                :is               = "menu.component"
                :codice_pratica   = "menu.codice_pratica"
                :modal            = "true"
                schema_from_route = "diary"
                @editedtask       = "task_modified"
                @is-valid         = "change_menu_save_disabled"
            ></component>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col v-if="menu.component_name === 'custodian'">
                    <b-button size="sm" variant="success" @click="$refs[ menu.key ].save()" :disabled="menu.save_disabled">
                      Salva
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <!-- modal per le form dei task -->
        <b-modal
            id          = "modal-form"
            :title      = "form.description ? form.description.charAt(0).toUpperCase() + form.description.slice(1) : ''"
            @hide       = "reset_modal_form"
            button-size = "sm"
            >
            <!-- ho dovuto togliere la riga:
                 v-if       = "form.schema_name"
                 ma non ho capito il perché (2022.10.31)
            -->
            <bnvform
                :key       = "modal_filter_key"
                :name      = "form.schema_name"
                :schema    = "form.schema"
                :options   = "form.schema_config"
                :record    = "form.record"
                :submit    = "false"
                :inline    = "true"
                ref        = "modal_form"
                layout     = "single"
                @submitted = "save_modal_form_data"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_modal_form">
                      Salva
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="warning" @click="hide('clear')">
                      Reset
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <!-- modal set template -->
        <b-modal
            id          = "modal-set-template"
            title       = "Impostazione del template"
            @hide       = "reset_modal_set_template"
            button-size = "sm"
            >
            <bnvform
                :key       = "modal_set_template_key"
                name       = "set_template"
                :schema    = "add_schema"
                :options   = "{}"
                :record    = "add_template"
                :submit    = "false"
                :inline    = "true"
                ref        = "modal_set_template"
                @submitted = "save_modal_set_template_data"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button :disabled="set_template_in_progress" size="sm" variant="success" @click="submit_set_template_form">
                      Salva
                    </b-button>
                </b-col><b-col>
                    <b-button :disabled="set_template_in_progress" size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
            <div v-if="set_template_in_progress" style="width: 90%; margin: auto;">
                <websocket-logger
                    :wspath = "'ws/settemplate/?code=' + wscode_settemplate"
                    title   = "Creazione pratica"
                    @closed = "close_modal_set_template"
                    @connection_closed = "connection_close_modal_set_template"
                    >
                </websocket-logger>
            </div>
        </b-modal>

        <!-- modal nuova pratica -->
        <b-modal
            id          = "modal-add"
            title       = "Nuova Pratica"
            @hide       = "reset_modal_add"
            button-size = "sm"
            >
            <bnvform
                :key       = "modal_add_key"
                name       = "add_pratica"
                :schema    = "add_schema"
                :options   = "{}"
                :record    = "{}"
                :submit    = "false"
                :inline    = "true"
                ref        = "modal_add"
                @submitted = "save_modal_add_data"
                @change    = "modal_add_change"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button :disabled="creation_in_progress" size="sm" variant="success" @click="submit_add_form">
                      Salva
                    </b-button>
                </b-col><b-col>
                    <b-button :disabled="creation_in_progress" size="sm" variant="warning" @click="hide('clear')">
                      Reset
                    </b-button>
                </b-col><b-col>
                    <b-button :disabled="creation_in_progress" size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
            <div v-if="creation_in_progress" style="width: 80%; margin: auto;">
                <websocket-logger
                    :wspath = "'ws/creation/?code=' + customer_id"
                    title   = "Creazione pratica"
                    @closed = "close_modal_add"
                    >
                </websocket-logger>
            </div>
        </b-modal>

        <!-- modal form email -->
        <b-modal
            id          = "modal-email"
            title       = "Nuova Email"
            @hide       = "reset_modal_email"
            size        = "lg"
            button-size = "sm"
            >
            <bnvform
                :key       = "modal_form_email"
                name       = "manual_email"
                :schema    = "email_schema"
                :options   = "{}"
                :record    = "email_record"
                :submit    = "false"
                :inline    = "true"
                @change    = "email_payload_changed"
                ref        = "form_email"
                @submitted = "save_modal_email"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_email_form">
                      Invia
                    </b-button>
                <!-- </b-col><b-col>
                    <b-button size="sm" variant="warning" @click="hide('clear')">
                      Reset
                    </b-button> -->
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <b-toast
            no-auto-hide
            id       = "perizia-toast"
            :title   = "perizia_message.class"
            :variant = "perizia_variant"
            :solid   = "true"
            toaster  = "b-toaster-bottom-right"
            @shown   = "start_make_perizia"
            @hidden  = "reset_perizia"
            >
			<span v-if="perizia_message.hasOwnProperty('url') === false">{{ perizia_message.action }}</span><a v-if="perizia_message.hasOwnProperty('url')" :href="perizia_message.url" target="_blank">{{ perizia_message.file_name }}</a>
            <b-spinner v-if="perizia_loader" variant="primary" label="Spinning" style="float: right;" small></b-spinner>
        </b-toast>

        <b-toast
            no-auto-hide
            id       = "report-toast"
            :title   = "report_message.class"
            :variant = "report_variant"
            :solid   = "true"
            toaster  = "b-toaster-bottom-right"
            @shown   = "start_make_report"
            @hidden  = "reset_report"
            >
			<span v-if="report_message.hasOwnProperty('url') === false">{{ report_message.action }}</span><a v-if="report_message.hasOwnProperty('url')" :href="report_message.url" target="_blank">{{ report_message.file_name }}</a>
<b-spinner v-if="report_loader" variant="primary" label="Spinning" style="float: right;" small></b-spinner>
        </b-toast>

    </div>

</template>

<style>
    tr.new td {
        font-weight: 800;
    }
    .border-active {
        border: 4px solid black;
    }
    .border-delivered {
        border: 4px solid green;
    }
</style>

