<script>

export default {

    name: 'statistic-amount',
    props: [ 'item' ],
    data() {
        return {
        }
    },
    created() {
    },
    computed: {
        amount() { return String( parseFloat( this.item.amount ).toFixed(2) ).replace('.', ',') + ' €'; }
    },
    methods: {
    },

}

</script>

<template>

    <!-- <div style="text-align: right;"> -->
    <div>
        <span>{{ amount }}</span>
    </div>

</template>


