<script>

export default {

    name: 'parcel-user-user',
    props: [ 'item' ],
    data() {
        return {
            pratica: null,
        }
    },
    created() {
        this.pratica = this.$store.getters['pratica/get_obj'];
    },
    computed: {
        tasks() {
            if ( this.item.custodian_id ) {
                var tasks = this.pratica.tasks.filter( x => ( x.customer_id === this.item.custodian_id && x.newstate < 4000 ) );
            } else {
                var tasks = this.pratica.tasks.filter( x => ( x.user_id === this.item.user_id ) );
            }
            return tasks;
        },
        customers () { return this.$store.getters['statemanager/customers'].map( x => x.id ); },
    },
    methods: {
        get_icon( task_label ) {
            return 'b-icon-' + this.$store.getters[ 'statemanager/icon' ]( task_label );
        },
        get_icon_color( task_label ) {
            for ( var i = 0; i < this.pratica.tasks.length; i++ ) {
                if ( this.pratica.tasks[ i ].task_label === task_label && this.customers.includes( this.pratica.tasks[ i ].customer_id ) ) {
                    var res =  this.pratica.tasks[ i ].label === null ? 'bg-danger' : this.$store.getters['statemanager/color_by_state']( this.pratica.tasks[ i ].label );
                    return res;
                }
            }
        },
        get_title( str ) {
            str = str.replace(/_/g, ' ');
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        is_unpaid( customer_id ) {
            if ( this.item.custodian_id ) { return false; }
            return this.$store.getters[ 'statemanager/unpaid_tasks' ]( this.item.customer_id ).includes( customer_id );
        },
        get_task_counter() {
            var custodian_tasks = this.pratica.tasks.filter( x => x.customer_id === this.item.custodian_id && x.newstate < 4000 );
            var total     = custodian_tasks.length;
            var completed = custodian_tasks.filter( x => [ 'done', 'unnecessary' ].includes( x.label ) ).length;
            return `${ completed }/${ total }`;
        }
    },

}

</script>

<template>

    <div style="vertical-align: middle" v-if="item.custodian_id === null">
        <div class="form-inline">
            <div v-for="task in tasks" class="text-center" style="padding: 0; margin: 0;display: inline-block">
                <div :title="get_title( task.task_label )">
                    <component
                        v-bind:key = "task.task_label"
                        :name      = "task.task_label"
                        style      = "margin-right: 3px; cursor: pointer;"
                        class      = "rounded-circle"
                        variant    = "light"
                        :class     = "get_icon_color( task.task_label )"
                        :is        = "get_icon( task.task_label )"
                        scale      = "0.9"
                        font-scale = "1.8"
                        :style     = "{ 'opacity': is_unpaid( task.task_label ) ? 0.4 : 1 }"
                    ></component><br/>
                </div>
            </div>
        </div>
    </div>
    <div style="vertical-align: middle" v-else-if="item.custodian_id !== null">
        <span style="font-weight: 600;">{{ get_task_counter() }}</span>
    </div>

</template>

