//////////////////////////////

module.exports = (type) => {
    var schema = {
        key: {
            type: type.INTEGER,
            allowNull: false,
            primaryKey: true,
        },
        label: {
            type: type.STRING(64),
            allowNull: false,
        },
        description: {
            type: type.STRING(64),
            allowNull: false,
        },
        metas: {
            type: type.JSON,
            allowNull: false,
        },
    };

    return schema;
}

