//////////////////////////////

// https://stackoverflow.com/questions/7922744/how-can-i-search-all-columns-in-a-table

module.exports = (type) => {

    var schema = {
        nome: {
            type: type.STRING(64),
            allowNull: true,
        },
        cognome: {
            type: type.STRING(64),
            allowNull: true,
        },
        telefono: {
            type: type.JSONB,
            defaultValue: [],
            allowNull : false,
            options: {
                type          : 'object_manager',
                new_item_type : 'string',
                not_empty     : true,
                label         : true,
                note          : true,
            },
        },
        email: {
            type: type.JSONB,
            defaultValue: [],
            allowNull : false,
            options: {
                type          : 'object_manager',
                new_item_type : 'email',
                not_empty     : true,
                label         : true,
            },
        },
    };

    return schema;
}


