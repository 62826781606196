////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'pratica',
    send_alert    : false,
    default_order : 'codice DESC',
};

var base = new base_module( options );

const actions = {
    ...base.actions,
    get_all( { commit, state, getters }, _data = { options: {} } ) { // ok
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        commit('start_request');
        data.options.order = getters.order;
        //Object.keys( state.filters ).map( key => { data.options[ key ] = state.filters[ key ]; } );
        state.service.get_all(getters.model_name + '/ispezione', data.options)
            .then( response => {
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
};

export default {
    namespaced : true,
    ...base,
    actions,
};

