<script>

const nprogress = require('nprogress');

const config = require('@/config.js')[ process.env.NODE_ENV || 'development' ]
const packagejson = require('@/../package.json');

export default {
    data () {
        return {
            password         : '',
            confirm_password : '',
            submitted        : false,
            enabled          : true,
        }
    },
    mounted () {
        this.$nextTick(function () {
            // Code that will run only after the
            // entire view has been rendered
            nprogress.done(true);
            this.$refs.password_input.focus();
        });
        if ( !this.jwt ) {
            this.enabled = false;
            this.$store.dispatch( 'alert/error', 'token assente' );
        }
    },
    watch: {
        password_changed( new_value, old_value ) {
            if ( new_value === true ) { setTimeout( () => { this.$router.push('/login') }, 3000 ); }
        }
    },
    computed: {
        loggingIn () {
            return this.$store.state.authentication.status.loggingIn;
        },
        password_changed () {
            return this.$store.state.authentication.password_changed;
        },
        alert () {
            return this.$store.state.alert;
        },
        icon_url () {
            if ( config.gui.hasOwnProperty('icon') && config.gui.icon ) { return config.backend_url + `icons/${ config.gui.icon }`; }
            return undefined;
        },
        color () {
            if ( config.gui.hasOwnProperty('color') && config.gui.color ) { return config.gui.color; }
            return undefined;
        },
        jwt() {
            return this.$route.query.jwt || null;
        },
    },
    created () {
        // reset login status
        this.$store.dispatch('authentication/logout');
    },
    methods: {
        handleSubmit (e) {
            this.submitted = true;
            const { password, confirm_password, jwt } = this;
            const { dispatch } = this.$store;
            if ( password && confirm_password ) {
                dispatch('authentication/save_password', { jwt, password, confirm_password });
            }
        },
    },
    components: {
    }
};

</script>

<template>
 
        <b-container>
            <b-col sm="10" md="7" lg="4" style="margin: auto;">
                <b-card
                    v-bind:style="color ? 'border: 2px solid ' + color : ''"
                    style="padding-top: 20px; padding-left: 20px; padding-right: 20px; padding-bottom: 0px;"
                    >
                    <b-row>
                        <b-col>
                            <div v-if="icon_url" class="text-center">
                                <b-img :src="icon_url" thumbnail fluid rounded="circle" width="120" alt="customer logo"></b-img>
                            </div>
                            <h4
                                class="text-center"
                                style="font-weight: 700; margin-bottom: 30px; margin-top: 10px;"
                                v-bind:style="color ? 'color: ' + color : ''"
                                >
                                Reset della password
                            </h4>
                            <form class="form-signin" @submit.prevent="handleSubmit">
                                <div class="login-wrap" style="margin-top: 15px">
                                    <b-form-input ref="password_input"  type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" placeholder="Password" style="margin-bottom: 10px;" @keydown.enter.native="handleSubmit"></b-form-input>
                                    <div v-show="submitted && !password" class="invalid-feedback">Campo obbligatorio</div>
                    
                                    <b-form-input type="password" v-model="confirm_password" name="confirm_password" class="form-control" :class="{ 'is-invalid': submitted && !confirm_password }" placeholder="Conferma password" style="margin-bottom: 10px;" @keydown.enter.native="handleSubmit"></b-form-input>
                                    <div v-show="submitted && !confirm_password" class="invalid-feedback">Campo obbligatorio</div>
                    
                                    <div class="text-center" style="margin-top: 15px; margin-bottom: 15px;">
                                        <b-button variant="outline-success" :disabled="enabled === false || loggingIn" role="submit" @click="handleSubmit">OK</b-button>
                                    </div>
                                </div>
                            </form>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col>
                            <div class="alert alert-info text-center" role="alert" v-if="alert.type === 'valid-feedback'">
                                {{ alert.message }}
                            </div>
                            <div class="alert alert-danger text-center" role="alert" v-if="alert.type === 'invalid-feedback'">
                                {{ alert.message }}
                            </div>
                        </b-col>
                    </b-row>
    

                </b-card>
                <b-row align-h="between" style="padding-left: 20px; padding-right: 20px"><a href="/login"><small>Login</small></a><div class="text-right"></div></b-row>
            </b-col>
        </b-container>

</template>

