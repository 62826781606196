<script>

import { mapActions, mapState/*, mapGetters*/ } from 'vuex';

import elements from '@/vue-model-mirror/bnvform/components';

export default {
    name: 'appuntamento-assignments',
    props: [ 'item' ],
    components : {},
    computed : {
//      ...mapGetters( 'store', [ 'attribute' ] ),
        ...mapState('appuntamento', {
            task_overrided : state => state.task_overrided,
        }),
        task_values() {
            let values = {};
            this.item.tasks.map( x => {
                values[ x.task_label ] = x;
            });
            return values;
        },
        disabled() {
            return false;
        },
    },
    watch: {
        task_overrided( new_value ) {
            if ( !new_value ) { return; }
            let label       = 'asselement-';
            let focus_label = 'asselement-';
            if ( this.fields['asselement-appuntamento'] === true ) {
                label += 'appuntamento';
                focus_label += 'ispezione';
            } else if ( this.fields['asselement-ispezione'] === true ) {
                label += 'ispezione';
                focus_label += 'appuntamento';
            } else {
                return;
            }
            this.$refs[ focus_label ].$refs.input.focus();
            this.$refs[ label ].is_valid = true;
            setTimeout( () => {
                this.$refs[ label ].is_valid = null;
                this.fields[ label ] = false;
            }, 500 );
        }
    },
    methods : {
        ...mapActions( 'task', { update_task: 'update_appuntamento' } ),
        set_value( payload ) {
            let task_name = Object.keys( payload )[0];
            if ( task_name === 'appuntamento' ) { this.fields['asselement-appuntamento'] = true; }
            else if ( task_name === 'ispezione' ) { this.fields['asselement-ispezione'] = true; }
            if ( payload[ task_name ] === this.task_values[ task_name ].user_id ) { return; }
            this.update_task( { payload: { user_id: payload[ task_name ] }, id: this.task_values[ task_name ].id, include: true } );
        },
    },
    created() {},
    data() {
        return {
            fields    : { 'asselement-appuntamento': false, 'asselement-ispezione': false },
            bnvselect : elements.bnvselect,
        }
    },
}

</script>

<template>
    <b-row align-v="center">
        <b-col cols="12">
            <component
                v-bind:key    = "'ass-element-appuntamento'"
                name          = "appuntamento"
                ref           = "asselement-appuntamento"
                :is           = "bnvselect"
                elementtype   = "select"
                :value        = "task_values['appuntamento'].user_id"
                label         = "Appuntamento"
                store         = "user"
                :allownull    = "true"
                :edit         = "true"
                :disabled     = "disabled"
                :display_label = "false"
                size          = "sm"
                @change       = "set_value"
                >
                <template #append>
                    <b-button variant="outline-secondary" style="cursor: default;" size="sm">
                         <component
                             name       = "geo"
                             style      = "margin-right: 3px;"
                             variant    = "light"
                             class      = "bg-secondary rounded-circle"
                             is         = "b-icon-geo"
                             scale      = "0.6"
                             font-scale = "1.5"
                         ></component><br/>
                    </b-button>
                </template>
            </component>
            <!-- <div v-if="task_values[ task_name ].confirm" class="invalid-feedback">
                {{ task_values[ task_name ].confirm }}
            </div> -->
            <component
                v-bind:key    = "'ass-element-ispezione'"
                name          = "ispezione"
                ref           = "asselement-ispezione"
                :is           = "bnvselect"
                elementtype   = "select"
                :value        = "task_values['ispezione'].user_id"
                label         = "Ispezione"
                store         = "user"
                :allownull    = "true"
                :edit         = "true"
                :disabled     = "disabled"
                size          = "sm"
                :display_label = "false"
                @change       = "set_value"
                >
                <template #append>
                    <b-button variant="outline-secondary" style="cursor: default;" size="sm">
                         <component
                             name       = "eye"
                             style      = "margin-right: 3px;"
                             variant    = "light"
                             class      = "bg-secondary rounded-circle"
                             is         = "b-icon-eye"
                             scale      = "0.6"
                             font-scale = "1.5"
                         ></component><br/>
                    </b-button>
                </template>
            </component>
            <!-- <div v-if="task_values[ task_name ].confirm" class="invalid-feedback">
                {{ task_values[ task_name ].confirm }}
            </div> -->
        </b-col>
    </b-row>
</template>

<style>
</style>
