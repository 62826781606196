<script>

export default {

    name: 'pra-agenzia',
    props: [ 'item', 'name' ],
    computed: {
        agenzia() {
            return this.item.pregs && this.item.pregs.agenzia ? this.item.pregs.agenzia : null
        },
        agenzia_label() {
            return this.agenzia ? this.agenzia.label.toUpperCase() : '';
        },
        telefono() {
            let res = null;
            if ( this.agenzia && this.agenzia.telefono.length > 0 ) {
                res = this.agenzia.telefono.map( x => {
                    //return x.label ? `${ x.label } ${ x.value }` : x.value;
                    return x.value;
                });
            }
            return res ? res.join(' / ') : null;
        },
        email() {
            let res = null;
            if ( this.agenzia && this.agenzia.email.length > 0 ) {
                res = this.agenzia.email.map( x => {
                    //return x.label ? `${ x.label } ${ x.value }` : x.value;
                    return x.value;
                });
            }
            return res ? res.join(' / ') : null;
        },
        indirizzo() {
            return this.agenzia ? this.agenzia.indirizzo : '';
        },
    },

}

</script>

<template>

    <div>
        <p style="line-height: 16px; margin-bottom: 2px;"><small style="font-weight: 600;">{{ agenzia_label }}</small><br>
            <span v-if="telefono"><small>
                <span v-if="name" style="font-weight: 600;">telefono: </span>{{ telefono }}<br/>
            </small></span>
            <span v-if="email"><small>
                <span v-if="name" style="font-weight: 600;">email: </span>{{ email }}<br/>
            </small></span>
            <span v-if="indirizzo"><small>
                <span v-if="name" style="font-weight: 600;">indirizzo: </span>{{ indirizzo }}<br/>
            </small></span>
            <!--
            <table :style="{ 'width': name ? '100%' : 'auto' }">
                <tr v-if="telefono">
                    <td><small style="font-weight: 600;">cellulare:</small></td>
                    <td><small style="padding-left: 4px;">{{ telefono }}</small></td>
                </tr>
                <tr v-if="email">
                    <td><small style="font-weight: 600;">email:</small></td>
                    <td><small style="padding-left: 4px;">{{ email }}</small></td>
                </tr>
                <tr>
                    <td><small style="font-weight: 600;">indirizzo:</small></td>
                    <td><small style="padding-left: 4px;">{{ indirizzo }}</small></td>
                </tr>
            </table>
            -->
        </p>
    </div>

</template>

