<script>

//import { mapActions, mapState } from 'vuex';

const { listpage } = require('@/vue-model-mirror');
const options      = require('@/listconfig');
const schemas      = require('@/schemaconfig');

export default {
    name: 'diary',
    extends: listpage, 
    props: [ 'codice_pratica' ],
    components: {
    },
    computed: {
        show_un: {
            get() {
                return this.$store.state.diary.show_un;
            },
            set( val ) {
                this.$store.dispatch('diary/set_show_un', val);
            },
        },
        obj () {
            return this.$store.state.pratica.obj;
        },
        qry_options () {
            var options = {};
            if ( this.options.hasOwnProperty('include') ) { options.include = this.options.include; }
            if ( this.options.hasOwnProperty('initialfilter') ) {
                Object.keys( this.options.initialfilter ).map( x => { options[ x ] = this.options.initialfilter[ x ]; } );
                this.options.initialfilter.codice_pratica = this.codice_pratica;
            }
            return options;
        },
        user () {
            return JSON.parse( localStorage.getItem('user') );
        },
        diary_created () {
            return this.$store.state.diary.created;
        },
    },
    created() {
        this.schema_name = 'diary';
        this.diary_schema = schemas[ this.schema_name ];
        this.$store.dispatch('diary/force_reload');
        this.$store.dispatch('pratica/get_tasks', this.codice_pratica);
    },
    watch: {
        diary_created(new_val, old_val) { // riportare sul parent
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch( 'diary/created' );
                this.toggle_modal();
            }
        },
    },
    methods: {
        reload_annotations( params = {} ) {
            var conf = this.qry_options;
            Object.keys( params ).map( x => { conf[ x ] = params[ x ] } );
            if ( this.paginate === true ) {
                conf.paginate = true;
            }
            this.$store.dispatch('diary/get_all', { options: conf });
        },
        new_element() {
            this.$store.dispatch('diary/executed');
            this.toggle_modal();
        },
        toggle_modal(modal_id = 'modal-new-diary') {
            modal_id = modal_id.constructor.name === 'MouseEvent' ? 'modal-filter' : modal_id;
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        add_form(form_data) {
            var options            = {};
            options.color          = form_data.color ? form_data.color : null;
            options.task_label     = form_data.task_label ? form_data.task_label : null;
            options.operator_id    = this.user.id;
            options.operator_label = `${ this.user.first_name } ${ this.user.last_name }`;
            var data         = {};
            data.action      = form_data.action;
            data.description = form_data.description;
            data.options     = options;

            data.codice_pratica = this.codice_pratica;
            data.user_id        = this.user.id;
            data.type           = 1;
            data.code           = 144;
            this.$store.dispatch('diary/create', data);
        },
        submit_add_form() {
            this.$refs.add_form.submit_form();
        },
        show_un_changed( val ) {
            //console.log( val );
            //this.$store.dispatch('diary/set_show_un', !this.show_un);
            //this.show_un = !this.show_un;
            this.$refs.listbnv.page = 1;
        },
    },
    data () {
        return {
            modal_add_key : 0,
            diary_schema  : null,
          //show_un       : false,
        };
    },
};

</script>

<template>

    <b-container v-if="obj" style="margin-top: 20px; margin-bottom: 100px" fluid>
        <b-row style="margin-bottom: 10px; margin-left: 5px;">
            <b-form-checkbox
                inline 
                switch
                @change   = "show_un_changed()"
                id        = "show_un"
                v-model   = "show_un"
                >
                Note manuali
            </b-form-checkbox>
            <b-col v-if="add" align-self="end" class="text-right">
                <b-button @click="new_element();" size="sm" variant="outline-info"><icon name="plus"/> Voce Manuale</b-button>
            </b-col>
        </b-row>
        <bnvlist
            :schema          = "schema_name"
            storename        = "bnvform"
            :skip            = "skip"
            :fields          = "fields"
            :primarykey      = "primary_key"
            :qryoptions      = "qry_options"
            :details         = "details"
            :detailsclass    = "details_class"
            :detailsaction   = "details_action"
            :edit            = "edit"
            :editaction      = "edit_action"
            :paginate        = "paginate"
            :link            = "link"
            :rowclass        = "rowclass"
            :linkfunction    = "linkfunction"
            :initialfilter   = "initialfilter"
            ref              = "listbnv"
            size             = "sm"
            :formpath        = "item_form"
            @row_selected    = "row_selected"
            @right-click     = "right_click"
        ></bnvlist>

        <!-- modal filtri -->
        <b-modal
            id="modal-new-diary"
            title="Nuova voce del Diario"
            button-size="lg"
            size="lg"
            >
            <bnvform
                :key       = "modal_add_key"
                name       = "diary"
                :schema    = "diary_schema"
                :options   = "{}"
                :record    = "{}"
                :submit    = "false"
                :inline    = "true"
                ref        = "add_form"
                :focus     = "true"
                @submitted = "add_form"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_add_form">
                      OK
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="warning" @click="hide('clear')">
                      Reset
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

    </b-container>

    <div v-else-if="!obj">
        <div class="text-center">
            <div class="spinner-grow text-dark" role="status" style="width: 6rem; height: 6rem;">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>

</template>

