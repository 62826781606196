////////////////////

import base_module      from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'email_template',
    send_alert    : false,
    default_order : 'type ASC|label ASC',
};

var base = new base_module( options );

const getters = {
    ...base.getters,
    email_body: ( state ) => ( template_id ) => {
        if ( state.items === null || state.items.length === 0 ) { return null; }
        var res = state.items.filter( x => x.id === parseInt( template_id ) );
        return res.length > 0 ? res[0].body : null;
    },
};

const actions = {
    ...base.actions,
    get_all( { commit, state, getters, rootGetters }, _data = { options: {} } ) { // ok
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        commit('start_request');
        data.options.order = getters.order;
        //Object.keys( state.filters ).map( key => { data.options[ key ] = state.filters[ key ]; } );
        var customers = rootGetters['statemanager/customers'].map( x => x.id );
        console.log( { customers } );
        data.options.customer_id__in = customers.join(',');
        state.service.get_all(getters.model_name, data.options)
            .then( response => {
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
}

export default {
    namespaced : true,
    state: base.state,
    getters,
    actions,
    mutations: base.mutations,
};

