//////////////////////////////

module.exports = (type) => {
    var schema = {
        file: {
            type: type.VIRTUAL,
            file: true,
            allowNull: false,
            max_size: 6000000,
            filter: 'images'
        },
        option: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'ispezione_doc_descriptions',
                key         : 'id',
                constraints : true,
                store_name  : 'ispezione_doc_description',
            }
        },
        description: {
             type: type.TEXT,
             allowNull: false,
        },
        task_id: {
            type: type.VIRTUAL,
            allowNull: false,
            hidden: true,
        }
    };

    return schema;
}


