

module.exports = {
    cols: [
        { 
            row1: {
                field1: 'nome',
                field2: 'cognome',
            },
            row3: {
                field3: 'titolo',
                field4: 'denominazione',
            },
            row4: {
                field5: 'codice_fiscale',
                field6: 'partita_iva',
            },
            row5: {
                field5: 'iban',
                field6: null,
            },
        },
    ]
};

