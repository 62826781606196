//////////////////////////////

module.exports = (type) => {
    var schema = {
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        newstate_key: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'newstates',
                key         : 'key',
                constraints : true,
                store_name  : 'newstate',
            },
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}


