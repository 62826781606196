<script>

//const link = 'https://app.perizieincloud.it/form/reminder/customer/17?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzEyODQ0ODAsImV4cCI6MTYzMTg4OTI4MH0.74ADmGoKOqhLUosLjb5mx3P5nIgmICisUrUoj7gbV9M';
//const link = 'http://192.168.3.164:8080/form/reminder/customer/17?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzEyODQ0ODAsImV4cCI6MTYzMTg4OTI4MH0.74ADmGoKOqhLUosLjb5mx3P5nIgmICisUrUoj7gbV9M';

const nprogress = require('nprogress');

export default {
    name: 'reminder-customer-page',
    props: [],
    mounted () {
        this.$nextTick(function () { nprogress.done(true); }) // stop loader
    },
    watch: {
        error_auth( new_value, old_value ) {
            if ( old_value === null && new_value ) {
                this.loading = false;
                this.error = new_value;
            }
        },
        obj( new_value, old_value ) {
            if ( Object.keys( old_value ).length === 0 && typeof new_value === 'object' ) {
                setTimeout( () => {
                    this.loading = false;
                }, 2000);
            }
        }
    },
    computed: {
        jwt() {
            return this.$route.query.jwt || null;
        },
        id() {
            return this.$route.params.id;
        },
        error_auth() { return this.$store.state.reminder.error_auth; },
        obj() { return this.$store.state.reminder.obj; },
        subject() {
            if ( !this.obj ) {
                return null;
            }
            return this.obj.subject;
        },
        title() {
            if ( !this.subject ) { return []; }
            var tokens = this.subject.split('(ns.');
            tokens[1] = '(ns' + tokens[1];
            return tokens;
        },
        customer() {
            if ( !this.obj ) {
                return null;
            }
            return this.obj.customer;
        },
    },
    methods: {
        send_data( index ) {
            var doc = this.obj.docs.filter( x => x.index === index )[0];
            if ( doc.loading === true ) { return; }
            var text = this.$refs[ 'textarea_' + index ][0].localValue;
            //var file = this.$refs[ 'file_' + index ][0].selectedFile;
            var files = this.files[ index ] || [];
            var error = this.has_error( text, files );
            if ( error ) {
                this.$store.dispatch( 'reminder/set_message', { index, message: { error: true, text: 'ERRORE: ' + error } } );
                return;
            }
            this.$store.dispatch( 'reminder/send_data', { index, text, files, label: doc.label } );
        },
        has_error( text, file ) {
            if ( text.length === 0 && ( !file || file === [] ) ) {
                return 'compliare almeno uno dei due campi.';
            }
            return null;
        },
        file_selected( value, ref ) { 
            if ( !this.files[ ref ] ) { this.files[ ref ] = []; }
            if ( value.length > 0 ) {
                this.files[ ref ] = this.files[ ref ].concat( value );
            }
            this.$refs[ 'file_' + ref ][0].reset();
            this.keys += 1;
        },
        get_files( id ) {
            return this.files[ id ] || [];
        },
        remove_file( id, ref ) {
            this.files[ ref ].splice(id, 1);
            this.keys += 1;
        }
    },
    created () {
        if ( !this.jwt || !this.id ) {
            this.error = 'jwt or id missing';
            return;
        }
        this.$store.dispatch('reminder/validate_jwt', { jwt: this.jwt, id: this.id });
    },
    data () {
        return {
            loading  : true,
            error    : null,
            files    : {},
            keys     : 0,
        };
    },
};

</script>

<template>

    <div v-if="loading">
        caricamento...
    </div>
    <div v-else-if="!loading">
        <div v-if="error">
            riscontrato errore: {{ error }}
        </div>
        <div v-else-if="obj">
            <h2 class="text-center" style="margin-bottom: 15px;">{{ customer }}</h2>
            <h4 style="text-align: center; margin-bottom: 30px; padding: 3px;"><span style="background-color: white">&nbsp;{{ title[0] }}&nbsp;<br/>&nbsp;{{ title[1] }}&nbsp;</span></h4>
            <b-card
                v-for = "doc in obj.docs.filter( x => x.deleted !== true )"
                style = "margin-top: 15px; margin-left: 20px; margin-right: 20px;"
                :key  = "'card_' + doc.index"
                :ref  = "'card_' + doc.index"
            >
                <b-card-title>
                    <p style="font-weight: 700; font-size: 1.2rem">{{ doc.label }}</p>
                </b-card-title>
                <b-row>
                    <b-col sm="12" md="6" lg="8">
                        <label :for="'textarea_' + doc.index">Dati in formato testo</label>
                        <b-form-textarea
                            :ref="'textarea_' + doc.index"
                            :id="'textarea_' + doc.index"
                            :disabled="doc.loading === true || doc.delivered === true"
                            placeholder="Digiti qui le informazioni"
                            rows="6"
                            max-rows="6"
                            style="margin-bottom: 10px;"
                        ></b-form-textarea>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <label :for="'file_' + doc.index">Allega un file</label>
                        <b-form-file
                            :ref             = "'file_' + doc.index"
                            :id              = "'file_' + doc.index"
                            :disabled        = "doc.loading === true || doc.delivered === true"
                            placeholder      = "Scegli un file oppure trascinalo qui..."
                            drop-placeholder = "Trascina un file qui..."
                            @input           = "file_selected( $event, doc.index )"
                            :multiple        = "true"
                        ></b-form-file>
                        <div :key="keys" style="margin-top: 15px;">
                            <b-list-group>
                                <b-list-group-item v-for="( file, index ) in get_files( doc.index )" style="padding: 6px 15px" :key="doc.index + '_' + index">
                                    <span>{{ file.name }}</span><icon style="float: right; color: red; cursor: pointer" @click="remove_file( index, doc.index )" name="times-circle"/>
                                </b-list-group-item>
                            </b-list-group>
                        </div>
                    </b-col>
                </b-row>
                <b-card-footer style="background-color: white; margin-top: 20px; border: none" class="text-right">
                    <b-row>
                        <b-col sm="12" md="9" lg="10">
                            <b-alert v-if="doc.message" :variant="doc.message.error ? 'danger' : 'warning'" style="font-size: 0.9rem; text-align: left; padding: .25rem .5rem" show>{{ doc.message.text }}</b-alert>
                            <b-alert v-if="doc.delivered" variant="success" style="font-size: 0.9rem; text-align: left; padding: .25rem .5rem" show>Dati inviati</b-alert>
                        </b-col>
                        <b-col sm="12" md="3" lg="2">
                            <b-button size="sm" variant="outline-success" :disabled="doc.delivered === true" @click="send_data( doc.index )">
                                <b-spinner v-if="doc.loading === true" small type="grow"></b-spinner>
                                Invia i dati
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-footer>
            </b-card>
        </div>
    </div>

</template>


