<script>

const nprogress = require('nprogress');

const config = require('@/config.js')[ process.env.NODE_ENV || 'development' ]
const packagejson = require('@/../package.json');

export default {
    data () {
        return {
            username    : '',
            password    : '',
            submitted   : false,
            email       : '',
            version     : packagejson.version,
            reset_email : '',
            modal_open  : false,
            ok_disabled : false,
        }
    },
    mounted () {
        this.$nextTick(function () {
            // Code that will run only after the
            // entire view has been rendered
            nprogress.done(true);
        })
    },
    watch: {
        request_sent( new_value, old_value ) {
            if ( new_value === true ) { this.toggle_modal(); }
        },
        modal_open( new_value, old_value ) {
            if ( new_value === true ) { setTimeout( () => this.$refs.reset_email_input.focus(), 400 ); }
            if ( new_value === false ) { this.ok_disabled = false; }
        }
    },
    computed: {
        loggingIn () {
            return this.$store.state.authentication.status.loggingIn;
        },
        alert () {
            return this.$store.state.alert;
        },
        icon_url () {
            if ( config.gui.hasOwnProperty('icon') && config.gui.icon ) { return config.backend_url + `icons/${ config.gui.icon }`; }
            return undefined;
        },
        color () {
            if ( config.gui.hasOwnProperty('color') && config.gui.color ) { return config.gui.color; }
            return undefined;
        },
        app_label () {
            if ( config.gui.hasOwnProperty('app_label') && config.gui.app_label ) { return config.gui.app_label; }
            return undefined;
        },
        request_sent () {
            return this.$store.state.authentication.request_sent;
        },
    },
    created () {
        // reset login status
        this.$store.dispatch('authentication/logout');
    },
    methods: {
        handleSubmit (e) {
            this.submitted = true;
            const { username, password } = this;
            const { dispatch } = this.$store;
            if (username && password) {
                dispatch('authentication/login', { username, password });
            }
        },
        reset_password( email ) {
            this.ok_disabled = true;
            this.$store.dispatch('authentication/reset_password', { email });
        },
        toggle_modal() {
            this.$root.$emit('bv::toggle::modal', 'modal-reset');
            this.modal_open = !this.modal_open;
        },
    },
    components: {
    }
};

</script>

<template>
 
        <b-container>
            <b-col sm="10" md="7" lg="4" style="margin: auto;">
                <b-card
                    v-bind:style="color ? 'border: 2px solid ' + color : ''"
                    style="padding-top: 20px; padding-left: 20px; padding-right: 20px; padding-bottom: 0px;"
                    >
                    <b-row>
                        <b-col>
                            <div v-if="icon_url" class="text-center">
                                <b-img :src="icon_url" thumbnail fluid rounded="circle" width="120" alt="customer logo"></b-img>
                            </div>
                            <h4
                                class="text-center"
                                style="font-weight: 700; margin-bottom: 30px; margin-top: 10px;"
                                v-bind:style="color ? 'color: ' + color : ''"
                                >
                                {{ app_label }}
                            </h4>
                            <form class="form-signin" @submit.prevent="handleSubmit">
                                <div class="login-wrap" style="margin-top: 15px">
                                    <b-form-input type="text" v-model="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && !username }" placeholder="Email" style="margin-bottom: 10px;" @keydown.enter.native="handleSubmit" autofocus></b-form-input>
                                    <div v-show="submitted && !username" class="invalid-feedback">Email is required</div>
                    
                                    <b-form-input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" placeholder="Password" style="margin-bottom: 10px;" @keydown.enter.native="handleSubmit"></b-form-input>
                                    <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
                    
                                    <label class="checkbox">
                                        <input type="checkbox" value="remember-me"> Remember me
                                    </label>
                    
                                    <div class="text-center" style="margin-top: 15px; margin-bottom: 15px;">
                                        <b-button variant="outline-success" :disabled="loggingIn" role="submit" @click="handleSubmit">Sign in</b-button>
                                    </div>
                                </div>
                            </form>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col>
                            <div class="alert alert-info text-center" role="alert" v-if="!modal_open && alert.type === 'valid-feedback'">
                                {{ alert.message }}
                            </div>
                            <div class="alert alert-danger text-center" role="alert" v-if="!modal_open && alert.type === 'invalid-feedback'">
                                {{ alert.message }}
                            </div>
                        </b-col>
                    </b-row>
    
                    <!-- Modal -->
                    <b-modal
                        id="modal-reset"
                        title="Reset della password"
                        button-size="sm"
                        >
                        <div class="modal-body">
                            <p>Inserisci il tuo indirizzo email per resettare la password.</p>
                            <div class="form-group">
                                <input v-model="reset_email" ref="reset_email_input" type="text" name="email" placeholder="Email" autocomplete="off" class="form-control placeholder-no-fix">
                                <div style="margin-top: 10px; padding: 4px" class="alert alert-info" role="alert" v-if="alert.type === 'valid-feedback'">
                                    <small>{{ alert.message }}</small>
                                </div>
                                <div style="margin-top: 10px; padding: 4px" class="alert alert-danger" role="alert" v-if="alert.type === 'invalid-feedback'">
                                    <small>{{ alert.message }}</small>
                                </div>
                            </div>
                        </div>
                        <template v-slot:modal-footer="{ ok, cancel, hide }">
                            <b-row><b-col>
                                <b-button :disabled="ok_disabled" size="sm" variant="success" @click="reset_password( reset_email )">
                                  OK
                                </b-button>
                            </b-col><b-col>
                                <b-button size="sm" variant="outline-secondary" @click="hide('close')">
                                  Chiudi
                                </b-button>
                            </b-col></b-row>
                        </template>
                    </b-modal>
                    <!-- modal -->

                </b-card>
                <b-row align-h="between" style="padding-left: 20px; padding-right: 20px"><a @click="toggle_modal" href="#"><small>Hai dimenticato la password?</small></a><div class="text-right"><small><em>vers. {{version}}</em></small></div></b-row>
            </b-col>
        </b-container>

</template>

