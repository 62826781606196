<script>

import { mapActions, mapState/*, mapGetters*/ } from 'vuex';
const schemas = require('@/schemaconfig');
import bnvform from '@/vue-model-mirror/bnvform';
import fabButton from '@/components/fab_button';
import Address from '@/registry/classes/address';

export default {
    name: 'pratica-subtab-incarico',
    components: { fabButton, bnvform },
    computed: {
        ...mapState('pratica', {
            pra    : state => state.obj,
            prareg : state => state.prareg,
        }),
        ...mapState('preg', {
            incarico_updated : state => state.incarico_updated,
        }),
        record() {
            return this.prareg.pregs.incarico;
        },
        loaded_sinistro() { return this.$store.state.comune_residenza.loaded_sinistro; },
        loaded_appuntamento() { return this.$store.state.comune_residenza.loaded_appuntamento; },
    },
    watch: {
        incarico_updated( new_value ) { 
            if ( !new_value ) { return; }
            this.form_class = 'incarico-updated';
            this.$store.dispatch('pratica/force_reload');
            setTimeout( () => { this.form_class = null; }, 2500 );
        },
        loaded_sinistro( new_value ) {
            if ( new_value === false ) { return; }
            this.luogo_sinistro.comune_id = this.$store.state.comune_residenza.obj.id;
        },
        loaded_appuntamento( new_value ) {
            if ( new_value === false ) { return; }
            this.luogo_appuntamento.comune_id = this.$store.state.comune_residenza.obj.id;
        },
    },
    methods: {
        ...mapActions( 'preg', { update: 'update_incarico' } ), // in pratica this.update richiamera' update_incarico() di preg.models
       async save() {
//          try {
            var data = this.$refs.incarico_form.get_form_data();
            data.strada_luogo_sinistro       = this.luogo_sinistro.strada || null;
            data.comune_luogo_sinistro_id    = this.luogo_sinistro.comune_id || null;
            data.n_civ_luogo_sinistro        = this.luogo_sinistro.n_civ || null;
            data.cap_luogo_sinistro          = this.luogo_sinistro.cap || null;
            data.nazione_luogo_sinistro      = this.luogo_sinistro.nazione || null;
            data.latitudine_luogo_sinistro   = this.luogo_sinistro.latitudine || null;
            data.longitudine_luogo_sinistro  = this.luogo_sinistro.longitudine || null;
            data.citta_estero_luogo_sinistro = this.luogo_sinistro.citta_estero || null;
            data.altro_luogo_sinistro        = this.luogo_sinistro.altro || null;
            data.strada_luogo_appuntamento       = this.luogo_appuntamento.strada || null;
            data.comune_luogo_appuntamento_id    = this.luogo_appuntamento.comune_id || null;
            data.n_civ_luogo_appuntamento        = this.luogo_appuntamento.n_civ || null;
            data.cap_luogo_appuntamento          = this.luogo_appuntamento.cap || null;
            data.nazione_luogo_appuntamento      = this.luogo_appuntamento.nazione || null;
            data.latitudine_luogo_appuntamento   = this.luogo_appuntamento.latitudine || null;
            data.longitudine_luogo_appuntamento  = this.luogo_appuntamento.longitudine || null;
            data.citta_estero_luogo_appuntamento = this.luogo_appuntamento.citta_estero || null;
            data.altro_luogo_appuntamento        = this.luogo_appuntamento.altro || null;
            
            let done = await this.update( { id: this.record.id, data } );

            
            if (done) {
                // Verifico se la pratica e' stata chiusa per chiudere eventuali solleciti aperti
                if (this.current_state != data.state && data.state === 4000) {
                    let codicePratica = this.record.codice_pratica == undefined ? this.record.codice :  this.record.codice_pratica;

                    if ( codicePratica == undefined) {
                        console.log(" ******** WARNING ******** ");
                        console.log(this.record);
                    }
                    
                        let deactivated = await this.$store.dispatch('reminder/reminder_deactivation', codicePratica);
                        
                        this.$bvToast.toast( 'Pratica chiusa. Eventuali solleciti sono stati disattivati', {
                            title         : 'SALVATAGGIO DATI',
                            autoHideDelay : 6000,
                            appendToast   : true,
                        });
                } else {
                    this.$bvToast.toast( 'Dati aggiornati correttamente', {
                        title         : 'SALVATAGGIO DATI',
                        autoHideDelay : 6000,
                        appendToast   : true,
                    });
                }
                
            } else {
                this.$bvToast.toast(  'Errore durante l\'aggiornamento dei dati', {
                    title         : 'SALVATAGGIO DATI',
                    autoHideDelay : 6000,
                    appendToast   : true,
                });
            }
            
            
        },
        async value_changed( payload ) {
            if ( payload.hasOwnProperty('importo_richiesto') || payload.hasOwnProperty('importo_liquidato') ) {
                let form = this.$refs.incarico_form;
                let importo_richiesto = parseFloat( form.get_element_value('importo_richiesto') );
                let importo_liquidato = parseFloat( form.get_element_value('importo_liquidato') );
                let saving = null;
                if ( !isNaN( importo_richiesto ) && !isNaN( importo_liquidato ) ) {
                    saving = parseFloat( importo_richiesto - importo_liquidato ).toFixed(2);
                }
                this.$refs.incarico_form.set_payload( { saving }, true );
            } else if ( payload.name === 'luogo_sinistro' ) {
                this.luogo_sinistro.set_google_payload( payload );
            } else if ( payload.name === 'luogo_appuntamento' ) {
                this.luogo_appuntamento.set_google_payload( payload );
            }
        },
        ad_button( data ) {
            this.field_name  = data.name;
            if ( data.name === 'luogo_sinistro' ) {
                this.ad_payload = this.luogo_sinistro;
            } else if ( data.name === 'luogo_appuntamento' ) {
                this.ad_payload = this.luogo_appuntamento;
            }
            this.toggle_modal('modal-address-data'); 
        },
        toggle_modal(modal_id = 'modal-address-data') {
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        save_ad( payload ) {
            this[ this.field_name ].fields.map( field => {
                this[ this.field_name ][ field ] = payload[ field ] || null;
            });
            console.log( 'address label', this[ this.field_name ].label );
            this.$refs.incarico_form.set_payload( { [ this.field_name ]: this[ this.field_name ].label } );
            this.toggle_modal(); 
            //this.form_key += 1;
        },
        submit_ad_form() {
            this.$refs.modal_ad.submit_form();
        },
    },
    created() {
        // memorizzo lo stato della pratica per i controlli in fase di salvataggio
        this.current_state = this.record.state;
        
        this.luogo_sinistro.store     = this.$store;
        this.luogo_appuntamento.store = this.$store;
        this.luogo_sinistro.set_values( this.record );
        this.luogo_appuntamento.set_values( this.record );
    },
    data () {
        return {
            form_key                : 0,
            pratica_incarico_schema : schemas.pratica_incarico,
            form_class              : null,
            current_state           : null,
            luogo_sinistro          : new Address('luogo_sinistro'),
            luogo_appuntamento      : new Address('luogo_appuntamento'),
            ad_schema               : schemas._address_data,
            ad_payload              : null,
            field_name              : null,
        }
    },
};

</script>

<template>

    <div>

        <div style="margin-bottom: 100px">
            <div class="incarico-card" :class="form_class">
                <bnvform
                    :key       = "form_key"
                    ref        = "incarico_form"
                    name       = "pratica_incarico"
                    :schema    = "pratica_incarico_schema"
                    :options   = "{}"
                    :record    = "record"
                    :inline    = "true"
                    :focus     = "true"
                    :submit    = "false"
                    @change    = "value_changed"
                    @ad-button = "ad_button"
                    @submitted = "save"
                />
            </div>
        </div>

        <!-- modal Address Data -->
        <b-modal
            id          = "modal-address-data"
            title       = "Indirizzo"
            button-size = "sm"
            >
            <bnvform
                name       = "_address_data"
                :schema    = "ad_schema"
                :options   = "{}"
                :record    = "ad_payload"
                :submit    = "false"
                :inline    = "true"
                ref        = "modal_ad"
                layout     = "single"
                @submitted = "save_ad"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_ad_form">
                      Salva
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <fab-button
            main_icon = "save"
            bg_color  = "#218838"
            @save     = "save"
        />

    </div>

</template>

<style>

    .incarico-card {
        padding: 3px;
        border: 1px solid white;
        transition: all 0.5s;
    }
    .incarico-updated {
        border-color: #218838;
    }

</style>

