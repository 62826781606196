<script>

export default {
    name: 'bnvbase',
    props: [
        'elementtype','name', 'value', 'label', 'allownull', 'errormessage', 'recordid', 'disabled', 'creator',
        'readonly', 'edit', 'size', 'helper', 'search', 'minlength', 'maxlength', 'precision', 'decimal', 'filter', 'config', 'maxvalue', 
        'layout', 'fields_per_row', 'display_error', 'display_label', 
    ],
    components: {},
    computed: {
        _readonly () {
            if ( this.readonly === true ) { return true; }
            if ( this.readonly === 'edit' && this.edit === true ) { return true; }
            if ( this.readonly === 'add' && this.edit === false ) { return true; }
            return false;
        },
        _helper () {
            if ( this.helper ) {
                return this.helper.replace(/\\n/g, '<br/>');
            }
        },
        validators() {
            if ( this.hasOwnProperty('creator') ) {
                return this.creator.schema.validators || []
            } else {
                return [];
            }
        },
        // element layout
        input_class () {
            if ( this.size === 'sm' ) { return 'form-control-sm'; }
            if ( this.size === 'lg' ) { return 'form-control-lg'; }
            return '';
        },
        field_class() {
            return ( this.layout === 'condensed' ) ? 'row' : '';
        },
        field_style() {
            return ( this.layout === 'condensed' ) ? { 'margin-bottom': '0.1rem' } : { 'margin-bottom': '0.4rem' };
        },
        _fields_per_row() {
            return this.fields_per_row ? parseInt( this.fields_per_row ) : 1;
        },
        label_class () {
            var _class = 'bnv-label '
            if ( this.layout === 'condensed' ) { _class += this._fields_per_row === 1 ? "col-sm-1" : "col-sm-2"; }
            _class += ' col-form-label';
            if ( this.size === 'sm' ) { _class += ' col-form-label-sm'; }
            if ( this.size === 'lg' ) { _class += ' col-form-label-lg'; }
            if ( this.elements_row ) { /*console.log( _class, this.elements_row );*/ } 
            return _class;
        },
        element_container_class() {
            if ( this.layout !== 'condensed' ) { return ''; }
            return this._fields_per_row === 1 ? 'col-sm-11' : 'col-sm-10';
        }
    },
    data () {
        return {
            model           : null,
            error_message   : null,
            error_component : false,
            confirm_message : null,
        }
    },
    created () {
        //this.or_model = typeof this.value === 'undefined' || this.value === null ? '' : this.value + '';
        this.model = typeof this.value === 'undefined' || this.value === null ? '' : this.value;
    },
    methods: {
        get_label: function() {
            return typeof this.label === 'undefined' ? this.name : this.label;
        },
        get_placeholder: function() {
            return typeof this.placeholder === 'undefined' ? undefined : this.placeholder;
        },
        set_value: function (value, force = false) { // public, attributo force aggiunto il 20210907 - usato da sollecito / form
            if ( !this.model || force === true ) {
                this.model = value;
            }
        },
        get_value: function() { // public
            this.error_component = false;
            this.error_message = null;
            var check = this._validate();
            if ( check === false ) { return undefined; }
            var value = this._return_model();
            if ( value === null ) { return null; }
            if ( this.search ) {
                var res = { value, search: null, };
                if ( this.search === 'contains_before_after' ) { res.search = value ? `|${ value }|` : null; }
                return res;
            }
            return value;
        },
        set_error( error ) {
            this.error_component = true;
            this.error_message = error;
        },
        reset_error() {
            this.error_component = false;
            this.error_message = null;
        },
        _return_model() {
            return this.model ? this.model : null;
        },
        reseta() { // public - experimental 14/03/20
            this.model = '';
        },
        _validate: function() {
            var check = this._validate_allow_null();
            if ( check && this.validators.length > 0 ) {
                for ( var i = 0; i < this.validators.length; i++ ) {
                    try {
                        this.validators[ i ]( this );
                    } catch ( error ) {
                        this.error_message = error;
                        this.error_component = true;
                        return false;
                    }
                }
            }
            return check;
        },
        _validate_allow_null: function() {
            if ( this.allownull === false ) {
                if ( this.model === '' || typeof this.model === 'undefined' || this.model === null ) {
                    this.error_message = 'campo obbligatorio';
                    this.error_component = true;
                    return false;
                }
            }
            return true;
        },
        get_error_message() {
            if ( typeof this.errormessage !== 'undefined' ) {
                this.error_message = this.errormessage;
                this.error_component = true;
            }
            return this.error_message;
        },
        set_confirm_message(text) {
            this.confirm_message = text;
            setTimeout( () => {
                this.confirm_message = null;
            }, 3000)
        },
        value_changed() {
            var payload = {};
            payload[ this.name ] = this.model;
            this.$emit('change', payload );
        },
        enter_key() {
            this.$emit('enter_key');
        },
//      blur() {
//          if ( this.or_model === this.model ) { return; }
//          this.$emit( 'generic-event', { event_name: 'changed-data', payload: { name: this.name, value: this.model } } );
//      }
    },
};

</script>

<template>
    <div></div>
</template>


<style>
    .form-group-condensed {
        margin-bottom: 0rem;
    }
    .bnv-label {
        white-space: nowrap;
    }
</style>
