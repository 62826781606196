

module.exports = {
    cols: [
          { 
              row1: {
                  field1: 'nome',
              },
              row2: {
                  field1: 'cognome',
              },
              row3: {
                  field1: 'denominazione',
              },
              row4: {
                  field1: 'indirizzo',
              },
              row5: {
                  field1: 'codice_fiscale',
              },
              row6: {
                  field1: 'partita_iva',
              },
              row7: {
                  field1: 'telefono',
              },
              row8: {
                  field1: 'email',
              },
              row9: {
                  field1: 'iban',
              },
          },
    ]
};

