//////////////////////////////

module.exports = (type) => {
    var schema = {
        nome: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        cognome: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        denominazione: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        cf: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        piva: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        telefono: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        cellulare: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        fax: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        email: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        indirizzo: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        cap: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        citta: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        note: {
            type: type.TEXT,
            allowNull: true,
            defaultValue: null,
        },
        iban: {
            type: type.STRING(40),
            allowNull: true,
            defaultValue: null,
        },
    };

    return schema;
}

