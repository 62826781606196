<script>

import bnvbase from './bnvbase.vue';
const md5 = require('md5');
const moment = require('moment');

export default {
    extends: bnvbase,
    props: [ 'store', ],
    computed: {
        options() {
            return this.$store.state[ this.store ];
        },
        index_label() {
            return this.creator.schema.references.index_label || 'id';
        },
        can_edit() {
            return ( this.creator.schema.references.hasOwnProperty('edit') && this.creator.schema.references.edit === true ) ? true : false;
        },
        validator() {
            return 'validate_new_item_' + this.creator.schema.references.new_item_type;
        },
        can_save() {
            return ( this.creator.schema.references.hasOwnProperty('save_new_value') && this.creator.schema.references.save_new_value === true ) ? true : false;
        },
        additional_attributes() {
            return ( this.creator.schema.references.hasOwnProperty('additional_attributes') && typeof this.creator.schema.references.additional_attributes === 'object' )
                   ? this.creator.schema.references.additional_attributes
                   : null;
        },
    },
    created () {
        if ( this.$store.state[ this.store ].force_reload === true || this.$store.state[ this.store ].items === null ) {
            this.$store.dispatch(this.store + '/get_all');
        }
    },
    methods: {
        _validate_allow_null: function() {
            if ( this.allownull === false ) {
                if ( !this.model === '' || this.model.length === 0 ) {
                    this.error_message = 'campo obbligatorio';
                    this.error_component = true;
                    return false;
                }
            }
            return true;
        },
        value_changed( data ) {
            if ( this.model.filter( x => x.label === data ).length === 0 ) {
                var item = { label: data };
                if ( this.additional_attributes ) {
                    var keys = Object.keys( this.additional_attributes );
                    keys.map( x => {
                        item[ x ] = this.additional_attributes[ x ];
                    });
                }
                item.index = md5( JSON.stringify( item ) + moment().format() );
                this.model.push( item );
            }
            this.temp_model = [];
        },
        remove_item( item ) {
            if ( item.read_only === true ) { return; }
            var index = this.get_item_index( item );
            if ( index > -1 ) {
                this.model.splice( index, 1 );
            }
        },
        modify_item( item ) {
            if ( item.read_only === true ) { return; }
            this.edit_item_index = this.get_item_index( item );
            this.edit_item       = item.label;
            this.toggle_modal( 'edit-item-' + this.name );
        },
        get_item_index( item ) {
            var index = -1;
            for ( var i = 0; i < this.model.length; i++ ) {
                if ( item.index === this.model[ i ].index ) { index = i; break; }
            }
            return index;
        },
        toggle_modal( modal_id = null ) {
            if ( modal_id === null ) { modal_id = 'new-item-' + this.name; }
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        clear_form() {
            this.new_item        = null;
            this.new_item_state  = null;
            this.edit_item       = null;
            this.edit_item_index = null;
            this.edit_item_state = null;
            this.save_item       = false;
        },
        save_new_item() {
            if ( this[ this.validator ]( this.new_item ) ) {
                this.value_changed( this.new_item );
                if ( this.save_item === true ) {
                    // salvataggio in tabella del nuovo item
                    console.log( 'salvo il nuovo item' );
                    this.$store.dispatch( `${ this.store }/create`, {
                        payload: {
                            label: this.new_item,
                        },
                        include : false,
                    });
                }
                this.toggle_modal();
            }
        },
        save_edit_item() {
            if ( this[ this.validator ]( this.edit_item ) ) {
                this.model[ this.edit_item_index ].label = this.edit_item + '';
                this.toggle_modal( 'edit-item-' + this.name );
            }
        },
        validate_new_item_email( data ) {
            if ( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test( data ) ) {
                this.new_item_state = true;
                this.edit_item_state = true;
                return true;
            } else {
                this.new_item_state = false;
                this.edit_item_state = false;
            }
        },
        validate_new_item_text_not_null( data ) {
            if ( data && data.length > 0 ) {
                this.new_item_state = true;
                this.edit_item_state = true;
                return true;
            } else {
                this.new_item_state = false;
                this.edit_item_state = false;
            }
        },
        set_focus( type ) {
            if ( type === 'new' ) {
                this.$refs.new_element.focus();
            } else if ( type === 'edit' ) {
                this.$refs.edit_element.focus();
            }
        },
    },
    data () {
        return {
            temp_model      : [],
            model           : [],
            new_item        : null,
            new_item_state  : null,
            edit_item       : null,
            edit_item_index : null,
            edit_item_state : null,
            save_item       : false,
        }
    },
};

</script>

<template>

    <div class="form-group">
        <label :for="'bnv-form-' + name" class="col-form-label" :class="label_class">{{ get_label() }}</label>
        <b-input-group>
        <b-select
            ref               = "input"
            class             = "form-control"
            :size             = "input_class"
            v-bind:class      = "{ 'is-invalid': error_component }"
            :aria-label       = "name"
            :aria-describedby = "name"
            :id               = "'bnv-form-' + name"
            v-model           = "temp_model"
            :disabled         = "disabled || _readonly || options.loading"
            @change           = "value_changed"
            >
            <option :value="[]">- - - - - - -</option>
            <option v-for="option in options.items" v-bind:key="option[ index_label ]" :value="option[ index_label ]">{{ option.label }}</option>
        </b-select>
        <b-input-group-append>
            <b-button variant="outline-info" @click="toggle_modal()"><icon name="plus"/></b-button>
        </b-input-group-append>
        </b-input-group>
        <small v-if="helper" :id="'bnv-form-' + name + '-help'" class="form-text text-muted" v-html="_helper"></small>
        <div class="invalid-feedback">
            {{ get_error_message() }}
        </div>
        <b-list-group>
            <b-list-group-item
                style="padding: .50rem 1rem; font-size: 0.9rem"
                v-for="item in model"
                :key="item.label"
                class="d-flex justify-content-between align-items-center"
                >
                {{ item.label }}
                <b-button-group vertical size="sm">
                 <div :style="{ 'cursor': item.delivered || item.read_only ? 'default' : 'pointer' }"  style="padding: 1px;" @click="remove_item( item )">
                     <icon :style="{ 'color': item.delivered || item.read_only ? 'lightgrey' : 'red' }" name="times-circle"/>
                 </div>
                 <div v-if="can_edit" :style="{ 'cursor': item.delivered ? 'default' : 'pointer' }" style="padding: 1px;" @click="modify_item( item )">
                     <icon :style="{ 'color': item.delivered ? 'lightgrey' : 'green' }" name="edit"/>
                 </div>
                </b-button-group>
            </b-list-group-item>
        </b-list-group>

        <!-- modal new-item -->
        <b-modal
            :id="'new-item-' + name"
            :title="'Nuova voce ' + get_label()"
            @hide="clear_form"
            button-size="sm"
            @shown="set_focus('new')"
            >
            <div>
                <b-form-input ref="new_element" :state="new_item_state" v-model="new_item" placeholder="Scrivi qui la nuova voce"></b-form-input>
                <div class="invalid-feedback">
                    nuova voce non valida
                </div>
                <div style="margin-top: 20px;">
                <b-form-checkbox
                    v-if    = "can_save"
                    v-model ="save_item"
                    >
                    Aggiungi la voce all'elenco
                </b-form-checkbox>
                </div>
            </div>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="save_new_item">
                      OK
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <!-- modal edit-item -->
        <b-modal
            :id="'edit-item-' + name"
            :title="'Modifica voce ' + get_label()"
            @hide="clear_form"
            button-size="sm"
            @shown="set_focus('edit')"
            >
            <div>
                <b-form-textarea ref="edit_element" :state="edit_item_state" v-model="edit_item"></b-form-textarea>
                <div class="invalid-feedback">
                    voce non valida
                </div>
            </div>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="save_edit_item">
                      OK
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>
    </div>

</template>



