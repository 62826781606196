<script>

export default {
    name: 'entry-selector',
    props: [ 'options', 'selected_index' ],
    components : {},
    computed : {},
    watch: {
        selected_controparte_index( new_value ) {
            this.$emit( 'change-selected', new_value )
        }
    },
    methods : {
        add_controparte() {
            this.$emit( 'add-item', null );
        }
    },
    created() {
        if ( this.selected_index ) { this.selected_controparte_index = this.selected_index; }
    },
    data() {
        return {
            selected_controparte_index : null
        }
    },
}

</script>

<template>

    <b-row style="margin-bottom: 20px" align-v="center">
        <b-col cols="1">
            <b-button v-if="options.length" size="sm" variant="outline-info" @click="add_controparte"><icon name="plus"/></b-button>
        </b-col>
        <b-col cols="11">
            <b-form-radio-group
                v-model  = "selected_controparte_index"
                :options = "options"
                name     = "radio-inline"
            ></b-form-radio-group>
        </b-col>
    </b-row>

</template>

<style>
</style>

