<script>

/*
  DEPRECATED
*/

const schemas = require('@/schemaconfig');
import formpage from '@/vue-model-mirror/formpage';
import RegistryResidenza from './components/residenza';
import RegistryRegistry from './components/registry';
import RegistryContact from './components/contact';

export default {
    name: 'registry-formpage',
    extends: formpage,
    props: [ '' ],
    components : { RegistryRegistry, RegistryResidenza, RegistryContact },
    computed : {
    },
    watch: {},
    methods : {
    },
    created() {
    },
    data() {
        return {
        }
    },
}

</script>

<template>

    <div>
        <h3 style="text-align: center; padding-bottom: 14px;">{{ title }}</h3>

        <b-row style="margin: 2px">
            <b-col style="border: 1px solid lightgrey; padding: 5px">
                <p style="font-weight: 800; margin-left: 10px; margin-bottom: 0">Anagrafica</p>
                <registry-registry
                    :record  = "record"
                    :started = "started"
                />
                <p style="font-weight: 800; margin-left: 10px; margin-bottom: 0">Residenza</p>
                <registry-residenza
                    :record  = "record"
                    :started = "started"
                />
                <p style="font-weight: 800; margin-left: 10px; margin-bottom: 0">Contatti</p>
                <registry-contact
                    :record  = "record"
                    :started = "started"
                />
            </b-col>
            <b-col>
            </b-col>
        </b-row>
    </div>

</template>

<style>
</style>
