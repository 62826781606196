

module.exports = {
    cols: [
          { 
              row1: {
                  field1: 'nome',
                  field2: 'cognome',
              },
              row2: {
                  field1: 'titolo',
                  field2: null,
              },
              field1: 'note',
          },
    ]
};

