<script>

const moment = require('moment-timezone');

export default {

    name: 'cmp-diary-task',
    props: [ 'item' ],
    data() {
        return {
            icons: {
                'primo_contatto' : 'flag',
                'disamina'       : 'check',
                'appuntamento'   : 'geo',
                'ispezione'      : 'eye',
                'supervisore'    : 'check',
                'documenti'      : 'file-earmark',
                'scrittura'      : 'pen',
                'sottoscrizione' : 'tag',
                'correzione'     : 'cone-striped',
                'evasione'       : 'box-arrow-right',
                'incasso'        : 'wallet',
                'pagamenti'      : 'people',
            },
            bg_colors: {
                'done'         : 'bg-success',
                'unnecessary'  : 'bg-secondary',
                'intermediate' : 'bg-warning'
            }
        }
    },
    computed: {
    },
    methods: {
        get_icon_color( task_state_label = null ) {
            if ( [ 0, 2, 144 ].includes( this.item.code ) ) { return 'bg-light' }
            //if ( this.item.code === 2 ) { return 'pra-' + this.item.options.state_label; }
            return task_state_label === null ? 'bg-danger' : this.bg_colors[ task_state_label ];
        },
        get_icon() {
            if ( this.item.code === 144 ) { return 'person'; }
            else if ( [ 0, 2, ].includes( this.item.code ) ) { return 'gear'; }
            if ( this.item.options.task_label ) {
                return this.icons.hasOwnProperty( this.item.options.task_label ) ? this.icons[ this.item.options.task_label ] : 'question';
            }
        },
    },
    created () {
    },

}

</script>

<template>

    <div style="">
        <component
            v-if       = "item.code !== 6"
            :name      = "item.code + '-' + item.id"
            style      = "margin-right: 3px; cursor: pointer;"
            class      = "rounded-circle"
            :is        = "'b-icon-' + get_icon()"
            :class     = "get_icon_color( item.options.task_state_label )"
            scale      = "0.9"
            font-scale = "1.8"
        ></component>
    </div>

</template>

<style>

    .pra-nuovo {
        background-color: #97CEF7;
    }

    .pra-lavorazione {
        background-color: #F29D61;
    }

    .pra-evasione {
        background-color: #F2E861;
    }

    .pra-chiuso {
        background-color: #61F270;
    }

</style>

