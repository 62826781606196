////////////////////

import pratica_registries from '@/praticav2/classes/pratica_registries';

function validate_email( text ) {
    if ( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test( text ) ) {
        return true;
    }
    return false;
}

function extract_emails( text ) {
    var res = [];
    try {
        var pre = text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
    } catch ( error ) {
        return res;
    }
    if ( Array.isArray( pre ) ) {
        for ( var i = 0; i < pre.length; i++ ) {
            try {
                var is_valid = validate_email( pre[ i ] );
            } catch ( error ) {
                continue;
            }
            if ( is_valid ) { res.push( pre[ i ] ); }
        }
    }
    return res;
}

export const pratica_addresses = {

    namespaced: true,

    state: {
        loading : false,
        items   : null,
        obj     : null,
        prareg  : null,
    },

    actions: {
        load_pratica( { commit, dispatch }, pra ) {
            commit('set_obj', pra);
            let prareg = new pratica_registries( pra );
            commit( 'set_prareg', prareg );
            dispatch( 'read_addresses' );
        },
        set_obj( { commit, dispatch }, pra ) {
            commit('set_obj', pra);
            dispatch( 'read_addresses' );
        },
        set_prareg( { commit, dispatch }, prareg ) {
            commit('set_prareg', prareg);
            dispatch( 'read_addresses' );
        },
        load_addresses( { commit, rootGetters, dispatch } ) {
            commit( 'reset' );
            var pra = rootGetters['pratica/get_obj'];
            var prareg = rootGetters['pratica/get_prareg'];
            commit( 'set_obj', pra );
            commit( 'set_prareg', prareg );
            dispatch( 'read_addresses' );
        },
        read_addresses( { state, commit } ) {
            var addresses = [];
            var items = [];
            let label;
            //console.log( 'has_agenzia', state.prareg.has('agenzia') );
            if ( state.prareg.has('agenzia') && state.prareg.pregs.agenzia.email.length > 0 ) { // OK
                state.prareg.pregs.agenzia.email.map( x => {
                    label = 'agenzia';
                    if ( x.label ) { label += ` (${ x.label })`; }
                    label += ': ' + x.value;
                    items.push( { label, id: x.value } );
                });
            }
            if ( state.prareg.has('assicurato') && state.prareg.pregs.assicurato.email.length > 0 ) { // OK
                state.prareg.pregs.assicurato.email.map( x => {
                    label = 'assicurato';
                    if ( x.label ) { label += ` (${ x.label })`; }
                    label += ': ' + x.value;
                    items.push( { label, id: x.value } );
                });
            }
            if ( state.prareg.has_assicurato_referente && state.prareg.pregs.assicurato_referente.email.length > 0 ) { // OK
                state.prareg.pregs.assicurato_referente.email.map( x => {
                    label = 'assicurato referente';
                    if ( x.label ) { label += ` (${ x.label })`; }
                    label += ': ' + x.value;
                    items.push( { label, id: x.value } );
                });
            }
            if ( state.prareg.has('cliente') && state.prareg.pregs.cliente && state.prareg.pregs.cliente.email.length > 0 ) { // OK
                state.prareg.pregs.cliente.email.map( x => {
                    label = 'cliente';
                    if ( x.label ) { label += ` (${ x.label })`; }
                    label += ': ' + x.value;
                    items.push( { label, id: x.value } );
                });
            }
            if ( state.prareg.has_cliente_referente && state.prareg.pregs.cliente_referente.email.length > 0 ) { // OK
                state.prareg.pregs.cliente_referente.email.map( x => {
                    label = 'cliente referente';
                    if ( x.label ) { label += ` (${ x.label })`; }
                    label += ': ' + x.value;
                    items.push( { label, id: x.value } );
                });
            }
            if ( state.prareg.has('compagnia') && state.prareg.pregs.compagnia && state.prareg.pregs.compagnia.email.length > 0 ) { // OK
                state.prareg.pregs.compagnia.email.map( x => {
                    label = 'compagnia';
                    if ( x.label ) { label += ` (${ x.label })`; }
                    label += ': ' + x.value;
                    items.push( { label, id: x.value } );
                });
            }
            if ( state.prareg.has('ispettorato') && state.prareg.pregs.ispettorato.email.length > 0 ) { // OK
                state.prareg.pregs.ispettorato.email.map( x => {
                    label = 'ispettorato';
                    if ( x.label ) { label += ` (${ x.label })`; }
                    label += ': ' + x.value;
                    items.push( { label, id: x.value } );
                });
            }
            if ( state.prareg.has('liquidatore') && state.prareg.pregs.liquidatore.email.length > 0 ) { // OK
                state.prareg.pregs.liquidatore.email.map( x => {
                    label = 'liquidatore';
                    if ( x.label ) { label += ` (${ x.label })`; }
                    label += ': ' + x.value;
                    items.push( { label, id: x.value } );
                });
            }
            let ids; let i;
            if ( state.prareg.pregs.controparte ) {
                ids = Object.keys( state.prareg.pregs.controparte );
                for ( i = 0; i < ids.length; i++ ) {
                    if ( state.prareg.has_controparte( ids[ i ] ) ) {
                        state.prareg.pregs.controparte[ ids[ i ] ].controparte.email.map( x => { // OK
                            label = 'controparte';
                            if ( x.label ) { label += ` (${ x.label })`; }
                            label += ': ' + x.value;
                            items.push( { label, id: x.value } );
                        });
                    }
                    if ( state.prareg.has_controparte_referente( ids[ i ] ) ) {
                        state.prareg.pregs.controparte[ ids[ i ] ].controparte_referente.email.map( x => { // OK
                            label = 'controparte referente';
                            if ( x.label ) { label += ` (${ x.label })`; }
                            label += ': ' + x.value;
                            items.push( { label, id: x.value } );
                        });
                    }
                }
            }
            if ( state.prareg.pregs.autorita ) {
                ids = Object.keys( state.prareg.pregs.autorita );
                for ( i = 0; i < ids.length; i++ ) {
                    if ( state.prareg.has_autorita( ids[ i ] ) ) {
                        state.prareg.pregs.autorita[ ids[ i ] ].autorita.email.map( x => {
                            label = 'autorità';
                            if ( x.label ) { label += ` (${ x.label })`; }
                            label += ': ' + x.value;
                            items.push( { label, id: x.value } );
                        });
                    }
                    if ( state.prareg.has_autorita_referente( ids[ i ] ) ) {
                        state.prareg.pregs.autorita[ ids[ i ] ].autorita_referente.email.map( x => {
                            label = 'autorità referente';
                            if ( x.label ) { label += ` (${ x.label })`; }
                            label += ': ' + x.value;
                            items.push( { label, id: x.value } );
                        });
                    }
                }
            }
            if ( state.prareg.pregs.testimone ) {
                ids = Object.keys( state.prareg.pregs.testimone );
                for ( i = 0; i < ids.length; i++ ) {
                    if ( state.prareg.has_testimone( ids[ i ] ) ) {
                        state.prareg.pregs.testimone[ ids[ i ] ].testimone.email.map( x => {
                            label = 'testimone';
                            if ( x.label ) { label += ` (${ x.label })`; }
                            label += ': ' + x.value;
                            items.push( { label, id: x.value } );
                        });
                    }
                    if ( state.prareg.has_testimone_referente( ids[ i ] ) ) {
                        state.prareg.pregs.testimone[ ids[ i ] ].testimone_referente.email.map( x => {
                            label = 'testimone referente';
                            if ( x.label ) { label += ` (${ x.label })`; }
                            label += ': ' + x.value;
                            items.push( { label, id: x.value } );
                        });
                    }
                }
            }
            commit( 'set_items', items );
        },
        get_all({commit, state, rootGetters}, data = { options: {} }) {},
        clear( { commit } ) { commit('reset'); },
    },

    mutations: {
        reset( state ) {
            state.loading = true;
            state.items = [];
            state.obj = null;
        },
        set_obj( state, pra ) { state.obj = pra; },
        set_prareg( state, prareg ) { state.prareg = prareg; },
        set_items( state, items ) {
            state.items = items;
            state.loading = false;
        }
    },

}

