////////////////////

import base_module      from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'sollecito',
    send_alert    : false,
    default_order : 'id ASC',
};

var base = new base_module( options );

const actions = {
    ...base.actions,
    clear( state ) {
        state.obj           = {};
        state.items         = null;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.executed      = false;
        state.force_reload  = false;
        state.selected      = false;
    },
}

export default {
    namespaced : true,
    actions,
    state      : base.state,
    getters    : base.getters,
    mutations  : base.mutations,
};

