<script>

import bnvtext from './bnvtext.vue';

export default {
    extends: bnvtext,
    props: [ 'placeholder', ],
    created() {
        this._validate();
    },
    watch: {
        _model( new_value ) {
            if ( this.timeout ) { clearTimeout( this.timeout ); }
            this.timeout = setTimeout( () => {
                this._validate();
            }, 500 );
        },
    },
    methods: {
        _reset_state() {
            this.error_message    = null; 
            this.merror_component = false;
            this.is_valid        = null;
        },
        _set_error( msg ) {
            this.error_message    = msg; 
            this.merror_component = true;
            this.is_valid        = null;
        },
        _set_is_valid() {
            this.error_message    = null; 
            this.merror_component = false;
            this.is_valid        = true;
        },
        _validate: function() {
            var check = this._validate_allow_null();
            if ( check === true && !this.model ) { this._reset_state(); return check; }
            if ( check === true ) {
                check = this._validate_codfisc();
            }
            //return check;
            return true;
        },
        _validate_codfisc() {
            var match = this.model.match( /^[a-z]{6}\d\d[a-z]\d\d[a-z]\d\d\d[a-z]$/i );
            if ( !match ) {
                //this.error_component = true;
                //this.error_message = `lunghezza massima ${this.maxlength} caratteri`;
                this._set_error('codice fiscale non valido');
                return false;
            } else {
                this._set_is_valid();
            }
            return true;
        },
        calc_codfisc( open = false ) {
            this._validate();
            var obj = { event_name: 'cf-button', payload: { open } }
            this.$emit('generic-event', obj );
        },
    },
    data() {
        return { is_valid: null, timeout: null, merror_component: null };
    },
};

</script>

<template>

    <div class="form-group" :class="field_class" :style="field_style">
        <label :for="'bnv-form-' + name" :class="label_class">{{ get_label() }}</label>
        <div :class="element_container_class">
            <b-input-group>
                <b-input
                    v-on:keyup.enter  = "enter_key"
                    ref               = "input"
                    type              = "text"
                    :size             = "input_class"
                    v-bind:class      = "{ 'is-invalid': merror_component, 'is-valid': is_valid }"
                    :placeholder      = "get_placeholder()"
                    :aria-label       = "name"
                    :aria-describedby = "name"
                    :id               = "'bnv-form-' + name"
                    v-model           = "_model"
                    :disabled         = "disabled"
                    :readonly         = "_readonly"
                    @blur             = "calc_codfisc()"
                />
                <b-input-group-append>
                    <b-button variant="outline-secondary" @click="calc_codfisc('open')"><icon name="bars"/></b-button>
                </b-input-group-append>
            </b-input-group>
            <small v-if="helper" :id="'bnv-form-' + name + '-help'" class="form-text text-muted" v-html="_helper"></small>
            <div class="invalid-feedback">
                {{ get_error_message() }}
            </div>
        </div>
    </div>

</template>



