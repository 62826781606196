////////////////////

import { authHeader } from '@/helpers';

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export const section_service = {
    get_all,
};

function get_all( data = null ) {
    var customer_id = data.customer_id;
    delete data.customer_id;
    var url = `${config.backend_url}section/${ customer_id }`;
    if ( data ) {
        var params = [];
        Object.keys( data ).map( x => {
            params.push( `${x}=${data[ x ]}` );
        });
        if ( params.length > 0 ) {
            url += '?' + params.join('&');
        }
    }
    var options = {
        method  : 'GET',
        headers : authHeader(),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = data || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

