//////////////////////////////

module.exports = (type) => {
    var schema = {
        container: {
            type: type.STRING(1024),
            allowNull: false,
        },
        created: {
            type: type.DATE,
            defaultValue: type.NOW,
        },
        user_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'users',
                key         : 'id',
                constraints : true,
                store_name  : 'user',
            }
        },
        hash: {
            type: type.STRING(2048),
            allowNull: false,
        },
        config: {
            type: type.JSONB,
            allowNull: false,
        },
        state: {
            type: type.STRING(32),
            defaultValue: 'queue',
        },
        id: {
          type: type.INTEGER,
          primaryKey: true,
          autoIncrement: true
        },
    };

    return schema;
}

