////////////////////

import { tipo_strada_service } from '@/services';
import base_module from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'no-model',
    send_alert    : false,
    //default_order : 'label ASC',
};

var base = new base_module( options, tipo_strada_service );

export default {
    namespaced : true,
    ...base,
};

