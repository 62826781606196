<script>

export default {

    name: 'statistic-user',
    props: [ 'item' ],
    data() {
        return {
        }
    },
    created() {
    },
    computed: {
    },
    methods: {
    },

}

</script>

<template>

    <div>
        <span v-if="item.type === 'user'">{{ item.user }}</span>
        <b-badge variant="warning" v-else-if="item.type === 'custodian'">{{ item.user }}</b-badge>
    </div>

</template>


