

module.exports = {
    cols: [
          { 
              row1: {
                  field1: 'comune_id',
                  field2: 'citta_estero',
              },
              row2: {
                  field1: 'strada',
                  field2: 'n_civ',
              },
              row3: {
                  field1: 'cap',
                  field2: 'nazione',
              },
              row4: {
                  field1: 'latitudine',
                  field2: 'longitudine',
              },
              field2: 'altro',
          },
    ]
};

