<script>

export default {

    name: 'parcel-user',
    props: [ 'item' ],
    data() {
        return {
            pratica: null,
        }
    },
    created() {
        this.pratica = this.$store.getters['pratica/get_obj'];
    },
    computed: {
        tasks() {
            var tasks = this.pratica.tasks.filter( x => ( x.customer_id === this.item.custodian_id && x.newstate < 4000 ) );
            return tasks;
        },
        customers () { return this.$store.getters['statemanager/customers'].map( x => x.id ); },
    },
    methods: {
        get_icon() {
            return 'b-icon-' + this.$store.getters[ 'statemanager/icon' ]( this.item.task_label );
        },
        get_icon_color( task_label ) {
            for ( var i = 0; i < this.pratica.tasks.length; i++ ) {
                if ( this.pratica.tasks[ i ].task_label === task_label && this.customers.includes( this.pratica.tasks[ i ].customer_id ) ) {
                    var res =  this.pratica.tasks[ i ].label === null ? 'bg-danger' : this.$store.getters['statemanager/color_by_state']( this.pratica.tasks[ i ].label );
                    return res;
                }
            }
        },
        get_title( str ) {
            str = str.replace(/_/g, ' ');
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        get_task_counter() {
            var custodian_tasks = this.pratica.tasks.filter( x => x.customer_id === this.item.custodian_id && x.newstate < 4000 );
            var total     = custodian_tasks.length;
            var completed = custodian_tasks.filter( x => x.done === true ).length;
            return `${ completed }/${ total }`;
        }
    },

}

</script>

<template>

    <div style="vertical-align: middle" v-if="!item.custodian_id">
        <component
            v-bind:key = "item.task_label"
            :class     = "get_icon_color( item.task_label )"
            variant    = "light"
            :name      = "item.task_label"
            style      = "margin-right: 3px; cursor: pointer;"
            :style     = "{ padding: [ 'disamina', 'supervisore' ].includes( item.task_label ) ? '0' : '3px' }"
            class      = "rounded-circle"
            :is        = "get_icon()"
            scale      = "0.9"
            font-scale = "1.8"
        ></component> {{ item.task_label }}
    </div>
    <div style="vertical-align: middle" v-else-if="item.custodian_id">
        <span style="font-weight: 600;">{{ get_task_counter() }}</span>
        <!-- <div class="form-inline">
            <div v-for="task in tasks" class="text-center" style="padding: 0; margin: 0;display: inline-block">
                <div :title="get_title( task.task_label )">
                    <component
                        v-bind:key = "task.task_label"
                        :name      = "task.task_label"
                        style      = "margin-right: 3px; cursor: pointer;"
                        class      = "rounded-circle"
                        variant    = "light"
                        :class     = "get_icon_color( task.task_label )"
                        :is        = "get_icon( task.task_label )"
                        scale      = "0.9"
                        font-scale = "1.8"
                    ></component><br/>
                </div>
            </div>
        </div> -->
    </div>

</template>

