//////////////////////////////

module.exports = (type) => {
    var schema = {
        compiler: {
            type: type.STRING(64),
            allowNull: true,
            defaultValue: null,
        },
        mandante: {
            type: type.STRING(1024),
            allowNull: true,
            defaultValue: null,
        },
        num_incarichi_pregressi: {
            type: type.INTEGER,
            allowNull: true,
            defaultValue: null,
        },
        pratica_url: {
            type: type.STRING(2048),
            allowNull: true,
            defaultValue: null,
        },
        anno: {
            type: type.INTEGER,
            allowNull: true,
            defaultValue: null,
        },
        studio: {
            type: type.STRING(128),
            allowNull: true,
            defaultValue: null,
        },
        ramo: {
            type: type.STRING(128),
            allowNull: true,
            defaultValue: null,
        },
        numero_pratica_esterno: {
            type: type.STRING(64),
            allowNull: true,
            defaultValue: null,
        },
        numero_pratica_esterno_extended: {
            type: type.STRING(64),
            allowNull: true,
            defaultValue: null,
        },
        societa_incaricata: {
            type: type.STRING(64),
            allowNull: true,
            defaultValue: null,
        },
        luogo_sinistro: {
            type: type.STRING(2048),
            allowNull: true,
            defaultValue: null,
        },
        data_incarico: {
            type: type.DATEONLY,
            allowNull: true,
            defaultValue: null,
        },
        data_sinistro: {
            type: type.DATEONLY,
            allowNull: true,
            defaultValue: null,
        },
        tipologia_danno: {
            type: type.STRING(2048),
            allowNull: true,
            defaultValue: null,
        },
        tipologia_incarico: {
            type: type.STRING(2048),
            allowNull: true,
            defaultValue: null,
        },
        numero_sinistro: {
            type: type.STRING(128),
            allowNull: true,
            defaultValue: null,
        },
        numero_polizza: {
            type: type.STRING(128),
            allowNull: true,
            defaultValue: null,
        },
        note: {
            type: type.TEXT,
            allowNull: true,
            defaultValue: null,
        },
        danno_presunto: {
            type: type.DECIMAL(10,2),
            allowNull: true,
            defaultValue: null,
        },
    }

    return schema;
}

