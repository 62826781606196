//////////////////////////////

module.exports = (type) => {
    var schema = {
        name: {
            type: type.STRING(64),
            allowNull: false,
            label: 'nome elemento'
        },
        id: {
            type: type.HIDDEN,
            allowNull: false,
        },
    };

    return schema;
}


