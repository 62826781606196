////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'registry_template',
    send_alert    : false,
    default_order : 'label ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    template_map       : new Map(),
    template_map_ids   : new Map(),
    template_map_names : new Map(),
}

const actions = {
    ...base.actions,
    load( { dispatch, state }, data = { options: {} } ) {
        if ( state.template_map.size > 0 ) { return; }
        dispatch( 'get_all', data );
    }
};

const mutations = {
    ...base.mutations,
    success_list( state, items ) {
        state.items    = items;
        items.map( x => {
            state.template_map.set( x.label, x );
            state.template_map_names.set( x.template_name, x.id );
            state.template_map_ids.set( x.id, x );
        });
        state.loading  = false;
        state.loading_by_id = false;
        state.error    = false;
        state.executed = true;
    },
};


export default {
    namespaced : true,
    ...base,
    actions,
    state,
    mutations,
};


