////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'user',
    send_alert    : false,
    default_order : 'last_name ASC',
};

var base = new base_module( options );

export default {
    namespaced : true,
    ...base,
};

