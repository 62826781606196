////////////////////

import axios from 'axios';
const moment = require('moment');

import base_module from '@/vue-model-mirror/stores/base.module';
import { authHeader } from '@/helpers';
const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];
import { registry_service } from '@/services';

var options = {
    model_name    : 'registry',
    send_alert    : false,
    default_order : 'id DESC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    customer_id      : null,
    obj_modal        : null,
    new_record       : false,
    record_updated   : false,
    new_record_modal : false,
    relation_created : false,
    relation_deleted : false,
    registry_deleted : false,
    error_delete     : false,
    error_delete_message : null,
    cga_updated      : null,
    error_obj        : null,
    filters          : {},
    text_filter       : '',
    text_filter_valid : true,
    date_filters      : [ { field: '', range: null } ],
}

const actions = {
    ...base.actions,
    get_all( { commit, state, getters }, _data = { options: {} } ) { // ok
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        commit('start_request');
        data.options.order = getters.order;
        console.log( state.filters );
        Object.keys( state.filters ).map( key => {
            if ( key === 'date_filters' ) {
                let date_filters = state.filters.date_filters;
                for ( var i = 0; i < state.filters.date_filters.length; i++ ) {
                    data.options[ date_filters[ i ].field ] = [
                        moment( date_filters[ i ].range[0] ).format('YYYY-MM-DD'),
                        moment( date_filters[ i ].range[1] ).format('YYYY-MM-DD'),
                    ].join('|');
                }
            } else {
                data.options[ key ] = state.filters[ key ];
            }
        });
        state.service.get_all(getters.model_name, data.options)
            .then( response => {
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                    commit('success_list', response.data);
                } else {
                    commit('success_list', response)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
    set_cga( { commit }, obj ) {
        commit( 'set_cga', obj );
    },
    set_params( { commit }, obj ) { commit('set_params', obj); },
    get_children( { commit }, options ) { // chiamato per ottenere una lista di chidren di una registry filtrati per role_id
        var url = config.backend_url + 'model/registry/' + options.id + '/children?child_role_id=' + options.qry.child_role_id;
        if ( options.qry.label ) {
            url += '&label=' + options.qry.label;
        }
        var options = { headers: authHeader() };
        axios.get( url, options )
            .then( response => {
                commit('success_list', response.data);
                commit('force_reload');
            })
            .catch( error => {
                commit('error', error)
            });
    },
    destroy( { commit }, id ) {
        var url = config.backend_url + 'model/registry/' + id;
        var options = { headers: authHeader() };
        axios.delete( url, options )
            .then( response => {
                commit('success_destroy_registry');
            })
            .catch( error => {
                if ( error.response.status === 400 && error.response.data.message && error.response.data.message.startsWith('impossibile eliminare l\'anagrafica') ) {
                    commit( 'error_delete', error.response.data.message );
                } else {
                    console.log( error );
                    commit('error', error)
                }
            });
    },
    remove_relation( { commit }, id ) {
        var url = config.backend_url + 'model/registry_relation/' + id;
        var options = { headers: authHeader() };
        axios.delete( url, options )
            .then( response => {
                commit('success_delete_relation');
            })
    },
    add_relation( { commit }, payload ) {
        var url = config.backend_url + 'model/registry_relation/';
        var options = { headers: authHeader() };
        axios.post( url, payload, options )
            .then( response => {
                commit('success_create_relation', response.data);
            })
    },
    create_with_attachments({ commit, state, getters }, data) {
        commit('start_request');
        var url = `${config.backend_url}model/${data.schema}`;
        var payload = {};
        const form = new FormData();
        Object.keys( data.payload ).map( x => {
            if ( x === 'attachments' ) {         // allegati
                let files = data.payload[ x ];
                let atts = [];
                files.map( file => {
                    form.append( 'files', file.file )
                    form.append( file.file.name, file.label )
                });
            } else if ( x === 'cgas' ) {         // cgas (esclusi dal payload)
                let cgas = data.payload[ x ];
                cgas.map( cga => {
                    form.append( 'cgas[]', cga.id );
                });
            } else {                             // altri campi
                form.append(x, data.payload[ x ]);
            }
        });
        var options = {
            url,
            headers : authHeader(),
            method  : 'post',
            data    : form,
        };
        options.headers['Content-Type'] = 'multipart/form-data';
        axios( options )
            .then( response => {
                commit('success', response.data);
            })
            .catch( error => {
                commit('error', error)
            });
    },
    create_modal_with_attachments({ commit, state, getters }, data) {
        commit('start_request');
        var url = `${config.backend_url}model/${data.schema}`;
        var payload = {};
        const form = new FormData();
        Object.keys( data.payload ).map( x => {
            if ( x === 'attachments' ) {         // allegati
                let files = data.payload[ x ];
                let atts = [];
                files.map( file => {
                    form.append( 'files', file.file )
                    form.append( file.file.name, file.label )
                });
            } else if ( x === 'cgas' ) {         // cgas (esclusi dal payload)
                let cgas = data.payload[ x ];
                cgas.map( cga => {
                    form.append( 'cgas[]', cga.id );
                });
            } else {                             // altri campi
                form.append(x, data.payload[ x ]);
            }
        });
        var options = {
            url,
            headers : authHeader(),
            method  : 'post',
            data    : form,
        };
        options.headers['Content-Type'] = 'multipart/form-data';
        axios( options )
            .then( response => {
                commit('success_modal', response.data);
            })
            .catch( error => {
                commit('error', error)
            });
    },
    update_with_attachments({ commit, state, getters }, data) {
        commit('start_request');
        var url = `${config.backend_url}model/${data.schema}/${data.id}?include=true`;
        //if ( include ) { url += `?include=true` }
        var payload = {};
        const form = new FormData();
        Object.keys( data.payload ).map( x => {
            if ( x === 'attachments' ) {         // allegati
//              let files = data.payload[ x ];
//              let atts = [];
//              files.map( file => {
//                  form.append( 'files', file.file )
//                  form.append( file.file.name, file.label )
//              });
            } else if ( x === 'cgas' ) {         // cgas (esclusi dal payload)
            } else {                             // altri campi
                form.append(x, data.payload[ x ]);
            }
        });
        //registry_service.createws( url, form )
        var options = {
            url,
            headers : authHeader(),
            method  : 'patch',
            data    : form,
        };
        options.headers['Content-Type'] = 'multipart/form-data';
        axios( options )
            .then( response => {
                commit('success_update', response.data);
            })
            .catch( error => {
                commit('error', error)
            });
    },
    create_modal({ commit, state, getters }, data) {
        commit('start_request');
        state.service.create( getters.model_name, data.payload, data.include )
            .then( obj => {
                commit('success_modal', obj);
            })
            .catch( error => {
                commit('error', error)
            });
    },
    update({ commit, state, getters }, data) {
        commit('start_request');
        state.service.update( getters.model_name, data.id, data.payload, data.include )
            .then( obj => {
                commit('success_update', obj);
            })
            .catch( error => {
                commit('error', error)
            });
    },
    set_text_filter( { commit }, value ) { commit( 'set_text_filter', value ); },
    set_date_filters( { commit }, value ) { commit( 'set_date_filters', value ); },
};

const mutations = {
    ...base.mutations,
    set_text_filter( state, value ) { state.text_filter = value; },
    set_date_filters( state, value ) { state.date_filters = value; },
    success_destroy_registry( state ) {
        state.registry_deleted = true;
        setTimeout( () => { state.registry_deleted = false; }, 200 );
        state.force_reload = true;
    },
    success_delete_relation( state ) {
        state.relation_deleted = true;
        setTimeout( () => { state.relation_deleted = false; }, 200 )
    },
    success_create_relation( state, obj ) {
        state.relation_created = obj;
        setTimeout( () => { state.relation_created = false; }, 200 )
    },
    success_modal( state, obj ) {
        state.obj_modal        = obj;
        state.loading          = false;
        state.new_record_modal = true;
        setTimeout( () => {
            state.obj_modal = null;
        }, 250);
        setTimeout( () => { state.new_record_modal = false; }, 200 )
    },
    set_params( state, obj ) {
        if ( typeof obj === 'object' ) {
            var keys = Object.keys( obj );
            for ( var i = 0; i < keys.length; i++  ) {
                if ( state.hasOwnProperty( keys[ i ] ) ) { state[ keys[ i ] ] = obj[ keys[ i ] ]; }
            }
        }
    },
    success_list( state, items ) {
        state.items    = items;
        state.loading  = false;
        state.loading_by_id = false;
        state.error    = false;
        state.executed = true;
        setTimeout( () => { state.executed = false; }, 200 )
    },
    success( state, obj ) {
        state.obj           = obj;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.executed      = true;
        state.new_record    = true;
        state.force_reload  = true;
        setTimeout( () => { state.new_record = false; }, 200 )
    },
    success_update( state, obj ) {
        state.obj            = obj;
        state.loading        = false;
        state.loading_by_id  = false;
        state.error          = false;
        state.executed       = true;
        state.record_updated = true;
        setTimeout( () => { state.record_updated = false; }, 200 )
    },
    error_delete( state, message ) {
        state.error_delete_message = message;
        state.error_delete = true;
        setTimeout( () => {
            state.error_delete = false;
        });
    },
    set_cga( state, obj ) {
        state.obj.cgas = obj.cgas;
        state.cga_updated = true;
        setTimeout( () => {
            state.cga_updated = null;
        }, 200);
    },
    error( state, error ) {
        state.error_obj    = error;
        state.loading      = false;
        state.error        = true;
        state.executed     = false;
        state.force_reload = true;
        setTimeout( () => {
            state.error     = false;
            state.error_obj = null;
        }, 200 );
    },
};

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
};

