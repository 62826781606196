<script>

import { mapState } from 'vuex';
import pratica_subtab_base from './base_data';
const schemas = require('@/schemaconfig');
import CodFisc from '@/registry/helpers/CodFisc';

export default {
    name: 'pratica-subtab-assicurato',
    extends: pratica_subtab_base,
    props: [],
    computed : {
        assicurato_referente_template() {
            let template = this.registry_template_ids.get( this.prareg.assicurato_referente.template_id );
            return template.template_name + '_subtab';
        },
        assicurato_referente_schema() {
            return schemas[ this.assicurato_referente_template ];
        },
        ...mapState('pratica', {
            cga_updated_preg : state => state.cga_updated,
            registry_setted : state => state.registry_setted,
        }),
    },
    watch: {
        cga_updated_preg( new_value ) {
            if ( !new_value ) { return; }
            this.make_toast( 'CGA aggiornate' );
            this.force_reload();
            this.reload('main_key');
        },
        registry_setted( new_value ) {
            if ( !new_value ) { return; }
            this.force_reload();
            this.reload('root_key');
        },
    },
    methods : {
        init_registry( template_name, role_name ) {
            if ( role_name === 'compagnia' ) {
                this.template_id    = this.registry_template.get( template_name ).id;
                this.role_id        = this.registry_role.get( role_name ).id;
                this.field_label    = 'Associa Compagnia';
                this.$refs.modal_association.mao.set_params({
                    search_filters : { template_id : this.template_id },
                    template       : '_compagnia',
                    role_id        : this.role_id,
                });
            } else if ( role_name === 'assicurato' ) {
                this.template_id    = template_name.map( x => this.registry_template.get( x ).id );
                this.role_id        = this.registry_role.get( role_name ).id;
                this.field_label    = 'Associa Assicurato';
                this.$refs.modal_association.mao.set_params({
                    search_filters : { template_id__in : this.template_id },
                    template       : [ '_persona_fisica', '_persona_giuridica' ],
                    role_id        : this.role_id,
                });
            } else if ( role_name === 'assicurato_referente' ) {
                this.template_id    = template_name.map( x => this.registry_template.get( x ).id );
                this.role_id        = this.registry_role.get( 'referente' ).id;
                this.field_label    = 'Associa Referente Assicurato';
                let modal_association_options = {
                    template  : [ '_persona_fisica', '_persona_giuridica' ],
                    role_id   : this.role_id,
                    father_id : this.pra.pregs.assicurato.id,
                };
                if ( this.pra.pregs.assicurato.registry_id ) {
                    modal_association_options.registry_father_id      = this.pra.pregs.assicurato.registry_id;
                    modal_association_options.registry_father_role_id = this.registry_role.get( 'referente' ).id;
                    modal_association_options.search_filters          = { id: this.pra.pregs.assicurato.registry_id, qry: { child_role_id: this.role_id }, label: 'Referenti associati' };
                    this.$refs.modal_association.mao.set_params( modal_association_options );
                    this.$refs.modal_association.load_registries();
                    return;
                } else {
                    modal_association_options.search_filters = { template_id__in : this.template_id };
                    this.$refs.modal_association.mao.set_params( modal_association_options );
                }
            } else if ( role_name === 'assicurato_polizza' ) {
                this.template_id    = template_name.map( x => this.registry_template.get( x ).id );
                this.role_id        = this.registry_role.get( 'polizza' ).id;
                this.field_label    = 'Associa Polizza Assicurato';
                let modal_association_options = {
                    template  : [ '_polizza' ],
                    role_id   : this.role_id,
                    father_id : this.pra.pregs.assicurato.id,
                };
                if ( this.pra.pregs.assicurato.registry_id ) {
                    modal_association_options.registry_father_id      = this.pra.pregs.assicurato.registry_id;
                    modal_association_options.registry_father_role_id = this.registry_role.get( 'polizza' ).id;
                    modal_association_options.search_filters          = { id: this.pra.pregs.assicurato.registry_id, qry: { child_role_id: this.role_id }, label: 'Polizze associate' };
                    this.$refs.modal_association.mao.set_params( modal_association_options );
                    this.$refs.modal_association.load_registries();
                    return;
                } else {
                    modal_association_options.search_filters = { template_id__in : this.template_id };
                    this.$refs.modal_association.mao.set_params( modal_association_options );
                }
            } else if ( role_name === 'agenzia' ) {
                this.template_id    = this.registry_template.get( template_name ).id;
                this.role_id        = this.registry_role.get( role_name ).id;
                this.field_label    = 'Associa Agenzia';
                let modal_association_options = {
                    template : '_agenzia',
                    role_id  : this.role_id,
                };
                if ( this.pra.pregs.compagnia.registry_id ) {
                    modal_association_options.registry_father_id      = this.pra.pregs.compagnia.registry_id;
                    modal_association_options.registry_father_role_id = this.pra.pregs.compagnia.role_id;
                    modal_association_options.search_filters          = { id: this.pra.pregs.compagnia.registry_id, qry: { child_role_id: this.role_id }, label: 'Agenzie associate' };
                    this.$refs.modal_association.mao.set_params( modal_association_options );
                    this.$refs.modal_association.load_registries();
                    return;
                } else {
                    modal_association_options.search_filters = { template_id : this.template_id };
                    this.$refs.modal_association.mao.set_params( modal_association_options );
                }
            } else if ( role_name === 'ispettorato' ) {
                this.template_id    = this.registry_template.get( template_name ).id;
                this.role_id        = this.registry_role.get( role_name ).id;
                this.field_label    = 'Associa Ispettorato';
                let modal_association_options = {
                    template : '_ispettorato',
                    role_id  : this.role_id,
                };
                if ( this.pra.pregs.compagnia.registry_id ) {
                    modal_association_options.registry_father_id      = this.pra.pregs.compagnia.registry_id;
                    modal_association_options.registry_father_role_id = this.pra.pregs.compagnia.role_id;
                    modal_association_options.search_filters          = { id: this.pra.pregs.compagnia.registry_id, qry: { child_role_id: this.role_id }, label: 'Ispettorati associati' };
                    this.$refs.modal_association.mao.set_params( modal_association_options );
                    this.$refs.modal_association.load_registries();
                    return;
                } else {
                    modal_association_options.search_filters = { template_id : this.template_id };
                    this.$refs.modal_association.mao.set_params( modal_association_options );
                }
            } else if ( role_name === 'liquidatore' ) {
                this.template_id    = this.registry_template.get( template_name ).id;
                this.role_id        = this.registry_role.get( role_name ).id;
                this.field_label    = 'Associa Liquidatore';
                let modal_association_options = {
                    template : '_liquidatore',
                    role_id  : this.role_id,
                };
                if ( this.pra.pregs.compagnia.registry_id ) {
                    modal_association_options.registry_father_id      = this.pra.pregs.compagnia.registry_id;
                    modal_association_options.registry_father_role_id = this.pra.pregs.compagnia.role_id;
                    modal_association_options.search_filters          = { id: this.pra.pregs.compagnia.registry_id, qry: { child_role_id: this.role_id }, label: 'Liquidatori associati' };
                    this.$refs.modal_association.mao.set_params( modal_association_options );
                    this.$refs.modal_association.load_registries();
                    return;
                } else {
                    modal_association_options.search_filters = { template_id : this.template_id };
                    this.$refs.modal_association.mao.set_params( modal_association_options );
                }
            }
            this.$refs.modal_association.load_items();
            //this.toggle_modal();
        },
        blur( payload ) { console.log( payload ); },
        cf_button( payload ) {
            var codice_fiscale = this.$refs.form_assicurato.get_element_value('codice_fiscale');
            if ( codice_fiscale && codice_fiscale.length > 0 ) {
                if ( !CodFisc.validate( codice_fiscale ) ) {
                    this.cf_payload.sesso             = null;
                    this.cf_payload.data_nascita      = null;
                    this.cf_payload.comune_nascita_id = null;
                    if ( payload.open ) { this.toggle_modal('modal-cf'); }
                    return;
                }
                var data = CodFisc.parse_codfisc( codice_fiscale );
                this.cf_payload.sesso          = data.sesso;
                this.cf_payload.data_nascita   = data.data_nascita;
                this.$store.dispatch( 'comune_nascita/get_by_code', data.codice_comune );
                if ( payload.open ) { this.toggle_modal('modal-cf'); }
                return;
            } else {
                this.cf_payload.sesso             = null;
                this.cf_payload.data_nascita      = null;
                this.cf_payload.comune_nascita_id = null;
            }
            if ( payload.open ) { this.toggle_modal('modal-cf'); }
        },
        save_cf_data( payload ) {
            this.$refs.modal_cf.validate();
            if ( !this.$refs.modal_cf.is_valid ) { return; }
            if ( payload.sesso && payload.data_nascita && payload.comune_nascita_id ) {
                var nome    = this.$refs.form_assicurato.get_element_value('nome');
                var cognome = this.$refs.form_assicurato.get_element_value('cognome');
                if ( nome && nome.length > 0 && cognome && cognome.length > 0 ) {
                    var data = {
                        nome,
                        cognome,
                        codice_comune : this.$store.state.comune_nascita.obj.codice,
                        data_nascita  : payload.data_nascita,
                        sesso         : payload.sesso,
                    };
                    var codice_fiscale = CodFisc.get_codfisc( data );
                    this.$refs.form_assicurato.set_payload( { codice_fiscale }, true );
                    this.$refs.form_assicurato.reset_error('codice_fiscale');
                    this.cf_payload.sesso             = payload.sesso;
                    this.cf_payload.comune_nascita_id = payload.comune_nascita_id;
                    this.cf_payload.data_nascita      = payload.data_nascita;
                }
            }
            this.toggle_modal('modal-cf');
        },
        submit_cf_form() {
            this.$refs.modal_cf.submit_form();
        },
        async save_all() { // fab button
            let payload = {};
            let errors = [];
            if ( this.prareg.has('assicurato') ) {
                let payload_assicurato = this.$refs.form_assicurato.get_form_data();
                if ( this.$refs.form_assicurato.is_valid ) {
                    payload.assicurato = {
                        ...payload_assicurato,
                        ...this.cf_payload,
                        ...this.addressobj.values,
                        id: this.pra.pregs.assicurato.id,
                    };
                    if ( payload.assicurato.codice_fiscale ) {
                        try {
                            let codfisc_data = CodFisc.parse_codfisc( payload.assicurato.codice_fiscale );
                            let comune_nascita_id = await this.$store.dispatch( 'comune_nascita/get_by_code', codfisc_data.codice_comune );
                            payload.assicurato.comune_nascita_id = comune_nascita_id;
                            payload.assicurato.sesso             = codfisc_data.sesso;
                            payload.assicurato.data_nascita      = codfisc_data.data_nascita;
                        } catch ( error ) {
                            payload.assicurato.comune_nascita_id = null;
                            payload.assicurato.sesso             = null;
                            payload.assicurato.data_nascita      = null;
                        }
                    }
//              } else {
//                  errors.push( 'assicurato' )
                }
                if ( this.prareg.has_assicurato_referente ) {
                    let payload_assicurato_referente = this.$refs.form_assicurato_referente.get_form_data();
                    if ( this.$refs.form_assicurato_referente.is_valid ) {
                        payload.assicurato_referente = {
                            ...payload_assicurato_referente,
                            id: this.pra.pregs.assicurato_referente.id
                        };
//                  } else {
//                      errors.push( 'assicurato_referente' )
                    }
                }
                if ( this.prareg.has('assicurato_polizza') ) {
                    let payload_assicurato_polizza = this.$refs.form_assicurato_polizza.get_form_data();
                    delete payload_assicurato_polizza.attachments; // rimouvo gli allegati
                    if ( this.$refs.form_assicurato_polizza.is_valid ) {
                        payload.assicurato_polizza = {
                            ...payload_assicurato_polizza,
                            id: this.pra.pregs.assicurato_polizza.id
                        };
//                  } else {
//                      errors.push( 'assicurato_polizza' )
                    }
                }
            }
            if ( this.prareg.has('compagnia') ) {
                let payload_compagnia = this.$refs.form_compagnia.get_form_data();
                if ( this.$refs.form_compagnia.is_valid ) {
                    payload.compagnia = {
                        ...payload_compagnia,
                        id: this.pra.pregs.compagnia.id,
                    };
//              } else {
//                  errors.push( 'compagnia' )
                }
                if ( this.prareg.has('agenzia') ) {
                    let payload_agenzia = this.$refs.form_agenzia.get_form_data();
                    if ( this.$refs.form_agenzia.is_valid ) {
                        payload.agenzia = {
                            ...payload_agenzia,
                            id: this.pra.pregs.agenzia.id,
                        };
//                  } else {
//                      errors.push( 'agenzia' )
                    }
                }
                if ( this.prareg.has('ispettorato') ) {
                    let payload_ispettorato = this.$refs.form_ispettorato.get_form_data();
                    if ( this.$refs.form_ispettorato.is_valid ) {
                        payload.ispettorato = {
                            ...payload_ispettorato,
                            id: this.pra.pregs.ispettorato.id,
                        };
//                  } else {
//                      errors.push( 'ispettorato' )
                    }
                }
                if ( this.prareg.has('liquidatore') ) {
                    let payload_liquidatore = this.$refs.form_liquidatore.get_form_data();
                    if ( this.$refs.form_liquidatore.is_valid ) {
                        payload.liquidatore = {
                            ...payload_liquidatore,
                            id: this.pra.pregs.liquidatore.id,
                        };
//                  } else {
//                      errors.push( 'liquidatore' )
                    }
                }
            }
            this.update_all( { payload, codice_pratica: this.codice_pratica } )
//          errors.map( label => {
//                this.classes[ label ] = 'dati_box_error';
//          });
//          setTimeout( () => {
//              errors.map( label => {
//                  this.classes[ label ] = null;
//                  this.reload( label + '_key' );
//              });
//          }, 5000 );
        },
        set_addressobj_value() {
            if ( this.prareg.has('assicurato') ) {
                this.addressobj.set_values( this.copy( this.pra.pregs.assicurato ) );
            }
        },
    },
    created() {
        this.set_addressobj_value();
        console.log( 'assicurato_polizza', this.prareg.has('assicurato_polizza') );
    },
    data() {
        return {
            root_key                  : 'root_0',
            schema_compagnia_subtab   : schemas._compagnia_subtab,
            schema_assicurato_subtab  : schemas._assicurato_subtab,
            schema_agenzia_subtab     : schemas._agenzia_subtab,
            schema_ispettorato_subtab : schemas._ispettorato_subtab,
            schema_liquidatore_subtab : schemas._liquidatore_subtab,
            schema_polizza_subtab     : schemas._polizza_subtab,
            compagnia_key             : 'compagnia_0',
            assicurato_key            : 'assicurato_0',
            agenzia_key               : 'agenzia_0',
            ispettorato_key           : 'ispettorato_0',
            liquidatore_key           : 'liquidatore_0',
            assicurato_referente_key  : 'assicurato_referente_0',
            assicurato_polizza_key    : 'assicurato_polizza_0',
            assicurato_si             : false,
            assicurato_referente_si   : false,
            assicurato_polizza_si     : false,
            compagnia_si              : false,
            agenzia_si                : false,
            ispettorato_si            : false,
            liquidatore_si            : false,
            unlink_payload            : {},
            // gestione codice fiscale
            cf_payload                : { sesso: null, data_nascita: null, comune_nascita_id: null, first_name: null, last_name: null },
            cf_schema                 : schemas._codfisc,
            classes                   : {},
        };
    },
}

</script>

<template>

    <div :key="root_key">

        <div :key="main_key">

        <b-row>
            <b-col cols="6" style="">

                <!-- ASSICURATO -->
                <div v-if="!prareg.has('assicurato')" style="margin-bottom: 15px">
                    <b-link @click="init_registry([ 'Persona fisica', 'Persona giuridica' ], 'assicurato')">
                        Assicurato <icon name="plus-circle" style="margin-bottom: 3px"/>
                    </b-link>
                </div>
                <div v-else-if="prareg.has('assicurato')">
                    <div style="min-height: 420px;" class="dati_box" :class="classes.assicurato || ''" @mouseover="assicurato_si = true" @mouseleave="assicurato_si = false">
                        <b-row
                            @click="edit_registry('assicurato')"
                            >
                            <b-col cols="9">
                                <h6 class="block_label">Assicurato</h6>
                            </b-col>
                            <b-col cols="3" class="text-right">
                                <icon v-if="assicurato_si" name="times-circle" variant="danger" style="color: red; margin-bottom: 3px; margin-right: 10px" @click.stop="unlink('assicurato')" />
                            </b-col>
                        </b-row>
                        <bnvform
                            ref        = "form_assicurato"
                            name       = "_assicurato_subtab"
                            :or_data   = "copy( pra.pregs.assicurato )"
                            :schema    = "schema_assicurato_subtab"
                            :options   = "{}"
                            :record    = "copy( pra.pregs.assicurato )"
                            :inline    = "true"
                            :submit    = "false"
                            :key       = "assicurato_key"
                            @change    = "value_changed"
                            @cf-button = "cf_button"
                            @ad-button = "ad_button"
                            @submitted = "save_all"
                        />
                    </div>
                </div>

                <!-- REFERENTE ASSICURATO -->
                <div v-if="!prareg.has_assicurato_referente" style="margin-bottom: 15px; margin-top: 15px;">
                    <b-link @click="init_registry([ 'Persona fisica', 'Persona giuridica' ], 'assicurato_referente')" :class="prareg.has('assicurato') ? '' : 'link_disabled'">
                        Referente dell'Assicurato <icon name="plus-circle" style="margin-bottom: 3px"/>
                    </b-link>
                </div>
                <div v-else-if="prareg.has_assicurato_referente">
                    <div style="min-height: 200px;" class="dati_box" :class="classes.assicurato_referente || ''" @mouseover="assicurato_referente_si = true" @mouseleave="assicurato_referente_si = false">
                        <b-row @click="edit_registry('assicurato_referente')">
                            <b-col cols="9">
                                <h6 class="block_label">Referente dell'Assicurato</h6>
                            </b-col>
                            <b-col cols="3" class="text-right">
                                <icon v-if="assicurato_referente_si" name="times-circle" variant="danger" style="color: red; margin-bottom: 3px; margin-right: 10px" @click.stop="unlink('assicurato_referente')" />
                            </b-col>
                        </b-row>
                        <bnvform
                            ref        = "form_assicurato_referente"
                            :name      = "assicurato_referente_template"
                            :schema    = "assicurato_referente_schema"
                            :options   = "{}"
                            :record    = "prareg.assicurato_referente"
                            :inline    = "true"
                            :submit    = "false"
                            :key       = "assicurato_referente_key"
                            @submitted = "save_all"
                        />
                    </div>
                </div>

                <!-- POLIZZA ASSICURATO -->
                <div v-if="!prareg.has('assicurato_polizza')" style="margin-bottom: 15px; margin-top: 15px;">
                    <b-link @click="init_registry([ 'Polizza' ], 'assicurato_polizza')":class="prareg.has('assicurato') ? '' : 'link_disabled'">
                        Polizza dell'Assicurato <icon name="plus-circle" style="margin-bottom: 3px"/>
                    </b-link>
                </div>
                <div v-else-if="prareg.has('assicurato_polizza')">
                    <div style="min-height: 200px;" class="dati_box" :class="classes.assicurato_polizza || ''" @mouseover="assicurato_polizza_si = true" @mouseleave="assicurato_polizza_si = false">
                        <b-row @click="edit_registry('assicurato_polizza')">
                            <b-col cols="9">
                                <h6 class="block_label">Polizza dell'Assicurato</h6>
                            </b-col>
                            <b-col cols="3" class="text-right">
                                <icon v-if="assicurato_polizza_si" name="times-circle" variant="danger" style="color: red; margin-bottom: 3px; margin-right: 10px" @click.stop="unlink('assicurato_polizza')" />
                            </b-col>
                        </b-row>
                        <bnvform
                            ref        = "form_assicurato_polizza"
                            name       = "_polizza_subtab"
                            :schema    = "schema_polizza_subtab"
                            :options   = "{}"
                            :record    = "prareg.assicurato_polizza"
                            :inline    = "true"
                            :submit    = "false"
                            :key       = "assicurato_polizza_key"
                            @submitted = "save_all"
                        />
                    </div>
                </div>

            </b-col>
            <b-col cols="6" style="">

                <!-- COMPAGNIA -->
                <div v-if="!prareg.has('compagnia')" style="margin-bottom: 15px; margin-top: 15px;">
                    <b-link @click="init_registry('Compagnia', 'compagnia')">
                        Compagnia Assicuratrice <icon name="plus-circle" style="margin-bottom: 3px"/>
                    </b-link>
                </div>
                <div v-else-if="prareg.has('compagnia')">
                    <div style="min-height: 100px;" class="dati_box" :class="classes.compagnia || ''" @mouseover="compagnia_si = true" @mouseleave="compagnia_si = false">
                        <b-row @click="edit_registry('compagnia')">
                            <b-col cols="9">
                                <h6 class="block_label">Compagnia Assicuratrice</h6>
                            </b-col>
                            <b-col cols="3" class="text-right">
                                <icon v-if="compagnia_si" name="times-circle" variant="danger" style="color: red; margin-bottom: 3px; margin-right: 10px" @click.stop="unlink('compagnia')" />
                            </b-col>
                        </b-row>
                        <bnvform
                            ref        = "form_compagnia"
                            name       = "_compagnia_subtab"
                            :schema    = "schema_compagnia_subtab"
                            :options   = "{}"
                            :record    = "pra.pregs.compagnia"
                            :inline    = "true"
                            :submit    = "false"
                            :key       = "compagnia_key"
                            @submitted = "save_all"
                        />
                    </div>
                </div>

                <!-- AGENZIA -->
                <div v-if="!prareg.has('agenzia')" style="margin-bottom: 15px; margin-top: 15px;">
                    <b-link @click="init_registry('Agenzia', 'agenzia')" :disabled="!prareg.has('compagnia')" :class="prareg.has('compagnia') ? '' : 'link_disabled'">
                        Agenzia <icon name="plus-circle" style="margin-bottom: 3px"/>
                    </b-link>
                </div>
                <div v-else-if="prareg.has('agenzia')">
                    <div style="min-height: 200px;" class="dati_box" :class="classes.agenzia || ''" @mouseover="agenzia_si = true" @mouseleave="agenzia_si = false">
                        <b-row @click="edit_registry('agenzia')">
                            <b-col cols="9">
                                <h6 class="block_label">Agenzia</h6>
                            </b-col>
                            <b-col cols="3" class="text-right">
                                <icon v-if="agenzia_si" name="times-circle" variant="danger" style="color: red; margin-bottom: 3px; margin-right: 10px" @click.stop="unlink('agenzia')" />
                            </b-col>
                        </b-row>
                        <bnvform
                            ref        = "form_agenzia"
                            name       = "_agenzia_subtab"
                            :schema    = "schema_agenzia_subtab"
                            :options   = "{}"
                            :record    = "pra.pregs.agenzia"
                            :inline    = "true"
                            :submit    = "false"
                            :key       = "agenzia_key"
                            @submitted = "save_all"
                        />
                    </div>
                </div>

                <!-- ISPETTORATO -->
                <div v-if="!prareg.has('ispettorato')" style="margin-bottom: 15px; margin-top: 15px;">
                    <b-link @click="init_registry('Ispettorato', 'ispettorato')" :disabled="!prareg.has('compagnia')" :class="prareg.has('compagnia') ? '' : 'link_disabled'">
                        Ispettorato Sinistri <icon name="plus-circle" style="margin-bottom: 3px"/>
                    </b-link>
                </div>
                <div v-else-if="prareg.has('ispettorato')">
                    <div style="min-height: 200px;" class="dati_box" :class="classes.ispettorato || ''" @mouseover="ispettorato_si = true" @mouseleave="ispettorato_si = false">
                        <b-row @click="edit_registry('ispettorato')">
                            <b-col cols="9">
                                <h6 class="block_label">Ispettorato Sinistri</h6>
                            </b-col>
                            <b-col cols="3" class="text-right">
                                <icon v-if="ispettorato_si" name="times-circle" variant="danger" style="color: red; margin-bottom: 3px; margin-right: 10px" @click.stop="unlink('ispettorato')" />
                            </b-col>
                        </b-row>
                        <bnvform
                            ref        = "form_ispettorato"
                            name       = "_ispettorato_subtab"
                            :schema    = "schema_ispettorato_subtab"
                            :options   = "{}"
                            :record    = "pra.pregs.ispettorato"
                            :inline    = "true"
                            :submit    = "false"
                            :key       = "ispettorato_key"
                            @submitted = "save_all"
                        />
                    </div>
                </div>

                <!-- LIQUIDATORE -->
                <div v-if="!prareg.has('liquidatore')" style="margin-bottom: 15px; margin-top: 15px;">
                    <b-link @click="init_registry('Liquidatore', 'liquidatore')" :disabled="!prareg.has('compagnia')" :class="prareg.has('compagnia') ? '' : 'link_disabled'">
                        Liquidatore <icon name="plus-circle" style="margin-bottom: 3px"/>
                    </b-link>
                </div>
                <div v-else-if="prareg.has('liquidatore')">
                    <div style="min-height: 100px;" class="dati_box" :class="classes.liquidatore || ''" @mouseover="liquidatore_si = true" @mouseleave="liquidatore_si = false">
                        <b-row @click="edit_registry('liquidatore')">
                            <b-col cols="9">
                                <h6 class="block_label">Liquidatore</h6>
                            </b-col>
                            <b-col cols="3" class="text-right">
                                <icon v-if="liquidatore_si" name="times-circle" variant="danger" style="color: red; margin-bottom: 3px; margin-right: 10px" @click.stop="unlink('liquidatore')" />
                            </b-col>
                        </b-row>
                        <bnvform
                            ref        = "form_liquidatore"
                            name       = "_liquidatore_subtab"
                            :schema    = "schema_liquidatore_subtab"
                            :options   = "{}"
                            :record    = "pra.pregs.liquidatore"
                            :inline    = "true"
                            :submit    = "false"
                            :key       = "liquidatore_key"
                            @submitted = "save_all"
                        />
                    </div>
                </div>

            </b-col>
        </b-row>

        </div>

        <modal-association
            ref             = "modal_association"
            :field_label    = "field_label"
            :customer_id    = "customer_id"
            :codice_pratica = "codice_pratica"
            @selected       = "registry_selected"
        />

        <!-- modal new confirm-unlink -->
        <b-modal
            id          = "confirm-unlink"
            title       = "Conferma operazione"
            @ok         = "unlink_dependencies"
            button-size = "sm"
            >
            <div style="width: 80%; margin: auto">
                Rimuovere l'anagrafica?
                <div v-if="unlink_payload.dependencies && unlink_payload.dependencies.length > 0" style="margin-top: 5px;">
                    Verranno rimosse anche le anagrafiche collegate:
                    <ul>
                        <li v-for="dep in unlink_payload.dependencies" :key="'dep_' + dep">{{ dep }}</li>
                    </ul>
                </div>
            </div>
        </b-modal>

        <!-- modal Address Data -->
        <b-modal
            :id         = "'modal-address-data-' + signature"
            title       = "Indirizzo"
            button-size = "sm"
            >
            <bnvform
                name       = "_address_data"
                :schema    = "ad_schema"
                :options   = "{}"
                :record    = "addressobj"
                :submit    = "false"
                :inline    = "true"
                ref        = "modal_ad"
                layout     = "single"
                @submitted = "save_ad"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_ad_form">
                      Salva
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <!-- modal CF -->
        <b-modal
            id          = "modal-cf"
            title       = "Dati relativi al Codice Fiscale"
            button-size = "sm"
            >
            <bnvform
                name       = "_codfisc"
                :schema    = "cf_schema"
                :options   = "{}"
                :record    = "cf_payload"
                :submit    = "false"
                :inline    = "true"
                ref        = "modal_cf"
                layout     = "single"
                @submitted = "save_cf_data"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_cf_form">
                      Salva
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <fab-button
            v-if      = "prareg.has('assicurato') || prareg.has('compagnia')"
            main_icon = "save"
            bg_color  = "#218838"
            @save     = "save_all"
        />

    </div>

</template>

<style>
</style>

