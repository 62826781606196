    <script>

import { mapActions } from 'vuex';

export default {
    name: 'relation-table',
    props: [ 'rels', 'action', ],
    components : {},
    computed : {
        rows() {
            var rows = {};
            for ( var i = 0; i < this.rels.length; i++ ) {
                var rel = this.rels[ i ];
                if ( this.deleted_ids.includes( rel.relation.id ) ) { continue; }
                if ( !rows.hasOwnProperty( rel.label ) ) { rows[ rel.label ] = []; }
                rows[ rel.label ].push( rel );
            }
            return this.order_obj( rows );
        },
    },
    watch: {},
    methods : {
        ...mapActions( 'registry', { delete_relation: 'remove_relation' } ),
        remove_relation( item ) {
//          var confirm_options = {
//              buttonSize: 'sm', autoFocusButton: 'ok', okVariant: 'info', okTitle: 'Confermo', cancelVariant: 'secondary',
//              cancelTitle: 'Annulla', noCloseOnEsc: true, noCloseOnBackdrop: true,
//          };
//          this.$bvModal.msgBoxConfirm('Eliminare la relazione?', confirm_options)
//              .then(value => {
//                  if ( value ) {
//                      this.delete_relation( item.id );
//                      setTimeout( () => {
//                          this.deleted_ids.push( item.id );
//                      }, 500);
//                  }
//              })
//              .catch(err => {
//                  console.log( err );
//              });
            this.delete_relation( item.id );
            setTimeout( () => {
                this.deleted_ids.push( item.id );
            }, 500);
        },
        order_obj( input ) {
            return Object.keys( input ).sort().reduce(
                (obj, key) => { 
                    obj[key] = input[key]; 
                    return obj;
                }, 
                {}
            );
        },
    },
    created() {},
    data() {
        return {
            deleted_ids : [],
        }
    },
}

</script>

<template>
    <table style="width: 95%; margin: auto;">
        <tr v-for="relation in Object.keys( rows )" :key="'rel_table_row_' + relation">
            <td style="width: 20%;">{{ relation }}:</td>
            <td style="width: 80%">
                <b-badge v-for="item in rows[ relation ]" :key="item.key" :variant="item.variant" style="margin-right: 15px">
                    <strong>{{ item.item_label }}</strong>
                    <icon style="margin-left: 5px;" name="times-circle" :style="{ cursor: action === 'edit' ? 'pointer' : 'default' }" @click="remove_relation( item.relation )"/>
                </b-badge>
            </td>
        </tr>
    </table>
</template>

<style>
</style>

