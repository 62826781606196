<script>

import draggable from 'vuedraggable';
const config  = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export default {
    name: 'pratica-columnright',
    props: [ 'items', ],
    components: {
        draggable,
    },
    watch: {
    },
    created() {
    },
    computed: {
        backend_url() { return config.backend_url },
    },
    methods: {
        change_column() {
            this.$emit( 'change-column', this.items );
        },
        is_even(n) { // check se è un numero pari
            n = Number(n);
            return n === 0 || !!(n && !(n%2));
        },
        remove_image( image_id ) {
            this.$emit( 'remove-image', image_id );
        },
    },
    data() {
        return {
        }
    },
};

</script>

<template>

        <!-- <div class="text-center">
            <b-button :disabled="items.length === 0" variant="outline-light" size="sm"><b-icon-tools></b-icon-tools> stampa report</b-button>
        </div> -->
        <draggable
            :list   = "items"
            @change = "change_column"
            group   = "people"
            style   = "height: 100%;"
            >
            <b-col v-for="(element, index) in items" xs="12" :key="element.id">
                <div style="background-color: white; padding: 5px; padding-top: 10px;">
                    <h6 v-if="is_even( index )" class="text-center" style="color: grey; padding-bottom: 5px;">Pagina {{ index / 2 + 1 }}</h6>
                    <b-img
                        v-if="element.task_image.value.thumb_url"
                        center
                        thumbnail
                        fluid
                        :src   = "backend_url + element.task_image.value.thumb_url.replace(/^\//, '')"
                        @click = "remove_image( element.id )"
                        >
                    </b-img>
                    <b-img
                        v-else-if="element.task_image.value.thumb_url === null"
                        center
                        thumbnail
                        fluid
                        :src   = "backend_url + 'icons/no-image.png'"
                        @click = "remove_image( element.id )"
                        >
                    </b-img>
                    <figcaption class="figure-caption text-center">
                        <span>
                            {{ element.task_image.value.description || 'description assente' }}
                        </span>
                    </figcaption>
                    <div style="height: 15px;"></div>
                </div>
                <div v-if="is_even( index ) === false" class="bg-dark" style="height: 25px;"></div>
            </b-col>
        </draggable>

</template>

