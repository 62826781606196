<script>

import Vue from 'vue';
Vue.use( require('vue-filter-pretty-bytes') );
import VueContext from 'vue-context';
const moment = require('moment-timezone');

import bnvlist from '@/vue-model-mirror/bnvlist';
import bnvform from '@/vue-model-mirror/bnvform';
const schemas = require('@/schemaconfig');
const config  = require('@/config.js')[ process.env.NODE_ENV || 'development' ];
const options = require('@/listconfig');

export default {
    name: 'filestable',
    components: {
        bnvlist,
        bnvform,
        VueContext,
    },
    props: [ 'codice_pratica', ],
    mounted () {
    },
    watch: {
    },
    computed: {
        fields () {        // default: undefined
            if ( this.options.hasOwnProperty('fields') ) {
                return this.options.fields.map( column_field => {
                    if ( column_field.hasOwnProperty('class') === false ) {
                        column_field['class'] = [];
                    } else if ( typeof column_field['class'] === 'string' ) {
                        column_field['class'] = [ column_field['class'] ];
                    }
                    if ( column_field.hasOwnProperty( 'screen' ) ) {
                        var classes = [];
                        var screen_classes = this._get_column_class( column_field.screen );
                        for ( var i = 0; i < screen_classes.length; i++ ) {
                            var class_string = typeof column_field['class'][ i ] === 'string' ? column_field['class'][ i ] : '';
                            classes.push( class_string + ' ' + screen_classes[ i ]  );
                        }
                        column_field['class'] = classes;
                        delete column_field.screen;
                    }
                    return column_field;
                });
            }
            return undefined;
        },
        order: {
            get: function() {
                return this.$store.state[ this.schema ].order;
            },
            set: function(value) {
                this.$store.dispatch(`${this.schema}/set_order`, value);
            }
        },
        qry_options () {
            var options = {};
            if ( this.options.hasOwnProperty('include') ) { options.include = this.options.include; }
            if ( this.options.hasOwnProperty('initialfilter') ) {
                Object.keys( this.options.initialfilter ).map( x => { options[ x ] = this.options.initialfilter[ x ]; } );
            }
            return options;
        },
        initialfilter () {
            return this.options.hasOwnProperty('initialfilter') ? this.options.initialfilter : {};
        },
        previous_folder_ids: {
            get() {
                return this.$store.state[ this.schema ].previous_folder_ids;
            },
            set(folder_ids) {
                this.$store.dispatch(`${ this.schema }/set_folder_ids`, folder_ids);
            },
        }
    },
    created () {
        this.options = options[ this.schema_name ];
        this.options.initialfilter = {
            codice_pratica : this.codice_pratica,
            parentId       : this.parent_id,
        };
        //console.log('listpage/created', Object.keys( this.qry_options ).length );
        if ( this.options.hasOwnProperty('order') && this.order === null ) { this.$store.dispatch(`${ this.schema_name }/set_order`, this.options.order); }
        var data = {
            options: {
                codice_pratica: this.codice_pratica,
                parentId: null,
                include : true,
            }
        }
        if ( this.$store.state.folder.freeze === false ) {
            this.previous_folder_ids = [ { text: String( this.codice_pratica ), 'value': null } ];
            this.$store.dispatch('folder/clear', data);
        } else {
            this.$store.dispatch('folder/freeze');
        }
    },
    methods: {
        goto_folder( folder_id, folder_name ) {
            if ( this.locked === false ) {
                this.$store.dispatch( `${ this.schema_name }/add_folder_id`, { 'value': folder_id, text: String(folder_name) } );
                //this.previous_folder_ids.push( { 'value': folder_id, text: String(folder_name) } );
                this.parent_id = folder_id;
                var data = {
                    options: {
                        codice_pratica : this.codice_pratica,
                        parentId       : folder_id,
                        include        : true,
                        order          : 'name ASC'
                    },
                };
                this.$store.dispatch('folder/get_all', data);
            }
        },
        get_link( image_id ) {
            this.$store.dispatch( 'task/get_link', image_id )
        },
        toggle_modal(modal_id = 'modal-filter') {
            modal_id = modal_id.constructor.name === 'MouseEvent' ? 'modal-filter' : modal_id;
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        set_focus( element_ref ) {
            this.$refs[ element_ref ].focus();
        },
        move_to_folder( data ) {
            var new_folders = [];
            for ( var i = 0; i < this.previous_folder_ids.length; i++ ) {
               var bc = this.previous_folder_ids[ i ];
               if ( bc.text === data.srcElement.innerText ) {
                   this.goto_folder( bc.value, bc.text );
                   this.previous_folder_ids = new_folders;
               }
               new_folders.push( bc );
            }
        },
        _get_column_class( screen ) {
            if ( screen === 'mobile' )  { return ['', '', 'd-none']; }
            if ( screen === 'tablet' )  { return ['', 'd-none d-md-table-cell', 'd-md-none']; }
            if ( screen === 'desktop' ) { return ['', 'd-none d-lg-table-cell', 'd-lg-none']; }
            return '';
        },
        row_selected( rows ) {
            this.$emit('row-selected', rows);
        },
        right_click( obj ) {
            this.$refs.menu.open( obj.event, { data: obj.row } );
        },
        context_action( action, obj ) {
            if ( action === 'rename' ) {
                var title = 'Rinomina ';
                title += obj.data.data.task_image_id === null ? 'Cartella' : 'File';
                this.modal_context_form.title       = title;
                this.modal_context_form.schema_name = 'rename';
                this.modal_context_form.schema      = schemas.rename;
                this.modal_context_form.record      = { name: obj.data.data.name, id: obj.data.data.id };
                this.modal_filter_key += 1; 
                this.modal_context_form.show = true;
                this.toggle_modal('modal-context-form');
            } else if ( action = 'delete' ) {
                var message = 'Eliminare ';
                if ( obj.data.data.task_image_id === null ) {
                    message += `la cartella ${ obj.data.data.name } e tutto il suo contenuto?`;
                } else {
                    message += `il file ${ obj.data.data.name }?`;
                }
                var options = { buttonSize: 'sm', autoFocusButton: 'ok', };
                this.$bvModal.msgBoxConfirm(message, options)
                    .then( res => { if ( res === true ) { this.$store.dispatch('folder/delete', obj.data.data.id); } } );
            }
        },
        submit_modal_context_form() {
            var payload = this.$refs['modal-context-form'].get_form_data();
            this.$store.dispatch('folder/rename', { payload, include: true });
        },
    },
    data () {
        return {
            schema_name         : 'folder',
            schema              : 'folder',
            locked              : false,
            parent_id           : null,
            modal_context_form  : { show: false },
            modal_filter_key    : 0,
        }
    },
};

</script>

<template>

    <div>
    
        <b-container fluid="xs">

            <b-row style="margin-top: 10px;">
                <b-col style="margin: 0; padding: 0;">
                    <template>
                        <b-breadcrumb :items="previous_folder_ids" @click="move_to_folder"></b-breadcrumb>
                    </template>
                </b-col>
            </b-row>

            <bnvlist
                schema           = "folder"
                storename        = "bnvform"
                :tableattributes = "{ 'no-local-sorting': true, 'no-sort-reset': true, 'responsive': true, 'small': true, 'borderless': false, 'select-mode': 'range', 'selectable': true }"
                size             = "sm"
                :link            = "null"
                :skip            = "[]"
                :fields          = "fields"
                primarykey       = "id"
                :qryoptions      = "qry_options"
                :details         = "false"
                :edit            = "false"
                :paginate        = "false"
                :initialfilter   = "initialfilter"
                ref              = "listbnv"
                @goto_folder     = "goto_folder"
                @get_link        = "get_link"
                @row-selected    = "row_selected"
                @right-click     = "right_click"
            ></bnvlist>

            <!-- menu contestuale -->
            <vue-context ref="menu">
                <template slot-scope="child">
                    <li style="cursor: pointer">
                        <a @click.prevent="context_action( 'rename', child )">Rinomina</a>
                    </li>
                    <li style="cursor: pointer">
                        <a @click.prevent="context_action( 'delete', child )">Elimina</a>
                    </li>
                </template> 
            </vue-context>

            <!-- modal per le form del menu contestuale -->
            <b-modal
                id          = "modal-context-form"
                :title      = "modal_context_form.title"
                button-size = "sm"
                >
                <bnvform
                    v-if       = "modal_context_form.show === true"
                    :key       = "modal_filter_key"
                    :name      = "modal_context_form.schema_name"
                    :schema    = "modal_context_form.schema"
                    :options   = "{}"
                    :record    = "modal_context_form.record"
                    :submit    = "false"
                    layout     = "single"
                    inline     = "false"
                    :focus     = "true"
                    ref        = "modal-context-form"
                    @submitted = "submit_modal_context_form"
                ></bnvform>
                <template v-slot:modal-footer="{ ok, cancel, hide }">
                    <b-row><b-col>
                        <b-button size="sm" variant="success" @click="submit_modal_context_form">
                          OK
                        </b-button>
                    </b-col><!-- <b-col>
                        <b-button size="sm" variant="warning" @click="hide('clear')">
                          Reset
                        </b-button>
                    </b-col> --><b-col>
                        <b-button size="sm" variant="outline-secondary" @click="cancel()">
                          Chiudi
                        </b-button>
                    </b-col></b-row>
                </template>
            </b-modal>

        </b-container>

    </div>

</template>

<style>
    .breadcrumb {
        padding: 2px 15px !important;
    }
    .locked {
        opacity: 0.5;
    }
    .link-locked {
        cursor: default;
    }
    .table th {
        border-top: 0px !important;
    }
    .table {
        width: 100% !important;
    }
</style>
