//////////////////////////////

require('dotenv').config();
import Vue from 'vue';
import { store }  from '@/store';
import { router } from '@/helpers';
import { Loader } from "@googlemaps/js-api-loader";

import 'vue2-datepicker/index.css';
import './plugins/bootstrap-vue';
import './plugins/nprogress';
import './plugins/vue-context';
import './vue-model-mirror/plugins/list-css';
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
Vue.component('icon', Icon)

import App from '@/App.vue';
Vue.config.productionTip = false;

        let ak = 'AIzaSyCnn5il42PmELj_wZ1jlZqR0W9gBpqW7yQ';
        const loader = new Loader({
            apiKey    : ak,
            version   : 'weekly',
            libraries : ['places']
        });
        loader.load().then( () => {
            console.log( 'google maps library loaded' );
        });

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
});


