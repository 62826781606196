<script>

//import { Loader } from "@googlemaps/js-api-loader";
import VueGoogleAutocomplete from "vue-google-autocomplete";
const moment = require('moment');

import bnvbase from './bnvbase.vue';

export default {
    extends: bnvbase,
    props: [ 'placeholder', 'display_button_modal' ],
    components: { VueGoogleAutocomplete },
    methods: {
        get_address_data( item, itembis ) {
//            console.log('sono qui');
            item.name = this.name;
            item.provincia = itembis.address_components[4].short_name
            item.regione   = itembis.address_components[5].short_name
            this.$emit('change', item );
            this.reset = false;
            let formatted_address = itembis.formatted_address.replace(', Italia', '');
            this.model = formatted_address;
        },
        open_modal( open = false ) {
            var obj = { event_name: 'ad-button', payload: { open, name: this.name } }
            this.$emit('generic-event', obj );
        },
        unlock() {
            this.model = '';
            this.reset = true;
            setTimeout( () => {
                this.$refs[ 'input-' + this.signature ].focus();
            }, 200 );
        },
        blur() {
            this.$emit( 'blur', null );
        },
        get_placeholder() {
            return this._placeholder || 'Scrivi un indirizzo';
        }
    },
    created() {
        this.signature = moment().valueOf();
//      let ak = 'AIzaSyCnn5il42PmELj_wZ1jlZqR0W9gBpqW7yQ';
//      const loader = new Loader({
//          apiKey    : ak,
//          version   : "weekly",
//          libraries : ["places"]
//      });
//      loader.load().then( () => {
//          //console.log( google );
//      });
    },
    computed: {
    },
    data() {
        return {
            reset     : false,
            signature : null,
        };
    },
};

</script>

<template>

    <div class="form-group" :class="field_class" :style="field_style">
        <label v-if="display_label !== false" :for="'bnv-form-' + name" :class="label_class">{{ get_label() }}</label>
        <div :class="element_container_class">
            <b-input-group>
                <vue-google-autocomplete
                    v-if              = "edit === false || reset === true"
                    :id               = "'map-' + signature"
                    :ref              = "'input-' + signature"
                    classname         = "form-control"
                    placeholder      = "Scrivi un indirizzo"
                    v-on:placechanged = "get_address_data"
                    :disabled         = "disabled"
                    @blur             = "blur"
                    @keyup.esc        = "blur"
                />
                <b-input
                    v-if              = "edit === true && reset === false"
                    v-on:keyup.enter  = "enter_key"
                    :ref              = "'input-' + signature"
                    type              = "text"
                    :size             = "input_class"
                    v-bind:class      = "{ 'is-invalid': error_component }"
                    :placeholder      = "get_placeholder()"
                    :aria-label       = "name"
                    :aria-describedby = "name"
                    :id               = "'bnv-form-' + name"
                    v-model           = "model"
                    :disabled         = "true"
                />
                <b-input-group-append>
                    <b-button v-if="edit === true && reset === false" variant="outline-warning" @click="unlock()"><icon name="pen"/></b-button>
                    <b-button v-if="display_button_modal !== false" variant="outline-secondary" @click="open_modal('open')"><icon name="bars"/></b-button>
                </b-input-group-append>
            </b-input-group>
            <small v-if="helper" :id="'bnv-form-' + name + '-help'" class="form-text text-muted" v-html="_helper"></small>
            <div class="invalid-feedback">
                {{ get_error_message() }}
            </div>
        </div>
    </div>

</template>

<style>
    .pac-container { z-index: 10000 !important; }
</style>

