<script>

export default {

    name: 'registry-contacts',
    props: [ 'item' ],
    created() {},
    computed: {
        telefono() {
            let res = null;
            if ( this.item.telefono.length > 0 ) {
                res = this.item.telefono.map( x => {
                    return x.label ? `${ x.label }: ${ x.value }` : x.value;
                    return x.value;
                });
            }
            return res ? res.join(' / ') : null;
        },
        email() {
            let res = null;
            if ( this.item.email.length > 0 ) {
                res = this.item.email.map( x => {
                    return x.label ? `${ x.label }: ${ x.value }` : x.value;
                    return x.value;
                });
            }
            return res ? res.join(' / ') : null;
        },
    },

}

</script>

<template>

        <table>
            <tr v-if="telefono">
                <td class="small-td"><small style="">{{ telefono }}</small></td>
            </tr>
            <tr v-if="email">
                <td class="small-td"><small style="">{{ email }}</small></td>
            </tr>
            <!-- <tr v-if="indirizzo">
                <td class="small-td" v-if="name"><small style="font-weight: 600;">indirizzo:</small></td>
                <td class="small-td"><small style="padding-left: 4px;">{{ indirizzo }}</small></td>
            </tr> -->
        </table>

</template>

<style>
    .small-td {
        padding: 2px 5px !important;
    }
</style>

