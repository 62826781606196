//////////////////////////////

module.exports = (type) => {

    var schema = {
        codice_pratica: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'pratiche',
                key         : 'codice',
                constraints : true,
                store_name  : 'pratica',
            }
        },
        // luogo appuntamento
        luogo_appuntamento: {
            type: type.VIRTUAL,
            allowNull: true,
            field_type: 'google_address',
        },
        strada_luogo_appuntamento: {
            type: type.STRING(64),
            allowNull: true,
            defaultValue: null,
        },
        n_civ_luogo_appuntamento: {
            type: type.STRING(64),
            allowNull: true,
            defaultValue: null,
        },
        cap_luogo_appuntamento: {
            type: type.STRING(32),
            allowNull: true,
            defaultValue: null,
        },
        comune_luogo_appuntamento_id: {
            type: type.INTEGER,
            allowNull: true,
            defaultValue: null,
            references: {
                model       : 'comuni',
                key         : 'id',
                constraints : true,
                store_name  : 'comune_residenza',
                type        : 'autocomplete'
            },
        },
        citta_estero_luogo_appuntamento: {
            type: type.STRING(128),
            allowNull: true,
            defaultValue: null,
        },
        nazione_luogo_appuntamento: {
            type: type.STRING(128),
            allowNull: true,
            defaultValue: null,
        },
        altro_luogo_appuntamento: {
            type: type.STRING(32),
            allowNull: true,
            defaultValue: null,
        },
        latitudine_luogo_appuntamento: {
            type: type.DECIMAL(10,6),
            allowNull: true,
            defaultValue: null,
        },
        longitudine_luogo_appuntamento: {
            type: type.DECIMAL(10,6),
            allowNull: true,
            defaultValue: null,
        },
        //
        tasks: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: [],
        },
        compagnia: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        assicurato: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        controparte: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        tipo_danno: {
            type: type.STRING(2048),
            allowNull: true,
            defaultValue: null,
        }
    };

    return schema;
}
