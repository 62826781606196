

module.exports = {
    cols: [
          { 
              row1: {
                  field1: 'denominazione',
                  field2: 'codice',
              },
              field1: 'telefono',
              field2: 'email',
          },
    ]
};

