/////////////////////////////////////////////////

class relation {

    constructor( relation_label, item, variant, relation ) {
        this.relation_label = relation_label;
        this.item           = item;
        this.variant        = variant;
        this.relation       = relation;
    }

    // public methods ///////////////////////////

    // getters & setters ////////////////////////

    get label() {
        if ( this.relation_label === 'referente' && this.variant === 'secondary' ) { return 'referente di'; }
        if ( this.relation_label === 'polizza' && this.variant === 'secondary' ) { return 'polizza di'; }
        return this.relation_label;
    }

    get key() {
        return `${ this.relation_label }_${ this.item.id }`;
    }

    get item_label() {
        return this.item.label;
    }

    get obj() {
        return {
            father_id      : this.relation.father_id || null,
            child_id       : this.relation.child_id || null,
            father_role_id : this.relation.father_role_id,
            child_role_id  : this.relation.child_role_id,
        };
    }

}

export default relation;

