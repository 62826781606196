////////////////////

import axios from 'axios';
const moment = require('moment');
import base_module from '@/vue-model-mirror/stores/base.module';
import { authHeader } from '@/helpers';
const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

var options = {
    model_name    : 'ricerca',
    send_alert    : false,
    default_order : 'codice ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    title_form     : 'Tipologie di Pratica',
    items          : null,
    page           : 1,
    task_overrided : false,
    // filters
    filters        : {
        show_mine             : true,
        show_map              : false,
        no_luogo_appuntamento : false,
        // Filtri aggiuntivi 
        primo_contatto_incompleto : false,
        appuntamento_done : false,
        ispezione_done : false,
        mostra_assegnati : false,
    },
    date_filters      : [ { field: '', range: null } ],
    ricerca_id     : '',
    edit_mode      : false,
    regione_id     : '',
    provincia_id   : '',
    comune_id      : '',
    // drag and drop
    moved          : false,
    is_draggable   : true,
    // report csv
    clear          : true, 
    ws             : null,
    report_loader  : false,
    message        : '',
    variant        : '',
};

const actions = {
    ...base.actions,
    make_report({ commit, dispatch }, data) {
        commit('set_clear_report');
        if ( config.backend_url.includes( 'https' ) ) {
            var ws = new WebSocket( config.backend_url.replace('https://', 'wss://') + `ws/ricerca/?code=${ data.ricerca_id }` );
        } else if ( config.backend_url.includes( 'http' ) ) {
            var ws = new WebSocket( config.backend_url.replace('http://', 'ws://') + `ws/ricerca/?code=${ data.ricerca_id }` );
        }
        commit('set_ws', ws );
        ws.onmessage = msg => commit('in_message', msg);
        dispatch('start_report', data.ricerca_id);
    },
    start_report( { dispatch }, ricerca_id ) {
        let options = { headers: authHeader() };
        let url = config.backend_url + 'model/ricerca/' + String( ricerca_id ) + '/csv';
        axios.post( url, {}, options )
            .then( res => {
            })
            .catch( error => {
                commit('error', error)
            });
 
    },
    reset_report( { commit } ) { commit('reset_report') },
    //set_codice_pratica( { commit }, codice_pratica ) { commit('set_codice_pratica', codice_pratica) },
    set_clear_report( { commit } ) { commit('set_clear_report') },
    //set_params( { commit }, params ) { commit('set_params', params); },
    get_all( { commit, state, getters, rootGetters }, _data = { options: {} } ) { // ok
        let ricerca_id = rootGetters['appuntamento/ricerca_id'];
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        if ( data.params ) { data.options = data.params; }
        commit('start_request');
        data.options.order = getters.order;
        var userjson = localStorage.getItem('user');
        var userobj = JSON.parse( userjson );
        let customer_ids = userobj.my_customers.map( x => x.id );
        data.options.customer_id__in = customer_ids.join(',');
        Object.keys( state.filters ).map( key => { 
            if ( key === 'date_filters' ) {
                let date_filters = state.filters.date_filters;
                for ( var i = 0; i < state.filters.date_filters.length; i++ ) {
                    data.options[ date_filters[ i ].field ] = [
                        moment( date_filters[ i ].range[0] ).format('YYYY-MM-DD'),
                        moment( date_filters[ i ].range[1] ).format('YYYY-MM-DD'),
                    ].join('|');
                }
            } else {
                data.options[ key ] = state.filters[ key ];
            }
         });
        let url = config.backend_url + 'model/ricerca/' + String( ricerca_id );
        let params_key = Object.keys( data.options );
        if ( params_key.length > 0 ) {
            url += '?';
            params_key.map( key => { url += `${ key }=${ data.options[ key ] }&`; });
        }
        let options = { headers: authHeader() };
        axios.get( url, options )
            .then( res => {
                let response = res.data;
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                   commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response.data)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
    change_list_order( { commit }, data ) {
        let payload = { new_order: data.new_order, old_order: data.old_order, codice_pratica: data.codice_pratica };
        let url = config.backend_url + 'model/ricerca/' + String( data.ricerca_id );
        let options = { headers: authHeader() };
        axios.patch( url, payload, options )
//          .then( response => {
//              console.log( response.data );
//          })
//          .catch( error => {
//              commit('error', error)
//          });
            .then( res => {
                let response = res.data;
                commit('success_list', response.data);
                commit('set_total', response.tot);
                commit('set_page', response.page);
                commit('set_rows_per_page', response.rows_per_page);
                commit('set_moved');
            })
            .catch( error => {
                commit('error', error)
            });
    },
    set_ricerca_id( { commit, dispatch }, value ) { commit('set_ricerca_id', value); dispatch('ricerca/set_active_obj', value, { root: true }); },
    set_edit_mode( { commit }, value ) { commit('set_edit_mode', value); },
    override_task( {commit}, task ) { commit('overridetask', task); },
    override_incarico( {commit}, incarico ) { commit('overrideincarico', incarico); },
    save_filter_value( { commit }, data ) { commit( 'save_filter_value', data ); },
    remove_filter_value( { commit }, key ) { commit( 'remove_filter_value', key ); },
    set_date_filters( { commit }, value ) { commit( 'set_date_filters', value ); },
};

const mutations = {
    ...base.mutations,
    save_filter_value( state, data ) {
        let key = Object.keys( data )[0];
        state[ key ] = data[ key ];
    },
    remove_filter_value( state, key ) {
        state[ key ] = '';
    },
    set_ricerca_id( state, value ) { state.ricerca_id = value; },
    set_edit_mode( state, value ) { state.edit_mode = value; },
    success_list( state, items ) {
        state.items    = items.map( x => { x.selected = false; return x; } );
        state.loading  = false;
        state.loading_by_id = false;
        state.error    = false;
        state.executed = true;
    },
    overridetask(state, task) {
        if ( state.items ) {
            for ( var i = 0; i < state.items.length; i++ ) {
                if ( state.items[ i ].codice !== task.codice_pratica ) { continue; }
                for ( var x = 0; x < state.items[ i ].tasks.length; x++ ) {
                    if ( state.items[ i ].tasks[ x ].id !== task.id ) { continue; }
                    state.items[ i ].tasks[ x ] = task;
                    break;
                }
            }
        }
        state.task_overrided = true;
        setTimeout( () => { state.task_overrided = false; }, 250 );
        var found = false;
    },
    overrideincarico(state, incarico) {
        if ( state.items ) {
            for ( var i = 0; i < state.items.length; i++ ) {
                if ( state.items[ i ].codice !== incarico.codice_pratica ) { continue; }
                state.items[ i ].pregs.incarico = incarico;
                break;
            }
        }
    },
    set_moved( state, value = true ) {
        state.force_reload = false;
        state.moved = value;
        setTimeout( () => { state.moved = false; }, 250 );
    },
    addfilter( state, filter ) {
        var index = Object.keys( filter )[0];
        state.filters[ index ] = filter[ index ];
        state.is_draggable = state.filters.show_mine === false && Object.keys( state.filters ).length === 1;
    },
    removefilter( state, index ) {
        delete state.filters[ index ];
        state.is_draggable = state.filters.show_mine === false && Object.keys( state.filters ).length === 1;
    },
    set_clear_report( state ) {
        state.clear = !state.clear;
    },
    set_ws( state, ws ) {
        state.ws = ws;
    },
    in_message( state, msg ) {
        var message = JSON.parse(msg.data);
        if ( message.hasOwnProperty('start') === true ) {
            state.report_loader = true;
        } else if ( message.hasOwnProperty('stop') === true ) {
            state.report_loader = false;
            state.ws.close();
            state.ws = null;
        } else {
            state.report_loader = true;
        }
        if ( message.level === 'info' ) {
            state.variant = '';
            if ( message.hasOwnProperty('success') === true ) {
                state.variant = 'success';
            }
        } else if ( message.level === 'notice' ) {
            state.variant = 'warning';
            state.report_loader = false;
        } else if ( message.level === 'crit' ) {
            state.variant = 'danger';
            state.report_loader = false;
        } else if ( message.level === 'error' ) {
            state.variant = 'danger';
            state.report_loader = false;
        }
        state.message = message;
    },
    reset_report( state ) {
        var message_class = 'Exportazione Itinerario';
        state.report_loader  = false;
        state.message        = { 'class': message_class, action: 'Inizializzazione in corso' };
        state.codice_pratica = null;
        state.variant        = '';
        state.ws             = null;
        state.params         = {};
    },
    set_date_filters( state, value ) { state.date_filters = value; console.log( 'date_filters', state.date_filters ); },
};
const getters = {
    ...base.getters,
    ricerca_id: state => state.ricerca_id,
}


export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
    getters,
};

