////////////////////

import axios from 'axios';
import base_module from '@/vue-model-mirror/stores/base.module';
import { authHeader } from '@/helpers';
const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

var options = {
    model_name    : 'cga',
    send_alert    : false,
    default_order : 'id ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    filters: {}
};

const actions = {
    ...base.actions,
    get_all( { commit, state, getters }, _data = { options: {} } ) { // ok
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        commit('start_request');
        data.options.order = getters.order;
        //Object.keys( state.filters ).map( key => { data.options[ key ] = state.filters[ key ]; } );
        state.service.get_all(getters.model_name, data.options)
            .then( response => {
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
    send_relations( { commit, getters, dispatch }, payload ) {
        var url = config.backend_url + `model/${ getters.model_name }/relations`;
        var options = { headers: authHeader() };
        payload.model = 'polizza_cga';
        axios.post( url, payload, options )
            .then( response => {
                dispatch( 'registry/set_cga', response.data, { root: true } );
            })
            .catch( error => {
                commit('error', error)
            });
    },
};


export default {
    namespaced : true,
    ...base,
    state,
    actions,
};

