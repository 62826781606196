//////////////////////////////

module.exports = (type) => {
    var schema = {
        description: {
            type: type.TEXT,
            allowNull: true,
            placeholder: 'descrizione assente',
            label: 'Descrizione',
        },
        image_id: {
            type: type.VIRTUAL,
            allowNull: false,
            hidden: true,
        },
    };

    return schema;
}


