////////////////////

import { bnvform_service } from '@/services';

export const userletter = {
    namespaced: true,
    state: {
        title_form    : 'Utente',
        users         : {},
        error         : false,
    },
    actions: {
        get_all({commit, state, rootGetters}, data = { options: {} }) {
            var customers = rootGetters['statemanager/customers'].map( x => x.id );
            if ( Object.keys( state.users ).length === 0 ) {
                bnvform_service.get_all('user', { order: 'id ASC', 'my_customers.id__in': customers })
                    .then( response => {
                        commit('set_letter', response);
                    })
                    .catch( error => {
                        commit('error', error)
                    });
           }
        },
        clear( { commit } ) { commit('clear'); },
    },
    mutations: {
        set_letter( state, data ) {
            for ( var i = 0; i < data.length; i++ ) { 
                state.users[ data[ i ].id ] = data[ i ].sigla;
            }
        },
        clear( state ) {
            state.users = {};
            state.error = false;
        },
    },
    getters: {
        is_mine: state => user_id => {
            return state.users.hasOwnProperty( user_id );
        }
    },
}

