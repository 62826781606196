<script>

const moment = require('moment');

import { mapActions, mapState/*, mapGetters*/ } from 'vuex';
import modal_association from './components/modal_association';
import entry_selector from './components/entry_selector';
import bnvform from '@/vue-model-mirror/bnvform';
import fabButton from '@/components/fab_button';
const schemas = require('@/schemaconfig');
import Address from '@/registry/classes/address';

export default {
    name: 'pratica-subtab-base',
    props: [ 'codice_pratica' ],
    components : {
        bnvform,
        fabButton,
        'modal-association' : modal_association,
        'entry-selector'    : entry_selector,
    },
    computed : {
        ...mapState('pratica', {
            pra    : state => state.obj,
            prareg : state => state.prareg,
        }),
        ...mapState('registry_autocomplete', {
            executed : state => state.executed,
            items    : state => state.items,
        }),
        customer_id() {
            return this.pra.custodian || this.pra.owner;
        },
        has_pregs() {
            return this.pra.hasOwnProperty('pregs') && typeof this.pra.pregs === 'object';
        },
        ...mapState('pratica', {
            registry_setted : state => state.registry_setted,
        }),
        ...mapState('preg', {
            delete_executed          : state => state.delete_executed,
            update_all_executed      : state => state.update_all_executed,
            create_relation_executed : state => state.create_relation_executed,
            already_related          : state => state.already_related,
        }),
        ...mapState('registry_template', {
            registry_template : state => state.template_map,
            registry_template_ids : state => state.template_map_ids,
        }),
        ...mapState('registry_role', {
            registry_role : state => state.role_map,
            registry_role_ids : state => state.role_map_ids,
        }),
        loaded() { return this.$store.state.comune_residenza.loaded; },
    },
    watch: {
        already_related( new_value ) {
            if ( new_value === true ) {
                console.log( 'already_related' );
                this.make_toast( 'MODIFICA ANAGRAFICHE', 'L\'Anagrafica è già collegata alla pratica' );
            }
        },
        executed( new_value ) {
            if ( !new_value ) { return; }
            if ( !this.$refs.modal_association.modals['modal-relations'] ) {
                console.log('verifico se il numero degli items > 0');
                if ( this.items.length > 0 ) {
                    this.toggle_modal();
                } else {
                    // qui apro direttamente il modal per la creazione della nuova registry
                    console.log( 'qui devo aprire direttamente il modal di creazione' );
                    this.$refs.modal_association.$refs.add_registry_component_invisible.toggle_modal()
                    //return;
                }
                
            }
//          if ( this.items && this.items.length > 0 ) {
//              this.show_options = true;
//          } else {
//              this.show_options = false;
//              this.clear();
//          }
        },
        registry_setted( new_value ) {
            //console.log( 'registry_setted', new_value );
            if ( new_value ) {
                this.force_reload();
                this.addressobj = new Address();
                this.addressobj.store = this.$store;
                this.set_addressobj_value();
                this.reload('main_key');
            }
        },
        delete_executed( new_value ) {
            if ( new_value ) {
                this.force_reload();
                this.make_toast( 'MODIFICA ANAGRAFICHE', 'Anagrafica eliminata con successo' );
            }
        },
        update_all_executed( new_value ) {
            if ( new_value === null ) { return; }
            this.force_reload();
            new_value.map( label => {
                //console.log( `aggiornato ${ label }` );
                this.classes[ label ] = 'dati_box_updated';
            });
            this.make_toast( 'MODIFICA ANAGRAFICHE', `${ new_value.length } anagrafica aggiornate con successo` );
            setTimeout( () => {
                new_value.map( label => {
                    this.classes[ label ] = null;
                    this.reload( label + '_key' );
                });
            }, 1000 );
        },
        loaded( new_value ) {
            if ( new_value === false ) { return; }
            this.addressobj.comune_id = this.$store.state.comune_residenza.obj.id;
        },
    },
    methods : {
        ...mapActions( 'preg', { create_relation: 'create_relation', delete_registries: 'delete_registries', update_all: 'update_all' } ),
        force_reload() {
            this.$store.dispatch('pratica/force_reload');
        },
        make_toast(title, body) {
            this.$bvToast.toast( body, {
                title         : title,
                autoHideDelay : 5000,
                appendToast   : true,
            });
        },
        toggle_modal(modal_id = 'modal-relations') {
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        registry_selected( payload ) {
            let data = {
                codice_pratica : this.codice_pratica,
                role_id        : this.role_id,
            };
            Object.keys( payload ).map( key => { data[ key ] = payload[ key ]; } )
            this.create_relation( data );
        },
        reload( key ) {
            let match = this[ key ].match( /^([a-z_]+)(\d+)$/ );
            if ( match ) {
                let new_value = match[1] + String( parseInt( match[2] ) + 1 );
                this[ key ] = new_value;
            } else { `ALERT: impossibile modificare la chiave ${ key }`; }
        },
        copy( obj ) { return JSON.parse( JSON.stringify( obj ) ); },
        save_all() {},
        edit_registry( role_name ) {
            let preg = this.prareg.pregs[ role_name ];
            this.role_id = preg.role_id;
            this.$refs.modal_association.mao.record_id          = preg.id;
            this.$refs.modal_association.mao.registry_father_id = preg.registry_id;
            this.$refs.modal_association.mao.role_id            = preg.role_id;
            this.$refs.modal_association.set_editreg_component( this.registry_template_ids.get( preg.template_id ).template_name )
            this.toggle_modal('modal-edit-registry');
        },
        unlink( label ) {
            this.unlink_payload = { main: label, dependencies: this.prareg.get_related_dependencies( label ) };
            this.toggle_modal('confirm-unlink');
        },
        unlink_dependencies() {
            let ids = this.prareg.get_ids( this.unlink_payload );
            this.delete_registries( { ids, codice_pratica: this.codice_pratica } );
        },
        // gestione address
        ad_button( data ) {
            this.toggle_modal('modal-address-data-' + this.signature);
        },
        submit_ad_form() {
            this.$refs.modal_ad.submit_form();
        },
        save_ad( payload ) {
            this.addressobj.set_values( payload );
            this.toggle_modal('modal-address-data-' + this.signature);
        },
        value_changed( obj ) {
            console.log( obj );
            this.addressobj.set_google_payload( obj )
        },
    },
    created() {
        this.addressobj.store = this.$store;
        this.signature = moment().valueOf();
    },
    data() {
        return {
            field_label    : '',
            template_id    : null,
            role_id        : null,
            main_key       : 'main_0',
            search_filters : null,
            signature      : null,
            // gestione indirizzo
            schema         : schemas._address,
            ad_schema      : schemas._address_data,
            addressobj     : new Address(),
        }
    },
}

</script>

<template>
    <div>
    </div>
</template>

<style>

.dati_box {
    border: 1px solid lightgrey;
    margin-top: 10px;
    border-radius: 10px;
    padding-top: 6px;
    box-shadow: 5px 5px #F5F5F5;
    transition: all 0.5s;
}

.dati_box:hover {
    background-color: #F5F5F5;
    cursor: pointer;
}

.dati_box_updated {
    border-color: #218838;
}

.dati_box_error {
    border-color: red;
}

.link_disabled {
    color: #212121;
    cursor: default;
}

.link_disabled:hover {
    text-decoration: none;
    color: #212121;
}

.block_label {
    font-size: 0.8em;
    font-weight: 700;
    padding: 5px;
    padding-left: 10px;
}

</style>

