////////////////////

import { folder_service } from '@/services';
import base_module from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'folder',
    send_alert    : false,
    default_order : 'name ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    createdws: false,
};

const actions = {
    rename({ commit }, data) {
        commit('start_request');
        folder_service.update( 'folder', data.payload.id, { name: data.payload.name }, data.include )
            .then(response => {
                commit('success_update');
                commit('success_list', response);
            })
            .catch( error => {
                commit('error', error)
                commit('success_update');
            });
    },
    delete({ commit }, item_id) {
        commit('start_request');
        folder_service.delete_item( 'folder', item_id )
            .then(response => {
                commit('success_list', response);
            })
            .catch( error => {
                commit('error', error)
            });
    },
    createws({ commit, dispatch }, data) {
        commit('start_request');
        var method = null;
        var options_select = { options: { codice_pratica: data.codice_pratica, include: true } };
        if ( data.payload.hasOwnProperty('codice_pratica') ) {
            method = 'createws_on_root';
            options_select.options.parentId = 'null';
        } else {
            method = 'createws';
            options_select.options.parentId = data.folder_id;
        }
        folder_service[ method ]( 'folder', data )
            .then( response => {
                commit('success_upload');
                commit('success_list', response);
                //dispatch('get_all', options_select );
            })
            .catch( error => {
                commit('error', error)
            });
    },
    close_modal({commit}) { commit('closemodal'); },
    ovveride_meta({commit}, data) {
        commit('overridemeta', data);
    },
    update({ commit }, data) {
        commit('start_request');
        folder_service.update( 'folder', data.id, data.payload, data.include )
            .then( obj => {
                commit('success', obj);
            })
            .catch( error => {
                commit('error', error)
            });
    },
};

const mutations = {
    success_update( state, items ) {
        state.updated = true;
    },
    success_upload( state, items ) {
        state.createdws = true;
    },
    success_list( state, items ) {
        var folders = [];
        var files   = [];
        for ( var i = 0; i < items.length; i++ ) {
            if ( items[ i ].task_image_id === null ) {
                folders.push( items[ i ] );
            }
        }
        state.items    = folders.concat( files );
        state.loading  = false;
        state.error    = false;
        state.executed = true;
    },
};

export default {
    namespaced : true,
    ...base,
};

////////////////////

//import { folder_service } from '@/services';
//
//export const move_folder = {
//    namespaced: true,
//    state: {
//        title_form    : 'move_folder',
//        obj           : {},
//        items         : null,
//        loading       : false,
//        error         : false,
//        created       : false,
//        updated       : false,
//        createdws     : false,
//        executed      : false,
//        force_reload  : false,
//        page          : 1,  // default
//        rows_per_page : 25, // default
//        tot           : 0,
//        order         : null,
//        page          : 1,  // default
//        rows_per_page : 25, // default
//        tot           : 0,
//        selected      : false,
//    },
//    actions: {
//        get_by_id({commit}, data) {
//            commit('start_request');
//            folder_service.get_by_id('folder', data.id, data.include)
//                .then( rows => {
//                    commit('success_obj', rows)
//                })
//                .catch( error => {
//                    commit('error', error)
//                });
//        },
//        get_all({ commit, state }, data = { options: {} }) {
//            commit('start_request');
//            data.options.order  = state.order;
//            folder_service.get_all('folder', data.options)
//                .then( response => {
//                    if ( data.options.paginate === true ) { // gestione paginator
//                        commit('success_list', response.data);
//                        commit('set_total', response.tot);
//                        commit('set_page', response.page);
//                        commit('set_rows_per_page', response.rows_per_page);
//                    } else {
//                        commit('success_list', response)
//                    }
//                })
//                .catch( error => {
//                    commit('error', error)
//                });
//        },
//        create({ commit }, data) {
//            commit('start_request');
//            folder_service.create( 'folder', data.payload, data.include )
//                .then(response => {
//                    commit('success_create');
//                    commit('success_list', response);
//                })
//                .catch( error => {
//                    commit('error', error)
//                    commit('success_create');
//                });
//        },
//        rename({ commit }, data) {
//            commit('start_request');
//            folder_service.update( 'folder', data.payload.id, { name: data.payload.name }, data.include )
//                .then(response => {
//                    commit('success_update');
//                    commit('success_list', response);
//                })
//                .catch( error => {
//                    commit('error', error)
//                    commit('success_update');
//                });
//        },
//        delete({ commit }, item_id) {
//            commit('start_request');
//            folder_service.delete_item( 'folder', item_id )
//                .then(response => {
//                    commit('success_list', response);
//                })
//                .catch( error => {
//                    commit('error', error)
//                });
//        },
//        createws({ commit, dispatch }, data) {
//            commit('start_request');
//            var method = null;
//            var options_select = { options: { codice_pratica: data.codice_pratica, include: true } };
//            if ( data.payload.hasOwnProperty('codice_pratica') ) {
//                method = 'createws_on_root';
//                options_select.options.parentId = 'null';
//            } else {
//                method = 'createws';
//                options_select.options.parentId = data.folder_id;
//            }
//            folder_service[ method ]( 'folder', data )
//                .then( response => {
//                    commit('success_upload');
//                    commit('success_list', response);
//                    //dispatch('get_all', options_select );
//                })
//                .catch( error => {
//                    commit('error', error)
//                });
//        },
//        force_reload({commit}) { commit('force_reload'); },
//        executed({commit}) { commit('executed'); },
//        clear({commit}) { commit('clear'); },
//        close_modal({commit}) { commit('closemodal'); },
//        ovveride_meta({commit}, data) {
//            commit('overridemeta', data);
//        },
//        update({ commit }, data) {
//            commit('start_request');
//            folder_service.update( 'folder', data.id, data.payload, data.include )
//                .then( obj => {
//                    commit('success', obj);
//                })
//                .catch( error => {
//                    commit('error', error)
//                });
//        },
//        force_reload({commit}) { commit('force_reload'); },
//        executed({commit}) { commit('executed'); },
//        set_order( {commit}, value ) {
//            commit('setorder', value);
//        },
//    },
//    mutations: {
//        setorder ( state, value ) {
//            state.order = value;
//        },
//        start_request( state ) {
//            state.loading      = true;
//            state.error        = false;
//            state.executed     = false;
//            state.force_reload = false;
//        },
//        success( state, obj ) {
//            state.obj      = obj;
//            state.loading  = false;
//            state.error    = false;
//            state.executed = true;
//        },
//        success_obj( state, obj ) {
//            state.obj      = obj;
//            state.loading  = false;
//            state.error    = false;
//            state.executed = false;
//        },
//        success_create( state, items ) {
//            state.created = true;
//        },
//        success_update( state, items ) {
//            state.updated = true;
//        },
//        success_upload( state, items ) {
//            state.createdws = true;
//        },
//        success_list( state, items ) {
//            var folders = [];
//            var files   = [];
//            for ( var i = 0; i < items.length; i++ ) {
//                if ( items[ i ].task_image_id === null ) {
//                    folders.push( items[ i ] );
//                //} else {
//                //    files.push( items[ i ] );
//                }
//            }
//            state.items    = folders.concat( files );
//            state.loading  = false;
//            state.error    = false;
//            state.executed = true;
//        },
//        error( state, error ) {
//            state.obj          = error;
//            state.loading      = false;
//            state.error        = true;
//            state.executed     = false;
//            state.force_reload = true;
//        },
//        set_total( state, data ) {
//            state.tot = data;
//        },
//        set_page( state, data ) {
//            state.page = data;
//        },
//        set_rows_per_page( state, data ) {
//            state.rows_per_page = data;
//        },
//        force_reload(state) {
//            state.force_reload = true;
//        },
//        executed(state) {
//            state.executed  = false;
//            state.created   = false;
//            state.updated   = false;
//            state.createdws = false;
//        },
//        clear( state ) {
//            state.obj          = {};
//            state.items        = null;
//            state.loading      = false;
//            state.error        = false;
//            state.executed     = false;
//            state.force_reload = false;
//        },
//    }
//}


