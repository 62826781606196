//////////////////////////////

module.exports = (type) => {
    var schema = {
        type: {
            type: type.INTEGER,
            allowNull: false,
            defaultValue: 0,
        },
        code: {
            type: type.INTEGER,
            allowNull: false,
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue: type.NOW,
        },
        action: {
            type: type.TEXT,
            allowNull: false,
        },
        description: {
            type: type.TEXT,
            allowNull: true,
        },
        options: {
            type: type.JSONB,
            allowNull: true,
            defaultValue: { color: null, task_label: null, },
        },
        user_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'users',
                key         : 'id',
                constraints : true,
                store_name  : 'user',
            }
        },
        codice_pratica: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'pratiche',
                key         : 'codice',
                constraints : true,
                store_name  : 'pratica',
            }
        },
        color: {
            type: type.VIRTUAL,
            allowNull: true,
            select: true,
            //references: {
                store_name  : 'color',
            //}
        },
        task_label: {
            type: type.VIRTUAL,
            allowNull: true,
            select: true,
            //references: {
                store_name  : 'tasklist',
            //}
        },
    };

    return schema;
}


