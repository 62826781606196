

module.exports = {
    cols: [
          { 
              row1: {
                  field1: 'tipo_pratica_id',
                  field2: 'data_sinistro',
              },
              row2: {
                  field1: null,
                  field2: 'data_denuncia',
              },
              row3: {
                  field1: 'owner',
                  field2: 'data_affidamento',
              },
              row4: {
                  field1: 'created_by',
                  field2: 'data_primo_contatto',
              },
              row5: {
                  field1: 'state',
                  field2: 'data_sopralluogo',
              },
              row6: {
                  field1: 'numero_sinistro',
                  field2: 'data_videoperizia',
              },
              row7: {
                  field1: 'ramo',
                  field2: 'data_invio_atto',
              },
              row8: {
                  field1: 'tipo_danno',
                  field2: 'data_evasione',
              },
              row9: {
                  field1: 'delega_a_pagare',
                  field2: 'saving',
              },
              row10: {
                  field1: 'risarcimento_in_forma_spese',
                  field2: 'importo_riserva',
              },
              row11: {
                  field1: 'tipo_trattazione',
                  field2: 'importo_richiesto',
              },
              row12: {
                  field1: 'num_pratica_esterno',
                  field2: 'tipo_chiusura_id',
              },
              row13: {
                  field1: 'luogo_sinistro',
                  field2: 'importo_liquidato',
              },
              row14: {
                  field1: 'luogo_appuntamento',
                  field2: 'importo_accertato',
              },
              field1: 'note',
          },
    ]
};

