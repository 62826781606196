<script>

import bnvbase from './bnvbase.vue';
const md5 = require('md5');
const moment = require('moment');

export default {
    extends: bnvbase,
    props: [ 'store', ],
    computed: {
        store_state () {
            return this.$store.state[ this.store ];
        },
        sent() {
            return this.store_state.sent;
        },
        references() { return this.creator.schema.references || {}; },
        has_label() { return this.references.label === true ? true : false; },
        has_note() { return this.references.note === true ? true : false; },
    },
    watch: {
        sent( new_value ) {
            if ( !new_value ) { return; }
            this.make_toast( new_value.message );
            this.model = new_value.attachments;
        }
    },
    created () {
        this.signature = moment().valueOf();
        if ( !this.model ) { this.model = []; }
    },
    methods: {
        toggle_modal( modal_id = null ) {
            if ( modal_id === null ) { modal_id = 'new-item-' + this.name + '-' + this.signature; }
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        clear_form() {
            this.new_item_state  = null;
            this.new_item        = null;
            this.new_item_label  = null;
            this.edit_item_state = null;
            this.edit_item       = null;
            this.edit_item_label = null;
            this.edit_item_on    = null;
            this.edit_item_note  = null;
            this.edit_item_id    = null;
        },
        save_new_item() {
            if ( this.recordid ) {
                let payload = {
                    file      : this.new_item,
                    label     : this.new_item_label,
                    id        : md5( JSON.stringify( this.new_item ) + moment().format() ),
                    record_id : this.recordid,
                };
                this.$store.dispatch( `${ this.store }/add_item`, payload );
            } else {
                let new_item = {
                    file  : this.new_item,
                    label : this.new_item_label,
                    id    : md5( JSON.stringify( new_item ) + moment().format() ),
                }
                this.model.push( new_item );
            }
            this.toggle_modal();
        },
        set_focus( type ) {
            if ( type === 'new' ) {
                //this.$refs.new_element.focus();
            } else if ( type === 'edit' ) {
                this.$refs.edit_element_label.focus();
            }
        },
        open_edit_item( item ) {
            if ( this.recordid ) {
                this.edit_item_id    = item.key;
                this.edit_item       = item;
                this.edit_item_on    = item.originalname;
                this.edit_item_label = item.label || '';
            } else {
                this.edit_item_id    = item.id;
                this.edit_item       = item;
                this.edit_item_on    = item.file.name;
                this.edit_item_label = item.label || '';
            }
            this.toggle_modal('edit-item-' + this.name + '-' + this.signature);
        },
        save_edit_item() {
            if ( this.recordid ) {
                let payload = {
                    key       : this.edit_item_id,
                    label     : this.edit_item_label,
                    record_id : this.recordid,
                };
                this.$store.dispatch( `${ this.store }/edit_item`, payload );
            } else {
                let item = null; let model = [];
                for ( var i = 0; i < this.model.length; i++ ) {
                    item = this.model[ i ];
                    if ( item.id === this.edit_item_id ) { item.label = this.edit_item_label + ''; }
                    model.push( item )
                }
                this.model = model;
                this.row_key += 1;
            }
            this.toggle_modal('edit-item-' + this.name + '-' + this.signature);
        },
        delete_edit_item() {
            if ( this.recordid ) {
                let payload = {
                    key   : this.edit_item_id,
                    record_id : this.recordid,
                };
                this.$store.dispatch( `${ this.store }/delete_item`, payload )
            } else {
                for ( var i = 0; i < this.model.length; i++ ) {
                    if ( this.model[ i ].id === this.edit_item_id ) { break; }
                }
                this.model.splice( i, 1 );
            }
            this.toggle_modal('edit-item-' + this.name + '-' + this.signature);
        },
        make_toast( body ) {
            this.$bvToast.toast( body, {
                title         : 'GESTIONE ALLEGATI',
                autoHideDelay : 5000,
                appendToast   : true,
            });
        },
        get_item_label( item ) {
            if ( item.label ) { return item.label; }
            return this.recordid ? item.originalname : item.file.name;
        },
    },
    data () {
        return {
            signature       : null,
            row_key         : 0,
            new_item_state  : null,
            new_item        : null,
            new_item_label  : null,
            new_item_note   : null,
            edit_item_state : null,
            edit_item       : null,
            edit_item_label : null,
            edit_item_on    : null,
            edit_item_note  : null,
            edit_item_id    : null,
        }
    },
};

</script>

<template>

    <div class="form-group" :class="field_class" :style="field_style" v-if="Array.isArray( model )">

        <label :for="'bnv-form-' + name" :class="label_class">{{ get_label() }}</label>
        <div :class="element_container_class" class="zindex" id="'bnv-form-' + name">
            <b-row no-gutter style="padding: 0; margin: 0;" :key="row_key">
                <b-col style="border: 1px solid #ced4da; background-color: white; padding: 1px;">
                    <b-badge @click="open_edit_item( item )" pill v-for="item in model" href="#" :key="item.id" variant="info" style="margin-right: 1px">
                        {{ get_item_label( item ) }}
                    </b-badge>
                </b-col>
                <b-col style="padding: 0;" cols="12" sm="auto"><b-button variant="outline-info" @click="toggle_modal()"><icon name="paperclip"/></b-button></b-col>
            </b-row>
        </div>

        <!-- modal new-item -->
        <b-modal
            :id         = "'new-item-' + name + '-' + signature"
            :title      = "'Nuova voce ' + get_label()"
            @hide       = "clear_form"
            button-size = "sm"
            @shown      = "set_focus('new')"
            >
            <div>
                <b-form-file ref="new_element" :state="new_item_state" v-model="new_item" placeholder="Nuova voce"></b-form-file>
                <div class="invalid-feedback">
                    nuova voce non valida
                </div>
                <div style="margin-top: 20px;">
                    <b-form-input v-if="has_label" ref="new_element_label" v-model="new_item_label" placeholder="Etichetta"></b-form-input>
                </div>
                <div style="margin-top: 20px;">
                    <b-form-textarea v-if="has_note" ref="new_element_note" v-model="new_item_note" placeholder="Scrivi una nota"></b-form-textarea>
                </div>
            </div>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="save_new_item">
                      OK
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <!-- modal edit-item -->
        <b-modal
            :id="'edit-item-' + name + '-' + signature"
            :title="'Modifica voce ' + get_label()"
            @hide="clear_form"
            button-size="sm"
            @shown="set_focus('edit')"
            >
            <div>
                <b-form-input v-model="edit_item_on" readonly></b-form-input>
                <div class="invalid-feedback">
                    nuova voce non valida
                </div>
                <div style="margin-top: 20px;">
                    <b-form-input v-if="has_label" ref="edit_element_label" v-model="edit_item_label" placeholder="Etichetta"></b-form-input>
                </div>
                <div style="margin-top: 20px;">
                    <b-form-textarea v-if="has_note" ref="edit_element_note" v-model="edit_item_note" placeholder="Scrivi una nota"></b-form-textarea>
                </div>
            </div>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="save_edit_item">
                      OK
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-danger" @click="delete_edit_item()">
                      Elimina
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

    </div>

</template>

<style>
    .zindex {
        z-index: 20;
    }
</style>

