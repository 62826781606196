//////////////////////////////

// https://stackoverflow.com/questions/7922744/how-can-i-search-all-columns-in-a-table

module.exports = (type) => {

    var schema = {
        /* GENERALE */
        nome: {
            type: type.STRING(64),
            allowNull: true,
        },
        cognome: {
            type: type.STRING(64),
            allowNull: true,
        },
        denominazione: {
            type: type.STRING(64),
            allowNull: true,
        },
        indirizzo: {
            type: type.VIRTUAL,
            allowNull: true,
            field_type: 'google_address',
        },
        codice_fiscale: {
            type: type.STRING(64),
            allowNull: true,
            options: {
                type : 'codfisc',
            },
        },
        partita_iva: {
            type: type.STRING(64),
            allowNull: true,
            options: {
                type: 'piva',
                display_error : false,
            }
        },
        telefono: {
            type: type.JSONB,
            defaultValue: [],
            allowNull : false,
            options: {
                type          : 'object_manager',
                new_item_type : 'string',
                not_empty     : true,
                label         : true,
                note          : true,
            },
        },
        email: {
            type: type.JSONB,
            defaultValue: [],
            allowNull : false,
            options: {
                type          : 'object_manager',
                new_item_type : 'email',
                not_empty     : true,
                label         : true,
            },
        },
        iban: {
            type: type.STRING(64),
            allowNull: true,
            options: {
                type: 'piva',
                display_error : false,
            }
        },
        note: {
            type: type.TEXT,
            allowNull: true,
        },
    };

    return schema;
}

