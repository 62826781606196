////////////////////

const axios = require('axios');

import base_module from '@/vue-model-mirror/stores/base.module';
const config = require('@/config')[ process.env.NODE_ENV || 'development' ];
import { authHeader } from '@/helpers';

var options = {
    model_name    : 'comune',
    send_alert    : false,
    default_order : 'nome ASC',
};

var base = new base_module( options );

const actions = {
    ...base.actions,
    async get_by_code( { commit, getters, state }, code ) {
        var url = `${config.backend_url}model/comune/?codice=${ code }`;
        var options = { headers: authHeader() };
        options.headers['Authorization'] = `Bearer ${ JSON.parse( localStorage.getItem('user') ).jwt }`;
        return await axios.get( url, options )
            .then( rows => {
                commit('success_obj', rows.data[0]);
                return rows.data[0].id;
            })
    }
};

export default {
    namespaced : true,
    state      : base.state,
    getters    : base.getters,
    mutations  : base.mutations,
    actions,
};

