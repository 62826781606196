<script>

const nprogress = require('nprogress');
const pm = require('./parcel_manager').default;

const { listpage } = require('@/vue-model-mirror');

export default {
    name: 'pratica-parcel',
    extends: listpage,
    props: [ 'label', 'codice_pratica', 'modal' ],
    mounted () {
        this.$nextTick(function () { nprogress.done(true); }) // stop loader
    },
    watch: {
        done(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                //console.log( 'procedura terminata' );
                this.$store.dispatch(`${ this.schema_name }/force_reload`);
                //this.list_key += 1;
                setTimeout( () => {
                    //this.pm = new pm( this.schema_name, this.$store, this.$refs.listbnv );
                    this.$emit('pra-modified', null);
                }, 500);
                this.$store.dispatch( `${ this.schema_name }/clear_procedure` );
            }
        },
        user_executed(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch('user/executed');
                this.executed.user = true;
            }
        },
        parcel_executed(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch('parcel/executed');
                this.executed.parcel = true;
            }
        },
        start_render(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                setTimeout( () => {
                    this.pm = new pm( this.schema_name, this.$store, this.$refs.listbnv );
                }, 500);
            }
        },
    },
    computed: {
        done() { return this.$store.state[ this.schema_name ].done; },
        obj() { return this.$store.state.pratica.obj; },
        mode() { return ( this.$store.getters['statemanager/customers'].map( x => x.id ).includes( this.obj.owner ) ) ? 'owner' : 'custodian'; },
        parcel_mode() { return this.mode === 'owner' ? this.obj.parcel_mode : this.obj.parcel_mode_custodian; },
        schema_name: { get: function() { return this.parcel_mode === 'task' ? 'pratica_parcel_task' : 'pratica_parcel_user'; }, set: function( value ) {}, },
        user_executed() { return this.$store.state.user.executed; },
        parcel_executed() { return this.$store.state.parcel.executed; },
        start_render() { return ( this.executed.user === true && this.executed.parcel === true ) ? true : false;  },
        imponibile() {
            var imponibile = 0;
            if ( this.pratica.custodian === null ) {
                imponibile = this.pratica.imponibile;
            } else if ( this.pratica.custodian !== null ) {
                imponibile = this.pratica.imponibile_custodian;
            }
            return imponibile;
        },
        imponibile_currency() { return new Intl.NumberFormat( 'it-IT', { style: 'currency', currency: 'EUR' } ).format( this.imponibile ); },
        spese_users_currency() { return new Intl.NumberFormat( 'it-IT', { style: 'currency', currency: 'EUR' } ).format( this.spese_users ); },
        totale_currency() { return new Intl.NumberFormat( 'it-IT', { style: 'currency', currency: 'EUR' } ).format( this.imponibile - this.spese_users ); },
        items() { return this.$store.state.pratica.items; },
    },
    methods: {
        table_modified( action, element_ref, value ) {
//          console.log( '1 action', action );
//          console.log( '2 element_ref', element_ref );
//          console.log( '3 value', value );
            if ( action === 'quota-modified' )           { this.pm.quota_modified(); }
            else if ( action === 'lock-modified' )       { this.pm.lock_modified(); }
            else if ( action === 'percentage-modified' ) { this.pm.percentage_modified( element_ref ); }
            else if ( action === 'minimum-modified' )    { this.pm.minimum_modified( element_ref ); }
        }
    },
    created () {
        this.list_key += 1;
        if ( this.items ) { this.pratica = this.items.filter( x => x.codice === parseInt( this.codice_pratica ) )[0]; }
        else { this.pratica = this.obj; }
        this.$store.dispatch(`${ this.schema_name }/force_reload`);
        this.$store.dispatch('pratica_parcel/force_reload');
        this.$store.dispatch('user/force_reload');
        this.$store.dispatch('parcel/force_reload');
        this.$store.dispatch('user/get_all');
        this.$store.dispatch('parcel/get_all', { options: { customer_id: this.mode === 'owner' ? this.obj.owner : this.obj.custodian } });
        this.$store.dispatch(`${ this.schema_name }/set_customer_id`, this.mode === 'owner' ? this.pratica.owner : this.pratica.custodian );
    },
    data () {
        return {
            pratica     : null,
            list_key    : 0,
            spese_users : '0,00€',
            executed : {
                user   : false,
                parcel : false,
            },
            total : false,
            pm    : null,
        }
    },
};

</script>

<template>

    <div v-if="obj.parcel_mode === 'user'">
        <bnvlist
            v-if             = "start_render"
            :key             = "list_key"
            :schema          = "schema_name"
            :skip            = "[]"
            :fields          = "fields"
            :primarykey      = "primary_key"
            :qryoptions      = "qry_options"
            :details         = "details"
            :detailsclass    = "details_class"
            :detailsaction   = "details_action"
            :edit            = "edit"
            :editaction      = "edit_action"
            :paginate        = "paginate"
            :link            = "link"
            :rowclass        = "rowclass"
            :linkfunction    = "linkfunction"
            :initialfilter   = "initialfilter"
            ref              = "listbnv"
            :formpath        = "item_form"
            :size            = "size"
            @row-selected    = "row_selected"
            @right-click     = "right_click"
            @table-modified  = "table_modified"
        ></bnvlist>
    </div>

    <div v-else-if="obj.parcel_mode === 'task'">
        <bnvlist
            v-if             = "start_render"
            :key             = "list_key"
            :schema          = "schema_name"
            :skip            = "[]"
            :fields          = "fields"
            :primarykey      = "primary_key"
            :qryoptions      = "qry_options"
            :details         = "details"
            :detailsclass    = "details_class"
            :detailsaction   = "details_action"
            :edit            = "edit"
            :editaction      = "edit_action"
            :paginate        = "paginate"
            :link            = "link"
            :rowclass        = "rowclass"
            :linkfunction    = "linkfunction"
            :initialfilter   = "initialfilter"
            ref              = "listbnv"
            :formpath        = "item_form"
            :size            = "size"
            @row-selected    = "row_selected"
            @right-click     = "right_click"
            @table-modified  = "table_modified"
        ></bnvlist>
    </div>

</template>

