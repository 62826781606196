<script>
import { mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';

export default {
    name: 'advanced-search',
    props: [ '', 'appuntamento_filters' ],
    components : { DatePicker },
    computed : {
        text: {
            get() { return this.$store.state.appuntamento.text_filter; },
            set( value ) { this.$store.dispatch( 'appuntamento/set_text_filter', value ) },
        },
        text_valid() {
            return this.$store.state.appuntamento.text_filter_valid;
        },
    },
    watch: {
        text( new_value ) {
            if ( new_value.length === 0 ) { this.clear(); return; }
            if ( new_value.length < this.min_matching_chars ) { return; }
            if ( this.timeout ) {
                clearTimeout( this.timeout );
            }
            
            this.timeout = setTimeout( () => {
                this.$emit( 'search', { text: new_value, date_filters: this.getDateFilters() } ); // setto il filtro
            }, 500);
        }
    },
    methods : {
        clear() {
            this.$emit( 'search', { text: '', date_filters: this.getDateFilters() } ); // setto il filtro
        },
        reset() { this.text = ''; },
        set_filters() {
            // text
            let text = '';
            if ( this.text.length > this.min_matching_chars - 1 ) {
                text = this.text;
            }
            this.$emit( 'search', { text, date_filters: this.getDateFilters() } ); // setto il filtro
            this.$refs.filters.hide();
        },
        getDateFilters() {
            let date_filters = [];
            // Se sono stati impostati dei filtri sulle date li riporto, altrimenti
            // vengono persi anche se visibili sulla pagina
            if (this.appuntamento_filters != undefined) {
                for ( var i = 0; i <  this.appuntamento_filters.length; i++ ) {
                    if ( this.appuntamento_filters[ i ].range === null ) { break; }
                    date_filters.push( this.appuntamento_filters[ i ] );
                }
            }
            return date_filters.length > 0 ? date_filters : null;
        }
    },
    created() {
    },
    data() {
        return {
            timeout            : null,
            min_matching_chars : 3,
        }
    },
}

</script>

<template>
    <div>
        <b-input-group class="mt-3">
            <template #prepend>
                <b-button
                    style  = "background-color: white;"
                    :style = "{ 'border-color': text_valid === false ? '#dc3545' : '#ced4da' }"
                    size   = "sm"
                    @click = "reset()"
                    :class = "{ 'is-invalid': !text_valid }"
                    >
                    <b-icon-x-circle-fill variant="secondary" scale="1.4" font-scale = "1" style="padding-top: 3px" ></b-icon-x-circle-fill>
                </b-button>
            </template>
            <!-- 
            <template #append>
                <b-dropdown
                    right
                    block
                    size  = "sm"
                    style = "background-color: #F5F5F5;"
                    ref   = "filters"
                    >
                    <div style="width: 600px; padding: 0 10px 10px 10px; background-color: #F5F5F5; margin: 0">
                        <div v-for="( item, index ) in date_filters">
                            <b-row :key="'date_filter_' + String( index )" style="padding-top: 10px">
                                <b-col>
                                    <b-select
                                        size     = "sm"
                                        :options = "date_fields"
                                        v-model  = "date_filters[ index ].field"
                                        @change  = "set_date_field( index )"
                                    />
                                </b-col>
                                <b-col>
                                    <DatePicker
                                        range
                                        lang            = "it"
                                        placeholder     = "imposta un intervallo"
                                        input-class     = "form-control form-control-sm"
                                        :append-to-body = "false"
                                        :shortcuts      = "shortcuts"
                                        :clearable      = "false"
                                        :formatter      = "formatter"
                                        v-model         = "date_filters[ index ].range"
                                        @change         = "set_range( index )"
                                        >
                                        <i class="glyphicon glyphicon-th" slot="calendar-icon" />
                                    </DatePicker>
                                </b-col>
                                <b-col style="padding-top: 5px" cols="1">
                                    <b-icon-x-circle-fill
                                        v-if        = "index < date_filters.length - 1"
                                        style       = "margin-top: 3px; cursor: pointer;"
                                        variant     = "danger"
                                        scale       = "1.2"
                                        font-scale  = "1"
                                        @click.stop = "remove_date_filter( index )"
                                    />
                                </b-col>
                            </b-row>
                        </div>
                        <b-row style="padding: 0 15px; margin-top: 15px" align-h="end" @click="set_filters">
                            <b-button variant="success" size="sm">Ok</b-button>
                        </b-row>
                    </div>
                </b-dropdown>
            </template>
            -->
            <b-input
                size        = "sm"
                v-model     = "text"
                placeholder = "Cerca ovunque"
                :class      = "{ 'is-invalid': !text_valid }"
                >
            </b-input>
        </b-input-group>
    </div>
</template>

<style>
    .dropdown-menu-right {
        background-color: #F5F5F5 !important;
    }
</style>

