////////////////////

import { authHeader } from '@/helpers';

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export const nullboolean_service = {
    get_all,
};

function get_all() {
    var options = [
        { id: true, label: 'Sì' },
        { id: false, label: 'No' },
    ];
    return new Promise(function(resolve, reject) {
        resolve( options );
    });
}


