

module.exports = {
    cols: [
          { 
              row1: {
                  field1: 'denominazione',
                  field2: 'codice_destinatario',
              },
              row2: {
                  field1: 'codice_fiscale',
                  field2: 'iban',
              },
              field1: 'note',
          },
    ]
};

