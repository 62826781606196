///////////////////////////////////////////////////

const validators                = require('@/form_validators');
const _address_layout           = require('@/form_layouts/address');
const _registry_layout          = require('@/form_layouts/registry');
const _contact_layout           = require('@/form_layouts/contact');
const _agenzia_layout           = require('@/form_layouts/agenzia');
const _compagnia_layout         = require('@/form_layouts/compagnia');
const _persona_fisica_layout    = require('@/form_layouts/persona_fisica');
const _address_data_layout      = require('@/form_layouts/address_data');
const _persona_giuridica_layout = require('@/form_layouts/persona_giuridica');
const _ispettorato_layout       = require('@/form_layouts/ispettorato');
const _liquidatore_layout       = require('@/form_layouts/liquidatore');
const _ente_pubblico_layout     = require('@/form_layouts/ente_pubblico');
const _pratica_incarico_layout  = require('@/form_layouts/pratica_incarico');
const _polizza_layout           = require('@/form_layouts/polizza');
const _all_fields_layout        = require('@/form_layouts/all_fields');

const _compagnia_subtab_layout         = require('@/form_layouts/compagnia_subtab');
const _assicurato_subtab_layout        = require('@/form_layouts/assicurato_subtab');
const _agenzia_subtab_layout           = require('@/form_layouts/agenzia_subtab');
const _ispettorato_subtab_layout       = require('@/form_layouts/agenzia_subtab');
const _liquidatore_subtab_layout       = require('@/form_layouts/liquidatore_subtab');
const _persona_fisica_subtab_layout    = require('@/form_layouts/persona_fisica_subtab');
const _persona_giuridica_subtab_layout = require('@/form_layouts/persona_giuridica_subtab');
const _cliente_subtab_layout           = require('@/form_layouts/cliente_subtab');
const _controparte_subtab_layout       = require('@/form_layouts/controparte_subtab');
const _riparatore_subtab_layout        = require('@/form_layouts/riparatore_subtab');
const _testimone_subtab_layout         = require('@/form_layouts/testimone_subtab');
const _autorita_subtab_layout          = require('@/form_layouts/autorita_subtab');
const _polizza_subtab_layout           = require('@/form_layouts/polizza_subtab');

module.exports = {

    tipo_pratica: {
        settings    : { size: 'sm', title_form: 'Tipo Pratica' },
        label       : { label: 'Nome' },
        descrizione : { label: 'Descrizione' },
        customer_id : { label: 'Customer' },
        created     : { skip: true },
        config      : { skip: true },
        id          : { skip: true },
    },

    pratica_incarico: {
        settings          : { size: 'sm', layout: 'condensed', form_layout: _pratica_incarico_layout, },
        codice_pratica : { label: '', skip: true },
        tipo_pratica_id : { label: 'Tipo Pratica', skip: false },
        owner : { label: 'Proprietario', skip: false, readonly: true, },
        created_by : { label: 'Creata da', skip: false, readonly: true, },
        state : { label: 'Stato', skip: false },
        numero_sinistro : { label: 'Numero Sinistro', skip: false },
        ramo : { label: 'Ramo', skip: false },
        tipo_danno : { label: 'Tipo Danno', skip: false },
        delega_a_pagare : { label: 'Delega a pagare', skip: false },
        risarcimento_in_forma_spese : { label: 'Risarcimento in forma spese', skip: false },
        tipo_trattazione : { label: 'Tipo Trattazione', skip: false },
        num_pratica_esterno : { label: 'N. pratica esterno', skip: false },
        strada_luogo_sinistro : { label: '', skip: true },
        n_civ_luogo_sinistro : { label: '', skip: true },
        cap_luogo_sinistro : { label: '', skip: true },
        comune_luogo_sinistro_id : { label: '', skip: true },
        citta_estero_luogo_sinistro : { label: '', skip: true },
        nazione_luogo_sinistro : { label: '', skip: true },
        altro_luogo_sinistro : { label: '', skip: true },
        latitudine_luogo_sinistro : { label: '', skip: true },
        longitudine_luogo_sinistro : { label: '', skip: true },
        strada_luogo_appuntamento : { label: '', skip: true },
        n_civ_luogo_appuntamento : { label: '', skip: true },
        cap_luogo_appuntamento : { label: '', skip: true },
        comune_luogo_appuntamento_id : { label: '', skip: true },
        citta_estero_luogo_appuntamento : { label: '', skip: true },
        nazione_luogo_appuntamento      : { label: '', skip: true },
        altro_luogo_appuntamento        : { label: '', skip: true },
        latitudine_luogo_appuntamento   : { label: '', skip: true },
        longitudine_luogo_appuntamento  : { label: '', skip: true },
        data_sinistro                   : { label: 'Data Sinistro', skip: false },
        data_denuncia                   : { label: 'Data Denuncia', skip: false },
        data_affidamento                : { label: 'Data Incarico', skip: false },
        data_primo_contatto             : { label: 'Data Primo Contatto', skip: false },
        data_sopralluogo                : { label: 'Data Sopralluogo', skip: false },
        data_videoperizia               : { label: 'Data Videoperizia', skip: false },
        data_invio_atto                 : { label: 'Data Invio Atto', skip: false },
        data_evasione                   : { label: 'Data Evasione', skip: false },
        importo_riserva                 : { label: 'Importo Riserva', skip: false },
        importo_richiesto               : { label: 'Importo Richiesto', skip: false },
        tipo_chiusura_id                : { label: 'Tipo Chiusura', skip: false },
        importo_liquidato               : { label: 'Importo Liquidato', skip: false },
        importo_accertato               : { label: 'Importo Accertato', skip: false },
        luogo_appuntamento              : { label: 'Luogo Appuntamento' },
        luogo_sinistro                  : { label: 'Luogo Sinistro' },
        saving                          : { label: 'Saving', readonly: true, decimal: '.01' },
        note                            : { label: 'Note', skip: false },
        created                         : { label: '', skip: true },
        config                          : { label: '', skip: true },
        operator                        : { label: '', skip: true },
        archived                        : { label: '', skip: true },
        id                              : { label: '', skip: true },
    },

    _add_registry: {
        settings          : { size: 'sm' },
    },

    _agenzia: {
        settings          : { size: 'sm', layout: 'condensed', form_layout: _agenzia_layout, },
        denominazione     : { label: 'Ragione sociale' },
        codice            : { label: 'Codice' },
        note              : { label: 'Note' },
    },

    _polizza: {
        settings              : { size: 'sm', layout: 'condensed', form_layout: _polizza_layout, },
        codice                : { label: 'Numero' },
        data_effetto          : { label: 'Data Effetto' },
        data_termine          : { label: 'Data Termine' },
        data_pagamento_premio : { label: 'Data Pagamento Premio' },
        cgas                  : { label: 'CGA', disabled: true },
        attachments           : { label: 'Allegati' },
        note                  : { label: 'Note' },
    },

    _polizza_preg: {
        settings              : { size: 'sm', layout: 'condensed', form_layout: _polizza_layout, },
        codice                : { label: 'Numero' },
        data_effetto          : { label: 'Data Effetto' },
        data_termine          : { label: 'Data Termine' },
        data_pagamento_premio : { label: 'Data Pagamento Premio' },
        cgas                  : { label: 'CGA', disabled: true },
        attachments           : { label: 'Allegati' },
        note                  : { label: 'Note' },
    },

    _persona_fisica: {
        settings            : { size: 'sm', layout: 'condensed', form_layout: _persona_fisica_layout, },
        nome                : { label: 'Nome' },
        cognome             : { label: 'Cognome' },
        titolo              : { label: 'Titolo' },
        iban                : { label: 'Iban' },
        codice_fiscale      : { label: 'Codice Fiscale' },
        partita_iva         : { label: 'Partita IVA' },
        codice_destinatario : { label: 'Codice Destinatario' },
        note                : { label: 'Note' },
    },

    _persona_giuridica: {
        settings            : { size: 'sm', layout: 'condensed', form_layout: _persona_giuridica_layout, },
        denominazione       : { label: 'Ragione Sociale' },
        partita_iva         : { label: 'Partita IVA' },
        codice_fiscale      : { label: 'Codice Fiscale' },
        iban                : { label: 'Iban' },
        codice_destinatario : { label: 'Codice Destinatario' },
        note                : { label: 'Note' },
    },

    _compagnia: {
        settings            : { size: 'sm', layout: 'condensed', form_layout: _compagnia_layout, },
        denominazione       : { label: 'Ragione Sociale' },
        partita_iva         : { label: 'Partita IVA' },
        codice_destinatario : { label: 'Codice Destinatario' },
        note                : { label: 'Note' },
    },

    _compagnia_subtab: {
        settings            : { size: 'sm', layout: 'condensed', form_layout: _compagnia_subtab_layout, },
        denominazione       : { label: 'Ragione Sociale' },
        codice_destinatario : { label: 'Codice Destinatario' },
    },

    _agenzia_subtab: {
        settings            : { size: 'sm', layout: 'condensed', form_layout: _agenzia_subtab_layout, },
        denominazione       : { label: 'Ragione Sociale' },
        codice_destinatario : { label: 'Codice Destinatario' },
        telefono            : { label: 'Telefono' },
        email               : { label: 'Email' },
    },

    _ispettorato_subtab: {
        settings            : { size: 'sm', layout: 'condensed', form_layout: _ispettorato_subtab_layout, },
        denominazione       : { label: 'Ragione Sociale' },
        codice_destinatario : { label: 'Codice Destinatario' },
        telefono            : { label: 'Telefono' },
        email               : { label: 'Email' },
    },

    _liquidatore_subtab: {
        settings : { size: 'sm', layout: 'condensed', form_layout: _liquidatore_subtab_layout, },
        nome     : { label: 'Nome' },
        cognome  : { label: 'Cognome' },
        telefono : { label: 'Telefono' },
        email    : { label: 'Email' },
    },

    _persona_fisica_subtab: {
        settings : { size: 'sm', layout: 'condensed', form_layout: _persona_fisica_subtab_layout, },
        nome     : { label: 'Nome' },
        cognome  : { label: 'Cognome' },
        telefono : { label: 'Telefono' },
        email    : { label: 'Email' },
    },

    _assicurato_subtab: {
        settings            : { size: 'sm', layout: 'condensed', form_layout: _assicurato_subtab_layout, },
    },

    _cliente_subtab: {
        settings          : { size: 'sm', layout: 'condensed', form_layout: _cliente_subtab_layout, },
    },

    _controparte_subtab: {
        settings          : { size: 'sm', layout: 'condensed', form_layout: _controparte_subtab_layout, },
    },

    _riparatore_subtab: {
        settings          : { size: 'sm', layout: 'condensed', form_layout: _riparatore_subtab_layout, },
    },

    _testimone_subtab: {
        settings          : { size: 'sm', layout: 'condensed', form_layout: _testimone_subtab_layout, },
    },

    _autorita_subtab: {
        settings          : { size: 'sm', layout: 'condensed', form_layout: _autorita_subtab_layout, },
    },

    _polizza_subtab: {
        settings              : { size: 'sm', layout: 'condensed', form_layout: _polizza_subtab_layout, },
        codice                : { label: 'Codice' },
        data_effetto          : { label: 'Data Effetto' },
        data_termine          : { label: 'Data Termine' },
        data_pagamento_premio : { label: 'Data Pagamento' },
        cgas                  : { label: 'CGA', disabled: true },
        attachments           : { label: 'Allegati' },
    },

    _persona_giuridica_subtab: {
        settings          : { size: 'sm', layout: 'condensed', form_layout: _persona_giuridica_subtab_layout, },
    },

    _ispettorato: {
        settings          : { size: 'sm', layout: 'condensed', form_layout: _ispettorato_layout, },
        denominazione     : { label: 'Ragione sociale' },
        codice            : { label: 'Codice' },
        note              : { label: 'Note' },
    },

    _liquidatore: {
        settings          : { size: 'sm', layout: 'condensed', form_layout: _liquidatore_layout, },
        nome              : { label: 'Nome' },
        cognome           : { label: 'Cognome' },
        titolo            : { label: 'Titolo' },
        note              : { label: 'Note' },
    },

    _ente_pubblico: {
        settings            : { size: 'sm', layout: 'condensed', form_layout: _ente_pubblico_layout, },
        denominazione       : { label: 'Ragione sociale' },
        codice_fiscale      : { label: 'Codice Fiscale' },
        iban                : { label: 'Iban' },
        codice_destinatario : { label: 'Codice Destinatario' },
        note                : { label: 'Note' },
    },

    _all_fields: {
        settings       : { size: 'sm', layout: 'condensed', form_layout: _all_fields_layout, },
        nome           : { label: 'Nome' },
        cognome        : { label: 'Cognome' },
        titolo         : { label: 'Titolo' },
        denominazione  : { label: 'Ragione sociale' },
        codice_fiscale : { label: 'Codice Fiscale' },
        partita_iva    : { label: 'Partita IVA' },
        iban           : { label: 'Iban' },
        note           : { label: 'Note' },
    },

    _contact: {
        settings          : { size: 'sm', layout: 'condensed', form_layout: _contact_layout, },
        telefono          : { label: 'Telefono' },
        email             : { label: 'Email' },
    },

    _registry: {
        settings          : { size: 'sm', layout: 'condensed', form_layout: _registry_layout, },
        nome              : { label: 'Nome' },
        cognome           : { label: 'Cognome' },
        titolo            : { label: 'Titolo' },
        denominazione     : { label: 'Denominaz.' },
        partita_iva       : { label: 'Partita IVA' },
        codice_fiscale    : { label: 'Cod. Fisc.' },
        data_nascita      : { label: 'Nato il', skip: true, },
        sesso             : { label: 'Sesso', skip: true, },
        comune_nascita_id : { label: 'Comune', skip: true, },
        iban              : { label: 'Iban' },
    },

    _codfisc: {
        settings          : { size: 'sm' },
        data_nascita      : { label: 'Nato il' },
        sesso             : { label: 'Sesso' },
        comune_nascita_id : { label: 'Comune' },
    },

    _address: {
        settings          : { size: 'sm', layout: 'condensed', form_layout: _address_layout, },
        indirizzo         : { label: 'Indirizzo' },
//      strada            : { label: 'Strada' },
//      n_civ             : { label: 'N.civ.' },
//      cap               : { label: 'CAP' },
//      comune_id         : { label: 'Comune' },
//      localita          : { label: 'Località' },
//      altro             : { label: 'Altro' },
//      citta_estero      : { label: 'Città estero' },
//      nazione           : { label: 'Nazione' },
//      latitudine        : { label: 'Latitudine', readonly: true },
//      longitudine       : { label: 'Longitudine', readonly: true },
    },

    _address_data: {
        settings          : { size: 'sm', layout: 'condensed', form_layout: _address_data_layout, },
        strada            : { label: 'Strada' },
        n_civ             : { label: 'N.civ.' },
        cap               : { label: 'CAP' },
        comune_id         : { label: 'Comune' },
        localita          : { label: 'Località' },
        altro             : { label: 'Altro' },
        citta_estero      : { label: 'Città estero' },
        nazione           : { label: 'Nazione' },
        latitudine        : { label: 'Latitudine', readonly: true },
        longitudine       : { label: 'Longitudine', readonly: true },
        field_name        : { label: 'Test' },
    },

    registry: {
        settings          : { size: 'sm', layout: 'condensed' },
        nome              : { label: 'Nome' },
        cognome           : { label: 'Cognome' },
        titolo            : { label: 'Titolo' },
        denominazione     : { label: 'Denominazione' },
        partita_iva       : { label: 'Partita IVA' },
        codice_fiscale    : { label: 'Codice Fiscale' },
        sesso             : { label: 'Sesso' },
        data_nascita      : { label: 'Data di nascita' },
        comune_nascita_id : { label: 'Comune di nascita' },
        iban              : { label: 'Iban' },
        note              : { label: 'Note' },
        indirizzo         : { label: 'Indirizzo' },
        tipo_strada       : { label: 'Tipo di Strada' },
        strada            : { label: 'Strada' },
        n_civ             : { label: 'N. civico' },
        cap               : { label: 'CAP' },
        comune_id         : { label: 'Comune' },
        localita          : { label: 'Località' },
        altro             : { label: 'Altro' },
        citta_estero      : { label: 'Città estero' },
        nazione           : { label: 'Nazione' },
        latitudine        : { label: 'Latitudine' },
        longitudine       : { label: 'Longitudine' },
        telefono          : { label: 'Telefono' },
        email             : { label: 'Email' },
        codice            : { label: 'Codice' },
        created           : { label: 'Creato il', skip: true },
        config            : { label: 'Config', skip: true },
        customer_id       : { label: 'Customer' },
        id                : { label: 'id', skip: true },
    },

    amounts_owner: {
        settings: { size: 'sm', },
        imponibile: { label: 'Onorario base' },
        supplemento_pl: { label: 'Supplemento PL' },
        totale_onorari: { label: 'Totale onorari', readonly: true },
        locomozione: { label: 'Locomozione' },
        visure: { label: 'Visure' },
        spese_non_imponibili: { label: 'Spese' },
        totale: { label: 'Totale parcella', readonly: true },
    },
    amounts_custodian: {
        settings: { size: 'sm', },
        imponibile: { label: 'Onorario base', readonly: true },
        supplemento_pl_custodian: { label: 'Supplemento PL' },
        totale_onorari: { label: 'Totale onorari', readonly: true },
        locomozione_custodian: { label: 'Locomozione' },
        visure_custodian: { label: 'Visure' },
        spese_non_imponibili_custodian: { label: 'Spese' },
        totale: { label: 'Totale parcella', readonly: true },
    },
    search_statistic: {
        settings                          : { size: 'sm', },
        'dati_pratica.data_incarico__gte' : { label: 'Data incarico Da' },
        'dati_pratica.data_incarico__lte' : { label: 'Data incarico A' },
        data_evasione__gte                : { label: 'Data evasione Da' },
        data_evasione__lte                : { label: 'Data evasione A' },
        'dati_pratica.mandante'           : { label: 'Mandante' },
        'dati_pratica.ramo'               : { label: 'Ramo' },
        'agenzia.denominazione'           : { label: 'Agenzia' },
        'custodian_id'                    : { label: 'Customer' },
        user_id                           : { label: 'Utente' },
    },
    ispezione_dp: {
        settings: { size: 'sm' },
        importo_riserva: { label: 'Importo riserva' },
    },
    ispezione_as: {
        settings: { size: 'sm' },
        codice_fiscale: { label: 'Codice fiscale' },
        telefono: { label: 'Telefono' },
        email: { label: 'Email' },
        iban: { label: 'Iban' },
        //preesistenza: { label: 'Preeesistenza' },
    },
    ispezione_co: {
        settings: { size: 'sm' },
        cf: { label: 'Codice fiscale' },
        telefono: { label: 'Telefono' },
        cellulare: { label: 'Cellulare' },
        email: { label: 'Email' },
        iban: { label: 'Iban' },
    },
    export_pratica: {
        settings: { size: 'sm' },
        format: { label: 'Formato' },
        order: { label: 'Ordina per' },
        order_direction: { label: 'Direzione' },
        owner: { label: 'Cliente' },
    },
    diary: {
        action: { label: 'Titolo' },
        description: { label: 'Note' },
        color: { label: 'Colore', skip: true },
        task_label: { label: 'Task', skip: true },
        id: { skip: true },
        code: { skip: true },
        type: { skip: true },
        created: { skip: true },
        options: { skip: true },
        user_id: { skip: true },
        codice_pratica: { skip: true },
    },
    add_pratica: {
        settings: { size: 'sm' },
        template_id: { label: 'template' },
    },
    set_template: {
        settings: { size: 'sm' },
        template_id: { label: 'Template' },
        owner: { label: 'Owner', readonly: true },
    },
    fileupload: {},
    meta_file: {
        description: { label: 'Descrizione' },
        file: { label: 'Immagine' },
        option: { label: 'Opzioni precompilate' },
    },
    meta_file_doc: {
        description: { label: 'Descrizione' },
        file: { label: 'Immagine' },
    },
    user: {
        settings: { size: 'sm', },
        id: { skip: true },
        hash: { skip: true },
        created: { skip: true },
        first_name: { label: 'Nome' },
        last_name: { label: 'Cognome' },
        email: { label: 'Email' },
        role_id: { label: 'Ruolo' },
        customer_id: { label: 'Società', skip: true },
        sigla: { label: 'sigla', readonly: 'edit' },
        enabled: { label: 'Attivo' },
        customers: { label: 'Customers associati', readonly: 'edit' }
    },
    pratica: {
        settings: { size: 'sm', },
        codice: { readonly: 'edit', label: 'Codice pratica' },
        created: { readonly: true, label: 'Data creazione' },
        owner: { readonly: 'create', label: 'Proprietario'  },
        created_by: { readonly: true, label: 'Creato da' },
        closed: { label: 'pratica chiusa', skip: true, },
        operator: { skip: true, },
        custodian: { skip: true, },
        operator_notes: { skip: true, },
        newstate: { label: 'Stato della pratica', },
        template_id: { skip: true },
        imponibile: { label: 'Imponibile',  skip: true },
        imponibile_custodian: { skip: true },
        spese_non_imponibili: { label: 'Spese non imponibili',  skip: true },
        parcel_mode: { skip: true },
        parcel_mode_custodian: { skip: true },
        supplemento_pl: { skip: true },
        locomozione: { skip: true },
        visure: { skip: true },
        data_evasione: { readonly: true, label: 'Data evasione Owner' },
        data_evasione_custodian: { readonly: true, label: 'Data evasione Custodian' },
        spese_non_imponibili_custodian: { label: 'Spese non imponibili Custodian',  skip: true },
        supplemento_pl_custodian: { skip: true },
        locomozione_custodian: { skip: true },
        visure_custodian: { skip: true },
    },
    pratica_owner: { // owner
        settings: { size: 'sm', },
        codice: { readonly: 'edit', label: 'Codice pratica' },
        created: { readonly: true, label: 'Data creazione' },
        owner: { readonly: 'create', label: 'Proprietario'  },
        created_by: { readonly: true, label: 'Creato da' },
        closed: { label: 'pratica chiusa', skip: true, },
        operator: { skip: true, },
        custodian: { skip: true, },
        operator_notes: { skip: true, },
        newstate: { label: 'Stato della pratica', },
        template_id: { skip: true },
        imponibile: { label: 'Imponibile',  skip: true },
        imponibile_custodian: { skip: true },
        spese_non_imponibili: { label: 'Spese non imponibili',  skip: true },
        parcel_mode: { skip: true },
        parcel_mode_custodian: { skip: true },
        supplemento_pl: { skip: true },
        locomozione: { skip: true },
        visure: { skip: true },
        data_evasione: { label: 'Data evasione Owner' },
        data_evasione_custodian: { readonly: true, label: 'Data evasione Custodian' },
        spese_non_imponibili_custodian: { label: 'Spese non imponibili Custodian',  skip: true },
        supplemento_pl_custodian: { skip: true },
        locomozione_custodian: { skip: true },
        visure_custodian: { skip: true },
        config: { skip: true },
    },
    pratica_custodian: { // custodian
        settings: { size: 'sm', },
        codice: { readonly: 'edit', label: 'Codice pratica' },
        created: { readonly: true, label: 'Data creazione' },
        owner: { readonly: 'create', label: 'Proprietario'  },
        created_by: { readonly: true, label: 'Creato da' },
        closed: { label: 'pratica chiusa', skip: true, },
        operator: { skip: true, },
        custodian: { skip: true, },
        operator_notes: { skip: true, },
        newstate: { label: 'Stato della pratica', },
        template_id: { skip: true },
        imponibile: { label: 'Imponibile',  skip: true },
        imponibile_custodian: { skip: true },
        spese_non_imponibili: { label: 'Spese non imponibili',  skip: true },
        parcel_mode: { skip: true },
        parcel_mode_custodian: { skip: true },
        supplemento_pl: { skip: true },
        locomozione: { skip: true },
        visure: { skip: true },
        data_evasione: { readonly: true, label: 'Data evasione Owner' },
        data_evasione_custodian: { label: 'Data evasione Custodian' },
        spese_non_imponibili_custodian: { label: 'Spese non imponibili Custodian',  skip: true },
        supplemento_pl_custodian: { skip: true },
        locomozione_custodian: { skip: true },
        visure_custodian: { skip: true },
    },
    dati_pratica: {
        settings: { size: 'sm', },
        compiler: { readonly: true, label: 'Script di imporazione' },
        pratica_url: { readonly: true, label: 'Url della pratica' },
        mandante: { label: 'Mandante' },
        num_incarichi_pregressi: { label: 'Num. incarichi pregressi' },
        anno: { label: 'Anno' },
        studio: { label: 'Studio' },
        ramo: { label: 'Ramo' },
        societa_incaricata: { label: 'Società incaricata' },
        numero_pratica_esterno: { label: 'Num. pratica esterno' },
        numero_pratica_esterno_extended: { label: 'Num. pratica esterno (lungo)' },
        luogo_sinistro: { label: 'Luogo sinistro' },
        data_incarico: { label: 'Data incarico' },
        data_sinistro: { label: 'Data sinistro' },
        tipologia_danno: { label: 'Tipologia danno' },
        tipologia_incarico: { label: 'Tipologia incarico' },
        numero_sinistro: { label: 'Numero sinistro' },
        numero_polizza: { label: 'Numero polizza' },
        danno_presunto: { label: 'Danno presunto', helper: 'inserire la cifra con la virgola per i decimali senza altri simboli' },
        note: { label: 'Note' },
    },
    agenzia: {
        settings: { size: 'sm', },
        denominazione: { label: 'Denominazione' },
        descrizione: { label: 'Descrizione' },
        codice: { label: 'Codice' },
        titolo: { label: 'Titolo' },
        rappresentante: { label: 'Rappresentante' },
        referenti: { label: 'Referenti' },
        piva: { label: 'Partita IVA' },
        telefono: { label: 'Telefono' },
        cellulare: { label: 'Cellulare' },
        fax: { label: 'Fax' },
        email: { label: 'Email', helper: 'utilizzare " / " come separatore' },
        indirizzo: { label: 'Indirizzo' },
        cap: { label: 'CAP' },
        citta: { label: 'Città' },
        note: { label: 'Note' },
    },
    assicurato: {
        settings: { size: 'sm', },
        nome: { label: 'Nome' },
        cognome: { label: 'Cognome' },
        denominazione: { label: 'Denominazione' },
        cf: { label: 'Codice fiscale' },
        piva: { label: 'Partita IVA' },
        telefono: { label: 'Telefono' },
        cellulare: { label: 'Cellulare' },
        fax: { label: 'Fax' },
        email: { label: 'Email', helper: 'utilizzare " / " come separatore' },
        indirizzo: { label: 'Indirizzo' },
        cap: { label: 'CAP' },
        citta: { label: 'Città' },
        note: { label: 'Note' },
        iban: { label: 'Iban' },
        preesistenza: { label: 'Preeesistenza' },
    },
    controparte: {
        settings: { size: 'sm', },
        nome: { label: 'Nome' },
        cognome: { label: 'Cognome' },
        denominazione: { label: 'Denominazione' },
        cf: { label: 'Codice fiscale' },
        piva: { label: 'Partita IVA' },
        telefono: { label: 'Telefono' },
        cellulare: { label: 'Cellulare' },
        fax: { label: 'Fax' },
        email: { label: 'Email', helper: 'utilizzare " / " come separatore' },
        indirizzo: { label: 'Indirizzo' },
        cap: { label: 'CAP' },
        citta: { label: 'Città' },
        iban: { label: 'Iban' },
        note: { label: 'Note' },
    },
    referente: {
        settings: { size: 'sm', },
        gruppo: { label: 'Gruppo' },
        utente: { label: 'Utente' },
        ruolo: { label: 'Ruolo' },
        titolo: { label: 'Titolo' },
        nome: { label: 'Nome' },
        cognome: { label: 'Cognome' },
        denominazione: { label: 'Denominazione' },
        cf: { label: 'Codice fiscale' },
        piva: { label: 'Partita IVA' },
        telefono: { label: 'Telefono' },
        cellulare: { label: 'Cellulare' },
        fax: { label: 'Fax' },
        email: { label: 'Email', helper: 'utilizzare " / " come separatore' },
        indirizzo: { label: 'Indirizzo' },
        cap: { label: 'CAP' },
        citta: { label: 'Città' },
        note: { label: 'Note' },
    },
    ufficio: {
        settings: { size: 'sm', },
        gruppo: { label: 'Gruppo' },
        descrizione: { label: 'Descrizione' },
        titolo: { label: 'Titolo' },
        rappresentante: { label: 'Rappresentante' },
        referenti: { label: 'Referenti' },
        codice: { label: 'Codice' },
        telefono: { label: 'Telefono' },
        cellulare: { label: 'Cellulare' },
        fax: { label: 'Fax' },
        email: { label: 'Email', helper: 'utilizzare " / " come separatore' },
        indirizzo: { label: 'Indirizzo' },
        cap: { label: 'CAP' },
        citta: { label: 'Città' },
        note: { label: 'Note' },
    },
    search_pratica: {
        settings: { size: 'sm', },
        'assicurato.cognome,assicurato.denominazione': {
            search: 'contains_before_after',
            label: 'assicurato',
            helper: 'cerca la stringa in \'cognome\' e \'denominazione\'',
        },
        'dati_pratica.data_incarico__gte' : { label: 'Data incarico Da' },
        'dati_pratica.data_incarico__lte' : { label: 'Data incarico A' },
        data_evasione__gte                : { label: 'Data evasione Da' },
        data_evasione__lte                : { label: 'Data evasione A' },
    },
    customer: {
        label: { label: 'nome' },
        created: { readonly: true, label: 'data creazione' },
    },
    custodian: {
        settings             : { size: 'sm' },
        custodian            : { label: 'Custodian', helper: 'Assegnando il custodian affidi ad esso la copleta gestione della pratica' },
        imponibile           : { label: 'Imponibile', readonly: true },
        imponibile_custodian : { label: 'Imponibile per il Custodian' },
        exec_password        : { label: 'Password', helper: 'È necessario reinserire la password per completare questa operazione' }
    },
    email_template: {
        settings    : { size: 'sm' },
        label       : { label: 'Etichetta' },
        type        : { label: 'Tipo' },
        customer_id : { label: 'Customer' },
        body        : { label: 'Corpo' },
        active      : { label: 'Attivo' },
        created     : { skip: true },
        config      : { skip: true },
        id          : { skip: true },
    },
    reminder: {
        settings    : { size: 'sm' },
        to             : { label: 'Destinatari "to"' },
        cc             : { label: 'Destinatari "cc"' },
        bcc            : { label: 'Destinatari "ccn"' },
        docs           : { label: 'Dati da richiedere' },
        template1_id   : { label: 'Prima email' },
        template2_id   : { label: 'Email di sollecito' },
        body_email1    : { label: 'Corpo prima email', validators: [] },
        body_email2    : { label: 'Corpo solleciti' },
        active         : { label: 'Sollecito attivo', readonly: 'add' },
        config         : { skip: true },
        task_id        : { skip: true },
        codice_pratica : { skip: true },
        customer_id    : { skip: true },
        created        : { skip: true },
        id             : { skip: true },
    },
    sollecito_document: {
        settings    : { size: 'sm', title_form: 'Documento per il sollecito' },
        label       : { label: 'Nome' },
        customer_id : { label: 'Customer' },
        active      : { label: 'Attivo' },
        created     : { skip: true },
        type        : { skip: true },
        id          : { skip: true },
        config      : { skip: true },
        
    },
    manual_email: {
        settings    : { size: 'sm' },
        to             : { label: 'Destinatari "to"' },
        cc             : { label: 'Destinatari "cc"' },
        bcc            : { label: 'Destinatari "ccn"' },
        docs           : { label: 'Dati da richiedere' },
        template1_id   : { label: 'Template' },
        body_email1    : { label: 'Corpo', validators: [] },
        active         : { label: 'Sollecito attivo' },
        codice_pratica : {},
        customer_id    : {},
    },

}
