////////////////////////////////////////

let date_fields = [
    { value: '',                      text: '- - - - - - -' },
    { value: 'data_sinistro',         text: 'Data Sinistro' },                      // In pratica_incarico
    { value: 'data_denuncia',         text: 'Data Denuncia' },                // In pratica_incarico
    { value: 'data_affidamento',      text: 'Data Incarico' },                 // In pratica_incarico
    { value: 'data_primo_contatto',   text: 'Data Primo Contatto' },    // In pratica_incarico
    { value: 'data_sopralluogo',      text: 'Data Sopralluogo' },           // In pratica_incarico
    { value: 'data_videoperizia',     text: 'Data Videoperizia' },          // In pratica_incarico
    { value: 'data_invio_atto',       text: 'Data Invio Atto' },                // In pratica_incarico
    { value: 'data_evasione',         text: 'Data Evasione' },                  // In pratica_incarico
    { value: 'data_effetto',          text: 'Data Effetto' },                       // In pregs
    { value: 'data_termine',          text: 'Data Termine' },                   // in pregs
    { value: 'data_pagamento_premio', text: 'Data Pagamento Premio' },  // In pregs
    { value: 'data_nascita',          text: 'Data di Nascita' },                // in pregs
];

export default date_fields;

