

module.exports = {
    cols: [
          { 
              row1: {
                  field1: 'denominazione',
                  field2: 'codice',
              },
              row2: {
                  field1: 'partita_iva',
                  field2: 'codice_destinatario'
              },
              field1: 'note',
          },
    ]
};

