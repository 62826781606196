//////////////////////////////

module.exports = (type) => {
    var schema = {
        user_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'users',
                key         : 'id',
                constraints : true,
                store_name  : 'user',
            }
        },
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        role_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'roles',
                key         : 'id',
                constraints : true,
                store_name  : 'role',
            }
        },
        id: {
          type: type.INTEGER,
          primaryKey: true,
          autoIncrement: true
        },
    };

    return schema;
}


