<script>

const nprogress = require('nprogress');
const sparkmd5  = require('spark-md5');

const moment    = require('moment');
const bli       = require('blueimp-load-image');

import bnvform from '@/vue-model-mirror/bnvform';
import card_gallery from './card_gallery';
const schemas = require('@/schemaconfig');
const config  = require('@/config.js')[ process.env.NODE_ENV || 'development' ];

export default {
    name: 'documents',
    components: {
        bnvform,
        'card-gallery': card_gallery,
    },
    props: [ 'task', 'add', 'isp_number' ],
    mounted () {













    },
    watch: {
        createdws(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch('task/executed');
                if ( this.$store.state.task.error ) {
                } else {
                    if ( this.perc_completed === 100 ) {
                        this.confirm_message = this.upload_counter.tot + ' documenti caricati con successo.';
                        setTimeout( () => {
                            this.$root.$emit('bv::toggle::collapse', 'collapse-upload-progress');
                            if ( this.multiple_update_visible ) { this.multiple_update_visible = false; }
                        }, 2000);
                    }
                }
            }
        },
        last_image_managed( new_val, old_val ) {
            if ( new_val ) {
                for ( var i = 0; i < this.queue.length; i++ ) {
                    if ( this.queue[i].file.name === new_val ) { break; }
                }
            }
        },
    },
    computed: {
        createdws() { return this.$store.state.task.createdws; },
        states() { return this.$store.state.localdb_documents.states; },
        upload_counter () { return this.$store.state.task.upload_counter; },
        last_image_managed () { return this.$store.state.task.last_image_managed; },
        ws_message() { return this.$store.state.task.message; },
        queue: {
            get () { return this.$store.state.task.queue; },
            set ( value ) { this.$store.dispatch( 'task/set_queue', value ); },
        },
        upload_terminated() { return this.$store.state.task.upload_terminated; },
        perc_completed() { return this.$store.getters['task/perc_completed']; },

        load_items() {
            return this.$store.state.localdb_documents.loading;
        },
        items() { return this.$store.state.localdb_documents.items; },
        latitude() { return this['location'].hasOwnProperty('latitude') ? this['location'].latitude : null; },
        longitude() { return this['location'].hasOwnProperty('longitude') ? this['location'].longitude : null; },
        accuracy() { return this['location'].hasOwnProperty('accuracy') ? this['location'].accuracy : null; },
        user () {
            return JSON.parse( localStorage.getItem('user') );
        },
    },
    created () {
        this.$store.dispatch('localdb_documents/get_all');







    },
    methods: {
        file_image_saved( data ) {
            this.$store.dispatch('task/add_image', data);
            this.meta_form_key += 1;
        },
        update_form_data( ref, payload ) {
            var data = {};
            if ( Object.keys( payload )[ 0 ] === 'option' ) {
                data.description = payload.option;
            }
            this.$refs[ ref ].set_payload( data );
        },
        delete_confirm(image) {
            this.boxOne = ''
            this.$bvModal.msgBoxConfirm('Vuoi eliminare il file "' + image.value.file_name + '"?', { buttonSize: 'sm', autoFocusButton: 'ok', })
                .then(value => {
                    if ( value === true ) { this.$store.dispatch('task/delete_image', image); }
                })
                .catch(err => {
                    console.log( err );
                });
        },
        remove_from_queue_confirm(image) {
//          this.boxOne = ''
//          this.$bvModal.msgBoxConfirm('Vuoi eliminare il file "' + image.file.name + '" dalla coda?')
//              .then(value => {
//                  if ( value === true ) { this.remove_from_queue( image ); }
//              })
//              .catch(err => {
//                  console.log( err );
//              });
            this.modal_image = image;
            this.toggle_modal('modal-manage-image');
        },
        toggle_modal(modal_id = 'modal-filter') {
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        download_image() {
            this.toggle_modal('modal-manage-image');
            require("downloadjs")(this.modal_image.b64, this.modal_image.name, this.modal_image.type);
            this.modal_image = null;
        },
        remove_image() {
            this.toggle_modal('modal-manage-image');
            this.remove_from_queue( this.modal_image.id );
            this.modal_image = null;
        },
        remove_from_queue(id) {
          this.$store.dispatch('localdb_documents/destroy', id);
        },
        count_images( metas ) {
            for ( var i = 0; i < metas.length; i++ ) {
                if ( metas[ i ].type === 'file' ) { return 1; }
            }
            return 0;
        },
        async get_md5_blob( blob ) {
            return new Promise( ( resolve, reject ) => {
                var a = new FileReader();
                a.readAsArrayBuffer(blob);
                a.onloadend = function () {
                  //console.log(sparkmd5.ArrayBuffer.hash(a.result));
                  resolve( sparkmd5.ArrayBuffer.hash(a.result) );
                };
            });
        },
        async get_md5_file( file ) {
            return new Promise((resolve, reject) => {
                var blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice,
                    chunkSize = 2097152,                             // Read in chunks of 2MB
                    chunks = Math.ceil(file.size / chunkSize),
                    currentChunk = 0,
                    spark = new sparkmd5.ArrayBuffer(),
                    fileReader = new FileReader();
            
                fileReader.onload = function (e) {
                    spark.append(e.target.result);                   // Append array buffer
                    currentChunk++;
            
                    if (currentChunk < chunks) {
                        loadNext();
                    } else {
                        //console.log('finished load_items');
                        //console.info('computed hash', spark.end());  // Compute hash
                        resolve( spark.end() );
                    }
                };
            
                fileReader.onerror = function () {
                    console.warn('oops, something went wrong.');
                };
            
                function loadNext() {
                    var start = currentChunk * chunkSize,
                        end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;
            
                    fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
                }
            
                loadNext();
            });
        },
        get_url( item ) { // commentato per tornare alla versione precedente
           return item.b64;
        },
        async adjust_image(input) {
            return new Promise( ( resolve, reject ) => {
                bli(
                  input,
                  function (img, data) {
                    if (data.imageHead && data.exif) {
                      // Reset Exif Orientation data:
                      bli.writeExifData(data.imageHead, data, 'Orientation', 1);
                      var exif = data.exif;
                      img.toBlob(function (blob) {
                          var blob = bli.replaceHead(blob, data.imageHead, function ( blob ) {
                              // do something with newBlob
                              resolve( { blob, exif });
                          })
                      }, 'image/jpeg')
                    }
                  },
                  { meta: true, orientation: true, canvas: true }
                )
            });
        },
        get_coordinates( data, direction ) {
            var dd = data[0] + data[1]/60 + data[2]/(60*60);
            if (direction == 'S' || direction == 'W') {
                dd = dd * -1;
            } // Don't do anything for N or E
            return dd;
        },
        async add_to_queue( data ) {
            var file = data.file;
            var blob = null;
            var latitude = null;
            var longitude = null;
            try {
                var converted_image = await this.adjust_image( file );
                //console.log(
                //    Object.keys( converted_image ),
                //    typeof converted_image.blob,
                //    typeof converted_image.exif,
                //);
                if ( Object.keys( converted_image ).length !== 2 || typeof converted_image.blob !== 'object' || typeof converted_image.exif !== 'object' ) {
                    throw 'not valid response';
                }
                blob = converted_image.blob;




            } catch ( error ) { console.log( error ); }
            data.url  = URL.createObjectURL( data.file );
            if ( blob ) {
                data.hash = await this.get_md5_blob( blob );
            } else {
                data.hash = await this.get_md5_file( data.file );
            }
            data.status = {
                variant : 'warning',
                text    : 'in coda',
                code    : 0,
            };
            this.$store.dispatch( 'task/push_to_queue', data );
            data.container      = this.container;
            data.datetime       = moment().format();
            if ( latitude && longitude ) {
                //console.log( latitude, longitude );
                data.latitude  = latitude;
                data.longitude = longitude;
            } else {
                data.latitude  = this.latitude;
                data.longitude = this.longitude;
            }
            data.accuracy       = this.accuracy;
            data.codice_pratica = this.task.codice_pratica;
            data.taskid         = this.task.id;
            data.tag            = `isp${ this.isp_number }`;
            data.ispezione_type = 'document';
            data.type           = blob ? 'image/jpeg' : data.file.type;
            data.size           = blob ? blob.size : data.file.size;
            data.name           = data.file.name;
            data.retries        = 0;
            data.user_id        = this.user.id;
            this.meta_form_key += 1;
            this.$store.dispatch('task/set_upload_terminated', false);
            var self = this;
            var reader = new FileReader();
            if ( blob ) {
                reader.readAsDataURL(blob); 
            } else {
                reader.readAsDataURL(data.file);
            }
            reader.onload = function(e) {
                data.b64 = e.target.result;
                delete data.file;
                self.$store.dispatch('localdb_documents/add', data);
                self.make_toast( 'DOCUMENTI', 'il file è stato aggiunto alla coda' );
            }
        },
        make_toast(title, body) {
            this.$bvToast.toast( body, {
                title         : title,
                autoHideDelay : 5000,
                appendToast   : true,
            });
        },
        upload_queue() {
            this.confirm_message = null;
            if ( this.queue.length > 0 ) { this.multiple_update_visible = true;  }







            var data = {
                payload: {
                    codice_pratica : this.task.codice_pratica,
                    task_id        : this.task.id,
                    tag            : `isp${ this.isp_number }`,
                    ispezione_type : 'document',



                },
                container: 'documents',
            };
            this.$store.dispatch( 'task/set_upload_queue' );
            this.$store.dispatch( 'task/createws_multiple', data );
        },
    },
    data () {
        return {
            form_reload        : 0,
            started            : false,
            action             : null,
            selected_tab_index : 0,
            tabnum             : 0,
            form_key           : 0,
            inline             : false,
            schema_meta_file   : schemas.meta_file_doc,
            backend_url        : config.backend_url,
            adding             : false,
            meta_form_key      : 0,
            multiple_update_visible : false,
            'location'         : {},
            location_error     : false,
            location_error_message : 'unknown error',
            confirm_message    : null,
            container          : 'document',
            modal_image        : null,
        }
    },
};

</script>

<template>

    <div>

    <b-container v-if="!adding" style="margin-top: 20px; margin-bottom: 100px;">

            <!-- <div class="text-center" v-if="load_items === true">
                <div class="spinner-grow text-dark" role="status" style="width: 6rem; height: 6rem;">
                    <span class="sr-only">Loading...</span>
                </div>
            </div> -->

        <template>
            <!-- <b-row style="margin-bottom: 10px" v-if="load_items === false"> -->
            <b-row style="margin-bottom: 10px">
                <b-col v-if="add" align-self="start" class="text-left">
                    <b-button @click="adding = true" size="sm" variant="outline-primary"><icon name="plus"/> aggiungi</b-button>
                </b-col>
            </b-row>
    
    

            <div>
                <b-alert
                    show
                    dismissible
                    v-if       = "confirm_message"
                    variant    = "info"
                    @dismissed = "confirm_message = null"
                    >
                    <icon name="info-circle"/> {{ confirm_message }}
                </b-alert>
                <b-collapse id="collapse-upload-progress" class="mt-2" :visible="multiple_update_visible">
                    <b-progress max="100" show-progress animated>
                        <b-progress-bar :value="perc_completed" :label="perc_completed + '%'"></b-progress-bar>
                    </b-progress>
                    <small id="emailHelp" class="form-text text-muted">upload in corso...</small>
                </b-collapse>
            </div>
        </template>

        <!-- elementi in coda -->
        <b-row>
            <b-col v-for="image in items" xs="12" md="4" lg="3" :key="image.id">
                <b-card
                    img-top
                    v-if            = "image.container === 'document'"
                    style           = "margin: 10px; padding: 5px; opacity: 0.6"
                    :img-src        = "get_url( image )"
                    img-alt         = "Image"
                    :border-variant = "states.get( image.status.code )"
                    @click          = "remove_from_queue_confirm(image)"
                    >
                    <b-card-text>{{ image.description }}</b-card-text>
                    <b-badge v-if="image.status" :variant="states.get( image.status.code )">{{ image.status.text }}</b-badge>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-for="image in task.images.filter( x => x.value.ispezione_type === 'document' )" xs="12" md="4" lg="3" :key="image.id">
                <card-gallery
                    v-if   = "image.value.ispezione_type === 'document'"
                    :image = "image"
                    @canc  = "delete_confirm(image)"
                />
            </b-col>
        </b-row>

        <!-- elementi in db -->
        <b-row v-if="task.images.filter( x => x.value.ispezione_type === 'document' ).length === 0 && queue.length === 0">
            <b-col style="margin-top: 20px;">
                <p style="color: grey;" class="text-center"><b-icon icon="info" font-scale="2"></b-icon><br/>Non ci sono documenti</p>
            </b-col>
        </b-row>

    </b-container>

    <b-container v-else-if="adding" style="margin-top: 20px; margin-bottom: 70px;">

        <b-row>
            <b-col xs="12" md="10" lg="8" style="margin: auto" class="text-left">
                <b-button @click="adding = false" size="sm" variant="outline"><icon name="arrow-left" style="margin-right: 6px"/>Torna ai documenti</b-button>
            </b-col>
        </b-row>

        <b-row>
            <b-col xs="12" md="10" lg="8" style="margin: auto">
                <bnvform
                    name       = "meta_file"
                    :key       = "meta_form_key"
                    :schema    = "schema_meta_file"
                    :options   = "{}"
                    :record    = "{ task_id: task.id }"
                    :submit    = "false"
                    :inline    = "false"
                    :ref       = "'meta_file_form_' + task.id"
                    :exit      = "false"
                    @submitted = "add_to_queue"
                    @change    = "update_form_data('meta_file_form_' + task.id, $event)"
                ></bnvform>
                    <!-- @closed  = "adding = false"
                    @saved   = "file_image_saved"
                    @change  = "update_form_data('meta_file_form_' + task.id, $event)" -->
            </b-col>
        </b-row>

    </b-container>

    <!-- modal manage image -->
    <b-modal
        id    = "modal-manage-image"
        title =  "Gestione Immagine"
        >
        Scegliere l'operazione da eseguire
        <template #modal-footer="{ ok, cancel, hide }">
            <b-button size="sm" variant="success" @click="download_image()">
                <icon name="download" style="margin-right: 5px" />Scarica
            </b-button>
                <b-button size="sm" variant="danger" @click="remove_image()">
                <icon name="trash" style="margin-right: 5px" />Elimina
            </b-button>
        </template>
    </b-modal>

</div>

</template>

