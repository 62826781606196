<script>

import { formpage } from '@/vue-model-mirror';

import tabs from './data/';

export default {
    name: 'pratica-data-container',
    props: [ 'codice_pratica' ],
    computed: {
    },
    methods: {
        get_tab_title ( tab_name ) {
            return tab_name.charAt(0).toUpperCase() + tab_name.slice(1);
        },
    },
    created() {},
    data () {
        return {
            new_tabs : {
                incarico    : null,
                cliente     : null,
                assicurato  : null,
                controparte : 'Controparti',
                riparatore  : 'Riparatori',
                autorita    : 'Autorità',
                testimone   : 'Testimoni',
            },
            tabs,
        }
    },
};

</script>

<template>

    <b-tabs pills>
        <b-tab v-for="cmp in Object.keys( new_tabs )" :title="new_tabs[ cmp ] || get_tab_title( cmp )" :key="cmp" lazy>
            <component
                style           = "margin-top: 20px"
                :ref            = "'tab_' + cmp"
                :is             = "tabs[ cmp ]"
                :codice_pratica = "codice_pratica"
            />
        </b-tab>
    </b-tabs>

</template>

