<script>

import pratica_subtab_base from './base_data';
import Address from '@/registry/classes/address';

export default {
    name: 'pratica-subtab-base-multi-item',
    extends: pratica_subtab_base,
    props: [ '' ],
    components : {},
    computed : {
        item_index() {
            if ( this.selected_index ) { return this.selected_index; }
            return this.item_ids.length > 0 ? this.item_ids[0] : null;
        },
    },
    watch: {
        item_index( new_value ) {
            if ( new_value && this.new_item === false ) {
                this.selected_index = new_value;
                this.reload('main_key');
            }
        },
        selected_index( new_value ) {
            if ( new_value && this.new_item === true ) { this.new_item = false; }
        },
        registry_setted( new_value ) {
            if ( new_value ) {
                //console.log('ricarico le anagrafiche');
                this.force_reload();
                this.reload('main_key');
            }
        },
        create_relation_executed( new_value ) {
            if ( new_value === null ) { return; }
            if ( this.item_ids.includes( String( new_value ) ) ) {
                this.force_reload();
                this.selected_index = new_value;
            }
        },
        delete_executed( new_value ) { // si somma al parent
            if ( new_value ) {
                this.force_reload();
                this.set_first_item();
            }
        },
    },
    methods : {
        set_first_item() {
            if ( this.item_ids.length > 0 ) {
                this.selected_index = this.item_ids[0];
            }
        },
        set_selected_index( value ) {
            this.selected_index = value;
            this.addressobj = new Address();
            this.addressobj.store = this.$store;
            this.set_addressobj_value();
        },
        edit_registry( role_name ) {
            let fn_name = `get_${ role_name }`;
            let preg = this.prareg[ fn_name ]( this.item_index );
            this.role_id = preg.role_id;
            this.$refs.modal_association.mao.record_id          = preg.id;
            this.$refs.modal_association.mao.registry_father_id = preg.registry_id;
            this.$refs.modal_association.mao.role_id            = preg.role_id;
            this.$refs.modal_association.set_editreg_component( this.registry_template_ids.get( preg.template_id ).template_name )
            this.toggle_modal('modal-edit-registry');
        },
        unlink( label ) {
            this.unlink_payload = { main: label, dependencies: this.prareg.get_related_dependencies( label, this.item_index ) };
            this.toggle_modal('confirm-unlink');
        },
        unlink_dependencies() {
            let ids = this.prareg.get_ids( this.unlink_payload, this.item_index );
            this.delete_registries( { ids, codice_pratica: this.codice_pratica } );
        },
    },
    created() {
        this.set_addressobj_value();
    },
    data() {
        return {
            new_item       : false,
            selected_index : null,
            unlink_payload : {},
        }
    },
}

</script>

<template>
    <div>
    </div>
</template>

<style>
</style>

