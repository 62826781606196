////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'newstate',
    send_alert    : false,
    default_order : 'key ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    filters: { active: true, }
};

const actions = {
    ...base.actions,
    get_all( { commit, state, getters, rootGetters }, _data = { options: {} } ) { // ok
        commit('start_request');
        commit('success_list', rootGetters['statemanager/states'])
    },
};


export default {
    namespaced : true,
    ...base,
    state,
    actions,
};

