//////////////////////////////

module.exports = (type) => {

    var schema = {
        sesso: {
            type: type.ENUM( 'Maschio', 'Femmina', 'Altro' ),
            allowNull: false,
            options: {
                store_name  : 'sesso', // basta lo store
            },
        },
        data_nascita: {
            type: type.DATEONLY,
            allowNull: false,
        },
        comune_nascita_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'comuni',
                key         : 'id',
                constraints : true,
                store_name  : 'comune_nascita',
                type        : 'autocomplete'
            },
        },
    };

    return schema;
}

