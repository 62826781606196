//////////////////////////////

// https://stackoverflow.com/questions/7922744/how-can-i-search-all-columns-in-a-table

module.exports = (type) => {

    var schema = {
        /* GENERALE */
        denominazione: {
            type: type.STRING(64),
            allowNull: true,
        },
        codice_fiscale: {
            type: type.STRING(64),
            allowNull: true,
            options: {
                type : 'codfisc',
            },
        },
        iban: {
            type: type.STRING(64),
            allowNull: true,
            options: {
                type  : 'iban',
            }
        },
        codice_destinatario: {
            type: type.STRING(64),
            allowNull: true,
        },
        note: {
            type: type.TEXT,
            allowNull: true,
        },
    };

    return schema;
}


