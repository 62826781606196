<script>

import bnvform from '@/vue-model-mirror/bnvform';
const nprogress = require('nprogress');

const schemas = require('@/schemaconfig');
const manager = require('@/helpers/placeholder_manager').default;

export default {
    components: {
        bnvform,
    },
    props: [ 'schema_from_route', ],
    mounted () {
        this.$nextTick(function () { // stop loader
            nprogress.done(true);
        })
    },
    computed: {
        store () { return this.$store.state[ this.schema_name ]; },
        qry_executed () {
            return this.$store.state[ this.schema_name ].executed;
        },
        record () {
            return typeof this.$route.params.id !== 'undefined' ? this.store.obj : {};
        },
        title () { // default schema_name.toUpperCase()
           return this.action === 'add' ? 'Email Template' : 'Modifica di un template';
        },
        inline () {
        },
        placeholders() {
            return manager.get_placeholders();
        },
    },
    created () {
        this.schema_name = this.$route.params.schema || this.schema_from_route;
        this.schema = schemas[ this.schema_name ];
        this.options = {};
        // gestione add/edit
        var record_id = this.$route.params.id;
        if ( typeof record_id !== 'undefined' ) {
            this.action = 'edit';
            this.$store.subscribe((mutation, state) => {
                if ( this.started === false && mutation.type === `${this.schema_name}/success_obj` ) {
                    this.started = true;
                }
            });
            this.$store.dispatch(`${this.schema_name}/get_by_id`, { id: record_id, include: true });
        } else {
            this.action = 'add';
            this.started = true;
        }
    },
    methods: {
        reset_form: function() {
            this.form_reload += 1;
        },
        operation_success: function( data ) {
            if ( this.schema.submit !== false ) {
                var title = this.action === 'add' ? `nuovo ${ data.label }` : `modifica ${ data.label }`;
                var body = this.action === 'add' ? 'record creato con successo' : `record salvato con successo`;
                this.make_toast( title.toUpperCase(), body );
            }
        },
        make_toast(title, body) {
            this.$bvToast.toast( body, {
                title         : title,
                autoHideDelay : 5000,
                appendToast   : true,
            });
        },
        set_placeholder( plhd ) {
            var body_written = this.$refs.email_template_form.get_value_by_element_name('body');
            if ( plhd !== 'documenti' || body_written.includes('{{documenti}}') === false ) {
                body_written += ( body_written.endsWith(' ') || body_written.endsWith('\n') ) ? `{{${ plhd }}}` : ` {{${ plhd }}}`;
            }
            this.$refs.email_template_form.set_payload( { body: body_written }, true );
            this.$refs.email_template_form.$refs.bnvelement_body[0].$refs.input.focus();
        },
    },
    data () {
        return {
            form_reload : 0,
            started     : false,
            action      : null,
        }
    },
};

</script>

<template>

    <div>
        <h3 style="text-align: center; padding-bottom: 14px;">{{ title }}</h3>
        <bnvform
            v-if       = "started === true"
            ref        = "email_template_form"
            :name      = "schema_name"
            :schema    = "schema"
            :options   = "options"
            :record    = "record"
            :inline    = "inline"
            :key       = "form_reload"
            @reset     = "reset_form"
            @submitted = "operation_success"
            @saved     = "operation_success"
        ></bnvform>
        <b-card style="">
            <p style="text-align: center"><b>Clicca un placeholder per inserirlo nel corpo del template</b></p>
            <span v-for="plhd in placeholders" style="font-size: 1.3em; margin-right: 10px; cursor: pointer;" @click="set_placeholder(plhd)">
                <b-badge variant="info">{{ plhd }}</b-badge>
            </span>
        </b-card>
    </div>

</template>

