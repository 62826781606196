//////////////////////////////////////////////////////////////
//
// stati:
//   - 0 : in coda                                   warning
//   - 1 : elaborazione (upload ok)                  warning
//   - 2 : ERRORE hash sbagliato                     danger
//   - 3 : ERRORE upload (altro)                     danger
//   - 4 : ERRORE fallita gestione dell'immagine     danger
//   - 5 : ERRORE max retries                        danger
//   - 6 : ERRORE esecuzione post                    danger
//
//////////////////////////////////////////////////////////////

const axios = require('axios');

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];
import { authHeader } from '@/helpers';

function base64ToBlob(prebase64, mime) 
{
    var base64 = prebase64.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
    mime = mime || '';
    var sliceSize = 1024;
    var byteChars = window.atob(base64);
    var byteArrays = [];

    for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
        var slice = byteChars.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: mime});
}

export const localdb_images = {

    namespaced: true,
    state: {
        db               : null,
        store_name       : 'images',
        items            : null,
        loading          : false, // caricamento file da indexedDB
        upload           : false, // upload in corso
        file_upload_byte : 0,
        file_byte        : 0,
        file_name        : null,
        max_retries      : 10,
        states           : new Map([
            [ 0, 'warning' ],
            [ 1, 'warning' ],
            [ 2, 'danger' ],
            [ 3, 'danger' ],
            [ 4, 'danger' ],
            [ 5, 'danger' ],
            [ 6, 'danger' ],
        ]),
    },
    getters: {
        file_perc_completed: state => {
            if ( state.file_upload_byte === 0 || state.file_byte === 0 ) { return 0; }
            var perc = Math.round( state.file_upload_byte * 100 / state.file_byte );
            return perc > 100 ? 100 : perc;
            return state.items.filter( x => x.status.code === 0 );
        },
        file_to_upload: ( state, getters ) => {
            return getters.upload_queue.length;
        },
        upload_queue: state => {
            if ( !state.items ) { return []; }
                return state.items.filter( x => x.status.code === 0 || x.status.code === 6 );
        },
        uploaded_items: state => {
            if ( !state.items ) { return []; }
            return state.items.filter( x => x.status.code === 1 );
        },
    },
    actions: {
        async verify_uploaded( { state, commit, getters, dispatch } ) {
            await dispatch('get_all_silent');
            var items = getters.uploaded_items;
            if ( items.length === 0 ) { return; }
            var url = `${config.backend_url}model/upload_queue`;
            var hashes = items.map( x => x.hash );
            var headers = authHeader();
            var options = { headers };
            var obj = await axios.post( url, hashes, options )
                .then( response => response.data )
                .catch( error => { console.log( error ); return undefined } );
            if ( !obj ) { return; }
            var hashes = Object.keys( obj );
            for ( var i = 0; i < hashes.length; i++ ) {
                if ( obj[ hashes[ i ] ] === 'ok' ) {
                    console.log( 'hash ' + hashes[ i ] + ' da eliminare' );
                    await dispatch( 'destroy_by_hash', hashes[ i ] );
                } else if ( obj[ hashes[ i ] ] === 'error' ) {
                    await dispatch( 'update_item_by_hash', { hash: hashes[ i ], code: 4, text: 'errore elaborazione' } );
                }
            }
        },
        set_db( { commit }, db ) { commit( 'set_db', db ); },
        async add( { commit, state, dispatch }, item ) {
            //console.log( item );
            commit('set_loading');
            await state.db.add( state.store_name, item );
            await dispatch('get_all');
            setTimeout( () => {
                dispatch('start_upload');
            }, 1000);
        },
        async get_all( { commit, state } ) {
            commit('set_loading');
            var items = await state.db.getAll(state.store_name);
            commit('set_items', items);
            commit('set_loading', false);
        },
        async get_all_silent( { commit, state } ) {
            var items = await state.db.getAll(state.store_name);
            commit('set_items', items);
        },
        async update( { commit, state, dispatch }, conf ) {
            await state.db.put( state.store_name, conf );
            dispatch('get_all');
        },
        async destroy( { commit, state, dispatch }, id ) {
            commit('set_loading');
            await state.db.delete( state.store_name, id );
            await dispatch('get_all');
        },
        async destroy_by_hash( { commit, state, dispatch }, hash ) {
            var items = await state.db.getAll(state.store_name);
            for ( var i = 0; i < items.length; i++ ) {
                if ( items[ i ].hash !== hash ) { continue; }
                await dispatch( 'destroy', items[ i ].id );
                break;
            }
        },
        async update_item_by_hash( { commit, state, dispatch }, data ) {
            var items = await state.db.getAll(state.store_name);
            for ( var i = 0; i < items.length; i++ ) {
                if ( items[ i ].hash !== data.hash ) { continue; }
                var item = items[ i ];
                var status = item.status;
                status.code = data.code;
                status.text = data.text;
                item.status = status;
                await dispatch( 'update', item );
                break;
            }
        },
        async start_upload( { commit, state, dispatch, getters, rootGetters } ) {
            if (getters.upload_queue.length === 0 ) {
                setTimeout( () => {
                    setTimeout( () => {
                        commit('set_file_upload_byte', 0);
                    }, 1000);
                }, 1000);
                return;
            }
            if ( rootGetters['task/ws_active'] === false ) { return; }
            if ( state.upload === true ) { return; }
            //console.log('parto con l\'upload');
            var item = getters.upload_queue[0];
            // check retries number
            if ( item.retries === state.max_retries ) {
                item.status = {
                    variant : 'danger',
                    text    : 'massimo numero di tentativi',
                    code    : 5
                };
                dispatch( 'update', item );
                dispatch( 'start_upload' );
                return;
            }
            commit('set_file_byte', item.size);
            commit('set_file_upload_byte', 0);
            commit('set_file_name', item.option);
            commit('set_upload');
            var url = `${config.backend_url}model/folder/ispezione/${ getters.upload_queue[0].container }`;
            var payload = {
                codice_pratica : item.codice_pratica,
                task_id        : item.task_id,
                tag            : item.tag,
                user_id        : item.user_id,
                ispezione_type : item.ispezione_type,
                latitude       : item.latitude,
                longitude      : item.longitude,
                accuracy       : item.accuracy,
                //file           : base64ToBlob( item.b64, item.type ),
                hash           : item.hash,
                option         : item.option,
                description    : item.description,
            };
            var headers = authHeader();
            delete headers['Content-Type'];
            const form = new FormData();
            Object.keys( payload ).map( x => {
                form.append(x, payload[ x ]);
            });
            form.append( 'file', base64ToBlob( item.b64, item.type ), item.name );
            var options = {
                headers,
                onUploadProgress: progress_event => {
                    commit( 'add_file_upload_byte', progress_event.loaded );
                }
            };
            item.retries += 1;
            dispatch( 'update', item );
            await axios.post( url, form, options )
                .then( response => {
                    commit('set_upload', false);
                    if ( response.status !== 200 ) {
                        item.status = {
                            variant : 'danger',
                            text    : 'upload error',
                            code    : response.data.message === 'file doesn\'t match checksum' ? 2 : 3
                        };
                    } else {
                        item.job_id = response.data.job_id;
                        item.status = { variant: 'danger', text: 'in elaborazione', code: 1 };
                    }
                    dispatch( 'update', item );
                    commit('set_upload', false);
                    setTimeout( () => {
                        dispatch('start_upload');
                    }, 1000)
                })
                .catch( error => {
                    commit('set_upload', false);
                    item.status = {
                        variant : 'danger',
                        text    : 'errore invio dati',
                        code    : 6
                    };
                    dispatch( 'update', item );
                    setTimeout( () => { dispatch('start_upload'); }, 1000);
                    console.log( error );
                });
        },
    },
    mutations: {
        set_db( state, db ) { state.db = db; },
        set_loading( state, value = true ) { state.loading = value; },
        set_items( state, items ) {
            state.items = items.sort((a, b) => a.id - b.id);
        },
        set_upload( state, value = true ) { state.upload = value; },
        set_file_byte( state, value ) { state.file_byte = value; },
        set_file_name( state, value ) { state.file_name = value; },
        add_file_upload_byte( state, value ) { state.file_upload_byte += value; },
        set_file_upload_byte( state, value ) { state.file_upload_byte = value; },
    },

}


