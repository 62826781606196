<script>

import bnvbase from './bnvbase.vue';

export default {
    extends: bnvbase,
    props: [ 'store', ],
    computed: {
        options () {
            return this.$store.state[ this.store ];
        },
        _disabled () {
            if ( this.disabled ) { return true; }
            if ( this._readonly ) { return true; }
            return false;
        }
    },
    created () {
        if ( this.$store.state[ this.store ].force_reload === true || this.$store.state[ this.store ].items === null ) {
            this.$store.dispatch(this.store + '/get_all');
        }
        this.model = !this.value ? new Array() : this.value.map( x => x.id ); // sovrascrive il parent per ottenere un array vuoto
    },
    methods: {
        _return_model() {
            return JSON.parse( JSON.stringify( this.model ) );
        },
        _validate_allow_null: function() {
            if ( this.allownull === false ) {
                if ( this.model.length === 0 ) {
                    this.error_message = 'selezionare almeno un\'opzione';
                    this.error_component = true;
                    return false;
                }
            }
            return true;
        },
        set_option( id ) {
            var index = null;
            if ( this.model.includes( id ) ) {
                this.model = this.model.filter( x => x !== id );
            } else {
                this.model.push( id );
            }
        },
    }
};

</script>

<template>

    <div class="form-group">
        <label :for="'bnv-form-' + name">{{ get_label() }}</label>
        <div ref="input" class="tomany-container form-control" v-bind:class="{ 'loading': disabled || options.loading, 'is-invalid error-div': error_component }">
            <!-- MANY-TO-MANY SELECTOR -->
            <div v-if="options.loading === false">
                <template v-for="option in options.items">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            name="inlineRadioOptions"
                            :key="option.id"
                            :id="'bnv-form-' + name + '_option_' + option.id"
                            :checked="model.includes( option.id )"
                            @change="set_option(option.id)"
                            :disabled="_disabled"
                            :readonly="_readonly"
                            />
                        <label class="form-check-label" v-bind:class="{ 'option-selected': model.includes( option.id ) }" for="'bnv-form-' + name + '_option_' + option.id">
                            {{ option.label }}
                        </label>
                    </div>
                </template>
            </div>
            <!-- LOADER -->
            <div v-if="options.loading === true" class="text-center" style="margin-top: 5px;">
                <div class="spinner-grow text-dark" role="status" style="width: 6rem; height: 6rem;">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <input type="hidden" class="form-control" v-bind:class="{ 'is-invalid': error_component }" :id="'bnv-form-' + name">
        <div class="invalid-feedback">
            {{ get_error_message() }}
        </div>
    </div>

</template>

<style scoped>

.tomany-container {
    height: 120px;
    background-color: white;
    overflow-y: auto;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    padding: 5px;
    padding-left: 10px;
}

.loading {
    background-color: #e9ecef;
}

.option-selected {
    font-weight: 800;
}

.error-div {
    border-color: #dc3545;
}

</style>


