//////////////////////////////

module.exports = (type) => {
    var schema = {
        format: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                key         : 'id',
                store_name  : 'format',
            },
        },
        order: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                key         : 'id',
                store_name  : 'pratica_order_field',
            },
        },
        order_direction: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                key         : 'id',
                store_name  : 'order_direction',
            },
        },
        owner: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            },
        },
    };

    return schema;
}


