//////////////////////////////

// https://stackoverflow.com/questions/7922744/how-can-i-search-all-columns-in-a-table

module.exports = (type) => {

    var schema = {
        codice_pratica: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'pratiche',
                key         : 'codice',
                constraints : true,
                store_name  : 'pratica',
            }
        },
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        preg_id: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'pregs',
                key         : 'id',
                constraints : true,
                store_name  : 'preg',
            }
        },
        text: {
            type: type.TEXT,
            allowNull: false,
            defaultValue: '',
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: false
        },
    };

    return schema;
}

