
var env = 'production';

module.exports = {

    development: {
        frontend_url: 'http://dev.perizieincloud.it/',
        // local
        backend_url: 'http://dev.perizieincloud.it:5000/',
        // production
        //backend_url:'https://api.perizieincloud.it/',
        // demo
      //backend_url: 'https://demoapi.perizieincloud.it/',
        rows_per_page: 25,
        gui: {
            //color     : '#FFA726',
            color     : '#241F8F',
            icon      : 'default.png',
            app_label : '(DEV) Perizie in Cloud',
        },
    },

    production: {
        // staging
        //backend_url: 'https://apistaging.perizieincloud.it/',
        //frontend_url: 'https://appstaging.perizieincloud.it',

        // production
        backend_url: 'https://api.perizieincloud.it/',
        frontend_url: 'https://app.perizieincloud.it',

        // demo
        //backend_url: 'https://demoapi.perizieincloud.it/',
        //frontend_url: 'https://demo.perizieincloud.it',

        rows_per_page: 25,
        gui: {
            //color     : env === 'staging' ? '#FFA726' : '#229954',
            // staging
            //color     : '#FFA726',
            // production
            color     : '#229954',
            icon      : 'default.png',
            app_label : 'Perizie in Cloud',
        },
    },

};




