<script>

import bnvbase from './bnvbase.vue';
const md5 = require('md5');
const moment = require('moment');

export default {
    extends: bnvbase,
    props: [ 'store', ],
    computed: {
        store_state () {
            return this.$store.state[ this.store ];
        },
        items() {
            return this.store_state.items;
        },
        selected_options() {
            if ( !this.model ) { this.model = []; }
            return this.model.map( x => x.id );
        },
        options() {
            if ( !this.items ) { return null; }
            return this.items.map( x => {
                return {
                    text  : x.label,
                    value : x.id,
                }
            });
        },
    },
    created () {
        this.signature = moment().valueOf();
        this.set_selected();
        console.log( 'disabled', this.disabled );
    },
    methods: {
        set_selected() {
            return this.selected_options.map( x => {
                this.selected.push( x );
            });
        },
        get_item_id( cga_id, polizza_id ) {
            for ( var i = 0; i < this.model.length; i++ ) {
                if ( this.store === 'cga' ) {
                    var item = this.model[ i ].polizza_cga;
                    if ( item.cga_id === cga_id && item.polizza_id === polizza_id ) { return item.id }
                } else if ( this.store === 'cga_preg' ) {
                    var item = this.model[ i ].preg_cga;
                    if ( item.cga_id === cga_id && item.preg_id === polizza_id ) { return item.id }
                }
            }
            return null;
        },
        load_items() {
            this.$store.dispatch( `${ this.store }/get_all` )
        },
        save_changes() {
            if ( this.recordid ) {
                let to_add    = [];
                let to_remove = [];
                this.selected.map( id => {
                    if ( !this.selected_options.includes( id ) ) { to_add.push( id ); }
                });
                this.selected_options.map( id => {
                    if ( !this.selected.includes( id ) ) { to_remove.push( id ); }
                });
                if ( to_add.length === 0 && to_remove.length === 0 ) { return; }
                var payload = { to_add : [], to_remove :  [], id: this.recordid };
                to_add.map( cga_id => {
                    payload.to_add.push( { cga_id, polizza_id: this.recordid } )
                });
                to_remove.map( cga_id => {
                    payload.to_remove.push( { cga_id, polizza_id: this.recordid, id: this.get_item_id( cga_id, this.recordid ) } );
                });
                this.$store.dispatch( `${ this.store }/send_relations`, payload );
            } else {
                let labels = new Map(); let model = [];
                this.options.map( x => { labels.set( x.value, x.text ); } );
                console.log( labels );
                for ( var i = 0; i < this.selected.length; i++ ) {
                    model.push( { id: this.selected[ i ], label: labels.get( this.selected[ i ] ) } );
                }
                this.model = model;
            }
        },
        toggle_modal( modal_id = null ) {
            if ( !this.items ) {
                this.load_items();
            }
            if ( modal_id === null ) { modal_id = 'cgas-' + this.signature; }
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
    },
    data () {
        return {
            signature       : null,
            selected        : [],
        }
    },
};

</script>

<template>

    <div class="form-group" :class="field_class" :style="field_style">
        <label :for="'bnv-form-' + name" :class="label_class">{{ get_label() }}</label>
        <div :class="element_container_class" class="zindex" id="'bnv-form-' + name">
            <b-row no-gutter style="padding: 0; margin: 0;">
                <b-col style="border: 1px solid #ced4da; background-color: #F0F0F0; padding: 1px;">
                    <b-badge pill v-for="item in model" href="#" :key="item.id" variant="info" style="margin-right: 1px">
                        {{ item.label }}
                    </b-badge>
                </b-col>
                <b-col style="padding: 0;" cols="12" sm="auto"><b-button variant="outline-info" @click="toggle_modal()" :disabled="true"><icon name="pen"/></b-button></b-col>
            </b-row>
        </div>

        <!-- modal cgas -->
        <b-modal
            :id         = "'cgas-' + signature"
            title       = "CGA Associati"
            @hide       = "save_changes"
            button-size = "sm"
            >
            <div style="max-height: 300px">
                <b-form-checkbox-group
                    v-if              = "items"
                    v-model           = "selected"
                    :options          = "options"
                    aria-describedby  = "cga options"
                    name              = "cga_selected"
                    stacked
                ></b-form-checkbox-group>
            </div>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>
    </div>

</template>

<style>
    .zindex {
        z-index: 20;
    }
</style>

