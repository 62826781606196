<script>

export default {

    name: 'linkxls',
    props: [ 'item' ],
    data() {
        return {
        }
    },
    computed: {},
    methods: {
//      get_class() {
//          return '';
//      },
//      open_modal_form ( task ) {
//          var state_task  = null;
//          this.item.tasks.map( x => { if ( x.task_label === task.name ) { state_task = x; } } );
//          if ( state_task.multi === false ) {
//              var data = { task_id: null, description: task.description };
//              var multi = this.item.tasks;
//              data.task_id = state_task.id;
//              if ( data.task_id !== null ) { this.$store.dispatch(`form_modal/set_schema`, data); return; }
//              console.log( `"${ task_name }" non corrisponde ad un task valido` );
//          }
//      }
        get_link() {
            if ( this.item.hasOwnProperty('metas') && this.item.metas.hasOwnProperty( 'file_id' ) ) {
                this.$store.dispatch('pratica/get_link', { codice: this.item.codice, file_id: this.item.metas.file_id } );
            } else {
                this.$emit('genericevent', { event_name: 'set_template', codice_pratica: this.item.codice, owner: this.item.owner });
            }
        }
    },
    created () {},

}

</script>

<template>

    <div style="" title="apri il file excel" @click.stop="get_link()">
        <component
            style      = "margin-right: 3px; cursor: pointer;"
            class      = "rounded-circle"
            is         = "b-icon-display"
            scale      = "0.9"
            font-scale = "1.8"
        ></component>
    </div>

</template>

