//////////////////////

import { reportService } from '@/services';
const config = require('@/config.js')[ process.env.NODE_ENV || 'test' ];

export const report = {

    namespaced: true,
    state: {
        message        : '',
        variant        : '',
        loader         : false,
        ws             : null,
        codice_pratica : null,
        clear          : true, 
        params         : {},
    },
    actions: {
        make({ commit, state }) {
            commit('set_clear');
            if ( config.backend_url.includes( 'https' ) ) {
                var ws = new WebSocket( config.backend_url.replace('https://', 'wss://') + `ws/report/?code=${ state.params.format }` );
            } else if ( config.backend_url.includes( 'http' ) ) {
                var ws = new WebSocket( config.backend_url.replace('http://', 'ws://') + `ws/report/?code=${ state.params.format }` );
            }
            commit('set_ws', ws );
            ws.onmessage = msg => commit('in_message', msg);
            reportService.make_report(state.codice_pratica, state.params);
        },
        reset( { commit } ) { commit('reset') },
        set_codice_pratica( { commit }, codice_pratica ) { commit('set_codice_pratica', codice_pratica) },
        set_clear( { commit } ) { commit('set_clear') },
        set_params( { commit }, params ) { commit('set_params', params); },
    },
    mutations: {
        set_clear( state ) {
            state.clear = !state.clear;
        },
        reset( state ) {
            var message_class = 'Export csv';
            state.loader         = false;
            state.message        = { 'class': message_class, action: 'Inizializzazione in corso' };
            state.codice_pratica = null;
            state.variant        = '';
            state.ws             = null;
            state.params         = {};
        },
        set_params( state, params ) {
            state.params = params;
        },
        set_ws( state, ws ) {
            state.ws = ws;
        },
        in_message( state, msg ) {
            var message = JSON.parse(msg.data);
            if ( message.hasOwnProperty('start') === true ) {
                state.loader = true;
            } else if ( message.hasOwnProperty('stop') === true ) {
                state.loader = false;
                state.ws.close();
                state.ws = null;
            } else {
                state.loader = true;
            }
            if ( message.level === 'info' ) {
                state.variant = '';
                if ( message.hasOwnProperty('success') === true ) {
                    state.variant = 'success';
                }
            } else if ( message.level === 'notice' ) {
                state.variant = 'warning';
                state.loader = false;
            } else if ( message.level === 'crit' ) {
                state.variant = 'danger';
                state.loader = false;
            } else if ( message.level === 'error' ) {
                state.variant = 'danger';
                state.loader = false;
            }
            state.message = message;
        },
        set_codice_pratica( state, codice_pratica ) {
            state.codice_pratica = codice_pratica;
        },
    }

}



