//////////////////////////////

const moment = require('moment');

class placeholders {

    constructor( pra ) { this.pra = pra; }

    get pregs() { return this.pra.pregs; }

    get assicuratoobj() { return this.pregs.assicurato || {}; }

    get controparteobj() { return this.pregs.controparte || {}; }

    get liquidatoreobj() { return this.pregs.liquidatore || {}; }

    get ispettoratoobj() { return this.pregs.ispettorato || {}; }

    get agenziaobj() { return this.pregs.agenzia || {}; }

    get compagniaobj() { return this.pregs.compagnia || {}; }

    get incaricoobj() { return this.pregs.incarico; }

    get polizzaobj() { return this.pregs.assicurato_polizza || {}; }

    get n_polizza() {
        return this.polizzaobj.codice || null;
    }

    get n_sinistro() {
        return this.incaricoobj.numero_sinistro || null;
    }

    get codice_pratica_interno() { return this.pra.codice; }

    get data_sinistro() {
        try {
            var date = moment( this.incaricoobj.data_sinistro, 'YYYY-MM-DD' );
            return date.format('DD/MM/YYYY');
        } catch ( error ) {
            return null
        }
    }

    get data_incarico() {
        try {
            var date = moment( this.incaricoobj.data_affidamento, 'YYYY-MM-DD' );
            return date.format('DD/MM/YYYY');
        } catch ( error ) {
            return null
        }
    }

    get luogo_sinistro() {
        return this.incaricoobj.indirizzo_luogo_sinistro || null;
    }

    get tipologia_incarico() {
        return this.incaricoobj.ramo || null;
    }

    get assicurato() {
        return this.assicuratoobj.label;
    }

    get assicurato_int() {
        if ( this.assicurato ) {
            if ( this.assicuratoobj.denominazione ) {
                return 'Spettabile ' + this.assicurato;
            } else {
                return 'Gentile ' + this.assicurato;
            }
        }
        return null;
    }

    get controparte() {
        if ( this.controparteobj && Object.keys( this.controparteobj ).length > 0 ) {
            let keys = Object.keys( this.controparteobj );
            return this.controparteobj[ keys[0] ].controparte.label;
        }
    }

    get controparte_int() {
        if ( this.controparte ) {
            let keys = Object.keys( this.controparteobj );
            if ( this.controparteobj[ keys[0] ].controparte.denominazione ) {
                return 'Spettabile ' + this.controparte;
            } else {
                return 'Gentile ' + this.controparte;
            }
        }
        return null;
    }

    get liquidatore() {
        return this.liquidatoreobj.label || null;
    }

    get liquidatore_int() {
        if ( this.liquidatore ) {
            return 'Gentile ' + this.liquidatore;
        }
        return null;
    }

    get ispettorato() {
        return this.ispettoratoobj.label || null;
    }

    get ispettorato_int() {
        if ( this.ispettorato ) {
            return 'Spettabile ' + this.ispettorato;
        }
        return null;
    }

    get agenzia() {
        return this.agenziaobj.label || null;
    }

    get agenzia_int() {
        if ( this.agenzia ) {
            return 'Spettabile ' + this.agenzia;
        }
        return null;
    }

    get mandante() {
        return this.compagniaobj.label || null;
    }

    get ramo() {
        return this.incaricoobj.ramo || null;
    }

    get documenti() {
        return 'documenti';
    }

    get danno_presunto() { // TODO
        console.log(this.incaricoobj.importo_riserva);
        try {
            var value = parseFloat( this.incaricoobj.importo_riserva.replace(',', '.') ).toFixed( 2 );
            if ( isNaN( value ) ) { throw 'invalid'; }
            return value;
        } catch ( error ) {
            return null;
        }
    }

    get placeholders() {
        return [
            'assicurato',
            'assicurato_int',
            'controparte',
            'controparte_int',
            'liquidatore',
            'liquidatore_int',
            'ispettorato',
            'ispettorato_int',
            'agenzia',
            'agenzia_int',
            'n_polizza',
            'n_sinistro',
            'codice_pratica_interno',
            'data_sinistro',
            'data_incarico',
            'luogo_sinistro',
            'tipologia_incarico',
            'mandante',
            'ramo',
            'danno_presunto',
            'documenti',
        ];
    }

    get all() {
        var res = {};
        for ( var i = 0; i < this.placeholders.length; i++ ) {
            res[ this.placeholders[ i ] ] = this[ this.placeholders[ i ] ];
        }
        return res;
    }

}

class placeholder_manager {

    constructor( pra, text = null ) {
        this.plhd     = new placeholders( pra );
        this.text     = text;
        this.error    = null;
        this.error_placeholders = [];
        this.error_template = 'placeholders non valorizzati: ';
        this.subtemp_1 = 'Sinistro {{mandante}} n. {{n_sinistro}} - {{assicurato}}';
        this.subtemp_2 = ' (ns. rif. {{codice_pratica_interno}})';
    }

    static validate( pra, text = null ) {
        var obj = new placeholder_manager( pra, text );
        return obj;
    }

    static get_placeholders( pra = {} ) {
        var obj = new placeholder_manager( pra );
        return obj.plhd.placeholders.sort();
    }

    get translation() {
        if ( this.is_valid ) {
            var string = this.text;
            for ( var i = 0; i < this.involved.length; i++ ) {
                if ( this.involved_label[ i ] === 'documenti' ) { continue; } // popolato da main_manager (backend)
                var regexp = new RegExp(`${ this.involved[ i ] }`);
                string = string.replace( regexp, this.plhd[ this.involved_label[ i ] ], 'g' );
            }
            return string;
        }
        throw 'non posso tradurre un testo non valido';
    }

    get subject() {
        if ( this.is_valid ) {
            var string = this.subject_template;
            for ( var i = 0; i < this.involved_subject.length; i++ ) {
                var regexp = new RegExp(`${ this.involved_subject[ i ] }`);
                string = string.replace( regexp, this.plhd[ this.involved_label_subject[ i ] ], 'g' );
            }
            return string.replace( / {2,}/g, ' ' );
        }
        throw 'non posso tradurre un testo non valido';
    }

    get is_valid() {
        if ( !this.text ) {
            this.error = 'non posso validare se non mi passsi il testo';
            return false
        }
        var is_valid = true;
        this.error_placeholders = [];
        this.involved_label.map( x => {
            if ( !this.plhd[ x ] ) {
                is_valid = false;
                this.error_placeholders.push( x )
            }
        });
        if ( is_valid === false ) {
            this.error = this.error_template + this.error_placeholders.join(', ');
        }
        if ( is_valid ) { return this.is_valid_subject; }
        return is_valid;
    }

    get is_valid_subject() {
        var is_valid = true;
        this.error_placeholders = [];
        this.involved_label_subject.map( x => {
            if ( !this.plhd[ x ] ) {
                is_valid = false;
                this.error_placeholders.push( x )
            }
        });
        if ( is_valid === false ) {
            this.error = this.error_template + this.error_placeholders.join(', ');
        }
        return is_valid;
    }

    get involved() {
        if ( this.text ) {
            var text = this.text;
            return text.match(/{{(.*?)}}/g);
        }
        return [];
    }

    get involved_label() {
        return this.involved.map( x => {
            return x.replace(/{|}/g, '');
        });
    }

    get involved_subject() {
        if ( this.text ) {
            var text = this.subject_template;
            return text.match(/{{(.*?)}}/g);
        }
        return [];
    }

    get involved_label_subject() {
        return this.involved_subject.map( x => {
            return x.replace(/{|}/g, '');
        });
    }

    get placeholders() { return this.plhd.all; }

    get subject_template() {
        var subject = this.subtemp_1;
        if ( this.plhd.controparte ) {
            subject += ' / {{controparte}}';
        }
        subject += this.subtemp_2;
        return subject;
    }

}

export default placeholder_manager;

