import { render, staticRenderFns } from "./contacts.vue?vue&type=template&id=47fe75f5"
import script from "./contacts.vue?vue&type=script&lang=js"
export * from "./contacts.vue?vue&type=script&lang=js"
import style0 from "./contacts.vue?vue&type=style&index=0&id=47fe75f5&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports