////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'registry_role',
    send_alert    : false,
    default_order : 'label ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    role_map: new Map(),
    role_map_ids : new Map(),
}

const actions = {
    ...base.actions,
    load( { dispatch, state }, data = { options: {} } ) {
        if ( state.role_map.size > 0 ) { return; }
        dispatch( 'get_all', data );
    }
};

const mutations = {
    ...base.mutations,
    success_list( state, items ) {
        state.items    = items;
        items.map( x => {
            state.role_map.set( x.label, x );
            state.role_map_ids.set( x.id, x );
        });
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.executed      = true;
    },
};

const getters = {
    ...base.getters,
    map: state => state.role_map_ids,
};

export default {
    namespaced : true,
    ...base,
    actions,
    state,
    mutations,
    getters,
};

