<script>

const moment = require('moment-timezone');

export default {

    name: 'cmp-diary-description',
    props: [ 'item' ],
    data() {
        return {
            icons: {
            },
        }
    },
    computed: {
        is_mine() { return this.$store.getters['userletter/is_mine']( this.item.user_id ); },
        pra()     { return this.$store.getters['pratica/get_item']( this.item.codice_pratica ); },
        owner()   { return this.pra.customer_owner.label },
        description() { 
            return this.item.description.replace( /\n/g, '<br/>' );
        }
    },
    methods: {
        get_task_state( task_state ) {
            if ( task_state === 'done' ) {
                return 'completato';
            } else if ( task_state === 'intermediate' ) {
                return 'stato intermedio';
            } else if ( task_state === 'unnecessary' ) {
                return 'non necessario';
            } else if ( task_state === null ) {
                return 'da fare';
            }
            return 'stato indeterminato';
        },
        get_task_name( str ) {
            str = str.replace(/_/g, ' ');
            return str.charAt(0).toUpperCase() + str.slice(1);
  
        },
    },
    created () {
    },

}


</script>

<template>

    <div>
        <div v-if="item.code === 0">
        </div>
        <div v-else-if="item.code === 1">
            <template v-if="item.options.removed !== true">il task <b-badge variant="secondary" pill>{{ get_task_name( item.options.task_label ) }}</b-badge> è stato assegnato<template v-if="is_mine"> a <b-badge variant="info" pill>{{ item.options.assigned_to_label }}</b-badge></template></template>
            <template v-else-if="item.options.removed === true">il task <b-badge variant="secondary" pill>{{ get_task_name( item.options.task_label ) }}</b-badge> è stato <strong>dis</strong>assegnato</template>
        </div>
        <div v-else-if="item.code === 3">
            il task <b-badge variant="secondary" pill>{{ get_task_name( item.options.task_label ) }}</b-badge> è passato allo stato <b-badge variant="info" pill>{{ get_task_state( item.options.task_state_label ) }}</b-badge>
        </div>
        <div v-else-if="item.code === 2">
            la pratica è passata dallo stato <b-badge variant="info" pill>{{ item.options.previous_state_label }}</b-badge> allo stato <b-badge variant="info" pill>{{ item.options.state_label }}</b-badge>
        </div>
        <div v-else-if="item.code === 4">
        </div>
        <div v-else-if="item.code === 5">
        </div>
        <div v-else-if="item.code === 6">
            <template v-if="item.options.removed !== true">la pratica è stata affidata a <b-badge variant="info" pill>{{ item.options.custodian_label }}</b-badge></template>
            <template v-else-if="item.options.removed === true">la pratica è tornata in gestione a <b-badge variant="info">{{ owner }}</b-badge></b-badge></template>
        </div>
        <div v-if="item.description" style="margin-bottom: 4px">
            <span style="margin-top: 4px;; background-color: #EFFE8F; font-weight: 600;" v-html="description"></span>
        </div>
    </div>

</template>

<style>

    .enlight-blue {
        background-color: #B3D5FB;
        padding-left: 3px;
        padding-right: 3px;
    }

</style>

