///////////////////////////////////////////////////////

class element_config {

    constructor(data) {
        var options = [
            'element_type', 'default_value', 'label', 'helper', 'placeholder', 'store_name',
            'allow_null', 'min_length', 'max_length', 'max_size', 'mime_types', 'edit', 'readonly',
            'search', 'filter', 'display_error',
        ];
        this.obj = {};
        options.map( key => {
            this.obj[ key ] = data[ key ];
        });
    }

}

export default element_config;
