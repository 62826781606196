//////////////////////////////

module.exports = (type) => {

    var schema = {
        label: {
            type: type.STRING(64),
            allowNull: false,
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        only_preg: {
            type: type.BOOLEAN,
            defaultValue: false,
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

