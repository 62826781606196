<script>

export default {
    name: 'simple-search',
    props: [ 'force_reload' ],
    components: {
    },
    computed: {
        store_data() {
            return this.$store.state.pratica.simple_search;
        },
        is_stored() {
            return ( Object.keys( this.store_data ).length > 0 ) ? true : false;
        },
        is_force_reload() {
            return this.force_reload === true ? true : false;
        },
    },
    created() {
        if ( this.is_stored ) {
            this.selected_search_mode  = this.store_data.mode;
            this.selected_search_field = this.store_data.field;
            this.simple_search         = this.store_data.text;
            this.filter = this.get_filter_label();
        } else {
            this.selected_search_mode  = this.default_search_mode;
            this.selected_search_field = this.default_search_field;
            this.simple_search         = '';
            this.filter                = 'Cerca';
        }
    },
    mounted() {
        this.$root.$on('bv::dropdown::show', bvEvent => {
            setTimeout( () => {
                if ( this.$refs.hasOwnProperty('text_input') ) {
                    this.$refs.text_input.select();
                }
            }, 600 );
        })
    },
    watch: {
    },
    methods: {
        useless_function() {},
        get_filter_label() {
            if ( this.selected_search_field ) {
                var field = '';
                for ( var i = 0; i < this.selected_field_options.length; i++ ) {
                    if ( this.selected_field_options[ i ].value === this.selected_search_field ) {
                        field = this.selected_field_options[ i ].text;
                        break;
                    }
                }
                field += this.selected_search_mode === 'all' ? ' = ' : ' contiene ';
                field += `"${ this.simple_search }"`;
                return field;
            }
            return 'Cerca';
        },
        set_force_reload() {
            if ( this.is_force_reload ) {
                this.$store.dispatch('pratica/force_reload');
            }
        },
        submit_simple_search() {
            var data = {
                text  : this.simple_search,
                mode  : this.selected_search_mode,
                field : this.selected_search_field,
            };
            var filter = {};
            var key = data.field;
            if ( key === 'codice' ) {
                var value = data.text;
            } else {
                var value = data.mode === 'all' ? data.text : `|${ data.text }|`;
            }
            filter[ key ] = value;
            if ( this.is_stored ) {
                this.$store.dispatch('pratica/remove_filter', this.store_data.field);
            }
            this.$store.dispatch('pratica/add_filter', filter);
            this.$store.dispatch('pratica/set_simple_search', data);
            this.$store.dispatch('pratica/set_switch_tab', true);
            this.filter = this.get_filter_label();
            this.$refs.simple_search_menu.hide();
            this.set_force_reload();
            this.$emit( 'reload-rows', null );
        },
        reset_search() {
            this.$store.dispatch('pratica/remove_filter', this.store_data.field);
            this.$store.dispatch('pratica/set_simple_search', {});
            this.$refs.simple_search_menu.hide();
            this.selected_search_mode  = this.default_search_mode;
            this.selected_search_field = this.default_search_field;
            this.simple_search         = '';
            this.filter                = 'Cerca';
            this.set_force_reload();
            this.$emit( 'reload-rows', null );
        },
    },
    data () {
        return {
            filter                 : '',
            default_search_mode    : 'partial',
            default_search_field   : 'codice',
            simple_search          : '',
            selected_search_mode   : '',
            selected_mode_options  : [
                { text: 'uguale a', value: 'all' },
                { text: 'contiene', value: 'partial' },
            ],
            selected_search_field  : '',
            selected_field_options : [
                { text: 'codice pratica', value: 'codice' },
                { text: 'assicurato', value: 'assicurato.nome,assicurato.cognome,assicurato.denominazione,assicurato.indirizzo,assicurato.citta,assicurato.telefono,assicurato.cellulare,assicurato.email' },
                { text: 'controparte', value: 'controparte.nome,controparte.cognome,controparte.denominazione,controparte.indirizzo,controparte.citta,controparte.telefono,controparte.cellulare,controparte.email' },
                { text: 'mandante', value: 'dati_pratica.mandante' },
                { text: 'liquidatore', value: 'referente.gruppo,referente.utente,referente.ruolo,referente.titolo,referente.nome,referente.cognome,referente.denominazione,referente.indirizzo,referente.citta' },
                { text: 'ispettorato', value: 'ufficio.gruppo,ufficio.descrizione,ufficio.titolo,ufficio.rappresentante,ufficio.codice,ufficio.indirizzo,ufficio.citta' },
                { text: 'agenzia', value: 'agenzia.denominazione,agenzia.descrizione,agenzia.codice,agenzia.titolo,agenzia.rappresentante,agenzia.indirizzo,agenzia.citta' },
                { text: 'n. sinistro', value: 'dati_pratica.numero_sinistro' },
                { text: 'n. polizza', value: 'dati_pratica.numero_polizza' },
                { text: 'tipo incarico', value: 'dati_pratica.tipologia_incarico' },
                { text: 'tipo danno', value: 'dati_pratica.tipologia_danno' },
                { text: 'luogo sinistro', value: 'dati_pratica.luogo_sinitro' },
                { text: 'luogo appuntamento', value: '', disabled: true },
                { text: 'n. pratica esterno', value: 'dati_pratica.numero_pratica_esterno' },
            ],
        };
    },
};

</script>

<template>
    <b-dropdown id="dropdown-form" :text="filter" variant="light" ref="simple_search_menu" size="sm" menu-class="w-100" block>
        <b-dropdown-form v-on:submit.prevent="useless_function">
            <b-input-group size="sm" style="margin-bottom: 12px;">
                <b-form-input v-model="simple_search" ref="text_input" v-on:keyup.enter="submit_simple_search"></b-form-input>
                <b-input-group-append>
                    <b-button variant="info" @click="submit_simple_search">Cerca</b-button>
                </b-input-group-append>
                <b-input-group-append>
                    <b-button variant="warning" @click="reset_search"><b-icon-x-circle-fill></b-icon-x-circle-fill></b-button>
                </b-input-group-append>
            </b-input-group>
            <b-form-group label="Modalità" label-size = "sm">
                <b-form-radio-group
                    size     = "sm"
                    id       = "selected_search_mode"
                    v-model  = "selected_search_mode"
                    :options = "selected_mode_options"
                    name     = "search-mode-radio-options"
                ></b-form-radio-group>
            </b-form-group>
            <b-form-group label="Campo" label-size = "sm">
                <b-form-radio-group
                    size     = "sm"
                    id       = "selected_search_field"
                    v-model  = "selected_search_field"
                    :options = "selected_field_options"
                    name     = "search-field-radio-options"
                ></b-form-radio-group>
            </b-form-group>
        </b-dropdown-form>
    </b-dropdown>
</template>

