<script>

import parcel  from './parcel';
import amounts from './amounts';

export default {
    name: 'pratica-module',
    components: { 'pratica-parcel': parcel, 'pratica-amounts': amounts },
    props: [ 'label', 'codice_pratica', 'modal' ],
    computed: {
        role_id() { return this.$store.getters['statemanager/role_id']; },
    },
    created() {},
    methods: {
        pra_modified() {
            this.prop_key += 1;
            this.user_key += 1;
        }
    },
    data () {
        return {
            selected_tab_index : 1,
            prop_key           : 0,
            user_key           : 0,
        }
    },
};

</script>

<template>

    <b-tabs v-model="selected_tab_index" pills>
        <!-- <b-tab title="Proprietario" :disabled="role_id !== 1"> -->
        <b-tab title="Proprietario" :disabled="![ 1, 6 ].includes( role_id )">
            <pratica-amounts
                schema_from_route = "amounts"
                :codice_pratica   = "codice_pratica"
                :key              = "prop_key"
                @pra-modified     = "pra_modified"
            />
        </b-tab>
        <b-tab title="Utenti">
            <pratica-parcel
                schema_from_route = "pratica_parcel"
                :key              = "user_key"
                :codice_pratica   = "codice_pratica"
                @pra-modified     = "pra_modified"
            />
        </b-tab>
    </b-tabs>

</template>

