////////////////////////////////////////

const axios = require('axios');

import { task_service } from '@/services';
import base_module from '@/vue-model-mirror/stores/base.module';
const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];
import { authHeader } from '@/helpers';

var options = {
    model_name    : 'task',
    send_alert    : false,
    default_order : 'codice DESC',
};

var base = new base_module( options, task_service );

const state = {
    ...base.state,
    executedupd        : false,
    open_modal         : false,
    createdws          : false,
    upload_counter     : { tot: 0, done: 0 },
    last_image_managed : null,
    upload_data        : null,
    ws                 : null,
    message            : null,
    queue              : [],
    upload_terminated  : true,
    upload_tot_byte    : 0,
    upload_done_byte   : 0,
    upload_temp_byte   : 0,
    connection_enabled : false,
};

const getters = {
    ...base.getters,
    perc_completed: state => {
        if ( state.upload_done_byte === 0 || state.upload_tot_byte === 0 ) { return 0; }
        var perc = Math.round( ( state.upload_done_byte + state.upload_temp_byte ) * 100 / state.upload_tot_byte );
        return perc > 100 ? 100 : perc;
    },
    ws_active: state => {
        return state.ws && state.ws.readyState === 1 ? true : false;
    },
};

const actions = {
    ...base.actions,
    get_all({commit, getters, state}, data = { options: {} }) {
        commit('start_request');
        state.service.get_all(getters.model_name, data.options)
            .then( response => {
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
    get_by_id({commit, getters, state}, data) {
        commit('start_request');
        state.service.get_by_id(getters.model_name, data.id, data.include)
            .then( rows => {
                commit('openmodal')
                commit('success_obj', rows)
            })
            .catch( error => {
                commit('error', error)
            });
    },
    update({ commit, dispatch, getters, state }, data) {
        commit('start_request');
        state.service.update( getters.model_name, data.id, data.payload, data.include )
            .then( obj => {
                commit('successupdate', obj);
                commit('overridemeta', { id: data.id, metas: obj.metas }) ;
                dispatch( 'pratica/override_task', obj, { root: true } );
            })
            .catch( error => {
                commit('error', error)
            });
    },
    update_appuntamento({ commit, dispatch, getters, state }, data) {
        commit('start_request');
        state.service.update( getters.model_name, data.id, data.payload, data.include )
            .then( obj => {
                commit('successupdate', obj);
                commit('overridemeta', { id: data.id, metas: obj.metas }) ;
                dispatch( 'appuntamento/override_task', obj, { root: true } );
                dispatch( 'appuntamento_list/override_task', obj, { root: true } );
                dispatch( 'pratica/override_task', obj, { root: true } );
            })
            .catch( error => {
                commit('error', error)
            });
    },
    updatemeta({ state, commit, dispatch, getters }, data) {
        commit('start_request');
        data.include = false;
        state.service.updatemeta( getters.model_name, data.id, data.payload, data.include )
            .then( obj => {
                commit('success', obj);
                dispatch('pratica/overridemetas', obj.pratica_metas, { root: true });
                dispatch('pratica/overridetasklabel', { codice_pratica: obj.codice, id: data.id, label: obj.label }, { root: true });
                commit('overridemetas', { id: data.id, metas: obj.updates });
            })
            .catch( error => {
                commit('error', error)
            });
    },
    delete_image( {commit, state, getters}, meta ) {
        state.service.deleteimage(getters.model_name, meta)
            .then( () => {
                commit('deleteimage', meta);
            })
            .catch( error => {
                commit('error', error)
            });
    },
    get_link( {commit}, file_id ) {
        window.open(`${ config.backend_url }s3download/${ file_id }`, '_blank');
    },
    executedupd({commit}) { commit('executedupd'); },
    clear({commit}) { commit('clear'); },
    close_modal({commit}) { commit('closemodal'); },
    ovveride_meta({commit}, data) {
        commit('overridemeta', data);
    },
    add_image({commit}, data) {
        commit('addimage', data);
    },
    open_connection( { commit, state, dispatch }, ispezione_id ) {
        commit('enable_connection');
        if ( state.ws && state.ws.readyState === 1 ) { return; }
        if ( state.ws ) {
            try { state.ws.close(); } catch(error) { console.log( error ); state.ws = null; }
        }
        try {
            if ( config.backend_url.includes( 'https' ) ) {
                var ws = new WebSocket( config.backend_url.replace('https://', 'wss://') + `ws/ispezionefile/?code=${ ispezione_id }` );
            } else if ( config.backend_url.includes( 'http' ) ) {
                var ws = new WebSocket( config.backend_url.replace('http://', 'ws://') + `ws/ispezionefile/?code=${ ispezione_id }` );
            }
            console.log('connection ok.');
        } catch ( error ) {
            if ( state.connection_enabled === true ) {
                setTimeout( () => {  state.ws = null; dispatch('open_connection', ispezione_id) }, 500 );
            }
        }
        commit('set_ws', ws );
        ws.onmessage = msg => {
            var message = JSON.parse(msg.data);
            var module = message.ispezione_type === 'image' ? 'localdb_images' : 'localdb_documents';
            if ( message.level === 'error' ) {
                dispatch( `${ module }/update_item_by_hash`, { hash: message.hash, code: 4, text: 'errore elaborazione' }, { root: true } );
            } else if ( message.action === 'immagine gestita con successo' ) {
                // eliminazione immagine da localdb_images
                dispatch( `${ module }/destroy_by_hash`, message.image.value.original_hash, { root: true } );
                commit('in_message', message);
            }
        }
        ws.onclose = event => {
            console.log('connection lost');
            if ( state.connection_enabled === true ) {
                setTimeout( () => {  state.ws = null; dispatch('open_connection', ispezione_id) }, 500 );
            }
        };
    },
    close_connection( { state, commit } ) {
        commit('disable_connection');
        state.ws.close();
        state.ws = null; // spostare in una mutation
    },
    async createws_multiple( { state, commit, dispatch }, conf ) {
        dispatch('open_connection', state.items[0].id);
        commit('set_upload_terminated', false);
        commit('reset_upload_tot_byte');
        commit('set_upload_tot_byte');
        //commit( 'set_upload_data', JSON.parse( JSON.stringify( conf ) ) );
        for ( var i = 0; i < state.queue.length; i++ ) {
            var data = JSON.parse( JSON.stringify( conf ) );
            Object.keys( state.queue[ i ] ).map( x => { data.payload[ x ] = state.queue[ i ][ x ] } );
            state.createdws = false;
            await dispatch( 'createws', data );
        }
    },
    async createws({ state, commit, dispatch, getters }, data) { // deprecated
        var { container, payload } = data;


        var url = `${config.backend_url}model/folder/ispezione/${ container }`;
        if ( data.include ) { url += `?include=true` }
        var headers = authHeader();
        delete headers['Content-Type'];
        const form = new FormData();
        Object.keys( payload ).map( x => {
            form.append(x, payload[ x ]);
        });
        commit('start_request');
        var options = {
            headers,
            onUploadProgress: progress_event => {
                commit( 'set_upload_temp_byte', progress_event.loaded );
            }
        };
        await axios.post( url, form, options )
            .then( payload => {
                var response = payload.data;
                commit('change_image_status', { variant: 'danger', text: 'in elaborazione', hash: response.hash });
                state.upload_counter.done += 1;
                state.createdws = true;
                commit( 'add_upload_done_byte', state.upload_temp_byte );
                commit( 'set_upload_temp_byte', 0 );
                if ( state.upload_counter.tot === state.upload_counter.done ) {
                    commit('set_upload_terminated', true);
                }
            })
            .catch( error => {
                console.log( error );
            });
    },
    set_upload_queue({ commit }) {
        commit( 'set_upload_tot' );
    },
    set_queue( { commit }, queue ) { commit( 'set_queue', queue ); },
    push_to_queue( { commit }, image ) { commit( 'push_to_queue', image ); },
    remove_from_queue( { commit }, hash ) { commit( 'remove_from_queue', hash ); },
    set_upload_terminated( { commit }, upload_terminated ) { commit( 'set_upload_terminated', upload_terminated ); },
};

const mutations = {
    ...base.mutations,
    success_list( state, items ) {
        state.items    = items;
        if ( state.queue.length > 0 ) {
            items.map( image => {
                var found = null;
                for ( var i = 0; i < state.queue.length; i++ ) {
                    if ( state.queue[ i ].hash === image.value.original_hash ) { found = i; break; }
                }
                state.queue.splice( found, 1 );
            });
        }
        state.loading  = false;
        state.loading_by_id = false;
        state.error    = false;
        state.executed = true;
        this.dispatch( 'task/open_connection', items[0].id ); // TODO ok SOLO se c'è una sola isp
                                                              // va spostata alla selezione della isp
    },
    image_managed( state, last_image_managed ) {
        state.last_image_managed = last_image_managed;
    },
    set_upload_tot( state ) {
        state.upload_counter.tot  = state.queue.length;
        state.upload_counter.done = 0;
    },
    add_upload_done( state ) {
        state.upload_counter.done += 1;
    },
    set_queue( state, queue ) {
        state.queue = queue;
    },
    push_to_queue( state, image ) { state.queue.push( image ); },
    remove_from_queue( state, hash ) {
        for ( var i = 0; i < state.queue.length; i++ ) {
            if ( state.queue[ i ].hash === hash ) { break; }
        }
        state.queue.splice( i, 1 );
    },
    change_image_status( state, payload ) {
        for ( var i = 0; i < state.queue.length; i++ ) {
            if ( state.queue[ i ].hash === payload.hash ) {
                state.queue[ i ].status = { variant: payload.variant, text: payload.text };
                break;
            }
        }
    },
    reset_upload_counter( state ) {
        state.upload_counter.tot  = 0;
        state.upload_counter.done = 0;
    },
    openmodal( state ) {
        state.open_modal = true;
    },
    closemodal( state ) {
        state.open_modal = false;
        state.executed     = false;
    },
    success_upload( state, items ) {
        state.createdws = true;
    },
    success( state, obj ) {
        state.obj      = obj;
        state.loading  = false;
        state.error    = false;
        state.executed = true;
    },
    successupdate( state, obj ) {
        state.obj         = obj;
        state.loading     = false;
        state.error       = false;
        state.executed    = false;
        state.executedupd = true;
    },
    success_ispezione_image( state, data ) {
        for ( var i = 0; i < state.items.length; i++ ) {
            if ( state.items[ i ].id === data.task_id ) { state.items[ i ].images = data.images; break; }
        }
    },
    executedupd(state) {
        state.executedupd = false;
    },
    overridemeta( state, data ) {
        if ( Array.isArray( data.metas ) ) {
            if ( state.items ) {
                for ( var i = 0; i < state.items.length; i++ ) {
                    if ( state.items[ i ].id === data.id ) { state.items[ i ].task_meta = JSON.parse(JSON.stringify(data.metas)); break; }
                }
            }
        }
    },
    overridemetas( state, data ) {
        var meta_interested = {};
        data.metas.map( x => { meta_interested[ x.id ] = x; });
        if ( state.items ) {
            for ( var i = 0; state.items.length; i++ ) {
                var ispezione = state.items[ i ];
                if ( ispezione.id === data.id ) {
                    for ( var a = 0; a < ispezione.task_meta.length; a++ ) {
                        var meta = state.items[ i ].task_meta[ a ];
                        if ( Object.keys( meta_interested ).includes( String( meta.id ) ) ) {
                            state.items[ i ].task_meta[ a ] = meta_interested[ meta.id ]
                        }
                    }
                }
            }
        }
    },
    addimage( state, data ) {
        var found = false;
        for ( var i = 0; i < state.items.length; i++ ) { if ( state.items[ i ].id === data.task_id ) { found = true; break; } }
        if ( found ) { state.items[ i ].images.push( data ); }
    },
    deleteimage( state, task ) {
        var task_found = false;
        var image_found = false;
        for ( var i = 0; i < state.items.length; i++ ) { if ( state.items[ i ].id === task.task_id ) { task_found = true; break; } }
        if ( task_found ) {
            for ( var a = 0; a < state.items[ i ].images.length; a++ ) { if ( state.items[ i ].images[ a ].id === task.id ) { image_found = true; break; } }
            if ( image_found ) { state.items[ i ].images.splice( a, 1 ); }
        }
    },
    set_ws( state, ws ) {
        state.ws = ws;
    },
    in_message( state, message ) {
        if ( message.hasOwnProperty('start') === true ) {
        } else if ( message.hasOwnProperty('end') === true ) {
            state.queue.shift();
            for ( var i = 0; i < state.items.length; i++ ) {
                if ( message.task_id === state.items[ i ].id ) {
                    state.items[ i ].images.push( message.image );
                    break;
                }
            }
            if ( state.queue.length === 0 ) {
                state.message = 'operazione completata con successo';
            } else {
                //state.createdws = false;
                state.message = null;
                //this.dispatch('task/createws'); // ugly
            }
            return;
        } else {
            //state.loader = true;
        }
        if ( message.level === 'info' ) {
            //state.variant = '';
            //if ( message.hasOwnProperty('success') === true ) {
            //    state.variant = 'success';
            //}
        } else if ( message.level === 'notice' ) {
            //state.variant = 'warning';
            //state.loader = false;
        } else if ( message.level === 'crit' ) {
            //state.variant = 'danger';
            //state.loader = false;
        } else if ( message.level === 'error' ) {
            //state.variant = 'danger';
            //state.loader = false;
        }
        state.message = message.action + '...';
    },
    set_upload_terminated( state, value ) { state.upload_terminated = value; },
    //set_upload_data( state, data ) { state.upload_data = data; },
    reset_upload_tot_byte( state ) { state.upload_tot_byte = 0; state.upload_done_byte = 0; state.upload_temp_byte = 0; },
    set_upload_temp_byte( state, value ) { state.upload_temp_byte = value; },
    add_upload_temp_byte( state, value ) { state.upload_temp_byte += value; },
    set_upload_tot_byte( state ) { state.queue.map( x => { state.upload_tot_byte += x.file.size; } ); },
    add_upload_done_byte( state, value ) { state.upload_done_byte += value; },
    enable_connection( state ) { state.connection_enabled = true; },
    disable_connection( state ) { state.connection_enabled = false; },
};

export default {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
};

