//////////////////////////////

// https://stackoverflow.com/questions/7922744/how-can-i-search-all-columns-in-a-table

module.exports = (type) => {

    var schema = {
        /* GENERALE */
        label: {
            type: type.STRING(128),
            allowNull: true,
        },
        nome: {
            type: type.STRING(128),
            allowNull: true,
        },
        cognome: {
            type: type.STRING(128),
            allowNull: true,
        },
        titolo: {
            type: type.STRING(128),
            allowNull: true,
        },
        denominazione: {
            type: type.STRING(128),
            allowNull: true,
        },
        partita_iva: {
            type: type.STRING(64),
            allowNull: true,
            options: {
                type: 'piva'
            }
        },
        codice_fiscale: {
            type: type.STRING(64),
            allowNull: true,
        },
        sesso: {
            type: type.ENUM( 'Maschio', 'Femmina' ), // rimosso "Altro"
            allowNull: true,
            options: {
                store_name  : 'sesso', // basta lo store
            },
        },
        data_nascita: {
            type: type.DATEONLY,
            allowNull: true,
        },
        comune_nascita_id: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'comuni',
                key         : 'id',
                constraints : true,
                store_name  : 'comune_nascita',
                type        : 'autocomplete'
            },
        },
        iban: {
            type: type.STRING(64),
            allowNull: true,
            options: {
                type: 'piva'
            }
        },
        note: {
            type: type.TEXT,
            allowNull: true,
        },
        
        /* INDIRIZZO */
        indirizzo: {
            type: type.VIRTUAL,
            allowNull: true,
            field_type: 'google_address',
        },
//      tipo_strada: { // TODO da rimuovere
//          type: type.STRING(64),
//          allowNull: true,
//          options: {
//              store_name: 'tipo_strada', // basta lo store
//          },
//      },
        strada: {
            type: type.STRING(64),
            allowNull: true,
        },
        n_civ: {
            type: type.STRING(64),
            allowNull: true,
        },
        cap: {
            type: type.STRING(32),
            allowNull: true,
        },
        comune_id: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'comuni',
                key         : 'id',
                constraints : true,
                store_name  : 'comune_residenza',
                type        : 'autocomplete'
            },
        },
        citta_estero: {
            type: type.STRING(128),
            allowNull: true,
        },
        nazione: {
            type: type.STRING(128),
            allowNull: true,
        },
//      localita: { // TODO da rimuovere
//          type: type.STRING(32),
//          allowNull: true,
//      },
        altro: {
            type: type.STRING(32),
            allowNull: true,
        },
        latitudine: {
            type: type.DECIMAL(10,6),
            allowNull: true,
        },
        longitudine: {
            type: type.DECIMAL(10,6),
            allowNull: true,
        },

        /* RECAPITI */
        telefono: {
            type: type.JSONB,
            defaultValue: [],
            allowNull : false,
            options: {
                type          : 'object_manager',
                new_item_type : 'string',
                not_empty     : true,
                label         : true,
                note          : true,
            },
        },
        email: {
            type: type.JSONB,
            defaultValue: [],
            allowNull : false,
            options: {
                type          : 'object_manager',
                new_item_type : 'email',
                not_empty     : true,
                label         : true,
            },
        },

        /* CAMPI SPECIFICI */
        codice: {
            type: type.STRING(64),
            allowNull: true,
        },
        codice_destinatario: { // aggiunto
            type: type.STRING(64),
            allowNull: true,
        },

        /* CAMPI POLIZZA */
        data_effetto: {
            type: type.DATEONLY,
            allowNull: true,
            defaultValue: null,
        },
        data_termine: {
            type: type.DATEONLY,
            allowNull: true,
            defaultValue: null,
        },
        data_pagamento_premio: {
            type: type.DATEONLY,
            allowNull: true,
            defaultValue: null,
        },

        /* SYSTEM */
        template_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'registry_templates',
                key         : 'id',
                constraints : true,
                store_name  : 'registry_template',
            }
        },
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        codice_pratica: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'pratiche',
                key         : 'codice',
                constraints : true,
                store_name  : 'pratica',
            }
        },
        role_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'registry_roles',
                key         : 'id',
                constraints : true,
                store_name  : 'registry',
            }
        },
        registry_id: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'registries',
                key         : 'id',
                constraints : true,
                store_name  : 'registry',
            }
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue: type.NOW,
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        archived: {
            type: type.BOOLEAN,
            defaultValue: false,
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

