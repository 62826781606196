<script>

export default {

    name: 'cmp-studio',
    props: [ 'item' ],
    data() {
        return {
            studio   : null,
            customer : null,
        }
    },
    computed: {},
    methods: {
        get_studio() {
            if ( this.item.hasOwnProperty('dati_pratica') ) {
                return this.item.dati_pratica.studio;
            }
            return null;
        },
        get_customer() {
            if ( this.item.hasOwnProperty('customer_owner') ) {
                return this.item.customer_owner.label;
            }
            return null;
        },
    },
    created () {
        this.studio = this.get_studio();
        this.customer = this.get_customer();
    },

}

</script>

<template>

    <div style="">
        <!-- <span>Su pratica </span> -->
        <span v-if="studio" style="font-weight: 700;">{{ studio }} </span>
        <span v-if="!studio" style="color: lightgrey">sconosciuto </span>
        <!-- <span>affidata a </span>
        <span v-if="customer" style="font-weight: 700;">{{ customer }}</span>
        <span v-if="!customer" style="color: lightgrey">sconosciuto</span> -->
    </div>

</template>

