/////////////////////////////////////////////////

// Classe di supporto per gestire le anagrafiche associate ad una pratica
// viene instanziata nello store pratica

class pratica_registries {

    constructor( pra ) {
        this.pra = pra;
    }

    // public methods ///////////////////////////

    has( label ) {
        if ( !this.has_pregs ) { return false; }
        return this.pregs.hasOwnProperty( label );
    }

    get_dependecies( box ) {
        return this.layout[ box ] || [];
    }

    get_ids( payload, index ) { // index è l'id del record controparte (o un altro record "multiplo") in visione
        let ids = [];
        if ( payload.main === 'assicurato_referente' ) {
            ids.push( this.assicurato_referente.id );
        } else if ( payload.main === 'cliente_referente' ) {
            ids.push( this.cliente_referente.id );
        } else if ( payload.main === 'controparte' ) {
            ids.push( this.get_controparte( index ).id );
        } else if ( payload.main === 'controparte_referente' ) {
            ids.push( this.get_controparte_referente( index ).id )
        } else if ( payload.main === 'riparatore' ) {
            ids.push( this.get_riparatore( index ).id );
        } else if ( payload.main === 'riparatore_referente' ) {
            ids.push( this.get_riparatore_referente( index ).id )
        } else if ( payload.main === 'testimone' ) {
            ids.push( this.get_testimone( index ).id );
        } else if ( payload.main === 'testimone_referente' ) {
            ids.push( this.get_testimone_referente( index ).id )
        } else if ( payload.main === 'autorita' ) {
            ids.push( this.get_autorita( index ).id );
        } else if ( payload.main === 'autorita_referente' ) {
            ids.push( this.get_autorita_referente( index ).id )
        } else {
            ids.push( this.pregs[ payload.main ].id );
        }
        if ( payload.dependencies ) {
            payload.dependencies.map( x => {
                if ( x === 'assicurato_referente' ) {
                    if ( this.has_assicurato_referente ) {
                        ids.push( this.assicurato_referente.id );
                    }
                } else if ( x === 'cliente_referente' ) {
                    if ( this.has_cliente_referente ) {
                        ids.push( this.cliente_referente.id );
                    }
                } else if ( x === 'controparte_referente' ) {
                    if ( this.has_controparte_referente( index ) ) {
                        ids.push( this.get_controparte_referente( index ).id );
                    }
                } else if ( x === 'riparatore_referente' ) {
                    if ( this.has_riparatore_referente( index ) ) {
                        ids.push( this.get_riparatore_referente( index ).id );
                    }
                } else if ( x === 'testimone_referente' ) {
                    if ( this.has_testimone_referente( index ) ) {
                        ids.push( this.get_testimone_referente( index ).id );
                    }
                } else if ( x === 'autorita_referente' ) {
                    if ( this.has_autorita_referente( index ) ) {
                        ids.push( this.get_autorita_referente( index ).id );
                    }
                } else if ( this.has( x ) ) {
                    ids.push( this.pregs[ x ].id )
                }
            });
        }
        return ids;
    }

    // verifica se la angrafica passata ha delle dipendenze
    // e se queste dipendenze sono presenti, in caso le restituisce
    get_related_dependencies( label, index ) {
        let dependencies = this.get_dependecies( label );
        if ( dependencies.length === 0 ) { return []; }
        let res = [];
        dependencies.map( x => {
            if ( x === 'assicurato_referente' ) {
                if ( this.has_assicurato_referente ) { res.push( x ); }
            } else if ( x === 'cliente_referente' ) {
                if ( this.has_cliente_referente ) { res.push( x ); }
            } else if ( x === 'controparte_referente' ) {
                if ( this.has_controparte_referente( index ) ) { res.push( x ); }
            } else if ( x === 'riparatore_referente' ) {
                if ( this.has_riparatore_referente( index ) ) { res.push( x ); }
            } else if ( x === 'testimone_referente' ) {
                if ( this.has_testimone_referente( index ) ) { res.push( x ); }
            } else if ( x === 'autorita_referente' ) {
                if ( this.has_autorita_referente( index ) ) { res.push( x ); }
            } else if ( this.has( x ) ) { res.push( x ); }
        });
        return res;
    }

    has_controparte( index ) {
        if ( !this.pregs.hasOwnProperty('controparte') || !this.pregs.controparte.hasOwnProperty( String( index ) ) ) { return false; }
        return this.pregs.controparte[ String( index ) ].hasOwnProperty('controparte') ? true : false;
    }

    get_controparte( index ) {
        if ( !this.has_controparte( index ) ) { return null; }
        return this.pregs.controparte[ String( index ) ].controparte;
    }

    has_controparte_referente( index ) {
        if ( !this.pregs.hasOwnProperty('controparte') || !this.pregs.controparte.hasOwnProperty( String( index ) ) ) { return false; }
        return this.pregs.controparte[ String( index ) ].hasOwnProperty('controparte_referente') ? true : false;
    }

    get_controparte_referente( index ) {
        if ( !this.has_controparte_referente( index ) ) { return null; }
        return this.pregs.controparte[ String( index ) ].controparte_referente;
    }

    // riparatore

    has_riparatore( index ) {
        if ( !this.pregs.hasOwnProperty('riparatore') || !this.pregs.riparatore.hasOwnProperty( String( index ) ) ) { return false; }
        return this.pregs.riparatore[ String( index ) ].hasOwnProperty('riparatore') ? true : false;
    }

    get_riparatore( index ) {
        if ( !this.has_riparatore( index ) ) { return null; }
        return this.pregs.riparatore[ String( index ) ].riparatore;
    }

    has_riparatore_referente( index ) {
        if ( !this.pregs.hasOwnProperty('riparatore') || !this.pregs.riparatore.hasOwnProperty( String( index ) ) ) { return false; }
        return this.pregs.riparatore[ String( index ) ].hasOwnProperty('riparatore_referente') ? true : false;
    }

    get_riparatore_referente( index ) {
        if ( !this.has_riparatore_referente( index ) ) { return null; }
        return this.pregs.riparatore[ String( index ) ].riparatore_referente;
    }

    // testimone

    has_testimone( index ) {
        if ( !this.pregs.hasOwnProperty('testimone') || !this.pregs.testimone.hasOwnProperty( String( index ) ) ) { return false; }
        return this.pregs.testimone[ String( index ) ].hasOwnProperty('testimone') ? true : false;
    }

    get_testimone( index ) {
        if ( !this.has_testimone( index ) ) { return null; }
        return this.pregs.testimone[ String( index ) ].testimone;
    }

    has_testimone_referente( index ) {
        if ( !this.pregs.hasOwnProperty('testimone') || !this.pregs.testimone.hasOwnProperty( String( index ) ) ) { return false; }
        return this.pregs.testimone[ String( index ) ].hasOwnProperty('testimone_referente') ? true : false;
    }

    get_testimone_referente( index ) {
        if ( !this.has_testimone_referente( index ) ) { return null; }
        return this.pregs.testimone[ String( index ) ].testimone_referente;
    }

    // autorita

    has_autorita( index ) {
        if ( !this.pregs.hasOwnProperty('autorita') || !this.pregs.autorita.hasOwnProperty( String( index ) ) ) { return false; }
        return this.pregs.autorita[ String( index ) ].hasOwnProperty('autorita') ? true : false;
    }

    get_autorita( index ) {
        if ( !this.has_autorita( index ) ) { return null; }
        return this.pregs.autorita[ String( index ) ].autorita;
    }

    has_autorita_referente( index ) {
        if ( !this.pregs.hasOwnProperty('autorita') || !this.pregs.autorita.hasOwnProperty( String( index ) ) ) { return false; }
        return this.pregs.autorita[ String( index ) ].hasOwnProperty('autorita_referente') ? true : false;
    }

    get_autorita_referente( index ) {
        if ( !this.has_autorita_referente( index ) ) { return null; }
        return this.pregs.autorita[ String( index ) ].autorita_referente;
    }

    // getters & setters ////////////////////////

    // assicurato_polizza
    get assicurato_polizza() {
        if ( !this.has('assicurato_polizza') ) { return null; }
        let res = this.pregs.assicurato_polizza;
        if ( res.config.attachments && Array.isArray( res.config.attachments ) ) {
            res.attachments = res.config.attachments;
        }
        return res;
    }

    set assicurato_polizza( polizza ) {
        if ( !this.has('assicurato_polizza') ) { return null; }
        if ( polizza.config.attachments && Array.isArray( polizza.config.attachments ) ) {
            polizza.attachments = polizza.config.attachments;
        }
        this.pregs.assicurato_polizza = polizza
    }

    // assicurato_referente

    get assicurato_referente() {
        if ( !this.has_assicurato_referente ) { return null; }
//      let row_id = this.preg_relations.get( this.pregs.assicurato.id ).child_id;
//      let keys = Object.keys( this.pregs );
//      for ( var i = 0; i < keys.length; i++ ) {
//          if ( this.pregs[ keys[ i ] ].id === row_id ) { return this.pregs[ keys[ i ] ]; }
//      }
        return this.pregs.assicurato_referente;
        throw 'non trovato';
    }

    set assicurato_referente( payload ) {
        if ( !this.has_assicurato_referente ) { return null; }
        let keys = Object.keys( this.pregs );
        for ( var i = 0; i < keys.length; i++ ) {
            if ( this.pregs[ keys[ i ] ].id === payload.id ) { this.pregs[ keys[ i ] ] = payload; return; }
        }
        throw 'impossibile settare assicurato_referente';
    }

    get has_assicurato_referente() {
        if ( !this.has_preg_relations || !this.has('assicurato') ) { return false; }
        //return this.preg_relations.has( this.pregs.assicurato.id );
        return this.pregs.hasOwnProperty( 'assicurato_referente' );
    }

    // cliente_referente

    get cliente_referente() {
        if ( !this.has_cliente_referente ) { return null; }
        let row_id = this.preg_relations.get( this.pregs.cliente.id ).child_id;
        let keys = Object.keys( this.pregs );
        for ( var i = 0; i < keys.length; i++ ) {
            if ( this.pregs[ keys[ i ] ].id === row_id ) { return this.pregs[ keys[ i ] ]; }
        }
        throw 'non trovato';
    }

    set cliente_referente( payload ) {
        if ( !this.has_cliente_referente ) { return null; }
        let keys = Object.keys( this.pregs );
        for ( var i = 0; i < keys.length; i++ ) {
            if ( this.pregs[ keys[ i ] ].id === payload.id ) { this.pregs[ keys[ i ] ] = payload; return; }
        }
        throw 'impossibile settare cliente_referente';
    }

    get has_cliente_referente() {
        if ( !this.has_preg_relations || !this.has('cliente') ) { return false; }
        return this.preg_relations.has( this.pregs.cliente.id );
    }

    // controparte

    get controparte_ids() {
        if ( this.pregs.hasOwnProperty('controparte') && Object.keys( this.pregs.controparte ).length > 0 ) { return Object.keys( this.pregs.controparte ); }
        return [];
    }

    set controparte( item ) { // solo per update
        this.pregs.controparte[ String( item.id ) ].controparte = item;
    }

    set controparte_referente( item ) { // solo per update
        this.pregs.controparte[ String( item.father_id ) ].controparte_referente = item.controparte_referente;
    }

    get controparte_items() {
        if ( this.pregs.hasOwnProperty('controparte') && Object.keys( this.pregs.controparte ).length > 0 ) {
            return Object.keys( this.pregs.controparte ).map( id => {
                return {
                    text  : this.pregs.controparte[ id ].controparte.cognome || this.pregs.controparte[ id ].controparte.denominazione,
                    value : id
                };
            });
        }
        return [];
    }

    // riparatore

    get riparatore_ids() {
        if ( this.pregs.hasOwnProperty('riparatore') && Object.keys( this.pregs.riparatore ).length > 0 ) { return Object.keys( this.pregs.riparatore ); }
        return [];
    }

    get riparatore_items() {
        if ( this.pregs.hasOwnProperty('riparatore') && Object.keys( this.pregs.riparatore ).length > 0 ) {
            return Object.keys( this.pregs.riparatore ).map( id => {
                return {
                    text : this.pregs.riparatore[ id ].riparatore.cognome || this.pregs.riparatore[ id ].riparatore.denominazione,
                    value : id
                };
            });
        }
        return [];
    }

    set riparatore( item ) { // solo per update
        this.pregs.riparatore[ String( item.id ) ].riparatore = item;
    }

    set riparatore_referente( item ) { // solo per update
        this.pregs.riparatore[ String( item.father_id ) ].riparatore_referente = item.riparatore_referente;
    }
 
    // testimone

    get testimone_ids() {
        if ( this.pregs.hasOwnProperty('testimone') && Object.keys( this.pregs.testimone ).length > 0 ) { return Object.keys( this.pregs.testimone ); }
        return [];
    }

    set testimone( item ) { // solo per update
        this.pregs.testimone[ String( item.id ) ].testimone = item;
    }

    set testimone_referente( item ) { // solo per update
        this.pregs.testimone[ String( item.father_id ) ].testimone_referente = item.testimone_referente;
    }

    get testimone_items() {
        if ( this.pregs.hasOwnProperty('testimone') && Object.keys( this.pregs.testimone ).length > 0 ) {
            return Object.keys( this.pregs.testimone ).map( id => {
                return {
                    text : this.pregs.testimone[ id ].testimone.cognome || this.pregs.testimone[ id ].testimone.denominazione,
                    value : id
                };
            });
        }
        return [];
    }
 
    // autorita

    get autorita_ids() {
        if ( this.pregs.hasOwnProperty('autorita') && Object.keys( this.pregs.autorita ).length > 0 ) { return Object.keys( this.pregs.autorita ); }
        return [];
    }

    set autorita( item ) { // solo per update
        this.pregs.autorita[ String( item.id ) ].autorita = item;
    }

    set autorita_referente( item ) { // solo per update
        this.pregs.autorita[ String( item.father_id ) ].autorita_referente = item.autorita_referente;
    }

    get autorita_items() {
        if ( this.pregs.hasOwnProperty('autorita') && Object.keys( this.pregs.autorita ).length > 0 ) {
            return Object.keys( this.pregs.autorita ).map( id => {
                return {
                    text : this.pregs.autorita[ id ].autorita.cognome || this.pregs.autorita[ id ].autorita.denominazione,
                    value : id
                };
            });
        }
        return [];
    }

    get preg_relations() {
        if ( !this.has_preg_relations ) { return null; }
        let res = new Map();
        this.pra.preg_relations.map( x => {
            res.set( x.father_id, x );
        });
        return res;
    }

    get has_preg_relations() {
        return this.pra.hasOwnProperty('preg_relations') && Array.isArray( this.pra.preg_relations ) && this.pra.preg_relations.length > 0;
    }

    get pregs() {
        return this.pra.pregs;
    }

    get has_pregs() {
        return this.pra.hasOwnProperty('pregs') && typeof this.pra.pregs === 'object';
    }

    get layout() {
        return {
            assicurato  : [ 'assicurato_referente', 'assicurato_polizza' ],
            compagnia   : [ 'agenzia', 'ispettorato', 'liquidatore' ],
            cliente     : [ 'cliente_referente', ],
            controparte : [ 'controparte_referente' ],
            riparatore  : [ 'riparatore_referente' ],
            testimone   : [ 'testimone_referente' ],
        };
    }

}

export default pratica_registries;

