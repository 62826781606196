<script>

export default {

    name: 'reminder-status',
    props: [ 'item' ],
    data() {
        return {
        }
    },
    computed: {},
    methods: {
    },
    created () {},

}

</script>

<template>

    <table style="margin: auto;">
        <tr><td style=""><small style="font-weight: 600">EMAIL INVIATE</small></td><td><span style="padding-left: 4px;"><b>{{ item.config.sent }}</b></span></td></tr>
        <tr><td style=""><small style="font-weight: 600">MAX EMAIL</small></td><td><span style="padding-left: 4px;"><b>{{ item.config.max }}</b></span></td></tr>
    </table>

</template>

