<script>

const nprogress    = require('nprogress');
const { listpage } = require('@/vue-model-mirror');
const moment       = require('moment');

export default {
    name: 'statistic',
    extends: listpage,
    mounted () {
        this.$nextTick(function () {
            nprogress.done(true);
        })
    },
    components: {
    },
    computed: {
//      qry_options () {
//          var options = {};
//          if ( this.options.hasOwnProperty('include') ) { options.include = this.options.include; }
//          if ( this.options.hasOwnProperty('initialfilter') ) {
//              Object.keys( this.options.initialfilter ).map( x => { options[ x ] = this.options.initialfilter[ x ]; } );
//          }
//          options['group'] = 'true';
//          //options['customer_id'] = this.filters.customer_id;
//          return options;
//      },
        customers() { return this.$store.getters['statemanager/customers']; },
        user () {
            return JSON.parse( localStorage.getItem('user') );
        },
        role_id() { return this.$store.getters['statemanager/role_id']; },
        amount_studio() { return new Intl.NumberFormat( 'it-IT', { style: 'currency', currency: 'EUR' } ).format( this.$store.state.search_statistic.amount_studio ); },
        amount_users() { return new Intl.NumberFormat( 'it-IT', { style: 'currency', currency: 'EUR' } ).format( this.$store.state.search_statistic.amount_users ); },
        amount_tot() { return this.$store.state.search_statistic.amount_studio - this.$store.state.search_statistic.amount_users; },
        total() { return new Intl.NumberFormat( 'it-IT', { style: 'currency', currency: 'EUR' } ).format( this.amount_tot ); },
        total_variant() {
            if ( this.amount_tot > 0 ) { return 'sucess'; }
            else if ( this.amount_tot < 0 ) { return 'danger'; }
            return 'warning';
        },
        pras() { return this.$store.state.search_statistic.pras_involved; },
    },
    created () {
        // filtro iniziale
        this.filters = this.$store.state.search_statistic.filters;
        if ( !this.filters.customer_id ) {
            this.filters.customer_id        = this.customers[0].id;
            this.filters.data_evasione__gte = moment().startOf('month').format('YYYY-MM-DD');
            this.filters.data_evasione__lte = moment().endOf('month').format('YYYY-MM-DD');
            this.$store.dispatch( 'search_statistic/set_filters', {
                group              : true,
                customer_id        : this.customers[0].id,
                data_evasione__gte : moment().startOf('month').format('YYYY-MM-DD'),
                data_evasione__lte : moment().endOf('month').format('YYYY-MM-DD'),
            });
        }
        this.loading_main = false;
    },
    methods: {
        toggle_modal(modal_id = 'modal-filter') {
            modal_id = modal_id.constructor.name === 'MouseEvent' ? 'modal-filter' : modal_id;
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        clear_search_form( modal_event ) {
            if ( modal_event.trigger === 'clear' ) {
                this.reset_search_form();
                this.$refs.search_form.submit_form();
            }
        },
        submit_search_form() {
            this.$refs.search_form.submit_form();
        },
        operation_success: function(data) {
            Object.keys( data ).map( x => {
                if ( data[ x ] === null ) {
                    this.$store.dispatch('search_statistic/remove_filter', x);
                } else {
                    var filter = {};
                    filter[ x ] = typeof data[ x ] === 'object' ? data[ x ].search : data[ x ];
                    if ( [ 'dati_pratica.mandante', 'dati_pratica.ramo', 'agenzia.denominazione',  ].includes( x ) && typeof data[ x ] !== 'object' ) {
                        filter[ x ] = `|${ filter[ x ] }|`;
                    }
                    this.$store.dispatch('search_statistic/add_filter', filter);
                }
            }); 
            this.filters = data;
            this.toggle_modal();
            this.$store.dispatch( 'search_statistic/force_reload' );
            this.list_key += 1;
        },
    },
    data () {
        return {
            loading_main : true,
            filters: {
                customer_id        : null,
                data_incarico__gte : null,
                data_incarico__lte : null,
                data_chiusura__gte : null,
                data_chiusura__lte : null,
                mandante           : null,
                ramo               : null,
                agenzia            : null,
                user_id            : null,
            },
            inline           : false,
            modal_filter_key : 0,
            list_key         : 0,
            visible          : true,
        }
    },
};

</script>

<template>

    <div style="width: 100%; margin: auto">
        <h3 style="text-align: center; padding-bottom: 14px;">{{ title }}</h3>
        <b-row class="justify-content-md-center" v-if="!loading_main">
            <b-col col lg="6" md="6" sm="12">
                <b-row style="margin-bottom: 10px;">
                    <b-col align-self="center" lg="auto" md="6">
                        <b-button @click="toggle_modal('modal-filter')" size="sm" variant="outline-success btn-sm mr-auto" style="margin-left: 10px;"><icon name="filter" style="fill: #28A745"/></b-button>
                        <b-button v-if="user.role_id === 1" :disabled="true" @click="toggle_modal('modal-export')" size="sm" variant="outline-secondary btn-sm mr-auto" style="margin-left: 10px;">
                            <icon name="download" style="fill: #6D767E"/>
                        </b-button>
                    </b-col>
                </b-row>
                <b-row style="margin-bottom: 10px">
                    <b-col v-if="add" align-self="end" class="text-right">
                        <b-button @click="new_element();" size="sm" variant="outline-info"><icon name="plus"/></b-button>
                    </b-col>
                </b-row>
                <bnvlist
                    :key             = "list_key"
                    :schema          = "schema_name"
                    storename        = "bnvform"
                    :skip            = "skip"
                    :fields          = "fields"
                    :primarykey      = "primary_key"
                    :qryoptions      = "qry_options"
                    :details         = "details"
                    :detailsclass    = "details_class"
                    :detailsaction   = "details_action"
                    :edit            = "edit"
                    :editaction      = "edit_action"
                    :paginate        = "paginate"
                    :link            = "link"
                    :rowclass        = "rowclass"
                    :linkfunction    = "linkfunction"
                    :initialfilter   = "initialfilter"
                    ref              = "listbnv"
                    :size            = "size"
                    :formpath        = "item_form"
                    @row_selected    = "row_selected"
                    @right-click     = "right_click"
                ></bnvlist>
        
                <b-row style="padding-left: 14px; margin-top: 20px" v-if="role_id === 1">
                    <table>
                        <tr>
                            <td>
                                <h4>Importo lordo studio</h4>
                            </td>
                            <td style="padding-left: 7px">
                                <h4><b-badge>{{ amount_studio }}</b-badge></h4>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h4>Totale utenti</h4>
                            </td>
                            <td style="padding-left: 7px">
                                <h4><b-badge>{{ amount_users }}</b-badge></h4>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h3>TOTALE</h3>
                            </td>
                            <td style="padding-left: 7px">
                                <h3><b-badge :variant="total_variant">{{ total }}</b-badge></h3>
                            </td>
                        </tr>
                    </table>
                </b-row>
                <b-row style="padding-left: 14px; margin-top: 20px; margin-bottom: 50px" v-if="role_id === 1">
                    <b-col>
                        <b-row>
                            <b-button v-b-toggle.collapse-2 variant="outline-secondary" class="m-1" size="sm">Pratiche incluse</b-button>
                        </b-row>
                        <b-row>
                            <b-collapse id="collapse-2">
                                <b-badge style="margin: 5px" v-for="codice in pras" :key="codice" variant="info">{{ codice }}</b-badge>
                            </b-collapse>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <!-- modal filtri -->
        <b-modal
            id="modal-filter"
            title="Filtri"
            @hide="clear_search_form"
            button-size="sm"
            >
            <bnvform
                v-if       = "search_schema"
                :key       = "modal_filter_key"
                :name      = "search_schema"
                :schema    = "schema"
                :options   = "form_config"
                :record    = "filters"
                :submit    = "false"
                :inline    = "inline"
                ref        = "search_form"
                @submitted = "operation_success"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_search_form">
                      OK
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="warning" @click="hide('clear')">
                      Reset
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>
    </div>

</template>


