//////////////////////////////

module.exports = (type) => {
    var schema = {
        importo_riserva: {
            type: type.DECIMAL(10,2),
            allowNull: true,
            defaultValue: null,
        },
//      id: {
//          type: type.VIRTUAL,
//          hidden: true,
//      },
    };

    return schema;
}


