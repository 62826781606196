<script>

import bnvform from '@/vue-model-mirror/bnvform';
const schemas = require('@/schemaconfig');
import CodFisc from '../helpers/CodFisc';

export default {
    name: 'registry-registry',
    props: [ 'record', 'started' ],
    components : { bnvform },
    computed : {
        comune_nascita_executed() { return this.$store.state.comune_nascita.executed_obj; }
    },
    watch: {
        comune_nascita_executed( new_value ) {
            if ( new_value === true ) {
                this.cf_payload.comune_nascita_id = this.$store.state.comune_nascita.obj.id;
            }
        }
    },
    methods : {
        value_changed( obj ) {
            console.log( obj );
//          var fields = {
//              strada      : obj.route,
//              comune_id   : obj.locality,
//              n_civ       : obj.street_number,
//              cap         : obj.postal_code,
//              nazione     : obj.country,
//              latitudine  : obj.latitude,
//              longitudine : obj.longitude,
//          };
//          if ( obj.country === 'Italia' ) {
//              fields.comune_id = obj.locality;
//              fields.citta_estero = null;
//          } else {
//              fields.citta_estero = obj.locality;
//              fields.comune_id = null;
//          }
//          this.$refs.form_registry.set_payload( fields, true );
        },
        operation_success( payload ) {
            console.log( payload );
        },
        toggle_modal(modal_id = 'modal-cf') {
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        reset_form() {},
        cf_button( payload ) {
            var codice_fiscale = this.$refs.form_registry.get_element_value('codice_fiscale');
            if ( codice_fiscale && codice_fiscale.length > 0 ) {
                if ( !CodFisc.validate( codice_fiscale ) ) {
                    this.cf_payload.sesso             = null;
                    this.cf_payload.data_nascita      = null;
                    this.cf_payload.comune_nascita_id = null;
                    if ( payload.open ) { this.toggle_modal(); }
                    return;
                }
                var data = CodFisc.parse_codfisc( codice_fiscale );
                this.cf_payload.sesso          = data.sesso;
                this.cf_payload.data_nascita   = data.data_nascita;
                this.$store.dispatch( 'comune_nascita/get_by_code', data.codice_comune );
                if ( payload.open ) { this.toggle_modal(); }
                return;
            } else {
                this.cf_payload.sesso             = null;
                this.cf_payload.data_nascita      = null;
                this.cf_payload.comune_nascita_id = null;
            }
            if ( payload.open ) { this.toggle_modal(); }
        },
        submit_cf_form() {
            this.$refs.modal_cf.submit_form();
        },
        save_cf_data( payload ) {
            this.$refs.modal_cf.validate();
            if ( !this.$refs.modal_cf.is_valid ) { return; }
            if ( payload.sesso && payload.data_nascita && payload.comune_nascita_id ) {
                var nome    = this.$refs.form_registry.get_element_value('nome');
                var cognome = this.$refs.form_registry.get_element_value('cognome');
                if ( nome && nome.length > 0 && cognome && cognome.length > 0 ) {
                    var data = {
                        nome,
                        cognome,
                        codice_comune : this.$store.state.comune_nascita.obj.codice,
                        data_nascita  : payload.data_nascita,
                        sesso         : payload.sesso,
                    };
                    var codice_fiscale = CodFisc.get_codfisc( data );
                    this.$refs.form_registry.set_payload( { codice_fiscale }, true );
                    this.$refs.form_registry.reset_error('codice_fiscale');
                }
            }
            this.toggle_modal();
        },
    },
    created() {},
    data() {
        return {
            schema_name  : '_registry',
            schema       : schemas['_registry'],
            key          : 0,
            options      : {},
            cf_payload   : { sesso: null, data_nascita: null, comune_nascita_id: null, first_name: null, last_name: null },
            cf_schema    : schemas['_codfisc'],
        }
    },
}

</script>

<template>

    <div>

        <bnvform
            v-if       = "started === true"
            ref        = "form_registry"
            :name      = "schema_name"
            :schema    = "schema"
            :options   = "options"
            :record    = "record"
            :inline    = "true"
            :submit    = "false"
            :key       = "key"
            @reset     = "reset_form"
            @submitted = "operation_success"
            @saved     = "operation_success"
            @change    = "value_changed"
            @cf-button = "cf_button"
        ></bnvform>

        <!-- modal CF -->
        <b-modal
            id          = "modal-cf"
            title       = "Dati relativi al Codice Fiscale"
            button-size = "sm"
            >
            <bnvform
                name       = "_codfisc"
                :schema    = "cf_schema"
                :options   = "{}"
                :record    = "cf_payload"
                :submit    = "false"
                :inline    = "true"
                ref        = "modal_cf"
                layout     = "single"
                @submitted = "save_cf_data"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_cf_form">
                      Salva
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

    </div>

</template>

<style>
</style>

