////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'pratica_parcel',
    send_alert    : false,
    default_order : 'id ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    filters       : {},
    amount_studio : 0,
    amount_users  : 0,
    pras_involved : [],
};

const getters = {
    ...base.getters,
}

const actions = {
    ...base.actions,
    get_all( { commit, state, getters }, _data = { options: {} } ) { // ok
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        //console.log( Object.keys( data.options ) );
        //console.log( Object.keys( state.filters ) );
        commit('start_request');
        data.options.order = getters.order;
        if ( Object.keys( state.filters ).length === 0 ) {
            //console.log( 'filtri vuoti' );
        }
        Object.keys( state.filters ).map( key => { data.options[ key ] = state.filters[ key ]; } );
        state.service.get_all(getters.model_name, data.options)
            .then( response => {
                //console.log('ciao');
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response.rows);
                    commit('set_amount_studio', response.amount_studio);
                    commit('set_amount_users', response.amount_users);
                    commit('set_pras', response.pras);
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
};

const mutations = {
    ...base.mutations,
    set_amount_studio( state, data ) { state.amount_studio = data; },
    set_amount_users( state, data ) { state.amount_users = data; },
    set_pras( state, data ) { state.pras_involved = data; },
    clear( state ) {
        state.obj           = {};
        state.items         = null;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.executed      = false;
        state.force_reload  = false;
        state.selected      = false;
        state.amount_studio = 0;
        state.amount_users  = 0;
    },
}

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
    getters,
};
