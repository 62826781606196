//////////////////////////////

module.exports = (type) => {
    var schema = {
        task_label: {
            type: type.STRING(128),
            allowNull: false,
        },
        label: {
            type: type.STRING(128),
            allowNull: true,
            dafaultValue: null,
        },
        multi: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: false,
        },
        user_id: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'users',
                key         : 'id',
                constraints : true,
                store_name  : 'user',
            }
        },
        codice_pratica: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'pratiche',
                key         : 'codice',
                constraints : true,
                store_name  : 'pratica',
            }
        },
        done: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: false,
        },
//      state_label: {
//          type: type.STRING(64),
//          allowNull: false,
//          defaultValue: 'nuovo',
//          references: {
//              model       : 'states',
//              key         : 'label',
//              constraints : true,
//              store_name  : 'state',
//          },
//      },
        operator: {
            type: type.VIRTUAL,
        },
        newstate: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'newstates',
                key         : 'id',
                constraints : true,
                store_name  : 'newstate',
            },
        },
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
    };

    return schema;
}

