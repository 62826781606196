///////////////////////////////////////

import sm from '@/helpers/statemanager';

export default {

    namespaced: true,
    state: {
        sm : new sm(),
    },
    getters: {
        is_setted          : ( state ) => state.sm.is_setted,
        states             : ( state ) => state.sm.states,
        states_tasks       : ( state ) => state.sm.states_tasks,
        customers          : ( state ) => state.sm.customers,
        first_state_id     : ( state ) => state.sm.first_state_id,
        templates          : ( state, getters ) => ( customer_id ) => {
            var templates = state.sm.templates[ customer_id ? customer_id : getters.customers[ 0 ].id ];
            return templates.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
        },
        custodians         : ( state, getters ) => ( customer_id ) => state.sm.get_custodians( customer_id ? customer_id : getters.customers[ 0 ].id ),
        custodian_conf     : ( state ) => ( customer_id ) => state.sm.get_custodian_conf( customer_id ),
        icon               : ( state ) => ( task_label ) => state.sm.get_icon( task_label ),
        color_by_state     : ( state ) => ( state_label ) => state.sm.get_color_by_state( state_label ),
        role_id            : ( state ) => state.sm.role_id,
        unpaid_tasks       : ( state ) => ( customer_id ) => state.sm.get_unpaid_tasks( customer_id ),
        state_label        : ( state ) => ( state_key ) => state.sm.get_state_label_by_key( state_key ),
    },
    actions: {
        set_obj( { commit }, data ) { commit('set_obj', data); },
    },
    mutations: {
        set_obj( state, data ) { state.sm.user = data; },
    }

}


