////////////////////

import { authHeader } from '@/helpers';

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export const periziaService = {
    make_perizia,
};

function make_perizia(codice_pratica, shrink) {
    var options = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify( { shrink } ),
    };

    return fetch(`${ config.backend_url }model/pratica/${ codice_pratica }/perizia`, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

