<script>

export default {

    name: 'cmp-files-pretty-print-size',
    props: [ 'item' ],
    data() {
        return {
        }
    },
    computed: {},
    methods: {
        get_size() {
            if ( this.item.task_image_id === null ) { return ''; }
            var size = this.item.task_image.value.conv_size ?this.item.task_image.value.conv_size :  this.item.task_image.value.size
            if ( !size ) { return '<span style="color: lightgrey; white-space: nowrap;"><i>no size</i></span>'; }
            return this.$options.filters.prettyBytes( size, 1, true );
        }
    },
    created () {
    },

}

</script>

<template>

    <div style="padding-left: 10px; color: grey;">
        <small style="white-space: nowrap;" v-html="get_size()"></small>
    </div>

</template>


