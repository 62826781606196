////////////////////

import { color_service } from '@/services';
import base_module from '@/vue-model-mirror/stores/base.module';

var options = {
    //model_name    : 'user',
    send_alert    : false,
    //default_order : 'last_name ASC',
};

var base = new base_module( options, color_service );

export default {
    namespaced : true,
    ...base,
};

////////////////////

//import { color_service } from '@/services';
//
//export const tasklist = {
//    namespaced: true,
//    state: {
//        title_form    : 'Ruolo',
//        obj           : {},
//        items         : null,
//        loading       : false,
//        error         : false,
//        executed      : false,
//        force_reload  : false,
//        page          : 1,  // default
//        rows_per_page : 25, // default
//        tot           : 0,
//    },
//    actions: {
//        get_all({commit, rootState}, data = { options: {} }) {
//            commit('start_request');
//            commit('success_list', rootState.pratica.tasks);
//        },
//    },
//    mutations: {
//        start_request( state ) {
//            state.loading      = true;
//            state.error        = false;
//            state.executed     = false;
//            state.force_reload = false;
//        },
//        success( state, obj ) {
//            state.obj      = obj;
//            state.loading  = false;
//            state.error    = false;
//            state.executed = true;
//        },
//        success_obj( state, obj ) {
//            state.obj      = obj;
//            state.loading  = false;
//            state.error    = false;
//            state.executed = false;
//        },
//        success_list( state, items ) {
//            state.items    = items;
//            state.loading  = false;
//            state.error    = false;
//            state.executed = true;
//        },
//        error( state, error ) {
//            state.obj          = error;
//            state.loading      = false;
//            state.error        = true;
//            state.executed     = false;
//            state.force_reload = true;
//        },
//        set_total( state, data ) {
//            state.tot = data;
//        },
//        set_page( state, data ) {
//            state.page = data;
//        },
//        set_rows_per_page( state, data ) {
//            state.rows_per_page = data;
//        },
//        force_reload(state) {
//            state.force_reload = true;
//        },
//        executed(state) {
//            state.executed = false;
//        },
//        clear( state ) {
//            state.obj          = {};
//            state.items        = null;
//            state.loading      = false;
//            state.error        = false;
//            state.executed     = false;
//            state.force_reload = false;
//        },
//    }
//}


