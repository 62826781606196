<script>

// TODO rimouovere related_objs

import { mapActions, mapState } from 'vuex';
const schemas = require('@/schemaconfig');
import addRegistry from './add_registry';
import ref_forms from '../forms';
import relation from '../classes/relations';
import RelationTable from './RelationTable';

export default {
    name: 'relation-manager',
    props: [ 'action', 'related_objs', 'customer_id', 'record', ],
    components : { addRegistry, RelationTable, },
    computed : {
        ...mapState('registry_autocomplete', {
            executed : state => state.executed,
            items    : state => state.items,
        }),
        ...mapState('registry_template', {
            templates : state => state.template_map_ids,
            template_names : state => state.template_map_names,
        }),
        ...mapState('registry_role', {
            roles : state => state.items,
        }),
        ...mapState('registry_role', {
            roles_map : state => state.role_map_ids,
        }),
        ...mapState('registry', {
            obj_modal : state => state.obj_modal,
            relation_created : state => state.relation_created,
        }),
        children_ids() {
            let ids = [];
            if ( this.record && this.record.children ) {
                ids = this.record.children.map( x => x.id );
            }
            return ids;
        },
    },
    mounted() {
        this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
            this.modals[ modalId ] = true;
        })
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            this.modals[ modalId ] = false;
        })
    },
    watch: {
        search_text( new_value ) {
            if ( this.skip ) { this.skip = false; this.clear(); this.show_options = false; return; }
                if ( new_value.length < this.min_matching_chars ) { this.clear(); this.show_options = false; return; }
            if ( this.timeout ) {
                clearTimeout( this.timeout );
            }
            this.timeout = setTimeout( () => {
                this.load_items();
            }, 500);
        },
        executed( new_value ) {
            if ( !new_value ) { return; }
            if ( !this.modals['modal-relations'] ) {
                if ( this.items.length > 0 ) {
                    this.toggle_modal();
                } else {
                    // qui apro direttamente il modal per la creazione della nuova registry
                    this.$refs.add_registry_component_invisible.toggle_modal()
                    return;
                }
                
            }
            if ( this.items && this.items.length > 0 ) {
                this.show_options = true;
            } else {
                this.show_options = false;
                this.clear();
            }
        },
        obj_modal( new_value ) {
            if ( !new_value || typeof new_value !== 'object' ) { return; }
            this.toggle_modal('modal-new-registry');
            this.item_selected( new_value );
        },
        relation_created( new_value ) {
            this.populate_relation( new_value )
        },
    },
    methods : {
        ...mapActions( 'registry_autocomplete', { search: 'get_all', clear: 'clear' } ),
        ...mapActions( 'registry', { add_relation: 'add_relation', } ),
        load_items() {
            let template_ids = this.template.map( name => this.template_names.get( name ) );
            var search_options = { order: 'label ASC', options: { customer_id: this.customer_id, paginate: true, rows_per_page: 12 } };
            if ( template_ids.length === 1 ) {
                search_options.options.template_id = String( template_ids[0] );
            } else {
                search_options.options.template_id__in = template_ids.join(',');
            }
            if ( this.search_text.length > 0 ) {
                search_options.options.label = `${ this.search_text }|`;
            }
            //var search_options = { order: 'label ASC', options: { label: `${ this.search_text }|`, customer_id: this.customer_id } };
            this.search( search_options );
        },
        close_list() {
            setTimeout( () => {
                this.show_options = false;
            }, 200 );
        },
        item_selected( item ) {
            this.skip        = true;
            this.show        = false;
            var relation_obj = {
                customer_id    : this.customer_id,
                child_role_id  : this.child_role,
                father_role_id : this.father_role,
            };
            if ( this.direction === 'child' ) {
                relation_obj.father_id = item.id;
                relation_obj.child_id  = this.record.id;
            } else {
                relation_obj.father_id = this.record.id;
                relation_obj.child_id  = item.id;
            }
            if ( this.action === 'edit' ) {
                this.add_relation( relation_obj );
            }
            this.rels.push( new relation( this.roles_map.get( this.direction === 'parent' ? this.child_role : this.father_role ).label.toLowerCase(), item, this.direction === 'parent' ? 'warning' : 'secondary', relation_obj ) );
            if ( this.modals['modal-relations'] === true ) {
                this.search_text = item.label;
                this.toggle_modal();
            } 
        },
        populate_relation( obj ) {
            for ( var i = 0; i < this.rels.length; i++ ) {
                if (
                    obj.father_id      === this.rels[ i ].obj.father_id &&
                    obj.child_id       === this.rels[ i ].obj.child_id &&
                    obj.father_role_id === this.rels[ i ].obj.father_role_id &&
                    obj.child_role_id  === this.rels[ i ].obj.child_role_id
                ) {
                    this.rels[ i ].relation = obj;
                    break;
                }
            }
        },
        set_rels() {
            var rels = [];
            // relazioni verso l'alto
            this.related_objs.parents.map( x => {
                rels.push( new relation( this.roles_map.get( x.registry_relation.father_role_id ).label.toLowerCase(), x, 'secondary', x.registry_relation ) );
            });
            // relazioni verso il basso
            this.related_objs.children.map( x => {
                rels.push( new relation( this.roles_map.get( x.registry_relation.child_role_id ).label.toLowerCase(), x, 'warning', x.registry_relation ) );
            });
            this.rels = rels;
        },
        toggle_modal(modal_id = 'modal-relations') {
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        clear_modal() {
            this.role        = '';
            this.child_role  = '';
            this.father_role = '';
            this.field_label = '';
            this.search_text = '';
            this.direction   = null;
        },
        clear_modal_new_registry() {
        },
        new_relation( data ) {
            this.roles.map( x => { if ( x.label === data.child_role ) { this.child_role = x.id; } } );
            this.roles.map( x => { if ( x.label === data.father_role ) { this.father_role = x.id; } } );
            this.template  = data.template;
            this.direction = data.direction;
            var role = data.direction === 'child' ? data.father_role : data.child_role;
            this.field_label = 'Associa ' + role.charAt(0).toUpperCase() + role.slice(1);
            this.load_items();
            //this.toggle_modal();
        },
        new_registry( data ) {
            this.newreg_schema_name = 'registry';
            this.newreg_component   = ref_forms[ data.template.replace( /^_/, '' ) ];
            this.toggle_modal('modal-new-registry');
        },
        submit_newreg() {
            var is_valid = this.$refs.newreg_form.save();
        },
        set_focus() {
            this.$refs.input.focus()
        }
    },
    created() {
        this.clear();
        this.set_rels();
    },
    data() {
        return {
            search_text         : '',
            show_options        : false,
            skip                : false,
            min_matching_chars  : 0,
            timeout             : null,
            rels                : [],
            role                : '',
            child_role          : '',
            father_role         : '',
            field_label         : '',
            template            : null,
            direction           : null,
            new_registry_record : { template_id: null, customer_id: null, },
            newreg_schema_name  : null,
            newreg_component    : null,
            newreg_key          : 0,
            modals              : { 'modal-relations': false },
        }
    },
}

</script>

<template>

    <div>
        <!-- COMPONENT INVISIBILE
             serve ad parire direttamente il modal di nuova anagrafica
             nel caso in cui non vi siamo anagrafiche associate -->
        <add-registry
            @save      = "new_registry"
            ref        = "add_registry_component_invisible"
            :payload   = "{ 'customer_id': customer_id, 'template': template }"
            :invisible = "true"
            >
            <template #button="data">
                <b-button variant="outline-secondary" @click="data.toggle_modal()" :disabled="search_text.length > 0" size="sm"><icon name="plus"/></b-button>
            </template>
        </add-registry>
        <!-- - - - - - - - - - - -->

        <b-row style="margin: 2px; max-width: 800px; margin: auto; margin-top: 10px; border: 1px solid lightgrey; padding: 10px; border-bottom: 0px">
            <b-col cols="3" style="padding: 0">
                <p style="font-weight: 800; margin-left: 5px; margin-bottom: 0">Relazioni</p>
            </b-col>
            <b-col cols="9" style="padding: 0; text-align: right;">
                <slot name="relation_buttons" :new_relation="new_relation" />
                <!-- <b-button @click="toggle_modal()" size="sm" variant="outline-info"><icon name="info"/></b-button> -->
            </b-col>
        </b-row>
        <b-row style="margin: 2px; max-width: 800px; margin: auto; border: 1px solid lightgrey; padding: 10px; padding-top: 2px; border-top: 0px">
            <b-col cols="12" style="padding: 0">
                <relation-table
                    :rels   = "rels"
                    :action = "action"
                />
            </b-col>
        </b-row>

        <!-- modal new registry -->
        <b-modal
            id          = "modal-new-registry"
            title       = "Nuova Anagrafica"
            size        = "xl"
            @hide       = "clear_modal_new_registry"
            button-size = "sm"
            >
            <component
                v-bind:key         = "newreg_key"
                ref                = "newreg_form"
                :is                = "newreg_component"
                :schema_from_route = 'newreg_schema_name'
                :modal             = "true"
            ></component>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_newreg">
                        Salva
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                        Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <!-- modal relations -->
        <b-modal
            id          = "modal-relations"
            title       = "Relazioni"
            @hide       = "clear_modal"
            @shown      = "set_focus"
            button-size = "sm"
            ref         = "modal_relations"
            >
            <div style="width: 80%; margin: auto">
                <div class="form-group autocomplete">
                    <label for="ac-relation" class="sm">{{ field_label }}</label>
                    <b-input-group>
                       <!-- :disabled         = "role === ''" -->
                        <b-input
                            autocomplete      = "off"
                            v-on:keyup.enter  = "enter_key"
                            ref               = "input"
                            type              = "text"
                            id                = "ac-relation"
                            size              = "sm"
                            v-model           = "search_text"
                            @blur             = "close_list"
                        />
                        <b-input-group-append>
                            <add-registry
                                @save      = "new_registry"
                                ref        = "add_registry_component"
                                :payload   = "{ 'customer_id': customer_id, 'template': template }"
                                >
                                <template #button="data">
                                    <b-button variant="outline-secondary" @click="data.toggle_modal()" :disabled="search_text.length > 0" size="sm"><icon name="plus"/></b-button>
                                </template>
                            </add-registry>
                        </b-input-group-append>
                    </b-input-group>
                    <div class="" style="height: 390px">
                        <b-list-group>
                            <b-list-group-item
                                href   = "#"
                                v-for  = "item in items"
                                :key   = "item.id"
                                style  = "padding: 4px 10px;"
                                :style = "{ 'background-color': children_ids.includes( item.id ) ? '#F0F0F0' : 'white' }"
                                @click = "item_selected( item )"
                                :disabled = "children_ids.includes( item.id )"
                                >
                                {{ item.label }}
                            </b-list-group-item>
                        </b-list-group>
                    </div>
                </div>
            </div>
        </b-modal>

    </div>

</template>

<style>
</style>

