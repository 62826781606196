////////////////////

//import { bnvform_service } from '@/services';

export const form_modal = {
    namespaced: true,
    state: {
        data       : {},
        open_modal : false,
    },
    actions: {
        set_schema({commit, dispatch}, data) {
            commit('launch_modal', data);
        },
        clear({commit}) {
            commit('clear');
        },
    },
    mutations: {
        launch_modal( state, data ) {
            state.data       = data;
            state.open_modal = true;
        },
        clear( state ) {
            state.data        = {};
            state.open_modal  = false;
        },
    }
}


