//////////////////////////////

module.exports = (type) => {

    var schema = {
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue: type.NOW,
        },
        nome: {
            type: type.STRING(64),
            allowNull: false,
        },
        zona: {
            type: type.STRING(64),
            allowNull: false,
        },
        regione: {
            type: type.STRING(64),
            allowNull: false,
        },
        provincia: {
            type: type.STRING(10),
            allowNull: false,
        },
        enabled: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: true,
        },
        codice: {
            type: type.STRING(10),
            allowNull: false,
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        custom: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: false,
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}


