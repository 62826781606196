<script>

import { mapActions, mapState, mapGetters } from 'vuex';

import elements from '@/vue-model-mirror/bnvform/components';

export default {
    name: 'user-list',
    props: [ 'ricerca_id', 'edit_mode', 'show_map' ],
    components : {},
    computed : {
        ...mapState('ricerca', {
            lists : state => state.items,
        }),
//      ...mapGetters( 'store', [ 'attribute' ] ),
    },
    watch: {
    },
    methods : {
//      ...mapActions( 'store', { local_action: 'action' } ),
        set_ricerca_id( data ) {
            this.local_ricerca_id = data.ricerca_id;
            this.$emit( 'change', { ricerca_id: data.ricerca_id } );
        },
        set_edit_mode() {
            this.local_edit_mode = !this.local_edit_mode;
            this.$emit( 'change', { edit_mode: this.local_edit_mode } );
        },
        destroy_ricerca() {
            this.$emit( 'change', { destroy_ricerca: this.local_ricerca_id } );
        },
        new_ricerca() {
            this.$emit( 'change', { new_ricerca: null } );
        },
        export_csv() {
            this.$emit( 'export' );
        },
    },
    created() {
        this.local_ricerca_id = this.ricerca_id;
        this.local_edit_mode  = this.edit_mode;
    },
    data() {
        return {
            bnvselect         : elements.bnvselect,
            select_key        : 0,
            local_ricerca_id  : '',
            local_edit_mode   : false,
            select_disabled   : false,
            edit_b_disabled   : false,
            add_b_disabled    : false,
            export_b_disabled : false,
            delete_b_disabled : false,
        }
    },
}

</script>

<template>
    <div style="border: 1px solid lightgrey; margin-top: 20px; border-radius: 15px; background-color: #F8F8F8; padding: 10px 0;">
        <!-- <b-row align-v="end"> -->
            <b-col style="">
                <component
                    v-bind:key    = "select_key"
                    name          = "ricerca_id"
                    ref           = "ricerca_id"
                    :is           = "bnvselect"
                    elementtype   = "select"
                    :value        = "local_ricerca_id"
                    label         = "Itinerari"
                    store         = "ricerca"
                    :allownull    = "true"
                    :edit         = "true"
                    :disabled     = "select_disabled || local_edit_mode"
                    size          = "sm"
                    @change       = "set_ricerca_id"
                    >
                </component>
            </b-col>
            <b-col style="padding-top: 7px">
                <b-row align-h="between" style="padding: 0 15px;">
                    <b-button size="sm" style="margin-bottom: 7px; margin-right: 10px" variant="outline-info" :disabled="add_b_disabled || local_edit_mode || show_map" @click="new_ricerca">
                        <icon name="plus" style="fill: #0E82FF"/>
                    </b-button>
                    <b-button :style="{ opacity: local_ricerca_id ? 1 : 0.3 }" size="sm" style="margin-bottom: 7px; margin-right: 10px" :variant="local_edit_mode ? 'primary' : 'outline-secondary'" @click="set_edit_mode" :disabled="edit_b_disabled || local_ricerca_id ? false : true || show_map">
                         <icon name="pen" :style="{ fill: local_edit_mode ? '#2D92FF' : '#6C757D' }"/>
                    </b-button>
                    <b-button :style="{ opacity: local_ricerca_id ? 1 : 0.3 }" size="sm" style="margin-bottom: 7px; margin-right: 10px" variant="outline-danger" :disabled="delete_b_disabled || ( !local_ricerca_id || local_edit_mode || show_map )" @click="destroy_ricerca">
                        <icon name="trash" style="fill: #DC3545"/>
                    </b-button>
                    <b-button :style="{ opacity: local_ricerca_id ? 1 : 0.3 }" size="sm" style="margin-bottom: 7px;" variant="outline-primary" :disabled="export_b_disabled || ( !local_ricerca_id || local_edit_mode ) || show_map" @click="export_csv">
                        <icon name="download" style="fill: #238DFF"/>
                    </b-button>
                </b-row>
            </b-col>
        <!-- </b-row> -->
    </div>
</template>

<style>
</style>
