<script>

import { mapActions, mapState/*, mapGetters*/ } from 'vuex';

export default {
    name: 'appuntamento-note',
    props: [ 'item' ],
    components : {},
    computed : {
        incarico_note() {
            return this.item.pregs.incarico.note || ''
        },
        ...mapState('pratica_incarico', {
            saved        : state => state.executed,
            new_incarico : state => state.obj
        }),
    },
    watch: {
        saved( new_value ) {
            if ( this.updated === false || new_value != true ) { return; }
            this.updated = false;
            this.reset_executed();
            this.override_incarico( this.new_incarico );
            this.is_valid = true;
            setTimeout( () => { this.is_valid = false }, 3000 );
        },
    },
    methods : {
        ...mapActions( 'pratica_incarico', { update_note: 'update', reset_executed: 'executed' } ),
        ...mapActions( 'appuntamento', { override_incarico: 'override_incarico' } ),
        save() {
            if ( this.note === this.incarico_note ) { return; }
            this.updated = true;
            this.update_note( { id: this.item.pregs.incarico.id, payload: { note: this.note } } );
        },
    },
    created() { this.note = this.incarico_note + ''; },
    data() {
        return {
            note    : '', 
            updated : false,
            is_invalid : null,
            is_valid   : null,
        }
    },
}

</script>

<template>
    <div>
        <b-form-textarea
            id           = "textarea"
            v-on:blur    = "save"
            v-model      = "note"
            placeholder  = "Note..."
            rows         = "6"
            max-rows     = "6"
            v-bind:class = "{ 'is-invalid': is_invalid, 'is-valid': is_valid }"
        ></b-form-textarea>
    </div>
</template>

<style>
</style>
