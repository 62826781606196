////////////////////

import axios from 'axios';
import base_module from '@/vue-model-mirror/stores/base.module';
import { authHeader } from '@/helpers';
const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

var options = {
    model_name    : 'comune_regione',
    send_alert    : false,
    default_order : 'label ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    filters       : {},
    title_form    : 'Tipologie di Pratica',
    items         : null,
    page          : 1,
    reload_select : false,
};

const actions = {
    ...base.actions,
};

const mutations = {
    ...base.mutations,
};

const getters = {
    ...base.getters,
    get_label: state => regione_id => {
        if ( !regione_id || !state.items ) { return null; }
        for ( var i = 0; i < state.items.length; i++ ) {
           if ( state.items[ i ].id === regione_id ) { return state.items[ i ].label; }
        }
        console.log( 'ERROR: comune_select.getters.get_label - label non trovata per id ' + String( regione_id ) );
        return null;
    },
};


export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
    getters,
};

