<script>

import bnvform      from '@/vue-model-mirror/bnvform';
import draggable    from 'vuedraggable';

import card_left    from './card_left';
import column_right from './column_right';
const schemas = require('@/schemaconfig');
const config  = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export default {
    name: 'pratica-photoeditor',
    props: [ 'codice_pratica', ],
    components: {
        draggable,
        bnvform,
        'card-left'    : card_left,
        'column-right' : column_right,
    },
    watch: {
        folder_found(new_val, old_val) {
            if ( new_val === true && old_val === null ) {
                var data = { options: {
                    parentId : this.store.photo_folder_id,
                    include  : true,
                }};
                this.$store.dispatch('photoeditor/get_all', data);
            } else if ( new_val === false && old_val === null ) {
                // cartella Foto non trovata
            }
        },
        gallery_loader(new_val, old_val) { // riportare sul parent
            if ( new_val === false && old_val === true ) {
                this.right_column = [];
                setTimeout( () => {
                    this.$bvToast.hide('gallery-toast');
                }, 5000);
            }
        },
    },
    created() {
        this.ed_schema = schemas.edit_description;
        this.$store.dispatch('photoeditor/set_codice_pratica', this.codice_pratica);
        this.$store.dispatch('photoeditor/get_photo_folder_id');
    },
    computed: {
        store() { return this.$store.state.photoeditor; },
        items: {
            get() {
                if ( this.$store.state.photoeditor.items === null ) { return []; }
                return this.$store.state.photoeditor.items;
            },
            //set( items ) { this.$store.dispatch('photoeditor/update_items', items); }
        },
        folder_found() { return this.$store.state.photoeditor.folder_found; },
        backend_url() { return config.backend_url },
        cloned_ids() { return this.right_column.map( x => x.id ); },
        images() { return Array.isArray( this.store.items ) ? this.store.items.filter( x => ['image/jpeg', 'image/png', 'image/bnp'].includes( x.task_image.value.mimetype ) ) : [] },
        //images() { return this.store.items },
        gallery_message () {
            return this.$store.state.photoeditor.message;
        },
        gallery_variant () {
            return this.$store.state.photoeditor.variant;
        },
        gallery_loader () {
            return this.$store.state.photoeditor.loader;
        },
    },
    methods: {
        edit_description( file ) {
            this.ed_record.description = file.value.hasOwnProperty('description') ? file.value.description || null : null;
            this.ed_record.image_id    = file.id;
            this.toggle_modal();
        },
        reset_ed() {},
        submit_ed( ) {
            this.$refs.edit_description.submit_form();
            this.toggle_modal();
        },
        save_ed( data ) {
            var payload = { description: data.description || null, };
            var options = {
                image_id : data.image_id,
                params   : { include: true, },
                payload,
            };
            this.$store.dispatch('photoeditor/edit_description', options);
        },
        toggle_modal(modal_id = 'edit-description') {
            modal_id = modal_id.constructor.name === 'MouseEvent' ? 'edit-description' : modal_id;
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        clone_image( image ) {
            if ( this.cloned_ids.includes( image.id ) ) { return; }
            return image;
        },
        remove_image( image_id ) {
            for ( var i = 0; i < this.right_column.length; i++ ) {
                if ( this.right_column[ i ].id === image_id ) { break; }
            }
            this.right_column.splice( i, 1 );
        },
        print_report() {
            var options = {
                payload        : this.right_column, 
                codice_pratica : this.codice_pratica,
                params         : {},
            };
            this.$store.dispatch('photoeditor/reset');
            this.$bvToast.show('gallery-toast');
            setTimeout( () => {
                this.$store.dispatch( 'photoeditor/print_report', options );
            }, 1000);
        },
        fill_right_column() {
            var images = this.images;
            for ( var i = 0; i < images.length; i++ ) {
                if ( this.cloned_ids.includes( images[ i ].id ) ) { continue; }
                var right_column = JSON.parse( JSON.stringify( this.right_column ) );
                right_column.push( JSON.parse( JSON.stringify( images[ i ] ) ) );
                this.right_column = right_column;
            }
        },
        empty_right_column() {
            this.right_column = [];
        },
    },
    data() {
        return {
            ed_record    : {},
            ed_key       : 0,
            ed_schema    : null,
            right_column : [],
        }
    },
};

</script>

<template>

    <b-container fluid class="p-4">

        <!-- draggable areas -->
        <!-- <div v-if="store.items && store.items.length > 0"> -->
        <div v-if="images.length > 0">
            <b-row style="margin-bottom: 15px;">
                <b-col cols="6" class="text-left">
                    <b-button :disabled="images.length === right_column.length" variant="outline-success" size="sm" @click="fill_right_column">Aggiungi tutte</b-button>
                    <b-button :disabled="right_column.length === 0" variant="outline-danger" size="sm" @click="empty_right_column" style="margin-left: 20px;">Rimuovi tutte</b-button>
                </b-col>
                <b-col cols="6" class="text-right">
                    <b-button :disabled="gallery_loader === true || right_column.length === 0" variant="outline-info" size="sm" @click="print_report"><b-icon-tools></b-icon-tools> Stampa report</b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="8">
                    <h4 style="color: grey" class="text-center">Immagini della cartella Foto</h4>
                    <draggable
                        :list  = "images"
                        :group = "{ name: 'people', pull: 'clone', put: false }"
                        :clone = "clone_image"
                        :sort  = "false"
                        >
                        <template v-for="element in images">
                            <b-col xs="6" :key="element.id" >
                                <card-left
                                    :element          = "element"
                                    @edit-description = "edit_description"
                                    >
                                </card-left>
                            </b-col>
                        </template>
                    </draggable>
                </b-col>
                <b-col cols="4" class="bg-dark" style="padding: 7px; padding-top: 7px;">
                    <h4 style="color: white" class="text-center">Report</h4>
                    <column-right
                        :items         = "right_column"
                        @remove-image  = "remove_image"
                        >
                    </column-right>
                </b-col>
            </b-row>
        </div>

        <!-- loader -->
        <div v-else-if="store.loading" class="text-center">
            <div class="spinner-grow text-dark" role="status" style="width: 6rem; height: 6rem;">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <!-- error message -->
        <div v-else-if="store.error === true" class="text-center">
            <b-alert variant="danger">{{ store.obj.message }}</b-alert>
        </div>

        <!-- modal edit_description -->
        <b-modal
            id          = "edit-description"
            title       = "Modifica Immagine"
            @hide       = "reset_ed"
            button-size = "sm"
            >
            <bnvform
                :key       = "ed_key"
                name       = "folder"
                :schema    = "ed_schema"
                :options   = "{}"
                :record    = "ed_record"
                :submit    = "false"
                :inline    = "true"
                ref        = "edit_description"
                layout     = "single"
                @submitted = "save_ed"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row>
                    <b-col>
                        <b-button size="sm" variant="success" @click="submit_ed">
                            Salva
                        </b-button>
                    </b-col><b-col>
                        <b-button size="sm" variant="outline-secondary" @click="cancel()">
                            Chiudi
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>

        <!-- gallery toast -->
        <b-toast
            no-auto-hide
            id       = "gallery-toast"
            :title   = "gallery_message.class"
            :variant = "gallery_variant"
            :solid   = "true"
            toaster  = "b-toaster-bottom-right"
            >
			<span v-if="gallery_message.hasOwnProperty('url') === false">{{ gallery_message.action }}</span><a v-if="gallery_message.hasOwnProperty('url')" :href="gallery_message.url" target="_blank">{{ gallery_message.file_name }}</a>
<b-spinner v-if="gallery_loader" variant="primary" label="Spinning" style="float: right;" small></b-spinner>
        </b-toast>

    </b-container>

</template>

