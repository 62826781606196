<script>

const nprogress = require('nprogress');

import elements from '@/vue-model-mirror/bnvform/components';

function sleep( ms ) {
    return new Promise( accept => {
        setTimeout( () => { accept(); }, ms );
    });
}

export default {
    name: 'assegnazioni',
    props: [ 'label', 'codice_pratica', 'modal' ],
    mounted () {
        this.$nextTick(function () { nprogress.done(true); }) // stop loader
    },
    watch: {
        qry_select(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.obj = this.store.obj;
                this.$store.dispatch('pratica/executed');
                this.set_task_values();
                //console.log('qry select eseguita');
                //this.loading = false;
                this.pratica_loaded = true;
            }
        },
        qry_save(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                //console.log( 'task ( utente ) salvato con successo' );
                //this.make_toast( 'ASSEGNAZIONE DI UN UTENTE', 'utente salvato con successo' );
                this.$emit('saved', this.store.obj );
                this.$store.dispatch('task/executedupd');
                this.$store.dispatch('pratica/override_task', this.store_task.obj); // qui sovrascrivo il task nello store pratica
                this.$emit('editedtask', this.store_task.obj );
                //this.form_key += 1;
            }
        },
        users_retrieved( new_val, old_val ) {
            if ( new_val && !old_val ) {
                //console.log('users retrieved');
                //this.loading = false;
                this.users_loaded = true;
            }
        },
    },
    computed: {
        _loading() {
            return ( this.users_loaded && this.pratica_loaded ) ? false : true;
        },
        qry_select () {
            return this.$store.state.pratica.selected;
        },
        qry_save () {
            return this.$store.state.task.executedupd;
        },
        store () {
            return this.$store.state.pratica;
        },
        store_task () {
            return this.$store.state.task;
        },
        states() {
            return this.$store.getters['statemanager/states_tasks'];
        },
        customers () { return this.$store.getters['statemanager/customers'].map( x => x.id ); },
        users_retrieved() { return this.$store.state.user.executed; },
    },
    methods: {
        get_label( task_name ) {
            var label = task_name;
            for ( var i = 0; i < this.obj.state.metas.length; i++ ) {
                if ( this.obj.state.metas[ i ].name === task_name ) {
                    if ( this.obj.state.metas[ i ].hasOwnProperty('description') ) { label = this.obj.state.metas[ i ].description; break; }
                }
            }
            //return label;
            return label[0].toUpperCase() + label.substring(1).replace(/_/g, ' ')
        },
        save_user( task_label ) {
            var task = this.task_values[ task_label ];
            var new_value = this.$refs[ 'asselement-' + task_label ][0].get_value();
            if ( new_value === task.user_id ) { return false; }
            var data = { id: task.id, payload: { user_id: new_value }, include: true };
            this.$store.dispatch('task/update', data);
            this.task_values[ task_label ].user_id = new_value;
            return true;
        },
        make_toast(title, body) {
            this.$bvToast.toast( body, {
                title         : title,
                autoHideDelay : 5000,
                appendToast   : true,
            });
        },
        set_task_values() {
            for ( var i = 0; i < this.obj.tasks.length; i++ ) {
                if ( this.customers.includes( this.obj.tasks[ i ].customer_id ) ) {
                    this.task_values[ this.obj.tasks[ i ].task_label ] = this.obj.tasks[ i ];
                }
            }
        },
        async save_assignments() {
            this.disabled = true;
            var elements = Object.keys( this.$refs )
                                 .filter( x => x.startsWith('asselement-') );
            let updated = 0;
            for ( var i = 0; i < elements.length; i++ ) {
                var match = elements[ i ].match(/^asselement\-(.+)$/);
                var task_label = match[1];
                var check = this.save_user( task_label );
                if ( check ) { await sleep( 500 ); updated += 1; }
            }
            this.form_key += 1;
            if ( updated > 0 ) {
                this.make_toast( 'ASSEGNAZIONE TASK', `${ updated > 1 ? updated : 'un' } task assegnat${ updated > 1 ? 'i' : 'o' } con successo` );
            }
            this.disabled = false;
        },
    },
    created () {
        this.$store.dispatch('user/get_all', { options: { enabled: true } } );
        if ( this.modal === true ) {
            //this.$store.dispatch('pratica/clear');
            this.$store.dispatch('pratica/executed');
            this.$store.dispatch('pratica/get_by_id', { id: this.codice_pratica, include: true });
            return;
        }
        if( !this.store.obj || typeof this.store.obj !== 'object' || Object.keys( this.store.obj ).length === 0 ) {
            //this.$store.dispatch('pratica/clear');
            this.$store.dispatch('pratica/get_by_id', { id: this.codice_pratica, include: true });
            return;
        } else {
            this.pratica_loaded = true;
        }
        this.obj = this.store.obj;
        this.set_task_values();
        //this.loading = false;
    },
    data () {
        return {
            loading   : true,
            obj       : null,
            bnvselect : elements.bnvselect,
            form_key  : 0,
            colors    : {
                nuovo       : '#97cef7',
                lavorazione : '#f29d61',
                evasione    : '#f2e861',
                chiuso      : '#61f270',
            },
            task_list : {
                'nuovo'       : {
                    color  : '#97cef7',
                    label  : 'Nuove',
                    fields : [ 'primo_contatto', 'disamina', 'appuntamento', ],
                },
                'lavorazione' : {
                    color  : '#f29d61',
                    label  : 'In lavorazione',
                    fields :  [ 'ispezione', 'supervisore', 'documenti', ],
                },
                'evasione'    : {
                    color  : '#f2e861',
                    label  : 'In evasione',
                    fields :  [ 'scrittura', 'sottoscrizione', 'correzione', 'evasione', ],
                },
                'chiuso'      : {
                    color  : '#61f270',
                    label  : 'Chiuse',
                    fields :  [ 'incasso', 'pagamenti', ],
                },
                
            },
            task_values : {},
            disabled    : false,

            users_loaded   : false,
            pratica_loaded : false,
        }
    },
};

</script>

<template>

    <div>
        <div v-if="!_loading">
            <b-container fluid="xl" :key="form_key">
                <b-row>
                   <!--  <b-col cols="12" md="12" lg="6" v-for="( taskobj, state_name ) in task_list" v-bind:key="state_name" style="margin-bottom: 10px;"> -->
                    <b-col cols="12" md="12" lg="6" v-for="( taskobj, state_name ) in states" v-bind:key="state_name" style="margin-bottom: 10px;">
                        <!-- <div v-bind:style="{ backgroundColor: taskobj.color }" style="padding: 10px;"> -->
                        <div v-bind:style="{ backgroundColor: colors[ state_name ] }" style="padding: 10px;">
                        <b-row align-v="center">
                            <b-col cols="12" class="text-center"><span style="font-weight: 800;">{{ taskobj.label }}</span></b-col>
                        </b-row>
                        <template v-for="task_name in taskobj.fields">
                            <b-row align-v="center" v-if="task_values[ task_name ]">
                                <b-col cols="12">
                                    <component
                                        v-bind:key    = "'ass-element-' + task_name"
                                        :name         = "task_name"
                                        :ref          = "'asselement-' + task_name"
                                        :is           = "bnvselect"
                                        elementtype   = "select"
                                        :value        = "task_values[ task_name ].user_id"
                                        :label        = "get_label(task_name)"
                                        store         = "user"
                                        :allownull    = "true"
                                        :edit         = "true"
                                        :disabled     = "disabled"
                                        size          = "sm"
                                    ></component>
                                    <div v-if="task_values[ task_name ].confirm" class="invalid-feedback">
                                        {{ task_values[ task_name ].confirm }}
                                    </div>
                                </b-col>
                                <!-- <b-col cols="3">
                                    <b-button variant="success" @click="save_user(task_name)" size="sm">Salva</b-button>
                                </b-col> -->
                            </b-row>
                        </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" class="text-center">
                        <b-button variant="success" @click="save_assignments()" >Salva</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div v-if="_loading">
            <div class="text-center">
                <div class="spinner-grow text-dark" role="status" style="width: 6rem; height: 6rem;">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>

</template>

