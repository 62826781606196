<script>

import fab from 'vue-fab'

export default {
    name: 'fab-button',
    props: [ 'main_icon', 'bg_color', ],
    components: { fab },
    computed: {
        _main_icon() {
            return this.main_icon ? this.main_icon : this.default_main_icon;
        },
        _bg_color() {
            return this.bg_color ? this.bg_color : this.default_bg_color;
        },
    },
    watch: {},
    methods: {
        save() {
            this.$emit( 'save', null )
        },
    },
    created() {},
    data() {
        return {
            default_main_icon : 'save',
            default_bg_color  : '#218838',
        }
    },
}

</script>

<template>

    <!-- https://github.com/PygmySlowLoris/vue-fab -->
    <div @click="save">
        <fab :main-icon="_main_icon" :bg-color="_bg_color" />
    </div>

</template>

<style>
</style>

