

module.exports = {
    cols: [
          { 
              row1: {
                  field1: 'nome',
              },
              row2: {
                  field2: 'cognome',
              },
              row3: {
                  field1: 'denominazione',
              },
              row4: {
                  field1: 'indirizzo',
              },
              row5: {
                  field1: 'codice_fiscale',
              },
              row6: {
                  field2: 'partita_iva'
              },
              row7: {
                  field2: 'telefono'
              },
              row8: {
                  field2: 'email'
              },
              row9: {
                  field2: 'iban'
              },
              field1: 'note',
          },
    ]
};

