<script>

export default {

    name: 'parcel-amount',
    props: [ 'item' ],
    data() {
        return {
            total_value : parseFloat( 0 ).toFixed(2),
            key         : 0,
        }
    },
    created() {
    },
    computed: {
        value() {
            if ( this.item.custodian_id !== null ) {
                console.log( this.item );
                return this.get_value();
            } else {
                if ( this.get_task_state( this.item.task_label ) === null || typeof this.get_task_state( this.item.task_label ) === 'undefined' ) { return parseFloat( 0 ).toFixed(2); }
                if ( !this.item.percentage || !this.item.minimum ) { return parseFloat( 0 ).toFixed(2); }
                return this.get_value();
            }
        },
        obj() { return this.$store.state.pratica.obj; },
        customers() { return this.$store.getters['statemanager/customers']; },
        is_custodian() { return ( this.customers.map( x => x.id ).includes( this.obj.owner ) ) ? false : true; },
        totale_onorari() {
            if ( this.is_custodian ) {
                var parcel = null;
                this.obj.my_parcels.map( x => { if ( x.custodian_id === this.obj.custodian ) { parcel = x; } } );
                var imponibile_custodian = parseFloat( this.obj.imponibile ) * parseFloat( parcel.percentage ) / 100 < parseFloat( parcel.minimum ) ?
                                           parseFloat( parcel.minimum ) :
                                           parseFloat( this.obj.imponibile ) * parseFloat( parcel.percentage ) / 100;
                return imponibile_custodian + parseFloat( this.obj.supplemento_pl_custodian );
            } else {
                return parseFloat( this.obj.imponibile ) + parseFloat( this.obj.supplemento_pl );
            }
        },
        is_unpaid() {
            if ( this.item.custodian_id ) { return false; }
            return this.$store.getters[ 'statemanager/unpaid_tasks' ]( this.item.customer_id ).includes( this.item.task_label );
        },
    },
    methods: {
        get_value() {
            if ( this.is_unpaid ) { return parseFloat( 0 ).toFixed(2); }
            if ( this.item.percentage > 0 && this.item.minimum > 0 ) {
                var perc = parseFloat( this.totale_onorari ) * parseFloat( this.item.percentage ) / 100;
                var res = perc < parseFloat( this.item.minimum ) ? parseFloat( this.item.minimum ) : perc;
                //if ( this.item.parcel_mode === 'user' ) { res *= this.item.quota / 100; }
                return parseFloat( res ).toFixed(2);
            } else if ( this.item.percentage > 0 ) {
                var res = this.totale_onorari * this.item.percentage / 100;
                //if ( this.item.parcel_mode === 'user' ) { res *= this.item.quota / 100; }
                return res.toFixed(2);
            } else if ( this.item.minimum > 0 ) {
                var res = parseFloat( this.item.minimum );
                //if ( this.item.parcel_mode === 'user' ) { res *= this.item.quota / 100; }
                return res.toFixed(2);
            }
	        return parseFloat( 0 ).toFixed(2);
        },
        get_task_state( task_label ) {
            if ( this.item.parcel_mode === 'user' ) {
                if ( this.item.custodian_id === null ) {
                    var completed = true;
                    var user_tasks = this.obj.tasks.filter( x => x.user_id === this.item.user_id );
                    for ( var a = 0; a < user_tasks.length; a++ ) {
                        if ( user_tasks[ a ].label === null || typeof user_tasks[ a ].label === 'undefined' ) { completed = false; }
                    }
                    return completed ? 'done' : undefined;
                } else {
                    var completed = true;
                    var custodian_tasks = this.obj.tasks.filter( x => x.customer_id === this.item.custodian_id && x.newstate < 4000 );
                    for ( var i = 0; i < custodian_tasks.length; i++ ) {
                        if ( custodian_tasks[ i ].label === null || typeof custodian_tasks[ i ].label === 'undefined' ) { completed = false; }
                    }
                    return completed ? 'done' : undefined;
                }
            } else {
                if ( this.item.custodian_id === null ) {
                    for ( var i = 0; i < this.obj.tasks.length; i++ ) {
                        if ( this.obj.tasks[ i ].task_label === task_label && this.obj.tasks[ i ].customer_id === this.item.customer_id ) {
                            return this.obj.tasks[ i ].label;
                        }
                    }
                } else {
                    var completed = true;
                    var custodian_tasks = this.obj.tasks.filter( x => x.customer_id === this.item.custodian_id && x.newstate < 4000 );
                    for ( var i = 0; i < custodian_tasks.length; i++ ) {
                        if ( custodian_tasks[ i ].label === null || typeof custodian_tasks[ i ].label === 'undefined' ) { completed = false; }
                    }
                    return completed ? 'done' : undefined;
                }
            }
        },
        get_ref() {
            return this.item.task_label ? 'amount-' + this.item.user_id + '-' + this.item.task_label : `amount-${ this.item.user_id }`;
        },
        set_value( value ) { this.total_value = parseFloat(value).toFixed(2); this.key += 1; },
    },

}

</script>

<template>

    <div style="width: 100px;">
        <b-input-group size="sm" prepend="€" :key="key">
            <b-form-input :disabled="true" type="number" step="0.01" :value="item.user_id != 0 ? value : total_value" :ref="get_ref()" ></b-form-input>
        </b-input-group>
    </div>

</template>

