<script>

import { mapState } from 'vuex';
const schemas = require('@/schemaconfig');
import baseform from './base_form';

export default {
    name: 'registry-agenzia',
    extends: baseform,
    props: [ '' ],
    components: {},
    computed: {
        ...mapState('registry', {
            cga_updated : state => state.cga_updated,
        }),
        ...mapState('preg', {
            cga_updated_preg : state => state.cga_updated,
        }),
        local_registry_schema() {
            return this.in_modal === true ? schemas._polizza_preg : schemas._polizza;
        },
    },
    watch: {
        cga_updated( new_value ) {
            if ( !new_value ) { return; }
            this.make_toast( 'CGA aggiornate' );
            this.main_key += 1;
        },
        cga_updated_preg( new_value ) {
            console.log( 'cga_updated_preg', new_value );
            if ( !new_value ) { return; }
            this.make_toast( 'CGA aggiornate' );
            this.main_key += 1;
        }
    },
    methods: {
        save( options = {} ) { // override parent
            var payload = this.base_payload;
            for ( var i = 0; i < this.blocks.length; i++ ) {
                this.$refs[ this.blocks[ i ] ].submit_form();
            }
            for ( var i = 0; i < this.blocks.length; i++ ) {
                var form_data = this.$refs[ this.blocks[ i ] ].form_data;
                payload = { ...payload, ...form_data };
                if ( this.$refs[ this.blocks[ i ] ].is_valid === false ) { return false; }
            }
            if ( !this.in_modal && this.action === 'add' ) {
                payload.related_objs = this.$refs.relations.rels.map( x =>  x.obj );
            }
            if ( this.in_modal === true ) {
                this.add_additional_attributes( payload ); 
                if ( options.save_on_registry === true ) {
                    payload.save_on_registry = true;
                    if ( options.registry_father_id ) {
                        payload.registry_father_id = options.registry_father_id;
                        payload.registry_father_role_id = options.registry_father_role_id;
                    }
                }
                if ( this.action === 'add' ) {
                    this.$store.dispatch( `${ this.schema_name }/create_modal_with_attachments`, { schema: this.schema_name, payload } );
                } else {
                    this.$store.dispatch( `${ this.schema_name }/update_modal`, { schema: this.schema_name, payload } );
                }
            } else if ( this.action === 'add' ) {
                this.$store.dispatch( `${ this.schema_name }/create_with_attachments`, { schema: this.schema_name, payload } );
            } else {
                this.$store.dispatch( `${ this.schema_name }/update_with_attachments`, { schema: this.schema_name, payload, id: this.record.id } );
            }
            return true;
        },
    },
    created() {},
    data() {
        return {
            template_name   : 'Polizza',
            registry_schema : schemas._polizza,
            blocks          : [ 'form_registry' ],
            relation_config : {
                polizza : { template: [ '_persona_fisica', '_persona_giuridica', '_ente_pubblico' ], direction: 'child', child_role: 'polizza', father_role: 'polizza' },
            },
        }
    },
}

</script>

<template>

    <div :key="main_key">
        <h3 style="text-align: center; padding-bottom: 14px;">POLIZZA</h3>

        <b-row style="margin: 2px; max-width: 800px; margin: auto" v-if="started === true">
            <b-col style="border: 1px solid lightgrey; padding: 5px;" lg="12" md="12">
                <p style="font-weight: 800; margin-left: 10px; margin-bottom: 0">Anagrafica</p>
                <bnvform
                    v-if       = "started === true"
                    ref        = "form_registry"
                    name       = "_polizza"
                    :schema    = "local_registry_schema"
                    :options   = "{}"
                    :record    = "record"
                    :inline    = "true"
                    :submit    = "false"
                    :key       = "registry_key"
                />
            </b-col>
        </b-row>

        <div v-if="in_modal === false">

            <relation-manager
                v-if          = "started === true"
                ref           = "relations"
                :action       = "action"
                :customer_id  = "customer_id"
                :related_objs = "related_objs"
                :record       = "record"
                @selected     = "set_parent"
                >
                <template #relation_buttons="parent">
                    <b-button size="sm" variant="secondary" style="margin-right: 10px;" @click="parent.new_relation( relation_config.polizza )"><icon name="plus"/> Polizza di</b-button>
                </template>
            </relation-manager>
    
            <b-row class="text-center" style="margin: 2px; margin-top: 15px; max-width: 800px; margin: auto">
                <b-col class="text-left">
                    <b-button v-if="action === 'edit'" size="sm" variant="danger" @click="destroy">Elimina</b-button>
                </b-col>
                <b-col class="text-right">
                    <b-button size="sm" variant="outline-secondary" style="margin-right: 10px" @click="back_to_list">Chiudi</b-button>
                    <b-button size="sm" variant="success" @click="save">Salva</b-button>
                </b-col>
            </b-row>

        </div>

    </div>

</template>

<style>
</style>

