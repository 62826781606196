<script>

export default {

    name: 'reminder-list',
    props: [ 'item', 'field_name', ],
    data() {
        return {};
    },
    computed: {
        value() { return this.item[ this.field_name ]; },
    },
    methods: {
        is_ok( row ) {
            return this.item.config[ row ]
        },
    },
    created () {},

}

</script>

<template>

    <div v-if="value.length === 0" class="text-center"><span style="color: lightgrey">nessuna voce</span></div>

    <div v-else-if="value.length > 0">

        <b-list-group>
            <b-list-group-item
                style="padding: .50rem 1rem; font-size: 0.9rem;"
                v-for="row in value"
                :key="field_name + row.label"
                class="flex-column align-items-start"
                >
                <div class="d-flex w-100 justify-content-between">
                    <h6 class="mb-1">{{ row.label }}</h6>
                    <icon v-if="row.hasOwnProperty('delivered')" :style="{ 'fill': row.delivered ? 'green' : 'lightgrey'}" style="margin-left: 2px;" name="check-circle"/>
                </div>
                <small v-if="row.datetime"><br/><b>Consegnato:</b> {{ row.datetime }}</small>
                <small v-if="row.file"><br/><b>Files:</b> {{ row.files }}</small>
                <small v-if="row.files"><br/><b>Files:</b> {{ row.files.join(', ') }}</small>
                <small v-if="row.response"><br/><b>Risposta:</b></small><pre v-if="row.response">{{ row.response }}</pre>
            </b-list-group-item>
        </b-list-group>

    </div>

</template>


