<script>

export default {

    name: 'parcel-config-use',
    props: [ 'item' ],
    data() {
        return {
            config     : {},
            locked     : true,
            percentage : 0,
            minimum    : 0,
        }
    },
    created() {
        //this.locked = this.$store.getters['statemanager/role_id'] !== 1 ? true : this.item.locked;
        this.locked = ![ 1, 6 ].includes( this.$store.getters['statemanager/role_id'] ) ? true : this.item.locked;
        this.percentage = JSON.parse( JSON.stringify( this.item.percentage ) );
        this.minimum   = JSON.parse( JSON.stringify( this.item.minimum ) );
    },
    computed: {
        lock_icon() {
            return this.locked ? 'lock' : 'unlock';
        },
        lock_disabled() {
            //return this.$store.getters['statemanager/role_id'] !== 1 ? true : false;
            return ![ 1, 6 ].includes( this.$store.getters['statemanager/role_id'] ) ? true : false;
        },
    },
    methods: {
        percentage_changed( value ) { this.percentage = value; },
        minimum_changed( value ) { this.minimum = value; },
        set_lock() {
            this.locked = !this.locked;
            this.$emit( 'genericevent', { event_name: 'table-modified', action: 'lock-modified', ref: null, value: this.locked  } );
        },
        focus_lost_percentage() {
            this.$emit( 'genericevent', { event_name: 'table-modified', action: 'percentage-modified', id: this.item.id, value: this.percentage } );
        },
        focus_lost_minimum() {
            this.$emit( 'genericevent', { event_name: 'table-modified', action: 'minimum-modified', id: this.item.id, value: this.minimum } );
        },
    },

}

</script>

<template>

    <b-input-group size="sm" v-if="item.user_id !== 0">
        <b-form-input style="width: 60px" @blur="focus_lost_percentage" @change="percentage_changed" type="number" :disabled="locked" :ref="'config-percentage-' + item.user_id" step="0.01" aria-label="Percentage" :value="percentage"></b-form-input>
        <b-form-input style="width: 60px" @blur="focus_lost_minimum" @change="minimum_changed" type="number" :disabled="locked" :ref="'config-minimum-' + item.user_id" step="0.01" aria-label="Minimum" :value="minimum"></b-form-input>
        <template v-slot:append>
            <b-input-group-append><b-button :disabled="lock_disabled" size="sm" @click="set_lock()" variant="light-outline"><b-icon :icon="lock_icon"></b-icon></b-button></b-input-group-append>
        </template>
    </b-input-group>

</template>

