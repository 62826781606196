/////////////////////////////////////////////////

class address {

    constructor( label = null ) {
        this._label = label;
        this._set_params();
    }

    // public methods ///////////////////////////

    set_values( obj ) {
        this.fields.map( field => {
            this[ field ] = obj[ this._get_label( field ) ];
        });
    }

    set_google_payload( obj ) {
        console.log( this.$store );
        this.strada      = obj.route || null;
        this.comune_id   = obj.locality || null;
        this.n_civ       = obj.street_number || null;
        this.cap         = obj.postal_code || null;
        this.nazione     = obj.country || null;
        this.latitudine  = obj.latitude || null;
        this.longitudine = obj.longitude || null;
        if ( obj.country === 'Italia' ) {
            if ( this._label ) {
                this.$store.dispatch( 'comune_residenza/get_by_name', { name: this.comune_id, label: this._label, regione: obj.regione, provincia: obj.provincia } );
            } else {
                //console.log( 'comune_id', this.comune_id );
                this.$store.dispatch( 'comune_residenza/get_by_name', { name: this.comune_id, regione: obj.regione, provincia: obj.provincia } );
            }
            if ( this.comune_id ) {
                this.citta_estero = null;
            }
        } else {
            this.citta_estero = obj.locality;
            this.comune_id = null;
        }
    }

    get label() {
        var address = '';
        if ( this.strada )  { address += this.strada + ' '; }
        if ( this.n_civ )   { address += 'n. ' + this.n_civ + ', '; }
        if ( this.cap )     { address += this.cap + ' '; }
        if ( this.citta )   { address += this.citta + ' '; }
        if ( this.nazione !== 'Italia' ) {
            if ( this.nazione ) { address += this.nazione + ' '; }
        }
        if ( this.altro )   { address += `(${ this.altro })`; }
        return address.trim();
    }

    // private methods //////////////////////////

    _set_params() {
        this.fields = [ 'strada', 'comune_id', 'n_civ', 'cap', 'nazione', 'latitudine', 'longitudine', 'citta_estero', 'altro',  ];
        this.fields.map( field => {
            this[ field ] = null;
        });
    }

    _get_label( label ) {
        if ( !this._label ) { return label; }
        if ( label === 'comune_id' ) {
            return `comune_${ this._label }_id`;
        }
        return `${ label }_${ this._label }`;
    }

    // getters & setters ////////////////////////

    set store( obj ) {
        this.$store = obj;
    }

    get values() {
        let res = {};
        this.fields.map( field => {
            res[ this._get_label( field ) ] = this[ field ];
        });
        return res;
    }

}

export default address;

