////////////////////

import axios from 'axios';
import base_module from '@/vue-model-mirror/stores/base.module';
import { authHeader } from '@/helpers';
const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

var options = {
    model_name    : 'preg',
    send_alert    : false,
    default_order : 'id ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    filters : {},
    sent    : false,
};

const actions = {
    ...base.actions,
    add_item( { commit, getters }, payload ) {
        payload.action = 'add';
        let url = config.backend_url + `model/${ getters.model_name }/${ payload.record_id }/attachments`;
        var options = { headers: authHeader() };
        const form = new FormData();
        Object.keys( payload ).map( key => {
            form.append(key, payload[ key ]);
        });
        var options = {
            url,
            headers : authHeader(),
            method  : 'patch',
            data    : form,
        };
        options.headers['Content-Type'] = 'multipart/form-data';
        axios( options )
            .then( response => {
                commit( 'sent', { message: 'allegato aggiunto', attachments: response.data.attachments } );
            })
            .catch( error => {
                commit('error', error)
            });
    },
    edit_item( { commit, getters }, payload ) {
        payload.action = 'edit';
        let url = config.backend_url + `model/${ getters.model_name }/${ payload.record_id }/attachments`;
        var options = { headers: authHeader() };
        axios.patch( url, payload, options )
            .then( response => {
                commit( 'sent', { message: 'etichetta modificata', attachments: response.data.attachments } );
            })
            .catch( error => {
                commit('error', error)
            });
    },
    delete_item( { commit, getters }, payload ) {
        payload.action = 'delete';
        let url = config.backend_url + `model/${ getters.model_name }/${ payload.record_id }/attachments`;
        var options = { headers: authHeader() };
        axios.patch( url, payload, options )
            .then( response => {
                commit( 'sent', { message: 'allegato rimosso', attachments: response.data.attachments } );
            })
            .catch( error => {
                commit('error', error)
            });
    },
};

const mutations = {
    sent( state, message ) {
        state.sent = message;
        setTimeout( () => { state.sent = false; }, 200 )
    },
    clear() {
        state.obj           = {};
        state.items         = null;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.executed      = false;
        state.force_reload  = false;
        state.selected      = false;
        state.filters       = {};
        state.sent          = false;
    },
}


export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
};

