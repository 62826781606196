<script>

import bnvform from '@/vue-model-mirror/bnvform';
const schemas = require('@/schemaconfig');

export default {
    name: 'registry-contact',
    props: [ 'record', 'started' ],
    components : { bnvform },
    computed : {
        is_valid() { return this.$refs.form.is_valid; },
        form_data() { return this.$refs.form.form_data; },
    },
    watch: {
    },
    methods : {
        value_changed( obj ) {
        },
        operation_success( payload ) {
            //console.log( payload );
        },
        reset_form() {},
        submit_form() { // public
            this.$refs.form.submit_form();
            return this.$refs.form.is_valid;
        }
    },
    created() {},
    data() {
        return {
            schema_name  : '_contact',
            schema       : schemas['_contact'],
            key          : 0,
            options      : {},
        }
    },
}

</script>

<template>

    <bnvform
        v-if       = "started === true"
        ref        = "form"
        :name      = "schema_name"
        :schema    = "schema"
        :options   = "options"
        :record    = "record"
        :inline    = "true"
        :submit    = "false"
        :key       = "key"
        @reset     = "reset_form"
        @submitted = "operation_success"
        @saved     = "operation_success"
        @change    = "value_changed"
    ></bnvform>

</template>

<style>
</style>

