<script>

export default {

    name: 'settings-importer',
    props: [ 'item' ],
    data() {
        return {
        }
    },
    computed: {},
    methods: {
    },
    created () {
    },

}

</script>

<template>

    <div style="">
        <p v-if="Object.keys( item.credentials ).length > 0" style="line-height: 8px; margin-bottom: 2px;"><br/>
            <table style="padding: 2px;" class="importer">
                <tr>
                    <td style=""><small style="font-weight: 600">username:</small></td>
                    <td><small style="padding-left: 4px;">{{ item.credentials.username }}</small></td>
                </tr>
                <tr>
                    <td style=""><small style="font-weight: 600">password:</small></td>
                    <td><small style="padding-left: 4px;">{{ item.credentials.password }}</small></td>
                </tr>
                <!-- <tr v-for="key in Object.keys( item.credentials ).filter( x => ['username', 'password'].includes( x ) === false )">
                    <td style=""><small style="font-weight: 600">{{ key }}:</small></td>
                    <td><small style="padding-left: 4px;">{{ item.credentials[ key ] }}</small></td>
                </tr> -->
            </table>
        </p>
    </div>

</template>

<style scoped>
    table.importer td {
        padding: 5px;
    }
</style>
