<script>

import bnvtext from './bnvtext.vue';

export default {
    extends: bnvtext,
    props: [ 'placeholder', ],
    created() {
        this._validate();
    },
    watch: {
        _model( new_value ) {
            if ( this.timeout ) { clearTimeout( this.timeout ); }
            this.timeout = setTimeout( () => {
                this._validate();
            }, 500 );
        },
    },
    methods: {
        _reset_state() {
            this.error_message    = null; 
            this.merror_component = false;
            this.is_valid        = null;
        },
        _set_error( msg ) {
            this.error_message    = msg; 
            this.merror_component = true;
            this.is_valid        = null;
        },
        _set_is_valid() {
            this.error_message    = null; 
            this.merror_component = false;
            this.is_valid        = true;
        },
        _validate: function() {
            var check = this._validate_allow_null();
            if ( check === true && !this._model ) { this._reset_state(); return check; }
            if ( check === true ) {
                check = this._validate_iban();
            }
            //return check;
            return true;
        },
        _validate_allow_null: function() {
            if ( this.allownull === false ) {
                if ( this.model === '' || typeof this.model === 'undefined' || this.model === null ) {
                    this.error_message = 'campo obbligatorio';
                    this.error_component = true;
                    return false;
                }
            }
            return true;
        },
        _validate_iban() {
            this.model = this.model.replace( / /g, '' );
            var value = this.model;
            let rearrange = value.substring(4, value.length) + value.substring(0, 4);
            let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');
            let alphaMap = {};
            let number = [];
        
            alphabet.forEach((value, index) => {
                alphaMap[value] = index + 10;
            });
        
            rearrange.split('').forEach((value, index) => {
                number[index] = alphaMap[value] || value;
            });
        
            if ( this._modulo(number.join('').toString(), 97) !== 1 ) {
                //this.error_message = 'Iban non valido';
                //this.error_component = true;
                this._set_error('Iban non valido');
                return false;
            } else {
                this._set_is_valid();
            }
            return true;
        },
        _modulo(aNumStr, aDiv) {
            var tmp = "";
            var i, r;
            for (i = 0; i < aNumStr.length; i++) {
                tmp += aNumStr.charAt(i);
                r = tmp % aDiv;
                tmp = r.toString();
            }
            return tmp / 1;
        }
    },
    data() {
        return { is_valid: null, timeout: null, merror_component: null };
    },
};

</script>

<template>

    <div class="form-group" :class="field_class" :style="field_style">
        <label :for="'bnv-form-' + name" :class="label_class">{{ get_label() }}</label>
        <div :class="element_container_class">
            <b-input
                v-on:keyup.enter  = "enter_key"
                ref               = "input"
                type              = "text"
                :size             = "input_class"
                v-bind:class      = "{ 'is-invalid': merror_component, 'is-valid': is_valid }"
                :placeholder      = "get_placeholder()"
                :aria-label       = "name"
                :aria-describedby = "name"
                :id               = "'bnv-form-' + name"
                v-model           = "_model"
                :disabled         = "disabled"
                :readonly         = "_readonly"
                @blur             = "_validate"
            />
            <small v-if="helper" :id="'bnv-form-' + name + '-help'" class="form-text text-muted" v-html="_helper"></small>
            <div v-if="display_error"  class="invalid-feedback">
                {{ get_error_message() }}
            </div>
        </div>
    </div>

</template>

