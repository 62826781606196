////////////////////

import { authHeader } from '@/helpers';

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export const sesso_service = {
    get_all,
};

function get_all() {
    var options = [
        { id: 'Maschio', label: 'Maschio' },
        { id: 'Femmina', label: 'Femmina' },
        { id: 'Altro',   label: 'Altro' },
    ];
    return new Promise(function(resolve, reject) {
        resolve( options );
    });
}


