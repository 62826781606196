////////////////////

import { authHeader } from '@/helpers';

const env    = process.env.NODE_ENV || 'local';
const config = require('@/config.js')[ env ];

export const userService = {
    login,
    logout,
    getAll,
    reset_password,
    save_password,
    get_challenge,
};

function get_challenge() {
    var user_id = JSON.parse( localStorage.getItem('user') ).id;
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', },
    };
    return fetch(`${config.backend_url}model/user/${ user_id }/challenge`, requestOptions)
        .then(handleResponse)
        .then(data => data)
}

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', },
        body: JSON.stringify( { email, password } )
    };

    return fetch(`${config.backend_url}model/user/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.jwt) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
}

function reset_password(email) {
    const requestOptions = {
        method: 'POST',
        headers : authHeader(),
        body: JSON.stringify( { email } )
    };

    return fetch(`${config.backend_url}model/user/resetpassword`, requestOptions)
        .then(handleResponse)
        .then( res => {
            return res;
        })
}

function save_password(jwt, password, confirm_password) {
    const requestOptions = {
        method: 'POST',
        headers : authHeader(),
        body: JSON.stringify( { password, confirm_password } )
    };
    requestOptions.headers['Authorization'] = 'Bearer ' + jwt;

    return fetch(`${config.backend_url}model/user/savepassword`, requestOptions)
        .then(handleResponse)
        .then( res => {
            return res;
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.backend_url}model/user`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
