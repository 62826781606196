<script>

import bnvbase from './bnvbase.vue';

export default {
    extends: bnvbase,
    props: [ 'store', ],
    computed: {
        options () {
            return this.$store.state[ this.store ];
        },
        label_name() {
            if ( !this.creator ) { return null; }
            return this.creator.schema.references.label || null;
        },
    },
    created () {
        if ( this.$store.state[ this.store ].force_reload === true || this.$store.state[ this.store ].items === null ) {
            this.$store.dispatch(this.store + '/get_all');
        }
    },
    data() {
        return {
            is_valid: null,
        };
    }
};

</script>

<template>

    <div>
        <div class="form-group" :class="field_class" :style="field_style" v-if="options.loading === false">
            <label :for="'bnv-form-' + name" :class="label_class" v-if="display_label !== false">{{ get_label() }}</label>
            <div :class="element_container_class">
                <b-input-group>
                    <b-select
                        ref               = "input"
                        class             = "form-control"
                        :size             = "input_class"
                        v-bind:class      = "{ 'is-invalid': error_component, 'is-valid': is_valid }"
                        :aria-label       = "name"
                        :aria-describedby = "name"
                        :id               = "'bnv-form-' + name"
                        v-model           = "model"
                        :disabled         = "disabled || _readonly || options.loading"
                        @change           = "value_changed"
                        >
                        <option value="">- - - - - - -</option>
                        <option v-for="option in options.items" v-bind:key="option.id" :value="option.id">{{ option[ label_name || 'label' ] }}</option>
                    </b-select>
                    <b-input-group-append v-if="$slots.append">
                        <slot name="append"/>
                    </b-input-group-append>
                </b-input-group>
                <small v-if="helper" :id="'bnv-form-' + name + '-help'" class="form-text text-muted" v-html="_helper"></small>
                <input v-if="_readonly" type="hidden" v-model="model" />
                <div class="invalid-feedback">
                    {{ get_error_message() }}
                </div>
                <div v-if="confirm_message" class="valid-feedback">
                    {{ confirm_message }}
                </div>
            </div>
        </div>
        <div class="form-group" :class="field_class" :style="field_style" v-if="options.loading === true">
            <label :for="'bnv-form-' + name" :class="label_class">{{ get_label() }}</label>
            <div :class="element_container_class">
                <input
                    type="text"
                    class="form-control spinner"
                    placeholder="caricamento opzioni..."
                    :id="'bnv-form-loader-' + name"
                    :disabled="true"
                />
                <small v-if="helper" :id="'bnv-form-' + name + '-help'" class="form-text text-muted" v-html="_helper"></small>
            </div>
        </div>
    </div>

</template>



