////////////////////

import { authHeader } from '@/helpers';

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export const tipo_strada_service = {
    get_all,
};

function get_all() {
    var options = [
        { id: 'Via',       label: 'Via' },
        { id: 'Vicolo',    label: 'Vicolo' },
        { id: 'Quartiere', label: 'Quartiere' },
        { id: 'Strada',    label: 'Strada' },
        { id: 'Campo',     label: 'Campo' },
        { id: 'Piazza',    label: 'Piazza' },
        { id: 'Viale',     label: 'Viale' },
        { id: 'Riviera',   label: 'Riviera' },
        { id: 'Salita',    label: 'Salita' },
        { id: 'Calle',     label: 'Calle' },
    ];
    options.sort( (a, b) => ( a.label > b.label ? 1 : -1 ) );
    return new Promise(function(resolve, reject) {
        resolve( options );
    });
}


