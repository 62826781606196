<script>

export default {

    name: 'cmp-files-uploader',
    props: [ 'item' ],
    data() {
        return {
        }
    },
    computed: {},
    methods: {
        get_uploader() {
            if ( this.item.task_image_id === null ) { return ''; }
            return this.item.task_image.value.uploaded_by;
        }
    },
    created () {
    },

}

</script>

<template>

    <div style="padding-left: 10px; color: grey;">
        <small style="white-space: nowrap;" v-html="get_uploader()"></small>
    </div>

</template>


