//////////////////////////////

module.exports = (type) => {
    var schema = {
        created: {
            type: type.DATE,
            defaultValue: type.NOW,
            allowNull: false,
        },
        label: {
            type: type.STRING(2048),
            allowNull: false,
        },
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        type: {
            type: type.STRING(64),
            allowNull: true,
        },
        config: {
            type: type.JSONB,
            defaultValue: {},
        },
        active: {
            type: type.BOOLEAN,
            defaultValue: true,
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

