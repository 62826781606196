/////////////////////////////////////////////////

class task {

    constructor( data ) {
        this.data = data;
    }

    // static public methods ////////////////////

    // static private methods ///////////////////

    // public methods ///////////////////////////

    // private methods //////////////////////////

    // getters & setters ////////////////////////

    get codice() {
        return String( this.data.codice );
    }

    get has_appuntamento() {
        try {
            if ( this.data.pregs.incarico.latitudine_luogo_appuntamento && this.data.pregs.incarico.longitudine_luogo_appuntamento ) {
                return true;
            }
            return false;
        } catch ( error ) {
            return false;
        }
    }

    get latitudine() {
        if ( !this.has_appuntamento ) { return null; }
        return parseFloat( this.data.pregs.incarico.latitudine_luogo_appuntamento );
    }

    get longitudine() {
        if ( !this.has_appuntamento ) { return null; }
        return parseFloat( this.data.pregs.incarico.longitudine_luogo_appuntamento );
    }

    get latlng() {
        if ( !this.has_appuntamento ) { return null; }
        return { lat: this.latitudine, lng: this.longitudine };
    }

//  get popup() {
//      if ( !this.has_appuntamento ) { return null; }
//      const newDiv = document.createElement("div");
//      newDiv.setAttribute("id", `label-${ this.codice }`);
//      const newContent = document.createTextNode( this.codice );
//      newDiv.appendChild(newContent);
//      const currentDiv = document.getElementById("label_container");
//      document.body.insertBefore(newDiv, currentDiv);
//      console.log( new google.maps.LatLng( this.latitudine, this.longitudine ) );
//      return new popup(
//          new google.maps.LatLng( this.latitudine, this.longitudine ),
//          document.getElementById(`label-${ this.codice }`),
//      );
//  }

}

// static getters ///////////////////////////

export default task;

