<script>

export default {

    name: 'appuntamento-controparte-line',
    props: [ 'item', 'name' ],
    created() {},
    computed: {
        controparte() {
            return this.item.pregs && this.item.pregs.controparte && this.item.pregs.controparte.length > 0 ? this.item.pregs.controparte[0].controparte : null
        },
        controparte_referente() {
            //return this.item.pregs && this.item.pregs.controparte && this.item.pregs.controparte[ Object.keys( this.item.pregs.controparte )[0] ].controparte_referente ? this.item.pregs.controparte[ Object.keys( this.item.pregs.controparte )[0] ].controparte_referente : null
            return null;
        },
        controparte_label() {
            return this.controparte ? this.controparte.label.toUpperCase() : '';
        },
        indirizzo() {
            return this.controparte ? this.controparte.indirizzo : '';
        },
        telefono() {
            let res = null;
            if ( this.controparte && this.controparte.telefono.length > 0 ) {
                res = this.controparte.telefono.map( x => {
                    //return x.label ? `${ x.label } ${ x.value }` : x.value;
                    return x.value;
                });
            }
            return res ? res.join(' / ') : null;
        },
        email() {
            let res = null;
            if ( this.controparte && this.controparte.email.length > 0 ) {
                res = this.controparte.email.map( x => {
                    //return x.label ? `${ x.label } ${ x.value }` : x.value;
                    return x.value;
                });
            }
            return res ? res.join(' / ') : null;
        },
        controparte_referente_label() {
            return this.controparte_referente ? this.controparte_referente.label.toUpperCase() : '';
        },
        telefono_referente() {
            let res = null;
            if ( this.controparte_referente && this.controparte_referente.telefono.length > 0 ) {
                res = this.controparte_referente.telefono.map( x => {
                    //return x.label ? `${ x.label } ${ x.value }` : x.value;
                    return x.value;
                });
            }
            return res ? res.join(' / ') : null;
        },
        email_referente() {
            let res = null;
            if ( this.controparte_referente && this.controparte_referente.email.length > 0 ) {
                res = this.controparte_referente.email.map( x => {
                    //return x.label ? `${ x.label } ${ x.value }` : x.value;
                    return x.value;
                });
            }
            return res ? res.join(' / ') : null;
        },
        indirizzo_referente() {
            return this.controparte_referente && this.controparte_referente.indirizzo ? this.controparte_referente.indirizzo : '';
        },
    },

}

</script>

<template>

    <div>
        <p style="line-height: 16px; margin-bottom: 2px;"><small style="font-weight: 600;">{{ controparte_label }}</small><br>
            <!-- <span v-if="telefono"><small>
                <span v-if="name" style="font-weight: 600;">telefono: </span>{{ telefono }}<br/>
            </small></span>
            <span v-if="email"><small>
                <span v-if="name" style="font-weight: 600;">email: </span>{{ email }}<br/>
            </small></span>
            <span v-if="indirizzo"><small>
                <span v-if="name" style="font-weight: 600;">indirizzo: </span>{{ indirizzo }}<br/>
            </small></span> -->
            <!--
            <table :style="{ 'width': name ? '100%' : 'auto' }">
                <tr v-if="telefono">
                    <td v-if="name"><small style="font-weight: 600;">cellulare:</small></td>
                    <td><small style="padding-left: 4px;">{{ telefono }}</small></td>
                </tr>
                <tr v-if="email">
                    <td v-if="name"><small style="font-weight: 600;">email:</small></td>
                    <td><small style="padding-left: 4px;">{{ email }}</small></td>
                </tr>
                <tr v-if="indirizzo">
                    <td v-if="name"><small style="font-weight: 600;">indirizzo:</small></td>
                    <td><small style="padding-left: 4px;">{{ indirizzo }}</small></td>
                </tr>
            </table>
            -->
        </p>
        <p v-if="name && controparte_referente" style="margin-top: 15px; line-height: 16px; margin-bottom: 2px;"><small>referente: </small><small style="font-weight: 600;">{{ controparte_referente_label }}</small><br>
            <!-- <span v-if="telefono_referente"><small>
                <span v-if="name" style="font-weight: 600;">telefono: </span>{{ telefono_referente }}<br/>
            </small></span>
            <span v-if="email_referente"><small>
                <span v-if="name" style="font-weight: 600;">email: </span>{{ email_referente }}<br/>
            </small></span>
            <span v-if="indirizzo_referente"><small>
                <span v-if="name" style="font-weight: 600;">indirizzo: </span>{{ indirizzo_referente }}<br/>
            </small></span> -->
            <!--
            <table :style="{ 'width': name ? '100%' : 'auto' }">
                <tr v-if="telefono_referente">
                    <td v-if="name"><small style="font-weight: 600;">cellulare:</small></td>
                    <td><small style="padding-left: 4px;">{{ telefono_referente }}</small></td>
                </tr>
                <tr v-if="email_referente">
                    <td v-if="name"><small style="font-weight: 600;">email:</small></td>
                    <td><small style="padding-left: 4px;">{{ email_referente }}</small></td>
                </tr>
                <tr v-if="indirizzo">
                    <td v-if="name"><small style="font-weight: 600;">indirizzo:</small></td>
                    <td><small style="padding-left: 4px;">{{ indirizzo }}</small></td>
                </tr>
            </table>
            -->
        </p>
    </div>

</template>

