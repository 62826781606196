//////////////////////////////

module.exports = (type) => {
    var schema = {
        custodian: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'custodian',
                key         : 'id',
                constraints : true,
                store_name  : 'custodian',
            }
        },
//      imponibile: {
//          type: type.DECIMAL(10,2),
//          allowNull: false,
//      },
//      imponibile_custodian: {
//          type: type.DECIMAL(10,2),
//          allowNull: false,
//      },
        exec_password: {
            type: type.PASSWORD,
            allowNull: false,
        },
        id: {
            type: type.HIDDEN,
        },
    };

    return schema;
}




