////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';
const config = require('@/config.js')[ process.env.NODE_ENV || 'test' ];

var options = {
    model_name    : 'presence',
    send_alert    : false,
    //default_order : 'last_name ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    ws: null,
};

const actions = {
    ...base.actions,
    open({commit}) {
        var userjson = localStorage.getItem('user');
        if ( userjson ) {
            var userobj = JSON.parse( userjson );
        }
        if ( userobj.jwt ) {
            if ( config.backend_url.includes( 'https' ) ) {
                var ws = new WebSocket( config.backend_url.replace('https://', 'wss://') + `presence?jwt=${ userobj.jwt }` );
            } else if ( config.backend_url.includes( 'http' ) ) {
                var ws = new WebSocket( config.backend_url.replace('http://', 'ws://') + `presence?jwt=${ userobj.jwt }` );
            }
        }
        commit('set_ws', ws);
    },
    close({commit}) {
        commit('remove_ws');
    },
};

const mutations = {
    ...base.mutations,
    set_ws(state, ws) {
        state.ws = ws;
    },
    remove_ws(state) {
        state.ws = null;
    },
};

export default {
    namespaced : true,
    state,
    actions,
    mutations,
    getters: base.getters,
};

////////////////////

//import { bnvform_service } from '@/services';
//const config = require('@/config.js')[ process.env.NODE_ENV || 'test' ];
//
//export const presence = {
//    namespaced: true,
//    state: {
//        title_form    : '',
//        obj           : {},
//        items         : null,
//        loading       : false,
//        error         : false,
//        executed      : false,
//        force_reload  : false,
//        page          : 1,  // default
//        rows_per_page : 25, // default
//        tot           : 0,
//        order         : null,
//        ws            : null
//    },
//    actions: {
//        open({commit}) {
//            var userjson = localStorage.getItem('user');
//            if ( userjson ) {
//                var userobj = JSON.parse( userjson );
//            }
//            if ( userobj.jwt ) {
//                if ( config.backend_url.includes( 'https' ) ) {
//                    var ws = new WebSocket( config.backend_url.replace('https://', 'wss://') + `presence?jwt=${ userobj.jwt }` );
//                } else if ( config.backend_url.includes( 'http' ) ) {
//                    var ws = new WebSocket( config.backend_url.replace('http://', 'ws://') + `presence?jwt=${ userobj.jwt }` );
//                }
//            }
//            commit('set_ws', ws);
//        },
//        close({commit}) {
//            commit('remove_ws');
//        },
//        get_by_id({commit}, data) {
//            commit('start_request');
//            bnvform_service.get_by_id('presence', data.id, data.include)
//                .then( rows => {
//                    commit('success_obj', rows)
//                })
//                .catch( error => {
//                    commit('error', error)
//                });
//        },
//        get_all({commit, state}, data = { options: {} }) {
//            commit('start_request');
//            data.options.order       = state.order;
//            bnvform_service.get_all('presence', data.options)
//                .then( response => {
//                    if ( data.options.paginate === true ) { // gestione paginator
//                        commit('success_list', response.data);
//                        commit('set_total', response.tot);
//                        commit('set_page', response.page);
//                        commit('set_rows_per_page', response.rows_per_page);
//                    } else {
//                        commit('success_list', response)
//                    }
//                })
//                .catch( error => {
//                    commit('error', error)
//                });
//        },
//        create({ commit }, data) {
//            commit('start_request');
//            bnvform_service.create( 'presence', data.payload, data.include )
//                .then( obj => {
//                    commit('success', obj);
//                })
//                .catch( error => {
//                    commit('error', error)
//                });
//        },
//        update({ commit }, data) {
//            commit('start_request');
//            bnvform_service.update( 'presence', data.id, data.payload, data.include )
//                .then( obj => {
//                    commit('success', obj);
//                })
//                .catch( error => {
//                    commit('error', error)
//                });
//        },
//        force_reload({commit}) { commit('force_reload'); },
//        executed({commit}) { commit('executed'); },
//        clear({commit}) { commit('clear'); },
//        set_order( {commit}, value ) {
//            commit('setorder', value);
//        },
//    },
//    mutations: {
//        set_ws(state, ws) {
//            state.ws = ws;
//        },
//        remove_ws(state) {
//            state.ws = null;
//        },
//        setorder ( state, value ) {
//            state.order = value;
//        },
//        start_request( state ) {
//            state.loading      = true;
//            state.error        = false;
//            state.executed     = false;
//            state.force_reload = false;
//        },
//        success( state, obj ) {
//            state.obj      = obj;
//            state.loading  = false;
//            state.error    = false;
//            state.executed = true;
//        },
//        success_obj( state, obj ) {
//            state.obj      = obj;
//            state.loading  = false;
//            state.error    = false;
//            state.executed = false;
//        },
//        success_list( state, items ) {
//            state.items    = items;
//            state.loading  = false;
//            state.error    = false;
//            state.executed = true;
//        },
//        error( state, error ) {
//            state.obj          = error;
//            state.loading      = false;
//            state.error        = true;
//            state.executed     = false;
//            state.force_reload = true;
//        },
//        set_total( state, data ) {
//            state.tot = data;
//        },
//        set_page( state, data ) {
//            state.page = data;
//        },
//        set_rows_per_page( state, data ) {
//            state.rows_per_page = data;
//        },
//        force_reload(state) {
//            state.force_reload = true;
//        },
//        executed(state) {
//            state.executed = false;
//        },
//        clear( state ) {
//            state.obj          = {};
//            state.items        = null;
//            state.loading      = false;
//            state.error        = false;
//            state.executed     = false;
//            state.force_reload = false;
//        },
//    }
//}


