<script>

import Vue        from 'vue';
import { openDB } from 'idb';
import menu_bar   from '@/layout/components/menu_bar';
import websocket_logger from '@/layout/components/websocket_logger'

Vue.component( 'menu-bar', menu_bar );

export default {
    name: 'app',
    computed: {
        is_logged () {
            return ( this.$store.state.authentication.status.hasOwnProperty('loggedIn') && this.$store.state.authentication.status.loggedIn === true ) ? true : false;
        },
        file_perc_completed() { return this.$store.getters['localdb_images/file_perc_completed']; },
        file_name() { return this.$store.state.localdb_images.file_name; },
        file_to_upload() { return this.$store.getters['localdb_images/file_to_upload']; },
        upload() { return this.$store.state.localdb_images.upload; },
        file_perc_completed_docs() { return this.$store.getters['localdb_documents/file_perc_completed']; },
        file_name_docs() { return this.$store.state.localdb_documents.file_name; },
        file_to_upload_docs() { return this.$store.getters['localdb_documents/file_to_upload']; },
        upload_docs() { return this.$store.state.localdb_documents.upload; },
        // upload zip
        extraction_uploading() { return this.$store.state.import_csv.upload_active; },
        file_percentage() { return this.$store.state.import_csv.file_percentage; },
        queue_percentage() { return this.$store.state.import_csv.queue_percentage; },
        zip_file_name() { return this.$store.state.import_csv.active_file_name; },
        loading_docs() { return this.$store.state.import_csv.loading_docs; },
        wscode_docs() { return this.$store.state.import_csv.wscode_docs; },
    },
    watch: {
        file_perc_completed( new_val, old_val ) {
            //console.log( new_val );
        },
        upload( new_val, old_val ) {
            if ( new_val === true && old_val === false ) {
                this.$bvToast.show('upload-toast');
            } else if ( new_val === false && old_val === true ) {
                this.$bvToast.hide('upload-toast');
            }
        },
        upload_docs( new_val, old_val ) {
            if ( new_val === true && old_val === false ) {
                this.$bvToast.show('upload-toast-docs');
            } else if ( new_val === false && old_val === true ) {
                this.$bvToast.hide('upload-toast-docs');
            }
        },
        is_logged( new_val, old_val ) {
            if ( new_val === true ) { this.check_images(); }
        },
        // upload zip
        extraction_uploading( new_val, old_val ) {
            if ( new_val === true && old_val === false ) {
                this.$bvToast.show('upload-toast-zip');
            } else if ( new_val === false && old_val === true ) {
                setTimeout( () => {
                    this.$bvToast.hide('upload-toast-zip');
                    this.$store.dispatch( 'import_csv/import_docs' );
                }, 1500);
            }
        },
        loading_docs( new_val ) {
            if ( new_val === true ) {
                this.$bvToast.show('import-docs-toast');
                console.log( 'wscode_docs', this.wscode_docs );
            }
        },
    },
    filters: {
        // upload zip
        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
    },
    components: { 'websocket-logger' : websocket_logger },
    async created() {
        // TODO NB: se modifichi la versione in dev devi ricaricare perché il db va il locked
        const db = await openDB('queue_db', 2, {
            upgrade(db, oldVersion, newVersion, transaction) {
                console.log( 'upgrade', oldVersion );
                if ( oldVersion < 2 ) {
                    try {
                        db.createObjectStore('images', {
                            keyPath: 'id',
                            // If it isn't explicitly set, create a value by auto incrementing.
                            autoIncrement: true,
                        });
                    } catch ( error ) {
                        console.log( error.toString() );
                    }
                    try {
                        db.createObjectStore('documents', {
                            keyPath: 'id',
                            // If it isn't explicitly set, create a value by auto incrementing.
                            autoIncrement: true,
                        });
                    } catch ( error ) {
                        console.log( error.toString() );
                    }
                }
                if ( oldVersion === 1 ) {
                    try {
                        db.deleteObjectStore('queue');
                    } catch ( error ) {
                        console.log( error.toString() );
                    }
                }
            },
            blocked() { // TODO
                console.log( 'DB blocked' );
            },
            blocking() { // TODO
                console.log( 'blocking' );
            },
            terminated() { // TODO
                console.log( 'terminated' );
            },
        });
        this.$store.dispatch( 'localdb_images/set_db', db );
        this.$store.dispatch( 'localdb_documents/set_db', db );
        this.check_images();
//      let ak = 'AIzaSyCnn5il42PmELj_wZ1jlZqR0W9gBpqW7yQ';
//      const loader = new Loader({
//          apiKey    : ak,
//          version   : 'weekly',
//          libraries : ['places', 'static']
//      });
//      loader.load().then( () => {
//          console.log( 'google maps library loaded' );
//      });
    },
    filters: {
        truncate: function (text, length, suffix) {
            if (text && text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
    },
    methods: {
        async check_images() {
            //console.log('check_images');
            await this.$store.dispatch( 'localdb_images/verify_uploaded', 'images' );
            await this.$store.dispatch( 'localdb_images/start_upload' );
            await this.$store.dispatch( 'localdb_documents/verify_uploaded', 'images' );
            await this.$store.dispatch( 'localdb_documents/start_upload' );
            if ( this.is_logged ) {
                setTimeout( () => { this.check_images(); }, 30000 );
            }
        }
    },
}

</script>

<template>

    <div >
        <menu-bar v-if="is_logged"></menu-bar>
        <b-container style="margin-top: 70px" fluid>
            <router-view :key="$route.fullPath" />
        </b-container>
        <b-toast
            id       = "upload-toast"
            variant  = "dark"
            :solid   = "true"
            toaster  = "b-toaster-bottom-right"
            solid
            no-auto-hide
            no-close-button
            >
            <template #toast-title>
                <div class="d-flex flex-grow-1 align-items-baseline">
                    <b-img blank blank-color="#ff5555" class="mr-2" width="12" height="12"></b-img>
                    <strong class="mr-auto">Upload in corso...</strong>
                </div>
            </template>
            <b-progress height="3px" :value="file_perc_completed" :max="100"></b-progress>
            <small v-if="file_name" style="color: grey;">{{ file_name | truncate(44, '...') }}<b-badge variant="light" style="float: right; margin-top: 4px">{{ Math.round(file_perc_completed) }}%</b-badge></small><br/>
            <small style="">File in coda di upload:<b-badge variant="light" style="float: right; margin-top: 4px;">{{ file_to_upload }}</b-badge></small>
        </b-toast>
        <b-toast
            id       = "upload-toast-docs"
            variant  = "dark"
            :solid   = "true"
            toaster  = "b-toaster-bottom-right"
            solid
            no-auto-hide
            no-close-button
            >
            <template #toast-title>
                <div class="d-flex flex-grow-1 align-items-baseline">
                    <b-img blank blank-color="#ff5555" class="mr-2" width="12" height="12"></b-img>
                    <strong class="mr-auto">Upload in corso...</strong>
                </div>
            </template>
            <b-progress height="3px" :value="file_perc_completed_docs" :max="100"></b-progress>
            <small v-if="file_name" style="color: grey;">{{ file_name_docs | truncate(44, '...') }}<b-badge variant="light" style="float: right; margin-top: 4px">{{ Math.round(file_perc_completed_docs) }}%</b-badge></small><br/>
            <small style="">File in coda di upload:<b-badge variant="light" style="float: right; margin-top: 4px;">{{ file_to_upload_docs }}</b-badge></small>
        </b-toast>
        <b-toast
            id       = "upload-toast-zip"
            variant  = "dark"
            :solid   = "true"
            toaster  = "b-toaster-bottom-right"
            solid
            no-auto-hide
            no-close-button
            >
            <template #toast-title>
                <div class="d-flex flex-grow-1 align-items-baseline">
                    <b-img blank blank-color="#ff5555" class="mr-2" width="12" height="12"></b-img>
                    <strong class="mr-auto">Upload in corso...</strong>
                    <!-- <small class="text-muted mr-2">42 seconds ago</small> -->
                </div>
            </template>
            <b-progress height="3px" :value="file_percentage" :max="100"></b-progress>
            <small v-if="zip_file_name" style="color: grey;">{{ zip_file_name | truncate(55, '...') }}<b-badge variant="light" style="float: right; margin-top: 4px">{{ Math.round(file_percentage) }}%</b-badge></small>
            <b-progress style="margin-top: 10px;" height="3px" :value="queue_percentage" :max="100"></b-progress>
            <small style="">Progresso Totale<b-badge variant="light" style="float: right; margin-top: 4px;">{{ queue_percentage }}%</b-badge></small>
        </b-toast>
        <b-toast
            id       = "import-docs-toast"
            variant  = "dark"
            :solid   = "true"
            toaster  = "b-toaster-bottom-right"
            solid
            no-auto-hide
            no-close-button
            >
            <template #toast-title>
                <div class="d-flex flex-grow-1 align-items-baseline">
                    <b-img blank blank-color="#ff5555" class="mr-2" width="12" height="12"></b-img>
                    <strong class="mr-auto">Importazione documenti in corso...</strong>
                    <!-- <small class="text-muted mr-2">42 seconds ago</small> -->
                </div>
            </template>
            <websocket-logger
                :wspath = "'ws/importdocs/?code=' + wscode_docs"
                title   = "Importazione documenti"
                >
            </websocket-logger>
        </b-toast>
    </div>

</template>

<style>
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
</style>
