////////////////////////////////////////

const fileupload                = require('@/schemas/fileupload.schema.js');
const pratica                   = require('@/schemas/pratica.schema.js');
const dati_pratica              = require('@/schemas/dati_pratica.schema.js');
const agenzia                   = require('@/schemas/agenzia.schema.js');
const assicurato                = require('@/schemas/assicurato.schema.js');
const controparte               = require('@/schemas/controparte.schema.js');
const referente                 = require('@/schemas/referente.schema.js');
const ufficio                   = require('@/schemas/ufficio.schema.js');
const search_pratica            = require('@/schemas/search_pratica.schema.js');
const customer                  = require('@/schemas/customer.schema.js');
const user                      = require('@/schemas/user.schema.js');
const role                      = require('@/schemas/role.schema.js');
const meta_file                 = require('@/schemas/meta_file.schema.js');
const meta_file_doc             = require('@/schemas/meta_file_doc.schema.js');
const add_pratica               = require('@/schemas/add_pratica.schema.js');
const rename                    = require('@/schemas/rename.schema.js');
const diary                     = require('@/schemas/diary.schema.js');
const export_pratica            = require('@/schemas/export_pratica.schema.js');
const ispezione_dp              = require('@/schemas/ispezione_dp.schema.js');
const ispezione_as              = require('@/schemas/ispezione_as.schema.js');
const ispezione_co              = require('@/schemas/ispezione_co.schema.js');
const edit_description          = require('@/schemas/edit_description.schema.js');
const importer_credential       = require('@/schemas/importer_credential.schema.js');
const custodian                 = require('@/schemas/custodian.schema.js');
const search_statistic          = require('@/schemas/search_statistic.schema.js');
const amounts_owner             = require('@/schemas/amounts_owner.schema.js');
const amounts_custodian         = require('@/schemas/amounts_custodian.schema.js');
const email_template            = require('@/schemas/email_template.schema.js');
const reminder                  = require('@/schemas/reminder.schema.js');
const sollecito_document        = require('@/schemas/sollecito_document.schema.js');
const manual_email_schema       = require('@/schemas/manual_email.schema.js');
const registry                  = require('@/schemas/registry.schema.js');
const comune                    = require('@/schemas/comune.schema.js');
const comune_regione            = require('@/schemas/comune_regione.schema.js');
const comune_provincia          = require('@/schemas/comune_provincia.schema.js');
const _address                  = require('@/schemas/_address.schema.js');
const _registry                 = require('@/schemas/_registry.schema.js');
const _codfisc                  = require('@/schemas/_codfisc.schema.js');
const _contact                  = require('@/schemas/_contact.schema.js');
const _add_registry             = require('@/schemas/_add_registry.schema.js');
const _agenzia                  = require('@/schemas/_agenzia.schema.js');
const _compagnia                = require('@/schemas/_compagnia.schema.js');
const _persona_fisica           = require('@/schemas/_persona_fisica.schema.js');
const _address_data             = require('@/schemas/_address_data.schema.js');
const _persona_giuridica        = require('@/schemas/_persona_giuridica.schema.js');
const _persona_giuridica_subtab = require('@/schemas/_persona_giuridica_subtab.schema.js');
const _ispettorato              = require('@/schemas/_ispettorato.schema.js');
const _liquidatore              = require('@/schemas/_liquidatore.schema.js');
const _ente_pubblico            = require('@/schemas/_ente_pubblico.schema.js');
const _polizza                  = require('@/schemas/_polizza.schema.js');
const _polizza_preg             = require('@/schemas/_polizza_preg.schema.js');
const _polizza_subtab           = require('@/schemas/_polizza_subtab.schema.js');
const _all_fields               = require('@/schemas/_all_fields.schema.js');
const _compagnia_subtab         = require('@/schemas/_compagnia_subtab.schema.js');
const _assicurato_subtab        = require('@/schemas/_assicurato_subtab.schema.js');
const _agenzia_subtab           = require('@/schemas/_agenzia_subtab.schema.js');
const _ispettorato_subtab       = require('@/schemas/_ispettorato_subtab.schema.js');
const _liquidatore_subtab       = require('@/schemas/_liquidatore_subtab.schema.js');
const _persona_fisica_subtab    = require('@/schemas/_persona_fisica_subtab.schema.js');
const _cliente_subtab           = require('@/schemas/_cliente_subtab.schema.js');
const _controparte_subtab       = require('@/schemas/_controparte_subtab.schema.js');
const _riparatore_subtab        = require('@/schemas/_riparatore_subtab.schema.js');
const _testimone_subtab         = require('@/schemas/_testimone_subtab.schema.js');
const _autorita_subtab          = require('@/schemas/_autorita_subtab.schema.js');
const pratica_incarico          = require('@/schemas/pratica_incarico.schema.js');
const tipo_pratica              = require('@/schemas/tipo_pratica.schema.js');
const appuntamento              = require('@/schemas/appuntamento.schema.js');
const ricerca                   = require('@/schemas/ricerca.schema.js');
const ricerca_pratica           = require('@/schemas/ricerca_pratica.schema.js');

module.exports = {
    fileupload,
    pratica,
    dati_pratica,
    agenzia,
    assicurato,
    controparte,
    referente,
    ufficio,
    search_pratica,
    customer,
    user,
    role,
    meta_file,
    meta_file_doc,
    add_pratica,
    rename,
    diary,
    export_pratica,
    ispezione_dp,
    ispezione_as,
    ispezione_co,
    edit_description,
    importer_credential,
    custodian,
    search_statistic,
    amounts_owner,
    amounts_custodian,
    email_template,
    reminder,
    sollecito_document,
    manual_email_schema,
    registry,
    comune,
    comune_regione,
    comune_provincia,
    _address,
    _registry,
    _codfisc,
    _contact,
    _add_registry,
    _agenzia,
    _compagnia,
    _persona_fisica,
    _address_data,
    _persona_giuridica,
    _persona_giuridica_subtab,
    _ispettorato,
    _liquidatore,
    _ente_pubblico,
    _polizza,
    _polizza_preg,
    _polizza_subtab,
    _all_fields,
    _compagnia_subtab,
    _assicurato_subtab,
    _agenzia_subtab,
    _ispettorato_subtab,
    _liquidatore_subtab,
    _persona_fisica_subtab,
    _cliente_subtab,
    _controparte_subtab,
    _riparatore_subtab,
    _testimone_subtab,
    _autorita_subtab,
    pratica_incarico,
    tipo_pratica,
    appuntamento,
    ricerca,
    ricerca_pratica,
};
