//////////////////////////////

module.exports = (type) => {
    var schema = {
        created: {
            type: type.DATE,
            defaultValue: type.NOW,
            allowNull: false,
        },
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        codice_pratica: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'pratiche',
                key         : 'codice',
                constraints : true,
                store_name  : 'pratica',
            }
        },
        task_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'tasks',
                key         : 'id',
                constraints : true,
                store_name  : 'task',
            }
        },
        to: {
            type: type.JSONB,
            defaultValue: [],
            allowNull : false,
            options: {
                tag           : true,
                store_name    : 'pratica_addresses',
                new_item_type : 'email',
                not_empty     : true,
            },
        },
        cc: {
            type: type.JSONB,
            defaultValue: [],
            //defaultValue: [ { label: 'pic@perizieincloud.it', read_only: true } ],
            allowNull : true,
            options: {
                tag           : true,
                store_name    : 'pratica_addresses',
                new_item_type : 'email',
            },
        },
        bcc: {
            type: type.JSONB,
            //defaultValue: [],
            defaultValue: [ { label: 'pic@perizieincloud.it', read_only: true } ],
            allowNull : true,
            options: {
                tag           : true,
                store_name    : 'pratica_addresses',
                new_item_type : 'email',
            },
        },
        docs: {
            type: type.JSONB,
            defaultValue: [],
            allowNull : false,
            options: {
                tag            : true,                           // lo renderizza in maschera con il component bnvtag
                store_name     : 'sollecito_document_bnvtag',    // lo store da quale preleva gli item
                new_item_type  : 'text_not_null',                // il nome del validatore per i nuovi item
                save_new_value : true,                           // possibilità di salvare nuovi item in tabella (tramite lo store)
                index_label    : 'label',                        // il nome dell'attributo selezionato dallo store da usare come etichetta
                edit           : true,                           // possibilità di editare un item (la modifica non viene salvata in tabella)
                additional_attributes : { 'delivered': false },  // attributi aggiuntivi da aggiungere all'item
            },
        },
        template1_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'email_templates',
                key         : 'id',
                constraints : true,
                store_name  : 'email_template',
                label       : 'name',
            }
        },
        template2_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'email_templates',
                key         : 'id',
                constraints : true,
                store_name  : 'email_template',
                label       : 'name',
            }
        },
        body_email1: {
            type: type.TEXT,
            allowNull: false,
        },
        body_email2: {
            type: type.TEXT,
            allowNull: false,
        },
        config: {
            type: type.JSONB,
            defaultValue: {},
        },
        active: {
            type: type.BOOLEAN,
            defaultValue: false,
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

