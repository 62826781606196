////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'user',
    send_alert    : false,
    default_order : 'last_name ASC',
};

var base = new base_module( options );

var getters = {
    ...base.getters,
    user_name: ( state ) => ( user_id ) => ( !user_id || state.items === null || state.items.length === 0 ) ? null : state.items.filter( x => x.id === user_id )[0].label,
}

var actions = {
    ...base.actions,
    get_all( { commit, state, getters, rootGetters }, _data = { options: {} } ) { // ok
        commit('start_request');
        //if ( state.items !== null && state.items.length > 0 ) { commit('success_list', state.items); return; }
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        data.options.order = getters.order;
        //Object.keys( state.filters ).map( key => { data.options[ key ] = state.filters[ key ]; } );
        data.options[ 'my_customers.id__in'] = rootGetters['statemanager/customers'].map( x => x.id ).join(',');
        state.service.get_all(getters.model_name, data.options)
            .then( response => {
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
}

export default {
    namespaced : true,
    ...base,
    getters,
    actions,
};

