//////////////////////////////

export default class {

    constructor() {
        this.obj       = null;
        this.is_setted = false;
        this.icons     = {
            'primo_contatto' : 'flag',
            'disamina'       : 'check',
            'appuntamento'   : 'geo',
            'ispezione'      : 'eye',
            'supervisore'    : 'check',
            'documenti'      : 'file-earmark',
            'scrittura'      : 'pen',
            'sottoscrizione' : 'tag',
            'correzione'     : 'cone-striped',
            'evasione'       : 'box-arrow-right',
            'incasso'        : 'wallet',
            'pagamenti'      : 'people',
        };
        this.bg_colors = {
            'done'         : 'bg-success',
            'unnecessary'  : 'bg-secondary',
            'intermediate' : 'bg-warning'
        };
    }

    // PUBLIC METHODS ////////////

    get_custodians( customer_id ) {
        if ( this.obj.hasOwnProperty('my_customers') ) {
            var customer_array = this.obj.my_customers.filter( x => x.id === customer_id );
            if ( customer_array.length === 1 ) {
                var customer = customer_array[0];
                if ( customer.hasOwnProperty('my_custodians') ) {
                    return customer.my_custodians.map( x => { return { id: x.id, label: x.label }; } )
                }
            }
        }
        return [];
    }

    get_custodian_conf( customer_id ) {
        if ( this.obj.hasOwnProperty('my_customers') ) {
           for ( var customer_index = 0; customer_index < this.obj.my_customers.length; customer_index++ ) {
                var customer = this.obj.my_customers[ customer_index ];
                if ( customer.hasOwnProperty('parcels') ) {
                    for ( var parcel_index = 0; parcel_index < customer.parcels.length; parcel_index++ ) {
                        var parcel = customer.parcels[ parcel_index ];
                        if ( parcel.custodian_id === customer_id ) {
                            return {
                                custodian_id : customer_id,
                                percentage   : parcel.percentage,
                                minimum      : parcel.minimum,
                            };
                        }
                    }
                }
            }
        }
    }

    get_icon( task_label ) { return this.icons.hasOwnProperty( task_label ) ? this.icons[ task_label ] : 'question' ; }

    get_color_by_state( state_label ) { return this.bg_colors.hasOwnProperty( state_label ) ? this.bg_colors[ state_label ] : 'black' ; }

    get_unpaid_tasks( customer_id ) {
        var cus = this.obj.my_customers.filter( x => x.id === customer_id );
        if ( cus.length > 0 ) {
            return cus[0].config.unpaid_tasks;
        }
        return [];
    }

    get_state_label_by_key( key ) {
        var state = this.states.filter( x => x.key === key );
        return state.length === 1 ? state[0].label : undefined;
    }

    // PRIVATE METHODS ///////////

    // GETTERS & SETTERS /////////

    set user( user ) {
        var obj = JSON.parse( JSON.stringify( user ) );
        this.obj = obj;
        this._is_setted = true;
    }

    get states() {
        var res = [];
        var customer = this.obj.my_customers[0];
        customer.my_states.map( x => {
            res.push({
                key         : x.key,
                id          : x.key,
                label       : x.label,
                description : x.description,
                name        : x.description.charAt(0).toUpperCase() + x.description.slice(1),
            });
        });
        return res.sort( (a, b) => (a.key > b.key) ? 1 : -1 );
    }

    get states_tasks() {
        var res = {};
        var customer = this.obj.my_customers[0];
        var states = customer.my_states.sort( (a, b) => (a.key > b.key) ? 1 : -1 )
        states.map( state => {
            res[ state.label ] = {
                label  : state.description.charAt(0).toUpperCase() + state.description.slice(1),
                fields : [],
                key    : state.key,
            }
            state.metas.map( task => {
                res[ state.label ].fields.push( task.name )
            });
        });
        return res;
    }

    get role_id() {
        return this.obj.role_id;
    }

    get customers() {
        var res = [];
        this.obj.my_customers.map( x => {
            res.push({
                id    : x.id,
                label : x.label,
            });
        });
        return res;
    }

    get templates() {
        var res = {};
        this.obj.my_customers.map( cus => {
            res[ cus.id ] = [];
            cus.my_templates.map( template => {
                res[ cus.id ].push({ 
                    label : template.label,
                    id    : template.id,
                });
            });
        });
        return res;
    }

    get first_state_id() {
        var states = this.obj.my_customers[0].my_states;
        states.sort((a,b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0)); 
        return states[0].key;
    }

}

