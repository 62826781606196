////////////////////////////////////

class modal_association_options {

    constructor() {
        this._reset();
    }

    // public methods ////////////////////////

    set_params( params ) {
        this._reset();
        Object.keys( params ).map( x => { this[ x ] = params[ x ]; } );
    }

    // private methods ////////////////////////

    _reset() {
        this._labels.map( x => { this[ x ] = null } );
    }

    // getters & setters //////////////////////

    get _labels() {
        return [
            'search_filters',
            'template',
            'role_id',
            'father_id',
            'registry_father_id',
            'registry_father_role_id',
            'record_id',
        ];
    }

}

export default modal_association_options;

