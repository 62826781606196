//////////////////////////////

// https://stackoverflow.com/questions/7922744/how-can-i-search-all-columns-in-a-table

module.exports = (type) => {
    var schema = {
        
        /* INDIRIZZO */
        strada: {
            type: type.STRING(64),
            allowNull: true,
        },
        n_civ: {
            type: type.STRING(64),
            allowNull: true,
        },
        cap: {
            type: type.STRING(32),
            allowNull: true,
        },
        comune_id: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'comuni',
                key         : 'id',
                constraints : true,
                store_name  : 'comune_residenza',
                type        : 'autocomplete'
            },
        },
        altro: {
            type: type.STRING(32),
            allowNull: true,
        },
        latitudine: {
            type: type.DECIMAL(10,6),
            allowNull: true,
        },
        longitudine: {
            type: type.DECIMAL(10,6),
            allowNull: true,
        },
        citta_estero: {
            type: type.STRING(128),
            allowNull: true,
        },
        nazione: {
            type: type.STRING(128),
            allowNull: true,
        },

    };

    return schema;
}

