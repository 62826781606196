///////////////////////////////////////

// GESTORE LOG DA WEBSOCKET

const config = require('@/config.js')[ process.env.NODE_ENV || 'test' ];

export const websocket_logger = {
    namespaced: true,
    state: {
        message          : '',
        variant          : '',
        loader           : false,
        ws               : null,
        wspath           : null,
    },
    actions: {
        set_path( { commit, state }, wspath ) {
            commit('set_path', wspath );
            if ( config.backend_url.includes( 'https' ) ) {
                var ws = new WebSocket( config.backend_url.replace('https://', 'wss://') + state.wspath );
            } else if ( config.backend_url.includes( 'http' ) ) {
                var ws = new WebSocket( config.backend_url.replace('http://', 'ws://') + state.wspath );
            }
            commit('set_ws', ws );
            ws.onmessage = msg => commit('in_message', msg);
        },
        reset( { commit }, message_class ) { commit('reset', message_class); },
    },
    mutations: {
        set_path( state, wspath ) { state.wspath = wspath; },
        set_ws( state, ws ) { state.ws = ws; },
        in_message( state, msg ) {
            var message = JSON.parse(msg.data);
            if ( message.hasOwnProperty('start') === true ) {
                state.loader = true;
            } else if ( message.hasOwnProperty('stop') === true ) {
                state.loader = false;
                state.ws.close();
                state.ws = null;
            } else {
                state.loader = true;
            }
            if ( message.level === 'info' ) {
                state.variant = '';
                if ( message.hasOwnProperty('success') === true ) {
                    state.variant = 'success';
                }
            } else if ( message.level === 'notice' ) {
                state.variant = 'warning';
            } else if ( message.level === 'crit' ) {
                state.variant = 'danger';
                state.loader = false;
            } else if ( message.level === 'error' ) {
                state.variant = 'danger';
                state.loader = false;
            }
            state.message = message;
        },
        reset( state, message_class ) {
            state.loader         = true;
            state.message        = { 'class': message_class, action: 'Inizializzazione in corso' };
            state.variant        = '';
        },
    }
}
