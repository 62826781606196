////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'diary',
    send_alert    : false,
    default_order : 'id DESC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    show_un : false,
};

const actions = {
    ...base.actions,
    get_all( { commit, state, getters }, _data = { options: {} } ) { // ok
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        commit('start_request');
        data.options.order = getters.order;
        if ( state.show_un === true ) { data.options.type = 1; }
        state.service.get_all(getters.model_name, data.options)
            .then( response => {
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
    create({ commit, state, getters }, data) {
        commit('start_request');
        state.service.create( getters.model_name, data, false )
            .then( response => {
                commit('success_create', response.data);
                commit('success_list', response.data);
                commit('set_total', response.tot);
                commit('set_page', response.page);
                commit('set_rows_per_page', response.rows_per_page);
            })
            .catch( error => {
                commit('error', error)
            });
    },
    set_show_un( { commit }, value ) { commit('set_show_un', value); }
};

const mutations = {
    ...base.mutations,
    success_create( state, obj ) {
        state.obj      = obj;
        state.loading  = false;
        state.error    = false;
        state.created  = true;
    },
    set_show_un( state, value ) {
        state.show_un = value;
    },
};

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
};

