//////////////////////////////

module.exports = (type) => {
    var schema = {
            id: {
                type: type.INTEGER,
                primaryKey: true,
                autoIncrement: true
            },
            label: {
                type: type.STRING(1024),
                allowNull: false,
            },
            script: {
                type: type.STRING(1024),
                allowNull: false,
            },
            customer_id: {
                type: type.INTEGER,
                allowNull: false,
                references: {
                    model       : 'customers',
                    key         : 'id',
                    constraints : true,
                    store_name  : 'customer',
                },
            },
            settings: {
                type: type.JSONB,
                allowNull: true,
                defaultValue: {},
            },
            sospeso: {
                type: type.BOOLEAN,
                defaultValue: true,
            },
    };

    return schema;
}


